import React, { useEffect, useMemo, useRef, useState } from 'react';

import { colors } from '../../../lib/designSystem';
import { BoundingBox } from '../../../lib/geometry';
import { getDescription, getIssueDetails, getNodeIds, getTitle } from '../../../lib/geometryHealthUtils';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { OVERLAY_CARD_WIDTH } from '../../../lib/visUtils';
import { Level } from '../../../proto/lcstatus/levels_pb';
import { Bounds } from '../../../pvproto/ParaviewRpc';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useSelectedVisualizerErrorValue } from '../../../recoil/useSelectedVisualizerError';
import { StaticVolume, useStaticVolumes } from '../../../recoil/volumes';
import { CollapsibleText } from '../../CollapsibleText';
import { SvgIcon } from '../../Icon/SvgIcon';
import { createStyles, makeStyles } from '../../Theme';
import Divider from '../../Theme/Divider';
import { useProjectContext } from '../../context/ProjectContext';
import { Dialog } from '../../dialog/Base';
import { useSetSurfacesTransparency } from '../../hooks/useSetSurfacesTransparency';

const useStyles = makeStyles(() => createStyles({
  root: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    marginLeft: '40px',
  },
  content: {
    fontSize: '13px',
    paddingLeft: '20px',
  },
  header: {
    fontSize: '13px',
    fontWeight: '700',
    marginBottom: '4px',
    backgroundColor: colors.neutral150,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    lineHeight: '1.5',
    marginTop: '8px',
  },
}), { name: 'FloatingVisualizerError' });

const boundingBoxToLcvisBounds = (boundingBox: BoundingBox): Bounds => [
  boundingBox.min.x,
  boundingBox.min.y,
  boundingBox.min.z,
  boundingBox.max.x,
  boundingBox.max.y,
  boundingBox.max.z,
];

export const FloatingVisualizerError = () => {
  const classes = useStyles();
  const { projectId, workflowId, jobId } = useProjectContext();

  const selectedVisualizerError = useSelectedVisualizerErrorValue(projectId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const [pointPosition, setPointPosition] = useState<[number, number] | null>(null);
  const setSurfacesTransparency = useSetSurfacesTransparency();
  const resetTransparency = useRef<(() => void) | null>(null);
  const staticVolumes = useStaticVolumes(projectId);

  const volumesById = useMemo(
    () => staticVolumes.reduce((result, volume) => {
      result.set(volume.id, volume);

      return result;
    }, new Map<string, StaticVolume>()),
    [staticVolumes],
  );

  const currentErrorSurfaces = useMemo(() => {
    if (!selectedVisualizerError) {
      return new Set<string>();
    }

    const nodeIds = selectedVisualizerError.issues.flatMap(({ issue }) => getNodeIds(issue));
    return nodeIds.reduce((result, identifier) => {
      let nodeSurfaceIds: Set<string>;

      if (volumesById.has(identifier)) {
        nodeSurfaceIds = volumesById.get(identifier)!.bounds;
      } else {
        nodeSurfaceIds = new Set([identifier]);
      }

      nodeSurfaceIds.forEach((surfaceId) => {
        result.add(surfaceId);
      });

      return result;
    }, new Set<string>());
  }, [selectedVisualizerError, volumesById]);

  // apply transparency mode
  useEffect(() => {
    if (currentErrorSurfaces.size > 0 && !resetTransparency.current) {
      resetTransparency.current = setSurfacesTransparency({
        targetSurfaceIds: currentErrorSurfaces,
        mode: 'opaque',
      });
    } else if (currentErrorSurfaces.size === 0 && resetTransparency.current) {
      resetTransparency.current();
      resetTransparency.current = null;
    }
  }, [currentErrorSurfaces, setSurfacesTransparency]);

  useEffect(() => {
    const boundingBox = selectedVisualizerError?.issues.at(0)?.boundingBox;
    if (boundingBox) {
      lcvHandler.queueDisplayFunction('track current error position', (display) => {
        display.widgets.arcballWidget?.zoomToBoundingBox(boundingBoxToLcvisBounds(boundingBox));
      });
    }
  }, [selectedVisualizerError?.issues]);

  useEffect(() => {
    if (!selectedVisualizerError) {
      return;
    }

    lcvHandler.queueDisplayFunction('track current error position', (display) => {
      display.projectPoint?.setPoint([
        selectedVisualizerError.coordinates.x,
        selectedVisualizerError.coordinates.y,
        selectedVisualizerError.coordinates.z,
      ], setPointPosition);
    });

    return () => {
      lcvHandler.queueDisplayFunction('clear error position tracker', (display) => {
        display.projectPoint?.clearPoint();
      });
    };
  }, [selectedVisualizerError]);

  if (!selectedVisualizerError || !pointPosition) {
    return null;
  }

  const panelPosition = { left: `${pointPosition[0] * 100}%`, top: `${pointPosition[1] * 100}%` };

  return (
    <div
      className={classes.root}
      style={panelPosition}>
      <Dialog
        compact
        onClose={() => {
          lcvHandler.display?.errorList?.updateSelection(null);
        }}
        open
        title="Found Issues"
        width={OVERLAY_CARD_WIDTH}>
        <div className={classes.container}>
          {selectedVisualizerError.issues.map(({ issue }, index, array) => {
            const isLastElement = index === array.length - 1;

            return (
              <React.Fragment key={issue.code + issue.description}>
                <div>
                  <div className={classes.header}>
                    <SvgIcon
                      color={issue.level === Level.ERROR ? colors.red500 : colors.yellow500}
                      maxHeight={12}
                      maxWidth={12}
                      name={issue.level === Level.ERROR ? 'diskExclamation' : 'warning'}
                    />
                    {getTitle(issue)}
                  </div>
                  <div className={classes.content}>
                    <CollapsibleText collapseLimit={80} text={getDescription(issue)} />

                    <div className={classes.detailsContainer}>
                      {getIssueDetails(issue, entityGroupMap).map((item) => (
                        <div key={item.name}><strong>{item.name}</strong>: {item.value}</div>
                      ))}
                    </div>
                  </div>
                </div>
                {!isLastElement && <Divider />}
              </React.Fragment>
            );
          })}
        </div>
      </Dialog>
    </div>
  );
};
