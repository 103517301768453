// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily, useRecoilValue } from 'recoil';

import * as rpc from '../../lib/rpc';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';

// List of geometries in a given project.
export const geometryListState = selectorFamily<
   geometryservicepb.ListGeometriesResponse | null,
   string
 >({
   key: 'geometryList',
   get: (projectId: string) => async () => {
     if (!rpc.clientGeometry || !projectId) {
       return null;
     }
     try {
       const response = await rpc.callRetryWithClient(
         rpc.clientGeometry,
         'listGeometries',
         rpc.clientGeometry.listGeometries,
         new geometryservicepb.ListGeometriesRequest({ projectId }),
       );
       return response;
     } catch (error) {
       console.error('Failed to list geometries', error);
       return null;
     }
   },
   // Protobuf objects mutates themselves even in get*.
   dangerouslyAllowMutability: true,
 });

export function useGeometryList(projectId: string) {
  return useRecoilValue(geometryListState(projectId));
}
