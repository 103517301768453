// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { RecoilProjectKey } from '../../../lib/persist';

/**
 * The states in this file check the width of the possible max width of the toolbar, check what
 * elements of the toolbar are present and then show/hide as many elements as can fit in the
 * available width. The hidden elements will only be visible in the toolbox button.
 * At the moment this is done only for the paraview toolbar.
 */

/**
 * State that will keep the width of the available space for the toolbar.
 */
export const toolbarMaxWidthState = atomFamily<number, RecoilProjectKey>({
  key: 'toolbarMaxWidthState',
  default: 0,
});

export const useToolbarMaxWidthValue = (
  key: RecoilProjectKey,
) => useRecoilValue(toolbarMaxWidthState(key));

export const useSetToolbarMaxWidth = (
  key: RecoilProjectKey,
) => useSetRecoilState(toolbarMaxWidthState(key));

export const DEFAULT_STATE_GROUPS_WIDTHS = {
  mainButtons: 0,
  mainFilters: 0,
  moreFilters: 0,
  toolbox: 0,
};

/**
 * State that will keep the width for the different toolbar floating groups and the total
 * available space so we can later figure whether we can show all groups or if'd need to add some of
 * them to the toolbox item.
 */
export const toolbarGroupsWidthsState = atomFamily<
typeof DEFAULT_STATE_GROUPS_WIDTHS, RecoilProjectKey
>({
  key: 'toolbarGroupsWidthsState',
  default: DEFAULT_STATE_GROUPS_WIDTHS,
});

export const useToolbarGroupsWidths = (
  key: RecoilProjectKey,
) => useRecoilState(toolbarGroupsWidthsState(key));

export const useSetToolbarGroupsWidths = (
  key: RecoilProjectKey,
) => useSetRecoilState(toolbarGroupsWidthsState(key));

export const DEFAULT_STATE_HIDDEN_GROUPS = {
  mainButtons: false,
  mainFilters: false,
  moreFilters: false,
};

/**
 * State that will keep the info whether we hide some toolbar groups because there is
 * not enought space in the toolbar.
 */
export const toolbarHiddenGroupsState = atomFamily<
  typeof DEFAULT_STATE_HIDDEN_GROUPS, RecoilProjectKey
>({
  key: 'toolbarHiddenGroupsState',
  default: DEFAULT_STATE_HIDDEN_GROUPS,
});

export const useToolbarHiddenGroups = (
  key: RecoilProjectKey,
) => useRecoilState(toolbarHiddenGroupsState(key));
