import React, { useState } from 'react';

import { getPhysicsId, getPhysicsName } from '../../../lib/physicsUtils';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useIsAdjointSetup } from '../../../state/internal/global/currentView';
import { ActionButton } from '../../Button/ActionButton';
import LabeledInput from '../../Form/LabeledInput';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import Tooltip from '../../Tooltip';
import { useProjectContext } from '../../context/ProjectContext';
import { AdvancedFluidPhysicsDialog } from '../../dialog/AdvancedFluidPhysics';
import { AdvancedHeatPhysicsDialog } from '../../dialog/AdvancedHeatPhysics';
import NodeLink from '../NodeLink';
import PropertiesSection from '../PropertiesSection';

import { FluidSolverSettings } from './physics/Fluid';
import { HeatSolverSettings } from './physics/Heat';

interface AdvancedSectionProps {
  physicsId: string;
  isFluid: boolean;
}

const AdvancedSettingsSection = (props: AdvancedSectionProps) => {
  // == Props
  const { physicsId, isFluid } = props;

  // == Contects
  const { readOnly } = useProjectContext();

  // == State
  const [openSettings, setOpenSettings] = useState<string | null>(null);

  return (
    <React.Fragment key={physicsId}>
      {isFluid ? (
        <AdvancedFluidPhysicsDialog
          onClose={() => setOpenSettings(null)}
          open={openSettings === physicsId}
          physicsId={physicsId}
        />
      ) : (
        <AdvancedHeatPhysicsDialog
          onClose={() => setOpenSettings(null)}
          open={openSettings === physicsId}
          physicsId={physicsId}
        />
      )}
      <LabeledInput
        label="Advanced Settings"
        layout="favorLabel">
        <Tooltip title={readOnly ? 'View advanced settings' : 'Edit advanced settings'}>
          <span>
            <ActionButton
              asBlock
              kind="minimal"
              onClick={() => setOpenSettings(physicsId)}
              size="small">
              {readOnly ? 'Inspect' : 'Customize'}
            </ActionButton>
          </span>
        </Tooltip>
      </LabeledInput>
    </React.Fragment>
  );
};

export const SolverSettingsPropPanel = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const isAdjointSetup = useIsAdjointSetup();

  const allPhysics = simParam.physics;

  return (
    <>
      <PropertiesSection>
        {allPhysics.map((physics) => {
          const physicsId = getPhysicsId(physics);
          const physicsName = getPhysicsName(physics, simParam);
          const isFluid = physics.params.case === 'fluid';
          return (
            <React.Fragment key={getPhysicsId(physics)}>
              <div style={{ padding: '8px 12px' }}>
                <CollapsibleNodePanel
                  heading={isAdjointSetup ? physicsName : (
                    <NodeLink
                      nodeIds={[physicsId]}
                      text={physicsName}
                    />
                  )}
                  nodeId={`solversettings-${physicsId}`}
                  panelName={physicsName}>
                  {isFluid ? (
                    <FluidSolverSettings physicsId={physicsId} />
                  ) : (
                    <HeatSolverSettings physicsId={physicsId} />
                  )}
                  <AdvancedSettingsSection isFluid={isFluid} physicsId={physicsId} />
                </CollapsibleNodePanel>
              </div>
            </React.Fragment>
          );
        })}
      </PropertiesSection>
    </>
  );
};
