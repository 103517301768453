// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as flags from '../../flags';
import * as rpc from '../../lib/rpc';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as levelspb from '../../proto/lcstatus/levels_pb';
import { useFrontendMenuState } from '../../recoil/frontendMenuState';
import { buildUserGeo, useCanComputeGeometryContacts, useGeometryContacts } from '../../recoil/geometryContactsState';
import { useGeometryHealthValue } from '../../recoil/geometryHealth';
import { getBaseFileUrl, useMeshUrlState } from '../../recoil/meshState';
import { usePendingWorkOrders } from '../../recoil/pendingWorkOrders';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useCadModifier } from '../../recoil/useCadModifier';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useProjectContext } from '../context/ProjectContext';
import { useNonEditableReason } from '../hooks/useNonEditableReason';

import { AddNodeMenuButton } from './AddNodeButton';

export const ComputeGeometryContacts = () => {
  const { projectId } = useProjectContext();
  const [meshUrlState] = useMeshUrlState(projectId);
  const [currModifier] = useCadModifier(projectId);
  const [pending, setPendingWorkOrders] = usePendingWorkOrders(projectId);
  const [frontendMenuStateSetup] = useFrontendMenuState(projectId, '', '');
  const contacts = useGeometryContacts(projectId);
  const experimentConfig = useEnabledExperiments();
  const geometryHealth = useGeometryHealthValue(projectId);
  const canComputeGeometryContacts = useCanComputeGeometryContacts(projectId);
  const setConfirmStack = useSetConfirmations();
  const [selectedGeometry] = useSelectedGeometry(projectId);

  const allowParasolid = experimentConfig.includes(flags.parasolidMeshing);
  const lcSurface = experimentConfig.includes(flags.lcsurfaceTessellation);
  const { workOrders } = pending;
  const hasGeometryIssues = geometryHealth?.issues.some((issue) => (
    issue.level === levelspb.Level.ERROR
  ));

  let disabledReason = '';
  if (Object.keys(workOrders).length) {
    disabledReason = 'Geometry contacts cannot be computed while other operations are running';
  } else if (contacts.operationError) {
    disabledReason = 'Geometry contacts could not be computed. Please contact support.';
  } else if (hasGeometryIssues) {
    disabledReason = 'Geometry contacts cannot be computed in geometries with errors.';
  }

  const nonEditableReason = useNonEditableReason('compute Geometry contacts', disabledReason);
  const disabled = nonEditableReason.length > 0;

  return (
    <AddNodeMenuButton
      menuItems={[
        {
          description: '',
          disabledReason: nonEditableReason,
          disabled,
          label: 'Compute Contacts',
          onClick: async () => {
            // Makes sure that we don't compute contacts when it is not allowed.
            if (!canComputeGeometryContacts) {
              return;
            }
            const contactsReq = new frontendpb.ComputeGeometryContactsRequest({
              projectId,
              geometryId: selectedGeometry.geometryId,
              geometryVersionId: selectedGeometry.geometryVersionId,
              userGeo: buildUserGeo(
                getBaseFileUrl(meshUrlState),
                frontendMenuStateSetup.meshScaling,
                allowParasolid,
                lcSurface,
              ),
              userGeoMod: currModifier || undefined,
            });

            const workOrder = new frontendpb.PendingWorkOrder({
              typ: {
                case: 'computeGeometryContacts',
                value: contactsReq,
              },
            });

            const req = new frontendpb.CheckGetGeometryContactsDependenciesRequest({
              request: contactsReq,
            });

            const reply = await rpc.callRetry(
              'CheckGetGeometryContactsDependencies',
              rpc.client.checkGetGeometryContactsDependencies,
              req,
            );

            const execute = () => {
              setPendingWorkOrders((pendingOrders: frontendpb.PendingWorkOrders) => {
                const newPendingOrders = pendingOrders.clone();
                const workOrdersMap = newPendingOrders.workOrders;
                if (!workOrdersMap[frontendpb.WorkOrderType.COMPUTE_GEOMETRY_CONTACTS]) {
                  workOrdersMap[frontendpb.WorkOrderType.COMPUTE_GEOMETRY_CONTACTS] = workOrder;
                }
                return newPendingOrders;
              });
            };

            if (!reply.allFound) {
              pushConfirmation(setConfirmStack, {
                continueLabel: 'Generate Contacts',
                destructive: true,
                onContinue: () => {
                  execute();
                },
                subtitle: `Generating new geometry contacts could potentially result in a corrupt
                project state. Please refer to the Release Notes dated May 16, 2024 for more
                details.

                As a workaround, you can create a new project, upload the CAD
                file (do not copy an existing project), and generate the
                geometry contacts.

                Do you want to continue?`,
                title: 'Warning',
              });
            } else {
              execute();
            }
          },
        },
      ]}
    />
  );
};
