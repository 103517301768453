// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

export const DEFAULT_MESH_NAME = 'Untitled Mesh';
/**
 * This atom tracks the state of the MultiMesh panel: Select Mesh, Edit Mesh, Mesh Details
 */
export const meshNameAtom = atomFamily<string, string>({
  key: 'meshName',
  default: DEFAULT_MESH_NAME,
});

export function useSetMeshName(projectId: string) {
  return useSetRecoilState(meshNameAtom(projectId));
}

export function useMeshName(projectId: string) {
  return useRecoilValue(meshNameAtom(projectId));
}
