// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getLocalStorageData } from '../../lib/browserStorage';
import { localStorageEffect } from '../../lib/persist';

const showRowChildrenCountKey = 'showRowChildrenCount';

/** Whether to show the number of children of each surface group in the geometry tree. */
const showRowChildrenCountState = atom<boolean>({
  key: 'showRowChildrenCount',
  default: getLocalStorageData(showRowChildrenCountKey, false),
  effects: [
    localStorageEffect(showRowChildrenCountKey),
  ],
});

export const useShowRowChildrenCountState = () => useRecoilState(showRowChildrenCountState);
export const useShowRowChildrenCountValue = () => useRecoilValue(showRowChildrenCountState);
export const useSetShowRowChildrenCount = () => useSetRecoilState(showRowChildrenCountState);
