// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useSetRecoilState } from 'recoil';

const showLCVisSettingsDialog = atom<boolean>({
  key: 'showLCVisSettingsDialog',
  default: false,
});

export const useShowLCVisSettingsDialog = () => useRecoilState(showLCVisSettingsDialog);
export const useSetShowLCVisSettingsDialog = () => useSetRecoilState(showLCVisSettingsDialog);
