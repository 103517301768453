// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/api/v0/luminarycloud/mesh/mesh.proto (package luminary.proto.api.v0.luminarycloud.mesh, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { FileChunk, Vector3 } from "../common/common_pb.js";
import { MeshType } from "../../../../upload/upload_pb.js";
import { AdVector3 } from "../../../../base/base_pb.js";
import { AnnularCylinder, Cube, Cylinder, OrientedCube, Sphere, SphereShell } from "../../../../cad/shape_pb.js";

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.Mesh
 */
export const Mesh = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.Mesh",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "create_time", kind: "message", T: Timestamp },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(Mesh_MeshStatus) },
  ],
);

/**
 * @generated from enum luminary.proto.api.v0.luminarycloud.mesh.Mesh.MeshStatus
 */
export const Mesh_MeshStatus = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.api.v0.luminarycloud.mesh.Mesh.MeshStatus",
  [
    {no: 0, name: "MESH_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MESH_STATUS_CREATING", localName: "CREATING"},
    {no: 2, name: "MESH_STATUS_COMPLETED", localName: "COMPLETED"},
    {no: 3, name: "MESH_STATUS_FAILED", localName: "FAILED"},
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.GetMeshRequest
 */
export const GetMeshRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.GetMeshRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.GetMeshResponse
 */
export const GetMeshResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.GetMeshResponse",
  () => [
    { no: 1, name: "mesh", kind: "message", T: Mesh },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.GetMeshMetadataRequest
 */
export const GetMeshMetadataRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.GetMeshMetadataRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.GetMeshMetadataResponse
 */
export const GetMeshMetadataResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.GetMeshMetadataResponse",
  () => [
    { no: 1, name: "mesh_metadata", kind: "message", T: MeshMetadata },
  ],
);

/**
 * Mesh metadata includes the basic mesh statistics and details, including
 * mesh zones, zone boundaries, mesh statistics, etc.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshMetadata
 */
export const MeshMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshMetadata",
  () => [
    { no: 1, name: "zones", kind: "message", T: MeshMetadata_Zone, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshMetadata.MeshStats
 */
export const MeshMetadata_MeshStats = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshMetadata.MeshStats",
  () => [
    { no: 1, name: "n_points", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "n_faces", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "n_cvs", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "min_coord", kind: "message", T: Vector3 },
    { no: 6, name: "max_coord", kind: "message", T: Vector3 },
  ],
  {localName: "MeshMetadata_MeshStats"},
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshMetadata.Boundary
 */
export const MeshMetadata_Boundary = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshMetadata.Boundary",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stats", kind: "message", T: MeshMetadata_MeshStats },
  ],
  {localName: "MeshMetadata_Boundary"},
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshMetadata.Zone
 */
export const MeshMetadata_Zone = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshMetadata.Zone",
  () => [
    { no: 1, name: "boundaries", kind: "message", T: MeshMetadata_Boundary, repeated: true },
    { no: 2, name: "stats", kind: "message", T: MeshMetadata_MeshStats },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "MeshMetadata_Zone"},
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.ListMeshesRequest
 */
export const ListMeshesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.ListMeshesRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.ListMeshesResponse
 */
export const ListMeshesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.ListMeshesResponse",
  () => [
    { no: 1, name: "meshes", kind: "message", T: Mesh, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.UpdateMeshRequest
 */
export const UpdateMeshRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.UpdateMeshRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.UpdateMeshResponse
 */
export const UpdateMeshResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.UpdateMeshResponse",
  () => [
    { no: 1, name: "mesh", kind: "message", T: Mesh },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.CreateMeshRequest
 */
export const CreateMeshRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.CreateMeshRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mesh_adaptation_params", kind: "message", T: MeshAdaptationParams, oneof: "params" },
    { no: 4, name: "mesh_generation_params", kind: "message", T: MeshGenerationParams, oneof: "params" },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.CreateMeshResponse
 */
export const CreateMeshResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.CreateMeshResponse",
  () => [
    { no: 1, name: "mesh", kind: "message", T: Mesh },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.UploadMeshRequest
 */
export const UploadMeshRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.UploadMeshRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "scaling", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "upload_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "file_chunk", kind: "message", T: FileChunk },
    { no: 6, name: "mesh_type", kind: "enum", T: proto3.getEnumType(MeshType) },
    { no: 7, name: "do_not_read_zones_openfoam", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.UploadMeshResponse
 */
export const UploadMeshResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.UploadMeshResponse",
  () => [
    { no: 1, name: "mesh", kind: "message", T: Mesh },
    { no: 2, name: "upload_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.DeleteMeshRequest
 */
export const DeleteMeshRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.DeleteMeshRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshAdaptationParams
 */
export const MeshAdaptationParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshAdaptationParams",
  () => [
    { no: 1, name: "source_simulation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "target_cv_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "h_ratio", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * MeshGenerationParams represents mesh generation parameters
 * This proto is used by SDK and UI for mesh generation and will be
 * stored into the mesh table.
 *
 * IMPORTANT: MeshGenerationParams must be kept in sync with
 * proto/meshgeneration/meshgeneration.proto. Also, when updating this proto, we
 * need to update all the Golang code that writes/reads MeshGenerationParams from the DB.
 * This is needed until the mesh params migration is done, likely as part of
 * implementing meshing in the API (LC-13297). See details in LC-16188.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams
 */
export const MeshGenerationParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams",
  () => [
    { no: 1, name: "body_x_axis", kind: "message", T: AdVector3 },
    { no: 2, name: "body_y_axis", kind: "message", T: AdVector3 },
    { no: 3, name: "add_refinement", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "proximity_layers", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "volume_params", kind: "message", T: MeshGenerationParams_VolumeParams, repeated: true },
    { no: 6, name: "model_params", kind: "message", T: MeshGenerationParams_ModelParams, repeated: true },
    { no: 7, name: "bl_params", kind: "message", T: MeshGenerationParams_BoundaryLayerParams, repeated: true },
    { no: 8, name: "refine_params", kind: "message", T: MeshGenerationParams_RefinementRegionParams, repeated: true },
    { no: 9, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "mesh_complexity_params", kind: "message", T: MeshGenerationParams_MeshComplexityParams },
    { no: 11, name: "overset_params", kind: "message", T: MeshGenerationParams_OversetParams },
    { no: 12, name: "meshing_mode", kind: "message", T: MeshGenerationParams_MeshingMode },
  ],
);

/**
 * Volume meshing parameters
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.VolumeParams
 */
export const MeshGenerationParams_VolumeParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.VolumeParams",
  () => [
    { no: 1, name: "min_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "max_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "volumes", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ],
  {localName: "MeshGenerationParams_VolumeParams"},
);

/**
 * Model meshing parameters
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.ModelParams
 */
export const MeshGenerationParams_ModelParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.ModelParams",
  () => [
    { no: 1, name: "curvature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "max_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "MeshGenerationParams_ModelParams"},
);

/**
 * Boundary layer meshing parameters
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.BoundaryLayerParams
 */
export const MeshGenerationParams_BoundaryLayerParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.BoundaryLayerParams",
  () => [
    { no: 1, name: "n_layers", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "initial_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "growth_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "MeshGenerationParams_BoundaryLayerParams"},
);

/**
 * Refinement region parameters
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.RefinementRegionParams
 */
export const MeshGenerationParams_RefinementRegionParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.RefinementRegionParams",
  () => [
    { no: 1, name: "h_limit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "sphere", kind: "message", T: Sphere, oneof: "shape" },
    { no: 3, name: "sphere_shell", kind: "message", T: SphereShell, oneof: "shape" },
    { no: 4, name: "cube", kind: "message", T: Cube, oneof: "shape" },
    { no: 5, name: "oriented_cube", kind: "message", T: OrientedCube, oneof: "shape" },
    { no: 6, name: "cylinder", kind: "message", T: Cylinder, oneof: "shape" },
    { no: 7, name: "annular_cylinder", kind: "message", T: AnnularCylinder, oneof: "shape" },
    { no: 8, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "MeshGenerationParams_RefinementRegionParams"},
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.MeshComplexityParams
 */
export const MeshGenerationParams_MeshComplexityParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.MeshComplexityParams",
  () => [
    { no: 1, name: "target_cells", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "limit_max_cells", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(MeshGenerationParams_MeshComplexityParams_ComplexityType) },
  ],
  {localName: "MeshGenerationParams_MeshComplexityParams"},
);

/**
 * Which of the values are we using to define complexity. TARGET means
 * target_cells is being used and the limit_max_cells should be 0. This is
 * used to see the radio button in the UI.
 *
 * @generated from enum luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.MeshComplexityParams.ComplexityType
 */
export const MeshGenerationParams_MeshComplexityParams_ComplexityType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.MeshComplexityParams.ComplexityType",
  [
    {no: 0, name: "MAX"},
    {no: 1, name: "TARGET"},
    {no: 2, name: "MIN"},
  ],
);

/**
 * Overset meshing parameters
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.OversetParams
 */
export const MeshGenerationParams_OversetParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.OversetParams",
  () => [
    { no: 1, name: "surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "MeshGenerationParams_OversetParams"},
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.MeshingMode
 */
export const MeshGenerationParams_MeshingMode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.MeshingMode",
  () => [
    { no: 1, name: "default", kind: "message", T: MeshGenerationParams_MeshingMode_Default, oneof: "mode" },
    { no: 2, name: "base", kind: "message", T: MeshGenerationParams_MeshingMode_Base, oneof: "mode" },
  ],
  {localName: "MeshGenerationParams_MeshingMode"},
);

/**
 * Additional parameters for default meshing mode
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.MeshingMode.Default
 */
export const MeshGenerationParams_MeshingMode_Default = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.MeshingMode.Default",
  [],
  {localName: "MeshGenerationParams_MeshingMode_Default"},
);

/**
 * Additional parameters for base meshing mode
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.MeshingMode.Base
 */
export const MeshGenerationParams_MeshingMode_Base = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.mesh.MeshGenerationParams.MeshingMode.Base",
  [],
  {localName: "MeshGenerationParams_MeshingMode_Base"},
);

