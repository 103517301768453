// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo } from 'react';

import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useProjectContext } from '../context/ProjectContext';

export const useFarfieldSurfaces = () => {
  const { projectId } = useProjectContext();
  const geometryTags = useGeometryTags(projectId);

  const farfieldSurfaces = useMemo(() => [...geometryTags.tagIds()].reduce((result, tagId) => {
    if (/far[\s-_]?field|symmetry[\s-_]plane/i.test(tagId)) {
      const surfaces = geometryTags.surfacesFromTagEntityGroupId(tagId) || [];

      surfaces.forEach((surfaceId) => result.add(surfaceId));
    }

    return result;
  }, new Set<string>()), [geometryTags]);

  return farfieldSurfaces;
};
