// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView } from '../../lib/componentTypes/context';
import { currentViewState } from '../../state/internal/global/currentView';
import { geometryServerStatusAtom } from '../geometry/geometryServerStatus';
import { geometryState } from '../geometry/geometryState';
import { geometryPendingState } from '../pendingWorkOrders';
import { activeVisUrlState } from '../vis/activeVisUrl';

type TipsOverlayKey = {
  projectId: string;
  workflowId: string;
  jobId: string;
  geometryId: string;
}

const showTipsOverlay = selectorFamily<boolean, TipsOverlayKey>({
  key: 'showTipsOverlay',
  get: (key: TipsOverlayKey) => ({ get }) => {
    const { projectId, workflowId, jobId, geometryId } = key;

    const [
      isGeometryPending,
      activeVisUrl,
      currentView,
      geoState,
      geometryServerStatus,
    ] = get(waitForAll([
      geometryPendingState(projectId),
      activeVisUrlState({ projectId, workflowId, jobId }),
      currentViewState,
      geometryState({ projectId, geometryId }),
      geometryServerStatusAtom(geometryId),
    ]));

    const isGeometryView = currentView === CurrentView.GEOMETRY;
    // Whether to show the tips overlay in the Geometry tab
    const isInitGeoLoadingState = (
      isGeometryView && geometryServerStatus === 'busy' && !geoState
    );
    // Whether to show the tips overlay in the Setup tab
    const isGeometryLoading = isGeometryPending && !activeVisUrl;

    return isInitGeoLoadingState || isGeometryLoading;
  },
});

export const useShowTipsOverlay = (key: TipsOverlayKey) => useRecoilValue(showTipsOverlay(key));
