// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useEffect, useRef } from 'react';

import { shallowEqual } from 'fast-equals';

import { CurrentView } from '../../lib/componentTypes/context';
import { parsePhysicsIdFromSubId } from '../../lib/physicsUtils';
import { getCurrentRrParam } from '../../lib/refinementRegionUtils';
import { GEOMETRY_TREE_NODE_TYPES, NodeType } from '../../lib/simulationTree/node';
import { DEFAULT_FILTER_ROOT } from '../../lib/visUtils';
import { NewNode, useNewNodes } from '../../recoil/nodeSession';
import { useEditStateValue } from '../../recoil/paraviewState';
import { useSetPropertiesPanelVisible, useShowGeometryPropertiesPanelOnSelectValue, useSimulationPropertiesPanelDockedValue } from '../../recoil/propertiesPanel';
import useMeshMultiPart from '../../recoil/useMeshingMultiPart';
import { useCurrentView, useIsGeometryView } from '../../state/internal/global/currentView';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { useFluidBoundaryConditionContainerNode } from './useFluidBoundaryConditionContainer';
import { useHeatBoundaryConditionContainerNode } from './useHeatBoundaryConditionContainer';

export enum PropertiesPanelPosition {
  POPPED_IN,
  POPPED_OUT_LEFT,
  POPPED_OUT_RIGHT,
}

/**
 * Return true if properties panel is popped out on either side
 */
export function isPoppedOut(position: PropertiesPanelPosition) {
  return (
    position === PropertiesPanelPosition.POPPED_OUT_LEFT ||
    position === PropertiesPanelPosition.POPPED_OUT_RIGHT
  );
}

/**
 * Determine where to show the properties panel, depending on the docked option for the simulation
 * tree and the type of node that is selected.
 */
export function usePropertiesPanelPosition() {
  // == Contexts
  const { selectedNode } = useSelectionContext();

  // == Recoil
  const simulationPropertiesPanelDocked = useSimulationPropertiesPanelDockedValue();
  const workflowFlag = useWorkflowFlagValue();

  const geoTreeRows = new Set(GEOMETRY_TREE_NODE_TYPES);
  if (workflowFlag) {
    [
      NodeType.CAMERA,
      NodeType.CAMERA_GROUP,
      NodeType.CAMERA_CONTAINER,
      NodeType.CAMERA_GLOBAL_CONTAINER,
      NodeType.TAGS_CONTAINER,
    ].forEach((node) => geoTreeRows.add(node));
  }
  if (selectedNode && geoTreeRows.has(selectedNode?.type)) {
    return PropertiesPanelPosition.POPPED_OUT_LEFT;
  }

  if (simulationPropertiesPanelDocked && !workflowFlag) {
    return PropertiesPanelPosition.POPPED_IN;
  }

  return PropertiesPanelPosition.POPPED_OUT_RIGHT;
}

/**
 * If the properties panel is shown as a floating panel and the panel is empty, we won't show them
 * if they belong to the geometry tree. We'll continue to show these empty prop panels for the
 * simulation tree, because their prop panels can be popped-in, where they are shown anyway.
 */
export function useIgnoreFloatingPropertiesPanel() {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode } = useSelectionContext();
  const editState = useEditStateValue();

  // == State
  const currentView = useCurrentView();
  const propertiesPanelPosition = usePropertiesPanelPosition();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const currentRrParam = getCurrentRrParam(selectedNode?.id, meshMultiPart);
  const physicsId = parsePhysicsIdFromSubId(selectedNode?.id ?? '');
  const {
    surfacesWithoutBoundaryConditions: fluidSurfacesWithoutBC,
  } = useFluidBoundaryConditionContainerNode(physicsId);
  const {
    surfacesWithoutBoundaryConditions: heatSurfacesWithoutBC,
  } = useHeatBoundaryConditionContainerNode(physicsId);
  const isGeometryView = useIsGeometryView();

  const isGeometryTreeNodeSelected = selectedNode?.type &&
    GEOMETRY_TREE_NODE_TYPES.has(selectedNode?.type);

  // LC-22605: In the geometry view we want to hide the prop panels for
  // the Geometry tree (on the right), but show the prop panels for the
  // other trees (e.g. checklist or modification tree).
  if (isGeometryTreeNodeSelected && isGeometryView) {
    return true;
  }

  switch (selectedNode?.type) {
    // This are the rows that we should never show a prop panel for. They are in the left tree.
    case NodeType.CUSTOM_FIELD_CONTAINER:
    case NodeType.TAGS_CONTAINER:
    case NodeType.GEOMETRY_CONTACT_CONTAINER:
    case NodeType.SURFACE_CONTAINER:
    case NodeType.POINT_CONTAINER:
    case NodeType.VOLUME_CONTAINER: {
      return true;
    }
    // This is for the rows that are shown in the right tree which can be either popped-in (in the
    // original ui) or popped out. The block is for the rows that don't have a prop panel so we
    // can ignore it if we are in the popped out mode.
    case NodeType.CAMERA_CONTAINER:
    case NodeType.CAMERA_GLOBAL_CONTAINER:
    case NodeType.CAMERA_GROUP:
    case NodeType.CAMERA:
    case NodeType.MATERIAL_CONTAINER:
    case NodeType.PHYSICS_CONTAINER:
    case NodeType.PHYSICS_FLUID_PHYSICAL_MODEL_CONTAINER:
    case NodeType.PHYSICS_HEAT_HEAT_SOURCE_CONTAINER:
    case NodeType.PLOT_CONTAINER:
    case NodeType.EXPLORATION_VARIABLE_CONTAINER: {
      return isPoppedOut(propertiesPanelPosition);
    }
    // Some nodes types can have props in some cases and no props in other cases so we should
    // check each of these nodes separately
    case NodeType.FILTER: {
      if (!editState && selectedNode.id === DEFAULT_FILTER_ROOT) {
        return isPoppedOut(propertiesPanelPosition);
      }
      return false;
    }
    case NodeType.REFINEMENT_REGION_CONTAINER: {
      if (!selectedNode || !meshMultiPart?.refinementParams.length || !currentRrParam) {
        return isPoppedOut(propertiesPanelPosition);
      }
      return false;
    }
    case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION_CONTAINER: {
      if (readOnly || !fluidSurfacesWithoutBC.length) {
        return isPoppedOut(propertiesPanelPosition);
      }
      return false;
    }
    case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION_CONTAINER: {
      if (readOnly || !heatSurfacesWithoutBC.length) {
        return isPoppedOut(propertiesPanelPosition);
      }
      return false;
    }
    case NodeType.OUTPUT_CONTAINER: {
      if (currentView !== CurrentView.ANALYSIS && currentView !== CurrentView.RESULTS) {
        return isPoppedOut(propertiesPanelPosition);
      }
      return false;
    }
    default:
      return false;
  }
}

/**
 * When a new node is added into the geometry tree, we should open the property panel if the
 * "Show prop panel on select" is enabled.
 * When a new node is added into the simulation tree, we should open the prop panel without
 * any other conditions.
 */
export function useShowPropertyPanelOnNewNode() {
  // == Context
  const { selectedNode } = useSelectionContext();

  // == Recoil
  const [newNodes] = useNewNodes();
  const showGeometryPropertiesPanelOnSelect = useShowGeometryPropertiesPanelOnSelectValue();
  const setPropertiesPanelVisible = useSetPropertiesPanelVisible();

  // == Data
  const newNodesRef = useRef<NewNode[]>([]);

  useEffect(() => {
    if (
      selectedNode &&
      newNodes.at(-1)?.nodeId === selectedNode.id &&
      !shallowEqual(newNodesRef.current, newNodes)
    ) {
      newNodesRef.current = newNodes;

      const geometryNodeAdded = GEOMETRY_TREE_NODE_TYPES.has(selectedNode.type);
      if (geometryNodeAdded) {
        setPropertiesPanelVisible(showGeometryPropertiesPanelOnSelect);
      } else {
        setPropertiesPanelVisible(true);
      }
    }
  }, [showGeometryPropertiesPanelOnSelect, newNodes, setPropertiesPanelVisible, selectedNode]);
}
