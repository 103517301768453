// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { ConnectError } from '@connectrpc/connect';
import { AtomEffect, atom, selector, useRecoilValue, useSetRecoilState, waitForAll } from 'recoil';

import { experimentConfigFixture } from '../lib/fixtures';
import { EMPTY_CONFIG_REPLY, EMPTY_PREFERENCES } from '../lib/paramDefaults/experimentConfig';
import * as persist from '../lib/persist';
import { syncUserStateEffect } from '../lib/recoilSync';
import * as rpc from '../lib/rpc';
import { isTestingEnv } from '../lib/testing/utils';
import { addRpcError } from '../lib/transientNotification';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as userstatepb from '../proto/userstate/userstate_pb';

const expPreferencesKey = 'experimentPreferences';
const expConfigKey = 'experimentConfig';

const serialize = (val: userstatepb.ExperimentPreferences) => val.toBinary();
const deserialize = (val: Uint8Array) => (
  val.length ? userstatepb.ExperimentPreferences.fromBinary(val) : EMPTY_PREFERENCES
);

const rpcPool = new rpc.StreamingRpcPool<
  frontendpb.ExperimentConfigRequest,
  frontendpb.ExperimentConfigReply
>('ExperimentConfig', rpc.client.experimentConfig);

const fetchExperimentMap = (): AtomEffect<
  frontendpb.ExperimentConfigReply
> => ({ setSelf }): (() => void) => rpcPool.start(
  'ExperimentConfig',
  () => new frontendpb.ExperimentConfigRequest(),
  (reply: frontendpb.ExperimentConfigReply) => {
    setSelf(reply);
  },
  (err: ConnectError) => {
    addRpcError('ExperimentConfig failed', err);
    setSelf(EMPTY_CONFIG_REPLY);
  },
);

const experimentConfigReplyStateRpc = atom<frontendpb.ExperimentConfigReply>({
  key: expConfigKey,
  effects: [fetchExperimentMap()],
});

const experimentConfigReplyTesting = atom<frontendpb.ExperimentConfigReply>({
  key: `${expConfigKey}/testing`,
  default: experimentConfigFixture(),
});

const experimentConfigReplyState = isTestingEnv() ?
  experimentConfigReplyTesting : experimentConfigReplyStateRpc;

const experimentPreferencesStateRpc = atom<userstatepb.ExperimentPreferences>({
  key: expPreferencesKey,
  // The default value for this global atom is set in `persist.getUserStateEffect(...)`
  // in `effects` the first time this state is used.
  effects: [
    syncUserStateEffect(expPreferencesKey, deserialize, serialize),
    persist.getUserStateEffect(expPreferencesKey, deserialize),
  ],
});

const experimentPreferencesStateTesting = atom<userstatepb.ExperimentPreferences>({
  key: `${expPreferencesKey}/testing`,
  default: EMPTY_PREFERENCES,
});

const experimentPreferencesState = isTestingEnv() ?
  experimentPreferencesStateTesting : experimentPreferencesStateRpc;

// Selector that merges the current experiment preferences in the user state with
// experiment config on the backend.
const mergedExperimentState = selector<userstatepb.ExperimentPreferences>({
  key: 'mergedExperiments',
  get: async ({ get }) => {
    const [
      experimentConfig,
      currentPrefs,
    ] = get(waitForAll([experimentConfigReplyState, experimentPreferencesState]));
    const experimentPrefs = new userstatepb.ExperimentPreferences({
      version: experimentConfig.version,
    });
    // Add prefs for valid experiments
    Object.entries(experimentConfig.experiments).forEach((entry) => {
      const [id, experiment] = entry;
      let pref = currentPrefs.experiments[id]?.clone();
      if (!pref) {
        // pref does not exist; create it.
        pref = new userstatepb.ExperimentMeta({
          override: false,
          id: BigInt(id),
        });
      }
      // Update the name and UI tag in case they changed.
      pref.name = experiment.name;
      pref.tag = experiment.uiTag;
      experimentPrefs.experiments[id] = pref;
    });
    return experimentPrefs;
  },
});

// Start a streaming rpc for new experiment config data. It returns the most up-to-date experiment
// config data.
export const useExperimentConfig = () => useRecoilValue(mergedExperimentState);
export const useSetExperimentConfig = () => useSetRecoilState(experimentPreferencesState);

export const enabledExperimentsState = selector<string[]>({
  key: 'enabledExperiments',
  get: async ({ get }) => {
    const expConfig = get(mergedExperimentState);
    const tags: string[] = [];
    Object.values(expConfig.experiments).forEach((meta: userstatepb.ExperimentMeta) => {
      if (!meta.override) {
        tags.push(meta.tag);
      }
    });
    return tags;
  },
});

// Returns the enabled experiments for this user. This doesn't include any tags which have
// been manually overriden.
export const useEnabledExperiments = () => useRecoilValue(enabledExperimentsState);

// Returns true if a specific experiment is enabled
// There was an issue (the get was not returning) when using a separate selector for the
// isEnabled state so instead we use a simple wrapper (as of 09/20/22)
export const useIsEnabled = (expTag: string) => (
  useRecoilValue(enabledExperimentsState).includes(expTag)
);
