// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import assert from '../../../../lib/assert';
import { useHeatPhysicsPresets } from '../../../../lib/physicsHeatControlsPresets';
import { parsePhysicsIdFromSubId } from '../../../../lib/physicsUtils';
import { useHeatPhysics } from '../../../../model/hooks/useHeatPhysics';
import { useIsAdjointSetup } from '../../../../state/internal/global/currentView';
import { ActionButton } from '../../../Button/ActionButton';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { AdvancedHeatPhysicsDialog } from '../../../dialog/AdvancedHeatPhysics';
import PropertiesSection from '../../PropertiesSection';

export const HeatSolverSettings = (props: {physicsId: string}) => {
  const { physicsId } = props;
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const isAdjointSetup = useIsAdjointSetup();

  const {
    physics,
    spatialDiscretizationPreset,
    setSpatialDiscretizationPreset,
    solutionControlsPreset,
    setSolutionControlsPreset,
    adjointSolutionControls,
    disablePresetsReason,
  } = useHeatPhysics(projectId, workflowId, jobId, readOnly, physicsId);

  const {
    spatialDiscOptions,
    solnControlOptions,
  } = useHeatPhysicsPresets(spatialDiscretizationPreset, solutionControlsPreset, false);

  if (!physics || !adjointSolutionControls) {
    return null;
  }

  return (
    <>
      <LabeledInput
        label="Spatial Discretization">
        <DataSelect
          asBlock
          disabled={readOnly || isAdjointSetup || !!disablePresetsReason}
          onChange={setSpatialDiscretizationPreset}
          options={spatialDiscOptions}
          size="small"
          tooltip={disablePresetsReason}
        />
      </LabeledInput>
      <LabeledInput
        label="Solution Controls">
        <DataSelect
          asBlock
          disabled={readOnly || !!disablePresetsReason}
          onChange={setSolutionControlsPreset}
          options={solnControlOptions}
          size="small"
          tooltip={disablePresetsReason}
        />
      </LabeledInput>
    </>
  );
};

export const PhysicsHeatPropPanel = () => {
  // Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected heat physics row');
  const physicsId = parsePhysicsIdFromSubId(node.id);

  // State
  const [advancedIsOpen, setAdvancedIsOpen] = useState(false);

  // Hooks
  const {
    physics,
    adjointSolutionControls,
  } = useHeatPhysics(projectId, workflowId, jobId, readOnly, physicsId);
  const propClasses = useCommonTreePropsStyles();

  if (!physics || !adjointSolutionControls) {
    return null;
  }

  return (
    <div className={propClasses.properties}>
      <PropertiesSection>
        <CollapsibleNodePanel
          headerRight={(
            <ActionButton
              asBlock
              kind="minimal"
              onClick={() => setAdvancedIsOpen(true)}
              size="small">
              {readOnly ? 'Inspect' : 'Customize'}
            </ActionButton>
          )}
          heading="Advanced Solver Settings"
          nodeId={node.id}
          panelName="advanced">
          <HeatSolverSettings physicsId={physicsId} />
        </CollapsibleNodePanel>
        <AdvancedHeatPhysicsDialog
          onClose={() => setAdvancedIsOpen(false)}
          open={advancedIsOpen}
          physicsId={physicsId}
        />
      </PropertiesSection>
    </div>
  );
};
