// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

/**
 * Due to the magic of floating point rounding errors, we need to use the below value for 2.
 *
 * When we floor this value (or ceil the reciprocal), the number of refinements is off by one.
 */export const AMR_TARGET_GROWTH = 2.0 + 1e-12;

export function amrRefinements(
  cv_schedule: number[],
) {
  return cv_schedule.length + 1;
}

export function amrInterval(
  refinementIterations: number,
  steps: number,
) {
  // The final adaptive mesh will be used for 2 intervals, this is where the +1 comes from in the
  // denominator. For a 1000 step sim with 4 iterations, remeshing will occur at iteration
  // 200, 400, and 600 (this last one will run for 400 iterations).
  const refinementInterval = Math.floor(steps / (refinementIterations + 1));
  return refinementInterval;
}

// Compute CV target schedule from initial and final target CVs with growth
// factor. If initial_target_cv is greater than 1 with a greater-than-unit
// growth the schedule will stair step, with repeated remeshing and solution at
// each CV level.
export function buildCvSchedule(
  inputInitialTargetCv: number,
  inputFinalTargetCv: number,
): number[] {
  // The max number CV targets to generate, each with repetitions.
  // e.g. 4 with a AMR_TARGET_GROWTH=2 gives three levels of 1/8, 1/4, 1/2, and 1
  const maxLevels = 4;
  // Repetitions of the first target CV (after the initial mesh)
  // This can be more than the other repetitions as these are the least costly
  const nRepeatFirst = 4;
  // Repetitions of the intermediate target CVs (between initial and final)
  // There will be at most maxLevels - 2 intermediate targets
  const nRepeatInter = 3;
  // Repetitions of the final target CV
  const nRepeatFinal = 3;

  // Ceiling of input final CV target
  const finalTargetCv = Math.ceil(inputFinalTargetCv);
  const shrinkFactor = 1.0 / AMR_TARGET_GROWTH;

  // If there is only the final level, take the max number of repetitions
  // Skip stairstepping and only iterate the final level if:
  // 1. initialTargetCv <= 1 : no value starting point
  // 2. shrinkFactor >= 1.0 : no growth
  // 3. initialTargetCv > finalTargetCv * shrinkFactor : initial too close to final
  if (
    inputInitialTargetCv <= 1 ||
    shrinkFactor >= 1.0 ||
    Math.ceil(inputInitialTargetCv) > Math.ceil(inputFinalTargetCv * shrinkFactor)
  ) {
    const nRepeat = Math.max(nRepeatFirst, nRepeatInter, nRepeatFinal);
    return Array(nRepeat).fill(finalTargetCv);
  }

  // Ceiling of input initial CV target, now that we know we are stair stepping
  const initialTargetCv = Math.ceil(inputInitialTargetCv);

  // Compute the CV factors for each level
  const cvFactors: number[] = new Array(maxLevels).fill(0);
  cvFactors[maxLevels - 1] = 1.0;
  for (let i = 0; i < maxLevels - 1; i += 1) {
    cvFactors[i] = shrinkFactor ** (maxLevels - 1 - i);
  }

  // Generate the CV schedule
  const cvSchedule: number[] = [];
  cvFactors.forEach((factor, iFactor) => {
    const targetCv = Math.ceil(finalTargetCv * factor);
    // Skip if the target is less than the initial target, with a buffer
    if (targetCv + 1 < initialTargetCv) {
      return;
    }

    let nRepeat = nRepeatInter;
    if (cvSchedule.length === 0) {
      nRepeat = nRepeatFirst;
    } else if (iFactor === cvFactors.length - 1) {
      nRepeat = nRepeatFinal;
    }

    cvSchedule.push(...Array(nRepeat).fill(targetCv));
  });

  return cvSchedule;
}
