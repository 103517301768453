// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, atomFamily, useRecoilState, useSetRecoilState } from 'recoil';

import { getLocalStorageData } from '../../../lib/browserStorage';
import { localStorageEffect } from '../../../lib/persist';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';

// Defaults of the Motion Animation state
const DEFAULT_START_TIME = 0;
const DEFAULT_END_TIME = 0.01;
const DEFAULT_NSTEPS = 10;
const DEFAULT_AXES_LENGTH = 0.10;

export function defaultMotionAnimationParamState(): ParaviewRpc.GridMotionParam {
  return {
    startTime: DEFAULT_START_TIME,
    endTime: DEFAULT_END_TIME,
    numberOfSteps: DEFAULT_NSTEPS,
    fvmMotionData: '',
    drawAxes: true,
    transformSurfaces: true,
    drawAxesLinks: false,
    axesLength: DEFAULT_AXES_LENGTH,
    childFrameId: '',
  };
}

function getStorageKey(projectId: string) {
  return `motion-animation-param-state-${projectId}`;
}

const motionAnimationParamState = atomFamily<ParaviewRpc.GridMotionParam, string>({
  key: 'motionAnimationParamState',
  default: (projectId: string) => getLocalStorageData<ParaviewRpc.GridMotionParam>(
    getStorageKey(projectId),
    defaultMotionAnimationParamState(),
  ),
  effects: (projectId: string) => [
    localStorageEffect(getStorageKey(projectId)),
  ],
});

export function useMotionAnimationParamState(projectId: string) {
  return useRecoilState(motionAnimationParamState(projectId));
}

// Whether the motion animation is active.
const motionAnimationPlayingState = atom<boolean>({
  key: 'motionAnimationPlayingState',
  default: false,
});

export function useMotionAnimationPlaying() {
  return useRecoilState(motionAnimationPlayingState);
}
export function useSetMotionAnimationPlaying() {
  return useSetRecoilState(motionAnimationPlayingState);
}

const showMotionAnimationSettings = atom<boolean>({
  key: 'showMotionAnimationSettings',
  default: false,
});

export function useShowMotionAnimationSettings() {
  return useRecoilState(showMotionAnimationSettings);
}
