// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { useRecoilCallback } from 'recoil';

import { rollupGroups } from '../../lib/entityGroupUtils';
import {
  assignDomainsToMaterial,
  assignDomainsToPhysics,
  findMaterialIdByDomain,
  findPhysicsIdByDomain,
  getMaterialDomains,
  getPhysicsDomains,
  unassignDomainFromMaterials,
  unassignDomainFromPhysics,
} from '../../lib/entityRelationships';
import { appendHeatSource, attachHeatSourceDomain, detachHeatSourceDomain } from '../../lib/heatSourceUtils';
import {
  ConfigurableMaterialType,
  appendMaterial,
  findMaterialEntityById,
  getMaterialId,
  isMaterialFluid,
  isMaterialSolid,
} from '../../lib/materialUtils';
import {
  ConfigurablePhysicsType,
  appendPhysics,
  findPhysicsByDomain,
  findPhysicsById,
  getPhysicsId,
} from '../../lib/physicsUtils';
import { appendPorousModel, attachVolume, detachVolume } from '../../lib/porousModelUtils';
import { findStaticVolumeById, mapDomainsToIds } from '../../lib/volumeUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { addPhysicsResidualsCallback } from '../../recoil/addPhysicsResidualNode';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useControlPanelModeValue } from '../../recoil/useProjectPage';
import { useSetRowsOpened } from '../../recoil/useSimulationTreeState';
import { useStaticVolumes } from '../../recoil/volumes';

import { typesToAdd } from './usePhysicsSet';
import { useWorkflowConfig } from './useWorkflowConfig';

export const useVolume = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
  id: string,
) => {
  // == Recoil
  const staticVolumes = useStaticVolumes(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const controlPanelMode = useControlPanelModeValue();
  const setNewNodes = useSetNewNodes();
  const setNodesOpened = useSetRowsOpened(projectId, controlPanelMode);
  const addResidualNodes = useRecoilCallback(addPhysicsResidualsCallback);
  const geometryTags = useGeometryTags(projectId);

  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  // The StaticVolume object represention of this volume
  const staticVolume = useMemo(
    () => findStaticVolumeById(id, staticVolumes),
    [id, staticVolumes],
  );

  // The list of surface IDs belonging to this volume
  const bounds = useMemo(() => staticVolume?.bounds || [], [staticVolume]);

  // The physics associated with this volume (may be null)
  const physics = useMemo(() => {
    if (staticVolume) {
      return findPhysicsByDomain(simParam, staticVolume.domain, geometryTags);
    }
    return null;
  }, [geometryTags, simParam, staticVolume]);

  // The fluid physics associated with this volume (may be null)
  const fluidPhysics = useMemo(() => {
    if (physics?.params.case === 'fluid') {
      return physics.params.value;
    }
    return null;
  }, [physics]);

  // The heat physics associated with this volume (may be null)
  const heatPhysics = useMemo(() => {
    if (physics?.params.case === 'heat') {
      return physics.params.value;
    }
    return null;
  }, [physics]);

  // The list of surface or surface group IDs to show in the read-only NodeTable
  const surfaceOrGroupIds = useMemo(
    () => rollupGroups(entityGroupData)([...bounds]),
    [bounds, entityGroupData],
  );

  // Porous models attached to this volume
  const attachedPorousModelIds = useMemo(() => {
    if (fluidPhysics && staticVolume) {
      const porousModel = fluidPhysics.porousBehavior.find(
        (model) => {
          const volumeIds = model.zoneIds.flatMap((zoneId) => {
            if (geometryTags.isTagId(zoneId)) {
              return mapDomainsToIds(staticVolumes, geometryTags.domainsFromTag(zoneId));
            }
            return mapDomainsToIds(staticVolumes, [zoneId]);
          });

          return volumeIds.includes(staticVolume.id);
        },
      );
      if (porousModel) {
        return [porousModel.porousBehaviorId];
      }
    }
    return [];
  }, [fluidPhysics, geometryTags, staticVolume, staticVolumes]);

  // Create a new porous model and attach this volume to it
  const attachNewPorousModel = useCallback(async () => {
    if (fluidPhysics && staticVolume) {
      return saveParamAsync((newParam) => {
        const porousModel = appendPorousModel(newParam, getPhysicsId(physics!));

        // Remove this volume from any other porous models
        detachVolume(newParam, staticVolume);
        // Then add it to this shiny new porous model
        porousModel.zoneIds = [staticVolume.domain];
        return porousModel.porousBehaviorId;
      });
    }
    return null;
  }, [fluidPhysics, physics, saveParamAsync, staticVolume]);

  // Attach this volume to a porous model
  const attachPorousModel = useCallback(async (model: simulationpb.PorousBehavior) => {
    if (staticVolume) {
      await saveParamAsync(
        (newParam) => attachVolume(newParam, staticVolume, model.porousBehaviorId),
      );
    }
  }, [saveParamAsync, staticVolume]);

  // Detach this volume from any porous models
  const detachPorousModel = useCallback(async () => {
    if (staticVolume) {
      await saveParamAsync((newParam) => detachVolume(newParam, staticVolume));
    }
  }, [saveParamAsync, staticVolume]);

  // Heat source assigned to this volume
  const attachedHeatSourceIds = useMemo(() => {
    if (physics && heatPhysics && staticVolume) {
      const heatSource = heatPhysics.heatSource.find(
        (source) => source.heatSourceZoneIds.includes(staticVolume.domain),
      );
      if (heatSource) {
        return [heatSource?.heatSourceId];
      }
    }
    return [];
  }, [heatPhysics, physics, staticVolume]);

  // Create a new heat source and attach this volume to it
  const attachNewHeatSource = useCallback(async () => {
    if (physics && heatPhysics && staticVolume) {
      const physicsId = getPhysicsId(physics);
      return saveParamAsync((newParam) => {
        const newHeatSource = appendHeatSource(newParam, physicsId);

        if (newHeatSource) {
          // Remove this volume from any other heat sources
          detachHeatSourceDomain(newParam, staticVolume.domain);
          // Then add it to this shiny new heat source
          newHeatSource.heatSourceZoneIds = [staticVolume.domain];
          return newHeatSource.heatSourceId;
        }

        return null;
      });
    }
    return null;
  }, [heatPhysics, physics, saveParamAsync, staticVolume]);

  // Attach this volume to a heat source
  const attachHeatSource = useCallback(async (heatSourceId: string) => {
    if (staticVolume) {
      await saveParamAsync(
        (newParam) => attachHeatSourceDomain(newParam, staticVolume.domain, heatSourceId),
      );
    }
  }, [saveParamAsync, staticVolume]);

  // Detach this volume from any heat sources
  const detachHeatSource = useCallback(async () => {
    if (staticVolume) {
      await saveParamAsync((newParam) => detachHeatSourceDomain(newParam, staticVolume.domain));
    }
  }, [saveParamAsync, staticVolume]);

  // The ID of the material to which this volume (domain) is assigned (may be undefined)
  const assignedMaterialId = useMemo(
    () => (staticVolume ?
      findMaterialIdByDomain(simParam, staticVolume.domain, geometryTags) : undefined),
    [geometryTags, simParam, staticVolume],
  );

  // The material entity to which this volume (domain) is assigned (may be undefined)
  const assignedMaterial = useMemo(
    () => (assignedMaterialId ? findMaterialEntityById(simParam, assignedMaterialId) : undefined),
    [assignedMaterialId, simParam],
  );

  // Create a new material and assign this volume (domain) to it
  const assignNewMaterial = useCallback(async (type: ConfigurableMaterialType) => {
    if (staticVolume) {
      return saveParamAsync((newParam) => {
        const newMaterialId = appendMaterial(newParam, type);

        assignDomainsToMaterial(newParam, new Set([staticVolume.domain]), newMaterialId);

        return newMaterialId;
      });
    }
    return null;
  }, [saveParamAsync, staticVolume]);

  // Assign this volume (domain) to a material
  const assignMaterial = useCallback(async (materialEntity: simulationpb.MaterialEntity) => {
    const materialId = getMaterialId(materialEntity);
    if (staticVolume && materialId) {
      await saveParamAsync(
        (newParam) => {
          const domains = getMaterialDomains(newParam, materialId, geometryTags);
          domains.add(staticVolume.domain);
          assignDomainsToMaterial(newParam, domains, materialId);
        },
      );
    }
  }, [geometryTags, saveParamAsync, staticVolume]);

  // Unassign this volume (domain) from any material
  const unassignMaterial = useCallback(async () => {
    if (staticVolume) {
      await saveParamAsync(
        (newParam) => {
          unassignDomainFromMaterials(newParam, staticVolume.domain);
        },
      );
    }
  }, [saveParamAsync, staticVolume]);

  // The ID of  the physics to which this volume (domain) is assigned (may be undefined)
  const assignedPhysicsId = useMemo(
    () => (staticVolume ?
      findPhysicsIdByDomain(simParam, staticVolume.domain, geometryTags) : undefined),
    [geometryTags, simParam, staticVolume],
  );

  // The physics to which this volume (domain) is assigned (may be undefined)
  const assignedPhysics = useMemo(
    () => (assignedPhysicsId ? findPhysicsById(simParam, assignedPhysicsId) : undefined),
    [assignedPhysicsId, simParam],
  );

  // Create a new physics and assign this volume (domain) to it
  const assignNewPhysics = useCallback(async (type: ConfigurablePhysicsType) => {
    if (staticVolume) {
      const [newPhysics, newPhysicsId] = await saveParamAsync(async (newParam) => {
        const createdPhysics = appendPhysics(newParam, type);
        const createdPhysicsId = getPhysicsId(createdPhysics);

        assignDomainsToPhysics(newParam, new Set([staticVolume.domain]), createdPhysicsId);

        return [createdPhysics, createdPhysicsId];
      });

      if (newPhysicsId) {
        setNewNodes((nodes) => [...nodes, initializeNewNode(newPhysicsId)]);
        setNodesOpened((oldValue) => ({ ...oldValue, [newPhysicsId]: true }));
        await addResidualNodes([newPhysics], projectId, workflowId, jobId);
      }

      return newPhysicsId;
    }
    return null;
  }, [saveParamAsync,
    staticVolume,
    setNewNodes,
    setNodesOpened,
    addResidualNodes,
    projectId,
    workflowId,
    jobId]);

  // Assign this volume (domain) to a physics
  const assignPhysics = useCallback(async (physicsToAssign: simulationpb.Physics) => {
    const physicsId = getPhysicsId(physicsToAssign);
    if (staticVolume && physicsId) {
      await saveParamAsync(
        (newParam) => {
          const domains = getPhysicsDomains(newParam, physicsId, geometryTags, staticVolumes);
          domains.add(staticVolume.domain);
          assignDomainsToPhysics(newParam, domains, physicsId);
        },
      );
    }
  }, [geometryTags, saveParamAsync, staticVolumes, staticVolume]);

  // Unassign this volume (domain) from any physics
  const unassignPhysics = useCallback(async () => {
    if (staticVolume) {
      await saveParamAsync(
        (newParam) => {
          unassignDomainFromPhysics(newParam, staticVolume.domain);
        },
      );
    }
  }, [saveParamAsync, staticVolume]);

  const availablePhysicsTypes = useMemo(() => {
    if (assignedMaterialId) {
      const material = findMaterialEntityById(simParam, assignedMaterialId);
      if (material) {
        return typesToAdd.filter((physicsType: simulationpb.Physics['params']['case']) => {
          switch (physicsType) {
            case 'heat': {
              return isMaterialSolid(material);
            }
            case 'fluid': {
              return isMaterialFluid(material);
            }
            default: {
              return false;
            }
          }
        });
      }
    }
    return [] as ConfigurablePhysicsType[];
  }, [assignedMaterialId, simParam]);

  return {
    staticVolume,
    physics,
    fluidPhysics,
    heatPhysics,
    surfaceOrGroupIds,

    attachedPorousModelIds,
    attachNewPorousModel,
    attachPorousModel,
    detachPorousModel,

    attachedHeatSourceIds,
    attachNewHeatSource,
    attachHeatSource,
    detachHeatSource,

    assignedMaterialId,
    assignedMaterial,
    assignNewMaterial,
    assignMaterial,
    unassignMaterial,

    assignedPhysicsId,
    assignedPhysics,
    assignNewPhysics,
    assignPhysics,
    unassignPhysics,
    availablePhysicsTypes,
  };
};
