// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useSetRecoilState } from 'recoil';

export enum OverlayMode {
    NONE = 'NONE',
    PROBE = 'PROBE',
    CENTER_OF_ROTATION = 'CENTER_OF_ROTATION',
    // Box select. It's only active while a SHIFT left mouse drag is in progress.
    BOX_SELECT = 'BOX_SELECT',
    ZOOM_TO_BOX = 'ZOOM_TO_BOX',
}

const overlayMode = atom<OverlayMode>({
  key: 'overlayMode',
  default: OverlayMode.NONE,
});

export function useOverlayMode() {
  return useRecoilState(overlayMode);
}

export function useSetOverlayMode() {
  return useSetRecoilState(overlayMode);
}
