// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback } from 'react';

import * as flags from '../../../flags';
import { CommonMenuItem } from '../../../lib/componentTypes/menu';
import { globalDisabledReason } from '../../../lib/geometryUtils';
import * as booleanpb from '../../../proto/cad/boolean_pb';
import * as shapepb from '../../../proto/cad/shape_pb';
import * as geometrypb from '../../../proto/geometry/geometry_pb';
import { useIsGeometryServerActive } from '../../../recoil/geometry/geometryServerStatus';
import { useGeometryHasFeaturesWithIssues, useGeometrySelectedFeature } from '../../../recoil/geometry/geometryState';
import { useEnabledExperiments } from '../../../recoil/useExperimentConfig';
import { RibbonToolbarTool } from '../../RibbonToolbar/RibbonToolbarButton';
import { useProjectContext } from '../../context/ProjectContext';
import { PatternType, defaultPattern } from '../../treePanel/propPanel/geometry/GeometryModificationPattern';
import { defaultShrinkwrap } from '../../treePanel/propPanel/geometry/GeometryModificationShrinkwrap';
import { TransfType, defaultTransf } from '../../treePanel/propPanel/geometry/GeometryModificationTransform';
import { useHasFarfield } from '../useHasFarfield';
import { useCreateNewModification } from '../useInteractiveGeometry';

type Operation = 'Add' | 'Farfield' | 'Boolean' | 'Transform' | 'Imprint' | 'Shrinkwrap' | 'Delete';

export const useGeometryOperationsData = () => {
  // == Contexts
  const { projectId, geometryId, readOnly } = useProjectContext();

  // == State
  const [selectedFeature] = useGeometrySelectedFeature(geometryId);
  const isGeoServerActive = useIsGeometryServerActive(geometryId);
  const experimentConfig = useEnabledExperiments();
  const createNewModification = useCreateNewModification(true);
  const hasFarfield = useHasFarfield();

  const geometryHasFeaturesWithIssues = useGeometryHasFeaturesWithIssues({
    projectId,
    geometryId,
  });

  // == Derived Data
  const allowDiscrete = experimentConfig.includes(flags.interactiveGeometryDiscrete);

  const createNewShapeOrFarfield = (create: geometrypb.Create, isFarfield: boolean) => {
    if (isFarfield) {
      return createNewModification({
        case: 'farfield',
        value: new geometrypb.Farfield({
          create,
        }),
      });
    }
    return createNewModification({
      case: 'create',
      value: create,
    });
  };

  const disabledReason = globalDisabledReason(selectedFeature, readOnly, isGeoServerActive);
  const disabled = !!disabledReason || geometryHasFeaturesWithIssues;

  const opDisabledReason = useCallback((op: Operation) => {
    if (hasFarfield && op === 'Farfield') {
      return `${op} is not available if there is a Farfield`;
    }
    if (geometryHasFeaturesWithIssues) {
      return 'Cannot perform operations when there are features with issues';
    }
    return disabledReason ?? '';
  }, [hasFarfield, disabledReason, geometryHasFeaturesWithIssues]);

  // IGEO TODO: All create ops return the same shape template. Populate the correct shape on each.
  // Right now, if a mod has a shape defined, it is read only, so we can't do it yet.
  const primitiveMenuItems: CommonMenuItem[] = [
    {
      disabled,
      disabledReason,
      label: 'Cylinder',
      startIcon: { name: 'cylinder', maxHeight: 14 },
      onClick: () => {
        createNewShapeOrFarfield(
          new geometrypb.Create({
            shape: {
              case: 'cylinder',
              value: new shapepb.Cylinder(),
            },
          }),
          false,
        );
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Box',
      startIcon: { name: 'cubeSolid', maxHeight: 14 },
      onClick: () => {
        createNewShapeOrFarfield(
          new geometrypb.Create({
            shape: {
              case: 'box',
              value: new shapepb.Cube(),
            },
          }),
          false,
        );
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Sphere',
      startIcon: { name: 'sphere', maxHeight: 14 },
      onClick: () => {
        createNewShapeOrFarfield(
          new geometrypb.Create({
            shape: {
              case: 'sphere',
              value: new shapepb.Sphere(),
            },
          }),
          false,
        );
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Half Sphere',
      startIcon: { name: 'halfSphere', maxHeight: 14 },
      onClick: () => {
        createNewShapeOrFarfield(
          new geometrypb.Create({
            shape: {
              case: 'halfSphere',
              value: new shapepb.HalfSphere(),
            },
          }),
          false,
        );
      },
    },
    { separator: true },
    {
      disabled,
      disabledReason,
      label: 'CAD',
      startIcon: { name: 'plus', maxHeight: 12 },
      onClick: () => {
        createNewModification({
          case: 'import',
          value: new geometrypb.Import({
            scaling: 1,
          }),
        });
      },
    },
  ];

  const booleanMenuItems: CommonMenuItem[] = [
    {
      disabled,
      disabledReason,
      label: 'Unite',
      startIcon: { name: 'geometryUnion', maxHeight: 14 },
      onClick: () => {
        createNewModification({
          case: 'boolean',
          value: new geometrypb.Boolean({
            op: {
              case: 'regUnion',
              value: new booleanpb.RegularUnion(),
            },
          }),
        });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Subtract',
      startIcon: { name: 'geometrySubtract', maxHeight: 14 },
      onClick: () => {
        createNewModification({
          case: 'boolean',
          value: new geometrypb.Boolean({
            op: {
              case: 'regSubtraction',
              value: new booleanpb.RegularSubtraction(),
            },
          }),
        });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Intersect',
      startIcon: { name: 'geometryIntersect', maxHeight: 14 },
      onClick: () => {
        createNewModification({
          case: 'boolean',
          value: new geometrypb.Boolean({
            op: {
              case: 'regIntersection',
              value: new booleanpb.RegularIntersection(),
            },
          }),
        });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Chop',
      startIcon: { name: 'geometryXor', maxHeight: 14 },
      onClick: () => {
        createNewModification({
          case: 'boolean',
          value: new geometrypb.Boolean({
            op: {
              case: 'regChop',
              value: new booleanpb.RegularChop(),
            },
          }),
        });
      },
    },
  ];

  const transformationMenuItems: CommonMenuItem[] = [
    {
      disabled,
      disabledReason,
      label: 'Mirror',
      startIcon: { name: 'mirroredTriangles', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'transform', value: defaultTransf(TransfType.MIRROR) });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Rotate',
      startIcon: { name: 'rotate', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'transform', value: defaultTransf(TransfType.ROTATION) });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Scale',
      startIcon: { name: 'geometryScale', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'transform', value: defaultTransf(TransfType.SCALING) });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Translate',
      startIcon: { name: 'arrowUpDownLeftRight', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'transform', value: defaultTransf(TransfType.TRANSLATION) });
      },
    },
    { separator: true },
    {
      disabled,
      disabledReason,
      label: 'Circular Pattern',
      startIcon: { name: 'patternCircular', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'pattern', value: defaultPattern(PatternType.CIRCULAR) });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Linear Pattern',
      startIcon: { name: 'patternLinear', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'pattern', value: defaultPattern(PatternType.LINEAR) });
      },
    },
  ];

  const data: RibbonToolbarTool[] = [
    {
      label: 'Add',
      items: primitiveMenuItems,
      disabled: !!opDisabledReason('Add'),
      disabledReason: opDisabledReason('Add'),
      icon: { name: 'cubePlus', maxHeight: 14 },
      key: 'primitiveOpsDropdown',
      locator: 'toolbar-add',
      title: disabled ? disabledReason : 'Add more volumes to your model.',
    },
    {
      label: 'Farfield',
      disabled: !!opDisabledReason('Farfield'),
      disabledReason: opDisabledReason('Farfield'),
      icon: { name: 'farfield', maxHeight: 14 },
      key: 'farfield',
      locator: 'toolbar-farfield',
      onClick: () => {
        createNewShapeOrFarfield(
          new geometrypb.Create({
            shape: {
              case: 'sphere',
              value: new shapepb.Sphere(),
            },
          }),
          true,
        );
      },
      title: 'Farfield will subtract all existing volumes from a larger one.',
    },
    {
      label: 'Boolean',
      items: booleanMenuItems,
      disabled: !!opDisabledReason('Boolean'),
      disabledReason: opDisabledReason('Boolean'),
      icon: { name: 'geometryBoolean', maxHeight: 13 },
      key: 'booleanOpsDropdown',
      locator: 'toolbar-boolean',
      title: 'Combine volumes through unions, intersections, and subtractions.',
    },
    {
      label: 'Transform',
      disabled: !!opDisabledReason('Transform'),
      disabledReason: opDisabledReason('Transform'),
      icon: { name: 'cubeOutlineInvertedCircle', maxHeight: 14.5 },
      key: 'transformOpsDropdown',
      items: transformationMenuItems,
      locator: 'toolbar-transform',
      title: 'Move and pattern volumes in space.',
    },
    {
      label: 'Imprint',
      disabled: !!opDisabledReason('Imprint'),
      disabledReason: opDisabledReason('Imprint'),
      icon: { name: 'doubleUniqueFrames', maxHeight: 14.5 },
      key: 'doubleUniqueFrames',
      locator: 'toolbar-imprint',
      onClick: () => {
        createNewModification({
          case: 'imprint',
          value: new geometrypb.Imprint(),
        });
      },
      title: 'Create interfaces between adjacent volumes.',
    },
    {
      label: 'Shrinkwrap',
      disabled: !!opDisabledReason('Shrinkwrap') || !allowDiscrete,
      disabledReason: opDisabledReason('Shrinkwrap'),
      icon: { name: 'shrinkwrap', maxHeight: 16 },
      key: 'shrinkwrap',
      locator: 'toolbar-shrinkwrap',
      onClick: () => {
        createNewModification({
          case: 'shrinkwrap',
          value: defaultShrinkwrap(),
        });
      },
      title: allowDiscrete ?
        `Cover volumes to make them watertight and to defeature them` :
        `Coming soon!`,
      earlyAccess: true,
    },
    {
      label: 'Delete',
      disabled: !!opDisabledReason('Delete'),
      disabledReason: opDisabledReason('Delete'),
      icon: { name: 'cubeDash', maxHeight: 14 },
      key: 'cubeDash',
      locator: 'toolbar-delete',
      onClick: () => {
        createNewModification({
          case: 'delete',
          value: new geometrypb.Delete(),
        });
      },
      title: 'Remove volumes from your model.',
    },
  ];

  return data;
};
