// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { LCVManipulationMode } from '@luminarycloudinternal/lcvis';
import cx from 'classnames';

import { ParamName, paramDesc } from '../../../SimulationParamDescriptor';
import { newProto, pvToList } from '../../../lib/Vector';
import { adVec3ToPv, equalsZero, newScalarAdVector, pvToAdVec3 } from '../../../lib/adUtils';
import assert from '../../../lib/assert';
import { getMonitorPlaneImposter, monitorPlaneToParam } from '../../../lib/imposterFilteringUtils';
import { hideBoxWidget, hidePlaneWidget, showBoxWidget, showPlaneWidget, updateBoxWidgetState, updatePlaneWidgetState } from '../../../lib/lcvis/api';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { NodeType } from '../../../lib/simulationTree/node';
import sleep from '../../../lib/sleep';
import { useNodePanel } from '../../../lib/useNodePanel';
import { mapDomainsToIds, mapIdsToDomains } from '../../../lib/volumeUtils';
import { AdVector3 } from '../../../proto/base/base_pb';
import { EntityIdentifier } from '../../../proto/client/entity_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { Bounds, BoxClipParam, BoxWidgetState, ImplicitPlaneWidgetState, PlaneParam, WidgetState, WidgetType } from '../../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useSetPendingMonitorPlanes } from '../../../recoil/monitorPlanes';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { StaticVolume, useStaticVolumes } from '../../../recoil/volumes';
import { ActionButton } from '../../Button/ActionButton';
import Form from '../../Form';
import { CollapsiblePanel } from '../../Panel/CollapsiblePanel';
import ParamRow, { ParamRowProps } from '../../ParamRow';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { EditButtons } from '../../controls/EditButtons';
import { LuminaryToggleSwitch } from '../../controls/LuminaryToggleSwitch';
import { UnitVectorButtons } from '../../controls/UnitVectorButtons';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { AttributesDisplay } from '../AttributesDisplay';
import { LabeledSection } from '../LabeledSection';
import { NodeSubselect } from '../NodeSubselect';
import PropertiesSection from '../PropertiesSection';

type EditMode = 'plane' | 'constraint';
type CancelType = 'all' | EditMode;

type ParamEditStateType = {
  editMode: EditMode | null;
  editSource: 'Paraview' | 'Form' | null;

  plane: PlaneParam;
  constraint: BoxClipParam;
  boxConstrained: boolean;
  volumeConstrained: boolean;
  volumeIds: string[];
};

const isEditingPlane = (editState: ParamEditStateType) => editState.editMode === 'plane';
const isEditingConstraint = (editState: ParamEditStateType) => editState.editMode === 'constraint';

const getEditStateFromExistingPlane = (
  plane: simulationpb.MonitorPlane,
  staticVolumes: StaticVolume[],
): ParamEditStateType => ({
  editMode: null,
  editSource: null,

  plane: {
    typ: 'Plane',
    origin: adVec3ToPv(plane.monitorPlanePoint ?? newScalarAdVector()),
    normal: adVec3ToPv(plane.monitorPlaneNormal ?? newScalarAdVector()),
  },
  constraint: {
    typ: 'BoxClip',
    position: adVec3ToPv(plane.monitorPlaneClipCenter ?? newScalarAdVector()),
    rotation: adVec3ToPv(plane.monitorPlaneClipRotation ?? newScalarAdVector()),
    length: adVec3ToPv(plane.monitorPlaneClipSize ?? newScalarAdVector()),
  },
  boxConstrained: !!plane.monitorPlaneBoxClip,
  volumeConstrained: !!plane.monitorPlaneVolumeClip,

  volumeIds: mapDomainsToIds(staticVolumes, (plane.monitorPlaneVolumes || []).map(({ id }) => id)),
});

// A panel displaying all the settings for the selected monitor plane node.
export const MonitorPlanePropPanel = () => {
  const { readOnly, projectId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const { simParam, saveParamAsync } = useSimulationConfig();
  const { selectedNode: node } = useSelectionContext();
  const staticVolumes = useStaticVolumes(projectId);
  const setPendingMonitorPlanes = useSetPendingMonitorPlanes();

  assert(!!node, 'No selected monitor plane row');

  const plane = useMemo(
    () => simParam.monitorPlane.find(({ monitorPlaneId }) => monitorPlaneId === node.id)!,
    [node.id, simParam.monitorPlane],
  );

  // The current fields being edited. This determines which widget to use in Paraview.
  const [paramEditState, setParamEditState] = useState<ParamEditStateType>(
    getEditStateFromExistingPlane(plane, staticVolumes),
  );
  const [isLoading, setIsLoading] = useState(false);

  const setPlaneNormal = (x: number, y: number, z: number) => {
    setParamEditState((prev) => ({
      ...prev,
      plane: { ...prev.plane, normal: { x, y, z } },
      editSource: 'Form',
    }));
  };

  const commonClasses = useCommonTreePropsStyles();
  const {
    addNode,
    activeEdit,
    viewState,
    getDataVisibilityBounds,
    paraviewRenderer,
    paraviewMeshMetadata,
  } = useParaviewContext();

  const defnPanel = useNodePanel(node.id, 'definition');
  const constraintPanel = useNodePanel(`${node.id}-constraint`, 'constraint');
  const boxConstraintPanel = useNodePanel(`${node.id}-box-constraint`, 'box-constraint');
  const volumeConstraintPanel = useNodePanel(`${node.id}-volume-constraint`, 'volume-constraint');

  const imposter = useMemo(() => {
    if (viewState?.root && plane) {
      return getMonitorPlaneImposter(plane, viewState.root, addNode);
    }

    return null;
  }, [viewState, plane, addNode]);

  const updateImposter = (monitorPlane: simulationpb.MonitorPlane) => {
    if (imposter) {
      const visParam = monitorPlaneToParam(monitorPlane);
      activeEdit(imposter.id, visParam);
    }
  };

  const cancelEdits = (type: CancelType) => {
    setParamEditState((currentValue) => {
      const clearState = getEditStateFromExistingPlane(plane, staticVolumes);

      switch (type) {
        case 'all':
          return clearState;
        case 'plane':
          return { ...currentValue, plane: clearState.plane, editMode: null, editSource: null };
        case 'constraint':
          return {
            ...currentValue,
            constraint: clearState.constraint,
            boxConstrained: clearState.boxConstrained,
            volumeConstrained: clearState.volumeConstrained,
            volumeIds: clearState.volumeIds,
            editMode: null,
            editSource: null,
          };
        default:
          throw new Error('Unknown cnacel type');
      }
    });
  };

  const cancelWidgets = () => {
    paraviewRenderer?.deleteWidget();
    hideBoxWidget();
    hidePlaneWidget();
  };

  const onCancel = (type: CancelType) => {
    cancelEdits(type);
    cancelWidgets();
  };

  const commitEdits = async () => {
    setIsLoading(true);

    // enforce asynchronous call
    await sleep(0);

    await saveParamAsync((newParam) => {
      const planeToUpdate = newParam.monitorPlane.find(
        ({ monitorPlaneId }) => monitorPlaneId === node.id,
      );

      if (planeToUpdate) {
        const { origin, normal } = paramEditState.plane;
        planeToUpdate.monitorPlanePoint = pvToAdVec3(origin);
        planeToUpdate.monitorPlaneNormal = pvToAdVec3(normal);

        const { position, length, rotation } = paramEditState.constraint;
        planeToUpdate.monitorPlaneClipCenter = pvToAdVec3(position);
        planeToUpdate.monitorPlaneClipSize = pvToAdVec3(length);
        planeToUpdate.monitorPlaneClipRotation = pvToAdVec3(rotation);
        planeToUpdate.monitorPlaneBoxClip = paramEditState.boxConstrained;

        planeToUpdate.monitorPlaneVolumeClip = paramEditState.volumeConstrained;
        planeToUpdate.monitorPlaneVolumes = paramEditState.volumeConstrained ?
          mapIdsToDomains(
            staticVolumes,
            paramEditState.volumeIds,
          ).map((id) => new EntityIdentifier({ id })) :
          [];

        updateImposter(planeToUpdate);
      }
    });

    setPendingMonitorPlanes((currentValue) => {
      const result = new Set(currentValue);

      result.delete(node.id);
      return result;
    });

    setParamEditState((previousValue) => ({
      ...previousValue,

      editMode: null,
      editSource: null,
    }));

    cancelWidgets();
    setIsLoading(false);
  };

  const changeValue = <K extends 'plane' | 'constraint', T extends keyof ParamEditStateType[K]>(
    editMode: K,
    field: T,
    value: ParamEditStateType[K][T],
  ) => {
    setParamEditState((prev) => ({
      ...prev,
      editMode,
      [editMode]: {
        ...prev[editMode],
        [field]: value,
      },
      editSource: 'Form',
    }));
  };

  const isConstrained = paramEditState.boxConstrained;
  const isVolumeConstrained = paramEditState.volumeConstrained;

  // Get some better initial params
  const enhanceClipSize = useCallback((clipSize?: AdVector3) => {
    const newClipSize = (clipSize || newScalarAdVector()).clone();

    if (!equalsZero(newClipSize)) {
      return newClipSize;
    }

    let bounds: Bounds;

    if (lcvisEnabled) {
      const displayBounds = lcvHandler.display?.getCurrentBounds();

      if (!displayBounds) {
        return newClipSize;
      }

      bounds = displayBounds;
    } else {
      if (!paraviewMeshMetadata?.meshMetadata) {
        return newClipSize;
      }

      bounds = getDataVisibilityBounds(paraviewMeshMetadata?.meshMetadata);
    }

    return newScalarAdVector(
      bounds[1] - bounds[0],
      bounds[3] - bounds[2],
      bounds[5] - bounds[4],
    );
  }, [getDataVisibilityBounds, lcvisEnabled, paraviewMeshMetadata?.meshMetadata]);

  const setIsConstrained = async (constrained: boolean) => {
    if (!constrained) {
      hideBoxWidget();
    }

    setParamEditState((currentValue) => ({
      ...currentValue,
      boxConstrained: constrained,
      constraint: {
        ...currentValue.constraint,
        length: adVec3ToPv(enhanceClipSize(pvToAdVec3(currentValue.constraint.length))),
      },
    }));
  };

  const setIsVolumeConstrained = async (constrained: boolean) => {
    setParamEditState((currentValue) => ({
      ...currentValue,
      volumeConstrained: constrained,
    }));
  };

  const allProps: ParamRowProps[] = [];

  allProps.push(
    {
      nestLevel: 0,
      inputOptions: { showUnits: true },
      param: paramDesc[ParamName.MonitorPlanePoint],
      readOnly: readOnly || defnPanel.collapsed,
      setValue: (origin: AdVector3) => {
        changeValue('plane', 'origin', adVec3ToPv(origin));
      },
      value: paramEditState.plane ?
        pvToAdVec3(paramEditState.plane.origin) :
        plane?.monitorPlanePoint,
    },
    {
      nestLevel: 0,
      inputOptions: { showUnits: true },
      param: paramDesc[ParamName.MonitorPlaneNormal],
      readOnly: readOnly || defnPanel.collapsed,
      setValue: (normal: AdVector3) => {
        changeValue('plane', 'normal', adVec3ToPv(normal));
      },
      value: paramEditState.plane ?
        pvToAdVec3(paramEditState.plane.normal) :
        plane?.monitorPlaneNormal,
    },
  );

  const constraintProps: ParamRowProps[] = [
    {
      nestLevel: 0,
      inputOptions: { showUnits: true },
      param: paramDesc[ParamName.MonitorPlaneClipCenter],
      readOnly: readOnly || defnPanel.collapsed,
      setValue: (position: AdVector3) => {
        changeValue('constraint', 'position', adVec3ToPv(position));
      },
      value: paramEditState.constraint ?
        pvToAdVec3(paramEditState.constraint.position) :
        plane?.monitorPlaneClipCenter,
    },
    {
      nestLevel: 0,
      inputOptions: { showUnits: true },
      param: paramDesc[ParamName.MonitorPlaneClipSize],
      readOnly: readOnly || defnPanel.collapsed,
      setValue: (length: AdVector3) => {
        changeValue('constraint', 'length', adVec3ToPv(length));
      },
      value: paramEditState.constraint ?
        pvToAdVec3(paramEditState.constraint.length) :
        plane?.monitorPlaneClipSize,
    },
    {
      nestLevel: 0,
      inputOptions: { showUnits: true },
      param: paramDesc[ParamName.MonitorPlaneClipRotation],
      readOnly: readOnly || defnPanel.collapsed,
      setValue: (rotation: AdVector3) => {
        changeValue('constraint', 'rotation', adVec3ToPv(rotation));
      },
      value: paramEditState.constraint ?
        pvToAdVec3(paramEditState.constraint.rotation) :
        plane?.monitorPlaneClipRotation,
    },
  ];

  const disablePlane = readOnly || !isEditingPlane(paramEditState);
  const disableConstraint = readOnly || !isEditingConstraint(paramEditState);

  const onPlaneWidgetUpdate = useCallback((newParam: WidgetState) => {
    if (newParam.typ === WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION) {
      const { plane: planeParam } = newParam;

      setParamEditState((currentValue) => ({
        ...currentValue,

        editMode: 'plane',
        editSource: 'Paraview',

        plane: planeParam,
      }));
    }
  }, []);

  const onBoxWidgetUpdate = useCallback((newParam: WidgetState) => {
    if (newParam.typ === WidgetType.BOX_WIDGET_REPRESENTATION) {
      const { box: boxParam } = newParam;
      setParamEditState((currentValue) => ({
        ...currentValue,

        editMode: 'constraint',
        editSource: 'Paraview',

        constraint: boxParam,
      }));
    }
  }, []);

  /** Activate the plane widget or box widget when actively editing one of the two. */
  useEffect(() => {
    const canHandleWidget = (
      lcvisEnabled || (!!paraviewRenderer && !!paraviewMeshMetadata?.meshMetadata)
    );

    if (paramEditState.editMode === null || !canHandleWidget) {
      return;
    }

    const startOrUpdateWidget = async () => {
      // don't start over if paraview is already selected
      if (paramEditState.editSource === 'Paraview') {
        return;
      }

      // only paraview requires that
      let bounds: Bounds | null = null;

      if (!lcvisEnabled) {
        bounds = getDataVisibilityBounds(paraviewMeshMetadata?.meshMetadata!);
      }

      if (isEditingPlane(paramEditState)) {
        if (lcvisEnabled) {
          const planeBounds = lcvHandler.display?.getCurrentDatasetBounds();

          if (!planeBounds) {
            return;
          }

          showPlaneWidget((data) => {
            const mappedData: ImplicitPlaneWidgetState = {
              typ: WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
              plane: {
                typ: 'Plane',
                origin: newProto(...data.position),
                normal: newProto(...data.normal),
              },
              bounds: planeBounds,
            };
            onPlaneWidgetUpdate(mappedData);
          });
          updatePlaneWidgetState({
            normal: pvToList(paramEditState.plane?.normal!),
            position: pvToList(paramEditState.plane?.origin!),
          });
        } else {
          assert(!!bounds, 'Expected Bounds');

          const widgetState: ImplicitPlaneWidgetState = {
            typ: WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
            plane: paramEditState.plane!,
            bounds,
          };
          paraviewRenderer.activateWidget(bounds, widgetState);

          await paraviewRenderer.registerOnUpdateWidgetHandler(onPlaneWidgetUpdate);
        }
      } else if (isEditingConstraint(paramEditState)) {
        if (lcvisEnabled) {
          const isZeroLength = (
            !paramEditState.constraint?.length ||
            equalsZero(pvToAdVec3(paramEditState.constraint?.length))
          );

          // zero length crashes the widget
          if (!isConstrained || isZeroLength) {
            return;
          }

          showBoxWidget({
            onChange: (data) => {
              const mappedData: BoxWidgetState = {
                typ: WidgetType.BOX_WIDGET_REPRESENTATION,
                box: {
                  typ: 'BoxClip',
                  position: newProto(...data.center),
                  rotation: newProto(...data.rotation),
                  length: newProto(...data.size),
                },
              };
              onBoxWidgetUpdate(mappedData);
            },
            manipulationMode: (
              // eslint-disable-next-line no-bitwise
              LCVManipulationMode.kLCVManipulationModeTranslate |
              LCVManipulationMode.kLCVManipulationModeScale |
              LCVManipulationMode.kLCVManipulationModeRotate
            ),
          });

          updateBoxWidgetState({
            center: pvToList(paramEditState.constraint?.position!),
            size: pvToList(paramEditState.constraint?.length!),
            rotation: pvToList(paramEditState.constraint?.rotation!),
          });
        } else {
          assert(!!bounds, 'Expected Bounds');

          const widgetState: BoxWidgetState = {
            typ: WidgetType.BOX_WIDGET_REPRESENTATION,
            box: paramEditState.constraint!,
          };
          paraviewRenderer.activateWidget(bounds, widgetState);
          await paraviewRenderer.registerOnUpdateWidgetHandler(onBoxWidgetUpdate);
        }
      }
    };
    startOrUpdateWidget().catch(() => {
      // do nothing
    });
  }, [
    lcvisEnabled,
    paraviewRenderer,
    paramEditState.editMode,
    paraviewMeshMetadata?.meshMetadata,
    paramEditState,
    getDataVisibilityBounds,
    onPlaneWidgetUpdate,
    onBoxWidgetUpdate,
    isConstrained,
  ]);

  useEffect(() => {
    if (lcvisEnabled || !paraviewRenderer) {
      return;
    }
    return () => {
      paraviewRenderer.deleteWidget();
      hideBoxWidget();
      hidePlaneWidget();
    };
  }, [lcvisEnabled, paraviewRenderer]);

  // Make sure we cancel any edits if the component is dismounted.
  useEffect(() => () => {
    onCancel('all');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    setParamEditState((previousValue) => ({ ...previousValue, editMode: null }));
    paraviewRenderer?.deleteWidget();
    hideBoxWidget();
    hidePlaneWidget();
  };

  const onStartEdit = (mode: EditMode) => () => setParamEditState((currentValue) => ({
    ...currentValue,
    editMode: mode,
    editSource: 'Form',
  }));

  const nodeFilter = useCallback<NodeFilter>((nodeType) => ({
    related: true,
    disabled: nodeType !== NodeType.VOLUME,
  }), []);

  return (
    <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <div className={cx(commonClasses.properties, 'scrollable')}>
        <AttributesDisplay attributes={[{ label: 'Type', value: 'Monitor Plane' }]} />
        <Divider />
        <PropertiesSection>
          <CollapsiblePanel
            collapsed={defnPanel.collapsed}
            headerRight={(
              <EditButtons
                disableEdit={readOnly || isEditingConstraint(paramEditState)}
                disableSave={false}
                editMode={isEditingPlane(paramEditState)}
                helpTextEdit={
                  isEditingConstraint(paramEditState) ?
                    'Finish editing constraints before modifying the definition.' :
                    ''
                }
                onCancel={() => onCancel('plane')}
                onSave={onSave}
                onStartEdit={onStartEdit('plane')}
              />
          )}
            heading="Definition"
            onToggle={defnPanel.toggle}>
            {allProps.map((rowProps) => (
              <ParamRow
                key={`${node.id}-${rowProps.param.name}`}
                {...rowProps}
                readOnly={disablePlane || isLoading}
              />
            ))}
            <Form.LabeledInput label="">
              {readOnly ? null : (
                <Form.Group>
                  <UnitVectorButtons
                    disabled={!isEditingPlane(paramEditState)}
                    onClick={setPlaneNormal}
                  />
                </Form.Group>
              )}
            </Form.LabeledInput>
          </CollapsiblePanel>
        </PropertiesSection>
        <Divider />
        <PropertiesSection>
          <CollapsiblePanel
            collapsed={constraintPanel.collapsed}
            headerRight={(
              <EditButtons
                disableEdit={readOnly || isEditingPlane(paramEditState)}
                disableSave={false}
                editMode={isEditingConstraint(paramEditState)}
                helpTextEdit={
                  isEditingPlane(paramEditState) ?
                    'Finish editing definition before modifying the constraints.' :
                    ''
                }
                onCancel={() => onCancel('constraint')}
                onSave={onSave}
                onStartEdit={onStartEdit('constraint')}
              />
          )}
            heading="Constraints"
            onToggle={constraintPanel.toggle}>
            <CollapsiblePanel
              collapsed={boxConstraintPanel.collapsed || !isConstrained}
              headerRight={(
                <LuminaryToggleSwitch
                  disabled={disableConstraint || isLoading}
                  onChange={setIsConstrained}
                  small
                  value={isConstrained}
                />
            )}
              heading="Box Clip"
              help="Constrain the monitor plane to a box."
              onToggle={boxConstraintPanel.toggle}>
              {isConstrained && constraintProps.map((rowProps) => (
                <ParamRow
                  key={`${node.id}-${rowProps.param.name}`}
                  {...rowProps}
                  readOnly={disableConstraint || isLoading}
                />
              ))}
              {isConstrained && <div style={{ height: '7px' }} />}
            </CollapsiblePanel>
            <CollapsiblePanel
              collapsed={volumeConstraintPanel.collapsed || !isVolumeConstrained}
              headerRight={(
                <LuminaryToggleSwitch
                  disabled={disableConstraint || isLoading}
                  onChange={setIsVolumeConstrained}
                  small
                  value={isVolumeConstrained}
                />
            )}
              heading="Volumes"
              help="Constrain the monitor plane to selected volumes."
              onToggle={volumeConstraintPanel.toggle}>
              {isVolumeConstrained &&
              (

                <NodeSubselect
                  id="monitor-plane-volume-selection"
                  labels={['volumes']}
                  nodeFilter={nodeFilter}
                  nodeIds={paramEditState.volumeIds}
                  onChange={(updatedIds) => {
                    setParamEditState((currentValue) => ({
                      ...currentValue,
                      volumeIds: updatedIds,
                    }));
                  }}
                  readOnly={disableConstraint || isLoading}
                  referenceNodeIds={[node.id]}
                  title="Volumes"
                />
              )}
            </CollapsiblePanel>
          </CollapsiblePanel>
        </PropertiesSection>
      </div>
      <div>
        <Divider />
        <LabeledSection label="">
          <ActionButton
            disabled={isLoading}
            onClick={commitEdits}
            showSpinner={isLoading}
            size="small">
            Submit
          </ActionButton>
        </LabeledSection>
      </div>
    </div>
  );
};
