// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { AuthInfoV2, getAuthInfoV2 } from '../../../lib/AuthInfoCallbackV2';

// A copy of Auth.getAuthInfo(). The two values
// are synchronized using AuthSubscriber in the toplevel index.tsx.
export const authInfoV2State = atom<AuthInfoV2>({
  key: 'authInfoV2State',
  default: getAuthInfoV2(),
});

export function useAuthInfoV2() {
  return useRecoilState(authInfoV2State);
}

export function useAuthInfoV2Value() {
  return useRecoilValue(authInfoV2State);
}

export function useSetAuthInfoV2() {
  return useSetRecoilState(authInfoV2State);
}
