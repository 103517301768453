import { RecoilProjectKey } from '../../lib/persist';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useViewStateOverflow } from '../../recoil/lcvis/viewStateOverflow';
import { useIsAnalysisView } from '../../state/internal/global/currentView';

/** Returns a boolean defining whether the variable selection (and colorbars) should be rendered. */
export const useRenderVariableSelection = (key: RecoilProjectKey) => {
  // == Data
  const isAnalysisView = useIsAnalysisView();
  const [lcvisData] = useViewStateOverflow(key);
  const lcvisEnabled = useLcVisEnabledValue(key.projectId);

  if (isAnalysisView) {
    return true;
  }

  return lcvisData.data.length > 0 && lcvisEnabled;
};
