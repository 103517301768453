// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilState, useRecoilValue } from 'recoil';

import { getSessionStorageData } from '../../../lib/browserStorage';
import { RecoilProjectKey, sessionStorageEffect } from '../../../lib/persist';

// Generate a unique key for the project and job
function getStorageKey(key: RecoilProjectKey) {
  return `run-status-visible-${key.projectId}/${key.workflowId}/${key.jobId}`;
}

const isRunStatusVisibleState = atomFamily<boolean, RecoilProjectKey>({
  key: 'isRunStatusVisibleState',
  default: (key: RecoilProjectKey) => {
    const storageKey = getStorageKey(key);
    return getSessionStorageData<boolean>(storageKey, true);
  },
  effects: (key: RecoilProjectKey) => [
    sessionStorageEffect(getStorageKey(key)),
  ],
});

export function useIsRunStatusVisible(key: RecoilProjectKey) {
  return useRecoilState(isRunStatusVisibleState(key));
}

export function useIsRunStatusVisibleValue(key: RecoilProjectKey) {
  return useRecoilValue(isRunStatusVisibleState(key));
}
