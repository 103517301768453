import { useMemo } from 'react';

import { useIsGeometryCheck } from '../../../recoil/pendingWorkOrders';
import { useAssistantMessagesValue } from '../../../state/external/assistant/assistantMessages';
import { useAssistantRespondingValue } from '../../../state/external/assistant/assistantResponding';
import { useAssistantThinkingValue } from '../../../state/external/assistant/assistantThinking';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useProjectContext } from '../../context/ProjectContext';
import { useGeometryStatus } from '../useGeometryStatus';

export const useAssistantSend = () => {
  // == Contexts
  const { projectId, geometryId } = useProjectContext();

  // == State
  const isGeometryView = useIsGeometryView();
  const { working } = useGeometryStatus();
  const assistantResponding = useAssistantRespondingValue();
  const assistantThinking = useAssistantThinkingValue();
  const assistantMessages = useAssistantMessagesValue(projectId);
  const isGeometryCheck = useIsGeometryCheck(projectId);

  // == Local state
  const messages = useMemo(
    () => Array.from(assistantMessages.values()).filter((message) => message.text),
    [assistantMessages],
  );

  const disabledReason = useMemo(() => {
    if (isGeometryView && !geometryId) {
      return `The AI assistant is only available after importing a geometry file.`;
    }
    if (working || isGeometryCheck) {
      return `The AI assistant is only available after the geometry preparation and checks
        are completed.`;
    }
    return '';
  }, [isGeometryView, geometryId, working, isGeometryCheck]);

  const disabled = (
    assistantResponding ||
    !!disabledReason ||
    // Disable the send button if the last message was from the user
    messages[messages.length - 1]?.isUser ||
    assistantThinking
  );

  return useMemo(() => ({
    disabled,
    disabledReason,
  }), [disabled, disabledReason]);
};
