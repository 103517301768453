// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { Empty } from '@bufbuild/protobuf';
import { selector, useRecoilValue } from 'recoil';

import * as rpc from '../../../lib/rpc';
import { staffRoleState } from '../user/frontendRole';

const supportUsersState = selector<string[] | undefined>({
  key: 'supportUsersState',
  get: async ({ get }) => {
    const isStaff = get(staffRoleState);
    if (isStaff) {
      const resp = await rpc.callRetry(
        'listSupportUsers',
        rpc.client.listSupportUsers,
        new Empty(),
      );
      return resp.users;
    }
    return undefined;
  },
  dangerouslyAllowMutability: true,
});

export function useListSupportUsers() {
  return useRecoilValue(supportUsersState);
}
