// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/visualizationservice/visualizationservice.proto (package luminary.proto.visualizationservice, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Bool, Float, Int, JobStatus, Vector3 } from "../base/base_pb.js";
import { QuantityType } from "../quantity/quantity_pb.js";

/**
 * @generated from enum luminary.proto.visualizationservice.StreamlinesDirection
 */
export const StreamlinesDirection = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.visualizationservice.StreamlinesDirection",
  [
    {no: 0, name: "STREAMLINES_DIRECTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "STREAMLINES_DIRECTION_FORWARD", localName: "FORWARD"},
    {no: 2, name: "STREAMLINES_DIRECTION_BACKWARD", localName: "BACKWARD"},
    {no: 3, name: "STREAMLINES_DIRECTION_BOTH", localName: "BOTH"},
  ],
);

/**
 * @generated from enum luminary.proto.visualizationservice.GlyphSamplingMode
 */
export const GlyphSamplingMode = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.visualizationservice.GlyphSamplingMode",
  [
    {no: 0, name: "GLYPH_SAMPLING_MODE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "GLYPH_SAMPLING_MODE_EVERY_NTH", localName: "EVERY_NTH"},
  ],
);

/**
 * Render representation modes.
 *
 * @generated from enum luminary.proto.visualizationservice.Representation
 */
export const Representation = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.visualizationservice.Representation",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "POINTS"},
    {no: 2, name: "WIREFRAME"},
    {no: 3, name: "SURFACE"},
    {no: 4, name: "SURFACE_WITH_EDGES"},
    {no: 5, name: "VOLUME"},
  ],
);

/**
 * Types of camera projections.
 *
 * @generated from enum luminary.proto.visualizationservice.CameraProjection
 */
export const CameraProjection = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.visualizationservice.CameraProjection",
  [
    {no: 0, name: "CAMERA_PROJECTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CAMERA_PROJECTION_PERSPECTIVE", localName: "PERSPECTIVE"},
    {no: 2, name: "CAMERA_PROJECTION_ORTHOGRAPHIC", localName: "ORTHOGRAPHIC"},
  ],
);

/**
 * Camera location based on a direction.
 *
 * @generated from enum luminary.proto.visualizationservice.CameraSpecificationDirection
 */
export const CameraSpecificationDirection = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.visualizationservice.CameraSpecificationDirection",
  [
    {no: 0, name: "CAMERA_SPECIFICATION_UNSPECIFIED"},
    {no: 1, name: "CAMERA_SPECIFICATION_X_POSITIVE"},
    {no: 2, name: "CAMERA_SPECIFICATION_Y_POSITIVE"},
    {no: 3, name: "CAMERA_SPECIFICATION_Z_POSITIVE"},
    {no: 4, name: "CAMERA_SPECIFICATION_X_NEGATIVE"},
    {no: 5, name: "CAMERA_SPECIFICATION_Y_NEGATIVE"},
    {no: 6, name: "CAMERA_SPECIFICATION_Z_NEGATIVE"},
  ],
);

/**
 * Predefined color maps.
 *
 * @generated from enum luminary.proto.visualizationservice.ColorMapName
 */
export const ColorMapName = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.visualizationservice.ColorMapName",
  [
    {no: 0, name: "COLOR_MAP_NAME_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "COLOR_MAP_NAME_VIRIDIS", localName: "VIRIDIS"},
    {no: 2, name: "COLOR_MAP_NAME_COOL_TO_WARM", localName: "COOL_TO_WARM"},
    {no: 3, name: "COLOR_MAP_NAME_TURBO", localName: "TURBO"},
    {no: 4, name: "COLOR_MAP_NAME_JET", localName: "JET"},
    {no: 5, name: "COLOR_MAP_NAME_WAVE", localName: "WAVE"},
    {no: 6, name: "COLOR_MAP_NAME_XRAY", localName: "XRAY"},
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.Range
 */
export const Range = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Range",
  () => [
    { no: 1, name: "min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.Resolution
 */
export const Resolution = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Resolution",
  () => [
    { no: 1, name: "width", kind: "message", T: Int },
    { no: 2, name: "height", kind: "message", T: Int },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.Plane
 */
export const Plane = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Plane",
  () => [
    { no: 1, name: "origin", kind: "message", T: Vector3 },
    { no: 2, name: "normal", kind: "message", T: Vector3 },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.Box
 */
export const Box = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Box",
  () => [
    { no: 1, name: "center", kind: "message", T: Vector3 },
    { no: 2, name: "lengths", kind: "message", T: Vector3 },
    { no: 3, name: "rotation", kind: "message", T: Vector3 },
  ],
);

/**
 * Spec of a slice filter.
 *
 * @generated from message luminary.proto.visualizationservice.Slice
 */
export const Slice = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Slice",
  () => [
    { no: 1, name: "plane", kind: "message", T: Plane },
    { no: 2, name: "project_vectors", kind: "message", T: Bool },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.Clip
 */
export const Clip = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Clip",
  () => [
    { no: 1, name: "plane", kind: "message", T: Plane, oneof: "clip_function" },
    { no: 2, name: "box", kind: "message", T: Box, oneof: "clip_function" },
    { no: 5, name: "inverted", kind: "message", T: Bool },
    { no: 6, name: "smooth", kind: "message", T: Bool },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.Contour
 */
export const Contour = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Contour",
  () => [
    { no: 1, name: "field", kind: "message", T: Field },
    { no: 2, name: "iso_values", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.Threshold
 */
export const Threshold = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Threshold",
  () => [
    { no: 1, name: "field", kind: "message", T: Field },
    { no: 2, name: "range", kind: "message", T: Range },
    { no: 3, name: "smooth", kind: "message", T: Bool },
    { no: 4, name: "invert", kind: "message", T: Bool },
    { no: 5, name: "strict", kind: "message", T: Bool },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.MultiSlice
 */
export const MultiSlice = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.MultiSlice",
  () => [
    { no: 1, name: "start_position", kind: "message", T: Vector3 },
    { no: 2, name: "end_position", kind: "message", T: Vector3 },
    { no: 3, name: "n_slices", kind: "message", T: Int },
    { no: 4, name: "project_vectors", kind: "message", T: Bool },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.SeedRakeConfig
 */
export const SeedRakeConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.SeedRakeConfig",
  () => [
    { no: 1, name: "start", kind: "message", T: Vector3 },
    { no: 2, name: "end", kind: "message", T: Vector3 },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.SeedSurfaceConfig
 */
export const SeedSurfaceConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.SeedSurfaceConfig",
  () => [
    { no: 1, name: "offset", kind: "message", T: Float },
    { no: 2, name: "project_on_surface", kind: "message", T: Bool },
    { no: 3, name: "sample_rate", kind: "message", T: Int },
    { no: 4, name: "surface_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.SeedGridConfig
 */
export const SeedGridConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.SeedGridConfig",
  () => [
    { no: 1, name: "rake_res", kind: "message", T: Int },
    { no: 2, name: "seed_res", kind: "message", T: Int },
    { no: 3, name: "seed_spacing", kind: "message", T: Float },
    { no: 4, name: "rake_spacing", kind: "message", T: Float },
    { no: 5, name: "center", kind: "message", T: Vector3 },
    { no: 6, name: "u_vec", kind: "message", T: Vector3 },
    { no: 7, name: "v_vec", kind: "message", T: Vector3 },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.Streamlines
 */
export const Streamlines = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Streamlines",
  () => [
    { no: 1, name: "rake", kind: "message", T: SeedRakeConfig, oneof: "seed_type" },
    { no: 2, name: "surface", kind: "message", T: SeedSurfaceConfig, oneof: "seed_type" },
    { no: 3, name: "grid", kind: "message", T: SeedGridConfig, oneof: "seed_type" },
    { no: 4, name: "n_streamlines", kind: "message", T: Int },
    { no: 5, name: "max_length", kind: "message", T: Float },
    { no: 6, name: "direction", kind: "enum", T: proto3.getEnumType(StreamlinesDirection) },
    { no: 7, name: "field", kind: "message", T: Field },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.Glyph
 */
export const Glyph = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Glyph",
  () => [
    { no: 1, name: "field", kind: "message", T: Field },
    { no: 2, name: "n_glyphs", kind: "message", T: Int },
    { no: 3, name: "sampling_mode", kind: "enum", T: proto3.getEnumType(GlyphSamplingMode) },
    { no: 4, name: "fixed_size_glyphs", kind: "message", T: Float, oneof: "glyph_size" },
    { no: 5, name: "glyph_scale_size", kind: "message", T: Float, oneof: "glyph_size" },
  ],
);

/**
 * Filter which wraps a given filter spec with an ID and a name to avoid
 * duplication,
 *
 * @generated from message luminary.proto.visualizationservice.Filter
 */
export const Filter = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Filter",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "slice", kind: "message", T: Slice, oneof: "spec" },
    { no: 4, name: "clip", kind: "message", T: Clip, oneof: "spec" },
    { no: 5, name: "contour", kind: "message", T: Contour, oneof: "spec" },
    { no: 6, name: "multi_slice", kind: "message", T: MultiSlice, oneof: "spec" },
    { no: 7, name: "threshold", kind: "message", T: Threshold, oneof: "spec" },
    { no: 8, name: "streamlines", kind: "message", T: Streamlines, oneof: "spec" },
    { no: 9, name: "glyph", kind: "message", T: Glyph, oneof: "spec" },
  ],
);

/**
 * Expresses the connections between filters. Each parent filter can have
 * multiple children filters. These are the direct children of a filter. The
 * distance between the parent and the children is one level.
 *
 * @generated from message luminary.proto.visualizationservice.FilterConnections
 */
export const FilterConnections = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.FilterConnections",
  () => [
    { no: 1, name: "children_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * Used to generate post processing extractions on a mesh.
 *
 * @generated from message luminary.proto.visualizationservice.MeshEntity
 */
export const MeshEntity = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.MeshEntity",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Used to generate post processing extractions on a given solution of a
 * simulation.
 *
 * @generated from message luminary.proto.visualizationservice.SimulationEntity
 */
export const SimulationEntity = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.SimulationEntity",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "solution_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Used to generate post processing extractions on a transient simulation.
 *
 * @generated from message luminary.proto.visualizationservice.TransientSimulationEntity
 */
export const TransientSimulationEntity = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.TransientSimulationEntity",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "start_solution_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "end_solution_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Used to generate post processing extractions on a geometry.
 *
 * @generated from message luminary.proto.visualizationservice.GeometryEntity
 */
export const GeometryEntity = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.GeometryEntity",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "geometry_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Entity to which the extract is associated.
 *
 * @generated from message luminary.proto.visualizationservice.ExtractEntity
 */
export const ExtractEntity = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.ExtractEntity",
  () => [
    { no: 1, name: "mesh", kind: "message", T: MeshEntity, oneof: "entity" },
    { no: 2, name: "simulation", kind: "message", T: SimulationEntity, oneof: "entity" },
    { no: 3, name: "geometry", kind: "message", T: GeometryEntity, oneof: "entity" },
  ],
);

/**
 * Defines a given variable to be displayed on arbitrary entities of the
 * post processing extract.
 *
 * @generated from message luminary.proto.visualizationservice.Field
 */
export const Field = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Field",
  () => [
    { no: 1, name: "quantity_typ", kind: "enum", T: proto3.getEnumType(QuantityType) },
    { no: 2, name: "component", kind: "enum", T: proto3.getEnumType(Field_Component) },
  ],
);

/**
 * @generated from enum luminary.proto.visualizationservice.Field.Component
 */
export const Field_Component = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.visualizationservice.Field.Component",
  [
    {no: 0, name: "COMPONENT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "COMPONENT_X", localName: "X"},
    {no: 2, name: "COMPONENT_Y", localName: "Y"},
    {no: 3, name: "COMPONENT_Z", localName: "Z"},
    {no: -1, name: "COMPONENT_MAGNITUDE", localName: "MAGNITUDE"},
  ],
);

/**
 * Defines the render attributes for a given entity.
 *
 * @generated from message luminary.proto.visualizationservice.DisplayAttributes
 */
export const DisplayAttributes = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.DisplayAttributes",
  () => [
    { no: 1, name: "field", kind: "message", T: Field },
    { no: 2, name: "visible", kind: "message", T: Bool },
    { no: 3, name: "representation", kind: "enum", T: proto3.getEnumType(Representation) },
    { no: 4, name: "opacity", kind: "message", T: Float },
  ],
);

/**
 * Properties of the axes grid.
 *
 * @generated from message luminary.proto.visualizationservice.AxesGridProperties
 */
export const AxesGridProperties = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.AxesGridProperties",
  () => [
    { no: 1, name: "visible", kind: "message", T: Bool },
  ],
);

/**
 * Camera location based on the look at convention.
 *
 * @generated from message luminary.proto.visualizationservice.CameraSpecificationLookAt
 */
export const CameraSpecificationLookAt = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.CameraSpecificationLookAt",
  () => [
    { no: 1, name: "position", kind: "message", T: Vector3 },
    { no: 2, name: "look_at", kind: "message", T: Vector3 },
    { no: 3, name: "up", kind: "message", T: Vector3 },
  ],
);

/**
 * Defines a camera used to render.
 *
 * @generated from message luminary.proto.visualizationservice.Camera
 */
export const Camera = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Camera",
  () => [
    { no: 1, name: "projection", kind: "enum", T: proto3.getEnumType(CameraProjection) },
    { no: 2, name: "direction", kind: "enum", T: proto3.getEnumType(CameraSpecificationDirection), oneof: "specification" },
    { no: 3, name: "look_at", kind: "message", T: CameraSpecificationLookAt, oneof: "specification" },
  ],
);

/**
 * Animation settings to generate a sole static image.
 *
 * @generated from message luminary.proto.visualizationservice.AnimationSettingsStatic
 */
export const AnimationSettingsStatic = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.AnimationSettingsStatic",
  () => [
    { no: 1, name: "camera", kind: "message", T: Camera },
    { no: 2, name: "resolution", kind: "message", T: Resolution },
  ],
);

/**
 * Animation settings to generate a video for transient simulations.
 *
 * @generated from message luminary.proto.visualizationservice.AnimationSettingsTimeStep
 */
export const AnimationSettingsTimeStep = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.AnimationSettingsTimeStep",
  () => [
    { no: 1, name: "camera", kind: "message", T: Camera },
    { no: 4, name: "resolution", kind: "message", T: Resolution },
    { no: 5, name: "format", kind: "enum", T: proto3.getEnumType(AnimationSettingsTimeStep_Format) },
    { no: 6, name: "skip_frames", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from enum luminary.proto.visualizationservice.AnimationSettingsTimeStep.Format
 */
export const AnimationSettingsTimeStep_Format = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.visualizationservice.AnimationSettingsTimeStep.Format",
  [
    {no: 0, name: "FORMAT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "FORMAT_GIF", localName: "GIF"},
    {no: 2, name: "FORMAT_MP4", localName: "MP4"},
  ],
);

/**
 * Animation properties.
 *
 * @generated from message luminary.proto.visualizationservice.AnimationProperties
 */
export const AnimationProperties = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.AnimationProperties",
  () => [
    { no: 1, name: "static", kind: "message", T: AnimationSettingsStatic, oneof: "settings" },
    { no: 2, name: "time_step", kind: "message", T: AnimationSettingsTimeStep, oneof: "settings" },
  ],
);

/**
 * Color map for a given display variable.
 *
 * @generated from message luminary.proto.visualizationservice.ColorMap
 */
export const ColorMap = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.ColorMap",
  () => [
    { no: 1, name: "field", kind: "message", T: Field },
    { no: 2, name: "name", kind: "enum", T: proto3.getEnumType(ColorMapName) },
    { no: 3, name: "range", kind: "message", T: Range },
    { no: 4, name: "visible", kind: "message", T: Bool },
    { no: 5, name: "discretize", kind: "message", T: Bool },
    { no: 6, name: "n_colors", kind: "message", T: Int },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.PostProcessingExtractSpec
 */
export const PostProcessingExtractSpec = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.PostProcessingExtractSpec",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "entity_type", kind: "message", T: ExtractEntity },
    { no: 4, name: "axes_grid_properties", kind: "message", T: AxesGridProperties },
    { no: 5, name: "filters", kind: "message", T: Filter, repeated: true },
    { no: 6, name: "filter_connections", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FilterConnections} },
    { no: 7, name: "global_display_attributes", kind: "message", T: DisplayAttributes },
    { no: 8, name: "display_attributes", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: DisplayAttributes} },
    { no: 9, name: "color_maps", kind: "message", T: ColorMap, repeated: true },
    { no: 10, name: "animation_properties", kind: "message", T: AnimationProperties },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.PostProcessingExtract
 */
export const PostProcessingExtract = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.PostProcessingExtract",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "extract_spec", kind: "message", T: PostProcessingExtractSpec },
    { no: 3, name: "status", kind: "message", T: JobStatus },
    { no: 4, name: "creation_time", kind: "message", T: Timestamp },
    { no: 5, name: "soft_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.CreatePostProcessingExtractRequest
 */
export const CreatePostProcessingExtractRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.CreatePostProcessingExtractRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "extract_spec", kind: "message", T: PostProcessingExtractSpec },
    { no: 3, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.CreatePostProcessingExtractResponse
 */
export const CreatePostProcessingExtractResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.CreatePostProcessingExtractResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.GetPostProcessingExtractRequest
 */
export const GetPostProcessingExtractRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.GetPostProcessingExtractRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.GetPostProcessingExtractResponse
 */
export const GetPostProcessingExtractResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.GetPostProcessingExtractResponse",
  () => [
    { no: 1, name: "extract", kind: "message", T: PostProcessingExtract },
    { no: 2, name: "output", kind: "message", T: Output },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.SignedUrl
 */
export const SignedUrl = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.SignedUrl",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.OutputExtract
 */
export const OutputExtract = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.OutputExtract",
  () => [
    { no: 1, name: "data_bytes", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "data" },
    { no: 2, name: "signed_url", kind: "message", T: SignedUrl, oneof: "data" },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.OutputStatic
 */
export const OutputStatic = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.OutputStatic",
  () => [
    { no: 1, name: "image", kind: "message", T: OutputExtract },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.OutputTimeStep
 */
export const OutputTimeStep = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.OutputTimeStep",
  () => [
    { no: 1, name: "video", kind: "message", T: OutputExtract },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.Output
 */
export const Output = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.Output",
  () => [
    { no: 1, name: "static", kind: "message", T: OutputStatic, oneof: "data" },
    { no: 2, name: "time_step", kind: "message", T: OutputTimeStep, oneof: "data" },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.ListPostProcessingExtractsRequest
 */
export const ListPostProcessingExtractsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.ListPostProcessingExtractsRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entity_type", kind: "message", T: ExtractEntity },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.ListPostProcessingExtractsResponse
 */
export const ListPostProcessingExtractsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.ListPostProcessingExtractsResponse",
  () => [
    { no: 1, name: "extracts", kind: "message", T: PostProcessingExtract, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.DeletePostProcessingExtractRequest
 */
export const DeletePostProcessingExtractRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.DeletePostProcessingExtractRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.DeletePostProcessingExtractResponse
 */
export const DeletePostProcessingExtractResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.DeletePostProcessingExtractResponse",
  [],
);

/**
 * Proto representation of the workspaceParams used by LcVis. This is used in
 * order to convert the requests into the internal workspace params proto and
 * then into json.
 *
 * @generated from message luminary.proto.visualizationservice.ClipFunction
 */
export const ClipFunction = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.ClipFunction",
  () => [
    { no: 1, name: "plane", kind: "message", T: Plane },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.StreamlineSeedConfig
 */
export const StreamlineSeedConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.StreamlineSeedConfig",
  () => [
    { no: 1, name: "n_streamlines", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "config", kind: "message", T: StreamlineSeedConfig_Config },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.StreamlineSeedConfig.Config
 */
export const StreamlineSeedConfig_Config = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.StreamlineSeedConfig.Config",
  () => [
    { no: 1, name: "start", kind: "message", T: Vector3 },
    { no: 2, name: "end", kind: "message", T: Vector3 },
    { no: 3, name: "offset", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "project_on_surface", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "sample_rate", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "surface_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "rake_res", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "seed_res", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "seed_spacing", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "rake_spacing", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "center", kind: "message", T: Vector3 },
    { no: 12, name: "u_vec", kind: "message", T: Vector3 },
    { no: 13, name: "v_vec", kind: "message", T: Vector3 },
  ],
  {localName: "StreamlineSeedConfig_Config"},
);

/**
 * @generated from message luminary.proto.visualizationservice.FilterParams
 */
export const FilterParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.FilterParams",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "clip_function", kind: "message", T: ClipFunction },
    { no: 3, name: "plane", kind: "message", T: Plane },
    { no: 4, name: "inverted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "smooth", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "project_vectors", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "field_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "field_component", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "strict", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 12, name: "invert", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "component", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "iso_values", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
    { no: 15, name: "start_pos", kind: "message", T: Vector3 },
    { no: 16, name: "end_pos", kind: "message", T: Vector3 },
    { no: 17, name: "nslices", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "direction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "max_length", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 22, name: "seed_config", kind: "message", T: StreamlineSeedConfig },
    { no: 23, name: "n_glyphs", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 24, name: "sampling_mode", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 25, name: "vector_field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "glyph_scale_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 27, name: "fixed_size_glyphs", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.FilterInternal
 */
export const FilterInternal = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.FilterInternal",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "params", kind: "message", T: FilterParams },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.WorkspaceParams
 */
export const WorkspaceParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.WorkspaceParams",
  () => [
    { no: 1, name: "workspace_params", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.InternalWorkspace
 */
export const InternalWorkspace = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.InternalWorkspace",
  () => [
    { no: 1, name: "workspace_params", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 2, name: "filters", kind: "message", T: FilterInternal, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.visualizationservice.ExtractOutputs
 */
export const ExtractOutputs = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.visualizationservice.ExtractOutputs",
  () => [
    { no: 1, name: "image_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

