// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { SetterOrUpdater, atom, useRecoilState, useSetRecoilState } from 'recoil';

import { ConfirmationDialogProps } from '../../../components/dialog/Confirmation';
import { Optional } from '../../../lib/tsUtils';

// A confirmation is essentially an object of type ConfirmationDialogProps, but with 'open'
// excluded and 'onClose' made optional.
export type ConfirmationConfig = Optional<Omit<ConfirmationDialogProps, 'open'>, 'onClose'>;

// The confirmation stack can hold both ConfirmationConfig objects and functions that return
// ConfirmationConfig objects.
export type ConfirmationItem = ConfirmationConfig | (() => ConfirmationConfig);

const confirmationStack = atom<ConfirmationItem[]>({
  key: 'confirmationStack',
  default: [],
});

export function useConfirmations() {
  return useRecoilState(confirmationStack);
}

export function useSetConfirmations() {
  return useSetRecoilState(confirmationStack);
}

export function pushConfirmation(
  setConfirmations: SetterOrUpdater<ConfirmationItem[]>,
  item: ConfirmationItem,
) {
  setConfirmations((prevValue) => [...prevValue, item]);
}
