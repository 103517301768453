// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * This atom contains the ID of the last selected mesh on a given project
 */
const previouslySelectedMeshAtom = atomFamily<string, string>({
  key: 'previouslySelectedMesh',
  default: '',
});

export function useSetPreviouslySelectedMesh(projectId: string) {
  return useSetRecoilState(previouslySelectedMeshAtom(projectId));
}

export function usePreviouslySelectedMesh(projectId: string) {
  return useRecoilValue(previouslySelectedMeshAtom(projectId));
}
