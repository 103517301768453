// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVStreamlineDir, LCVStreamlineSeedType, LCVType } from '@luminarycloudinternal/lcvis';
import { deepEqual } from 'fast-equals';

import { RgbColor } from '../../../designSystem';
import { LcvModule } from '../../types';

import { LcvFilter } from './LcvFilter';
import { StreamlinesState } from './filterUtils';

/**
 * A filter for computing a streamlines
 */
export class LcvStreamlines extends LcvFilter {
  parent: LcvFilter;
  state: StreamlinesState;
  color: RgbColor | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    id: string,
    parent: LcvFilter,
    initialState: StreamlinesState,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'streamlines',
        `streamlines_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'streamlines',
      id,
    );

    this.parent = parent;
    this.state = initialState;

    this.lcv.connectFilters(sessionHandle, workspaceHandle, parent.handle, this.handle);

    this.updateParams();
  }

  /**
   * Pass the object params into LCVis
   */
  updateParams() {
    const {
      direction,
      field_name,
      max_length,
      seedPlacementParams,
      n_streamlines,
      use_chunked_url_fetch,
    } = this.state;

    switch (direction) {
      case 'Forward':
        this.setParam(
          'direction',
          LCVType.kLCVDataTypeUint,
          LCVStreamlineDir.kLCVStreamlineDirForward,
        );
        break;
      case 'Backward':
        this.setParam(
          'direction',
          LCVType.kLCVDataTypeUint,
          LCVStreamlineDir.kLCVStreamlineDirBackward,
        );
        break;
      default:
        this.setParam(
          'direction',
          LCVType.kLCVDataTypeUint,
          LCVStreamlineDir.kLCVStreamlineDirBoth,
        );
        break;
    }

    this.setParam('field_name', LCVType.kLCVDataTypeString, field_name);
    this.setParam('max_length', LCVType.kLCVDataTypeFloat, max_length);
    this.setParam('n_streamlines', LCVType.kLCVDataTypeUint, n_streamlines);

    if (seedPlacementParams?.typ === 'Rake') {
      this.setParam(
        'seed_type',
        LCVType.kLCVDataTypeUint,
        LCVStreamlineSeedType.kLCVStreamlineSeedTypeRake,
      );
      this.setParam(
        'rake_start',
        LCVType.kLCVDataTypeFloat3,
        [seedPlacementParams.start.x, seedPlacementParams.start.y, seedPlacementParams.start.z],
      );
      this.setParam(
        'rake_end',
        LCVType.kLCVDataTypeFloat3,
        [seedPlacementParams.end.x, seedPlacementParams.end.y, seedPlacementParams.end.z],
      );
    } else if (seedPlacementParams?.typ === 'Surface') {
      this.setParam(
        'seed_type',
        LCVType.kLCVDataTypeUint,
        LCVStreamlineSeedType.kLCVStreamlineSeedTypeSurface,
      );

      const surface_names = this.lcv.writeStringArray(
        this.sessionHandle,
        seedPlacementParams.surfaces,
      );

      this.setParam('surface_names', LCVType.kLCVDataTypeData1D, surface_names);
      this.setParam('offset', LCVType.kLCVDataTypeFloat, seedPlacementParams.offset);
      this.setParam(
        'project_on_surface',
        LCVType.kLCVDataTypeUint,
        seedPlacementParams.projectOnSurface,
      );
      this.setParam('sample_rate', LCVType.kLCVDataTypeUint, seedPlacementParams.sampleRate);

      this.lcv.release(this.sessionHandle, surface_names, 0);
    }
    // TODO(vis): Other seed types pending LCVis/backend implementation

    this.setParam('use_chunked_url_fetch', LCVType.kLCVDataTypeInt, use_chunked_url_fetch);
  }

  setState(newState: StreamlinesState) {
    if (deepEqual(this.state, newState)) {
      return;
    }
    this.state = newState;
    this.updateParams();
  }
}
