// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.

import { escape } from 'html-escaper';

import * as ProtoDescriptor from '../ProtoDescriptor';
import {
  getQuantityCond,
  getQuantitySize,
  getQuantityTags,
  getQuantityText,
  getQuantityUnit,
  quantities,
} from '../QuantityDescriptor';
import * as basepb from '../proto/base/base_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import * as entitygrouppb from '../proto/entitygroup/entitygroup_pb';
import * as feoutputpb from '../proto/frontend/output/output_pb';
import { OutputIncludes, OutputNode } from '../proto/frontend/output/output_pb';
import * as outputpb from '../proto/output/output_pb';
import { ReferenceValueType } from '../proto/output/reference_values_pb';
import { QuantityTag } from '../proto/quantity/quantity_options_pb';
import * as quantitypb from '../proto/quantity/quantity_pb';
import { EntityGroupData } from '../recoil/entityGroupState';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import { ParamScope } from './ParamScope';
import { newScalarAdVector } from './adUtils';
import assert from './assert';
import { findFarfield, getWallSurfacesWithTags } from './boundaryConditionUtils';
import { SelectOption } from './componentTypes/form';
import { UNIVERSAL_GAS_CONSTANT } from './constants';
import { EntityGroupMap } from './entityGroupMap';
import { expandGroupsExcludingTags, unwrapSurfaceIds } from './entityGroupUtils';
import { boldEscaped } from './html';
import { intersectSet } from './lang';
import { DEFAULT_GLOBAL_FRAME_ID, findBodyFrame, frameExists } from './motionDataUtils';
import { prefixNameGen, uniqueSequenceName } from './name';
import { getOutputCoefficient } from './outputUtils';
import { findParticleGroupById } from './particleGroupUtils';
import { createPhysicsScope, findPhysicsById, findPhysicsIndexById, getPhysicsId } from './physicsUtils';
import { getPorousSurfaces } from './porousModelUtils';
import { newNodeId } from './projectDataUtils';
import { isSimulationImplicitTime, isSimulationTransient } from './simulationUtils';
import { upperFirst, wordsToList } from './text';
import { mapDomainsToIds } from './volumeUtils';

type NodePropsCase = OutputNode['nodeProps']['case'];

const {
  GLOBAL_OUTPUT_TYPE,
  POINT_OUTPUT_TYPE,
  SURFACE_OUTPUT_TYPE,
  VOLUME_OUTPUT_TYPE,
  DERIVED_OUTPUT_TYPE,
} = feoutputpb.OutputNode_Type;
const {
  FORCE_DIRECTION_BODY_ORIENTATION_AND_FLOW_DIR,
  FORCE_DIRECTION_CUSTOM,
} = outputpb.ForceDirectionType;
const {
  TIME_AVERAGE,
  TIME_SERIES,
  TIME_AVERAGE_CAUCHY,
  TIME_SERIES_CAUCHY,
  PERCENT_MAX_DEV,
  PERCENT_MAX_DEV_SERIES,
  PERCENT_MAX_DEV_AVG,
  PERCENT_MAX_DEV_AVG_SERIES,
  TIME_TRAILING_AVG_SERIES,
} = outputpb.TimeAnalysisType;
const {
  CALCULATION_AGGREGATE,
  CALCULATION_DIFFERENCE,
  CALCULATION_PER_SURFACE,
} = feoutputpb.CalculationType;
const {
  SPACE_AREA_AVERAGING,
  SPACE_MASS_FLOW_AVERAGING,
  SPACE_NO_AVERAGING,
} = outputpb.SpaceAveragingType;
const { ACTUATOR_DISK } = simulationpb.ParticleGroupType;

type VectorComponentNodeTypes =
  | feoutputpb.PointProbeNode
  | feoutputpb.SurfaceAverageNode
  | feoutputpb.VolumeReductionNode

// A category of output nodes.
export interface OutputNodeCategory {
  name: string,
  nodePropsCase: NodePropsCase,
  choices: ProtoDescriptor.Choice[],
}

export const DIM3D = 3;

export const FRAME_NOT_FOUND_MESSAGE = 'The selected reference frame for this output ' +
  'does not exist in the current simulation settings.';

const OutputIncludesTypes = [
  OutputIncludes.OUTPUT_INCLUDE_BASE,
  OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT,
  OutputIncludes.OUTPUT_INCLUDE_TIME_AVERAGE,
  OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE,
  OutputIncludes.OUTPUT_INCLUDE_RESIDUAL,
  OutputIncludes.OUTPUT_INCLUDE_MAX_DEV,
];

export const VALID_OUTPUT_NODE_TYPES_FOR_ADJOINT = [
  SURFACE_OUTPUT_TYPE,
  VOLUME_OUTPUT_TYPE,
  DERIVED_OUTPUT_TYPE,
];

export const UNIDENTIFIED_OUTPUT_ID = 'OUTPUT NOT FOUND';
export const DERIVED_DEPENDENCY_SUFFIXES = new Map<string, OutputIncludes>([
  [' - Coefficient', OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT],
  [' - Average', OutputIncludes.OUTPUT_INCLUDE_TIME_AVERAGE],
  [' - Coefficient Average', OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE],
]);

function choiceText({ text, unit }: ProtoDescriptor.Quantity) {
  return `${text} ${unit && `(${unit})`}`;
}

function choiceForQuantity(
  quantity: ProtoDescriptor.Quantity,
  enumNumber: number,
): ProtoDescriptor.Choice {
  return {
    enumNumber,
    help: quantity.help,
    name: quantity.name,
    text: choiceText(quantity),
    data: quantity.quantityType,
  };
}

function choicesWithTag(tag: QuantityTag): ProtoDescriptor.Choice[] {
  return quantities.filter((item) => item.tags.includes(tag)).map(
    (quantity, idx): ProtoDescriptor.Choice => choiceForQuantity(quantity, idx),
  );
}

// Possible choices for point probe types
export const pointTypeChoices: OutputNodeCategory[] = [{
  name: 'pointProbe',
  nodePropsCase: 'pointProbe',
  choices: choicesWithTag(QuantityTag.TAG_POINT_PROBE),
}];

// Possible choices for volume types
export const volumeTypeChoices: OutputNodeCategory[] = [{
  name: 'volumeReductions',
  nodePropsCase: 'volumeReduction',
  choices: choicesWithTag(QuantityTag.TAG_ANALYZER_VOLUME),
}];

// Every item in the output list must have a unique id. They do not necessarily map 1-to-1
// to quantities so we cannot just use the QuantityType itself.
// Important: do not modify existing ids, otherwise this will break existing cases.
export const quantityTypeToEnumNumber = new Map<quantitypb.QuantityType, number>([
  [quantitypb.QuantityType.LIFT, 0],
  [quantitypb.QuantityType.DRAG, 1],
  [quantitypb.QuantityType.SIDEFORCE, 2],
  [quantitypb.QuantityType.PRESSURE_FORCE, 3],
  [quantitypb.QuantityType.FRICTION_FORCE, 4],
  [quantitypb.QuantityType.TOTAL_FORCE, 5],
  [quantitypb.QuantityType.DOWNFORCE, 6],
  [quantitypb.QuantityType.TOTAL_MOMENT, 7],
  [quantitypb.QuantityType.PITCHING_MOMENT, 8],
  [quantitypb.QuantityType.ROLLING_MOMENT, 9],
  [quantitypb.QuantityType.YAWING_MOMENT, 10],
  [quantitypb.QuantityType.DISK_TORQUE, 11],
  [quantitypb.QuantityType.DISK_THRUST, 12],
  [quantitypb.QuantityType.AREA, 13],
  [quantitypb.QuantityType.TEMPERATURE, 14],
  [quantitypb.QuantityType.DENSITY, 15],
  [quantitypb.QuantityType.PRESSURE, 16],
  [quantitypb.QuantityType.VELOCITY_MAGNITUDE, 17],
  [quantitypb.QuantityType.MACH, 18],
  [quantitypb.QuantityType.Y_PLUS, 19],
  [quantitypb.QuantityType.TOTAL_PRESSURE, 20],
  [quantitypb.QuantityType.TOTAL_TEMPERATURE, 21],
  [quantitypb.QuantityType.MASS_FLOW, 22],
  [quantitypb.QuantityType.ABS_MASS_FLOW, 23],
  [quantitypb.QuantityType.PRESSURE_DROP, 24],
  [quantitypb.QuantityType.TOTAL_PRESSURE_DROP, 25],
  [quantitypb.QuantityType.ENERGY_FLUX, 26],
  [quantitypb.QuantityType.VELOCITY, 27],
  [quantitypb.QuantityType.VISCOUS_DRAG, 28],
  [quantitypb.QuantityType.PRESSURE_DRAG, 29],
  [quantitypb.QuantityType.DISK_ROTATION_RATE, 30],
]);

export function findOutputNodeById(outputs: feoutputpb.OutputNodes, id: string) {
  return outputs.nodes.find((value) => value.id === id);
}

export function findOutputNodeByName(outputs: feoutputpb.OutputNodes, name: string) {
  return outputs.nodes.find((value) => value.name === name);
}

function getChoicesFromQuantities(
  quantityList: ProtoDescriptor.Quantity[],
  filterQuantities: (quantity: ProtoDescriptor.Quantity) => boolean,
) {
  return quantityList.filter(filterQuantities).map((quantity) => {
    const { name, quantityType } = quantity;
    const enumNumber = quantityTypeToEnumNumber.get(quantityType);
    // Make sure the quantity can be found in the hardcoded map.
    assert(enumNumber !== undefined, `${name}:${quantityType} not found in enum number map.`);
    return choiceForQuantity(quantity, enumNumber!);
  });
}

// Possible choices for surface types
const surfaceTypeChoices = ((): OutputNodeCategory[] => {
  // Create the available categories including the choices that they provide in the output
  // selection list. Each provided output gets a unique enum number.
  const forces: OutputNodeCategory = {
    name: 'forces',
    nodePropsCase: 'force',
    choices: getChoicesFromQuantities(
      quantities,
      (item) => (item.tags.includes(QuantityTag.TAG_ANALYZER_FORCES) &&
        !item.tags.includes(QuantityTag.TAG_COEFFICIENT)
      ),
    ),
  };
  const surfaceAverages: OutputNodeCategory = {
    name: 'averages',
    nodePropsCase: 'surfaceAverage',
    choices: getChoicesFromQuantities(
      quantities,
      (item) => (item.tags.includes(QuantityTag.TAG_ANALYZER_AVERAGE)),
    ),
  };
  return [forces, surfaceAverages];
})();

// Enum numbers for global types.
// Important: do not modify, otherwise this will break existing cases.
export const RESIDUAL_ENUM_NUMBER = 24;
export const INNER_ITER_ENUM_NUMBER = 25;

// Possible choices for global output types
export const globalTypeChoices = ((): OutputNodeCategory[] => {
  const residuals: OutputNodeCategory = {
    name: 'solution_residuals',
    nodePropsCase: 'residual',
    choices: [{
      enumNumber: RESIDUAL_ENUM_NUMBER,
      help: '',
      name: 'solution_residuals',
      text: 'Solution Residuals',
    }],
  };
  const innerIteration = quantities.find(
    (quantity) => quantity.quantityType === quantitypb.QuantityType.INNER_ITERATION_COUNT,
  );
  const basic: OutputNodeCategory = {
    name: 'basic',
    nodePropsCase: 'basic',
    choices: [{
      enumNumber: INNER_ITER_ENUM_NUMBER,
      help: innerIteration!.help,
      name: innerIteration!.name,
      text: innerIteration!.text,
      data: innerIteration!.quantityType,
    }],
  };
  return [residuals, basic];
})();

// Returns the possible choices depending on the output type
export function choicesForType(type: feoutputpb.OutputNode_Type) {
  switch (type) {
    case GLOBAL_OUTPUT_TYPE:
      return globalTypeChoices;
    case SURFACE_OUTPUT_TYPE:
      return surfaceTypeChoices;
    case POINT_OUTPUT_TYPE:
      return pointTypeChoices;
    case VOLUME_OUTPUT_TYPE:
      return volumeTypeChoices;
    default:
      throw Error('Undefined output type.');
  }
}

// Concatenated list of outputs for a specific type (based on current state of the params).
export function nodeSelectorChoices(
  type: feoutputpb.OutputNode_Type,
  paramScope: ParamScope,
  quantity?: quantitypb.QuantityType,
): ProtoDescriptor.Choice[] {
  const entries: ProtoDescriptor.Choice[] = [];
  choicesForType(type).forEach((cat: OutputNodeCategory) => {
    cat.choices.forEach((entry: ProtoDescriptor.Choice) => {
      if (entry.data) {
        const cond = getQuantityCond(entry.data);
        if (cond && entry.data !== quantity && !paramScope.isEnabled(cond)) {
          return;
        }
      }
      entries.push(entry);
    });
  });
  return entries;
}

// Return the category of a choice with the provided enumNumber and type.
export function getNodeCategory(
  type: feoutputpb.OutputNode_Type,
  enumNumber: number,
): OutputNodeCategory {
  const found = choicesForType(type).find((cat: OutputNodeCategory) => cat.choices.find(
    (choice) => choice.enumNumber === enumNumber,
  ));
  if (!found) {
    throw Error('Choice not found');
  }
  return found;
}

// Return the choice that has the provided enumNumber and type.
export function getNodeEntry(
  type: feoutputpb.OutputNode_Type,
  enumNumber: number,
): ProtoDescriptor.Choice {
  let found: ProtoDescriptor.Choice | undefined;
  choicesForType(type).some((cat) => {
    found = cat.choices.find((choice) => choice.enumNumber === enumNumber);
    return found;
  });
  if (!found) {
    throw Error(`Choice not found ${type} ${enumNumber}`);
  }
  return found;
}

export function defaultName(output: OutputNode): string {
  if (!output.type) {
    return '<New Output>';
  }
  switch (output.nodeProps.case) {
    case 'residual':
      return 'Solution Residuals';
    case 'derived':
      return 'Custom Output';
    case 'basic':
    case 'force':
    case 'surfaceAverage':
      return getQuantityText(output.nodeProps.value.quantityType);
    case 'pointProbe':
      return `Point ${getQuantityText(output.nodeProps.value.quantityType)}`;
    case 'volumeReduction':
      return `Volume ${getQuantityText(output.nodeProps.value.quantityType)}`;
    default:
      return '';
  }
}

// Output node categories that can be used as stopping condition
export const STOP_COND_OUTPUT_NODES: NodePropsCase[] = [
  'force',
  'surfaceAverage',
  'residual',
  'pointProbe',
  'volumeReduction',
  'derived',
];

export const RESIDUALS_FLUID = [
  quantitypb.QuantityType.RESIDUAL_DENSITY,
  quantitypb.QuantityType.RESIDUAL_X_MOMENTUM,
  quantitypb.QuantityType.RESIDUAL_Y_MOMENTUM,
  quantitypb.QuantityType.RESIDUAL_Z_MOMENTUM,
  quantitypb.QuantityType.RESIDUAL_ENERGY,
  quantitypb.QuantityType.RESIDUAL_TKE,
  quantitypb.QuantityType.RESIDUAL_SA_VARIABLE,
  quantitypb.QuantityType.RESIDUAL_RE_THETA,
  quantitypb.QuantityType.RESIDUAL_GAMMA,
  quantitypb.QuantityType.RESIDUAL_OMEGA,
  quantitypb.QuantityType.RESIDUAL_N_TILDE,
];

export const RESIDUALS_HEAT = [
  quantitypb.QuantityType.RESIDUAL_ENERGY,
];

export const ALL_RESIDUALS = [...new Set([...RESIDUALS_FLUID, ...RESIDUALS_HEAT])];

export function isIncluded(outputNode: OutputNode, outputInclude: OutputIncludes): boolean {
  return !!outputNode.include[outputInclude];
}

function deprecatedManualMessage(force: feoutputpb.ForceNode, tags: QuantityTag[]): string {
  const isMoment = tags.includes(QuantityTag.TAG_MOMENT);
  const vector = isMoment ? 'moment axis' : 'force';
  const newQuantity = isMoment ? 'Moment' : 'Force';
  const message = `The manual ${vector} direction setting for ` +
    `${getQuantityText(force.quantityType!)} has been deprecated. Please ` +
    `select the generic ${newQuantity} quantity for this output. Existing output ` +
    `properties will be preserved.`;
  return message;
}

export function setIncluded(
  outputNode: OutputNode,
  outputInclude: OutputIncludes,
  included: boolean,
) {
  outputNode.include[outputInclude] = included;
}

function deprecatedManual(force: feoutputpb.ForceNode, tags: QuantityTag[]): boolean {
  return (
    tags.includes(QuantityTag.TAG_AUTO_DIRECTION) &&
    force.props?.forceDirType === FORCE_DIRECTION_CUSTOM
  );
}

export function needsFarfield(tags: QuantityTag[]): boolean {
  return (
    tags.includes(QuantityTag.TAG_AUTO_DIRECTION) &&
    !tags.includes(QuantityTag.TAG_ACTUATOR_DISK) &&
    !tags.includes(QuantityTag.TAG_MOMENT)
  );
}

/**
 * Determines whether to use the physics id that the user selected or the default one. We use a
 * default id (usually the id of the first physics) if the physicsId is not defined in the
 * residual node and we only have one physics.
 * This is the case for simulation that have been created prior the addition of the
 * new client params.
 * @param residualNode
 * @param simParam Simulation param
 * @returns true if the default physics id should be used
 */
export function defaultPhysicsId(
  residualNode: feoutputpb.ResidualNode,
  simParam: simulationpb.SimulationParam,
): boolean {
  return !residualNode.physicsId && simParam.physics.length === 1;
}

/**
 * Returns the default physics id
 * @param simParam simulation param
 * @returns the default physics id
 */
export function getDefaultPhysicsId(simParam: simulationpb.SimulationParam) {
  if (simParam.physics[0]) {
    return getPhysicsId(simParam.physics[0]);
  }
  return undefined;
}

export function getOutputNodeWarnings(
  outputNode: OutputNode,
  outputNodes: feoutputpb.OutputNodes,
  param: simulationpb.SimulationParam,
  entityGroupData: EntityGroupData,
  paramScope: ParamScope,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
  refValType?: ReferenceValueType,
  local = false,
  ancestors: string[] = [],
): string[] {
  const warnings: string[] = [];

  const inSurfacesIn = expandGroupsExcludingTags(entityGroupData, outputNode.inSurfaces);
  const outSurfacesIn = expandGroupsExcludingTags(entityGroupData, outputNode.outSurfaces);
  const wallSurfaces = getWallSurfacesWithTags(param, geometryTags);

  const isDisk = (surface: string) => (
    findParticleGroupById(param, surface)?.particleGroupType === ACTUATOR_DISK
  );

  const prefix = local ? 'Output' : `Output <b>${escape(outputNode.name)}</b>`;

  const farfield = findFarfield(param);
  if (
    !farfield &&
    refValType === ReferenceValueType.REFERENCE_FARFIELD_VALUES &&
    (
      isIncluded(outputNode, OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT) ||
      isIncluded(outputNode, OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE)
    )
  ) {
    warnings.push(`${prefix} includes coefficients that cannot be computed. ` +
      `Far Field Values selected for reference values but no far field exists.`);
  }

  let quantity = quantitypb.QuantityType.INVALID_QUANTITY_TYPE;

  switch (outputNode.nodeProps.case) {
    case 'force': {
      const force = outputNode.nodeProps.value;
      quantity = force.quantityType;
      const tags = getQuantityTags(quantity);
      const inSurfaces = geometryTags.unrollFaceTags(inSurfacesIn);

      if (!inSurfaces.length) {
        warnings.push(`${prefix} has no surfaces.`);
      }
      if (deprecatedManual(force, tags)) {
        warnings.push(deprecatedManualMessage(force, tags));
      }
      if (tags.includes(QuantityTag.TAG_ACTUATOR_DISK)) {
        if (!outputNode.inSurfaces.every(isDisk)) {
          warnings.push(`${prefix} is an actuator disk output that includes non-disk surfaces.`);
        }
      }
      const frameId = outputNode.frameId;
      if (frameId && frameId !== DEFAULT_GLOBAL_FRAME_ID && !frameExists(param, frameId)) {
        warnings.push(`${prefix} was assigned a reference frame that ` +
          `does not exist in the current simulation settings.`);
      }
      if (!farfield && needsFarfield(tags)) {
        warnings.push(`${getQuantityText(quantity)} can only be computed when there ` +
          `is a far-field boundary condition`);
      }
      break;
    }
    case 'surfaceAverage': {
      const inSurfaces = geometryTags.unrollFaceTags(inSurfacesIn);
      if (!inSurfaces.length) {
        warnings.push(`${prefix} has no surfaces.`);
      }
      quantity = outputNode.nodeProps.value.quantityType;
      if (getQuantityTags(quantity).includes(QuantityTag.TAG_ACTUATOR_DISK)) {
        if (!outputNode.inSurfaces.every(isDisk)) {
          warnings.push(`${prefix} is an actuator disk output that includes non-disk surfaces.`);
        }
      }
      const surfaceAverage = outputNode.nodeProps.value;
      quantity = surfaceAverage.quantityType;
      if (
        surfaceAverage.props?.averagingType === SPACE_MASS_FLOW_AVERAGING ||
        [
          quantitypb.QuantityType.MASS_FLOW,
          quantitypb.QuantityType.ABS_MASS_FLOW,
        ].includes(quantity)
      ) {
        const outSurfaces = geometryTags.unrollFaceTags(outSurfacesIn);
        if ([...inSurfaces, ...outSurfaces].some((surface) => wallSurfaces.includes(surface))) {
          warnings.push(
            `${prefix}: invalid quantity or averaging selection for Wall surface. ` +
            `Mass flow cannot be computed on a wall.`,
          );
        }
      }

      break;
    }
    case 'pointProbe': {
      quantity = outputNode.nodeProps.value.quantityType;
      if (!inSurfacesIn.length) {
        warnings.push(`${prefix} has no monitor points.`);
      }
      break;
    }
    case 'volumeReduction': {
      quantity = outputNode.nodeProps.value.quantityType;
      const inSurfaces = geometryTags.unrollBodyTags(inSurfacesIn);
      if (!inSurfaces.length) {
        warnings.push(`${prefix} has no volumes.`);
      }
      break;
    }
    case 'basic': {
      quantity = outputNode.nodeProps.value.quantityType;
      if (
        quantity === quantitypb.QuantityType.INNER_ITERATION_COUNT &&
        !(isSimulationTransient(param) && isSimulationImplicitTime(param))
      ) {
        warnings.push(`${prefix} can only be computed for time implicit transient simulations.`);
      }
      break;
    }
    case 'derived': {
      const derived = outputNode.nodeProps.value;
      const { elements, errors } = derived;
      const dependencies = elements.filter(
        (element) => (element.elementType.case === 'dependency'),
      ).map((element) => (element.elementType.value)) as feoutputpb.DerivedNodeDependency[];
      ancestors.push(outputNode.id);
      // Only show one node warning for dependencies to avoid displaying the
      // same warning multiple times for separate dependencies.
      let dependencyWarning = false;
      dependencies.forEach((dep) => {
        if (!dependencyWarning) {
          const { id, include } = dep;
          if (ancestors.includes(id)) {
            warnings.push(`${prefix} has cyclic dependencies.`);
            dependencyWarning = true;
            return;
          }
          const node = findOutputNodeById(outputNodes, id);
          if (node) {
            const dependencyPropsCase = node.nodeProps.case;
            if (
              !dependencyPropsCase ||
              !['force', 'surfaceAverage', 'pointProbe', 'derived', 'volumeReduction'].includes(
                dependencyPropsCase,
              )
            ) {
              warnings.push(`Expression can only include force, surface average, ` +
                `point probe, volume, or custom outputs. ${prefix} includes ${node.name}, ` +
                `which has an incompatible type.`);
              dependencyWarning = true;
              return;
            }
            if (
              dependencyPropsCase !== 'force' ||
              getQuantityTags(node.nodeProps.value.quantityType).includes(
                QuantityTag.TAG_ACTUATOR_DISK,
              )
            ) {
              if (
                include === OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT ||
                include === OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE
              ) {
                warnings.push(`${prefix} includes invalid coefficient dependencies. No ` +
                  `coefficient exists for ${node.name}.`);
                dependencyWarning = true;
                return;
              }
            }
            if (node.calcType === CALCULATION_PER_SURFACE && node.inSurfaces.length > 1) {
              const entity = (dependencyPropsCase === 'pointProbe') ? 'monitor point' : 'surface';
              const type = dependencyPropsCase === 'pointProbe' ? 'Point' : 'Per Surface';
              warnings.push(`${prefix} includes a ${type} output that includes ` +
                `multiple ${entity}s. There can only be one ${entity} for ${type} dependencies ` +
                `included in a custom output expression.`);
              dependencyWarning = true;
              return;
            }
            const dependencyWarnings = getOutputNodeWarnings(
              node,
              outputNodes,
              param,
              entityGroupData,
              paramScope,
              staticVolumes,
              geometryTags,
              refValType,
              local,
              [...ancestors],
            );
            if (dependencyWarnings.some((str) => (str.includes('cyclic dependencies')))) {
              warnings.push(`${prefix} has cyclic dependencies.`);
              dependencyWarning = true;
              return;
            }
            if (dependencyWarnings.length) {
              warnings.push(`${prefix} depends on invalid outputs.`);
              dependencyWarning = true;
            }
          } else {
            warnings.push(`${prefix} depends on undefined outputs.`);
            dependencyWarning = true;
          }
        }
      });
      warnings.push(...errors);
      break;
    }
    case 'residual': {
      const residual = outputNode.nodeProps.value;
      const selectedId = residual.physicsId;
      if (!selectedId && !defaultPhysicsId(residual, param)) {
        warnings.push(`${prefix} does not have a physics selected.`);
      }
      if (selectedId && !findPhysicsById(param, selectedId)) {
        warnings.push(`${prefix} depends on physics that does not exist in the current setup.`);
      }
      break;
    }
    default: {
      // no warnings
    }
  }

  if (!outputNode.type) {
    warnings.push(`${prefix} has no type.`);
  }

  let groupNotFound = outputNode.inSurfaces?.some(
    (id) => !entityGroupData.groupMap.has(id),
  );
  if (outputNode.calcType === CALCULATION_DIFFERENCE) {
    groupNotFound = (
      groupNotFound ||
      outputNode.outSurfaces?.some((id) => !entityGroupData.groupMap.has(id))
    );
  }
  if (groupNotFound) {
    const entities = outputNode.nodeProps.case === 'pointProbe' ? 'point probes' : 'surface groups';
    warnings.push(
      `${prefix} includes ${entities} that could not be found in the current simulation`,
    );
  }
  if (param.general?.floatType === simulationpb.FloatType.ADA1D &&
    outputNode.id === param.adjoint?.adjointOutput?.id) {
    const { outputList } = createOutputs(
      outputNode,
      outputNodes,
      param,
      entityGroupData,
      true,
      false,
    );
    if ((outputList.length || 0) > 1) {
      // Trailing averages cannot be used without the instantaneous value, hence this check also
      // prevents their use (which the adjoint solver does not support).
      warnings.push(`${outputNode.name} can only define one value for the adjoint solver. ` +
        `For example, it cannot use both 'base' and 'coefficient' values. Therefore, trailing ` +
        `averages are also not supported. Please define another output for monitoring those ` +
        `values.`);
    } else if (!outputList.length) {
      warnings.push(`${outputNode.name} does not define any value for the adjoint solver.`);
    }
  }

  return warnings;
}

// Used to warn users that forces on non-wall/disk/porous surfaces many not be available for older
// simulations.
export function getForceNotAvailableWarnings(
  outputNode: OutputNode,
  outputNodes: feoutputpb.OutputNodes,
  param: simulationpb.SimulationParam,
  entityGroupData: EntityGroupData,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
  ancestors: string[] = [],
): string[] {
  const warnings: string[] = [];

  const inSurfacesIn = expandGroupsExcludingTags(entityGroupData, outputNode.inSurfaces);
  const wallSurfaces = getWallSurfacesWithTags(param, geometryTags);
  const porousSurfaces = getPorousSurfaces(param, staticVolumes, geometryTags);

  const isDisk = (surface: string) => (
    findParticleGroupById(param, surface)?.particleGroupType === ACTUATOR_DISK
  );

  if (outputNode.nodeProps.case === 'force') {
    const inSurfaces = geometryTags.unrollFaceTags(inSurfacesIn);

    if (!inSurfaces.every((surface) => (
      wallSurfaces.includes(surface) || isDisk(surface) || porousSurfaces.includes(surface)
    ))) {
      warnings.push(
        `Forces on surfaces that are not walls, actuator disks, or porous volume boundaries, ` +
        `are not available in older simulations.`,
      );
    }
  }
  if (outputNode.nodeProps.case === 'derived') {
    const derived = outputNode.nodeProps.value;
    const { elements } = derived;
    const dependencies = elements.filter(
      (element) => (element.elementType.case === 'dependency'),
    ).map((element) => (element.elementType.value)) as feoutputpb.DerivedNodeDependency[];
    ancestors.push(outputNode.id);
    let dependencyWarning = false;
    dependencies.forEach((dep) => {
      if (!dependencyWarning) {
        const { id } = dep;
        // Avoid infinite loop in case of cyclic dependencies.
        if (ancestors.includes(id)) {
          dependencyWarning = true;
          return;
        }
        const node = findOutputNodeById(outputNodes, id);
        if (node) {
          const dependencyWarnings = getForceNotAvailableWarnings(
            node,
            outputNodes,
            param,
            entityGroupData,
            staticVolumes,
            geometryTags,
            [...ancestors],
          );
          dependencyWarnings.forEach((warning) => {
            warnings.push(warning);
          });
          dependencyWarning = dependencyWarnings.length > 0;
        }
      }
    });
  }
  return warnings;
}

export function includePrefix(include: OutputIncludes) {
  switch (include) {
    case OutputIncludes.OUTPUT_INCLUDE_BASE: return 'base_';
    case OutputIncludes.OUTPUT_INCLUDE_TIME_AVERAGE: return 'avg_';
    case OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT: return 'coef_';
    case OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE: return 'coef_avg_';
    case OutputIncludes.OUTPUT_INCLUDE_RESIDUAL: return 'cauchy_';
    case OutputIncludes.OUTPUT_INCLUDE_MAX_DEV: return 'max_dev_';
    default:
      throw Error('Unsupported include option for include prefix.');
  }
}

function includedOutputs(
  outputNode: OutputNode,
  outputTemplate: outputpb.Output,
  entityGroupData: EntityGroupData,
  prefix: string,
  suffix: string,
  includeConvMonitor: boolean,
  series: boolean,
  derivedNodeDependency: boolean,
): { outputList: outputpb.Output[], staticOutputIndex: number[] } {
  const outputList: outputpb.Output[] = [];
  const staticOutputIndex: number[] = [];
  const addOutputsForCalcType = (node: outputpb.Output, include: number) => {
    switch (outputNode.calcType) {
      case CALCULATION_AGGREGATE:
        // Tags are unrolled by the backend.
        node.inSurfaces = expandGroupsExcludingTags(entityGroupData, outputNode.inSurfaces);
        outputList.push(node);
        staticOutputIndex.push(OutputIncludesTypes.indexOf(include));
        break;
      case CALCULATION_PER_SURFACE:
        // Create one output per surface
        outputNode.inSurfaces
          .filter((surface) => entityGroupData.groupMap.has(surface))
          .forEach((surface, idx) => {
            const outputClone = node.clone();
            // Do not unroll tags. The backend will do it for us.
            if (
              entityGroupData.groupMap.get(surface).entityType !==
              entitygrouppb.EntityType.TAG_CONTAINER
            ) {
              outputClone.inSurfaces = Array.from(entityGroupData.leafMap.get(surface)!);
            }
            outputClone.inSurfaces = expandGroupsExcludingTags(entityGroupData, [surface]);
            outputClone.shortName = entityGroupData.groupMap.get(surface)!.name.trim();
            // Only add a suffix if there are multiple entities.  This allows custom expressions
            // to be used for per surface outputs with only one entity. See LC-22166.
            const id_suffix = outputNode.inSurfaces.length > 1 ? `_surface_id_${surface}` : '';
            outputClone.id = `${outputClone.id}${id_suffix}`;
            outputList.push(outputClone);
            staticOutputIndex.push(
              outputNode.inSurfaces.length * OutputIncludesTypes.indexOf(include) + idx,
            );
          });
        break;
      case CALCULATION_DIFFERENCE: {
        // Use a custom output to compute the difference
        // Create dependencies
        const node1 = node.clone();
        node1.id = newNodeId();
        // Tags are unrolled by the backend.
        node1.inSurfaces = expandGroupsExcludingTags(entityGroupData, outputNode.inSurfaces);
        node1.timeAnalysis = TIME_SERIES;
        const node2 = node.clone();
        node2.id = newNodeId();
        // Tags are unrolled by the backend.
        node2.inSurfaces = expandGroupsExcludingTags(entityGroupData, outputNode.outSurfaces);
        node2.timeAnalysis = TIME_SERIES;
        // Create derived properties
        const derived = new outputpb.DerivedType();
        derived.expression = `${node1.id}-${node2.id}`;
        derived.dependencies = [node1, node2];
        const outputClone = node.clone();
        outputClone.outputProperties = { case: 'derivedProperties', value: derived };
        outputList.push(outputClone);
        staticOutputIndex.push(OutputIncludesTypes.indexOf(include));
      }
        break;
      default:
        throw Error('invalid calculation type.');
    }
  };
  Object.entries(outputNode.include).forEach(([outputIncludeStr, included]) => {
    if (included) {
      const outputInclude = parseInt(outputIncludeStr, 10);
      switch (outputInclude) {
        case OutputIncludes.OUTPUT_INCLUDE_INNER:
          // Nothing needs to be done here because this include is used upstream to turn on the
          // innerIters boolean of outputTemplate, there will be other "includes" in the map.
          break;
        case OutputIncludes.OUTPUT_INCLUDE_BASE: {
          const newOutput = outputTemplate.clone();
          newOutput.id = `${includePrefix(outputInclude)}${outputNode.id}`;
          newOutput.name = suffix.length > 0 ? `${prefix} ${suffix}` : prefix;
          newOutput.avgIters = 1;
          addOutputsForCalcType(newOutput, outputInclude);
          break;
        }
        case OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT: {
          const newOutput = getOutputCoefficient(outputTemplate);
          newOutput.id = `${includePrefix(outputInclude)}${outputNode.id}`;
          newOutput.name = `${prefix} - Coefficient`;
          newOutput.avgIters = 1;
          addOutputsForCalcType(newOutput, outputInclude);
          break;
        }
        case OutputIncludes.OUTPUT_INCLUDE_TIME_AVERAGE:
          if (derivedNodeDependency || isIncluded(outputNode, OutputIncludes.OUTPUT_INCLUDE_BASE)) {
            const newOutput = outputTemplate.clone();
            newOutput.id = `${includePrefix(outputInclude)}${outputNode.id}`;
            newOutput.name = suffix.length > 0 ?
              `${prefix} ${suffix} - Average` : `${prefix} - Average`;
            // Ensure average iterations >= 1 for backward compatibility.
            newOutput.avgIters = Math.max(outputNode.trailAvgIters, 1);
            if (series) {
              newOutput.timeAnalysis = TIME_TRAILING_AVG_SERIES;
            }
            addOutputsForCalcType(newOutput, outputInclude);
          }
          break;
        case OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE:
          if (
            derivedNodeDependency ||
            isIncluded(outputNode, OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT)
          ) {
            const newOutput = getOutputCoefficient(outputTemplate);
            newOutput.id = `${includePrefix(outputInclude)}${outputNode.id}`;
            newOutput.name = `${prefix} Coefficient - Average`;
            // Ensure average iterations >= 1 for backward compatibility.
            newOutput.avgIters = Math.max(outputNode.trailAvgIters, 1);
            if (series) {
              newOutput.timeAnalysis = TIME_TRAILING_AVG_SERIES;
            }
            addOutputsForCalcType(newOutput, outputInclude);
          }
          break;
        case OutputIncludes.OUTPUT_INCLUDE_RESIDUAL:
          if (includeConvMonitor) {
            const newOutput = outputTemplate.clone();
            newOutput.id = `${includePrefix(outputInclude)}${outputNode.id}`;
            newOutput.name = `${prefix} Residual`;
            newOutput.timeAnalysis = series ? TIME_SERIES_CAUCHY : TIME_AVERAGE_CAUCHY;
            addOutputsForCalcType(newOutput, outputInclude);
          }
          break;
        case OutputIncludes.OUTPUT_INCLUDE_MAX_DEV:
          if (includeConvMonitor) {
            const newOutput = outputTemplate.clone();
            newOutput.id = `${includePrefix(outputInclude)}${outputNode.id}`;
            newOutput.name = `${prefix} (%) - Max Deviation`;
            if (series) {
              newOutput.analysisIters = outputTemplate.analysisIters + 1;
            }
            if (outputTemplate.avgIters === 1) {
              newOutput.timeAnalysis = series ? PERCENT_MAX_DEV_SERIES : PERCENT_MAX_DEV;
            } else {
              newOutput.timeAnalysis = series ? PERCENT_MAX_DEV_AVG_SERIES : PERCENT_MAX_DEV_AVG;
            }
            addOutputsForCalcType(newOutput, outputInclude);
          }
          break;
        default:
          throw Error('Undefined output include type for force output node');
      }
    }
  });
  return { outputList, staticOutputIndex };
}

export function componentSuffix(comp: basepb.Vector3Component) {
  switch (comp) {
    case basepb.Vector3Component.VECTOR_3_COMPONENT_X:
      return 'X';
    case basepb.Vector3Component.VECTOR_3_COMPONENT_Y:
      return 'Y';
    case basepb.Vector3Component.VECTOR_3_COMPONENT_Z:
      return 'Z';
    default:
      return '';
  }
}

export function generateExpression(elements: feoutputpb.ExpressionElement[]): string {
  // Instead of disabling a derived output with blank expression, we interpret it as 0
  if (
    elements.length === 0 ||
    (
      elements.length === 1 &&
      elements[0].elementType.case === 'substring' &&
      !elements[0].elementType.value
    )
  ) {
    return '0';
  }
  let expression = '';
  elements.forEach((element) => {
    switch (element.elementType.case) {
      case 'dependency': {
        const dep = element.elementType.value;
        const include = dep.include!;
        const id = dep.id;
        expression += `${includePrefix(include)}${id}`;
      }
        break;
      case 'substring':
        expression += `${element.elementType.value}`;
        break;
      case undefined:
      default:
        throw Error('Unrecognized expression element type case.');
    }
  });
  return expression;
}

// Create output requests based on the state of the output node.
export function createOutputs(
  outputNode: OutputNode,
  outputNodes: feoutputpb.OutputNodes,
  simParam: simulationpb.SimulationParam,
  entityGroupData: EntityGroupData,
  includeConvMonitor: boolean,
  series: boolean,
  ancestors: string[] = [],
): {
  // outputList based on the state of the outputNode
  outputList: outputpb.Output[],
  // Array containing a static index (i.e. a index that is independent of the state of the
  // node) for each possible output defined by the node.
  staticOutputIndex: number[]
} {
  const createDependencies = (
    elements: feoutputpb.ExpressionElement[],
  ): outputpb.Output[] => {
    const dependencies = elements.filter(
      (element) => (element.elementType.case === 'dependency'),
    ).map((element) => (element.elementType.value)) as feoutputpb.DerivedNodeDependency[];
    const outputDeps: outputpb.Output[] = [];
    dependencies.forEach((dependency) => {
      const id = dependency.id;
      const include = dependency.include;
      if (!id) {
        throw Error('Derived output dependency has empty ID');
      }
      if (ancestors.includes(id)) {
        throw Error('Derived output includes ancestor as dependency');
      }
      const node = findOutputNodeById(outputNodes, id);
      if (node) {
        const nodeCopy = node.clone();
        nodeCopy.include = {};
        setIncluded(nodeCopy, include, true);
        const { outputList } = createOutputs(
          nodeCopy,
          outputNodes,
          simParam,
          entityGroupData,
          false,
          true,
          [...ancestors],
        );
        outputDeps.push(outputList[0]);
      } else {
        console.warn(`Output ID not found: ${id}`);
      }
    });
    return outputDeps;
  };

  const outputFromNode = (node: OutputNode): outputpb.Output => {
    const output = new outputpb.Output({
      timeAnalysis: series ? TIME_SERIES : TIME_AVERAGE,
      id: node.id,
      avgIters: Math.max(node.averageIters, 1),
      analysisIters: Math.max(node.analysisIters, 1),
    });
    const frameId = node.frameId;
    if (frameId !== DEFAULT_GLOBAL_FRAME_ID) {
      output.frameId = frameId;
    }
    if (isIncluded(node, OutputIncludes.OUTPUT_INCLUDE_INNER)) {
      output.innerIters = true;
    }
    return output;
  };
  const outputTemplate = outputFromNode(outputNode);

  const getIncludedOutputs = (props: any) => {
    const quantity = props.quantityType;
    const component = props.vectorComponent;
    outputTemplate.vectorComponent = component;
    const suffix = getQuantityUnit(quantity) ? `(${getQuantityUnit(quantity)})` : '';
    const prefix = component === basepb.Vector3Component.VECTOR_3_COMPONENT_INVALID ?
      getQuantityText(quantity) : `${getQuantityText(quantity)} ${componentSuffix(component)}`;
    outputTemplate.quantity = quantity;
    return includedOutputs(
      outputNode,
      outputTemplate,
      entityGroupData,
      prefix,
      suffix,
      includeConvMonitor,
      series,
      !!ancestors.length,
    );
  };

  const outputList: outputpb.Output[] = [];
  const staticOutputIndex: number[] = [];

  if (outputNode.type) {
    switch (outputNode.nodeProps.case) {
      case 'force': {
        const forceNode = outputNode.nodeProps.value;
        const quantity = forceNode.quantityType;
        const prefix = getQuantityText(quantity);
        const suffix = getQuantityUnit(quantity) ? `(${getQuantityUnit(quantity)})` : '';
        outputTemplate.quantity = forceNode.quantityType;
        if (forceNode.props) {
          outputTemplate.outputProperties = { case: 'forceProperties', value: forceNode.props };
        }
        return includedOutputs(
          outputNode,
          outputTemplate,
          entityGroupData,
          prefix,
          suffix,
          includeConvMonitor,
          series,
          !!ancestors.length,
        );
      }
      case 'residual': {
        const residualNode = outputNode.nodeProps.value;

        // temporarily, if the params exist, then get the correct physics index for the selected
        // physics ID
        // if no sim param, default to the 0th index for single physics compatibility
        // this is a workaround for the JobTable, because it calls this function without
        // access to the simParam for each job
        if (residualNode.props) {
          outputTemplate.outputProperties = {
            case: 'residualProperties',
            value: residualNode.props,
          };
          const foundIndex = simParam && findPhysicsIndexById(simParam, residualNode.physicsId);
          const physicsIndex = foundIndex && foundIndex !== -1 ? foundIndex : 0;
          outputTemplate.outputProperties.value.physicsIndex = physicsIndex;
        }

        Object.entries(residualNode.resEnabled).forEach(([resStr, value], idx) => {
          if (value) {
            const res = parseInt(resStr, 10);
            const newOutput = outputTemplate.clone();
            newOutput.quantity = res;
            newOutput.name = `${getQuantityText(res)} Residual`;
            outputList.push(newOutput);
            staticOutputIndex.push(idx);
          }
        });
        return { outputList, staticOutputIndex };
      }
      case 'surfaceAverage': {
        const surfaceAverageNode = outputNode.nodeProps.value;
        if (surfaceAverageNode.props) {
          outputTemplate.outputProperties = {
            case: 'surfaceAverageProperties',
            value: surfaceAverageNode.props,
          };
          if (surfaceAverageNode.quantityType === quantitypb.QuantityType.PRESSURE_DROP) {
            outputNode.calcType = feoutputpb.CalculationType.CALCULATION_DIFFERENCE;
            surfaceAverageNode.quantityType = quantitypb.QuantityType.PRESSURE;
          }
          if (surfaceAverageNode.quantityType === quantitypb.QuantityType.TOTAL_PRESSURE_DROP) {
            outputNode.calcType = feoutputpb.CalculationType.CALCULATION_DIFFERENCE;
            surfaceAverageNode.quantityType = quantitypb.QuantityType.TOTAL_PRESSURE;
          }
        }
        return getIncludedOutputs(surfaceAverageNode);
      }
      case 'pointProbe': {
        const pointProbeNode = outputNode.nodeProps.value;
        if (pointProbeNode.props) {
          outputTemplate.outputProperties = {
            case: 'probeProperties',
            value: pointProbeNode.props,
          };
        }
        return getIncludedOutputs(pointProbeNode);
      }
      case 'volumeReduction': {
        const volumeReductionNode = outputNode.nodeProps.value;
        if (volumeReductionNode.props) {
          outputTemplate.outputProperties = {
            case: 'volumeReductionProperties',
            value: volumeReductionNode.props,
          };
        }
        return getIncludedOutputs(volumeReductionNode);
      }
      case 'basic': {
        const basicNode = outputNode.nodeProps.value;
        const quantity = basicNode.quantityType;
        if (basicNode.props) {
          outputTemplate.outputProperties = {
            case: 'basicProperties',
            value: basicNode.props,
          };
        }
        outputTemplate.quantity = quantity;
        outputTemplate.name = getQuantityText(quantity);
        outputTemplate.shortName = '';
        staticOutputIndex.push(0);
        outputList.push(outputTemplate.clone());
        return { outputList, staticOutputIndex };
      }
      case 'derived': {
        const derivedNode = outputNode.nodeProps.value;
        ancestors.push(outputNode.id);
        const { elements } = derivedNode;
        outputTemplate.outputProperties = {
          case: 'derivedProperties',
          value: new outputpb.DerivedType({
            expression: generateExpression(elements),
            dependencies: createDependencies(elements),
          }),
        };
        return includedOutputs(
          outputNode,
          outputTemplate,
          entityGroupData,
          outputNode.name,
          '',
          includeConvMonitor,
          series,
          true,
        );
      }
      default:
        throw Error('Undefined node type case.');
    }
  }
  return { outputList, staticOutputIndex };
}

/**
 * Get the list of available residuals for the parameter and physics
 * @param simParam simulation params
 * @param paramScope param scope
 * @param physicsId id of the physics
 * @param experimentConfig enabled experiment feature flags
 * @returns list of available residual quantities
 */
export function getAvailableResiduals(
  simParam: simulationpb.SimulationParam,
  physicsId: string,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  let residuals: quantitypb.QuantityType[] = [];
  const physics = findPhysicsById(simParam, physicsId);
  if (physics) {
    switch (physics.params.case) {
      case 'fluid': {
        residuals = RESIDUALS_FLUID;
        const scope =
          createPhysicsScope(simParam, physics, experimentConfig, geometryTags, staticVolumes);
        residuals = residuals.filter((res) => {
          const cond = getQuantityCond(res);
          return cond ? scope.isEnabled(cond) : true;
        });
      }
        break;
      case 'heat':
        residuals = RESIDUALS_HEAT;
        break;
      default:
        throw Error(`Unknown physics case ${physics.params.case}`);
    }
  }
  return residuals;
}

/**
 * Disable unavailable residuals in a residual output node.
 * @param residualNode residual node where the residuals are changed in-place
 * @param simParam simulation params
 * @param paramScope param scope
 * @param enableActive enable all available residuals
 */
export function updateResidualChoices(
  residualNode: feoutputpb.ResidualNode,
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
  enableActive?: boolean,
) {
  const physicsId = defaultPhysicsId(residualNode, simParam) ?
    getDefaultPhysicsId(simParam) : residualNode?.physicsId;
  if (physicsId) {
    const residuals =
      getAvailableResiduals(simParam, physicsId, experimentConfig, geometryTags, staticVolumes);
    const resEnabledMap = residualNode.resEnabled;
    Object.keys(resEnabledMap).forEach((res) => {
      const resNum = parseInt(res, 10);
      if (!residuals.includes(resNum)) {
        resEnabledMap[resNum] = false;
      } else if (enableActive) {
        resEnabledMap[resNum] = true;
      }
    });
  }
}

// Switch to a new type. Change the default values.
// newOutputNode: the output node we are changing based on the newChoice and newType parameters.
export function changeOutputType(
  newChoice: number,
  newType: feoutputpb.OutputNode_Type,
  newOutputNode: OutputNode,
  param: simulationpb.SimulationParam,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  if (newType === DERIVED_OUTPUT_TYPE) {
    newOutputNode = new OutputNode({
      calcType: CALCULATION_AGGREGATE,
      nodeProps: {
        case: 'derived',
        value: new feoutputpb.DerivedNode({ elements: [] }),
      },
    });
    setIncluded(newOutputNode, feoutputpb.OutputIncludes.OUTPUT_INCLUDE_BASE, true);
    return;
  }

  // Get the node props for the new choice and type.
  const { nodePropsCase } = getNodeCategory(newType, newChoice);
  newOutputNode.choice = newChoice;
  if (!newOutputNode.calcType) {
    newOutputNode.calcType = CALCULATION_AGGREGATE;
  }
  newOutputNode.type = newType;
  if (!Object.keys(newOutputNode.include).length) {
    setIncluded(newOutputNode, OutputIncludes.OUTPUT_INCLUDE_BASE, true);
  }

  const setVectorComponent = (props: VectorComponentNodeTypes) => {
    props.vectorComponent = getQuantitySize(props.quantityType) === DIM3D ?
      basepb.Vector3Component.VECTOR_3_COMPONENT_X :
      basepb.Vector3Component.VECTOR_3_COMPONENT_INVALID;
  };

  switch (nodePropsCase) {
    case 'surfaceAverage': {
      const quantity = getNodeEntry(newType, newChoice).data!;
      const tags = getQuantityTags(quantity);
      const surfaceAverageNode = new feoutputpb.SurfaceAverageNode();
      const surfaceAvgProps = newOutputNode.nodeProps.case === nodePropsCase ?
        newOutputNode.nodeProps.value?.props : new outputpb.SurfaceAverageProperties();
      // If averaging type isn't set to MASS_FLOW_AVERAGING, then we should automatically
      // assign it to AREA_AVERAGING because the other two options are INVALID or NO_AVERAGING,
      // and by default the user should have a valid averaging type selected.
      if (surfaceAvgProps!.averagingType !== SPACE_MASS_FLOW_AVERAGING) {
        surfaceAvgProps!.averagingType = SPACE_AREA_AVERAGING;
      }
      // Explicitly disable averaging for certain quantities
      if ([
        quantitypb.QuantityType.ABS_MASS_FLOW,
        quantitypb.QuantityType.MASS_FLOW,
        quantitypb.QuantityType.AREA,
      ].includes(quantity)) {
        surfaceAvgProps!.averagingType = SPACE_NO_AVERAGING;
      }

      surfaceAverageNode.props = surfaceAvgProps;
      surfaceAverageNode.quantityType = quantity;
      if (tags.includes(QuantityTag.TAG_DROP)) {
        newOutputNode.calcType = CALCULATION_DIFFERENCE;
      }

      const incompatibleIncludes = [
        OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT,
        OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE,
      ];
      incompatibleIncludes.forEach((inc) => setIncluded(newOutputNode, inc, false));

      setVectorComponent(surfaceAverageNode);
      newOutputNode.nodeProps = {
        case: 'surfaceAverage',
        value: surfaceAverageNode,
      };
      break;
    }
    case 'force': {
      // disallow DIFFERENCE calculation type from being selected if outputNode is force node.
      if (newOutputNode.calcType === CALCULATION_DIFFERENCE) {
        newOutputNode.calcType = CALCULATION_AGGREGATE;
      }
      const quantity = getNodeEntry(newType, newChoice).data!;
      const tags = getQuantityTags(quantity);
      const forceNode = newOutputNode.nodeProps.case === nodePropsCase ?
        newOutputNode.nodeProps.value : new feoutputpb.ForceNode();
      const forceProps = newOutputNode.nodeProps.case === nodePropsCase ?
        newOutputNode.nodeProps.value.props! : new outputpb.ForceProperties();
      forceNode.quantityType = quantity;

      if (tags.includes(QuantityTag.TAG_AUTO_DIRECTION)) {
        forceProps.forceDirType = FORCE_DIRECTION_BODY_ORIENTATION_AND_FLOW_DIR;
      } else {
        forceProps.forceDirType = FORCE_DIRECTION_CUSTOM;
      }

      // TODO(LC-6878): coefficient include option removed for
      // actuator disk outputs for now. Add back in later with coefficient
      // calculated based on blade tip speed for disk actuators
      if (tags.includes(QuantityTag.TAG_ACTUATOR_DISK)) {
        const incompatibleIncludes = [
          OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT,
          OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE,
        ];
        incompatibleIncludes.forEach((inc) => setIncluded(newOutputNode, inc, false));
      } else if (
        isIncluded(newOutputNode, OutputIncludes.OUTPUT_INCLUDE_TIME_AVERAGE)
      ) {
        setIncluded(
          newOutputNode,
          OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE,
          true,
        );
      }

      if (newOutputNode.nodeProps.case === nodePropsCase) {
        return;
      }
      forceProps.forceDirection = newScalarAdVector(1);
      forceNode.props = forceProps;
      newOutputNode.nodeProps = { case: 'force', value: forceNode };
      break;
    }
    case 'residual': {
      const residualsNode = new feoutputpb.ResidualNode();
      const residualProps = new outputpb.ResidualProperties();
      residualProps.type = outputpb.ResidualType.RESIDUAL_RELATIVE;
      residualsNode.props = residualProps;
      let firstPhysicsId = '';
      // Select the first physics by default
      if (param.physics.length > 0) {
        firstPhysicsId = param.physics[0]!.physicsIdentifier!.id;
      }
      residualsNode.physicsId = firstPhysicsId;
      ALL_RESIDUALS.forEach((res) => {
        residualsNode.resEnabled[res] = true;
      });
      updateResidualChoices(
        residualsNode,
        param,
        experimentConfig,
        geometryTags,
        staticVolumes,
        true,
      );
      newOutputNode.nodeProps = { case: 'residual', value: residualsNode };
      break;
    }
    case 'basic': {
      const quantity = getNodeEntry(newType, newChoice).data!;
      newOutputNode.nodeProps = {
        case: 'basic',
        value: new feoutputpb.BasicNode({
          quantityType: quantity,
          props: new outputpb.BasicType(),
        }),
      };
      break;
    }
    case 'pointProbe': {
      const quantity = getNodeEntry(newType, newChoice).data!;
      const newProbe = new feoutputpb.PointProbeNode({
        quantityType: quantity,
        props: new outputpb.PointProbeType(),
      });
      newOutputNode.nodeProps = {
        case: 'pointProbe',
        value: newProbe,
      };
      setVectorComponent(newProbe);
      newOutputNode.calcType = CALCULATION_PER_SURFACE;
      break;
    }
    case 'volumeReduction': {
      const quantity = getNodeEntry(newType, newChoice).data!;

      const volumeRedProps = newOutputNode.nodeProps.case === nodePropsCase ?
        newOutputNode.nodeProps.value.props :
        new outputpb.VolumeReductionProperties({
          reductionType: outputpb.VolumeReductionType.VOLUME_AVERAGING,
        });
      const calcType = newOutputNode.nodeProps.case === nodePropsCase ?
        newOutputNode.calcType : CALCULATION_AGGREGATE;

      const newVolumeRed = new feoutputpb.VolumeReductionNode({
        quantityType: quantity,
        props: volumeRedProps,
      });
      newOutputNode.nodeProps = {
        case: 'volumeReduction',
        value: newVolumeRed,
      };
      setVectorComponent(newVolumeRed);
      newOutputNode.calcType = calcType;
      break;
    }
    default:
      throw Error('Invalid output node type');
  }
}

export function updateOutputNodes(
  outputNodes: feoutputpb.OutputNodes,
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const newOutputNodes = outputNodes.clone();
  newOutputNodes.nodes.forEach((output) => {
    if (output.nodeProps.case === 'residual') {
      const residualNode = output.nodeProps.value;
      updateResidualChoices(residualNode, simParam, experimentConfig, geometryTags, staticVolumes);
    }
  });
  return newOutputNodes;
}

// Remove any surfaces that are no longer present in the current params
export function updateSurfaces(
  outputNodes: feoutputpb.OutputNodes,
  entityGroupMap: EntityGroupMap,
) {
  const newOutputNodes = outputNodes.clone();
  const oldNodeList = outputNodes.nodes;
  newOutputNodes.nodes.forEach((output, index) => {
    const oldInSurfaces = oldNodeList[index].inSurfaces;
    const oldOutSurfaces = oldNodeList[index].outSurfaces;
    output.inSurfaces = oldInSurfaces.filter((surfaceId) => entityGroupMap.has(surfaceId));
    output.outSurfaces = oldOutSurfaces.filter((surfaceId) => entityGroupMap.has(surfaceId));
  });
  return newOutputNodes;
}

export function defaultChoice(
  type: feoutputpb.OutputNode_Type,
  choices: ProtoDescriptor.Choice[],
  farfield: boolean,
): number {
  // Find the first choice that won't be disabled due to lack of farfield boundary condition
  if (type === GLOBAL_OUTPUT_TYPE) {
    return choices[0].enumNumber;
  }
  const choice = farfield ?
    choices[0] : choices.find(({ data }) => (!needsFarfield(getQuantityTags(data))));
  if (!choice) {
    throw Error('Unable to find a default output quantity.');
  }
  return choice.enumNumber;
}

// When creating a new output node, if the quantity type is lift, sideforce, roll, etc.,
// set the frame ID to the Body Frame ID if a Body Frame exists, otherwise
// leave frame ID empty and the Global Frame will be used by default
export function setDefaultFrameId(node: OutputNode, param: simulationpb.SimulationParam) {
  if (node.nodeProps.case === 'force') {
    const quantity = node.nodeProps.value.quantityType;
    if (
      getQuantityTags(quantity).includes(QuantityTag.TAG_AUTO_DIRECTION) &&
      !getQuantityTags(quantity).includes(QuantityTag.TAG_ACTUATOR_DISK)
    ) {
      const bodyFrame = findBodyFrame(param);
      if (bodyFrame) {
        node.frameId = bodyFrame.frameId;
      }
    }
  }
}

export function createDefaultNode(
  param: simulationpb.SimulationParam,
  nodePropsCase: NodePropsCase,
  type: feoutputpb.OutputNode_Type,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
): OutputNode {
  const node = new OutputNode({ id: newNodeId() });
  setIncluded(node, OutputIncludes.OUTPUT_INCLUDE_BASE, true);
  const choices = choicesForType(type).find((cat) => cat.nodePropsCase === nodePropsCase)!.choices;
  changeOutputType(
    defaultChoice(type, choices, !!findFarfield(param)),
    type,
    node,
    param,
    experimentConfig,
    geometryTags,
    staticVolumes,
  );
  setDefaultFrameId(node, param);
  node.name = defaultName(node);
  node.analysisIters = 1;
  node.averageIters = 1;
  node.trailAvgIters = 1;
  return node;
}

// Returns a default residual output node
export function createResNode(
  param: simulationpb.SimulationParam,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
): OutputNode {
  const node = createDefaultNode(
    param,
    'residual',
    GLOBAL_OUTPUT_TYPE,
    experimentConfig,
    geometryTags,
    staticVolumes,
  );
  assert(node.nodeProps.case === 'residual', 'Newly created residual output node has wrong type');
  const resEnabledMap = node.nodeProps.value.resEnabled;
  // Uncheck the turb residual
  Object.keys(resEnabledMap).forEach((quantityStr) => {
    const quantity = parseInt(quantityStr, 10);
    if (getQuantityTags(quantity).includes(QuantityTag.TAG_TURBULENCE)) {
      resEnabledMap[quantity] = false;
    }
  });
  return node;
}

export function createOutputNode(): OutputNode {
  return new OutputNode({ id: newNodeId(), analysisIters: 1, averageIters: 1, trailAvgIters: 1 });
}

export function setDefaultName(node: OutputNode, allNodes: feoutputpb.OutputNodes) {
  const names = allNodes.nodes.map((output) => output.name);
  const nameRoot = defaultName(node);
  node.name = uniqueSequenceName(
    names,
    prefixNameGen(nameRoot, true),
    { recycleNumbers: true },
  );
}

// Change a field in a list of outputs and then update all of the outputs.
export function updateOutputParam(
  outputNodes: feoutputpb.OutputNodes,
  outputNodeId: string,
  changeParam: (newOutputNode: OutputNode) => void,
  setOutputs: (newOutputsNodes: feoutputpb.OutputNodes) => void,
) {
  const newOutputNodes = outputNodes.clone();
  const newOutput = findOutputNodeById(newOutputNodes, outputNodeId);
  if (!newOutput) {
    throw Error('Current output not in output list.');
  }
  changeParam(newOutput);
  setOutputs(newOutputNodes);
}

// Compute the sound speed of an ideal gas
export function idealGasSoundSpeed(cp: number, molecularWeight: number, temp: number): number {
  const gasConstant = 1000 * UNIVERSAL_GAS_CONSTANT / molecularWeight;
  return Math.sqrt((cp * gasConstant) / (cp - gasConstant) * temp);
}

export function removeDependencySuffix(
  nameWithSuffix: string,
): { name: string, include: OutputIncludes } {
  let name = nameWithSuffix;
  let include = OutputIncludes.OUTPUT_INCLUDE_BASE;
  DERIVED_DEPENDENCY_SUFFIXES.forEach((inc, suffix) => {
    if (nameWithSuffix.endsWith(suffix)) {
      name = nameWithSuffix.slice(0, -suffix.length);
      include = inc;
    }
  });
  return { name, include };
}

export function protoCategoriesToSelectOptions(
  categories: OutputNodeCategory[],
  currentChoice: number,
) {
  return categories.flatMap((category) => category.choices.map((choice) => ({
    value: choice.enumNumber,
    name: choice.text,
    selected: currentChoice === choice.enumNumber,
  })));
}

/** The name of a porous volume and its attached surface IDs */
export type PorousSurfaces = { porousName: string, surfaces: string[] };

/**
 * Get a list of surface IDs that are attached to the `porousModels` but are missing from `surfaces`
 */
export function getMissingPorousSurfaces(
  porousModels: simulationpb.PorousBehavior[],
  surfaceIds: string[],
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
) {
  const volumes: PorousSurfaces[] = [];
  if (porousModels.length) {
    // Since this project has porous models, we must verify if the user has selected any surfaces
    // related to those porous models.

    // Key: zone ID, Value: porous model name
    const porousNamesByZone = new Map<string, string>();
    porousModels.forEach((porous) => {
      const name = porous.porousBehaviorName;
      porous.zoneIds.forEach((zone) => {
        const domains = geometryTags.domainsFromTag(zone);

        const allDomains = domains.length > 0 ?
          mapDomainsToIds(staticVolumes, domains) :
          [zone];

        allDomains.forEach((domain) => {
          porousNamesByZone.set(domain, name);
        });
      });
    });

    staticVolumes.forEach((volume) => {
      const { bounds, id } = volume;

      if (porousNamesByZone.has(id)) {
        const selectedSurfaces = intersectSet(bounds, surfaceIds);
        if (selectedSurfaces.size && selectedSurfaces.size !== bounds.size) {
          // This volume with missing surfaces is attached to a porous model so we must let the user
          // know
          volumes.push({
            porousName: porousNamesByZone.get(id)!,
            surfaces: [...bounds],
          });
        }
      }
    });
  }

  return volumes;
}

/**
 * @param inSurfaces selected surfaces
 * @param porousSurfaces surfaces that are attached to a porous volume
 * @param wallSurfaces surfaces that have a wall boundary condition
 * @param hasMissingSurfaces true if the `inSurfaces` do not have all the required surfaces for
 * their porous volumes
 * @returns `true` if there are missing surfaces and the selected surfaces contain surfaces that are
 * attached to a porous volume and have a boundary condition other than "Wall"
 */
export function showPorousSurfacesWarning(
  inSurfaces: string[],
  porousSurfaces: Set<string>,
  wallSurfaces: string[],
  hasMissingSurfaces: boolean,
) {
  const porousInSurfaces = inSurfaces.filter((surface) => porousSurfaces.has(surface));
  const nonWallPorousInSurfaces = porousInSurfaces.filter(
    (surface) => !wallSurfaces.includes(surface),
  );
  return hasMissingSurfaces && nonWallPorousInSurfaces.length > 0;
}

/**
 * Get the warning text for an output node in the simulation tree
 */
export function getOutputForcesWarning(
  simParam: simulationpb.SimulationParam,
  outputNode: OutputNode,
  porousModels: simulationpb.PorousBehavior[],
  entityGroupData: EntityGroupData,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
) {
  const formattedLabel = `Output ${boldEscaped(outputNode.name)}`;
  const warnings: string[] = [];
  const inSurfaces = unwrapSurfaceIds(outputNode.inSurfaces, geometryTags, entityGroupData);
  const porousSurfaces = new Set(getPorousSurfaces(simParam, staticVolumes, geometryTags));
  const wallSurfaces = getWallSurfacesWithTags(simParam, geometryTags);
  if (outputNode.nodeProps.case === 'force') {
    const missingSurfaces = getMissingPorousSurfaces(
      porousModels,
      inSurfaces,
      staticVolumes,
      geometryTags,
    );
    const showWarning = showPorousSurfacesWarning(
      inSurfaces,
      porousSurfaces,
      wallSurfaces,
      missingSurfaces.length > 0,
    );
    if (showWarning) {
      const list = wordsToList(missingSurfaces.map((porous) => porous.porousName));
      // this is for the edge case where the user uploads settings and the porous models do not have
      // a name
      const missingPorous = list.length ? list : 'the selected porous';
      warnings.push(
        `${formattedLabel} does not have all bounding surfaces of ${missingPorous} media selected
        for an accurate aggregate force.`,
      );
    }
  }

  return warnings;
}

export function getOutputQuantity(outputNode: feoutputpb.OutputNode) {
  const type = outputNode.type;
  if (type !== DERIVED_OUTPUT_TYPE) {
    const entry = getNodeEntry(type, outputNode.choice);
    return entry.data ? entry.data as quantitypb.QuantityType : undefined;
  }
  return quantitypb.QuantityType.INVALID_QUANTITY_TYPE;
}

export function calcForceOptions(
  isVolume: boolean,
  value?: feoutputpb.CalculationType,
): SelectOption<feoutputpb.CalculationType>[] {
  const label = isVolume ? 'volume' : 'surface';
  return [
    {
      value: CALCULATION_AGGREGATE,
      tooltip: `Output an aggregate value across all of the ${label}s`,
      name: 'Aggregate',
      selected: value === CALCULATION_AGGREGATE,
    },
    {
      value: CALCULATION_PER_SURFACE,
      tooltip: `Output one value per ${label}`,
      name: `Per ${upperFirst(label)}`,
      selected: value === CALCULATION_PER_SURFACE,
    },
  ];
}

export function calcIntegralOptions(
  isVolume: boolean,
  value?: feoutputpb.CalculationType,
): SelectOption<feoutputpb.CalculationType>[] {
  return [
    ...calcForceOptions(isVolume, value),
    {
      value: CALCULATION_DIFFERENCE,
      tooltip: 'Output the difference between the IN surfaces and the OUT surfaces',
      name: 'Difference',
      selected: value === CALCULATION_DIFFERENCE,
    },
  ];
}
