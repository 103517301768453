// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useSetRecoilState } from 'recoil';

const isCreateTagModalOpenState = atom<boolean>({
  key: 'isCreateTagModalOpenState',
  default: false,
});

export const useIsCreateTagModalOpen = () => useRecoilState(isCreateTagModalOpenState);
export const useSetCreateTagModalOpen = () => useSetRecoilState(isCreateTagModalOpenState);
