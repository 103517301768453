// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import { SimulationTreeNode } from '../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { newSimulationTree } from '../../../lib/simulationTree/utils';

import { explorationTreeSelector } from './exploration';
import { geoModificationsTreeSelector } from './geometryModifications';
import { setupTreeSelector } from './setup';

export const simulationTreeSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'simulationTreeSelector',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const setupTree = get(setupTreeSelector(key));
    const explorationTree = get(explorationTreeSelector(key));

    // The root of a tree that organizes the simulation settings
    return newSimulationTree([setupTree, explorationTree]);
  },
  dangerouslyAllowMutability: true,
});

export function useSimulationTree(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(simulationTreeSelector({ projectId, workflowId, jobId }));
}

export function useModificationsTree(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(geoModificationsTreeSelector({ projectId, workflowId, jobId }));
}
