// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { findStaticVolumeById, volumeNodeId } from '../lib/volumeUtils';
import { CadMetadata } from '../proto/cadmetadata/cadmetadata_pb';
import { MeshFileMetadata } from '../proto/lcn/lcmesh_pb';

import { getGeoState, onGeometryTabSelector } from './geometry/geometryState';
import { geometryUsesTagsSelector } from './geometry/geometryUsesTags';
import { meshMetadataSelector, meshUrlState } from './meshState';
import { cadMetadataSetupTabState } from './useCadMetadata';

// A StaticVolume is always read from the mesh metadata
export interface StaticVolume {
  id: string;
  defaultName: string;
  index: number;
  domain: string;
  cadBodyId?: number;
  bounds: Set<string>; // the volume's constituent surface IDs
}

export type StaticVolumeKey = {
  projectId: string;
  volumeId: string;
};

export const defaultVolumeState = (
  meshMetadata?: MeshFileMetadata,
  cadMetadata?: CadMetadata,
  usesGeoTags?: boolean,
): StaticVolume[] => {
  const zones = meshMetadata?.zone || [];
  const cadVolumeNames = cadMetadata?.volumeNames || [];
  const cadBodyIds = cadMetadata?.volumeIds || [];
  return zones.map((zone, i) => {
    const bounds = new Set(zone.bound.map((bound) => bound.name));
    const name = zone.volumeDisplayName ||
      (usesGeoTags ? undefined : cadVolumeNames[i]) ||
      `Volume ${i + 1}`;

    return {
      id: volumeNodeId(i),
      index: i,
      domain: zone.name,
      defaultName: name,
      cadBodyId: cadBodyIds[i],
      bounds,
    };
  });
};

// Represents the atom state for static volumes
export const staticVolumesState = atomFamily<StaticVolume[], string>({
  key: 'staticVolumes',
  default: selectorFamily<StaticVolume[], string>({
    key: 'volumes/Default',
    get: (projectId: string) => ({ get }) => {
      const [geoUsesTags] = get(waitForAll([geometryUsesTagsSelector(projectId)]));

      // In geometry mode, the volume state comes from the geometry state.
      if (get(onGeometryTabSelector)) {
        const geoState = getGeoState(get, projectId);
        return geoState ?
          defaultVolumeState(geoState.metadata, geoState.cadMetadata, geoUsesTags) :
          [];
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return get(staticVolumesSetupTabState(projectId));
    },
    dangerouslyAllowMutability: true,
  }),
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const staticVolumesSetupTabState = atomFamily({
  key: 'staticVolumesSetupTab',
  default: selectorFamily({
    key: 'volumesSetupTab/Default',
    get: (projectId: string) => ({ get }) => {
      const [geoUsesTags] = get(waitForAll([geometryUsesTagsSelector(projectId)]));

      const meshUrl = get(meshUrlState(projectId));
      const [cadMetadata, metadata] = get(waitForAll([
        cadMetadataSetupTabState(projectId),
        meshMetadataSelector({ projectId, meshUrl: meshUrl.geometry }),
      ]));
      return defaultVolumeState(metadata?.meshMetadata, cadMetadata, geoUsesTags);
    },
  }),
});

export type SurfaceVolumeMap = Map<string, StaticVolume>;

export const surfaceToVolumeMapState = selectorFamily<SurfaceVolumeMap, string>({
  key: 'surfaceToVolumeMapState',
  get: (projectId: string) => async ({ get }) => {
    const staticVolumes = get(staticVolumesState(projectId));

    return staticVolumes.reduce((result, volume) => {
      volume.bounds.forEach((surfaceId) => {
        result.set(surfaceId, volume);
      });
      return result;
    }, new Map<string, StaticVolume>());
  },
});

export function useStaticVolumes(projectId: string) {
  return useRecoilValue(staticVolumesState(projectId));
}

export function useSurfaceToVolumes(projectId: string) {
  return useRecoilValue(surfaceToVolumeMapState(projectId));
}

export const staticVolumeSelector = selectorFamily<StaticVolume | undefined, StaticVolumeKey>({
  key: 'staticVolumeSelector',
  get: (key: StaticVolumeKey) => ({ get }) => {
    const { projectId, volumeId } = key;
    const volumes = get(staticVolumesState(projectId));
    return findStaticVolumeById(volumeId, volumes);
  },
});

export const useStaticVolume = (key: StaticVolumeKey) => useRecoilValue(staticVolumeSelector(key));
