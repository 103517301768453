// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilState } from 'recoil';

import * as frontendpb from '../../../../proto/frontend/frontend_pb';

export const stopCondReplyState = atomFamily<frontendpb.StoppingConditionReply | null, string>({
  key: 'stopCondReply',
  default: null,
  dangerouslyAllowMutability: true,
});

export function useStopCondReply(projectJobUrl: string) {
  return useRecoilState(stopCondReplyState(projectJobUrl));
}
