// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ControlPanelMode } from '../lib/componentTypes/controlPanel';

import { useIsStarterPlan } from './useAccountInfo';

export const controlPanelModeState = atom<ControlPanelMode>({
  key: 'controlPanelModeState',
  default: 'simulation',
});

export function useControlPanelMode() {
  return useRecoilState(controlPanelModeState);
}

export function useControlPanelModeValue() {
  return useRecoilValue(controlPanelModeState);
}

export function useSetControlPanelMode() {
  return useSetRecoilState(controlPanelModeState);
}

export function useIsBaselineMode() {
  const [controlPanelMode] = useControlPanelMode();
  return controlPanelMode === 'simulation';
}

export function useIsExploration() {
  const isBaselineMode = useIsBaselineMode();
  return !isBaselineMode;
}

export function useIsExplorationStarterPlan() {
  const isInExploration = useIsExploration();
  const isInStarerPlan = useIsStarterPlan();
  return isInExploration && isInStarerPlan;
}

const motionFrameToDeleteState = atom<string>({
  key: 'motionFrameToDeleteState',
  default: '',
});

export function useMotionFrameToDelete() {
  return useRecoilState(motionFrameToDeleteState);
}

export function useSetMotionFrameToDelete() {
  return useSetRecoilState(motionFrameToDeleteState);
}
