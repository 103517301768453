// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useSetRecoilState } from 'recoil';

const isDraggingFilesState = atom<boolean>({
  key: 'isDraggingFilesState',
  default: false,
});

export const useIsDraggingFiles = () => useRecoilState(isDraggingFilesState);
export const useSetDraggingFiles = () => useSetRecoilState(isDraggingFilesState);
