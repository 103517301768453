// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useRecoilValue } from 'recoil';

const hoveredRowId = atom<string | null>({
  key: 'jobTableHoveredRow',
  default: null,
});

export function useHoveredRowId() {
  return useRecoilState(hoveredRowId);
}

export function useHoveredRowIdValue() {
  return useRecoilValue(hoveredRowId);
}

const selectedRowId = atom<string | null>({
  key: 'jobTableSelectedRow',
  default: null,
});

export function useSelectedRowId() {
  return useRecoilState(selectedRowId);
}

export function useSelectedRowIdValue() {
  return useRecoilValue(selectedRowId);
}
