// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import * as ProtoDescriptor from '../../../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../../../SimulationParamDescriptor';
import { referenceValues } from '../../../flags';
import { getAdValue } from '../../../lib/adUtils';
import { findFarfield } from '../../../lib/boundaryConditionUtils';
import { FaultInfo } from '../../../lib/inputValidationUtils';
import { getMaterialFluid } from '../../../lib/materialUtils';
import { formatNumber } from '../../../lib/number';
import { setParamValue } from '../../../lib/paramCallback';
import { DEFAULT_OUTPUT_NODES } from '../../../lib/paramDefaults/outputNodesState';
import {
  getReferenceGeometricQuantity,
  getReferencePressure,
  getReferenceTemperature,
  getReferenceVelocity,
  validReferenceValueSelection,
} from '../../../lib/referenceValueUtils';
import * as rpc from '../../../lib/rpc';
import { getBoundaryCondName } from '../../../lib/simulationTree/utils';
import { addRpcError } from '../../../lib/transientNotification';
import { useNodePanel } from '../../../lib/useNodePanel';
import { AdFloatType } from '../../../proto/base/base_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import * as frontendpb from '../../../proto/frontend/frontend_pb';
import { OutputNodes } from '../../../proto/frontend/output/output_pb';
import { ReferenceValueType } from '../../../proto/output/reference_values_pb';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useOutputNodes } from '../../../recoil/outputNodes';
import { useEnabledExperiments } from '../../../recoil/useExperimentConfig';
import { useMeshReadyState } from '../../../recoil/useMeshReadyState';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useSimulationBoundaryNames } from '../../../state/external/project/simulation/param/boundaryNames';
import { useSimulationParamScope } from '../../../state/external/project/simulation/paramScope';
import { pushConfirmation, useSetConfirmations } from '../../../state/internal/dialog/confirmations';
import { useIsSetupView } from '../../../state/internal/global/currentView';
import { ActionButton } from '../../Button/ActionButton';
import { IconButton } from '../../Button/IconButton';
import Form from '../../Form';
import { DataSelect } from '../../Form/DataSelect';
import LabeledInput from '../../Form/LabeledInput';
import { ValidAdNumberInput } from '../../Form/ValidatedInputs/ValidNumberInput';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import { CollapsiblePanel } from '../../Panel/CollapsiblePanel';
import { ParamForm } from '../../ParamForm';
import QuantityAdornment from '../../QuantityAdornment';
import Tooltip from '../../Tooltip';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { LuminaryToggleSwitch } from '../../controls/LuminaryToggleSwitch';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { SectionMessage } from '../../notification/SectionMessage';
import { ResetIcon } from '../../svg/ResetIcon';
import { AttributesDisplay } from '../AttributesDisplay';
import NodeLink from '../NodeLink';
import PropertiesSection from '../PropertiesSection';

const {
  REFERENCE_PRESCRIBE_VALUES,
  REFERENCE_FARFIELD_VALUES,
} = ReferenceValueType;

async function syncRefValues(projectId: string) {
  try {
    const req = new frontendpb.SyncOutputNodesReferenceValuesRequest({ projectId });
    await rpc.callRetry(
      'SyncOutputNodesReferenceValues',
      rpc.client.syncOutputNodesReferenceValues,
      req,
    );
  } catch (error) {
    addRpcError('Failed to synchhronize reference values.', error);
  }
}

const InvalidSelectionMessage = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      justifyContent: 'center',
      marginTop: '10px',
    }}>
    <SectionMessage level="warning" message="No Far-field boundary found." />
  </div>
);

function ViscosityTableMessage(visc: number) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'center',
        marginTop: '10px',
      }}>
      <SectionMessage
        level="warning"
        message={`Using constant viscosity value (${formatNumber(visc)}) instead of ` +
          `temperature-dependent table.`}
      />
    </div>
  );
}

const RefTypeNodeLink = (
  { outputNodes, param }: { outputNodes: OutputNodes, param: simulationpb.SimulationParam },
) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const bcNames = useSimulationBoundaryNames(projectId, workflowId, jobId);

  switch (outputNodes.referenceValues?.referenceValueType) {
    case REFERENCE_FARFIELD_VALUES: {
      const farfield = findFarfield(param);
      if (farfield) {
        return (
          <LabeledInput label="">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <NodeLink
                asBlock
                nodeIds={[farfield.boundaryConditionName]}
                text={getBoundaryCondName(bcNames, farfield)}
              />
            </div>
          </LabeledInput>
        );
      }
      break;
    }
    default: // None
  }
  return <></>;
};

// A panel for showing the reference values for a simulation under the Outputs node.
export const ReferenceValuePropPanel = () => {
  // == Contexts
  const { selectedNode } = useSelectionContext();
  const { projectId, workflowId, jobId, readOnly: disable } = useProjectContext();

  // == Hooks
  const { simParam, saveParam } = useSimulationConfig();

  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const [outputNodes, setOutputNodes] = useOutputNodes(projectId, workflowId, jobId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const setConfirmStack = useSetConfirmations();
  const isSetupView = useIsSetupView();

  // == Data
  const validSelection = validReferenceValueSelection(outputNodes, simParam);
  const refValType = outputNodes.referenceValues?.referenceValueType;

  const queueSyncRefValues = () => {
    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: async () => {
        await syncRefValues(projectId);
      },
      title: 'Sync Reference Values',
      children: (
        <div>
          This will overwrite the reference values previously
          set in all simulations. Are you sure you want to proceed?
        </div>
      ),
    });
  };

  if (!selectedNode) {
    throw Error('Outputs Prop Panel should only display when a node is selected.');
  }
  const createRefValsSelection = (): ReactElement => (
    <LabeledInput
      help="Sync reference values with far field or prescribe custom values."
      key="available-values"
      label="Available Values">
      <DataSelect
        asBlock
        disabled={!meshReadyState}
        faultType={!validSelection ? 'warning' : undefined}
        onChange={(value) => {
          setOutputNodes((oldNodes) => {
            const newNodes = oldNodes.clone();
            newNodes.referenceValues!.referenceValueType = value;
            return newNodes;
          });
        }}
        options={[
          {
            options: [{
              name: 'Far-field',
              value: REFERENCE_FARFIELD_VALUES,
              selected: refValType === REFERENCE_FARFIELD_VALUES,
              disabled: !findFarfield(simParam),
              disabledReason: 'This setup does not have a Far-field boundary.',
            }],
          },
          {
            name: 'Custom',
            value: REFERENCE_PRESCRIBE_VALUES,
            selected: refValType === REFERENCE_PRESCRIBE_VALUES,
          },
        ]}
        size="small"
      />
    </LabeledInput>
  );

  const getReferenceValue = (descriptor: ProtoDescriptor.RealParam): {
    value: AdFloatType,
    readOnly: boolean
  } => {
    switch (descriptor.pascalCaseName) {
      case 'AreaRef':
      case 'LengthRef':
      case 'LengthRefPitch':
      case 'LengthRefRoll':
      case 'LengthRefYaw':
        return getReferenceGeometricQuantity(outputNodes, descriptor.pascalCaseName);
      case 'PRef':
        return getReferencePressure(outputNodes, simParam);
      case 'TRef':
        return getReferenceTemperature(outputNodes, simParam);
      case 'VRef':
        return getReferenceVelocity(outputNodes, simParam, geometryTags, staticVolumes);
      default: throw Error('Unrecognized reference value param description');
    }
  };

  const resetButton = (
    <Tooltip title="Reset custom values to default">
      <span>
        <IconButton
          aria-label="reset"
          disabled={!meshReadyState || refValType !== REFERENCE_PRESCRIBE_VALUES}
          onClick={() => setOutputNodes((oldNodes) => {
            const newNodes = DEFAULT_OUTPUT_NODES.clone();
            newNodes.nodes = oldNodes.nodes;
            if (newNodes.referenceValues) {
              newNodes.referenceValues.useAeroMomentRefLengths =
                !!oldNodes.referenceValues?.useAeroMomentRefLengths;
            }
            return newNodes;
          })}>
          <ResetIcon maxHeight={13} />
        </IconButton>
      </span>
    </Tooltip>
  );

  const createRefValueInput = (
    descriptor: ProtoDescriptor.RealParam,
  ) => {
    const { value, readOnly } = getReferenceValue(descriptor);
    const validate = (newVal: number) => {
      const error = ProtoDescriptor.checkBounds(
        { openBound: true, minVal: 0, ...descriptor },
        newVal,
      );
      if (error) {
        return { type: 'error', message: error } as FaultInfo;
      }
      return undefined;
    };
    return (
      <Form.LabeledInput help={descriptor.help} label={descriptor.text}>
        <ValidAdNumberInput
          asBlock
          disabled={!meshReadyState || readOnly}
          endAdornment={<QuantityAdornment quantity={descriptor.quantityType} />}
          name={descriptor.pascalCaseName}
          onCommit={(val) => setOutputNodes((oldNodes) => {
            const newNodes = oldNodes.clone();
            if (newNodes.referenceValues) {
              setParamValue(newNodes.referenceValues, descriptor, val);
            }
            return newNodes;
          })}
          size="small"
          validate={validate}
          value={value}
        />
      </Form.LabeledInput>
    );
  };
  const paramGroup = paramGroupDesc[ParamGroupName.ReferenceValues];
  // Use the new way of storing reference values if the message is defined (it will defined
  // for all projects that are created after the message was added). For old projects use the
  // reference values in the params.
  const refLengthPanel = useNodePanel(
    `${selectedNode.id}-ref-length`,
    'ref-length',
    { forceExpanded: true },
  );
  const useAeroMomentRefLength = !!outputNodes.referenceValues?.useAeroMomentRefLengths;
  const setUseAeroMomentRefLength = (value: boolean) => {
    setOutputNodes((oldNodes) => {
      const newNodes = oldNodes.clone();
      newNodes.referenceValues!.useAeroMomentRefLengths = value;
      return newNodes;
    });
  };

  const nonDimPanel = useNodePanel(
    `${selectedNode.id}-non-dim`,
    'non-dim',
    { forceExpanded: true },
  );
  const vRef = getReferenceValue(paramDesc[ParamName.VRef] as ProtoDescriptor.RealParam);
  const tRef = getReferenceValue(paramDesc[ParamName.TRef] as ProtoDescriptor.RealParam);
  const pRef = getReferenceValue(paramDesc[ParamName.PRef] as ProtoDescriptor.RealParam);
  const lRef = getReferenceValue(paramDesc[ParamName.LengthRef] as ProtoDescriptor.RealParam);

  let density: number = 0;
  let viscosity: number = 0;
  let nFluid: number = 0;
  let hasViscTable: boolean = false;
  simParam.materialEntity.forEach((material) => {
    const fluid = getMaterialFluid(material);
    if (fluid) {
      nFluid += 1;
      if (fluid.densityRelationship === simulationpb.DensityRelationship.IDEAL_GAS) {
        const molecularWeight = getAdValue(fluid.molecularWeight);
        density += getAdValue(pRef.value) * molecularWeight / (8314.4598 * getAdValue(tRef.value));
      } else {
        density += getAdValue(fluid.constantDensityValue);
      }
      if (fluid.laminarViscosityModelNewtonian ===
        simulationpb.LaminarViscosityModelNewtonian.SUTHERLAND) {
        const sMuRef = getAdValue(fluid.sutherlandViscosityRef);
        const sTRef = getAdValue(fluid.sutherlandViscosityTempRef);
        const sConst = getAdValue(fluid.sutherlandConstant);
        const refT = getAdValue(tRef.value);
        viscosity += sMuRef * ((refT / sTRef) ** 1.5) * (sTRef + sConst) / (refT + sConst);
      } else {
        viscosity += getAdValue(fluid.laminarConstantViscosityConstant);

        if (fluid.laminarViscosityModelNewtonian ===
          simulationpb.LaminarViscosityModelNewtonian.TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY) {
          hasViscTable = true;
        }
      }
    }
  });
  density /= nFluid;
  viscosity /= nFluid;
  const reynolds = getAdValue(vRef.value) * getAdValue(lRef.value) * density / viscosity;

  if (experimentConfig.includes(referenceValues) && outputNodes.useRefValues) {
    return (
      <PropertiesSection key={paramGroup.text}>
        <CollapsibleNodePanel
          headerRight={resetButton}
          heading="Reference Values For Coefficients"
          help={'Used exclusively for non dimensional outputs, these values do not interact with ' +
            'physics inputs such as boundary conditions).'}
          nodeId={selectedNode.id}
          panelName="reference_values">
          {createRefValueInput({
            type: ProtoDescriptor.ParamType.REAL,
            camelCaseName: 'areaRef',
            pascalCaseName: 'AreaRef',
            help: paramDesc[ParamName.AreaRef].help,
            text: paramDesc[ParamName.AreaRef].text,
            name: 'area',
            quantityType: QuantityType.AREA,
            parentGroups: [],
          })}
          {createRefValueInput({
            type: ProtoDescriptor.ParamType.REAL,
            camelCaseName: 'lengthRef',
            pascalCaseName: 'LengthRef',
            help: paramDesc[ParamName.LengthRef].help,
            text: paramDesc[ParamName.LengthRef].text,
            name: 'length',
            quantityType: QuantityType.LENGTH,
            parentGroups: [],
          })}
          {createRefValsSelection()}
          {validSelection && (
            <>
              <RefTypeNodeLink outputNodes={outputNodes} param={simParam} />
              {createRefValueInput({
                type: ProtoDescriptor.ParamType.REAL,
                camelCaseName: 'pRef',
                pascalCaseName: 'PRef',
                help: paramDesc[ParamName.PRef].help,
                text: paramDesc[ParamName.PRef].text,
                name: 'pressure',
                quantityType: QuantityType.PRESSURE,
                parentGroups: [],
              })}
              {createRefValueInput({
                type: ProtoDescriptor.ParamType.REAL,
                camelCaseName: 'tRef',
                pascalCaseName: 'TRef',
                help: paramDesc[ParamName.TRef].help,
                text: paramDesc[ParamName.TRef].text,
                name: 'temperature',
                quantityType: QuantityType.TEMPERATURE,
                parentGroups: [],
              })}
              {createRefValueInput({
                type: ProtoDescriptor.ParamType.REAL,
                camelCaseName: 'vRef',
                pascalCaseName: 'VRef',
                help: paramDesc[ParamName.VRef].help,
                text: paramDesc[ParamName.VRef].text,
                name: 'velocity',
                quantityType: QuantityType.VELOCITY,
                parentGroups: [],
              })}
            </>
          )}
          {!validSelection && <InvalidSelectionMessage />}
        </CollapsibleNodePanel>
        {nFluid > 0 && (
          <CollapsiblePanel
            collapsed={nonDimPanel.collapsed}
            heading="Non Dimensional Quantities"
            help={'The material properties (density, viscosity, etc.) used to compute these ' +
              'quantities are simple averages of the properties of all fluid materials ' +
              'currently defined, evaluated at the reference temperature and pressure.'}
            onToggle={nonDimPanel.toggle}>
            <AttributesDisplay
              attributes={[{
                label: 'Reynolds Number',
                value: formatNumber(reynolds),
              }]}
            />
            {hasViscTable && ViscosityTableMessage(viscosity)}
          </CollapsiblePanel>
        )}
        <CollapsiblePanel
          collapsed={refLengthPanel.collapsed || !useAeroMomentRefLength}
          headerRight={(
            <LuminaryToggleSwitch
              onChange={setUseAeroMomentRefLength}
              small
              value={useAeroMomentRefLength}
            />
          )}
          heading="Pitch, Roll, Yaw Reference Lengths"
          help={paramDesc[ParamName.UseAeroMomentRefLengths].help}
          onToggle={refLengthPanel.toggle}>
          {useAeroMomentRefLength && (
            <>
              {
                createRefValueInput({
                  type: ProtoDescriptor.ParamType.REAL,
                  camelCaseName: 'lengthRefPitch',
                  pascalCaseName: 'LengthRefPitch',
                  help: paramDesc[ParamName.LengthRefPitch].help,
                  text: paramDesc[ParamName.LengthRefPitch].text,
                  name: 'length pitch',
                  quantityType: QuantityType.LENGTH,
                  parentGroups: [],
                })
              }
              {createRefValueInput({
                type: ProtoDescriptor.ParamType.REAL,
                camelCaseName: 'lengthRefRoll',
                pascalCaseName: 'LengthRefRoll',
                help: paramDesc[ParamName.LengthRefRoll].help,
                text: paramDesc[ParamName.LengthRefRoll].text,
                name: 'length roll',
                quantityType: QuantityType.LENGTH,
                parentGroups: [],
              })}
              {createRefValueInput({
                type: ProtoDescriptor.ParamType.REAL,
                camelCaseName: 'lengthRefYaw',
                pascalCaseName: 'LengthRefYaw',
                help: paramDesc[ParamName.LengthRefYaw].help,
                text: paramDesc[ParamName.LengthRefYaw].text,
                name: 'length yaw',
                quantityType: QuantityType.LENGTH,
                parentGroups: [],
              })}
            </>
          )}
        </CollapsiblePanel>
        {
          /* Only allow syncing from the setup page */
          isSetupView && (
            <ActionButton
              asBlock
              compact={false}
              disabled={disable}
              kind="secondary"
              onClick={() => queueSyncRefValues()}
              size="small"
              title="Apply these reference values to all simulations.">
              Apply to all
            </ActionButton>
          )
        }
      </PropertiesSection>
    );
  }
  return (
    <PropertiesSection key={paramGroup.text}>
      <ParamForm<simulationpb.ReferenceValues>
        group={paramGroup}
        key={paramGroup.name}
        onUpdate={(newRefVals) => saveParam((newParam) => {
          newParam.referenceValues = newRefVals;
        })}
        paramScope={paramScope}
        proto={simParam.referenceValues!}
        readOnly={!meshReadyState || disable}
      />
    </PropertiesSection>
  );
};
