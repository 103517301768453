import { EntityGroupData } from '../recoil/entityGroupState';
import { TRANSPARENCY_MODE_SUBTITLE, defaultTransparencySettings, useTransparencySettings } from '../recoil/lcvis/transparencySettings';

import { expandGroups } from './entityGroupUtils';
import { filteredWithLeadIcon } from './menuUtil';

export function useSurfaceTransparencyMenuItems() {
  const [transparencySettings, setTransparencySettings] = useTransparencySettings();

  return (
    nodeIdsToGroup: string[],
    entityGroupData: EntityGroupData,
  ) => {
    const canMakeSelectionTransparent = Boolean(
      nodeIdsToGroup.length &&
      entityGroupData.groupMap.has(nodeIdsToGroup[0]),
    );

    return filteredWithLeadIcon('transparency', [
      {
        itemConfig: {
          label: 'Make Transparent',
          help: TRANSPARENCY_MODE_SUBTITLE,
          onClick: () => {
            setTransparencySettings((oldSettings) => ({
              ...oldSettings,
              active: true,
              surfaces: new Set(expandGroups(entityGroupData.leafMap)(nodeIdsToGroup)),
              invert: false,
            }));
          },
        },
        shouldShow: !transparencySettings.active && canMakeSelectionTransparent,
      },
      {
        itemConfig: {
          label: 'Make Others Transparent (Reveal)',
          help: TRANSPARENCY_MODE_SUBTITLE,
          onClick: () => {
            setTransparencySettings((oldSettings) => ({
              ...oldSettings,
              active: true,
              surfaces: new Set(expandGroups(entityGroupData.leafMap)(nodeIdsToGroup)),
              invert: true,
            }));
          },
        },
        shouldShow: !transparencySettings.active && canMakeSelectionTransparent,
      },
      {
        itemConfig: {
          label: 'Exit Transparency Mode',
          help: TRANSPARENCY_MODE_SUBTITLE,
          onClick: () => setTransparencySettings(defaultTransparencySettings()),
        },
        shouldShow: transparencySettings.active,
      },
    ]);
  };
}
