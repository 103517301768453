// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getSessionStorageData } from '../../../lib/browserStorage';
import { sessionStorageEffect } from '../../../lib/persist';

// Generate a unique key for the project and job
const getStorageKey = (projectId: string) => `last-opened-results-tab-${projectId}`;

const lastOpenedResultsTabState = atomFamily<string, string>({
  key: 'lastOpenedResultsTabState',
  default: (projectId: string) => {
    const storageKey = getStorageKey(projectId);
    return getSessionStorageData<string>(storageKey, '');
  },
  effects: (projectId: string) => [
    sessionStorageEffect(getStorageKey(projectId)),
  ],
});

export function useLastOpenedResultsTab(projectId: string) {
  return useRecoilState(lastOpenedResultsTabState(projectId));
}

export function useLastOpenedResultsTabValue(projectId: string) {
  return useRecoilValue(lastOpenedResultsTabState(projectId));
}

export function useSetLastOpenedResultsTab(projectId: string) {
  return useSetRecoilState(lastOpenedResultsTabState(projectId));
}
