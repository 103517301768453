// Copyright 2024-2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { Virtuoso as List } from 'react-virtuoso';

import { isUnmodifiedEscapeKey } from '../../lib/event';
import { globalDisabledReason } from '../../lib/geometryUtils';
import { clamp } from '../../lib/number';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import { MODIFICATIONS_TREE_DATA_LOCATOR, NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { VIEWER_PADDING } from '../../lib/visUtils';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as geometrypb from '../../proto/geometry/geometry_pb';
import { usePanel } from '../../recoil/expandedPanels';
import { useGeometryBusyState, useGeometryServerStatus, useIsGeoServerCreatingFeature, useIsGeometryServerActive } from '../../recoil/geometry/geometryServerStatus';
import { useGeometrySelectedFeature, useGeometryState } from '../../recoil/geometry/geometryState';
import { useModificationsTree } from '../../state/internal/tree/simulation';
import { useVisHeightValue } from '../../state/internal/vis/visHeight';
import { IconButton } from '../Button/IconButton';
import { SvgIcon } from '../Icon/SvgIcon';
import { CollapsiblePanel } from '../Panel/CollapsiblePanel';
import { ROW_OUTER_HEIGHT } from '../Theme/commonStyles';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useTree } from '../hooks/useTree';
import { RedoIcon } from '../svg/RedoIcon';
import { SearchIcon } from '../svg/SearchIcon';
import { UndoIcon } from '../svg/UndoIcon';
import { SimulationRowContainer } from '../treePanel/SimulationRowContainer';
import { useArrowKeyNav } from '../treePanel/useArrowKeyNav';
import { LoadingEllipsis } from '../visual/LoadingEllipsis';

import { MIN_HEIGHT, TREE_VERTICAL_PADDING, useTreePanelStyles } from './treePanelShared';

const logger = new Logger('ModificationTreePanel');

const UndoRedoButtons = () => {
  const { projectId, geometryId, readOnly } = useProjectContext();
  const geoState = useGeometryState(projectId, geometryId);
  const [selectedFeature] = useGeometrySelectedFeature(geometryId);
  const isGeoServerActive = useIsGeometryServerActive(geometryId);
  const disabledReason = globalDisabledReason(selectedFeature, readOnly, isGeoServerActive);
  const disabled = disabledReason !== undefined;

  return (
    <>
      <IconButton
        data-locator="toolbar-help"
        disabled={!geoState || geoState.nUndosAvailable <= 0 || !isGeoServerActive || disabled}
        onClick={() => {
          const req = new geometryservicepb.ModifyGeometryRequest({
            geometryId,
            modification: new geometrypb.Modification({
              modType: geometrypb.Modification_ModificationType.UNDO,
            }),
          });
          rpc.clientGeometry!.modifyGeometry(req).catch((err) => {
            logger.error(err);
          });
        }}
        title={disabled ? disabledReason : `Undo`}>
        <UndoIcon maxHeight={11.6} />
      </IconButton>
      <IconButton
        data-locator="toolbar-help-redo"
        disabled={!geoState || geoState.nRedosAvailable <= 0 || !isGeoServerActive || disabled}
        onClick={() => {
          const req = new geometryservicepb.ModifyGeometryRequest({
            geometryId,
            modification: new geometrypb.Modification({
              modType: geometrypb.Modification_ModificationType.REDO,
            }),
          });
          rpc.clientGeometry!.modifyGeometry(req).catch((err) => {
            logger.error(err);
          });
        }}
        title={disabled ? disabledReason : `Redo`}>
        <RedoIcon maxHeight={11.6} />
      </IconButton>
    </>
  );
};

/**
 * The ModificationTreePanel component is a card window that appears in the 3D viewer and displays
 * the Modification tree when on the Geometry page.
 * @returns CollapsiblePanel with the Geometry tree
 */
export const ModificationTreePanel = () => {
  // == Context
  const { projectId, workflowId, jobId, geometryId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Hooks
  const classes = useTreePanelStyles();

  // == Recoil
  const visHeight = useVisHeightValue();
  const simTree = useModificationsTree(projectId, workflowId, jobId);
  const [expanded, setExpanded] = usePanel({
    nodeId: `modification-tree-${projectId}`,
    panelName: 'modification-tree',
    defaultExpanded: true,
  });
  const [geoServerStatus] = useGeometryServerStatus(geometryId);
  const [geoServerBusyState] = useGeometryBusyState(geometryId);
  const isGeoServerCreatingFeature = useIsGeoServerCreatingFeature(geometryId);
  const geoState = useGeometryState(projectId, geometryId);

  // == Data
  const [listContainerHeight, setListContainerHeight] = useState(MIN_HEIGHT);
  const [filter, setFilter] = useState<null | string>(null);
  const filterActive = filter !== null;
  const filterFilled = filterActive && filter !== '';
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  // This may break eventually (simTree.children[0]), but for now there is an extra parent node that
  // wraps modifications and history.
  const {
    listRef,
    rowProps,
    maybeUpdateRowsOpened,
  } = useTree(simTree.children[0], filterFilled);
  const isInitialLoadingState = geoServerStatus === 'busy' && !geoState;

  const pendingRowProps = useMemo(() => {
    // If the geo server is creating a feature, get its ID and append 'Pending' to the name.
    if (geoServerStatus === 'busy' && isGeoServerCreatingFeature) {
      const featureId = geoServerBusyState?.BusyStateType.value?.featureId;
      if (!featureId) {
        return rowProps;
      }

      // Geometry uploads have multiple steps (messages), and pending will be appended multiple
      // times if we don't check for it.
      const pendingRegex = /^Pending .*\.\.\.$/;
      return rowProps.map((val, id) => {
        if (val.node.id === featureId && !pendingRegex.test(val.node.name)) {
          val.node.name = `Pending ${val.node.name} ...`;
        }
        return val;
      });
    }
    return rowProps;
  }, [rowProps, geoServerStatus, geoServerBusyState, isGeoServerCreatingFeature]);

  // If the filter is non-empty, we'll keep only the nodes which name matches the filter and the
  // nodes that contains a children with a name that matches it (even if the parent is collapsed).
  const filteredRowProps = useMemo(() => {
    if (filter === null || filter === '') {
      return pendingRowProps;
    }
    const filterString = filter.toLowerCase();

    const filterItem = (node: SimulationTreeNode): boolean => {
      // We don't need the sub containers
      if (node.type === NodeType.ROOT_GEOMETRY) {
        return false;
      }
      if (node.name.toLowerCase().includes(filterString)) {
        return true;
      }
      return false;
    };

    return pendingRowProps
      // Do the actual filter per name
      .filter((row) => filterItem(row.node));
  }, [pendingRowProps, filter]);

  const handleKeyPress = useCallback((event) => {
    if (filterActive && isUnmodifiedEscapeKey(event)) {
      setFilter(null);
    }
  }, [filterActive]);

  // We are using the regular addEventListener because useEventListener doesn't work properly here
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // Listen to arrow keys for navigating in the geometry tree with keyboard shortcuts
  useArrowKeyNav(simTree, filteredRowProps, listRef);

  const renderRow = useCallback((index, row) => (
    <SimulationRowContainer {...row} disableToggle={filterFilled} key={row.node.id} />
  ), [filterFilled]);

  useEffect(() => {
    maybeUpdateRowsOpened(simTree, selectedNodeIds);
  }, [selectedNodeIds, maybeUpdateRowsOpened, simTree]);

  // Make sure the List's parent container has some reasonable height depending on the content
  useLayoutEffect(() => {
    if (!visHeight) {
      return;
    }

    // Calculate the available height
    let maxHeight = (
      visHeight - (
        // Remove the paddings around the edges of the 3D viewer
        2 * VIEWER_PADDING
      ) - (
        // Remove the collapsible header for the Geometry panel + internal padding around the list
        36 + 2 * TREE_VERTICAL_PADDING
      ) - (
        // Account some space for the 3D axis in the bottom left
        150
      )
    );

    // We should put a hardcap of 70% from the 3D viewer's height
    maxHeight = Math.min(visHeight * 0.7, maxHeight);

    // Set the height depending on the amount of rows, but no more than the calculated limit
    setListContainerHeight(
      clamp(filteredRowProps.length * ROW_OUTER_HEIGHT, [MIN_HEIGHT, maxHeight]),
    );
  }, [filteredRowProps, visHeight, listContainerHeight]);

  if (!simTree) {
    return (
      <></>
    );
  }

  // Render
  return (
    <div
      className={cx(classes.root)}
      data-locator="modificationPanel">
      <CollapsiblePanel
        collapsed={!expanded}
        disabled={filterActive}
        expandWhenDisabled
        headerRight={
          !filterActive ? <div className={classes.headerRight}><UndoRedoButtons /></div> : undefined
        }
        heading={(
          <div className={classes.heading}>
            <button
              className={classes.searchButton}
              onClick={(event) => {
                if (filterActive) {
                  setFilter(null);
                } else {
                  setFilter('');
                  requestAnimationFrame(() => {
                    searchInputRef.current?.focus();
                  });
                }
                // Clicking the icon should not trigger the parent CollapsiblePanel
                event.stopPropagation();
              }}
              type="button">
              <SearchIcon maxWidth={12} />
            </button>
            {filterActive ? (
              <input
                className={classes.searchInput}
                onChange={(event) => setFilter(event.target.value)}
                // Clicking over the input should not trigger the parent CollapsiblePanel
                onClick={(event) => event.stopPropagation()}
                placeholder="Find..."
                ref={searchInputRef}
                type="text"
                value={filter}
              />
            ) : 'Modification List'}
          </div>
        )}
        onToggle={() => setExpanded(!expanded)}
        primaryHeading>
        <div className={classes.content}>
          {!!rowProps.length && !filteredRowProps.length && (
            <div className={classes.noResults}>No Modifications</div>
          )}
          {!!filteredRowProps.length && (
            <div
              className={classes.list}
              data-locator={MODIFICATIONS_TREE_DATA_LOCATOR}
              style={{ height: listContainerHeight }}>
              <List
                data={filteredRowProps}
                defaultItemHeight={ROW_OUTER_HEIGHT} // not necessary, but helps performance
                itemContent={renderRow}
                ref={listRef}
              />
            </div>
          )}
          {isInitialLoadingState && (
            <div className={classes.pendingRow}>
              <SvgIcon maxHeight={12} maxWidth={12} name="diskArrowUp" />
              <div>Connecting to the server<LoadingEllipsis /></div>
            </div>
          )}
        </div>
      </CollapsiblePanel>
    </div>
  );
};
