// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState } from 'recoil';

/**
 * This atom is needed to keep track of any edit that is being done at the moment
 * with the EditableText component that uses the contentEditable html attribute.
 * In some cases the parent might completely rerender that component which can undo
 * the temporarily written (but not saved) value in the EditableText. E.g. when a
 * job status changes, it reloads the job row in the Results tab. If the user was
 * doing a rename an the moment, it would be lost.
 */
const editableTextState = atom<null | string>({
  key: 'editableTextState',
  default: null,
});

export function useEditableTextState() {
  return useRecoilState(editableTextState);
}

export type EditableTextSelection = null | {
  type: string;
  anchorOffset: number;
  focusOffset: number;
  moveToEnd: boolean;
}

/**
 * This will keep track of the mouse caret position and any text selection that's
 * made within the EditableText element so that we can restore them after react
 * rerenders. That way we'll prevent the user from losing any progress while typing.
 */
const editableTextSelectionState = atom<EditableTextSelection>({
  key: 'editableTextSelection',
  default: null,
});

export function useEditableTextSelectionState() {
  return useRecoilState(editableTextSelectionState);
}
