// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/api/v0/luminarycloud/simulation/simulation.proto (package luminary.proto.api.v0.luminarycloud.simulation, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { SimulationParam } from "../../../../client/simulation_pb.js";
import { File, Vector3 } from "../common/common_pb.js";
import { ReferenceValues } from "../../../../output/reference_values_pb.js";

/**
 * See `gen/quantities.py`.
 *
 * @generated from enum luminary.proto.api.v0.luminarycloud.simulation.QuantityType
 */
export const QuantityType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.api.v0.luminarycloud.simulation.QuantityType",
  [
    {no: 0, name: "QUANTITY_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "QUANTITY_TYPE_LIFT", localName: "LIFT"},
    {no: 2, name: "QUANTITY_TYPE_DRAG", localName: "DRAG"},
    {no: 3, name: "QUANTITY_TYPE_SIDEFORCE", localName: "SIDEFORCE"},
    {no: 4, name: "QUANTITY_TYPE_ROLLING_MOMENT", localName: "ROLLING_MOMENT"},
    {no: 5, name: "QUANTITY_TYPE_YAWING_MOMENT", localName: "YAWING_MOMENT"},
    {no: 6, name: "QUANTITY_TYPE_PITCHING_MOMENT", localName: "PITCHING_MOMENT"},
    {no: 7, name: "QUANTITY_TYPE_TOTAL_FORCE", localName: "TOTAL_FORCE"},
    {no: 8, name: "QUANTITY_TYPE_DOWNFORCE", localName: "DOWNFORCE"},
    {no: 9, name: "QUANTITY_TYPE_DISK_THRUST", localName: "DISK_THRUST"},
    {no: 10, name: "QUANTITY_TYPE_FRICTION_FORCE", localName: "FRICTION_FORCE"},
    {no: 11, name: "QUANTITY_TYPE_FRICTION_FORCE_COEFFICIENT", localName: "FRICTION_FORCE_COEFFICIENT"},
    {no: 12, name: "QUANTITY_TYPE_PRESSURE_FORCE", localName: "PRESSURE_FORCE"},
    {no: 13, name: "QUANTITY_TYPE_PRESSURE_FORCE_COEFFICIENT", localName: "PRESSURE_FORCE_COEFFICIENT"},
    {no: 14, name: "QUANTITY_TYPE_LIFT_COEFFICIENT", localName: "LIFT_COEFFICIENT"},
    {no: 15, name: "QUANTITY_TYPE_DRAG_COEFFICIENT", localName: "DRAG_COEFFICIENT"},
    {no: 16, name: "QUANTITY_TYPE_TOTAL_FORCE_COEFFICIENT", localName: "TOTAL_FORCE_COEFFICIENT"},
    {no: 17, name: "QUANTITY_TYPE_SIDEFORCE_COEFFICIENT", localName: "SIDEFORCE_COEFFICIENT"},
    {no: 18, name: "QUANTITY_TYPE_DOWNFORCE_COEFFICIENT", localName: "DOWNFORCE_COEFFICIENT"},
    {no: 19, name: "QUANTITY_TYPE_TOTAL_MOMENT", localName: "TOTAL_MOMENT"},
    {no: 20, name: "QUANTITY_TYPE_DISK_TORQUE", localName: "DISK_TORQUE"},
    {no: 21, name: "QUANTITY_TYPE_TOTAL_MOMENT_COEFFICIENT", localName: "TOTAL_MOMENT_COEFFICIENT"},
    {no: 22, name: "QUANTITY_TYPE_ROLLING_MOMENT_COEFFICIENT", localName: "ROLLING_MOMENT_COEFFICIENT"},
    {no: 23, name: "QUANTITY_TYPE_YAWING_MOMENT_COEFFICIENT", localName: "YAWING_MOMENT_COEFFICIENT"},
    {no: 24, name: "QUANTITY_TYPE_PITCHING_MOMENT_COEFFICIENT", localName: "PITCHING_MOMENT_COEFFICIENT"},
    {no: 25, name: "QUANTITY_TYPE_PRESSURE", localName: "PRESSURE"},
    {no: 27, name: "QUANTITY_TYPE_TEMPERATURE", localName: "TEMPERATURE"},
    {no: 28, name: "QUANTITY_TYPE_DENSITY", localName: "DENSITY"},
    {no: 29, name: "QUANTITY_TYPE_TOTAL_PRESSURE", localName: "TOTAL_PRESSURE"},
    {no: 31, name: "QUANTITY_TYPE_TOTAL_TEMPERATURE", localName: "TOTAL_TEMPERATURE"},
    {no: 32, name: "QUANTITY_TYPE_MACH", localName: "MACH"},
    {no: 33, name: "QUANTITY_TYPE_VELOCITY_MAGNITUDE", localName: "VELOCITY_MAGNITUDE"},
    {no: 34, name: "QUANTITY_TYPE_Y_PLUS", localName: "Y_PLUS"},
    {no: 35, name: "QUANTITY_TYPE_ENERGY_FLUX", localName: "ENERGY_FLUX"},
    {no: 36, name: "QUANTITY_TYPE_VELOCITY_X", localName: "VELOCITY_X"},
    {no: 37, name: "QUANTITY_TYPE_VELOCITY_Y", localName: "VELOCITY_Y"},
    {no: 38, name: "QUANTITY_TYPE_VELOCITY_Z", localName: "VELOCITY_Z"},
    {no: 39, name: "QUANTITY_TYPE_PRESSURE_DRAG", localName: "PRESSURE_DRAG"},
    {no: 40, name: "QUANTITY_TYPE_PRESSURE_DRAG_COEFFICIENT", localName: "PRESSURE_DRAG_COEFFICIENT"},
    {no: 41, name: "QUANTITY_TYPE_VISCOUS_DRAG", localName: "VISCOUS_DRAG"},
    {no: 42, name: "QUANTITY_TYPE_VISCOUS_DRAG_COEFFICIENT", localName: "VISCOUS_DRAG_COEFFICIENT"},
  ],
);

/**
 * @generated from enum luminary.proto.api.v0.luminarycloud.simulation.CalculationType
 */
export const CalculationType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.api.v0.luminarycloud.simulation.CalculationType",
  [
    {no: 0, name: "CALCULATION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CALCULATION_TYPE_AGGREGATE", localName: "AGGREGATE"},
    {no: 2, name: "CALCULATION_TYPE_PER_SURFACE", localName: "PER_SURFACE"},
  ],
);

/**
 * @generated from enum luminary.proto.api.v0.luminarycloud.simulation.AveragingType
 */
export const AveragingType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.api.v0.luminarycloud.simulation.AveragingType",
  [
    {no: 0, name: "AVERAGING_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "AVERAGING_TYPE_MASS_FLOW", localName: "MASS_FLOW"},
    {no: 2, name: "AVERAGING_TYPE_AREA", localName: "AREA"},
  ],
);

/**
 * Represents a simulation object.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.Simulation
 */
export const Simulation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.Simulation",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "create_time", kind: "message", T: Timestamp },
    { no: 4, name: "update_time", kind: "message", T: Timestamp },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(Simulation_SimulationStatus) },
    { no: 6, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Represents current status of a simulation.
 *
 * @generated from enum luminary.proto.api.v0.luminarycloud.simulation.Simulation.SimulationStatus
 */
export const Simulation_SimulationStatus = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.api.v0.luminarycloud.simulation.Simulation.SimulationStatus",
  [
    {no: 0, name: "SIMULATION_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SIMULATION_STATUS_PENDING", localName: "PENDING"},
    {no: 2, name: "SIMULATION_STATUS_ACTIVE", localName: "ACTIVE"},
    {no: 3, name: "SIMULATION_STATUS_COMPLETED", localName: "COMPLETED"},
    {no: 4, name: "SIMULATION_STATUS_FAILED", localName: "FAILED"},
    {no: 5, name: "SIMULATION_STATUS_SUSPENDED", localName: "SUSPENDED"},
  ],
);

/**
 * Various settings to configure how a simulation is run.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.SimulationOptions
 */
export const SimulationOptions = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.SimulationOptions",
  () => [
    { no: 1, name: "batch_processing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "gpu_type", kind: "enum", T: proto3.getEnumType(SimulationOptions_GPUType) },
    { no: 9, name: "gpu_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from enum luminary.proto.api.v0.luminarycloud.simulation.SimulationOptions.GPUType
 */
export const SimulationOptions_GPUType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.api.v0.luminarycloud.simulation.SimulationOptions.GPUType",
  [
    {no: 0, name: "GPU_TYPE_UNSPECIFIED"},
    {no: 1, name: "GPU_TYPE_V100"},
    {no: 2, name: "GPU_TYPE_A100"},
  ],
);

/**
 * Used to create a simulation.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.CreateSimulationRequest
 */
export const CreateSimulationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.CreateSimulationRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "simulation_options", kind: "message", T: SimulationOptions },
    { no: 6, name: "simulation_param", kind: "message", T: SimulationParam },
    { no: 7, name: "simulation_param_json", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 8, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Represents simulation created.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.CreateSimulationResponse
 */
export const CreateSimulationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.CreateSimulationResponse",
  () => [
    { no: 1, name: "simulation", kind: "message", T: Simulation },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationRequest
 */
export const GetSimulationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationResponse
 */
export const GetSimulationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationResponse",
  () => [
    { no: 1, name: "simulation", kind: "message", T: Simulation },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.UpdateSimulationRequest
 */
export const UpdateSimulationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.UpdateSimulationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.UpdateSimulationResponse
 */
export const UpdateSimulationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.UpdateSimulationResponse",
  () => [
    { no: 1, name: "simulation", kind: "message", T: Simulation },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.ListSimulationsRequest
 */
export const ListSimulationsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.ListSimulationsRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.ListSimulationsResponse
 */
export const ListSimulationsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.ListSimulationsResponse",
  () => [
    { no: 1, name: "simulations", kind: "message", T: Simulation, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.DeleteSimulationRequest
 */
export const DeleteSimulationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.DeleteSimulationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.SuspendSimulationRequest
 */
export const SuspendSimulationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.SuspendSimulationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationGlobalResidualsRequest
 */
export const GetSimulationGlobalResidualsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationGlobalResidualsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "residual_normalization", kind: "enum", T: proto3.getEnumType(GetSimulationGlobalResidualsRequest_ResidualNormalization) },
  ],
);

/**
 * Optional. Whether to return the absolute or relative residuals. If
 * unspecified, it defaults to relative normalization.
 *
 * @generated from enum luminary.proto.api.v0.luminarycloud.simulation.GetSimulationGlobalResidualsRequest.ResidualNormalization
 */
export const GetSimulationGlobalResidualsRequest_ResidualNormalization = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationGlobalResidualsRequest.ResidualNormalization",
  [
    {no: 0, name: "RESIDUAL_NORMALIZATION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "RESIDUAL_NORMALIZATION_RELATIVE", localName: "RELATIVE"},
    {no: 2, name: "RESIDUAL_NORMALIZATION_ABSOLUTE", localName: "ABSOLUTE"},
  ],
);

/**
 * TODO: Add sample output here.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationGlobalResidualsResponse
 */
export const GetSimulationGlobalResidualsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationGlobalResidualsResponse",
  () => [
    { no: 1, name: "csv_file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationSurfaceQuantityOutputRequest
 */
export const GetSimulationSurfaceQuantityOutputRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationSurfaceQuantityOutputRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "quantity_type", kind: "enum", T: proto3.getEnumType(QuantityType) },
    { no: 3, name: "surface_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "calculation_type", kind: "enum", T: proto3.getEnumType(CalculationType) },
    { no: 6, name: "frame_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "force_direction", kind: "message", T: Vector3 },
    { no: 18, name: "moment_center", kind: "message", T: Vector3 },
    { no: 19, name: "averaging_type", kind: "enum", T: proto3.getEnumType(AveragingType) },
    { no: 20, name: "reference_values", kind: "message", T: ReferenceValues },
  ],
);

/**
 * TODO: Add sample output here.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationSurfaceQuantityOutputResponse
 */
export const GetSimulationSurfaceQuantityOutputResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationSurfaceQuantityOutputResponse",
  () => [
    { no: 1, name: "csv_file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationSurfaceSolutionRequest
 */
export const GetSimulationSurfaceSolutionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationSurfaceSolutionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "time_step", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationSurfaceSolutionResponse
 */
export const GetSimulationSurfaceSolutionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationSurfaceSolutionResponse",
  () => [
    { no: 2, name: "file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationVolumeSolutionRequest
 */
export const GetSimulationVolumeSolutionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationVolumeSolutionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "time_step", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationVolumeSolutionResponse
 */
export const GetSimulationVolumeSolutionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationVolumeSolutionResponse",
  () => [
    { no: 2, name: "file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation.GetSimulationParametersRequest
 */
export const GetSimulationParametersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation.GetSimulationParametersRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

