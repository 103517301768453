// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { CurrentView } from '../../lib/componentTypes/context';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { colors } from '../../lib/designSystem';
import { isInExploration } from '../../lib/navigation';
import useResizeObserver from '../../lib/useResizeObserver';
import { getFilterNodeIcon } from '../../lib/visUtils';
import { isSensitivityAnalysis } from '../../lib/workflowUtils';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useAnyFiltersPending } from '../../recoil/lcvis/lcvisFilterStatus';
import { useLcVisReadyValue } from '../../recoil/lcvis/lcvisReadyState';
import { useIsEditingFilterType } from '../../recoil/paraviewState';
import { useMeshReadyState } from '../../recoil/useMeshReadyState';
import { useActiveVisUrlValue } from '../../recoil/vis/activeVisUrl';
import { useWorkflowState } from '../../recoil/workflowState';
import { useCurrentView, useIsGeometryView } from '../../state/internal/global/currentView';
import { useWorkflowFlagValue } from '../../workflowFlag';
import Dropdown from '../Dropdown';
import { createStyles, makeStyles } from '../Theme';
import { getIconName } from '../Toolbar/LcVisToolbarButton';
import { useProjectContext } from '../context/ProjectContext';
import { useIsExplorationSetup } from '../hooks/exploration/useCreateExploration';
import { useAxesGridButtonData } from '../hooks/ribbonToolbar/useAxesGridButtonData';
import { useBoxOrSingleSelectSwitcherData } from '../hooks/ribbonToolbar/useBoxOrSingleSelectSwitcherData';
import { useGeometryOperationsData } from '../hooks/ribbonToolbar/useGeometryOperationsData';
import { useLcVisButtonData } from '../hooks/ribbonToolbar/useLcVisButtonData';
import { useScreenshotButtonData } from '../hooks/ribbonToolbar/useScreenshotButtonData';
import { useShowOriginData } from '../hooks/ribbonToolbar/useShowOriginData';
import { useSurfaceOrVolumeSelectDropdownData } from '../hooks/ribbonToolbar/useSurfaceOrVolumeSelectDropdownData';
import { useVisDropdownData } from '../hooks/ribbonToolbar/useVisDropdownData';
import RunSimulationButton from '../project/RunSimulationButton';
import { useVisToolsItems } from '../project/VisToolsPanel';
import { useHandleLcVisFilterClick } from '../visFilter/useHandleLcVisFilterClick';
import { useHandlePvFilterClick } from '../visFilter/useHandlePvFilterClick';
import { useIsFilterDisabled } from '../visFilter/useIsFilterDisabled';
import { useVisFilterParentNode } from '../visFilter/useVisFilterParentNode';

import { RIBBON_TOOLBAR_BUTTON_WIDTH, RibbonToolbarButton, RibbonToolbarTool } from './RibbonToolbarButton';
import { AdvancedAnalysisButton, CloseAdvancedAnalysisButton } from './actions/AdvancedAnalysisButton';
import { GoToNextStepButton } from './actions/GoToNextStepButton';
import { LoadToSetupButton } from './actions/LoadToSetupButton';

const useStyles = makeStyles(() => createStyles({
  root: {
    height: '60px',
    backgroundColor: colors.neutral150,
    padding: '0 12px',
    display: 'flex',
  },
  tools: {
    flex: 1,
    display: 'flex',
    minWidth: '110px',
  },
  darkSeparator: {
    width: '0px',
    height: '100%',
    borderLeft: `3px solid ${colors.neutral100}`,
  },
  lightSeparator: {
    width: '0px',
    height: '100%',
    borderLeft: `1px solid ${colors.neutral300}`,
  },
  rightActions: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
}), { name: 'RibbonToolbar' });

const ToolbarButton = (props: { tool: RibbonToolbarTool }) => {
  const { tool } = props;

  return (
    <RibbonToolbarButton
      disabled={tool.disabled}
      disabledReason={tool.disabledReason}
      dropdown={tool.dropdown}
      engaged={tool.engaged}
      icon={tool.icon}
      label={tool.label}
      locator={tool.locator}
      onClick={tool.onClick}
      title={tool.title}
    />
  );
};

const ToolbarDropdown = (props: { tool: RibbonToolbarTool, items: CommonMenuItem[] }) => {
  const { tool, items } = props;

  return (
    <Dropdown
      menuItems={items!}
      position="below-left"
      toggle={(
        <RibbonToolbarButton
          disabled={tool.disabled}
          disabledReason={tool.disabledReason}
          dropdown
          icon={tool.icon}
          label={tool.label}
          locator={tool.locator}
          onClick={() => { }}
          title={tool.title}
        />
      )}
    />
  );
};

// The component will be shown between the header and and the 3d viewer.
export const RibbonToolbar = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Hooks
  const classes = useStyles();

  // == State
  const workflowFlag = useWorkflowFlagValue();
  const isGeometryView = useIsGeometryView();
  const visToolsData = useVisToolsItems();
  const currentView = useCurrentView();
  const location = useLocation();
  const isExploration = isInExploration(location.pathname);

  // == State
  const geometryOperationsData = useGeometryOperationsData();
  const lcVisButtonData = useLcVisButtonData();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const workflow = useWorkflowState(projectId, workflowId);
  const activeVisUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const visDropdownData = useVisDropdownData({ omit: ['clip', 'slice'] });
  const boxOrSingleSelectSwitcherData = useBoxOrSingleSelectSwitcherData();
  const surfaceOrVolumeSelectDropdownData = useSurfaceOrVolumeSelectDropdownData();
  const axesGridButtonData = useAxesGridButtonData();
  const showOriginData = useShowOriginData();
  const screenshotButtonData = useScreenshotButtonData();
  const isExplorationSetup = useIsExplorationSetup();
  const handleLcvButtonClick = useHandleLcVisFilterClick();
  const lcVisReady = useLcVisReadyValue();
  const anyFiltersPending = useAnyFiltersPending();
  const isEditing = useIsEditingFilterType();
  const parentNode = useVisFilterParentNode();
  const handlePvFilterClick = useHandlePvFilterClick();
  const isFilterDisabled = useIsFilterDisabled();

  // == Data
  const toolsContainerRef = useRef<HTMLDivElement>(null);
  const toolsContainerWidth = useResizeObserver(toolsContainerRef).width;
  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  // The toolbar consists of many tools which are structured in up to 4 sections, where each
  // section is separated from the rest by a separator. That's the reason we must have 4 different
  // arrays. That complicates things a lot but it is what it is.
  const tools1: RibbonToolbarTool[] = [];
  const tools2: RibbonToolbarTool[] = [];
  const tools3: RibbonToolbarTool[] = [];
  const tools4: RibbonToolbarTool[] = [];

  // First section (other tools)
  if (!!activeVisUrl && !isSensitivity && lcVisButtonData && !isGeometryView) {
    // lcvis/paraview toggle
    tools1.push(lcVisButtonData);

    if (lcvisEnabled) {
      // main tools for lcvis
      tools1.push(
        {
          disabled: !lcVisReady || anyFiltersPending,
          engaged: isEditing(ParaviewRpc.TreeNodeType.CLIP),
          icon: { name: getIconName('clip') },
          key: 'clip',
          label: 'Add Clip',
          locator: 'toolbar-clip',
          onClick: (event) => handleLcvButtonClick(event, 'clip'),
        },
        {
          disabled: !lcVisReady || anyFiltersPending,
          engaged: isEditing(ParaviewRpc.TreeNodeType.SLICE),
          icon: { name: getIconName('slice') },
          key: 'slice',
          label: 'Add Slice',
          locator: 'toolbar-slice',
          onClick: (event) => handleLcvButtonClick(event, 'slice'),
        },
      );
    } else if (parentNode && meshReadyState) {
      // paraview tools
      tools1.push(
        {
          disabled: isFilterDisabled(ParaviewRpc.TreeNodeType.CLIP),
          icon: { name: getFilterNodeIcon(ParaviewRpc.TreeNodeType.CLIP) },
          key: 'clip',
          label: 'Add Clip',
          locator: 'toolbar-clip',
          onClick: () => handlePvFilterClick(ParaviewRpc.TreeNodeType.CLIP),
        },
        {
          disabled: isFilterDisabled(ParaviewRpc.TreeNodeType.SLICE),
          icon: { name: getFilterNodeIcon(ParaviewRpc.TreeNodeType.SLICE) },
          key: 'slice',
          label: 'Add Slice',
          locator: 'toolbar-slice',
          onClick: () => handlePvFilterClick(ParaviewRpc.TreeNodeType.SLICE),
        },
      );
    }

    // other vis filters
    if (visDropdownData?.items?.length) {
      tools1.push(visDropdownData);
    }
  }

  if (isGeometryView) {
    tools1.push(...geometryOperationsData);
  }

  // Second section (select tools)
  if (lcvisEnabled) {
    tools2.push(boxOrSingleSelectSwitcherData);
  }
  tools2.push(surfaceOrVolumeSelectDropdownData);

  // Third section (vis tools)
  visToolsData.forEach((item) => {
    tools3.push({
      disabled: item.disabled,
      engaged: item.engaged,
      icon: { name: item.icon },
      key: item.key,
      onClick: item.onClick,
      label: item.label,
      title: item.tooltip,
    });
  });

  // Fourth section (camera tools)
  tools4.push(axesGridButtonData);
  if (screenshotButtonData) {
    tools4.push(screenshotButtonData);
  }
  tools4.push(showOriginData);

  const allTools: RibbonToolbarTool[] = [...tools1, ...tools2, ...tools3, ...tools4];
  const allToolsWidth = allTools.length * RIBBON_TOOLBAR_BUTTON_WIDTH + 25;

  // Calculate the amount of tools that won't fit into the toolbar
  const excessToolsLength = toolsContainerWidth > 0 ? Math.ceil(
    (allToolsWidth - toolsContainerWidth) / RIBBON_TOOLBAR_BUTTON_WIDTH,
  ) : 0;
  const visibleTools = allTools.slice(0, allTools.length - 1 - excessToolsLength);
  const excessTools = allTools.slice(allTools.length - 1 - excessToolsLength);
  const excessToolsForDropdown: CommonMenuItem[] = excessTools.map((tool) => ({
    label: tool.label,
    onClick: (event: any) => tool.onClick(event),
    disabled: tool.disabled,
    help: tool.title,
    startIcon: tool.icon,
    items: tool.items,
  } as CommonMenuItem));

  const lightSeparator1Pos = tools1.length + tools2.length;
  const lightSeparator2Pos = tools1.length + tools2.length + tools3.length;

  const mainActionButton = useMemo(() => {
    switch (currentView) {
      case CurrentView.GEOMETRY:
        return <LoadToSetupButton />;
      case CurrentView.ANALYSIS:
        return <AdvancedAnalysisButton />;
      default:
        return (
          <>
            {isExplorationSetup && <CloseAdvancedAnalysisButton />}
            <RunSimulationButton size="medium" />
          </>
        );
    }
  }, [currentView, isExplorationSetup]);

  if (!workflowFlag) {
    return null;
  }

  return (
    <div className={classes.root} data-locator="ribbonToolbar">
      <div className={classes.tools} ref={toolsContainerRef}>
        {/* Render the visible tools normally */}
        {visibleTools.map((tool, idx) => (
          <React.Fragment key={tool.key}>
            {/* There should be a dark separator after tools1 */}
            {tools1.length > 0 && idx === tools1.length && (
              <div className={classes.darkSeparator} />
            )}
            {/* There should be a light separator between the rest of the tools sections */}
            {[
              lightSeparator1Pos,
              lightSeparator2Pos,
            ].includes(idx) && <div className={classes.lightSeparator} />}
            {tool.items ?
              <ToolbarDropdown items={tool.items} tool={tool} /> :
              <ToolbarButton tool={tool} />}
            {idx === visibleTools.length - 1 && excessTools.length > 1 && (
              <div className={classes.lightSeparator} />
            )}
          </React.Fragment>
        ))}

        {excessTools.length === 1 && (
          <ToolbarButton tool={excessTools[0]} />
        )}
        {excessTools.length > 1 && (
          <ToolbarDropdown
            items={excessToolsForDropdown}
            tool={{
              icon: { name: 'horizontalCirclesTriple' },
              key: 'more tools',
              locator: 'more-tools',
              onClick: (event) => { },
              label: 'More Tools',
            }}
          />
        )}
      </div>
      <div className={classes.rightActions}>
        {[
          CurrentView.PHYSICS,
          CurrentView.MESH,
          CurrentView.OUTPUTS,
        ].includes(currentView) && <GoToNextStepButton />}
        {mainActionButton}
      </div>
    </div>
  );
};
