// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { shallowEqual } from 'fast-equals';

import { EMPTY_VALUE } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { getIconForEntityGroup, rollupGroups } from '../../lib/entityGroupUtils';
import { AnyKeyboardEvent, isUnmodifiedEnterKey, isUnmodifiedEscapeKey, isUnmodifiedTabKey } from '../../lib/event';
import { parseString } from '../../lib/html';
import { NodeTableType, VOLUMES_TABLES } from '../../lib/nodeTableUtil';
import { Logger } from '../../lib/observability/logs';
import { SelectionAction, allowableEntityTypes } from '../../lib/selectionUtils';
import { formatDescendantCount } from '../../lib/simulationTree/utils';
import { ListenerEvent, useEventListener } from '../../lib/useEventListener';
import { EntityType } from '../../proto/entitygroup/entitygroup_pb';
import { useEntityGroupData, useNumDescendantsMap } from '../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useSetEntitySelection } from '../../recoil/selectionOptions';
import { useSetNodeSelectHoveredId } from '../../state/internal/selection/highlighting';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useToggleHighlightForNode } from '../hooks/useToggleHighlightForNode';
import { SectionMessage } from '../notification/SectionMessage';

import { NodeSelection } from './NodeSelection';
import { HelperButton } from './NodeSubselect/HelperButton';

const logger = new Logger('treePanel/NodeTable');

// MemberType refers to the kind of nodes being selected/unselected and influences how
// node item labels are generated as well as the helper text in the table.
// The enum value will be used as a label in the table's help text.
enum MemberType {
  SURFACE = 'surface',
  ACTUATOR_DISK = 'disk',
  POINTS = 'point',
  EXTRACT_BOUNDARY = 'mesh surface',
  VOLUME = 'volume',
  TAGS_CONTAINER = 'tag'
}

const useStyles = makeStyles(
  () => createStyles({
    nodeTable: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',

      '& + &': {
        paddingTop: '8px',
      },
    },
    nodeTitle: {
      color: '#8e8e8f',
      fontWeight: 500,
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    nodeList: {
      background: colors.surfaceDark3,
      border: `1px solid ${colors.surfaceDark3}`,
      borderRadius: '4px',
      fontSize: '13px',
      minHeight: '64px',
      maxHeight: '300px',
      overflow: 'auto',
      padding: '4px 8px',
      outline: 'none',

      '&:not(.disabled)': {
        cursor: 'text',
      },
      '&:focus:not(.disabled), &.warning': {
        borderColor: colors.yellow500,
      },
      '&:focus:not(.disabled), &.active': {
        borderColor: colors.primaryCta,
      },
      '&.disabled': {
        background: colors.neutral250,
      },
    },
    helperText: {
      color: '#8e8e8f',
      fontSize: '13px',
      height: '28px',
      lineHeight: '28px',
    },
  }),
  { name: 'NodeTable' },
);

interface NodeTableProps {
  // The list of individual nodes to display in the table.
  nodeIds: string[];
  // An optional button to show above the table.
  button?: ReactElement | null;
  // Whether or not the table can be edited.
  editable: boolean;
  // Whether or not to organize the nodes into groups. (Applies only for MemberType.SURFACE)
  formGroups?: boolean;
  // A string that uniquely identifies an instance of this table
  tableId: string;
  // The index of this table. Only needed when there are multiple tables of the same tableType.
  tableIndex?: number;
  // Represents the type of table
  tableType: NodeTableType;
  // The title of the table.
  title?: string;
  // Optional function to map a node ID to a scope for the Tag component.
  nodeIdToScope?: (id: string) => string;
  /** Display a warning border outline when true */
  warning?: boolean;
}

// Maps NodeTableType to MemberType
const getMemberType = (tableType: NodeTableType) => {
  switch (tableType) {
    case NodeTableType.PHYSICAL_BEHAVIOR_ATTACH:
      return MemberType.ACTUATOR_DISK;
    case NodeTableType.POINTS:
      return MemberType.POINTS;
    case NodeTableType.EXTRACT_BOUNDARY:
      return MemberType.EXTRACT_BOUNDARY;
    case NodeTableType.MESHING_SIZE:
    case NodeTableType.VOLUMES:
      return MemberType.VOLUME;
    default:
      return MemberType.SURFACE;
  }
};

const NODE_TABLE_DATA_LOCATOR = 'nodeSelectionTable';

// A panel displaying the properties of the boundary condition.
export const NodeTable = (props: NodeTableProps) => {
  const classes = useStyles();
  const toggleHighlightForNode = useToggleHighlightForNode();
  const setNodeSelectHoveredId = useSetNodeSelectHoveredId();

  const { projectId, workflowId, jobId } = useProjectContext();
  const {
    activeNodeTable,
    setActiveNodeTable,
    nodeTableWarning,
    setNodeTableWarning,
    modifySelection,
  } = useSelectionContext();

  const countMap = useNumDescendantsMap(projectId, workflowId, jobId);

  const memberType = getMemberType(props.tableType);

  const setEntitySelectionStateBase = useSetEntitySelection(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const lcVisEnabled = useLcVisEnabledValue(projectId);
  const [rowIds, setRowIds] = useState<string[]>(props.nodeIds);
  const prevPropsNodeIds = useRef<string[]>(props.nodeIds);
  const nodeListEl = useRef<HTMLDivElement>(null);

  const showWarning = activeNodeTable.id === props.tableId && nodeTableWarning;

  const nodeTableIdentifier = { type: props.tableType, id: props.tableId, index: props.tableIndex };
  const domIdentifier = `nodeSelectionTable-${props.tableId}`;

  // If formGroups is true, set the node IDs to be the surface IDs formed into
  // surface group IDs. Otherwise, set the node IDs to be the original list
  // provided in props.
  useEffect(() => {
    if (props.formGroups &&
      (memberType === MemberType.SURFACE || memberType === MemberType.ACTUATOR_DISK)) {
      setRowIds(rollupGroups(entityGroupData)(props.nodeIds).sort());
    } else {
      setRowIds(props.nodeIds);
    }
  }, [entityGroupData, memberType, props.formGroups, props.nodeIds, setNodeTableWarning]);

  const isActive = (activeNodeTable?.id === props.tableId);

  const startSelection = () => {
    if (!props.editable || isActive) {
      return;
    }

    // Set the active node table and highlight the current selection
    modifySelection({
      action: SelectionAction.HIGHLIGHT_CURRENT,
      nodeTableOverride: nodeTableIdentifier,
      updateActiveNodeTable: true,
    });
    setNodeTableWarning('');

    const entityTypes = allowableEntityTypes.get(props.tableType) || [];

    const noVolumesAllowed = entityTypes.every((item) => item !== EntityType.VOLUME);
    const noSurfacesAllowed = entityTypes.every((item) => item !== EntityType.SURFACE);

    // don't change current selection if node types don't match
    if (noVolumesAllowed && noSurfacesAllowed) {
      return;
    }

    setEntitySelectionStateBase(noVolumesAllowed ? 'surface' : 'volume');
  };

  const saveSelection = () => {
    modifySelection({
      action: SelectionAction.OVERWRITE_EXCLUDE,
      modificationIds: props.nodeIds,
      nodeTableOverride: nodeTableIdentifier,
      updateActiveNodeTable: false,
    });
  };

  const endSelection = () => {
    // Set the nodetable to NONE and highlight the currently selected node
    modifySelection({
      action: SelectionAction.HIGHLIGHT_CURRENT,
      nodeTableOverride: { type: NodeTableType.NONE },
      updateActiveNodeTable: true,
    });
    setNodeTableWarning('');
  };

  const getNodeLabel = (id: string) => {
    try {
      return entityGroupData.groupMap.get(id).name;
    } catch (error) {
      // Sometimes this is a transient error when a NodeTable and the EntityGroupMap are
      // being updated simultaneously but EntityGroupMap updates first. If it's not transient
      // the label will be displayed incorrectly.
      logger.error('Node ID is missing from entityGroupMap.');
      return EMPTY_VALUE;
    }
  };

  const clearSelections = () => {
    if (props.nodeIds.length) {
      modifySelection({
        action: SelectionAction.OVERWRITE,
        modificationIds: [],
        nodeTableOverride: nodeTableIdentifier,
      });
    }
  };

  const onHover = (nodeId: string, hovered: boolean) => {
    toggleHighlightForNode(nodeId, VOLUMES_TABLES.includes(props.tableType), hovered);
    setNodeSelectHoveredId(hovered ? nodeId : null);
  };

  // If the nodes in the selection change, we should automatically save the selection
  useEffect(() => {
    if (isActive && !shallowEqual(prevPropsNodeIds.current, props.nodeIds)) {
      saveSelection();
      prevPropsNodeIds.current = props.nodeIds;
    }
    // Do not include the saveSelection to avoid endless loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nodeIds, activeNodeTable.type]);

  // If the user starts the NodeTable edit mode and goes to another page (by clicking the home link
  // or using the browser back button), that may not end the edit mode, which will cause problems
  // later when they go back to a page with a tree. This is a last resort attempt to end the
  // NodeTable edit mode in case it wasn't happening with the other events.
  useEffect(() => () => {
    if (activeNodeTable.type !== NodeTableType.NONE && !nodeListEl.current) {
      setActiveNodeTable({ type: NodeTableType.NONE });
    }
  }, [activeNodeTable, setActiveNodeTable]);

  // End the selection mode if we press ESC or Tab
  useEventListener('keydown', (event: ListenerEvent) => {
    if (isActive && (
      isUnmodifiedEscapeKey(event as AnyKeyboardEvent) ||
      isUnmodifiedTabKey(event as AnyKeyboardEvent)
    )) {
      endSelection();
    }
  });

  // End the selection if we click outside the 3d viewer, the geometry tree panel, the
  // camera toolbar and the table.
  // Use the click event (not up/down), or if we are in selection and we click on a collapsible
  // panel header, that will also toggle the collapsible, instead of only ending the selection.
  useEventListener('click', (event: ListenerEvent) => {
    if (isActive) {
      const treeContainerEl = document.querySelector('[data-locator="geometryTreePanel"]');
      const cameraToolbarEl = document.querySelector('[data-locator="cameraControlPanel"]');
      const viewerContainerEl = document.querySelector(lcVisEnabled ?
        '[data-locator="lcvisEventHandler"]' : '.Paraview-rendererChild canvas');

      if (event.target) {
        const targetNode = event.target as Node;
        if (
          !nodeListEl.current?.contains(targetNode) &&
          !treeContainerEl?.contains(targetNode) &&
          !cameraToolbarEl?.contains(targetNode) &&
          !viewerContainerEl?.contains(targetNode)
        ) {
          endSelection();
        }
      }
    }
  });

  const nodeList: ReactElement[] = rowIds.map((id) => (
    <NodeSelection
      auxText={props.formGroups ? formatDescendantCount(countMap.get(id)) : undefined}
      closing={{
        disabled: !props.editable,
        onClick: (event) => {
          // We don't want the event for clicking the (x) to bubble up because it would start
          // the selection mode (if we are not in it).
          event.stopPropagation();
          // Remove the clicked item.
          if (isActive) {
            // In edit mode, we should just remove the clicked item from the current node table.
            modifySelection({
              action: SelectionAction.SUBTRACT,
              modificationIds: [id],
              nodeTableOverride: nodeTableIdentifier,
            });
          } else {
            // NOT in edit mode, we should remove the clicked item, but without activating the table
            // or changing the highlighted items.
            modifySelection({
              action: SelectionAction.SUBTRACT,
              modificationIds: [id],
              nodeTableOverride: nodeTableIdentifier,
              updateActiveNodeTable: false,
              updateHighlighting: false,
            });
          }
        },
      }}
      icon={
        entityGroupData.groupMap.has(id) ?
          getIconForEntityGroup(entityGroupData.groupMap.get(id)) :
          undefined
      }
      key={id}
      onClick={startSelection}
      onMouseEnter={() => onHover(id, true)}
      onMouseLeave={() => onHover(id, false)}
      scope={props.nodeIdToScope?.(id) || ''}
      text={getNodeLabel(id)}
    />
  ));

  return (
    <div className={classes.nodeTable}>
      {(props.title || props.button || (props.editable)) && (
        <div className={classes.nodeTitle}>
          {/* add a div here, even if it's empty, so that the buttons are moved to the right */}
          <div>
            {props.title && `${props.title} (${props.nodeIds.length})`}
          </div>
          {props.button}
          {props.editable && (
            <HelperButton
              disabled={!props.nodeIds.length}
              label="Clear All"
              onClick={clearSelections}
            />
          )}
        </div>
      )}
      <div
        className={cx(classes.nodeList, {
          active: isActive,
          disabled: !props.editable,
          warning: props.warning,
        })}
        data-locator={NODE_TABLE_DATA_LOCATOR}
        id={domIdentifier}
        onClick={startSelection}
        onKeyDown={(event) => {
          if (isUnmodifiedEnterKey(event)) {
            event.stopPropagation();
            startSelection();
          }
        }}
        ref={nodeListEl}
        role="button"
        tabIndex={0}>
        <div>
          <span className={classes.helperText}>
            {props.editable && !props.nodeIds.length ? `Select ${memberType}s` : ''}
          </span>
        </div>
        {nodeList}
      </div>
      {showWarning && (
        <div style={{ marginTop: '8px' }}>
          <SectionMessage level="warning">
            {parseString(nodeTableWarning)}
          </SectionMessage>
        </div>
      )}
    </div>
  );
};

export default NodeTable;
