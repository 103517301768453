// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const def: Record<string, string | undefined> = {};

// Atom to store routing parameters.
export const routeParamsState = atom({
  key: 'routeParamsAtom',
  default: def,
});

export function useRouteParamsValue() {
  return useRecoilValue(routeParamsState);
}

export function useSetRouteParams() {
  return useSetRecoilState(routeParamsState);
}
