// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * The state tracks which node from the NodeTable/NodeSubselect is being hovered at the moment.
 */
const nodeSelectHoveredIdState = atom<string | null>({
  key: 'nodeSelectHoveredIdState',
  default: null,
});

export function useNodeSelectHoveredIdValue() {
  return useRecoilValue(nodeSelectHoveredIdState);
}

export function useSetNodeSelectHoveredId() {
  return useSetRecoilState(nodeSelectHoveredIdState);
}
