// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { atom, useRecoilState, useSetRecoilState } from 'recoil';

const isItarEnvDialogOpened = atom<boolean>({
  key: 'isItarEnvDialogOpened',
  default: false,
});

export function useIsItarEnvDialogOpened() {
  return useRecoilState(isItarEnvDialogOpened);
}

export function useOpenItarEnvDialog() {
  const setIsItarEnvDialogOpened = useSetRecoilState(isItarEnvDialogOpened);

  return useCallback(() => setIsItarEnvDialogOpened(true), [setIsItarEnvDialogOpened]);
}
