// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getLocalStorageData } from '../lib/browserStorage';
import { localStorageEffect } from '../lib/persist';

const SHOW_PROP_PANEL_ON_SELECT_KEY = 'geometry-tree:show-prop-panel-on-select';

/**
 * The property panels for the geometry tree items won't open by default when you click an item
 * row from the tree. This atom represents the value for the "Show prop panel on select" toggle
 * below the tree. If TRUE, the prop panel will open automatically when the tree row is clicked.
 */
export const showGeometryPropertiesPanelOnSelectState = atom<boolean>({
  key: 'showGeometryPropertiesPanelOnSelectState',
  default: getLocalStorageData<boolean>(SHOW_PROP_PANEL_ON_SELECT_KEY, true),
  effects: [
    localStorageEffect(SHOW_PROP_PANEL_ON_SELECT_KEY),
  ],
});

export function useShowGeometryPropertiesPanelOnSelect() {
  return useRecoilState(showGeometryPropertiesPanelOnSelectState);
}
export function useShowGeometryPropertiesPanelOnSelectValue() {
  return useRecoilValue(showGeometryPropertiesPanelOnSelectState);
}

/**
 * This atom holds the truth whether we should show the property panel for the geometry tree items.
 * It can be shown by either one of:
 * - clicking a button from the TreeRow;
 * - toggling the "Show prop panel on select" from the Geometry Tree and then selecting some item.
 */
export const propertiesPanelVisibleState = atom<boolean>({
  key: 'propertiesPanelVisibleState',
  default: false,
});

export function usePropertiesPanelVisible() {
  return useRecoilState(propertiesPanelVisibleState);
}
export function usePropertiesPanelVisibleValue() {
  return useRecoilValue(propertiesPanelVisibleState);
}
export function useSetPropertiesPanelVisible() {
  return useSetRecoilState(propertiesPanelVisibleState);
}

const SIMULATION_PROPERTIES_PANEL_DOCKED_KEY = 'simulation-tree:properties-panel-docked';

/**
 * This atom holds the truth whether we should show the simulation tree's property panels docked
 * in the right sidebar or not.
 */
const simulationPropertiesPanelDockedState = atom<boolean>({
  key: 'simulationPropertiesPanelDockedState',
  default: getLocalStorageData<boolean>(SIMULATION_PROPERTIES_PANEL_DOCKED_KEY, false),
  effects: [
    localStorageEffect(SIMULATION_PROPERTIES_PANEL_DOCKED_KEY),
  ],
});

export function useSimulationPropertiesPanelDockedValue() {
  return useRecoilValue(simulationPropertiesPanelDockedState);
}
export function useSetSimulationPropertiesPanelDocked() {
  return useSetRecoilState(simulationPropertiesPanelDockedState);
}
