// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, useRecoilState } from 'recoil';

import { viewStateFixture } from '../lib/fixtures';
import { isTestingEnv } from '../lib/testing/utils';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

export const viewStateAtomFamily = atomFamily<ParaviewRpc.ViewState | null, string>({
  key: 'viewState',
  default: isTestingEnv() ? viewStateFixture : null,
});

export function useViewState(projectId: string) {
  return useRecoilState(viewStateAtomFamily(projectId));
}
