import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

// LCVis filters should not be applied immediately after Monitor Planes are created.
// Instead, users should configure them by clicking the "Submit" button before applying
// them in LCVis.
export const pendingMonitorPlanesState = atom({
  key: 'pendingMonitorPlanes',
  default: new Set<string>(),
});

export function usePendingMonitorPlanes() {
  return useRecoilValue(pendingMonitorPlanesState);
}

export function useSetPendingMonitorPlanes() {
  return useSetRecoilState(pendingMonitorPlanesState);
}
