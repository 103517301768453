// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE } from 'recoil';

import * as flags from '../../flags';
import { UploadProgress } from '../../lib/UploadProgress';
import { SelectOptionGroup } from '../../lib/componentTypes/form';
import { meshConversionStatus } from '../../lib/meshConversionStatus';
import { geometryIdLink, projectLink } from '../../lib/navigation';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import {
  CAD_FILE_TYPES,
  DIRECTORY_FILE_TYPES,
  DISCRETE_GEOMETRY_FILE_TYPES,
  FileType,
  MESH_FILE_TYPES,
  PK_FILE_TYPE,
  STAFF_ONLY_FILE_TYPES,
  fileTypesToSelectOption,
} from '../../lib/upload/fileTypes';
import { isGeometryFile, isInteractiveGeometryFile } from '../../lib/upload/uploadUtils';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as meshgenerationpb from '../../proto/meshgeneration/meshgeneration_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import * as uploadpb from '../../proto/upload/upload_pb';
import { useCheckedUrls } from '../../recoil/checkedGeometryUrls';
import { useResetEntityGroupMap } from '../../recoil/entityGroupState';
import { useSetFrontendMenuState } from '../../recoil/frontendMenuState';
import { geometryListState } from '../../recoil/geometry/geometryListState';
import { useSetGeometryHealth } from '../../recoil/geometryHealth';
import { useMeshUrlState } from '../../recoil/meshState';
import { usePendingWorkOrders } from '../../recoil/pendingWorkOrders';
import { useCadModifier } from '../../recoil/useCadModifier';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useSetInputFilename } from '../../recoil/useInputFilename';
import { useIsStaff } from '../../state/external/user/frontendRole';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';

import MeshImportDialog, { Preset } from './MeshImportDialog';

const logger = new Logger('MeshImportDialogCommon');

const GET_GEOMETRY = frontendpb.WorkOrderType.GET_GEOMETRY;

const FILE_TYPE_OPTIONS: SelectOptionGroup<FileType>[] = [
  {
    label: 'CAD',
    options: [
      ...fileTypesToSelectOption(CAD_FILE_TYPES),
      ...fileTypesToSelectOption(DISCRETE_GEOMETRY_FILE_TYPES)],
  },
  { label: 'Mesh', options: fileTypesToSelectOption(MESH_FILE_TYPES) },
];

export interface MeshImportDialogCommonProps {
  // Toggles the dialog open/closed
  open: boolean;
  // Called when the dialog is closed
  onClose: () => void;
  // Prepopulated file or directory values
  preset?: Preset;
  // Type of file to be uploaded
  type?: 'CAD' | 'MESH';
}

/** See LC-20058. */
function handlePkFileWithIgeo(url: string, allowParasolid: boolean) {
  const isParasolidFile = PK_FILE_TYPE.ext.some((ext) => url.endsWith(ext));
  // Waiting until LC-20058 is figured out.
  return !isParasolidFile || !allowParasolid || true;
}

// Main entrypoint for file importing into a project. Wraps MeshImportDialog and handles
// specific state management when uploading files.
export const MeshImportDialogCommon = (props: MeshImportDialogCommonProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const isGeometryView = useIsGeometryView();
  const refreshGeometryList = useRecoilRefresher_UNSTABLE(geometryListState(projectId));
  const [, setPendingWorkOrders] = usePendingWorkOrders(projectId);
  const navigate = useNavigate();
  const [currModifier] = useCadModifier(projectId);
  const [checkedUrls, setCheckedUrls] = useCheckedUrls(projectId);
  const [meshUrlState, setMeshUrlState] = useMeshUrlState(projectId);
  const setFrontendMenuState = useSetFrontendMenuState(projectId, '', '');
  const setInputFilename = useSetInputFilename(projectId);
  const setGeometryHealth = useSetGeometryHealth(projectId);
  // Resets the entity group map.
  const resetEntityGroups = useResetEntityGroupMap(projectId, workflowId, jobId);
  const isStaff = useIsStaff();
  const experimentConfig = useEnabledExperiments();
  const allowParasolid = experimentConfig.includes(flags.parasolidMeshing);
  const allowDiscreteIgeo = experimentConfig.includes(flags.interactiveGeometryDiscrete);
  const lcsurfaceTessellation = experimentConfig.includes(flags.lcsurfaceTessellation);
  const remeshingEnabled = experimentConfig.includes(flags.remeshing);

  // Given a Url already uploaded into our system, handle mesh conversion.
  const processInputFile = async (
    url: string,
    scaling: number,
    conversion: frontendpb.MeshConversionStatus,
    setUploadProgress: (value: React.SetStateAction<UploadProgress>) => void,
    fileNames: string[],
    fileType: uploadpb.MeshType | undefined,
  ) => {
    const allGeometryFiles = fileNames.every((file) => isGeometryFile(file));
    const allInteractiveGeometryFiles =
      fileNames.every((file) => isInteractiveGeometryFile(file, allowDiscreteIgeo));
    if (allGeometryFiles || remeshingEnabled) {
      if (
        isGeometryView &&
        (allInteractiveGeometryFiles || remeshingEnabled) &&
        handlePkFileWithIgeo(url, allowParasolid)
      ) {
        const req = new geometryservicepb.CreateGeometryRequest({
          projectId,
          scaling,
          // url is the uploaded file url that is the source of the geometry. Can be a zip file.
          url,
          // In case we are uploading multiple files, pick the first one arbitrarily.
          name: fileNames[0],
        });
        rpc.callRetryWithClient(
          rpc.clientGeometry!,
          'createGeometry',
          rpc.clientGeometry!.createGeometry,
          req,
        ).then(
          (response) => {
            // Refresh the geometry list so that it gets updated when the users go back to the base
            // geometry page.
            navigate(geometryIdLink(projectId, response.geometry!.id!));
            refreshGeometryList();
          },
        ).catch((err) => {
          logger.error('Failed to create geometry', err);
        });
      } else {
        setPendingWorkOrders((workOrders: frontendpb.PendingWorkOrders) => {
          const newWorkOrders = workOrders.clone();
          const workOrdersMap = newWorkOrders.workOrders;
          if (!workOrdersMap[GET_GEOMETRY]) {
            workOrdersMap[GET_GEOMETRY] = new frontendpb.PendingWorkOrder({
              typ: {
                case: 'getGeometry',
                value: new frontendpb.GetGeometryRequest({
                  projectId,
                  userGeo: new meshgenerationpb.UserGeometry({
                    url,
                    scaling,
                    allowParasolid,
                    lcsurfaceTessellation,
                  }),
                  userGeoMod: currModifier || undefined,
                }),
              },
            });
          }
          return newWorkOrders;
        });
        navigate(projectLink(projectId));
      }
      return null;
    }

    // Conversion not required, just return the uploaded URL.
    if (conversion !== frontendpb.MeshConversionStatus.IN_PROGRESS) {
      setUploadProgress({ done: true, progress: 1 });
      return new projectstatepb.MeshUrl({ url, geometry: url, mesh: url });
    }
    // Conversion required, wait for conversion.
    const resultUrl = await meshConversionStatus(
      projectId,
      url,
      scaling,
      true,
      setUploadProgress,
      fileType,
    );
    return new projectstatepb.MeshUrl({ url, geometry: resultUrl, mesh: resultUrl });
  };

  const onStartUpload = (name: string) => {
    // The geometry tab handles state differently, we don't want to corrupt the meshUrl state of the
    // setup tab.
    if (isGeometryView && handlePkFileWithIgeo(name, allowParasolid)) {
      return;
    }
    resetEntityGroups();
    const inputName = new projectstatepb.InputFilename({
      name,
    });
    setInputFilename(inputName);
    // Change the checkedUrl status so the new file must be rechecked.
    if (checkedUrls.status === projectstatepb.CheckGeometryStatus.SUCCESSFUL) {
      setCheckedUrls((oldCheckedUrls) => {
        const newUrlList = oldCheckedUrls.urls.filter((url) => url !== meshUrlState.url);
        const newStatus = oldCheckedUrls.status === projectstatepb.CheckGeometryStatus.SUCCESSFUL ?
          projectstatepb.CheckGeometryStatus.RECHECKING :
          projectstatepb.CheckGeometryStatus.UNCHECKED;
        return new projectstatepb.CheckedUrls({
          urls: newUrlList,
          status: newStatus,
        });
      });
    }
  };

  const onNewMeshUrl = (newMeshUrl: projectstatepb.MeshUrl | null) => {
    // The geometry tab handles state differently, we don't want to corrupt the meshUrl state of the
    // setup tab.
    if (newMeshUrl && !isGeometryView) {
      setMeshUrlState(newMeshUrl);
      // Clear the geometry health for mesh files. For geometry files this is done as part
      // of the GetGeometry request.
      setGeometryHealth(null);
    }
    // This occurs when user uploads a mesh file in the geometry tab.
    if (newMeshUrl && isGeometryView) {
      setMeshUrlState(newMeshUrl);
      setGeometryHealth(null);
      navigate(projectLink(projectId));
    }
  };

  const handleScaling = (scaling: number) => {
    // Store the scaling to use as the default value next time.
    setFrontendMenuState((oldState) => {
      const newState = oldState.clone();
      newState.meshScaling = scaling;
      return newState;
    });
  };

  const options = useMemo(() => {
    const result = FILE_TYPE_OPTIONS.slice();
    if (isStaff) {
      result.push(
        { label: 'Staff Only', options: fileTypesToSelectOption(STAFF_ONLY_FILE_TYPES) },
      );
    }
    return result;
  }, [isStaff]);

  return (
    <MeshImportDialog
      acceptNumericExtensions
      directoryTypeOptions={DIRECTORY_FILE_TYPES}
      fileTypeOptions={options}
      handleScaling={handleScaling}
      onClose={props.onClose}
      onNewMeshUrl={onNewMeshUrl}
      onStartUpload={onStartUpload}
      open={props.open}
      preset={props.preset}
      processInputFile={processInputFile}
      projectId={projectId}
      type={props.type}
    />
  );
};

export default MeshImportDialogCommon;
