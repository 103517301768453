// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilState, useSetRecoilState } from 'recoil';

export const uploadErrorAtomFamily = atomFamily<string, string>({
  key: 'uploadError',
  default: '',
});

export function useUploadError(projectId: string) {
  return useRecoilState(uploadErrorAtomFamily(projectId));
}

export function useSetUploadError(projectId: string) {
  return useSetRecoilState(uploadErrorAtomFamily(projectId));
}
