// Copyright 2020-2025 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily, waitForAll } from 'recoil';

import * as flags from '../../../../flags';
import {
  ADAPTATION_BOUNDARY_LABEL,
  BOUNDARY_ID,
  BOUNDARY_LABEL,
  MODEL_ID,
  MODEL_LABEL,
  SIZE_ID,
  SIZE_LABEL,
  isDefaultMeshMode,
} from '../../../../lib/mesh';
import { MESH_NODE_ID, NodeType, REFINEMENT_REGION_CONTAINER_ID, SimulationTreeNode } from '../../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../../lib/simulationTree/sectionRecoilKey';
import { isGeometryFile } from '../../../../lib/upload/uploadUtils';
import { MeshingMethod } from '../../../../proto/client/simulation_pb';
import { meshUrlState } from '../../../../recoil/meshState';
import { selectedGeometryState } from '../../../../recoil/selectedGeometry';
import { enabledExperimentsState } from '../../../../recoil/useExperimentConfig';
import { MeshPanelType, meshPanelStateAtom } from '../../../../recoil/useMeshPanelState';
import { meshingMultiPartSelector } from '../../../../recoil/useMeshingMultiPart';
import { simulationParamState } from '../../../external/project/simulation/param';

export const meshSectionSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'meshSection',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const { projectId } = key;
    const meshPanelState = get(meshPanelStateAtom(projectId));
    const onMeshSelectPanel = meshPanelState === MeshPanelType.SELECT;
    const [simParam, meshUrl, enabledExperiments, selectedGeometry] = get(waitForAll([
      simulationParamState(key),
      meshUrlState(projectId),
      enabledExperimentsState,
      selectedGeometryState(projectId),
    ]));
    const meshMethod = simParam?.adaptiveMeshRefinement?.meshingMethod;
    const isLMA = meshMethod === MeshingMethod.MESH_METHOD_AUTO;
    const remeshingEnabled = enabledExperiments.includes(flags.remeshing);

    const meshMultiPart = get(meshingMultiPartSelector(key));
    const refinementChildren = meshMultiPart?.refinementParams.map(
      (refinementParam) => (
        new SimulationTreeNode(
          NodeType.REFINEMENT_REGION,
          refinementParam.id,
          refinementParam.name,
        )
      ),
    ) ?? [];

    const hasChildren = isGeometryFile(meshUrl.url) || selectedGeometry.geometryId ||
      remeshingEnabled;
    const meshChildren: SimulationTreeNode[] = [];

    if (hasChildren && !onMeshSelectPanel) {
      if (isDefaultMeshMode(meshMultiPart) && !isLMA) {
        meshChildren.push(...[
          new SimulationTreeNode(NodeType.MESH_SIZE, SIZE_ID, SIZE_LABEL, []),
          new SimulationTreeNode(NodeType.MESH_MODEL, MODEL_ID, MODEL_LABEL, []),
          new SimulationTreeNode(NodeType.MESH_BOUNDARY, BOUNDARY_ID, BOUNDARY_LABEL, []),
          new SimulationTreeNode(
            NodeType.REFINEMENT_REGION_CONTAINER,
            REFINEMENT_REGION_CONTAINER_ID,
            'Refinement Regions',
            refinementChildren,
          ),
        ]);
      } else if (isLMA) {
        meshChildren.push(
          new SimulationTreeNode(
            NodeType.MESH_ADAPTATION_BOUNDARY,
            BOUNDARY_ID,
            ADAPTATION_BOUNDARY_LABEL,
            [],
          ),
        );
      }
    }

    return new SimulationTreeNode(NodeType.MESH, MESH_NODE_ID, 'Mesh', meshChildren);
  },
  dangerouslyAllowMutability: true,
});
