// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { advancedAnalysisLink, isInExploration, jobLink, setupLink, workflowLink } from '../../../lib/navigation';
import { AdvancedAnalysisParams } from '../../../lib/routeParamTypes';
import { isSensitivityAnalysis } from '../../../lib/workflowUtils';
import useProjectWorkflowAndJobStatusMap from '../../../model/hooks/useProjectWorkflowAndJobStatusMap';
import { JobStatusType } from '../../../proto/base/base_pb';
import { useWorkflowState } from '../../../recoil/workflowState';
import { pushConfirmation, useSetConfirmations } from '../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../Button/ActionButton';
import { SplitButton } from '../../Button/SplitButton';
import Tooltip from '../../Tooltip';
import { useProjectContext } from '../../context/ProjectContext';
import { useConfigWarnings } from '../../hooks/useRunSimulation';
import { LeveledMessageList } from '../../notification/LeveledMessageList';

export const AdvancedAnalysisButton = () => {
  // == Contexts
  const { projectId, workflowId, jobId, copyToSetup } = useProjectContext();

  // == Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const setConfirmStack = useSetConfirmations();

  // == State
  const statuses = useProjectWorkflowAndJobStatusMap(projectId);
  const workflow = useWorkflowState(projectId, workflowId);
  const isExploration = isInExploration(location.pathname);
  // Do not include workflowId/jobId because we want the setup errors, not the simulation errors.
  const warnings = useConfigWarnings(projectId, '', '');

  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  const simulationIsRunning = statuses[workflowId] && [
    JobStatusType.Active,
    JobStatusType.PendingRetry,
    JobStatusType.Suspending,
  ].includes(statuses[workflowId] as JobStatusType);

  const setupDoeTooltip = warnings.length ?
    <LeveledMessageList maxToShow={10} messages={warnings} /> : '';

  const referrerType = useMemo(() => {
    if (isSensitivity) {
      return 'sensitivity';
    }
    if (isInExploration(location.pathname)) {
      return 'doe';
    }
    return 'simulation';
  }, [isSensitivity, location.pathname]);

  return (
    <Tooltip title={setupDoeTooltip}>
      <span>
        <SplitButton
          asBlock
          dataLocator="advancedAnalysisButton"
          disabled={warnings.length > 0}
          kind="primary"
          menuItems={[{
            disabled: !!simulationIsRunning,
            disabledReason: simulationIsRunning ? `Starting an adjoint simulation is not possible
              while the current simulation is still running.` : undefined,
            label: 'Setup Adjoint',
            onClick: async () => {
              pushConfirmation(setConfirmStack, {
                onContinue: async () => {
                  // Use the settings from the existing simulation for the new adjoint setup by
                  // copying all settings to the setup
                  await copyToSetup();

                  // Go the the Adjoint advanced analysis link. The useSwitchPage hook has
                  // an effect that updates some of the configs for the adjoint.
                  navigate(
                    advancedAnalysisLink(
                      projectId,
                      workflowId,
                      jobId,
                      'adjoint',
                      referrerType,
                    ),
                  );
                },
                title: 'Overriding Setup Configuration',
                children: `Opening the "Setup Adjoint" page from a current simulation
                  will override your configuration in the Setup page with the settings from that
                  simulation.`,
              });
            },
          }]}
          onClick={() => {
            pushConfirmation(setConfirmStack, {
              onContinue: async () => {
                // Use the settings from the existing simulation for the new DoE setup by copying
                // all settings to the setup
                await copyToSetup();

                // Go to the advanced analysis page. It will set the controlPanelMode to
                // 'exploration' when it detects the `advanced` path and the `doe` in the pathname.
                navigate(
                  advancedAnalysisLink(
                    projectId,
                    workflowId,
                    jobId,
                    'doe',
                    referrerType,
                  ),
                );
              },
              title: 'Overriding Setup Configuration',
              children: `Opening the "Setup Design of Experiments" page from a current simulation
                will override your configuration in the Setup page with the settings from that
                simulation.`,
            });
          }}
          splitIcon={{ name: 'gear', maxHeight: 12 }}>
          Setup Design of Experiments
        </SplitButton>
      </span>
    </Tooltip>
  );
};

export const CloseAdvancedAnalysisButton = () => {
  // == Contexts
  const { projectId } = useProjectContext();

  // == Hooks
  const navigate = useNavigate();
  const params = useParams<AdvancedAnalysisParams>();

  // == State
  const referrerWorkflowId = params.referrerWorkflowId ?? '';
  const referrerJobId = params.referrerJobId ?? '';
  const referrerType = params.referrerType ?? 'setup';

  const link = useMemo(() => {
    switch (referrerType) {
      case 'setup':
        return setupLink(projectId);
      case 'doe':
        return jobLink(projectId, referrerWorkflowId, referrerJobId);
      case 'simulation':
        return workflowLink(projectId, referrerWorkflowId, false);
      case 'sensitivity':
        return workflowLink(projectId, referrerWorkflowId, true);
      default:
        throw Error('Unknown referrer type');
    }
  }, [projectId, referrerWorkflowId, referrerJobId, referrerType]);

  return (
    <ActionButton
      asBlock
      dataLocator="closeAdvancedAnalysis"
      kind="cancel"
      onClick={() => navigate(link)}>
      Close
    </ActionButton>
  );
};
