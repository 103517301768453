// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { getSessionStorageData } from '../lib/browserStorage';
import { sessionStorageEffect } from '../lib/persist';

const STORAGE_KEY = 'hasImportedModel';

export const hasImportedModelState = atom<boolean>({
  key: 'hasImportedModelState',
  default: getSessionStorageData<boolean>(STORAGE_KEY, false),
  effects: [sessionStorageEffect(STORAGE_KEY)],
});

export function useHasImportedModel() {
  return useRecoilValue(hasImportedModelState);
}

export function useSetImportedModel() {
  return useSetRecoilState(hasImportedModelState);
}
