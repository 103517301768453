// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState } from 'recoil';

export type ProjectCopiesInProgress = Array<{
  name: string;
  id: string;
}>

const projectCopiesInProgressState = atom<ProjectCopiesInProgress>({
  key: 'ProjectCopiesInProgressState',
  default: [],
  dangerouslyAllowMutability: true,
});

export function useProjectCopiesInProgress() {
  return useRecoilState(projectCopiesInProgressState);
}
