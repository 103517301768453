// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { useMultiphysicsInterfaces } from '../../model/hooks/useMultiphysicsInterfaces';
import { useGeometryContacts } from '../../recoil/geometryContactsState';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useSimulationAvailableMultiphysicsContacts } from '../../state/external/project/simulation/geometryContacts';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useGeometryStatus } from '../hooks/useGeometryStatus';
import { useMultiphysicsNode } from '../hooks/useMultiphysicsNode';
import { useNonEditableReason } from '../hooks/useNonEditableReason';

import { AddNodeMenuButton } from './AddNodeButton';

export const AddMultiphysicsInterfaceButton = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Recoil
  const contacts = useGeometryContacts(projectId);
  const setNewNodes = useSetNewNodes();
  const availableContacts = useSimulationAvailableMultiphysicsContacts(
    projectId,
    workflowId,
    jobId,
  );

  // == Custom hooks
  const {
    addMultiphysicsInterface,
  } = useMultiphysicsInterfaces(projectId, workflowId, jobId, readOnly);
  const { queueAddFromContacts } = useMultiphysicsNode();
  const { working } = useGeometryStatus();

  const addInterface = useCallback(async () => {
    const newInterface = await addMultiphysicsInterface();
    const nodeId = newInterface.slidingInterfaceId;
    setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
    setSelection([nodeId]);
  }, [addMultiphysicsInterface, setNewNodes, setSelection]);

  const menuItems = useMemo(() => {
    const items: CommonMenuItem[] = [
      {
        label: 'Coupling Interface',
        onClick: addInterface,
      },
    ];

    if (contacts.contacts.length) {
      const disabledReason = availableContacts.length ? '' : 'None of the contacts spans physics';
      items.push({
        label: 'Coupling Interfaces from Contacts',
        onClick: queueAddFromContacts,
        disabledReason,
        disabled: !!disabledReason,
      });
    }
    return items;
  }, [addInterface, availableContacts, contacts.contacts, queueAddFromContacts]);

  const tooltip = (!working && !menuItems.length) ? 'Assign volumes to fluid physics first' : '';
  const nonEditableReason = useNonEditableReason('add Multiphysics', tooltip);

  return (
    <AddNodeMenuButton
      disabled={working || !menuItems.length || !!nonEditableReason}
      menuItems={menuItems}
      tooltip={nonEditableReason}
    />
  );
};
