// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import * as ReactDOM from 'react-dom';

import * as ProtoDescriptor from '../../ProtoDescriptor';
import {
  CommonMenuHeading,
  CommonMenuItem,
  CommonMenuListItem,
  CommonMenuPosition,
  CommonMenuPositionTransform,
  CommonMenuSeparator,
  HelpfulIconSpec,
} from '../../lib/componentTypes/menu';
import { colors } from '../../lib/designSystem';
import {
  isUnmodifiedArrowDownKey,
  isUnmodifiedArrowLeftKey,
  isUnmodifiedArrowRightKey,
  isUnmodifiedArrowUpKey,
  isUnmodifiedEnterKey,
  isUnmodifiedEscapeKey,
  isUnmodifiedSpaceKey,
} from '../../lib/event';
import { parseString } from '../../lib/html';
import { clamp } from '../../lib/number';
import objectId from '../../lib/objectId';
import { getIconSpecDims } from '../../lib/svgIcon/utils';
import useResizeObserver from '../../lib/useResizeObserver';
import { analytics } from '../../services/analytics';
import Form from '../Form';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import Tooltip, { TooltipProps } from '../Tooltip';
import { EarlyAccessLink } from '../common/EarlyAccessLink';

const ICON_SIZE = 13;
const MARGIN_THRESHOLD = 8;
const MAIN_LINE_HEIGHT = 16;

const useMenuStyles = makeStyles(
  () => createStyles({
    backdrop: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    },
    root: {
      width: 'fit-content',
      overflowY: 'auto',
      listStyle: 'none',
      margin: '0px',
      padding: '8px 0px',
      outline: 'none',
      border: `1px solid ${colors.secondaryButtonBackground}`,
      borderRadius: '4px',
      boxShadow: `0 0 0 1px ${colors.neutral50}`,
      backgroundColor: colors.surfaceMedium1,
      '&.modal': {
        position: 'absolute',
      },
    },
  }),
  { name: 'CommonMenu' },
);

export const useItemStyles = makeStyles(
  () => createStyles({
    root: {
      '--left-border-color': 'transparent',
      '--bg-color': 'transparent',
      '--outline-color': 'transparent',
      '--main-text-color': colors.highEmphasisText,
      '--desc-text-color': colors.lowEmphasisText,
      '--font-weight': 400,
      '--cursor': 'pointer',
      '&.destructive': {
        '--main-text-color': colors.red700,
        '--desc-text-color': colors.red800,
      },
      '&:not(.disabled)': {
        '&:focus': {
          '--outline-color': colors.primaryInteractive,
        },
        '&:hover': {
          '--left-border-color': colors.purple500,
          '--bg-color': colors.neutral150,
        },
        '&.engaged': {
          '--left-border-color': colors.purple500,
          '--bg-color': colors.purple50,
        },
        '&:active': {
          '--bg-color': colors.neutral100,
        },
        '&.selected': {
          '--left-border-color': colors.purple500,
          '--bg-color': colors.purple50,
          '--font-weight': 600,
        },
      },
      '&.disabled': {
        '--main-text-color': colors.neutral650,
        '--desc-text-color': colors.neutral500,
        '--cursor': 'not-allowed',
      },
      outline: '1px solid var(--outline-color)',
      backgroundColor: 'var(--bg-color)',
      borderLeft: '3px solid var(--left-border-color)',
      padding: '6px 8px 6px 5px',
      fontSize: '13px',
      lineHeight: `${MAIN_LINE_HEIGHT}px`,
      fontWeight: 'var(--font-weight)' as any,
      color: 'var(--main-text-color)',
      transition: 'background-color 250ms, color 250ms, border-color 250ms',
      cursor: 'var(--cursor)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '8px',
      minHeight: '28px',
      overflow: 'hidden',
    },
    primary: {
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '8px',
      overflow: 'hidden',
      alignItems: 'center',
    },
    separator: {
      height: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      '&:before': {
        content: '""',
        height: '1px',
        backgroundColor: colors.secondaryButtonBackground,
      },
    },
    titleItem: {
      '&:focus': {
        outline: `1px solid ${colors.primaryCta}`,
      },
    },
    title: {
      textTransform: 'uppercase',
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '16px',
      color: colors.lowEmphasisText,
      padding: '2px 8px',
      maxWidth: '200px',
      '&.truncate': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    control: {
      flex: '0 0 auto',
    },
    icon: {
      flex: '0 0 auto',
      width: `${ICON_SIZE}px`,
      height: `${MAIN_LINE_HEIGHT}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > span': {
        // Removes white space when icon is inside a span tag (for Tooltip)
        display: 'flex',
      },
    },
    menuLabel: {
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    link: {
      display: 'block',
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '4px',
    },
    main: {
      fontSize: '13px',
      whiteSpace: 'nowrap',
      display: 'flex',
      // the earlyAccess icon has a clickable area of 16px in height so we need to vertically align
      alignItems: 'center',
      gap: '8px',
    },
    labelContent: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    shortcut: {
      opacity: 0.65,
      fontSize: '80%',
      flex: '0 0 auto',
    },
    description: {
      color: 'var(--desc-text-color)',
      fontSize: '12px',
      lineHeight: '16px',
    },
  }),
  { name: 'CommonMenuListItem' },
);

interface CommonMenuAndItemProps {
  // Overrides parent onClose, which takes two arguments
  onClose: () => void;
  closeOnSelect?: boolean;
  maxWidth?: number;
  // By default, if any menu item has an icon, all rows are rendered with space
  // provisioned for an icon, so menu items without a specified icon will show a
  // gap.  This allows the menu item labels to be left-aligned .  When
  // 'flushAlign' is true, icon space is not provisioned unless the menu item
  // specifies an icon.  As a result, all menu items are flush with the left
  // edge of the menu.
  flushAlign?: boolean;
  // Optionally show a check box with each menu list item
  showCheckBoxes?: boolean;
  // this shouldn't be passed from parents and is used only internally when we have nested menus
  level?: number;
  modal?: boolean;
  /** True if the CommonMenuHeading should wrap instead of truncate */
  wrapHeading?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
  /** Specifies the delay in milliseconds before the dropdown closes (default: 200) */
  closeDelay?: number;
  /** Specifies the position of the inner menu when the item has the `[items]` property */
  innerMenuPosition?: CommonMenuPosition;
  /** Specifies the transform applied to the inner menu when the item has the `[items]` property */
  innerMenuPositionTransform?: CommonMenuPositionTransform;
}

export interface CommonMenuProps extends CommonMenuAndItemProps {
  // An HTML element that's used to set the position of the menu.
  anchorEl: Element | null | undefined;
  // If true, the menu is opened
  open: boolean;
  // Optional max height for the menu. It's useful when the menu contains a user generated list
  maxHeight?: number;
  menuItems: CommonMenuItem[];
  // Setting this to true will not render the overlay on the background that closes the menu upon
  // clicking on it
  hideBackdrop?: boolean;
  // Setting this to true will not shift the focus to this Menu when it is opened
  disableFocus?: boolean;
  // How to position the menu relative to its anchor. Default position is "below-right" which
  // means the menu will start below the anchor and will grow to the right.
  position?: CommonMenuPosition;
  positionTransform?: CommonMenuPositionTransform;
  onMouseEnter?: () => void;
}

interface ItemProps extends CommonMenuAndItemProps {
  hasEndIcons: boolean;
  hasStartIcons: boolean;
  item: CommonMenuItem;
  scrollBarWidth: number;
  /** Whether to show the individual item with a checkbox. */
  toggleable: boolean;
}

function commonRowStyle(maxWidth?: number) {
  return { ...maxWidth && { maxWidth: `${maxWidth}px` } };
}

const Item = (props: ItemProps) => {
  const {
    closeOnSelect,
    flushAlign,
    hasEndIcons,
    hasStartIcons,
    item,
    level,
    maxWidth,
    modal,
    onClose,
    scrollBarWidth = 0,
    showCheckBoxes,
    toggleable,
    wrapHeading,
    tooltipPlacement,
    closeDelay = 200,
  } = props;

  const itemClasses = useItemStyles();

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const nestedAnchorRef = useRef<HTMLLIElement>(null);
  const [nestedOpen, setNestedOpen] = useState(false);

  const clearHideNestedMenuTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  // When we leave an item with nested menu we don't immediately hide the nested menu but allow
  // some timeout so the scroll to the nested menu can happen safely before disappearing.
  const handleMouseLeave = () => {
    clearHideNestedMenuTimeout();
    timeoutRef.current = setTimeout(() => {
      setNestedOpen(false);
    }, closeDelay);
  };

  const renderCheckBox = (checked: boolean, disabled: boolean) => {
    if (showCheckBoxes || toggleable) {
      return (
        <div className={itemClasses.control}>
          <Form.CheckBox checked={checked} disabled={disabled} onChange={() => { }} />
        </div>
      );
    }
    return <></>;
  };

  const renderIcon = (hasIcons: boolean, icon?: HelpfulIconSpec) => {
    if (hasIcons) {
      if (icon) {
        const svg = (
          <SvgIcon
            {...icon}
            {...getIconSpecDims(icon, ICON_SIZE, ICON_SIZE)}
          />
        );
        return (
          <div className={itemClasses.icon}>
            {icon.tooltip ? (
              <Tooltip title={icon.tooltip}>
                <span>{svg}</span>
              </Tooltip>
            ) : svg}
          </div>
        );
      }
      if (!flushAlign) {
        return (<div className={itemClasses.icon} />);
      }
    }
    return (<></>);
  };

  const renderSeparator = () => (
    <li className={itemClasses.separator} />
  );

  const renderHeading = (itemHeading: CommonMenuHeading, wrap?: boolean) => (
    <li className={itemClasses.titleItem} style={commonRowStyle(maxWidth)}>
      <Tooltip title={parseString(itemHeading.help)}>
        <div className={cx(itemClasses.title, !wrap && 'truncate')}>{itemHeading.title}</div>
      </Tooltip>
    </li>
  );

  const renderMenuItem = (menuItem: CommonMenuListItem) => {
    const {
      description,
      destructive,
      disabled = false,
      disabledReason,
      earlyAccess,
      endIcon,
      engaged = false,
      help,
      items,
      keyboardShortcut,
      label,
      link,
      nameAttribute,
      onClick,
      onMouseDown,
      selected = false,
      startIcon,
    } = menuItem;

    if (items?.length && !modal) {
      throw Error('Nested items are not supported with non-modal menus');
    }

    const newLevel = (level || 0) + 1;
    const commonStyle = commonRowStyle(maxWidth);

    const handleSelect = (event: React.MouseEvent | React.KeyboardEvent) => {
      if (items) {
        // if there's a submenu, we don't want to close the menu when this item is clicked.
        return;
      }
      if (!disabled) {
        onClick(event);
        closeOnSelect && onClose();
      }
    };

    const tooltipTitle = () => {
      if (disabled) {
        return parseString(disabledReason);
      }
      return typeof help === 'string' ? parseString(help) : help;
    };

    const innerContent = (
      <>
        <div className={itemClasses.primary}>
          {toggleable ? renderCheckBox(engaged, disabled) : renderCheckBox(selected, disabled)}
          {renderIcon(hasStartIcons, startIcon)}
          <div className={itemClasses.menuLabel}>
            <Tooltip
              placement={tooltipPlacement}
              title={tooltipTitle()}>
              <div className={itemClasses.content}>
                <div className={itemClasses.main}>
                  {/* If the label is a string, we should parse it so that we can show html tags
                   safely. If it's not a string, treat it as a JSX and render it directly. */}
                  <div
                    aria-label={typeof label === 'string' ? label : ''}
                    className={itemClasses.labelContent}
                    data-locator="commonMenuListItemLabel">
                    {typeof label === 'string' ? parseString(label) : label}
                  </div>
                  {earlyAccess && <EarlyAccessLink />}
                </div>
                {description &&
                  <div className={itemClasses.description}>{parseString(description)}</div>}
              </div>
            </Tooltip>
          </div>
          {keyboardShortcut && (
            <div className={itemClasses.shortcut}>{keyboardShortcut}</div>
          )}
          {renderIcon(hasEndIcons, endIcon)}
        </div>
        {items?.length ? renderIcon(true, { name: 'caretRight', maxHeight: 6 }) : null}
      </>
    );

    // Using native <li> here instead of MUI's MenuItem, because the latter
    // exposes no way to disable those infernal ripple effects.
    return (
      <li
        aria-disabled={disabled}
        className={cx(itemClasses.root, {
          destructive,
          disabled,
          selected,
          engaged: engaged && !toggleable,
        })}
        data-label={label}
        data-name={nameAttribute}
        onClick={link ?
          () => { } :
          (event) => {
            event.preventDefault();
            event.stopPropagation();
            handleSelect(event);
          }}
        onKeyUp={(event) => {
          if (isUnmodifiedEnterKey(event) || isUnmodifiedSpaceKey(event)) {
            handleSelect(event);
          }
          if (items && isUnmodifiedArrowRightKey(event)) {
            setNestedOpen(true);
          }
          if (items && isUnmodifiedArrowLeftKey(event)) {
            setNestedOpen(false);
          }
        }}
        onMouseDown={onMouseDown}
        onMouseEnter={() => {
          if (items) {
            setNestedOpen(true);
          }
        }}
        onMouseLeave={() => {
          if (items) {
            handleMouseLeave();
          }
        }}
        ref={nestedAnchorRef}
        role="menuitem"
        style={commonStyle}
        tabIndex={disabled ? -1 : 0}>
        {link ? (
          <a
            className={itemClasses.link}
            href={link.href}
            rel={link.external ? 'noopener noreferrer' : ''}
            target={link.external ? '_blank' : '_self'}>
            {innerContent}
          </a>
        ) : innerContent}
        {items?.length ? (
          <CommonMenu
            anchorEl={nestedAnchorRef.current}
            closeOnSelect={closeOnSelect}
            flushAlign={flushAlign}
            hideBackdrop // don't add another backdrop for the nested menus
            level={newLevel}
            menuItems={items}
            modal={modal}
            onClose={() => {
              setNestedOpen(false);
              onClose();
            }}
            onMouseEnter={() => clearHideNestedMenuTimeout()}
            open={nestedOpen}
            position={props.innerMenuPosition || 'right-down'}
            positionTransform={props.innerMenuPositionTransform || { left: 3 + scrollBarWidth }}
            showCheckBoxes={showCheckBoxes}
          />
        ) : null}
      </li>
    );
  };

  if ((item as CommonMenuSeparator).separator) {
    return renderSeparator();
  }
  if ((item as CommonMenuHeading).title !== undefined) {
    return renderHeading(item as CommonMenuHeading, wrapHeading);
  }
  return renderMenuItem(item as CommonMenuListItem);
};

// This is used to navigate between the items via the [up] and [down] keyboard keys
// when the menu is opened and some item is already focused.
const focusNextOrPrevItem = (
  listRef: React.RefObject<HTMLUListElement>,
  direction: 'up' | 'down',
) => {
  if (!listRef.current) {
    return;
  }

  const nodes = listRef.current.children;
  const activeItem = document.activeElement;

  const getActiveItemAfter = (anchor: number) => [...nodes].find((item, idx) => (
    idx > anchor &&
    item.getAttribute('tabindex') === '0' &&
    item.ariaDisabled !== 'true'
  ));

  const getActiveItemBefore = (anchor: number) => [...nodes].filter((item, idx) => (
    idx < anchor &&
    item.getAttribute('tabindex') === '0' &&
    item.ariaDisabled !== 'true'
  )).pop();

  for (let i = 0; i < nodes.length; i += 1) {
    // Find which item is currently focused and then go to the next/prev item. This properly
    // skips separator, title items and disabled items. If there is no available element
    // after/before the current focused item, it starts to look from the top/bottom of the list.
    if (activeItem === nodes[i]) {
      if (direction === 'down') {
        const el = getActiveItemAfter(i) || getActiveItemAfter(-1);
        (el as HTMLElement)?.focus();
      }

      if (direction === 'up') {
        const el = getActiveItemBefore(i) || getActiveItemBefore(nodes.length);
        (el as HTMLElement)?.focus();
      }
      break;
    }
  }
};

// Checks the size of the menu and the position of the anchor trigger and calculate the proper
// coordinates for the menu depending on the "position" property and the window edges.
const calculateMenuCoords = (
  anchorEl: Element,
  listRef: React.RefObject<HTMLUListElement>,
  position: CommonMenuPosition,
  positionTransform?: CommonMenuPositionTransform,
) => {
  const anchorRect = anchorEl.getBoundingClientRect();
  const menuWidth = listRef.current?.clientWidth || 0;
  const menuHeight = listRef.current?.clientHeight || 0;

  // The first word in the position phrase shows where the menu is relative to the anchor
  // element and the second word sets the direction along which it will expand.
  const getMenuInitialCoords = () => {
    switch (position) {
      case 'above-right': {
        return {
          top: anchorRect.top - menuHeight,
          left: anchorRect.left,
        };
      }
      case 'above-left': {
        return {
          top: anchorRect.top - menuHeight,
          left: anchorRect.right - menuWidth,
        };
      }
      case 'below-right': {
        return {
          top: anchorRect.top + anchorRect.height,
          left: anchorRect.left,
        };
      }
      case 'below-left': {
        return {
          top: anchorRect.top + anchorRect.height,
          left: anchorRect.right - menuWidth,
        };
      }
      case 'left-down': {
        return {
          top: anchorRect.top,
          left: anchorRect.left - menuWidth,
        };
      }
      case 'left-up': {
        return {
          top: anchorRect.bottom - menuHeight,
          left: anchorRect.left - menuWidth,
        };
      }
      case 'right-down': {
        return {
          top: anchorRect.top,
          left: anchorRect.right,
        };
      }
      case 'right-up': {
        return {
          top: anchorRect.bottom - menuHeight,
          left: anchorRect.right,
        };
      }
      default: {
        throw Error('Unknown CommonMenuPosition');
      }
    }
  };

  // Get the initial top/left coordinates of the menu by checking only where the anchor is
  const { top, left } = getMenuInitialCoords();

  // Adjust the coordinates if there are any manual transforms passed from parent components
  const transformedTop = top + (positionTransform?.top || 0);
  const transformedLeft = left + (positionTransform?.left || 0);

  // Make sure the window does not overflow from the window and allow a safe margin
  const minTopAllowed = MARGIN_THRESHOLD;
  const minLeftAllowed = MARGIN_THRESHOLD;

  const maxTopAllowed = window.innerHeight - menuHeight - MARGIN_THRESHOLD;
  const maxLeftAllowed = window.innerWidth - menuWidth - MARGIN_THRESHOLD;

  return {
    top: clamp(transformedTop, [minTopAllowed, maxTopAllowed]),
    left: clamp(transformedLeft, [minLeftAllowed, maxLeftAllowed]),
  };
};

export const CommonMenu = (props: CommonMenuProps) => {
  const {
    anchorEl,
    closeOnSelect,
    flushAlign,
    hideBackdrop,
    disableFocus,
    level = 0,
    menuItems,
    maxHeight,
    maxWidth,
    modal = true,
    onClose,
    onMouseEnter,
    open,
    showCheckBoxes,
    position = 'below-right',
    positionTransform,
    innerMenuPosition,
    innerMenuPositionTransform,
    wrapHeading,
    tooltipPlacement,
    closeDelay,
    ...otherProps
  } = props;

  const [menuCoords, setMenuCoords] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  // The #menus is placed in the main index.html file and that's where we attach the portals to.
  const menusNode = document.getElementById('menus');
  const menusNodeRef = useRef(menusNode);
  const listRef = useRef<HTMLUListElement>(null);

  const menusNodeSize = useResizeObserver(menusNodeRef);
  const menuClasses = useMenuStyles();

  // We need to know whether to provision icon space for those menu items that
  // don't specify a start/end icon.
  const hasStartIcons = menuItems.some((item) => !!(item as CommonMenuListItem).startIcon);
  const hasEndIcons = menuItems.some((item) => !!(item as CommonMenuListItem).endIcon);

  useEffect(() => {
    if (open && anchorEl) {
      // Avoid updating the coordinates in this case since the anchor element is not visible.
      if (anchorEl.getBoundingClientRect().width === 0 &&
        anchorEl.getBoundingClientRect().height === 0) {
        return;
      }
      // Set the coords upon opening the menu
      setMenuCoords(calculateMenuCoords(anchorEl, listRef, position, positionTransform));

      // When the menu is opened, the focus by default is on the trigger anchor (not on the menu).
      // This causes some issues like not being able to call the onClose when pressing [esc] or
      // not being able to immediatelly focus the first menu item when pressing [tab] on Chrome.
      // Moving the focus on the menu in the background fixes these issues.
      !disableFocus && listRef.current?.focus();

      // If the parent menu has a scrollbar, calculate its width and pass it down to the Item so
      // that it can set an appropriate left offset for the nested menu (if it exists).
      window.requestAnimationFrame(() => {
        if (!listRef.current) {
          return;
        }

        if (listRef.current.clientHeight < listRef.current.scrollHeight) {
          // Get the scrollbar width if it exists. Subtract the border (the 2px at the end).
          setScrollBarWidth(listRef.current?.offsetWidth - listRef.current?.clientWidth - 2);
        } else {
          setScrollBarWidth(0);
        }
      });
    }
  }, [open, menusNodeSize, anchorEl, listRef, position, positionTransform, disableFocus]);

  if (!open || !menusNode) {
    return null;
  }

  // Calculates a safe maximum height by subtracting a safe margin from the window height
  const maxAvailableHeight = menusNodeSize.height - (2 * MARGIN_THRESHOLD);

  const menuItemContent = menuItems.map((item: CommonMenuItem, i: number) => (
    <Item
      closeDelay={closeDelay}
      closeOnSelect={closeOnSelect}
      flushAlign={flushAlign}
      hasEndIcons={hasEndIcons}
      hasStartIcons={hasStartIcons}
      innerMenuPosition={innerMenuPosition}
      innerMenuPositionTransform={innerMenuPositionTransform}
      item={item}
      key={objectId(item)}
      level={level}
      maxWidth={maxWidth}
      modal={modal}
      onClose={onClose}
      scrollBarWidth={scrollBarWidth}
      showCheckBoxes={showCheckBoxes}
      toggleable={!!(item as CommonMenuListItem).toggleable}
      tooltipPlacement={tooltipPlacement}
      wrapHeading={wrapHeading}
    />
  ));

  const menuStyle = modal ? {
    maxHeight: maxHeight ? Math.min(maxHeight, maxAvailableHeight) : maxAvailableHeight,
    left: menuCoords.left,
    top: menuCoords.top,
  } : {};

  const menuContent = (
    <ul
      className={cx(menuClasses.root, { modal })}
      onKeyDown={(event) => {
        if (isUnmodifiedEscapeKey(event)) {
          onClose();
        }
        if (isUnmodifiedArrowDownKey(event)) {
          focusNextOrPrevItem(listRef, 'down');
        }
        if (isUnmodifiedArrowUpKey(event)) {
          focusNextOrPrevItem(listRef, 'up');
        }
      }}
      onMouseEnter={onMouseEnter}
      ref={listRef}
      role="menu"
      style={menuStyle}
      tabIndex={-1}
      {...otherProps}>
      {menuItemContent}
    </ul>
  );

  if (!modal) {
    return menuContent;
  }

  const rootContent = (
    <>
      {!hideBackdrop && (
        <div
          aria-hidden
          className={menuClasses.backdrop}
          onClick={() => onClose()}
          onKeyDown={(event) => {
            if (isUnmodifiedEscapeKey(event)) {
              onClose();
            }
          }}
        />
      )}
      {menuContent}
    </>
  );

  return ReactDOM.createPortal(rootContent, menusNode);
};

// Maps a list of ProtoDescriptor.Choice items to a list of CommonMenuItem items
export const paramChoicesToMenuItems = (
  choices: ProtoDescriptor.Choice[],
  clickChoice: (choice: ProtoDescriptor.Choice) => void,
  choiceDisabledReason?: (choice: ProtoDescriptor.Choice) => string,
) => (
  choices.map((choice: ProtoDescriptor.Choice) => {
    const disabledReason = choiceDisabledReason?.(choice) || '';
    return {
      label: choice.text,
      onClick: () => {
        clickChoice(choice);
        analytics.track(`Add ${choice.text}`, {
          choiceText: choice.text,
          choiceEnumNumber: choice.enumNumber,
        });
      },
      disabled: !!disabledReason,
      disabledReason,
    } as CommonMenuListItem;
  })
);
