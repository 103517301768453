// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';

import { getLocalStorageData } from '../../../lib/browserStorage';
import { localStorageEffect } from '../../../lib/persist';

const STORAGE_KEY = 'overlay-cards-width';
const DEFAULT_WIDTH = 250;

export const leftOverlayCardsWidthBase = atom<number>({
  key: 'leftOverlayCardsWidthBase',
  default: getLocalStorageData<number>(STORAGE_KEY, DEFAULT_WIDTH),
  effects: [
    localStorageEffect(STORAGE_KEY),
  ],
});

const leftOverlayCardsWidthState = selector<number>({
  key: 'leftOverlayCardsWidthState',
  get: ({ get }) => get(leftOverlayCardsWidthBase),
  set: ({ set }, newValue) => {
    // Instead of directly calling lcvHandler, we'll use a callback
    if (typeof window !== 'undefined' && typeof newValue === 'number') {
      window.requestAnimationFrame(() => {
        // Dynamically import lcvHandler to avoid circular dependency
        import('../../../lib/lcvis/handler/LcvHandler').then(({ lcvHandler }) => {
          lcvHandler.queueDisplayFunction('update triad', (display) => {
            display.updateTriadPosition();
          });
        }).catch(() => { });
      });

      set(leftOverlayCardsWidthBase, newValue);
    }
  },
});

export const useLeftOverlayCardsWidth = () => useRecoilState(leftOverlayCardsWidthState);
export const useLeftOverlayCardsWidthValue = () => useRecoilValue(leftOverlayCardsWidthState);

export const getLeftOverlayCardsWidth = () => {
  const storedValue = localStorage.getItem(STORAGE_KEY);
  return storedValue ? parseInt(storedValue, 10) : DEFAULT_WIDTH;
};
