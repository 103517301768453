// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/api/v0/luminarycloud/stopping_condition/stopping_condition.proto (package luminary.proto.api.v0.luminarycloud.stopping_condition, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { StoppingConditionOp } from "../../../../output/output_pb.js";

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.BasicStoppingConditions
 */
export const BasicStoppingConditions = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.BasicStoppingConditions",
  () => [
    { no: 1, name: "max_iterations", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "max_physical_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "op", kind: "enum", T: proto3.getEnumType(StoppingConditionOp) },
    { no: 4, name: "max_inner_iterations", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.StoppingCondition
 */
export const StoppingCondition = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.StoppingCondition",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "output_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "threshold", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "start_at_iteration", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "averaging_iterations", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "iterations_to_consider", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.GetBasicStoppingConditionsRequest
 */
export const GetBasicStoppingConditionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.GetBasicStoppingConditionsRequest",
  () => [
    { no: 1, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.GetBasicStoppingConditionsResponse
 */
export const GetBasicStoppingConditionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.GetBasicStoppingConditionsResponse",
  () => [
    { no: 1, name: "basic_stopping_conditions", kind: "message", T: BasicStoppingConditions },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.UpdateBasicStoppingConditionsRequest
 */
export const UpdateBasicStoppingConditionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.UpdateBasicStoppingConditionsRequest",
  () => [
    { no: 1, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "max_iterations", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "max_physical_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 4, name: "op", kind: "enum", T: proto3.getEnumType(StoppingConditionOp), opt: true },
    { no: 5, name: "max_inner_iterations", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.UpdateBasicStoppingConditionsResponse
 */
export const UpdateBasicStoppingConditionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.UpdateBasicStoppingConditionsResponse",
  () => [
    { no: 1, name: "basic_stopping_conditions", kind: "message", T: BasicStoppingConditions },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.CreateOrUpdateStoppingConditionRequest
 */
export const CreateOrUpdateStoppingConditionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.CreateOrUpdateStoppingConditionRequest",
  () => [
    { no: 1, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "output_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "threshold", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "start_at_iteration", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "averaging_iterations", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "iterations_to_consider", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.CreateOrUpdateStoppingConditionResponse
 */
export const CreateOrUpdateStoppingConditionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.CreateOrUpdateStoppingConditionResponse",
  () => [
    { no: 1, name: "stopping_condition", kind: "message", T: StoppingCondition },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.GetStoppingConditionRequest
 */
export const GetStoppingConditionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.GetStoppingConditionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.GetStoppingConditionResponse
 */
export const GetStoppingConditionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.GetStoppingConditionResponse",
  () => [
    { no: 1, name: "stopping_condition", kind: "message", T: StoppingCondition },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.ListStoppingConditionsRequest
 */
export const ListStoppingConditionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.ListStoppingConditionsRequest",
  () => [
    { no: 1, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.ListStoppingConditionsResponse
 */
export const ListStoppingConditionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.ListStoppingConditionsResponse",
  () => [
    { no: 1, name: "stopping_conditions", kind: "message", T: StoppingCondition, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.DeleteStoppingConditionRequest
 */
export const DeleteStoppingConditionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.DeleteStoppingConditionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.stopping_condition.DeleteStoppingConditionResponse
 */
export const DeleteStoppingConditionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.stopping_condition.DeleteStoppingConditionResponse",
  [],
);

