// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView } from '../lib/componentTypes/context';
import { RecoilProjectKey } from '../lib/persist';
import { currentViewState } from '../state/internal/global/currentView';

import { meshReadyStateAtom } from './useMeshReadyState';
import { surfaceToVolumeMapState } from './volumes';

export enum GeometryTreePositionType {
  FLOATING,
  NONE,
}

export const geometryTreePositionAtom = atomFamily<GeometryTreePositionType, RecoilProjectKey>({
  key: 'geometryTreePositionAtom',
  default: selectorFamily<GeometryTreePositionType, RecoilProjectKey>({
    key: 'geometryTreePositionAtom/default',
    get: (key: RecoilProjectKey) => ({ get }) => {
      const [
        surfaceToVolumeMap,
        meshReadyState,
        currentView,
      ] = get(waitForAll([
        surfaceToVolumeMapState(key.projectId),
        meshReadyStateAtom(key),
        currentViewState,
      ]));

      // We show the tree only when we have data for it or if we are the Geometry tab
      if ((meshReadyState && surfaceToVolumeMap.size) || currentView === CurrentView.GEOMETRY) {
        return GeometryTreePositionType.FLOATING;
      }

      return GeometryTreePositionType.NONE;
    },
  }),
});

export function useGeometryTreePosition(key: RecoilProjectKey) {
  return useRecoilValue(geometryTreePositionAtom(key));
}
