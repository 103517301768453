// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getSessionStorageData } from '../../../lib/browserStorage';
import { sessionStorageEffect } from '../../../lib/persist';

// Track the expanded state of the left nav and persist it in the sessionStorage.
// Also store the expanded sub sections within the nav, persisting them is not necessary.

const LEFT_NAV_EXPANDED_KEY = 'lcLeftNavExpanded';
const LEFT_NAV_HOVERED_KEY = 'lcLeftNavHovered';

type ExpandedValue = boolean;

// State for the expanded state of the whole side
const leftNavExpandedStore = atom<ExpandedValue>({
  key: 'leftNavStore',
  default: getSessionStorageData<ExpandedValue>(LEFT_NAV_EXPANDED_KEY, true),
  effects: [
    sessionStorageEffect(LEFT_NAV_EXPANDED_KEY),
  ],
});

export function useLeftNavExpandedState() {
  return useRecoilState(leftNavExpandedStore);
}
export function useLeftNavExpandedValue() {
  return useRecoilValue(leftNavExpandedStore);
}

// the layout is re-mounted when the page changes, we need to preserve the state to prevent
// the menu from collapsing during page transitions
const leftNavHoveredStore = atom<ExpandedValue>({
  key: 'leftNavHoveredStore',
  default: getSessionStorageData<ExpandedValue>(LEFT_NAV_HOVERED_KEY, false),
  effects: [
    sessionStorageEffect(LEFT_NAV_HOVERED_KEY),
  ],
});

export function useLeftNavHoveredState() {
  return useRecoilState(leftNavHoveredStore);
}

export function useSetLeftNavHovered() {
  return useSetRecoilState(leftNavHoveredStore);
}

// State for the expanded state of the individual sub sections
export type LeftNavSectionsState = {
  administration?: ExpandedValue,
  platform?: ExpandedValue,
  myaccount?: ExpandedValue,
}

const leftNavExpandedSectionsStore = atom<LeftNavSectionsState>({
  key: 'leftNavExpandedSectionsStore',
  default: {},
});

export function useLeftNavExpandedSectionsState() {
  return useRecoilState(leftNavExpandedSectionsStore);
}
export function useLeftNavExpandedSectionsValue() {
  return useRecoilValue(leftNavExpandedSectionsStore);
}
