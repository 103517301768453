// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import { colors, hexToRgb } from '../../lib/designSystem';

import { createStyles, makeStyles } from '.';

// 28 is the size for most of the control buttons and dropdowns in the properties panels.
// Sometimes the heading for a section/collapsible/links contains such control item which would grow
// the height of the header. To make stylings consistent, we should use that height as a default
// even for the cases without a control element.
export const CONTROL_HEIGHT = 28;

// Use for input-like layouts that consist of an embedded <input> plus other
// controls (spinners, prefix, suffix, etc.) or adornments.  In the future, we
// may want to create a component to encapsulate this layout.  See
// ../NumberSpinner.tsx for reference usage.
export const useCommonInputContainerStyles = makeStyles(
  () => createStyles({
    root: {
      '--border-color': 'transparent',
      '--text-color': colors.highEmphasisText,
      '--adornment-color': colors.lowEmphasisText,
      '&:focus-within': {
        '--border-color': colors.primaryCta,
      },
      '&.error': {
        '--border-color': colors.red500,
      },
      '&.warning': {
        '--border-color': colors.yellow600,
      },
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      padding: '0 4px',
      backgroundColor: colors.surfaceDark3,
      borderRadius: '4px',
      border: '0',
      fontSize: '13px',
      // Margin is often a misplaced implementation of external layout concerns.
      // Here, however, it's used to give the input's box-shadow (which doesn't
      // affect or contribute to document flow) room to render without getting
      // clipped.
      margin: '2px',
      minHeight: '20px',
      boxShadow: `inset 0 -1px 0 ${colors.surfaceLight2},
                  inset 0 1px 4px ${hexToRgb(colors.surfaceDark3, 0.1)},
                  0 0 0 1.4px var(--border-color)`,
      transition: 'box-shadow 250ms',
    },
    disabled: {
      backgroundColor: colors.surfaceLight2,
      '--text-color': colors.disabledType,
      '--adornment-color': colors.disabledType,
    },
    adornment: {
      color: 'var(--adornment-color)',
      fontSize: '0.9em',
      flex: '0 0 auto',
    },
    inputContainer: {
      flex: '1 1 auto',
      display: 'flex',
      '& > *': {
        flex: '1 1 auto',
      },
    },
    input: {
      boxSizing: 'border-box',
      width: '100%',
      minHeight: '28px',
      backgroundColor: colors.transparent,
      fontSize: '13px',
      lineHeight: 1.5,
      color: 'var(--text-color)',
      border: 0,
      margin: 0,
      padding: '0px 4px',
      boxShadow: 'none',
      '&.numeric': {
        textAlign: 'end',
      },
      '&:disabled': {
        color: colors.disabledType,
      },
      '&:focus': {
        outline: 0,
      },
    },
  }),
  { name: 'LDSInput' },
);

export const ROW_INNER_HEIGHT = 28;
const ROW_BORDER_SIZE = 1;
export const ROW_OUTER_HEIGHT = ROW_INNER_HEIGHT + (2 * ROW_BORDER_SIZE);

export const useCommonTreeRowStyles = makeStyles(
  () => createStyles({
    rowRoot: {
      '--control-opacity': 0,
      '--control-color': colors.neutral650,
      '--early-access-color': colors.primaryInteractive,
      '--early-access-color-hover': colors.primaryCta,
      '--control-focus-color': colors.primaryCta,
      '--toggle-color': colors.neutral700,
      '--inner-opacity': 1,
      '--sublabel-color': colors.neutral650,
      color: colors.highEmphasisText,
      cursor: 'default',
      fontSize: '13px',
      margin: '0',
      width: '100%',
      overflow: 'hidden',
      padding: 0,
      paddingRight: '8px',
      paddingLeft: 'calc(var(--depth, 0) * 24px)',
      userSelect: 'none',
      whiteSpace: 'nowrap',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '2px',
      border: `${ROW_BORDER_SIZE}px solid transparent`,
      backgroundColor: '#1e1f21',
      '&:hover': {
        '--control-opacity': 1,
        background: '#292a2c',
      },
      '&:focus': {
        '--toggle-color': colors.highEmphasisText,
        borderColor: colors.purple400,
        outline: 'none',
      },
    },
    subHighlighted: {
      '--control-color': colors.highEmphasisText,
      '--early-access-color': colors.highEmphasisText,
      '--early-access-color-hover': colors.lowEmphasisText,
      background: colors.purple200,
      '&:hover,&$hovered': {
        background: colors.neutral250,
      },
    },
    highlighted: {
      '--sublabel-color': colors.neutral700,
      '--control-color': colors.highEmphasisText,
      '--early-access-color': colors.highEmphasisText,
      '--early-access-color-hover': colors.lowEmphasisText,
      '--control-focus-color': colors.primaryInteractive,
      background: colors.purple400,
      fontWeight: 500,
      '&:hover': {
        background: colors.purple500,
      },
    },
    dropDestination: {
      '--sublabel-color': colors.neutral700,
      '--control-color': colors.highEmphasisText,
      '--early-access-color': colors.highEmphasisText,
      '--early-access-color-hover': colors.lowEmphasisText,
      '--control-focus-color': colors.primaryInteractive,
      background: colors.purple400,
    },
    dropDestinationChild: {
      backgroundColor: colors.neutral200,
    },
    outlined: {
      borderColor: colors.primaryCta,
    },
    hovered: {
      background: 'rgba(255, 255, 255, 0.05)',
    },
    dimmed: {
      '--inner-opacity': 0.5,
    },
    iconContainer: {
      flex: '0 0 auto',
      width: '12px',
      height: '12px',
      margin: 0,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 'var(--inner-opacity)',
      transition: 'opacity 250ms',
    },
    badge: {
      backgroundColor: 'transparent',
      width: '6px',
      height: '6px',
      bottom: '-2px',
      right: '-2px',
      position: 'absolute',
      borderRadius: '50%',
      '&.error': {
        backgroundColor: colors.red500,
      },
      '&.warning': {
        backgroundColor: colors.yellow500,
      },
      '&.success': {
        backgroundColor: colors.green500,
      },
      '&.info': {
        backgroundColor: colors.blue500,
      },
    },
    collapseToggle: {
      flex: '0 0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px',
      color: 'var(--toggle-color)',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 0,
      outline: 0,
      margin: 0,
      boxShadow: 'none',
      '&:disabled': {
        visibility: 'hidden',
      },
      '&:focus': {
        outline: 0,
        boxShadow: 'inset 0 0 0 1px var(--control-focus-color)',
      },
    },
    innerRow: {
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
      gap: '5px',
      height: ROW_INNER_HEIGHT,
    },
    rowText: {
      flex: '1 1 auto',
      lineHeight: '26px',
      padding: 0,
      paddingLeft: '1px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      gap: '4px',
      opacity: 'var(--inner-opacity)',
      transition: 'opacity 250ms',
    },
    rowName: {
      overflow: 'hidden',
      display: 'flex',
      flexGrow: 1,
      maxWidth: 'max-content',
      marginLeft: '-1px',
      marginRight: '-3px',
    },
    unnamedRow: {
      fontStyle: 'italic',
    },
    rowLabel: { // For text-only row text (i.e. not EditableText)
      paddingLeft: '2px',
      boxSizing: 'border-box',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '&.bold': {
        fontWeight: 600,
      },
    },
    rowSublabel: {
      fontSize: '12px',
      color: 'var(--sublabel-color)',
    },
    controls: {
      display: 'flex',
    },
    control: {
      display: 'flex',
      transition: 'opacity 250ms',
      '&.opaque': {
        '&.frozen': {
          '--control-opacity': 0.5,
        },
        opacity: 'var(--control-opacity)',
      },
      '&.colorable': {
        color: 'var(--control-color)',
      },
    },
    earlyAccess: {
      display: 'inline-flex',
      color: 'var(--early-access-color)',
      transition: 'color 250ms',
      '&:hover': {
        color: 'var(--early-access-color-hover)',
      },
    },
    auxIcons: {
      flex: '0 0 auto',
      display: 'flex',
    },
    auxIcon: {
      flex: '0 0 auto',
      display: 'flex',
      width: ROW_INNER_HEIGHT,
      height: ROW_INNER_HEIGHT,
      justifyContent: 'center',
      alignItems: 'center',
    },
    visualCues: {
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
      minWidth: 'max-content',
      paddingLeft: '2px',
    },
    semiVisible: {
      opacity: '0.3',
    },
  }),
  { name: 'SimulationTreeRow' },
);

// Layout styles common to simulation tree components
export const useCommonTreePropsStyles = makeStyles(
  () => createStyles({
    properties: {
      fontSize: '13px',

      '&.scrollable': {
        flexGrow: '1',
        overflowY: 'auto',
      },
    },
    sectionVerticalNodeLinks: {
      overflow: 'hidden',
      textAlign: 'end',
    },
    sectionDescription: {
      fontSize: '13px',
      lineHeight: 1.2,
      fontWeight: 400,
      color: colors.neutral650,
      padding: '0 0 12px',
    },
    sectionMessageContainer: {
      padding: '16px 0',
    },
    sectionMessages: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '16px',
    },
    selectorContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    },
    panelHeaderButtons: {
      display: 'flex',
    },
    typeRow: {
      display: 'flex',
      height: `${CONTROL_HEIGHT}px`,
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: '13px',
      gap: '8px',
    },
    typeKey: {
      color: colors.inputPlaceholderText,
      fontWeight: 600,
    },
    typeValue: {
      color: colors.highEmphasisText,
      fontWeight: 400,
    },
    nodeTableDescription: {
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 400,
      color: colors.neutral650,
      padding: '0 0 8px',
    },
    nodeTableFooter: {
      fontSize: '13px',
      lineHeight: 1.4,
      fontWeight: 400,
      color: colors.lowEmphasisText,
    },
    note: {
      color: colors.lowEmphasisText,
      fontSize: '13px',
    },
    gridPanel: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      borderRadius: '4px',
      border: `1px solid ${colors.surfaceLight1}`,
      padding: '12px',
      fontSize: '13px',
      gap: '4px 16px',
      alignItems: 'baseline',
      '& > .label': {
        color: colors.inputPlaceholderText,
        fontWeight: 600,
        padding: '6px 0',
        textAlign: 'start',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& > .value': {
        color: colors.highEmphasisText,
        fontWeight: 400,
        padding: '6px 0',
        textAlign: 'end',
        overflow: 'hidden',
      },
    },
  }),
  { name: 'CommonSimulationTreeProps' },
);

export const useCommonMultiInputLines = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '4px',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    line: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      gap: '8px',
      overflow: 'hidden',
    },
    input: {
      display: 'flex',
      flex: '0 0 30%',
    },
    control: {
      display: 'flex',
      flex: '0 0 auto',
    },
    label: {
      flex: '1 1 auto',
      lineHeight: 1.2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'CommonMultiInputLines' },
);

export const useCommonMotionFramePropStyles = makeStyles(
  () => createStyles({
    framePanelSummary: {
      marginTop: '8px',
      maxWidth: '100%',
      overflow: 'hidden',
      display: 'flex',
    },
    frameConfigSummary: {
      flex: '1 1 auto',
      overflow: 'hidden',
      fontSize: '13px',
      fontWeight: 400,
      color: colors.inputPlaceholderText,
      height: `${CONTROL_HEIGHT}px`,
      lineHeight: `${CONTROL_HEIGHT}px`,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'MotionFrameProps' },
);

export const PROP_PANEL_GAP = 8;

export const useCommonPropPanelRowStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      alignItems: 'baseline',
      gap: `${PROP_PANEL_GAP}px`,
      margin: 0,
      marginTop: 'var(--layout-props-panel-row-separation)',
      '&:first-child:not(.lead)': {
        marginTop: 0,
      },
      '& > *:nth-child(1)': {
        flex: '1 0 40%',
      },
      '& > *:nth-child(2)': {
        flex: '1 1 60%',
        // The max-width allows the text-overflow: ellipsis to work on children. Without it, we need
        // overflow: hidden, but it cuts box-shadow unnecessarily so we should avoid it if possible.
        maxWidth: `calc(60% - ${PROP_PANEL_GAP}px)`,
      },
      '&.favorLabel': {
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > *:nth-child(1)': {
          flex: '1 1 auto',
        },
        '& > *:nth-child(2)': {
          flex: '0 0 auto',
        },
      },
    },
  }),
  { name: 'PropPanelRow' },
);

export const useCommonTablePageStyles = makeStyles(
  () => createStyles({
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '16px',
    },
  }),
  { name: 'TablePage' },
);

export const useCommonUserTableStyles = makeStyles(
  () => createStyles({
    enabled: {
      background: colors.green500,
    },
    pending: {
      background: colors.neutral750,
      color: colors.neutral50,
    },
    disabled: {
      background: colors.orange800,
      color: colors.orange50,
    },
    status: {
      borderRadius: '4px',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
      padding: '2px 8px',
    },
    confirmationContent: {
      color: colors.lowEmphasisText,
    },
    confirmationEmphasis: {
      fontWeight: 700,
      color: colors.highEmphasisText,
    },
    menuButton: {
      backgroundColor: 'transparent',
      color: 'inherit',
      border: 0,
      outline: 0,
      padding: '8px',
      margin: 0,
      cursor: 'pointer',
      borderRadius: '4px',
      display: 'flex',
      transition: 'background-color 250ms',
      '&:hover': {
        backgroundColor: colors.surfaceMedium1,
      },
    },
  }),
  { name: 'AdminSupportBody' },
);

export const useFloatingCollapsiblePanelStyles = makeStyles(() => createStyles({
  root: {
    '& > div > div[role="tab"]': {
      backgroundColor: colors.neutral150,
    },
  },
  closePanelButton: {
    paddingRight: '8px',
  },
  content: {
    background: 'rgba(31, 32, 34, 0.90)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '0 12px 12px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  cancelButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}), { name: 'FloatingCollapsiblePanel' });
