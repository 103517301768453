// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as cadmetadatapb from '../proto/cadmetadata/cadmetadata_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as lcmeshpb from '../proto/lcn/lcmesh_pb';
import * as lcsolnpb from '../proto/lcn/lcsoln_pb';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';
import { EntityGroupData } from '../recoil/entityGroupState';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import { newAdFloat } from './adUtils';
import { getNonSlipWallSurfaces, getWallSurfacesWithTags } from './boundaryConditionUtils';
import { SvgIconSpec } from './componentTypes/svgIcon';
import { expandGroupsExcludingTags, unwrapSurfaceIds, unwrapSurfaceIdsNoEntityGroups } from './entityGroupUtils';
import { BoundingBox, expandBoundingBox } from './geometry';
import newInt from './intUtils';
import { areSetsEquivalent, intersects } from './lang';
import { formatNumberCondensed, fromBigInt } from './number';
import { Logger } from './observability/logs';
import { extname, isLcExtension } from './path';
import * as rpc from './rpc';
import { mapVolumeIdsToIndices, volumeNodeId } from './volumeUtils';

export const BOUNDARY_ID = '__meshBoundary';
export const MODEL_ID = '__meshModel';
export const SIZE_ID = '__meshSize';

export const ADAPTATION_BOUNDARY_LABEL = 'Adaptation Boundary Layer';
export const BOUNDARY_LABEL = 'Boundary Layer';
export const MODEL_LABEL = 'Model';
export const SIZE_LABEL = 'Mesh Size';

const logger = new Logger('mesh.ts');

const { MAX, MIN, TARGET } = meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType;

export const COMMON_START_ICON: SvgIconSpec = {
  name: 'plus',
  maxHeight: 11,
};

export type nullableOptions = meshgenerationpb.UserMeshingParams | null;
export type nullableMeshing = meshgenerationpb.MeshingMultiPart | null;

// Result of parsing the LCN mesh metadata.
export interface MeshMetadata {
  // Structure of the mesh file. If internalUrl is a *.lcsoln, this field stores the
  // metadata of the matching mesh file.
  meshMetadata: lcmeshpb.MeshFileMetadata,
  // Structure of the soln file. Set iff internalUrl is a *.lcsoln* file.
  solnMetadata: lcsolnpb.SolnFileMetadata | null,
}

export interface AggregateStats {
  hasMesh: boolean;
  boundingBoxes: {
    mesh?: BoundingBox;
    surfaces: Record<string, BoundingBox>;
    volumes: Record<string, BoundingBox>;
  }
  counters: {
    controlVolume: number;
    face: number;
    point: number;
    surface: number;
  };
}

export function isDefaultMeshMode(meshMultiPart: nullableMeshing) {
  if (meshMultiPart?.meshingMode) {
    const mode = meshMultiPart.meshingMode.mode.case;
    return (mode === 'default' || !mode);
  }
  return true;
}

export function meshParamsUrl(simParam: simulationpb.SimulationParam, readOnly: boolean): string {
  return (readOnly && simParam.input?.url) || '';
}

export function meshParamsId(simParam: simulationpb.SimulationParam, readOnly: boolean): string {
  return (readOnly && simParam.input?.meshIdentifier?.id) || '';
}

// Return an initial bounding box with miniscule extents
export function defaultBoundingBox() {
  return {
    min: { x: 1e20, y: 1e20, z: 1e20 },
    max: { x: -1e20, y: -1e20, z: -1e20 },
  };
}

// Return an initial AggregateStats objects
function defaultStats(): AggregateStats {
  return {
    hasMesh: false,
    boundingBoxes: {
      mesh: undefined,
      volumes: {},
      surfaces: {},
    },
    counters: {
      controlVolume: 0,
      face: 0,
      point: 0,
      surface: 0,
    },
  };
}

// Return an AggregateStats object derived from mesh metadata
export function meshAggregateStats(meshMetadata?: lcmeshpb.MeshFileMetadata): AggregateStats {
  const initStats = defaultStats();

  if (meshMetadata) {
    return meshMetadata.zone.reduce((result, zone, volumeIdx) => {
      if (!zone.stats) {
        return result;
      }
      const zoneStats = zone.stats!;
      const volumeId = volumeNodeId(volumeIdx);
      result.hasMesh = true;
      result.counters.controlVolume += fromBigInt(zoneStats.nCvs);
      result.counters.point += fromBigInt(zoneStats.nPoints);
      result.counters.face += fromBigInt(zoneStats.nFaces);
      result.counters.surface += zone.bound!.length;
      result.boundingBoxes.mesh = expandBoundingBox(
        result.boundingBoxes.mesh || defaultBoundingBox(),
        zoneStats,
      );
      zone.bound.forEach((bound) => {
        const surfaceId = bound.name;
        if (!bound.stats) {
          return;
        }
        const boundStats = bound.stats!;
        result.boundingBoxes.surfaces[surfaceId] = expandBoundingBox(
          result.boundingBoxes.surfaces[surfaceId] || defaultBoundingBox(),
          boundStats,
        );
        result.boundingBoxes.volumes[volumeId] = expandBoundingBox(
          result.boundingBoxes.volumes[volumeId] || defaultBoundingBox(),
          boundStats,
        );
      });
      return result;
    }, initStats);
  }

  return initStats;
}

function meshHeadingText(baseText: string, index: number) {
  return (index === 0) ? `Default ${baseText}` : `${baseText} ${index}`;
}

export function sizeHeading(index: number) {
  return meshHeadingText(SIZE_LABEL, index);
}

export function adaptationBoundaryHeading(index: number) {
  return meshHeadingText(ADAPTATION_BOUNDARY_LABEL, index);
}

export function boundaryHeading(index: number) {
  return meshHeadingText(BOUNDARY_LABEL, index);
}

export function modelHeading(index: number) {
  return meshHeadingText(MODEL_LABEL, index);
}

// For a given meshing boundary layer index and prospective list of surface IDs, return indices of
// all other boundary layer params that are already assigned one or more those surface IDs.
export function conflictingMeshBoundaryLayerSurfaces(
  boundaryIndex: number,
  surfaceIds: string[],
  blParamsList: meshgenerationpb.MeshingMultiPart_BoundaryLayerParams[]
    | simulationpb.BoundaryLayerProfile[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  const unrolledSurfaceIds = unwrapSurfaceIds(surfaceIds, geometryTags, entityGroupData);

  return [...blParamsList].reduce((result, params, currentBoundaryIndex) => {
    const isCurrentBoundary = currentBoundaryIndex === boundaryIndex;

    if (isCurrentBoundary) {
      return result;
    }

    const hasConflictingSurfaces = unwrapSurfaceIds(params.surfaces, geometryTags, entityGroupData)
      .some((id) => unrolledSurfaceIds.includes(id));

    if (hasConflictingSurfaces) {
      return [...result, currentBoundaryIndex];
    }

    return result;
  }, [] as number[]);
}

const BlSelectionType = meshgenerationpb.MeshingMultiPart_BoundaryLayerParams_SelectionType;
const ModelSelectionType = meshgenerationpb.MeshingMultiPart_ModelParams_SelectionType;
const VolumeSelectionType = meshgenerationpb.MeshingMultiPart_VolumeParams_SelectionType;

// For a given meshing model index and prospective list of surface IDs, return indices of all other
// model params that are already assigned one or more those surface IDs.
export function conflictingMeshModelSurfaces(
  modelIndex: number,
  surfaceIds: Set<string>,
  meshMultiPart: meshgenerationpb.MeshingMultiPart,
  geometryTags: GeometryTags,
) {
  const inputSurfaces = new Set(
    unwrapSurfaceIdsNoEntityGroups(Array.from(surfaceIds), geometryTags),
  );
  return meshMultiPart.modelParams.reduce((result, params, i) => {
    const surfaces = unwrapSurfaceIdsNoEntityGroups(params.surfaces, geometryTags);
    if (
      (i !== modelIndex) &&
      surfaces.some((id) => inputSurfaces.has(id))
    ) {
      result.push(i);
    }
    return result;
  }, [] as number[]);
}

// For a given meshing volume params index and prospective list of volume IDs, return indices of
// all other volume params that are already assigned one or more those volume IDs.
export function conflictingMeshParamVolumes(
  volumeParamsIndex: number,
  volumeIds: string[],
  meshMultiPart: meshgenerationpb.MeshingMultiPart,
  staticVolumes: StaticVolume[],
) {
  const volumeIndices = mapVolumeIdsToIndices(volumeIds, staticVolumes);
  return meshMultiPart.volumeParams.reduce((result, params, i) => {
    if ((i !== volumeParamsIndex) && intersects(params.volumes, volumeIndices)) {
      result.push(i);
    }
    return result;
  }, [] as number[]);
}

/**
 * Issues a ReadIndex RPC to get the mesh or solution metadata for the given URL.  It then extracts
 * the lcmesh.MeshFileMetadata object from a ReadFileIndex RPC reply.  If the reply contains
 * multiple metadata objects (the case for a lcsoln file), it returns the first object that can be
 * parsed as MeshFileMatadata. internalUrl is the value of
 * analyzer.ReadFileIndexReply.internal_url.
 */
export async function readMeshMetadata(projectId: string, url: string): Promise<MeshMetadata> {
  if (!url) {
    throw Error('no url');
  }
  // This may happen temporarily while meshUrl is being updated.
  if (!isLcExtension(extname(url))) {
    const meshMetadata = new lcmeshpb.MeshFileMetadata();
    const solnMetadata = null;
    logger.error("readMeshMetadata: url doesn't have a valid extension: url=%s", url);
    return { meshMetadata, solnMetadata };
  }
  // TODO(saito) create a global cache of metadata. It's silly to talk to
  // the server every time.
  const req = new frontendpb.ReadFileIndexRequest({
    projectId,
    url,
  });
  const reply = await rpc.callRetry('ReadFileIndex', rpc.client.readFileIndex, req);
  // Fish out the mesh metadata. Note that the ReadFileIndex reply will contain multiple metadata
  // objects when the file is a solution; one for the solution itself, and another for the mesh
  // from which the solution was computed.
  let meshMetadata: lcmeshpb.MeshFileMetadata | null = null;
  let solnMetadata: lcsolnpb.SolnFileMetadata | null = null;

  reply.metadata.forEach((binaryData) => {
    const mesh = new lcmeshpb.MeshFileMetadata();
    if (binaryData.unpackTo(mesh)) {
      meshMetadata = mesh;
    }
    const soln = new lcsolnpb.SolnFileMetadata();
    if (binaryData.unpackTo(soln)) {
      solnMetadata = soln;
    }
  });
  if (!meshMetadata) {
    throw Error(`failed to parse file metadata: type=${reply.metadata}`);
  }
  return { meshMetadata, solnMetadata };
}

// Reconcile the boundary settings with the simulation params. As the inital param is the only
// one with the selection dropdown, it is the only one we need to reconcile, and only where
// WALL_NO_SLIP or ALL is selected. This method mutates the input argument meshingMultiPart.
function reconcileBoundarySettings(
  meshingMultiPart: meshgenerationpb.MeshingMultiPart,
  simParam: simulationpb.SimulationParam,
  meshSurfaces: string[],
  geometryTags: GeometryTags,
) {
  const blParam = meshingMultiPart.blParams[0];
  switch (blParam.selection) {
    case BlSelectionType.WALL_NO_SLIP:
      blParam.surfaces = getNonSlipWallSurfaces(simParam, geometryTags);
      break;
    case BlSelectionType.ALL:
      blParam.surfaces = meshSurfaces;
      break;
    default:
      break;
  }
}

// Reconcile the model settings with the simulation param. This will only make changes for the
// first param if WALL or ALL is selected. This method mutates the input argument meshingMultiPart.
function reconcileModelSettings(
  meshingMultiPart: meshgenerationpb.MeshingMultiPart,
  simParam: simulationpb.SimulationParam,
  meshSurfaces: string[],
  geometryTags: GeometryTags,
) {
  const modelParam = meshingMultiPart.modelParams[0];
  switch (modelParam.selection) {
    case ModelSelectionType.WALL:
      modelParam.surfaces = getWallSurfacesWithTags(simParam, geometryTags);
      break;
    case ModelSelectionType.ALL:
      modelParam.surfaces = meshSurfaces;
      break;
    default:
      break;
  }
}

// Reconcile the volume settings with the provided cad metadata. This will only make changes for the
// first param if ALL is selected. This method mutates the input argument meshingMultiPart.
function reconcileVolumeSettings(
  meshingMultiPart: meshgenerationpb.MeshingMultiPart,
  cadMetadata: cadmetadatapb.CadMetadata,
) {
  const numVols = Math.max(cadMetadata.nBodies, 1);
  const volumeParam = meshingMultiPart.volumeParams[0];
  switch (volumeParam.selection) {
    case VolumeSelectionType.ALL:
      volumeParam.volumes = [];
      for (let i = 0; i < numVols; i += 1) {
        volumeParam.volumes.push(BigInt(i));
      }
      break;
    default:
      break;
  }
}

// LC-15789 When the params/settings change for a simulation, we used to not update the meshing
// settings.  However, if the user has selected options like "All wall surfaces" or "All volumes",
// they will expect these settings to behave properly. To that end, we implement a process of
// "reconciling" meshing settings with the available surfaces, volumes & settings.
export function reconcileMeshingSettings(
  settings: meshgenerationpb.MeshingMultiPart,
  simParam: simulationpb.SimulationParam,
  cadMetadata: cadmetadatapb.CadMetadata,
  meshSurfaces: string[],
  geometryTags: GeometryTags,
): meshgenerationpb.MeshingMultiPart {
  const newSettings = settings.clone();
  reconcileBoundarySettings(newSettings, simParam, meshSurfaces, geometryTags);
  reconcileModelSettings(newSettings, simParam, meshSurfaces, geometryTags);
  reconcileVolumeSettings(newSettings, cadMetadata);
  return newSettings;
}

/**
 * Updates the selection values using the params
 */
export function updateMeshSelectionsFromParam(
  meshMultiPart: meshgenerationpb.MeshingMultiPart,
  simParam: simulationpb.SimulationParam,
  cadMetadata: cadmetadatapb.CadMetadata,
  meshSurfaces: string[],
  geometryTags: GeometryTags,
) {
  const boundary0 = meshMultiPart.blParams[0];
  const blSurfaces = boundary0.surfaces;
  // Determine if blSurfaces is equal to no surfaces, all surfaces, or the wall non-slip surfaces.
  if (blSurfaces.length === 0) {
    boundary0.selection = BlSelectionType.NONE;
  } else if (areSetsEquivalent(blSurfaces, meshSurfaces)) {
    boundary0.selection = BlSelectionType.ALL;
  } else if (areSetsEquivalent(blSurfaces, getNonSlipWallSurfaces(simParam, geometryTags))) {
    boundary0.selection = BlSelectionType.WALL_NO_SLIP;
  } else {
    boundary0.selection = BlSelectionType.SELECTED;
  }

  const model0 = meshMultiPart.modelParams[0];
  const modelSurfaces = model0.surfaces;
  // Determine if modelSurfaces is equal to no surfaces, all surfaces, or the wall surfaces.
  if (modelSurfaces.length === 0) {
    model0.selection = ModelSelectionType.NONE;
  } else if (areSetsEquivalent(modelSurfaces, meshSurfaces)) {
    model0.selection = ModelSelectionType.ALL;
  } else if (areSetsEquivalent(modelSurfaces, getWallSurfacesWithTags(simParam, geometryTags))) {
    model0.selection = ModelSelectionType.WALL;
  } else {
    model0.selection = ModelSelectionType.SELECTED;
  }

  const volume0 = meshMultiPart.volumeParams[0];
  const volumesList = volume0.volumes;
  // Determine if the volume list contains all the volumes.
  if (volumesList.length === cadMetadata.nBodies) {
    volume0.selection = VolumeSelectionType.ALL;
  } else {
    volume0.selection = VolumeSelectionType.SELECTED;
  }
}

/**
   * Updates the mesh name for given meshId
   * @param meshId ID of target mesh
   * @param newName New mesh name
   */
export async function renameExistingMesh(meshId: string, newName: string) {
  const req = new frontendpb.UpdateMeshRequest({ id: meshId, name: newName });
  const reply = await rpc.callRetry('UpdateMesh', rpc.client.updateMesh, req);
  if (!reply.mesh) {
    throw Error(`Unable to update mesh, meshId=${meshId}`);
  }
  return reply.mesh;
}

/**
   * Deletes the mesh with the given meshId
   * @param meshId ID of target mesh
   */
export async function deleteMeshAPI(meshId: string) {
  const req = new frontendpb.DeleteMeshRequest({ id: meshId });
  const reply = await rpc.callRetry('DeleteMesh', rpc.client.deleteMesh, req);
  if (!reply) {
    throw Error(`Unable to delete mesh, meshId=${meshId}`);
  }
}

export async function getMeshMetadata(meshId: string) {
  if (!meshId) {
    return;
  }
  const req = new frontendpb.GetMeshMetadataRequest({ meshId });
  const reply = await rpc.callRetry('GetMeshMetadata', rpc.client.getMeshMetadata, req);
  if (reply && !reply.meshMeta) {
    throw Error(`Unable to get mesh metadata, meshId=${meshId}`);
  }
  return reply!.meshMeta!;
}

export function createMeshSubtitleFromStats(stats: AggregateStats) {
  const cvs = stats.counters.controlVolume;
  const points = stats.counters.point;

  return `${formatNumberCondensed(cvs)} CVs, ${formatNumberCondensed(points)} points`;
}

export async function getMeshStatsAndCreateSubtitle(meshId: string) {
  const meta = await getMeshMetadata(meshId);
  const stats = meshAggregateStats(meta);

  return createMeshSubtitleFromStats(stats);
}

export async function getMeshInfoFromMeshId(meshId: string) {
  const req = new frontendpb.GetMeshInfoRequest({ meshId });
  if (!meshId) {
    return undefined;
  }
  const reply = await rpc.callRetry('MeshInfo', rpc.client.getMeshInfo, req);
  if (!reply.mesh) {
    throw Error(`Unable to retrieve mesh info for meshId=${meshId}`);
  }
  return reply.mesh;
}

/**
 * This function reconciles the incoming sizing parameters to ensure that the min size is less than
 * the max size and that the model size is within the range of the mesh sizes.
 *
 * @param meshMultiPart The meshing multi part object to reconcile
 */
export function reconcileIncomingSizingParams(
  meshMultiPart: meshgenerationpb.MeshingMultiPart | undefined,
) {
  if (!meshMultiPart?.volumeParams.length || !meshMultiPart?.modelParams.length) {
    return;
  }
  const volume0 = meshMultiPart.volumeParams[0];
  const model0 = meshMultiPart.modelParams[0];

  const minMeshSize = volume0.minSize;
  const maxMeshSize = volume0.maxSize;
  const modelSize = model0.maxSize;

  // If the mesh min size is not less than the max size, set to default min size
  if (minMeshSize >= maxMeshSize) {
    volume0.minSize = 0.0001;
  }
  // If model size is outside the range of mesh sizes, set it to the max mesh size
  if (modelSize < minMeshSize || modelSize > maxMeshSize) {
    model0.maxSize = maxMeshSize;
  }
}

/**
 * Check the selection fields for the volume, model, and boundary layer params and set each to a
 * compatible value. The first of each list is the only one with a selection field. If the selection
 * does not match the number of volumes or surfaces, set the selection to SELECTED.
 *
 * @param meshMultiPart
 * @param totalVolumes number
 * @param totalSurfaces number
 * @returns
 */
export function reconcileSelectionFields(
  meshMultiPart: meshgenerationpb.MeshingMultiPart | undefined,
  totalVolumes: number,
  totalSurfaces: number,
) {
  if (!meshMultiPart) {
    return;
  }

  // Iterate through BLParams, VolumeParams, and ModelParams to ensure that the selection field is
  // set correctly.
  const { volumeParams, modelParams, blParams } = meshMultiPart;

  // We only need to check the params if the selection is ALL (default). In the future, this value
  // will be stored properly in the backend. If the value is anything but default, it must have come
  // from the backend.
  if (volumeParams.length && volumeParams[0].selection === VolumeSelectionType.ALL) {
    const volumes = volumeParams[0].volumes.length;
    if (volumes !== totalVolumes) {
      volumeParams[0].selection = VolumeSelectionType.SELECTED;
    }
  }

  if (modelParams.length && modelParams[0].selection === ModelSelectionType.ALL) {
    const surfaces = modelParams[0].surfaces.length;
    if (surfaces !== totalSurfaces) {
      modelParams[0].selection = ModelSelectionType.SELECTED;
    }
  }

  if (blParams.length && blParams[0].selection === BlSelectionType.ALL) {
    const surfaces = blParams[0].surfaces.length;
    if (surfaces !== totalSurfaces) {
      blParams[0].selection = BlSelectionType.SELECTED;
    }
  }
}

/**
 * Converts the boundary layer params of MeshingMultiPart to adaptation boundary layer params needed
 * for mesh adaptation.
 *
 * @param blParams Current boundary layer params
 * @returns List of adaptation boundary layer params
 */
export function convertBLParamsToAdaptationBLParams(
  blParams: meshgenerationpb.MeshingMultiPart_BoundaryLayerParams[] | undefined,
) {
  return blParams?.map((blParam) => {
    const { growthRate, initialSize, nLayers, surfaces } = blParam;
    return new simulationpb.BoundaryLayerProfile({
      nLayers: newInt(nLayers),
      initialSize: newAdFloat(initialSize),
      growthRate: newAdFloat(growthRate),
      surfaces,
    });
  }) ?? [];
}

const complexityTypeMap = new Map<
  meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType,
  string
>([[MAX, 'Max Count'], [MIN, 'Minimal Count'], [TARGET, 'Target Count']]);

export function getComplexityLabel(
  value: meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType = MAX,
): string {
  return complexityTypeMap.get(value)!;
}

/**
 * Assigns selected surfaces to the specified boundary layer or model surfaces.
 *
 * This function first checks if the selected surfaces are already assigned to a layer.
 * If so, they are unassigned from the existing layer. When layers use tags or groups,
 * these are expanded and verified individually. Finally, all selected surfaces are
 * assigned to the layer specified by `boundaryIndex`.
 *
 */
export function assignSurfaceToBoundaryLayer(
  layers: meshgenerationpb.MeshingMultiPart_BoundaryLayerParams[]
    | simulationpb.BoundaryLayerProfile[]
    | meshgenerationpb.MeshingMultiPart_ModelParams[],
  boundaryIndex: number,
  selectedSurfaces: string[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  const unwrappedSurfacesToSelect = unwrapSurfaceIds(
    selectedSurfaces,
    geometryTags,
    entityGroupData,
  );

  layers.forEach((layer, layerIndex) => {
    if (layerIndex === boundaryIndex) {
      return;
    }

    layer.surfaces = layer.surfaces.reduce<string[]>((result, itemId) => {
      const currentItemSurfaces = unwrapSurfaceIds([itemId], geometryTags, entityGroupData);
      const surfacesIntersect = intersects(currentItemSurfaces, unwrappedSurfacesToSelect);

      if (!surfacesIntersect) {
        return [...result, itemId];
      }

      return [
        ...result,
        ...currentItemSurfaces.filter((id) => !unwrappedSurfacesToSelect.includes(id)),
      ];
    }, []);
  });

  // attach new selection to the current boundary layer (without unrolling tags since the underlying
  // components require all groups to be unrolled but they support tags).
  const expandedSurfaces = expandGroupsExcludingTags(entityGroupData, selectedSurfaces);
  layers[boundaryIndex].surfaces = expandedSurfaces;
}
