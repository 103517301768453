// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { Message } from '@bufbuild/protobuf';
import { useRecoilValue } from 'recoil';

import * as ProtoDescriptor from '../../../ProtoDescriptor';
import { getQuantityUnit } from '../../../QuantityDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../../../SimulationParamDescriptor';
import * as flags from '../../../flags';
import { ParamScope, chainParamScopes, createParamScope } from '../../../lib/ParamScope';
import { getAdValue, newScalarAdVector } from '../../../lib/adUtils';
import { isBoundaryConditionCompatibleWithRoughness } from '../../../lib/boundaryConditionUtils';
import { EMPTY_VALUE } from '../../../lib/constants';
import { colors } from '../../../lib/designSystem';
import { getMaterialFluid, getMaterialName, getMaterialSolid, isMaterialFluid } from '../../../lib/materialUtils';
import { getComplexityLabel, meshAggregateStats } from '../../../lib/mesh';
import { extractCoordinatesFromTransforms, getFrameDefaultRotationalVelocity, getFrameDefaultTranslationVelocity, orderedFrames } from '../../../lib/motionDataUtils';
import { formatNumber, formatNumberList, fromBigInt, toPositiveAbsoluteInteger } from '../../../lib/number';
import { Logger } from '../../../lib/observability/logs';
import { findFluidPhysicsMaterial, getBoundaryConditionsForPhysics, getPhysicsId, getPhysicsName, makeInitializationId } from '../../../lib/physicsUtils';
import { extractProtoField } from '../../../lib/proto';
import { getCompatibleTablesMap } from '../../../lib/rectilinearTable/globalMap';
import { FanCurveTableDefinition, ProfileBCTableDefintion, RadialDistributionTableDefinition } from '../../../lib/rectilinearTable/model';
import { getReferenceGeometricQuantity, getReferencePressure, getReferenceTemperature, getReferenceVelocity } from '../../../lib/referenceValueUtils';
import { getBoundaryCondName, getRunButtonText } from '../../../lib/simulationTree/utils';
import { isSimulationImplicitTime, isSimulationTransient } from '../../../lib/simulationUtils';
import { isGeometryFile } from '../../../lib/upload/uploadUtils';
import { useGeneral } from '../../../model/hooks/useGeneral';
import { useSolutionOutput } from '../../../model/hooks/useSolutionOutput';
import { useTime } from '../../../model/hooks/useTime';
import * as basepb from '../../../proto/base/base_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { ReferenceValueType } from '../../../proto/output/reference_values_pb';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import { useFrontendMenuState } from '../../../recoil/frontendMenuState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useJobNameMap } from '../../../recoil/jobNameMap';
import { useMeshMetadata, useMeshUrlState } from '../../../recoil/meshState';
import { useOutputNodes } from '../../../recoil/outputNodes';
import { useEnabledExperiments } from '../../../recoil/useExperimentConfig';
import { useInputFilename } from '../../../recoil/useInputFilename';
import useMeshMultiPart from '../../../recoil/useMeshingMultiPart';
import { projectActiveMeshSelector } from '../../../recoil/useProjectActiveMesh';
import { useIsBaselineMode } from '../../../recoil/useProjectPage';
import { useStoppingConditions } from '../../../recoil/useStoppingConditions';
import { useSkipSetupSummary } from '../../../recoil/user';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useBatchModeChecked, useCurrentConfig } from '../../../recoil/workflowConfig';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useSimulationBoundaryNames } from '../../../state/external/project/simulation/param/boundaryNames';
import { useSimulationMaterials } from '../../../state/external/project/simulation/param/materials';
import { useSimulationParamScope } from '../../../state/external/project/simulation/paramScope';
import { useSetupSummaryOpened } from '../../../state/external/project/simulation/setupSummaryOpened';
import { useIsAdjointSetup } from '../../../state/internal/global/currentView';
import CheckBox from '../../Form/CheckBox';
import { paramsToShow, reorderParams } from '../../ParamForm';
import { createStyles, makeStyles } from '../../Theme';
import { useProjectContext } from '../../context/ProjectContext';
import { TableMapInput } from '../../controls/TableMapInput';
import { useGetPhysicalBehaviorCellProps } from '../../hooks/physicalBehavior/useGetPhysicalBehaviorCellProps';
import { useIsLMAActive } from '../../hooks/useMesh';
import { useHandleRunSumulation } from '../../hooks/useRunSimulation';
import { REMOVE_GRAVITY_FORM_PARAMS } from '../../treePanel/propPanel/GeneralSettings';
import { conditionBaseOptions } from '../../treePanel/propPanel/StoppingConditions';
import { getMotionTypes } from '../../treePanel/propPanel/motion/Frame';
import { removeParams as removeFluidBoundaryConditionParams } from '../../treePanel/propPanel/physics/fluid/BoundaryCondition';
import { removeParams as removeHeatBoundaryConditionParams } from '../../treePanel/propPanel/physics/heat/BoundaryCondition';
import { removeParams as removeHeatSourceParams } from '../../treePanel/propPanel/physics/heat/HeatSource';
import { NavContentDialog, NavContentDialogItem, TableData, TableDataRow, TableDataValueWithUnit } from '../NavContentDialog';

const logger = new Logger('dialog/SetupSummary');

const useStyles = makeStyles(
  () => createStyles({
    doNotShow: {
      marginTop: '8px',
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      justifyContent: 'end',
    },
    doNotShowLabel: {
      padding: '0',
      background: 'none',
      border: 'none',
      color: colors.highEmphasisText,
      fontSize: '13px',
    },
  }),
  { name: 'SetupSummaryDialog' },
);

export interface SetupSummaryDialogProps {
  onClose: () => void;
  onContinue: () => void;
  open: boolean;
}

interface CustomChoice extends Partial<ProtoDescriptor.Choice> {
  id?: string;
}

function getSelectedTextFromChoices(choices: CustomChoice[], value: any): string {
  return choices.find(
    (choice) => (choice.enumNumber === value || choice.id === value),
  )?.text || EMPTY_VALUE;
}

function getTableDataDefinition(param: ProtoDescriptor.Param) {
  switch (param.name) {
    case ParamName.ProfileBcData:
    case ParamName.ProfileSourceData:
      return ProfileBCTableDefintion;
    case ParamName.FanCurveTableData:
      return FanCurveTableDefinition;
    case ParamName.ActuatorDiskRadialTableData:
      return RadialDistributionTableDefinition;
    default:
      logger.info(`Missing table definition for param ${param.name} in the setup summary.`);
      return null;
  }
}

function stringifyAdVector(vector: basepb.AdVector3): string {
  const x = getAdValue(vector.x);
  const y = getAdValue(vector.y);
  const z = getAdValue(vector.z);
  return formatNumberList([x, y, z]);
}

function stringifyVector(vector: basepb.Vector3): string {
  const { x, y, z } = vector;
  return formatNumberList([x, y, z]);
}

const getParamValue = (
  param: ProtoDescriptor.Param,
  // The current value of the parameter. The dynamic type of this field is that
  // of the corresponding proto field.
  // E.g.:
  // If the field is a double, it is basepb.AdFloatType;
  // If it is a vector, it is basepb.AdVector3;
  // If it is an integer, it is base.Int;
  // For boolean and string protos, it is a primitive boolean or string.
  value: any,
  // The projectId and the simParam are needed for the Data tables that can be shown for the
  // Tabulated profile for an Inlet and for the Table Data for an Output's/Input's Fan Curve
  // the Inlet//fan curve
  projectId?: string,
  simParam?: simulationpb.SimulationParam,
) => {
  switch (param.type) {
    case ProtoDescriptor.ParamType.BOOL:
      return (value as boolean) ? 'On' : 'Off';
    case ProtoDescriptor.ParamType.STRING:
      return value || '';
    case ProtoDescriptor.ParamType.REAL:
      return formatNumber(getAdValue(value as basepb.AdFloatType));
    case ProtoDescriptor.ParamType.INT:
      return formatNumber(fromBigInt((value as basepb.Int)?.value || 0n));
    case ProtoDescriptor.ParamType.VECTOR3: {
      const vector = (value as basepb.AdVector3) || newScalarAdVector();
      return stringifyAdVector(vector);
    }
    case ProtoDescriptor.ParamType.TABLE: {
      if (value) {
        const tableDefinition = getTableDataDefinition(param);
        if (tableDefinition && simParam) {
          return (
            <TableMapInput
              dialogTitle=""
              disabled
              nameErrorFunc={() => ''}
              onChange={() => { }}
              projectId={projectId || ''}
              tableDefinition={tableDefinition}
              tableMap={getCompatibleTablesMap(simParam, tableDefinition)}
              value={value}
            />
          );
        }
        return value;
      }
      return EMPTY_VALUE;
    }
    case ProtoDescriptor.ParamType.MULTIPLE_CHOICE:
      return getSelectedTextFromChoices(param.choices, value);
    default:
      throw Error(`invalid param type: ${param}`);
  }
};

/**
 * Accepts a proto and param and then returns a TableDataRow object that can be directly consumed
 * by the NavContentDialog.tsx.
 */
const getTableRowDataForParam = <T extends Message>(
  proto: T,
  param: ProtoDescriptor.Param,
  projectId?: string,
  simParam?: simulationpb.SimulationParam,
): TableDataRow => {
  const protoField = extractProtoField(proto, param);
  const value = getParamValue(param, protoField, projectId, simParam);
  const unit = param.quantityType && getQuantityUnit(param.quantityType);

  return {
    key: param.text,
    value: unit ? [value, unit] : value,
  };
};

/**
 * This mimicks the ParamForm that is used for extracting multiple props for a proto but this one
 * returns the props in a format that can be consumed by the tables in the NavContentDialog.tsx.
 */
const getRowsData = <T extends Message>(
  proto: T | undefined,
  paramGroupName: ParamGroupName,
  paramScope: ParamScope,
  removeParams: string[] = [],
  order: string[] = [],
  projectId?: string,
  simParam?: simulationpb.SimulationParam,
): TableDataRow[] => {
  if (!proto) {
    return [];
  }
  const group = paramGroupDesc[paramGroupName];
  const params = paramsToShow(paramScope, group, removeParams);
  if (order) {
    reorderParams(params, order);
  }
  return params.map((param) => getTableRowDataForParam(proto, param, projectId, simParam));
};

/**
 * Combine some rows into the previous row. This will effectively add a 3rd column into the previous
 * row and will add the current row's value there (the key for the current row is skipped).
 */
const transformRowsToMultipleColsLayout = (
  // The rows represented in a key/value pair array
  rows: TableDataRow[],
  // The keys for the rows that should be combined into the previous row. The keys are taken from
  // the paramDesc, e.g. "paramDesc[ParamName.HeatSourcePower].text".
  combine: string[],
  // By default, we'll combine only if the previous row's value or key matches the current row's key
  // If force.always is true, we'll combine even if the key doesn't match.
  // If force.similar is true, we'll combine if the row's key is included in the previous value.
  force: {
    always?: boolean,
    similar?: boolean,
  } = {},
) => rows.reduce((result, row) => {
  const prevItem = result[result.length - 1];

  if (
    row.value &&
    combine?.includes(row.key) &&
    prevItem &&
    typeof prevItem.value === 'string' &&
    (
      force.always ||
      prevItem.value === row.key ||
      prevItem.key === row.key ||
      (force.similar && row.key.toLowerCase().includes(prevItem.value.toLowerCase()))
    )
  ) {
    result.pop();
    result.push({
      key: prevItem.key,
      values: [prevItem.value, row.value],
    });
  } else {
    result.push(row);
  }
  return result;
}, [] as TableDataRow[]);

export const SetupSummaryDialog = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Hooks
  const classes = useStyles();

  // == Recoil
  const [summaryOpened, setSummaryOpened] = useSetupSummaryOpened();
  const [skipSetupSummary, setSkipSetupSummary] = useSkipSetupSummary();
  const boundaryConditionNames = useSimulationBoundaryNames(projectId, workflowId, jobId);
  const enabledExperiments = useEnabledExperiments();
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const isBaselineMode = useIsBaselineMode();
  const isSensitivityAnalysis = config.exploration?.policy.case === 'sensitivityAnalysis';
  const isAdjointSetup = useIsAdjointSetup();
  const isLMAActive = useIsLMAActive();
  const materialData = useSimulationMaterials(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const batchModeChecked = useBatchModeChecked(projectId, workflowId, jobId);
  const handleRunSimulation = useHandleRunSumulation();

  const runButtonText = getRunButtonText(
    isBaselineMode,
    isSensitivityAnalysis,
    isAdjointSetup,
    config,
    isLMAActive,
  );

  const onContinue = useCallback(async () => {
    await handleRunSimulation();
    setSummaryOpened(false);
  }, [setSummaryOpened, handleRunSimulation]);

  // For the Geometry section
  const inputFilename = useInputFilename(projectId);

  // For the General section
  const {
    flowBehavior,
    flowBehaviorTypes,
    general,
    gravityOn,
  } = useGeneral(projectId, workflowId, jobId, readOnly);
  const generalTimeValue = useMemo(
    () => getSelectedTextFromChoices(flowBehaviorTypes, flowBehavior),
    [flowBehavior, flowBehaviorTypes],
  );
  const { time } = useTime(projectId, workflowId, jobId, readOnly);
  const { solutionOutput } = useSolutionOutput(projectId, workflowId, jobId, readOnly);

  // For the Mesh section
  const [meshUrl] = useMeshUrlState(projectId);
  const meshMetadataUrlState = useMeshMetadata(projectId, meshUrl.mesh)?.meshMetadata;
  const meshStats = meshAggregateStats(meshMetadataUrlState);
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const complexity = meshMultiPart?.complexityParams;
  const meshingEnabled = isGeometryFile(meshUrl.url);
  const meshingStrategy = meshingEnabled ? getComplexityLabel(complexity?.type) : '';
  const projectActiveMesh = useRecoilValue(projectActiveMeshSelector({
    projectId, workflowId, jobId,
  }));

  // For Frames & Motion
  const { frames } = orderedFrames(simParam);

  // For the Physics sections
  const allPhysics = simParam.physics;
  const getCellProps = useGetPhysicalBehaviorCellProps(simParam, {
    general: true,
    blade: true,
    advanced: true,
  });
  const jobNameMap = useJobNameMap(projectId);
  const [frontendMenuState] = useFrontendMenuState(projectId, workflowId, jobId);
  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);

  // For the Stopping conditions section
  const transient = isSimulationTransient(simParam);
  const [stopConds] = useStoppingConditions(projectId, workflowId, jobId);
  const stopCondOutputNodeChoices = outputNodes.nodes.map(
    ({ id, name }): CustomChoice => ({ id, text: name }),
  );

  const referenceValueAvailableValue = useMemo(() => {
    // This can happen transiently in read-only shared projects when loading to setup.
    if (outputNodes.referenceValues === undefined) {
      return 'Invalid';
    }
    switch (outputNodes.referenceValues!.referenceValueType) {
      case ReferenceValueType.REFERENCE_PRESCRIBE_VALUES:
        return 'Custom';
      case ReferenceValueType.REFERENCE_FARFIELD_VALUES:
        return 'Far-field';
      default:
        return 'Invalid';
    }
  }, [outputNodes]);

  const getInitParamsForExistingSolution = useCallback(() => {
    const initParams: Array<string | number> = ['Existing Solution'];

    const initState = frontendMenuState.initState;
    const selectedJobName = jobNameMap.get({ workflowId: initState?.workflowId || '' });
    const iter = initState?.iter;

    if (selectedJobName) {
      initParams.push(selectedJobName);
    }
    if (iter && iter > -1) {
      initParams.push(iter);
    }
    return initParams;
  }, [frontendMenuState, jobNameMap]);

  /**
   * For Fluid physics, we'll have a table for displaying the individual fluid props.
   * For Heat physics, we are not showing any props for the heat itself, so we'll just add the label
   */
  const getPhysicsTables = useCallback((physics: simulationpb.Physics): TableData[] => {
    const physicsId = getPhysicsId(physics);
    const physicsName = getPhysicsName(physics, simParam);

    if (physics.params.case === 'fluid') {
      const fluid = physics.params.value;
      const material = findFluidPhysicsMaterial(simParam, physics, geometryTags, staticVolumes);
      const physicsScope = chainParamScopes([physics, material], enabledExperiments, paramScope);

      // Viscous Model
      const viscousModelChoices = physicsScope.enabledChoices(
        paramDesc[ParamName.ViscousModel],
      );
      const viscousModel = fluid.basicFluid?.viscousModel;
      // Turbulence
      const turbulenceModelChoices = physicsScope.enabledChoices(
        paramDesc[ParamName.TurbulenceModel],
      );
      const turbulence = fluid?.turbulence;
      const turbulenceModel = turbulence?.turbulenceModel;

      const rows: TableDataRow[] = [];
      if (material) {
        rows.push({
          key: 'Material',
          value: getMaterialName(material, simParam),
        });
      }
      rows.push(
        {
          key: 'Viscous Model',
          value: getSelectedTextFromChoices(viscousModelChoices, viscousModel),
        },
        {
          key: 'Turbulence',
          value: getSelectedTextFromChoices(turbulenceModelChoices, turbulenceModel),
        },
        ...getRowsData(
          turbulence,
          ParamGroupName.Turbulence,
          physicsScope,
          ['TurbulenceModel'],
        ),
      );

      return [{
        id: physicsId,
        label: physicsName,
        heading: physicsName,
        rows,
      }];
    }

    if (physics.params.case === 'heat') {
      return [{
        id: physicsId,
        label: physicsName,
        rows: [],
      }];
    }
    return [];
  }, [enabledExperiments, paramScope, simParam, geometryTags, staticVolumes]);

  /**
   * For Fluid physics, we'll list all Physical Models.
   * For Heat physics, we are not showing any props for the heat itself, so we'll just add the label
   */
  const getPhysicsPhysicalModelsTables = useCallback((
    physics: simulationpb.Physics,
  ): TableData[] => {
    if (physics.params.case === 'fluid') {
      const fluid = physics.params.value;
      const tables: TableData[] = [];

      // Add Porous Behavior models
      fluid.porousBehavior.forEach((behavior) => {
        const rows: TableDataRow[] = [
          {
            key: paramDesc[ParamName.DarcyCoeff].text,
            value: [
              stringifyAdVector(behavior.darcyCoeff || newScalarAdVector()),
              getQuantityUnit(QuantityType.DARCY_COEFFICIENT),
            ],
          },
          {
            key: paramDesc[ParamName.ForchheimerCoeff].text,
            value: [
              stringifyAdVector(behavior.forchheimerCoeff || newScalarAdVector()),
              getQuantityUnit(QuantityType.FORCHHEIMER_COEFFICIENT),
            ],
          },
          {
            key: paramDesc[ParamName.PorousHeatSourcePowerPerUnitVolume].text,
            value: [
              getAdValue(behavior.porousHeatSourcePowerPerUnitVolume),
              getQuantityUnit(QuantityType.POWER_PER_UNIT_VOLUME),
            ],
          },
        ];

        tables.push({
          id: behavior.porousBehaviorId,
          heading: behavior.porousBehaviorName,
          rows,
        });
      });

      // Add Physical Behavior models (Disk models)
      fluid.physicalBehavior.forEach((behavior) => {
        const rows: TableDataRow[] = [];

        const behaviorScope = createParamScope(behavior, enabledExperiments, paramScope);

        const behaviorProps = getCellProps(behavior, behaviorScope);
        behaviorProps.general.forEach((cellProp) => {
          if (cellProp) {
            rows.push(getTableRowDataForParam(behavior, cellProp.param, projectId, simParam));
          }
        });

        tables.push({
          id: behavior.physicalBehaviorId,
          heading: behavior.physicalBehaviorName,
          rows,
        });
      });
      return tables;
    }
    return [];
  }, [paramScope, enabledExperiments, getCellProps, projectId, simParam]);

  const getPhysicsHeatSourceTables = useCallback(
    (physics: simulationpb.Physics): TableData[] => {
      if (physics.params.case === 'heat') {
        const heat = physics.params.value;
        const material = findFluidPhysicsMaterial(simParam, physics, geometryTags, staticVolumes);
        const physicsScope = chainParamScopes([physics, material], enabledExperiments, paramScope);

        return heat.heatSource.map((heatSource) => {
          const heatScope = chainParamScopes([heatSource], enabledExperiments, physicsScope);

          const customOrder = [ParamName.ProfileSource];
          const keepParams = ['ProfileSourceData'];
          // This will return the Heat Source Type and the Power / Power per unit value.
          const rows = getRowsData(
            heatSource,
            ParamGroupName.HeatSource,
            heatScope,
            removeHeatSourceParams.filter((param) => !keepParams.includes(param)),
            customOrder,
            projectId,
            simParam,
          );

          return {
            id: heatSource.heatSourceId,
            heading: heatSource.heatSourceName,
            rows: transformRowsToMultipleColsLayout(rows, [
              paramDesc[ParamName.HeatSourcePowerPerUnitVolume].text,
              paramDesc[ParamName.HeatSourcePower].text,
            ], { always: true }),
          };
        }) || [];
      }
      return [];
    },
    [enabledExperiments, paramScope, projectId, simParam, geometryTags, staticVolumes],
  );

  const getPhysicsBoundaryConditionTables = useCallback((
    physics: simulationpb.Physics,
  ): TableData[] => {
    const tables: TableData[] = [];
    const physicsId = getPhysicsId(physics);

    if (physics.params.case === 'fluid') {
      const fluid = physics.params.value;
      const material = findFluidPhysicsMaterial(simParam, physics, geometryTags, staticVolumes);

      // Add fluid physics boundary conditions
      fluid.boundaryConditionsFluid.forEach((cond, idx) => {
        const isOutlet = cond.physicalBoundary === simulationpb.PhysicalBoundary.OUTLET;
        const isInlet = cond.physicalBoundary === simulationpb.PhysicalBoundary.INLET;
        const isInletFanCurve = cond.inletMomentum === simulationpb.InletMomentum.FAN_CURVE_INLET;
        const isWall = cond.physicalBoundary === simulationpb.PhysicalBoundary.WALL;

        const bcParamScope = chainParamScopes(
          [material, physics, cond],
          enabledExperiments,
          paramScope,
        );

        const customOrder: string[] = [];
        if (isOutlet) {
          customOrder.push(ParamName.OutletStrategy);
        }

        // the Tabulated Profile is visible only when something other than "Fan Curve" is selected
        if (isInlet && !isInletFanCurve) {
          customOrder.push(ParamName.ProfileBc);
        }

        // These params are excluded for the ParamForm render for the prop panel because they
        // require custom rendering (and are added by the insertElement) but we can keep them here.
        const keepParams = ['ProfileBcData', 'FanCurveTableData'];
        const rows = getRowsData(
          cond,
          ParamGroupName.BoundaryConditionsFluid,
          bcParamScope,
          [
            ...removeFluidBoundaryConditionParams.filter((param) => !keepParams.includes(param)),
            // Remove the "Tabulated Profile" when the "Fan Curve" option is picked for Momentum
            ...(isInletFanCurve ? [paramDesc[ParamName.ProfileBc].pascalCaseName] : []),
          ],
          customOrder,
          projectId,
          simParam,
        );

        if (isWall) {
          const hasRoughnessInputs = isBoundaryConditionCompatibleWithRoughness(cond);
          if (hasRoughnessInputs) {
            rows.push({
              key: 'Wall Roughness',
              value: cond.roughnessControl ? 'On' : 'Off',
            });
            if (cond.roughnessControl) {
              rows.push({
                key: 'Roughness Height',
                value: getAdValue(cond.equivalentSandGrainRoughness),
              });
            }
          }
        }

        tables.push({
          id: cond.boundaryConditionName,
          heading: getBoundaryCondName(boundaryConditionNames, cond),
          rows: transformRowsToMultipleColsLayout(rows, [
            paramDesc[ParamName.MassFlowRate].text,
            paramDesc[ParamName.TotalTemperature].text,
            paramDesc[ParamName.TotalPressure].text,
            paramDesc[ParamName.InletVelocityMagnitude].text,
            paramDesc[ParamName.InletVelocityComponents].text,
            paramDesc[ParamName.InletVelocityComponents].text,
            paramDesc[ParamName.FarfieldFlowDirection].text,
            paramDesc[ParamName.FixedHeatFlux].text, // Fixed Heat Flux for Wall's Energy
            paramDesc[ParamName.FixedTemperature].text, // Fixed Temperature for Wall's Energy
            paramDesc[ParamName.TurbulentViscosity].text,
            paramDesc[ParamName.TurbulentViscosityRatio].text,
          ], { similar: true }),
        });
      });

      // Add the Initialization table for fluid physics
      const initialization = fluid.initializationFluid;
      const initType = initialization?.initializationType;

      const rows: TableDataRow[] = [];
      if (initType === simulationpb.InitializationType.EXISTING_SOLUTION) {
        rows.push({
          key: 'Initialization',
          value: getInitParamsForExistingSolution().join(', '),
        });
      } else {
        const initParamScope = chainParamScopes(
          [physics, material],
          enabledExperiments,
          paramScope,
        );
        rows.push(...getRowsData(
          initialization,
          ParamGroupName.InitializationFluid,
          initParamScope,
        ));
      }
      tables.push({
        id: makeInitializationId(physicsId),
        heading: 'Initialization',
        rows,
      });
    }
    if (physics.params.case === 'heat') {
      // Add heat physics boundary conditions
      const heat = physics.params.value;
      heat.boundaryConditionsHeat.forEach((cond, idx) => {
        const heatPhysicsScope = createParamScope(physics, enabledExperiments, paramScope);
        const bcParamScope = chainParamScopes([cond], enabledExperiments, heatPhysicsScope);

        const customOrder = [ParamName.ProfileBc];
        const keepParams = ['ProfileBcData'];
        const rows = getRowsData(
          cond,
          ParamGroupName.BoundaryConditionsHeat,
          bcParamScope,
          removeHeatBoundaryConditionParams.filter((param) => !keepParams.includes(param)),
          customOrder,
          projectId,
          simParam,
        );
        tables.push({
          id: cond.boundaryConditionName,
          heading: getBoundaryCondName(boundaryConditionNames, cond),
          rows,
        });
      });

      // Add the Initialization table for heat physics
      const initialization = heat.initializationHeat;
      const initType = initialization?.initializationType;

      const initParamScope = createParamScope(physics, enabledExperiments, paramScope);
      const rows: TableDataRow[] = [];
      if (initType === simulationpb.InitializationType.EXISTING_SOLUTION) {
        rows.push({
          key: 'Initialization',
          value: getInitParamsForExistingSolution().join(', '),
        });
      } else {
        rows.push(...getRowsData(
          initialization,
          ParamGroupName.InitializationHeat,
          initParamScope,
        ));
      }
      tables.push({
        id: makeInitializationId(physicsId),
        heading: 'Initialization',
        rows,
      });
    }

    return tables;
  }, [
    boundaryConditionNames,
    enabledExperiments,
    getInitParamsForExistingSolution,
    paramScope,
    simParam,
    projectId,
    geometryTags,
    staticVolumes,
  ]);

  const geometrySection = {
    nav: 'Geometry',
    tables: [{
      label: 'Geometry',
      rows: [
        { key: 'Geometry', value: inputFilename.name },
      ],
    }],
  };

  const generalSection = useMemo(() => ({
    nav: 'General',
    tables: [{
      label: 'General',
      rows: [
        { key: 'Time', value: generalTimeValue },
        ...getRowsData(
          time,
          ParamGroupName.Time,
          paramScope,
        ),
        { key: 'Gravity', value: gravityOn ? 'On' : 'Off' },
        ...getRowsData(
          general,
          ParamGroupName.General,
          paramScope,
          REMOVE_GRAVITY_FORM_PARAMS,
        ),
        // Iterations per output
        ...getRowsData(
          solutionOutput,
          ParamGroupName.Output,
          paramScope,
          ['DebugOutput', 'DebugOutputInteriorSurfaceData'],
        ),
        {
          key: 'Physics',
          links: allPhysics.map((physic) => ({ label: getPhysicsName(physic, simParam) })),
        },
      ],
    }],
  }), [
    allPhysics,
    general,
    generalTimeValue,
    gravityOn,
    paramScope,
    simParam,
    solutionOutput,
    time,
  ]);

  const meshSection = useMemo(() => {
    const rows: TableDataRow[] = [];

    if (projectActiveMesh) {
      rows.push({
        key: 'Mesh Name',
        value: projectActiveMesh.name,
      });
    }

    if (meshStats.counters.controlVolume) {
      rows.push({
        key: 'Control Volumes',
        value: meshStats.counters.controlVolume.toLocaleString(),
      });
    }

    if (projectActiveMesh && meshingStrategy) {
      rows.push({
        key: 'Sizing Strategy',
        value: meshingStrategy,
      });
    }

    if (rows.length) {
      return {
        nav: 'Mesh',
        tables: [{
          label: 'Mesh',
          rows,
        }],
      };
    }
    return undefined;
  }, [meshStats.counters.controlVolume, projectActiveMesh, meshingStrategy]);

  const removedHeatParams = (solid: simulationpb.MaterialSolid | undefined): string[] => {
    const params: string[] = ['MaterialSolidPreset'];
    if (!solid) {
      return params;
    }

    // Due to issues with the conditionals, if there is a table we need to remove the constant value
    // and insert the table name manually.
    if (solid.thermalConductivityTableData) {
      params.push('ThermalConductivityConstantSolid');
    }
    return params;
  };

  const materialSection: NavContentDialogItem = useMemo(() => ({
    nav: 'Material',
    subitems: materialData.length > 1 ?
      materialData.map(({ id, name }) => ({ id, label: name })) : undefined,
    tables: materialData.map(({ id, name, model: materialEntity }, idx) => ({
      id,
      label: idx === 0 ? 'Material' : undefined,
      heading: name,
      rows: isMaterialFluid(materialEntity) ? [
        { key: 'Type', value: 'Fluid Material' },
        ...getRowsData(
          getMaterialFluid(materialEntity),
          ParamGroupName.MaterialFluid,
          createParamScope(materialEntity, enabledExperiments, paramScope),
          ['MaterialFluidPreset'],
        ),
      ] : [
        { key: 'Type', value: 'Solid Material' },
        ...getRowsData(
          getMaterialSolid(materialEntity),
          ParamGroupName.MaterialSolid,
          createParamScope(materialEntity, enabledExperiments, paramScope),
          removedHeatParams(getMaterialSolid(materialEntity)),
        ),
        // See comment in removedHeatParams.
        ...getMaterialSolid(materialEntity)?.thermalConductivityTableData ?
          [{
            key: paramDesc[ParamName.ThermalConductivityTableData].text,
            value: getMaterialSolid(materialEntity)?.thermalConductivityTableData,
          }] : [],
      ],
    })),
  }), [materialData, enabledExperiments, paramScope]);

  const framesMotionSection = useMemo(() => {
    // We don't need to show the global frame so if there's only 1 frame, we can skip the section
    if (frames.length <= 1) {
      return undefined;
    }

    return {
      nav: 'Frames & Motion',
      // Skip the first frame (which is always the global frame).
      tables: frames.slice(1).map((motion, idx) => {
        const {
          origin,
          orientation,
        } = extractCoordinatesFromTransforms(motion.frameTransforms);

        const rows: TableDataRow[] = [];

        const parentFrame = frames.find((frame) => frame.frameId === motion.frameParent);
        if (parentFrame) {
          rows.push({
            key: 'Parent',
            value: parentFrame.frameName,
          });
        }

        rows.push(
          {
            key: 'Origin',
            value: [stringifyVector(origin), getQuantityUnit(QuantityType.LENGTH)],
          },
          {
            key: 'Orientation',
            value: [stringifyVector(orientation), getQuantityUnit(QuantityType.DEGREE)],
          },
        );

        const motionType = motion.motionType;
        if (motionType !== simulationpb.MotionType.NO_MOTION) {
          const formulationParam = paramDesc[ParamName.MotionFormulation] as
            ProtoDescriptor.MultipleChoiceParam;
          rows.push(
            {
              key: 'Type',
              // Theoretically we could use getSelectedTextFromChoices here with the
              // paramDesc[ParamName.MotionType].choices (like we do for the Formulation below),
              // but the labels that are used in the Frame's prop panel are customized (and not
              // those from the paramDesc). That's why we use the types from the Frame's prop panel.
              value: getMotionTypes(motionType).find((type) => type.selected)?.name || EMPTY_VALUE,
            },
            {
              key: 'Formulation',
              value: getSelectedTextFromChoices(
                formulationParam.choices,
                motion.motionFormulation,
              ),
            },
          );

          if (motionType === simulationpb.MotionType.CONSTANT_ANGULAR_MOTION) {
            const rotationalVelocity = getFrameDefaultRotationalVelocity(motion);
            rows.push({
              key: 'Rotation Vector',
              value: [
                stringifyVector(rotationalVelocity),
                getQuantityUnit(QuantityType.ANGULAR_VELOCITY),
              ],
            });
          }

          if (motionType === simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION) {
            const translationalVelocity = getFrameDefaultTranslationVelocity(motion);
            rows.push({
              key: 'Translation Vector',
              value: [
                stringifyVector(translationalVelocity),
                getQuantityUnit(QuantityType.VELOCITY),
              ],
            });
          }
        }

        return {
          id: motion.frameId,
          heading: motion.frameName,
          label: idx === 0 ? 'Frames & Motion' : undefined,
          rows,
        };
      }, [] as TableData[]),
    };
  }, [frames]);

  /**
   * The section for each physics will contain multiple tables, which will vary depending on the
   * type of physics (fluid vs heat) but the general structure is:
   *
   * For Fluid physics, we show:
   *  - section label with the Fluid name
   *  - table with the individual fluid props (material, viscous model, turbulence, etc.);
   *  - table for each physical model (disk or porous)
   *  - table for each boundary condition;
   *  - table with initialization props.
   *
   * For Heat physics, we show tables for:
   *  - section label with the Heat name
   *  - table for each heat source;
   *  - table for each boundary condition;
   *  - table with the initialization props.
   */
  const physicsSections: NavContentDialogItem[] = useMemo(
    () => allPhysics.map((physics: simulationpb.Physics): NavContentDialogItem => {
      const physicsId = getPhysicsId(physics);
      const physicsName = getPhysicsName(physics, simParam);
      const bcList = getBoundaryConditionsForPhysics(physics);
      const subitems = [];

      if (physics.params.case === 'fluid') {
        const fluid = physics.params.value;
        fluid.porousBehavior.forEach((behavior) => {
          subitems.push({
            id: behavior.porousBehaviorId,
            label: behavior.porousBehaviorName,
          });
        });
        fluid.physicalBehavior.forEach((behavior) => {
          subitems.push({
            id: behavior.physicalBehaviorId,
            label: behavior.physicalBehaviorName,
          });
        });
      }

      if (physics.params.case === 'heat') {
        const heat = physics.params.value;
        heat.heatSource.forEach((heatSource) => {
          subitems.push({
            id: heatSource.heatSourceId,
            label: heatSource.heatSourceName,
          });
        });
      }

      subitems.push(
        ...bcList.map((cond) => ({
          id: cond.boundaryConditionName,
          label: getBoundaryCondName(boundaryConditionNames, cond),
        })),
        {
          id: `${physicsId}-init`,
          label: 'Initialization',
        },
      );

      return {
        nav: physicsName,
        subitems,
        tables: [
          ...getPhysicsTables(physics),
          ...getPhysicsPhysicalModelsTables(physics),
          ...getPhysicsHeatSourceTables(physics),
          ...getPhysicsBoundaryConditionTables(physics),
        ],
      };
    }),
    [
      allPhysics,
      boundaryConditionNames,
      getPhysicsBoundaryConditionTables,
      getPhysicsHeatSourceTables,
      getPhysicsTables,
      getPhysicsPhysicalModelsTables,
      simParam,
    ],
  );

  const referenceValuesSection: NavContentDialogItem = useMemo(() => ({
    nav: 'Reference Values',
    tables: [{
      label: 'Reference Values',
      heading: 'Reference Values',
      rows: enabledExperiments.includes(flags.referenceValues) && outputNodes.useRefValues ? [
        {
          key: 'Area',
          value: [
            getAdValue(getReferenceGeometricQuantity(outputNodes, 'AreaRef').value),
            getQuantityUnit(QuantityType.AREA),
          ],
        },
        {
          key: 'Length',
          value: [
            getAdValue(getReferenceGeometricQuantity(outputNodes, 'LengthRef').value),
            getQuantityUnit(QuantityType.LENGTH),
          ],
        },
        ...(outputNodes.referenceValues?.useAeroMomentRefLengths ?
          [
            {
              key: 'Length (Pitch)',
              value: [
                getAdValue(getReferenceGeometricQuantity(outputNodes, 'LengthRefPitch').value),
                getQuantityUnit(QuantityType.LENGTH),
              ] as TableDataValueWithUnit,
            },
            {
              key: 'Length (Roll)',
              value: [
                getAdValue(getReferenceGeometricQuantity(outputNodes, 'LengthRefRoll').value),
                getQuantityUnit(QuantityType.LENGTH),
              ] as TableDataValueWithUnit,
            },
            {
              key: 'Length (Yaw)',
              value: [
                getAdValue(getReferenceGeometricQuantity(outputNodes, 'LengthRefYaw').value),
                getQuantityUnit(QuantityType.LENGTH),
              ] as TableDataValueWithUnit,
            },
          ] : []),
        {
          key: 'Available Values',
          value: referenceValueAvailableValue,
        },
        {
          key: 'Absolute Static Pressure',
          value: [
            getAdValue(getReferencePressure(outputNodes, simParam).value),
            getQuantityUnit(QuantityType.PRESSURE),
          ],
        },
        {
          key: 'Temperature',
          value: [
            getAdValue(getReferenceTemperature(outputNodes, simParam).value),
            getQuantityUnit(QuantityType.TEMPERATURE),
          ],
        },
        {
          key: 'Velocity',
          value: [
            getAdValue(
              getReferenceVelocity(outputNodes, simParam, geometryTags, staticVolumes).value,
            ),
            getQuantityUnit(QuantityType.VELOCITY),
          ],
        },
      ] : getRowsData(
        simParam.referenceValues,
        ParamGroupName.ReferenceValues,
        paramScope,
      ),
    }],
  }), [enabledExperiments, outputNodes, paramScope, simParam, referenceValueAvailableValue,
    geometryTags, staticVolumes,
  ]);

  const stoppingConditionsSection: NavContentDialogItem = useMemo(() => {
    const generalStopCondTableRows: TableDataRow[] = [
      {
        key: transient ? 'Max Time Steps' : 'Max Iterations',
        value: toPositiveAbsoluteInteger(stopConds.maxIterations),
      },
      {
        key: 'Stop if',
        value: conditionBaseOptions.find(
          (option) => option.value === stopConds.op,
        )?.name || EMPTY_VALUE,
      },
    ];

    if (transient) {
      generalStopCondTableRows.splice(1, 0, {
        key: 'Max Physical Time',
        value: stopConds.maxPhysicalTime,
      });

      if (isSimulationImplicitTime(simParam)) {
        generalStopCondTableRows.push({
          key: 'Max Inner Iterations',
          value: toPositiveAbsoluteInteger(stopConds.maxInnerIterations),
        });
      }
    }

    return {
      nav: 'Stopping Conditions',
      tables: [
        {
          label: 'Stopping Conditions',
          heading: 'General',
          rows: generalStopCondTableRows,
        },
        ...stopConds.cond.map((cond, idx) => {
          const stopCondNode = cond.node;
          const isResidual = stopCondNode?.nodeProps.case === 'residual';
          const scale = isResidual ? 1 : 100;

          const rows = [
            {
              key: 'Output Name',
              value: getSelectedTextFromChoices(stopCondOutputNodeChoices, stopCondNode?.id),
            },
            {
              key: 'Tolerance',
              value: formatNumber(scale * cond.threshold),
            },
          ];

          if (stopCondNode && !isResidual) {
            rows.push(
              {
                key: 'Averaging Iterations',
                value: formatNumber(toPositiveAbsoluteInteger(cond.nIterations)),
              },
            );
          }

          return {
            id: cond.id,
            heading: `Condition ${idx + 1}`,
            rows,
          };
        }),
      ],
    };
  }, [stopCondOutputNodeChoices, stopConds, transient, simParam]);

  return (
    <NavContentDialog
      continueButton={{
        label: runButtonText.label,
        disabled: readOnly,
        icon: batchModeChecked ? { name: 'clockReset', maxHeight: 12 } : undefined,
        name: 'runSimulationInSetupSummary',
      }}
      data={[
        geometrySection,
        generalSection,
        meshSection,
        materialSection,
        framesMotionSection,
        ...physicsSections,
        referenceValuesSection,
        stoppingConditionsSection,
      ]}
      footer={(
        <div className={classes.doNotShow}>
          <CheckBox
            checked={skipSetupSummary}
            onChange={() => setSkipSetupSummary(!skipSetupSummary)}
          />
          <button
            className={classes.doNotShowLabel}
            onClick={() => setSkipSetupSummary(!skipSetupSummary)}
            type="button">
            Do not show Setup Summary before run
          </button>
        </div>
      )}
      navWidth={200}
      onClose={() => setSummaryOpened(false)}
      onContinue={onContinue}
      open={summaryOpened}
      title="Setup Summary"
      width={960}
    />
  );
};
