// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { VIEWER_PADDING } from '../../lib/visUtils';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useRightOverlayCardsWidth } from '../../state/internal/component/rightOverlayCards';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';

import { FloatingSimTree } from './FloatingSimTree';
import { ModificationTreePanel } from './ModificationTreePanel';

const useStyles = makeStyles(
  () => createStyles({
    cards: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: `${VIEWER_PADDING}px`,
      right: `${VIEWER_PADDING}px`,
      zIndex: 3,
      overflow: 'auto',
      maxHeight: `calc(100% - 2 * ${VIEWER_PADDING}px)`,
      justifyContent: 'space-between',
    },
  }),
  { name: 'RightOverlayCards' },
);

/**
 * This is a floating panel that is shown on the right side of the 3D viewer.
 * It is used for the Modification Tree Panel on the geometry page.
 */
export const RightOverlayCards = () => {
  const { projectId } = useProjectContext();
  // == Hooks
  const classes = useStyles();
  const isGeometryView = useIsGeometryView();

  // == Recoil
  const [width] = useRightOverlayCardsWidth();
  const workflowFlag = useWorkflowFlagValue();
  const geometryPending = useIsGeometryPending(projectId);
  // We do not want to show the simulation tree while the geometry is pending since users cannot
  // see the geometry's entities.
  const showSimTreeOutsideGeometry = workflowFlag && !geometryPending;

  // == Render
  return (
    <div className={classes.cards} style={{ width }}>
      {isGeometryView ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {workflowFlag && <FloatingSimTree />}
          <ModificationTreePanel />
        </div>
      ) : (
        showSimTreeOutsideGeometry && <FloatingSimTree />
      )}
    </div>
  );
};
