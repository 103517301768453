// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactChild, ReactElement, useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

import { addClosableTab, replaceTabText, tabLink } from '../../lib/TabManager';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { colors } from '../../lib/designSystem';
import {
  RowDatum,
  createExplorationVarData,
  createRowData,
  createWorkflowData,
  exportJobTableCallback,
  getRowId,
} from '../../lib/jobTableUtils';
import { Logger } from '../../lib/observability/logs';
import { useUserCanEdit } from '../../lib/projectRoles';
import { addError } from '../../lib/transientNotification';
import { useDeleteWorkflow } from '../../lib/useDeleteWorkflow';
import * as feoutputpb from '../../proto/frontend/output/output_pb';
import { JobType } from '../../proto/notification/notification_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useJobNameMap } from '../../recoil/jobNameMap';
import { useOutputNodes } from '../../recoil/outputNodes';
import { useIsStarterPlan } from '../../recoil/useAccountInfo';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { ScalarOutput, useProgressiveOutputResults } from '../../recoil/useOutputResults';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useTabsState } from '../../recoil/useTabsState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useWorkflowMap } from '../../recoil/workflowState';
import { useSetLastOpenedResultsTab } from '../../state/external/project/lastOpenedResultsTab';
import { useIsStaff } from '../../state/external/user/frontendRole';
import { ActionButton } from '../Button/ActionButton';
import { CommonMenu } from '../Menu/CommonMenu';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { EditableText } from '../controls/EditableText';
import { TriangleIcon } from '../svg/TriangleIcon';
import { CircularLoader } from '../visual/CircularLoader';

import { getJobStatusData } from './JobStatus';
import { MenuButton } from './MenuButton';
import OutputCell from './OutputCell';
import { PauseResumeToggleJobTable } from './PauseResumeToggle';
import ResultsTable, { TableColumn } from './ResultsTable';
import { useSelectedRowId } from './state';

const logger = new Logger('JobTable');

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '1px',
      overflow: 'hidden',
      height: '100%',
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: colors.surfaceMedium2,
    },
    toggleButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      cursor: 'pointer',
    },
    toggleButtonIcon: {
      flex: '0 0 auto',
      display: 'flex',
      alignItems: 'center',
      width: '8px',
      height: '8px',
      color: colors.lowEmphasisText,
    },
    results: {
      flex: '1 1 auto',
      overflow: 'auto',
      backgroundColor: colors.surfaceMedium2,
    },
  }),
  { name: 'JobTable' },
);

// JobTable summarizes jobs in a workflow.  We display them in a table
// currently, but we should switch to a more compact form, e.g., parallel
// coordinate display. This is similar to JobPanel, but JobPanel only displays
// one job.

function createTableColumn(
  result: ScalarOutput[],
  nameLines: string[],
  outputNode: feoutputpb.OutputNode,
  rowData: RowDatum[],
  surface?: string,
): TableColumn {
  return {
    nameLines,
    values: rowData.map((row, index) => {
      const rowId = getRowId(row);
      // If there is no output because the row does not have a job, return an empty fragment.
      if (!row.job) {
        return <React.Fragment key={rowId} />;
      }
      // If the result is not yet available, return a placeholder. Otherwise, return the result.
      return result[index] ? (
        <OutputCell
          key={rowId}
          result={result[index].baseValue}
          status={result[index].status}
        />
      ) : <div key={rowId}>...</div>;
    }),
    maxWidth: '140px', // truncate output titles that are too long
  };
}

// Create categories from an output node for the results table. This is similar
// to createCategory in JobPanel, but the categories are not nested.
const createColumns = (
  outputNode: feoutputpb.OutputNode,
  results: ScalarOutput[][],
  columns: TableColumn[],
  rowData: RowDatum[],
) => {
  results.forEach((result: ScalarOutput[]) => {
    const shortName = result[0].shortName;
    const name = result[0].name;
    columns.push(
      createTableColumn(
        result,
        shortName.length > 0 ? [outputNode.name, name, shortName] : [outputNode.name, name],
        outputNode,
        rowData,
      ),
    );
  });
};

type IdBoolMap = Record<string, boolean | undefined>;

interface OpenCollapseToggleProps {
  openWorkflowIds: IdBoolMap;
  setOpenWorkflowIds: (OpenWorkflowIds: IdBoolMap) => void;
  rowId: string;
}
const OpenCollapseToggle = (props: OpenCollapseToggleProps) => {
  const {
    openWorkflowIds,
    setOpenWorkflowIds,
    rowId,
  } = props;
  const toggleOpen = () => {
    // Toggle the row ID in or out of the openWorkflowIds.
    const newOpenWorkflowIds = { ...openWorkflowIds };
    newOpenWorkflowIds[rowId] = !newOpenWorkflowIds[rowId];
    setOpenWorkflowIds(newOpenWorkflowIds);
  };
  const classes = useStyles();
  return (
    <div
      className={classes.toggleButton}
      data-locator="job-table-toggle"
      onClick={toggleOpen}
      onKeyDown={toggleOpen}
      role="button"
      tabIndex={0}>
      <div className={classes.toggleButtonIcon}>
        <TriangleIcon direction={openWorkflowIds[rowId] ? 'down' : 'right'} height={8} width={8} />
      </div>
    </div>
  );
};

interface OnEditNameArgs {
  name: string;
  type: JobType;
  workflowId: string;
  jobId: string | undefined;
}

interface JobNameCellProps extends OnEditNameArgs {
  isEditing: boolean;
  onEditName: (args: OnEditNameArgs) => void;
}

// We are defining the cell for the job name separately here so that we can memoize it.
// Otherwise if we are editing the name while the simulation is in progress, some keystrokes,
// caret position, text selection or some other input state may be lost during the rerender.
const JobNameCell = React.memo((props: JobNameCellProps) => {
  const { isEditing, jobId, name, onEditName, type, workflowId } = props;

  const onSetText = useCallback((newName: string) => {
    onEditName({ name: newName, type, workflowId, jobId });
  }, [onEditName, type, workflowId, jobId]);

  return (
    <EditableText
      active={isEditing}
      onChange={onSetText}
      value={name}
    />
  );
});

interface ExportJobTableButtonProps {
  workflowIds: string[];
  disabled?: boolean;
  children: ReactChild;
}

// A button for exporting the job table data. Shows a spinner while data is being fetched and
// prepared.
const ExportJobTableButton = (props: ExportJobTableButtonProps) => {
  const { projectId } = useProjectContext();
  const [showDownloadSpinner, setShowDownloadSpinner] = useState(false);
  const exportExploration = useRecoilCallback(exportJobTableCallback);
  const isStarterPlan = useIsStarterPlan();

  return (
    <ActionButton
      disabled={props.disabled || isStarterPlan}
      kind="minimal"
      onClick={() => {
        setShowDownloadSpinner(true);
        exportExploration(projectId, props.workflowIds).then(
          () => setShowDownloadSpinner(false),
        ).catch(
          (error) => {
            setShowDownloadSpinner(false);
            addError('An internal error occured while downloading data. ' +
              'Try again later or contact support.');
            logger.error(error);
          },
        );
      }}
      showSpinner={showDownloadSpinner}
      size="small"
      startIcon={{ name: 'cloudDownload' }}
      title={isStarterPlan ? 'Cannot Download Results on Starter Plan' : ''}>
      {props.children}
    </ActionButton>
  );
};

const JobTable = () => {
  const classes = useStyles();

  const { projectId } = useProjectContext();

  // The IDs of any workflows that are open. They are closed by default.
  const [openWorkflowIds, setOpenWorkflowIds] = useState<IdBoolMap>({});
  // The ID of the row that is currently selected.
  const [rowSelected, setRowSelected] = useSelectedRowId();
  // True if the selected row is currently being renamed.
  const [renamingActive, setRenamingActive] = useState(false);
  // A menu can be attached to a row element. This is the element the menu is
  // attached. If this is null, there is no menu.
  const [rowMenuElement, setRowMenuElement] = useState<Element | null>(null);

  // The ids of deleted workflows. We need that in order to filter out deleted workflows
  // while the backend delete request is still in progress.
  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  const navigate = useNavigate();
  const outputNodes = useOutputNodes(projectId, '', '')[0];

  const projectMetadata = useProjectMetadata(projectId);
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);
  const [workflows, workflowIds] = useMemo(() => {
    const workflowList = projectMetadata?.workflow.filter(
      (metadata) => !deletedIds.includes(metadata.workflowId),
    ) || [];
    return [workflowList, workflowList.map((workflow) => workflow.workflowId)];
  }, [projectMetadata, deletedIds]);
  const workflowMap = useWorkflowMap(projectId, workflowIds);
  const jobNameMap = useJobNameMap(projectId);
  const experimentConfig = useEnabledExperiments();
  const [tabsState, setTabsState] = useTabsState(projectId);
  const deleteWorkflow = useDeleteWorkflow(projectId);
  const setLastOpenedResultsTab = useSetLastOpenedResultsTab(projectId);

  const workflowData = useMemo(
    () => createWorkflowData(workflowMap, workflows, experimentConfig),
    [workflowMap, workflows, experimentConfig],
  );

  const rowData = useMemo(
    () => createRowData(
      Object.keys(openWorkflowIds).filter((id) => openWorkflowIds[id]),
      workflowData,
      jobNameMap,
    ),
    [openWorkflowIds, workflowData, jobNameMap],
  );

  // When the name is changed, the job map is updated with the new name.
  // Also, the tabs state is updated to reflect the new simulation name assigned to the tab.
  const onEditName = useCallback((args: OnEditNameArgs) => {
    const { name, type, workflowId, jobId } = args;

    setRenamingActive(false);
    // If we are renaming a DoE row, we shouldn't update the tabs state
    // because a DoE doesn't have a tab representation.
    if (type !== JobType.DESIGN_OF_EXPERIMENTS) {
      const link = tabLink(type, projectId, workflowId, jobId);
      setTabsState(replaceTabText(link, name, tabsState));
    }
    return jobNameMap.set({ workflowId, jobId }, name);
  }, [projectId, jobNameMap, setRenamingActive, tabsState, setTabsState]);

  const columns: TableColumn[] = [];

  // Create a column of just the open / collapse toggles.
  let toggleColumnEmpty = true;
  const toggleColumn: TableColumn = {
    nameLines: [],
    values: rowData.map((row) => {
      if ([JobType.DESIGN_OF_EXPERIMENTS, JobType.SENSITIVITY_ANALYSIS].includes(row.type)) {
        toggleColumnEmpty = false;
        return (
          <OpenCollapseToggle
            key={row.workflow.id}
            openWorkflowIds={openWorkflowIds}
            rowId={row.workflow.id}
            setOpenWorkflowIds={setOpenWorkflowIds}
          />
        );
      }
      return <React.Fragment key={row.workflow.id} />;
    }),
  };
  if (!toggleColumnEmpty) {
    columns.push(toggleColumn);
  }

  const nameColumn: TableColumn = {
    leftAlign: true,
    nameLines: ['Name'],
    values: rowData.map((row) => {
      const rowId = getRowId(row);
      return (
        <div
          key={rowId}
          onDoubleClick={(event) => {
            if (userCanEdit) {
              event.stopPropagation();
              setRenamingActive(true);
              setRowSelected(rowId);
            }
          }}>
          <JobNameCell
            isEditing={renamingActive && (rowId === rowSelected)}
            jobId={row.job?.jobId}
            name={row.name}
            onEditName={onEditName}
            type={row.type}
            workflowId={row.workflow.id}
          />
        </div>
      );
    }),
  };
  columns.push(nameColumn);
  // Find the columns describing the exploration input variables.
  const inputColumns = useMemo((): TableColumn[] => (
    createExplorationVarData(rowData, geometryTags, staticVolumes).map((labeledData, index) => ({
      nameLines: labeledData.labelRows,
      values: labeledData.data.map((val) => <div key={labeledData.labelRows.join('/')}>{val}</div>),
      maxWidth: '140px',
    }))
  ), [rowData, geometryTags, staticVolumes]);
  columns.push(...inputColumns);

  const isStaff = useIsStaff();
  const jobs = rowData.map((row) => row.job);
  const curWorkflows = rowData.map((row) => row.workflow.id);
  const curParams = rowData.map((row) => row.workflow.simParam ?? null);

  const statusColumns = useMemo(() => (
    getJobStatusData(projectId, curWorkflows, jobs, curParams, true, isStaff, false)
  ), [projectId, curWorkflows, jobs, curParams, isStaff]);

  columns.push(...statusColumns);

  // Get progressively loaded results and loading state
  const [outputResults, isLoading] = useProgressiveOutputResults(projectId, outputNodes, rowData);

  // Memoize columns with results
  const outputColumns = useMemo(() => {
    const col: TableColumn[] = [];
    if (!outputNodes?.nodes) {
      return col;
    }

    outputNodes.nodes.forEach((output) => {
      const results = outputResults[output.id] || []; // Handle case where results don't exist yet

      // Always create columns even if no results yet
      if (!results.length && output.name) {
        // Create placeholder columns with loading state
        col.push(createTableColumn(
          Array(rowData.length).fill({ name: output.name, shortName: '', status: 'Loading...' }),
          [output.name],
          output,
          rowData,
        ));
      } else if (results.length > 0) {
        // Create columns with actual results
        createColumns(output, results, col, rowData);
      }
    });
    return col;
  }, [outputNodes?.nodes, outputResults, rowData]);

  columns.push(...outputColumns);

  const selectedRow = rowData.find((row) => getRowId(row) === rowSelected);
  const openDisabled = !selectedRow?.job || !selectedRow.workflow.reply;
  const openJob = (rowId: string | null) => {
    const row = rowData.find((eachRow) => getRowId(eachRow) === rowId);
    if (!row) {
      throw Error('No row selected to open.');
    }
    // Opening design of experiments is not yet implemented. Ignore attempts to
    // open them.
    if (!row.job) {
      return;
    }
    const workflowId = row.workflow.id;
    const jobId = row.job!.jobId;

    const link = tabLink(row.type, projectId, workflowId, jobId);
    const text = jobNameMap.getDefault({ workflowId, jobId, type: row.type });
    setTabsState(addClosableTab(text, link, tabsState));
    setLastOpenedResultsTab(link);
    navigate(link);
  };

  const menuItems: CommonMenuItem[] = [];

  if (userCanEdit) {
    // A column for pausing, resuming, and opening a menu of actions.
    const actionColumn = {
      nameLines: [],
      values: rowData.map((row) => {
        const rowId = getRowId(row);
        let toggleButton: ReactElement | null = null;
        if (
          row.type === JobType.DESIGN_OF_EXPERIMENTS ||
          row.type === JobType.SENSITIVITY_ANALYSIS
        ) {
          toggleButton = (
            <PauseResumeToggleJobTable
              cancelOnly
              projectId={projectId}
              workflowId={row.workflow.id}
            />
          );
        }
        return (
          <React.Fragment key={rowId}>
            {toggleButton}
            <MenuButton
              onClick={(event) => {
                setRowMenuElement(event.target as Element);
              }}
              rowId={rowId}
            />
          </React.Fragment>
        );
      }),
    };
    columns.push(actionColumn);
    // Only workflows can be deleted. Individual jobs within an exploration
    // workflow cannot.
    const isDeletable = selectedRow && selectedRow.type !== JobType.EXPLORATION_JOB;
    const removeWorkflow = () => {
      if (selectedRow) {
        setDeletedIds([selectedRow.workflow.id, ...deletedIds]);
        setRowMenuElement(null);
        deleteWorkflow(selectedRow.workflow.id).catch(() => { });
      }
    };
    const renameRow = () => {
      setRowMenuElement(null);
      setRenamingActive(true);
    };
    menuItems.push({
      label: 'Rename',
      onClick: renameRow,
      startIcon: { name: 'pencilLines' },
    });
    if (isDeletable) {
      menuItems.push({ separator: true });
      menuItems.push({
        label: 'Delete...',
        help: 'Permanently delete results',
        startIcon: { name: 'trash' },
        destructive: true,
        onClick: removeWorkflow,
      });
    }
  }

  const onRowClicked = (rowId: string) => {
    if (rowId !== rowSelected) {
      setRowSelected(rowId);
      setRenamingActive(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.buttonGroup}>
        {isLoading && <CircularLoader size={12} />}
        <ActionButton
          disabled={openDisabled}
          kind="minimal"
          onClick={() => (openJob(rowSelected))}
          size="small"
          startIcon={{ name: 'open' }}>
          Open
        </ActionButton>
        <ExportJobTableButton
          disabled={!selectedRow}
          workflowIds={selectedRow ? [selectedRow.workflow.id] : []}>
          Download Selected
        </ExportJobTableButton>
        <ExportJobTableButton workflowIds={workflowIds}>
          Download All
        </ExportJobTableButton>
      </div>
      <div className={classes.results} data-locator="job-table-results">
        <ResultsTable
          columns={columns}
          onRowClicked={onRowClicked}
          onRowDoubleClicked={openJob}
          rowData={rowData}
        />
        {rowMenuElement && (
          <CommonMenu
            anchorEl={rowMenuElement}
            menuItems={menuItems}
            onClose={() => setRowMenuElement(null)}
            open
            position="below-left"
            positionTransform={{ top: 7, left: 5 }}
          />
        )}
      </div>
    </div>
  );
};

export default JobTable;
