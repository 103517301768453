// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as ProtoDescriptor from '../../ProtoDescriptor';
import {
  appendHeatBoundaryCondition,
  getDisabledBoundaryConditionReason,
  getUnassignedSurfacesByPhysics,
  heatPhysicalBoundaryChoices,
} from '../../lib/boundaryConditionUtils';
import { expandGroups } from '../../lib/entityGroupUtils';
import { getPhysicsDomainsWithoutUnroll } from '../../lib/entityRelationships';
import { NodeType } from '../../lib/simulationTree/node';
import { assignSurfacesToBoundaryCondition } from '../../lib/simulationUtils';
import { usePhysicsSet } from '../../model/hooks/usePhysicsSet';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useGeometryContacts } from '../../recoil/geometryContactsState';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useStaticVolumes } from '../../recoil/volumes';
import { paramChoicesToMenuItems } from '../Menu/CommonMenu';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useGeometryStatus } from '../hooks/useGeometryStatus';
import { useInterfacesFromContacts } from '../hooks/useInterfacesFromContacts';
import { useNonEditableReason } from '../hooks/useNonEditableReason';
import { usePeriodicPairNodes } from '../hooks/usePeriodicPairNodes';
import { useSimulationConfig } from '../hooks/useSimulationConfig';
import { useSlidingInterfaceNodes } from '../hooks/useSlidingInterfaceNodes';

import { AddNodeMenuButton } from './AddNodeButton';

interface AddHeatBoundaryConditionButtonProps {
  /** An ID for a heat transfer physics */
  physicsId: string;
}

// A button for adding a boundary condition.
export const AddHeatBoundaryConditionButton = (props: AddHeatBoundaryConditionButtonProps) => {
  // == Props
  const { physicsId } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const {
    selectedNode,
    selectedNodeIds,
    setSelection,
    setNodeTableWarning,
  } = useSelectionContext();

  // == Recoil
  const contacts = useGeometryContacts(projectId);
  const geometryTags = useGeometryTags(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const setNewNodes = useSetNewNodes();
  const staticVolumes = useStaticVolumes(projectId);

  // == Model hooks
  const { saveBoundaryConditionAsync } = usePhysicsSet(projectId, workflowId, jobId, readOnly);

  // == Custom hooks
  const { simParam } = useSimulationConfig();
  const { addPeriodicBoundPair } = usePeriodicPairNodes(physicsId);
  const { addSlidingInterface } = useSlidingInterfaceNodes(physicsId);
  const { queueAddContacts } = useInterfacesFromContacts(physicsId);
  const { working } = useGeometryStatus();

  // == Data
  const availSurfaces = getUnassignedSurfacesByPhysics(
    simParam,
    physicsId,
    geometryTags,
    staticVolumes,
    entityGroupData,
  );
  const disabledReason = getDisabledBoundaryConditionReason(
    availSurfaces.length,
    getPhysicsDomainsWithoutUnroll(simParam, physicsId).size,
  );
  const choices = heatPhysicalBoundaryChoices();

  const nonEditableReason = useNonEditableReason(
    'add Heat Boundary Conditions',
    working ? '' : disabledReason,
  );

  const addBoundaryCondition = async (boundary: ProtoDescriptor.Choice) => {
    const nodeId = await saveBoundaryConditionAsync(
      (newParam) => {
        const newBoundCond = appendHeatBoundaryCondition(newParam, physicsId, boundary.enumNumber);

        const surfaceTypes = [NodeType.SURFACE, NodeType.SURFACE_GROUP];

        // If only surfaces and groups are selected, add them to the new boundary condition.
        if (!selectedNode || surfaceTypes.includes(selectedNode.type)) {
          const warning = assignSurfacesToBoundaryCondition(
            expandGroups(entityGroupData.leafMap)(selectedNodeIds),
            newBoundCond,
            newParam,
            geometryTags,
            staticVolumes,
            entityGroupData,
          );
          setNodeTableWarning(warning);
        }

        return newBoundCond.boundaryConditionName;
      },
    );

    setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
    setSelection([nodeId]);
  };

  const menuItems = paramChoicesToMenuItems(
    choices,
    addBoundaryCondition,
  );

  menuItems.push(
    {
      label: 'Periodic Pair',
      onClick: addPeriodicBoundPair,
      disabled: readOnly,
    },
    {
      label: 'Interface',
      onClick: addSlidingInterface,
    },
  );

  if (contacts.found && contacts.contacts.length) {
    menuItems.push({
      label: 'Interfaces from Contacts',
      onClick: queueAddContacts,
    });
  }

  return (
    <AddNodeMenuButton
      disabled={working || !!nonEditableReason}
      menuItems={menuItems}
      tooltip={nonEditableReason}
    />
  );
};
