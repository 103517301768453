import { useEffect, useMemo, useRef } from 'react';

import { deepEqual } from 'fast-equals';
import { DefaultValue, atomFamily, selectorFamily, useRecoilState } from 'recoil';

import { useProjectContext } from '../../components/context/ProjectContext';
import { CurrentView } from '../../lib/componentTypes/context';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { Logger } from '../../lib/observability/logs';
import { traverseTreeNodes } from '../../lib/paraviewUtils';
import { RecoilProjectKey } from '../../lib/persist';
import { defaultCmap } from '../../lib/visUtils';
import { ArrayInformation, ColorMap, DisplayPvVariable, FieldAssociation, ViewAttrs } from '../../pvproto/ParaviewRpc';
import { useCurrentView } from '../../state/internal/global/currentView';
import { meshMetadataSelector } from '../meshState';
import { newMeshKeys, paraviewInitialSettingsState, paraviewSettingsKeyPrefix } from '../paraviewState';
import { activeVisUrlState, useActiveVisUrlValue } from '../vis/activeVisUrl';
import { filterStateSelector, useFilterStateValue } from '../vis/filterState';

const logger = new Logger('viewStateOverflow');
// Data that is contained in the Paraview ViewState that isn't in any other states
// but the UI will need to know about and pass between LCVis for results view.
// Filters (ie viewState.root) live in filterState.ts.
type ViewStateOverflowType = {
    attrs: ViewAttrs;
    data: ArrayInformation[];
    surfaceData: ArrayInformation[];
    editSource: 'LCVis' | 'UI';
}

const lcvisDataState = atomFamily<{
    data: ArrayInformation[],
    surfaceData: ArrayInformation[]
}, RecoilProjectKey>({
  key: 'lcvisDataState',
  default: {
    data: [],
    surfaceData: [],
  },
});

export const viewStateOverflow = selectorFamily<ViewStateOverflowType, RecoilProjectKey>({
  key: 'viewStateOverflow',
  get: (key: RecoilProjectKey) => ({ get }) => {
    const activeUrl = get(activeVisUrlState(key));
    const metadata = get(meshMetadataSelector({ projectId: key.projectId, meshUrl: activeUrl }));
    const meshKeys = newMeshKeys(paraviewSettingsKeyPrefix, activeUrl, metadata);

    const paraviewState = get(paraviewInitialSettingsState({ projectId: key.projectId, meshKeys }));
    const dataState = get(lcvisDataState(key));

    return {
      attrs: paraviewState.attrs,
      data: dataState.data,
      surfaceData: dataState.surfaceData,
      editSource: 'UI',
    };
  },
  set: (key: RecoilProjectKey) => ({ get, set }, newVal) => {
    const { projectId, workflowId, jobId } = key;
    const activeUrl = get(activeVisUrlState({ projectId, workflowId, jobId }));
    const metadata = get(meshMetadataSelector({ projectId, meshUrl: activeUrl }));
    const meshKeys = newMeshKeys(paraviewSettingsKeyPrefix, activeUrl, metadata);
    const filterState = get(filterStateSelector({ projectId, workflowId, jobId }));
    const pvSettingsRecoil = paraviewInitialSettingsState({ projectId, meshKeys });
    const pvSettings = get(pvSettingsRecoil);

    if (newVal instanceof DefaultValue) {
      set(lcvisDataState(key), {
        data: [],
        surfaceData: [],
      });
      set(pvSettingsRecoil, {
        ...pvSettings,
        attrs: { ...pvSettings.attrs }, // TODO: reset this somehow?
      });
      return;
    }

    const displayVariable = pvSettings.attrs.displayVariable;
    const newColorMaps = newVal.attrs.colorMaps;

    // Color maps that might need to be added to newColorMaps to account for newly
    // added filter nodes.
    const extraColorMaps: [DisplayPvVariable, ColorMap][] = [];
    // These are O(n^2) checks, but n is always small so it's fine.
    if (
      displayVariable &&
      displayVariable.displayDataName !== 'None' &&
      !newColorMaps?.find((cMap) => deepEqual(cMap[0], displayVariable))
    ) {
      // The root color map is not in newColorMaps, so add it.
      extraColorMaps.push(
        [displayVariable,
          lcvHandler.display?.workspace?.getColorMap(displayVariable) ?? defaultCmap(),
        ],
      );
    }
    traverseTreeNodes(filterState, (node) => {
      const displayVar = node?.displayProps?.displayVariable;
      if (
        displayVar &&
        displayVar.displayDataName !== 'None' &&
        !newColorMaps?.find((cMap) => deepEqual(cMap[0], displayVar))
      ) {
        // The color map for this display variable is not in newColorMaps, so add it.
        extraColorMaps.push([
          displayVar,
          lcvHandler.display?.workspace?.getColorMap(displayVar) ?? defaultCmap(),
        ]);
      }
    });

    // Update the color maps in LCVis.
    newColorMaps?.forEach((cMap) => {
      if (cMap[0].displayDataName === 'None') {
        return;
      }
      try {
        lcvHandler.display?.workspace?.updateColorMap(cMap[0], cMap[1]);
      } catch (error) {
        logger.error('Exception thrown while updating color maps', error, cMap[0]);
      }
    });

    set(lcvisDataState(key), {
      data: newVal.data,
      surfaceData: newVal.surfaceData,
    });

    const trueComp = lcvHandler.display?.workspace?.setDisplayVariable(
      newVal.attrs.displayVariable?.displayDataName ?? 'None',
      newVal.attrs.displayVariable?.displayDataNameComponent,
    );

    set(pvSettingsRecoil, {
      ...pvSettings,
      attrs: {
        ...newVal.attrs,
        displayVariable: {
          displayDataName: newVal.attrs.displayVariable?.displayDataName ?? 'None',
          displayDataNameComponent: trueComp ?? 0,
        },
        colorMaps: [...(newColorMaps ?? []), ...extraColorMaps],
      },
    });
  },
});

export const useViewStateOverflow = (
  key: RecoilProjectKey,
) => useRecoilState(viewStateOverflow(key));

/**
 * Sets the viewStateOverflow data based on the workspace field data
 * anytime the workspace executes, or after the url or filter state changes.
 */
export const useSyncViewStateOverflow = (key: RecoilProjectKey) => {
  const [lcvisData, setLcvisData] = useViewStateOverflow(key);
  const activeVisUrl = useActiveVisUrlValue(key);
  const filterState = useFilterStateValue(key);

  const viewOverflowRef = useRef(lcvisData);

  useEffect(() => {
    if (lcvisData !== viewOverflowRef.current) {
      viewOverflowRef.current = lcvisData;
    }
  }, [lcvisData]);

  useEffect(() => {
    lcvHandler.queueDisplayFunction('sync filter data', (display) => {
      const { workspace } = display;
      workspace?.addOnExecuteWorkspaceCallback(
        'sync filter data after execution',
        () => {
          const data = workspace?.fieldData || [] as ArrayInformation[];

          const previousComponentsByName = (
            viewOverflowRef.current.attrs.colorMaps || []
          ).reduce((result, [displayVariable]) => {
            result.set(displayVariable.displayDataName, displayVariable.displayDataNameComponent);

            return result;
          }, new Map<string, number>());

          const colorMaps = workspace.fieldData.map((field) => {
            const previousComponent = previousComponentsByName.get(field.name);

            const displayVariable = {
              displayDataName: field.name,
              // if a component with the given name exists in the lcvis data then
              // use its displayDataNameComponent; otherwise, default to 0
              displayDataNameComponent: previousComponent ?? 0,
            };

            return [
              displayVariable,
              workspace.getColorMap(displayVariable),
            ] as [DisplayPvVariable, ColorMap];
          });

          setLcvisData({
            data,
            surfaceData: viewOverflowRef.current.surfaceData,
            attrs: {
              ...viewOverflowRef.current.attrs,
              colorMaps,
            },
            editSource: 'LCVis',
          });
        },
      );
    });
  }, [activeVisUrl, filterState, setLcvisData]);
};

/**
 * Updates the color map in recoil with the ranges from LCVis.
 */
export const useSyncColorMap = (key: RecoilProjectKey) => {
  const [lcvisData, setLcvisData] = useViewStateOverflow(key);
  const currentView = useCurrentView();
  const prevCmapNames = useRef<string[]>([]);

  const visibleCmapNames = useMemo(() => {
    const names: string[] = [];
    lcvisData.attrs.colorMaps?.forEach((cMap) => {
      names.push(cMap[0].displayDataName);
    });
    return names;
  }, [lcvisData.attrs.colorMaps]);

  useEffect(() => {
    if (
      lcvisData.editSource === 'LCVis' ||
      !lcvisData.attrs.colorMaps?.length ||
      currentView !== CurrentView.ANALYSIS ||
      deepEqual(prevCmapNames.current, visibleCmapNames)
    ) {
      return;
    }
    prevCmapNames.current = visibleCmapNames;

    lcvHandler.queueDisplayFunction('sync color map', (display) => {
      const { workspace } = display;
      if (!workspace) {
        return;
      }
      const globalRanges = lcvisData.attrs.colorMaps?.map((cMap) => cMap[1].globalRange) ?? [];
      const displayVarsToUpdate: Map<string, [number, number]> = new Map();

      lcvisData.attrs.colorMaps?.forEach((cMap, index) => {
        if (cMap[0].displayDataName === 'None') {
          return;
        }
        // LC-22939: there was a bad color map with the incorrect component. That
        // will throw an exception if we ask the workspace for the color map. Detect that here,
        // and skip the color map.
        try {
          const lcvCmap = workspace.getColorMap(cMap[0]);
          if (!deepEqual(lcvCmap.range, globalRanges[index])) {
            displayVarsToUpdate.set(cMap[0].displayDataName, lcvCmap.range);
          }
        } catch (error) {
          logger.error('Exception thrown while getting color maps', error, cMap[0]);
        }
      });

      if (displayVarsToUpdate.size) {
        const newColorMaps = lcvisData.attrs.colorMaps?.map((keyAndValue) => {
          if (displayVarsToUpdate.has(keyAndValue[0].displayDataName)) {
            const val = displayVarsToUpdate.get(keyAndValue[0].displayDataName) as [number, number];
            return [
              keyAndValue[0],
              {
                ...keyAndValue[1],
                range: val,
                globalRange: val,
              },
            ];
          }
          return keyAndValue;
        }) as [DisplayPvVariable, ColorMap][];

        setLcvisData({
          ...lcvisData,
          attrs: {
            ...lcvisData.attrs,
            colorMaps: newColorMaps,
          },
          editSource: 'LCVis',
        });
      }
    });
  }, [lcvisData, setLcvisData, currentView, visibleCmapNames]);
};

/**
 * @returns a function that updates the color map in the view state
 */
export const useUpdateLcvisColorMap = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const [lcvisData, setLcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });

  return (displayVariable: DisplayPvVariable, newCmap: ColorMap) => {
    let updated = false;
    const newColorMaps = lcvisData.attrs.colorMaps?.map((keyAndValue) => {
      if (deepEqual(keyAndValue[0], displayVariable)) {
        updated = true;
        return [
          displayVariable,
          newCmap,
        ];
      }
      return keyAndValue;
    }) as [DisplayPvVariable, ColorMap][];
    if (!updated) {
      newColorMaps.push([displayVariable, newCmap]);
    }

    setLcvisData({
      ...lcvisData,
      attrs: {
        ...lcvisData.attrs,
        colorMaps: newColorMaps,
      },
      editSource: 'UI',
    });
  };
};

export const useGetLcvisGlobalScalarDataRange = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });

  return (displayVariable: DisplayPvVariable, fieldType: FieldAssociation) => {
    for (let i = 0; i < lcvisData.data.length; i += 1) {
      if (lcvisData.data[i].name === displayVariable.displayDataName &&
        lcvisData.data[i].type === fieldType) {
        const range = lcvisData.data[i].range[displayVariable.displayDataNameComponent];
        return range;
      }
    }
    return null;
  };
};
