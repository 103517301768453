// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilValue } from 'recoil';

import { Logger } from '../../../lib/observability/logs';
import * as rpc from '../../../lib/rpc';
import { isTestingEnv } from '../../../lib/testing/utils';
import * as supportpb from '../../../proto/support/support_pb';

const logger = new Logger('intercom');

/**
 * This gets a hashed with the user's id and the Secret Key from Intercom's Messenger.
 * This is needed if we want to enable the Identity Verification for the Messenger.
 * */
function rpcGenerateIntercomUserHMAC() {
  const req = new supportpb.GenerateIntercomHMACRequest();
  return rpc.callRetry('generateIntercomHMAC', rpc.client.generateIntercomHMAC, req);
}

export const intercomUserHashState = atomFamily<string, string | undefined>({
  key: 'intercomUserHashState',
  default: async (userId: string | undefined) => {
    if (isTestingEnv() || !userId) {
      return '';
    }
    try {
      const reply = await rpcGenerateIntercomUserHMAC();
      return reply.hmac;
    } catch (err) {
      logger.error(err);
      return '';
    }
  },
});

export function useIntercomUserHash(userId: string) {
  return useRecoilValue(intercomUserHashState(userId));
}
