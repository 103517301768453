import deepDiff from 'deep-diff';

import { SeedPlacementParams, SeedPlacementType } from '../../../../pvproto/ParaviewRpc';
import { Vector } from '../../../vectorAlgebra';

// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
export type LcvFilterType = (
  'import_dataset' |
  'clip' |
  'farfield' |
  'slice' |
  'contour' |
  'monitor_plane' |
  'threshold' |
  'streamlines' |
  'glyph' |
  'multi_slice'
);

export type Box = {
  position: Vector<3>,
  length: Vector<3>,
  rotation: Vector<3>,
}

export type Plane = {
  origin: Vector<3>,
  normal: Vector<3>,
}

export type SliceState = {
  plane: Plane,
  projectVectors: boolean,
}

export const vec3ToString = (vec: Vector<3>) => vec.join(' ');

export type ClipFunction = Box | Plane;

export type ClipState = {
  clipFunction: ClipFunction,
  smooth: boolean,
  invert: boolean,
}

export type ContourState = {
  isosurface_field: string;
  component: number;
  iso_values: number[];
}

export type GlyphState = {
  vector_field: string;
  glyph_scale_size: number;
  fixed_size_glyphs: boolean;
  every_nth_sampling: boolean;
  n_glyphs: number;
}

export type MonitorPlaneState = {
  origin: Vector<3>;
  normal: Vector<3>;
  volume_ids: string[];
  box_constraint_enabled: boolean;
  box_size?: Vector<3>;
  box_rotation?: Vector<3>;
  box_origin?: Vector<3>;
}
export type StreamlinesRake = {
  start: Vector<3>,
  end: Vector<3>,
}

export type StreamlinesSurface = {
  surfaces: string[];
  sampleRate: number;
  offset: number;
  projectOnSurface: boolean;
}

export type StreamlinesType = 'Rake' | 'Surface'

export type StreamlinesState = {
  direction: string;
  field_name: string;
  max_length: number;
  seed_type: SeedPlacementType;
  seedPlacementParams?: SeedPlacementParams;
  use_chunked_url_fetch: string;
  n_streamlines: number;
  project_on_surface: StreamlinesSurface['projectOnSurface'];
}

export type MultiSliceState = {
  start_pos: Vector<3>;
  end_pos: Vector<3>;
  n_slices: number;
  project_vectors: boolean;
}

export type ThresholdState = {
  field_name: string;
  field_component: number;
  min_value: number;
  max_value: number;
  smooth: boolean;
  invert: boolean;
  strict: boolean;
}

/**
 * Classification rules for determining whether a change requires workspace execution.
 * Each rule returns `true` if the change falls into a category that does not require execution.
 */
const WORKSPACE_EXECUTION_CLASSIFIERS = {
  isVisibilityOnly: (path) => path.at(-1) === 'visible',
  isDisplayPropsOnly: (path) => path.includes('displayProps'),
  isFilterParamChange: (path) => /param\.\b/i.test(path.join('.')),
} satisfies { [key: string]: (diffPath: string[]) => boolean };

/**
 * Determines whether a workspace execution is required based on the given differences.
 * Execution is required if at least one difference does not match any exclusion rule.
 */
export const requiresWorkspaceExecution = <T>(differences?: deepDiff.Diff<T>[]) => {
  if (!differences || differences.length === 0) {
    return false;
  }

  return (
    !(differences || []).every(
      (difference) => (
        Object.values(WORKSPACE_EXECUTION_CLASSIFIERS).some(
          (classifier) => classifier(difference.path || []),
        )
      ),
    )
  );
};
