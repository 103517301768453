// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../proto/client/simulation_pb';
import { UserGeometryMod } from '../proto/meshgeneration/meshgeneration_pb';
import { TreeNode } from '../pvproto/ParaviewRpc';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

import { ClientState } from './ParaviewClient';
import { getAdValue } from './adUtils';
import { Logger } from './observability/logs';
import { newNode, traverseTreeNodes, updateTreeNodes } from './paraviewUtils';
import {
  ProbePoint,
  actuatorDiskParamFromParticleGroup,
  getProbePoints,
  monitorPointParamFromParticleGroup,
  newActuatorDiskParam,
  newMonitorPointParam,
} from './particleGroupUtils';
import Renderer from './renderer';

const logger = new Logger('imposterFilteringUtils');

export const isImposter = (node: ParaviewRpc.TreeNode): boolean => {
  switch (node.param.typ) {
    case ParaviewRpc.TreeNodeType.ACTUATOR_DISK:
    case ParaviewRpc.TreeNodeType.MONITOR_PLANE:
    case ParaviewRpc.TreeNodeType.MONITOR_POINT:
      return true;
    default:
      return false;
  }
};

export const getImposterDisplayProps = (
  node: ParaviewRpc.TreeNode,
): ParaviewRpc.DisplayProps | null => {
  if (node.param.typ === ParaviewRpc.TreeNodeType.MONITOR_POINT) {
    return { reprType: 'Surface', displayVariable: null };
  }
  if (node.param.typ === ParaviewRpc.TreeNodeType.ACTUATOR_DISK) {
    return { reprType: 'Surface', displayVariable: null };
  }
  // For anything else, just take the global display props.
  return null;
};

// Get the id of the node in the simulation tree that represents the imposter.
// Returns null if the node is not an imposter.
export const getImposterId = (node: ParaviewRpc.TreeNode) => {
  if (node.param.typ === ParaviewRpc.TreeNodeType.ACTUATOR_DISK) {
    return node.param.particleGroupId;
  }
  if (node.param.typ === ParaviewRpc.TreeNodeType.MONITOR_POINT) {
    return node.param.id;
  }
  if (node.param.typ === ParaviewRpc.TreeNodeType.MONITOR_PLANE) {
    return node.param.id;
  }
  return null;
};

// Traverse the visualization tree nodes and find the imposter using the id associated with a param.
export const findImposter = (
  id: string | null,
  root: ParaviewRpc.TreeNode | undefined,
): ParaviewRpc.TreeNode | null => {
  if (!id) {
    return null;
  }
  let imposter: ParaviewRpc.TreeNode | null = null;
  const isTheNodeImLookingFor = (node: ParaviewRpc.TreeNode) => {
    if (getImposterId(node) === id) {
      imposter = node;
    }
  };

  // This will traverse through all nodes and not return early.  Our trees are never deep (maybe
  // 4ish total nodes) so this is not bad.
  if (root) {
    traverseTreeNodes(root, isTheNodeImLookingFor);
  }
  return imposter;
};

// Finds an imposter and if present removes it from the visualization tree.
// The delete function is passed in from paraviewContext.
export const deleteImposter = (
  id: string,
  root: ParaviewRpc.TreeNode,
  deleteNode: (nodeId: string) => void,
) => {
  const imposter = findImposter(id, root);
  if (imposter) {
    deleteNode(imposter.id);
  }
};

// Convert a monitor plane to a TreeNode param.
export function monitorPlaneToParam(
  plane: simulationpb.MonitorPlane,
): ParaviewRpc.MonitorPlaneParam {
  let boxclip: ParaviewRpc.BoxClipParam | null = null;
  const isConstrained = plane.monitorPlaneBoxClip;
  if (isConstrained) {
    boxclip = {
      typ: 'BoxClip',
      position: {
        x: getAdValue(plane.monitorPlaneClipCenter?.x),
        y: getAdValue(plane.monitorPlaneClipCenter?.y),
        z: getAdValue(plane.monitorPlaneClipCenter?.z),
      },
      rotation: {
        x: getAdValue(plane.monitorPlaneClipRotation?.x),
        y: getAdValue(plane.monitorPlaneClipRotation?.y),
        z: getAdValue(plane.monitorPlaneClipRotation?.z),
      },
      length: {
        x: getAdValue(plane.monitorPlaneClipSize?.x),
        y: getAdValue(plane.monitorPlaneClipSize?.y),
        z: getAdValue(plane.monitorPlaneClipSize?.z),
      },
    };
  }
  let volumes: ParaviewRpc.ExtractVolumesParam | null = null;
  if (plane.monitorPlaneVolumeClip) {
    volumes = {
      typ: 'ExtractVolumes',
      volumes: plane.monitorPlaneVolumes.map((identifier) => identifier.id),
    };
  }
  const monitorParam: ParaviewRpc.MonitorPlaneParam = {
    typ: ParaviewRpc.TreeNodeType.MONITOR_PLANE,
    plane: {
      typ: 'Plane',
      origin: {
        x: getAdValue(plane.monitorPlanePoint?.x),
        y: getAdValue(plane.monitorPlanePoint?.y),
        z: getAdValue(plane.monitorPlanePoint?.z),
      },
      normal: {
        x: getAdValue(plane.monitorPlaneNormal?.x),
        y: getAdValue(plane.monitorPlaneNormal?.y),
        z: getAdValue(plane.monitorPlaneNormal?.z),
      },
    },
    boxclip,
    volumes,
    id: plane.monitorPlaneId,
  };
  return monitorParam;
}

// Search for an existing imposter by id, and if none is found,
// create a new one.
export function getMonitorPlaneImposter(
  plane: simulationpb.MonitorPlane,
  root: ParaviewRpc.TreeNode,
  addNode: (parentNodeId: string, newNode: ParaviewRpc.TreeNode) => void,
): ParaviewRpc.TreeNode {
  const imposter = findImposter(plane.monitorPlaneId, root);
  if (!imposter) {
    const monitorParam = monitorPlaneToParam(plane);
    const createdNode = newNode(monitorParam, root, true, null);
    createdNode.displayProps = getImposterDisplayProps(createdNode);
    // Under the hood this is an asyn rpc
    addNode(root.id, createdNode);
    return createdNode;
  }
  return imposter;
}

// Remove all filters in the visualization tree that have imposters.
function removeImposters(root: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode {
  // Logic for removing filters. Currently, this is only for imposters,
  // but could be for any paraview filter that should not be shared
  // between two tabs/results. An alternative to this listing is to have
  // filters mark themselves as imposters/input dependent. In that world,
  // we would have a single condition, but the filters would be responsible
  // for marking themselves.
  const shouldKeep = (node: ParaviewRpc.TreeNode): boolean => !isImposter(node);

  const removeNodes = (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => ({
    ...node,
    child: node.child.filter(shouldKeep),
  });

  return updateTreeNodes(root, removeNodes);
}

type ImpostersConfig = { particleGroups?: boolean; probePoints?: boolean; monitorPlanes?: boolean };

export function addImposters(
  simParam: simulationpb.SimulationParam,
  root: ParaviewRpc.TreeNode,
  attrs: ParaviewRpc.ViewAttrs,
  // The previous root node before imposters were removed for the new session.
  formerRoot: ParaviewRpc.TreeNode,
  config: ImpostersConfig = { particleGroups: true, probePoints: true, monitorPlanes: true },
) {
  const insertImposter = (param: ParaviewRpc.TreeNodeParam) => {
    const newImposter: ParaviewRpc.TreeNode = newNode(param, root, true, null);
    newImposter.displayProps = getImposterDisplayProps(newImposter);
    // Restore the visibility of imposters from the previous session.
    newImposter.visible = findImposter(getImposterId(newImposter), formerRoot)?.visible ?? true;
    // Its important to edit the tree in place, since the newNode function
    // uses the tree to decide on names. If newNode can't see the new names
    // then all disks are named the same, which causes issues.
    root!.child.push(newImposter);
  };

  if (config.particleGroups) {
    const particleGroups = simParam.particleGroup;
    // Actuator disks are listed individually, so loop through
    // the particle groups and add them as we find them.
    particleGroups.forEach((param: simulationpb.ParticleGroup) => {
      if (param.particleGroupType === simulationpb.ParticleGroupType.ACTUATOR_DISK) {
        const imposterParam = actuatorDiskParamFromParticleGroup(param);
        insertImposter(imposterParam);
      }
    });
  }

  if (config.probePoints) {
  // Probe (aka, monitor) points are all listed in a table. Grab the
  // list and add all of them.
    const probePoints = getProbePoints(simParam);
    probePoints.forEach((point: ProbePoint) => {
      const imposterParam = newMonitorPointParam(point.id, root, attrs);
      imposterParam.point.center.x = point.x;
      imposterParam.point.center.y = point.y;
      imposterParam.point.center.z = point.z;
      if (attrs.sphereSize) {
        imposterParam.point.radius = attrs.sphereSize;
      }
      insertImposter(imposterParam);
    });
  }

  if (config.monitorPlanes) {
  // Monitor Planes
    const planes = simParam.monitorPlane;
    planes.forEach((plane: simulationpb.MonitorPlane) => {
      const imposterParam = monitorPlaneToParam(plane);
      insertImposter(imposterParam);
    });
  }
}

// Delete the far field imposter. This is a no-op if the far field doesn't exist.
export function deleteFarFieldImposter(paraviewClientState: ClientState) {
  if (!paraviewClientState.client) {
    return;
  }
  ParaviewRpc.clearfarfieldpreview(paraviewClientState.client).catch((reason: any) => {
    logger.error('Failed to clearfarfieldpreview: ', reason);
  });
}

function getFarFieldParam(
  newOp: UserGeometryMod,
  transparent: boolean,
): ParaviewRpc.FarFieldParam | null {
  let imposterParam: ParaviewRpc.FarFieldParam | null = null;

  switch (newOp.farField.case) {
    case 'sphere': {
      const { radius, center } = newOp.farField.value;
      if (center !== undefined && radius !== undefined) {
        imposterParam = {
          typ: ParaviewRpc.TreeNodeType.FAR_FIELD,
          transparent,
          paramType: 'SphereParam',
          param: {
            typ: 'Sphere',
            center: {
              x: center.x,
              y: center.y,
              z: center.z,
            },
            radius,
          },
        };
      }
      break;
    }
    case 'halfSphere': {
      const { radius, center, normal } = newOp.farField.value;
      if (radius !== undefined && center !== undefined && normal !== undefined) {
        imposterParam = {
          typ: ParaviewRpc.TreeNodeType.FAR_FIELD,
          paramType: 'HalfSphereParam',
          transparent,
          param: {
            typ: 'HalfSphere',
            center: {
              x: center.x,
              y: center.y,
              z: center.z,
            },
            normal: {
              x: normal.x,
              y: normal.y,
              z: normal.z,
            },
            radius,
          },
        };
      }
      break;
    }
    case 'cube': {
      const { min, max } = newOp.farField.value;
      if (min !== undefined && max !== undefined) {
        imposterParam = {
          typ: ParaviewRpc.TreeNodeType.FAR_FIELD,
          paramType: 'AxisAlignedBoxParam',
          transparent,
          param: {
            typ: 'AxisAlignedBox',
            min: {
              x: min.x,
              y: min.y,
              z: min.z,
            },
            max: {
              x: max.x,
              y: max.y,
              z: max.z,
            },
          },
        };
      }
      break;
    }
    case 'cylinder': {
      const { radius, start, end } = newOp.farField.value;
      if (radius !== undefined && start !== undefined && end !== undefined) {
        imposterParam = {
          typ: ParaviewRpc.TreeNodeType.FAR_FIELD,
          paramType: 'CylinderParam',
          transparent,
          param: {
            typ: 'Cylinder',
            start: {
              x: start.x,
              y: start.y,
              z: start.z,
            },
            end: {
              x: end.x,
              y: end.y,
              z: end.z,
            },
            radius,
          },
        };
      }
      break;
    }
    default: // no default, cases exhausted
  }
  return imposterParam;
}

// Update the far field imposter with new parameters. The create param tells
// paraview to create the imposter if none existed which helps us delete the far
// field. This is required since deleting the far field doesn't update the newOp
// state fast enough and without it, we end up reverting to the previous state
// state when the component is unmounted.
export const updateFarFieldImposter = (
  renderer: Renderer,
  newOp: UserGeometryMod,
  transparent: boolean,
  paraviewClientState: ClientState,
) => {
  if (!paraviewClientState.client) {
    return;
  }

  const imposterParam: ParaviewRpc.FarFieldParam | null = getFarFieldParam(newOp, transparent);

  // Double check to make sure we actually have a populated object.
  if (!imposterParam) {
    logger.error('Could not set the imposter param.');
    return;
  }
  ParaviewRpc.setfarfieldpreview(
    paraviewClientState.client,
    imposterParam,
  ).catch((reason: any) => {
    logger.error('Failed to set farfield preview: ', reason);
  });
  renderer.resetCamera();
};

// This function ensures that imposters are only present for the current simulation. That is, it
// removes all imposters from the root, then traverses the params and adds imposters.
export const updateImposters = (
  param: simulationpb.SimulationParam,
  root: ParaviewRpc.TreeNode,
  attrs: ParaviewRpc.ViewAttrs,
  config?: ImpostersConfig,
): ParaviewRpc.TreeNode => {
  const newRoot = removeImposters(root);
  // These are the imposters for actuator disks and monitor points.
  addImposters(param, newRoot, attrs, root, config);
  return newRoot;
};

// Takes a list of ids of objects in the sim tree and returns their corresponding paraview tree node
// names. If an object does not have a associated tree node it is simply omitted.
export const convertToPvTreeNodeNames = (
  simTreeIds: string[],
  pvRootNode: ParaviewRpc.TreeNode,
) => {
  const names: string[] = [];
  traverseTreeNodes(pvRootNode, (node) => {
    const id = getImposterId(node);
    if (id && simTreeIds.includes(id)) {
      names.push(node.name);
    }
  });
  return names;
};

// Get the imposter for this particle group. Will create a new node if one is not found. The
// addNode function is passed in from paraviewContext.
export const getParticleGroupImposter = (
  particleGroup: simulationpb.ParticleGroup | undefined,
  groupId: string,
  viewState: ParaviewRpc.ViewState | null,
  param: simulationpb.SimulationParam,
  addNode: (parentNodeId: string, newNode: ParaviewRpc.TreeNode) => void,
): TreeNode | null => {
  if (viewState?.root) {
    // Particle groups for actuator disks contain the id of what we are looking for, but the id of a
    // monitor / probe point is behind several layers of indireciton.
    const id = (particleGroup?.particleGroupType === simulationpb.ParticleGroupType.ACTUATOR_DISK) ?
      particleGroup.particleGroupId : groupId;

    let imposter: TreeNode | null = findImposter(id, viewState.root);

    if (!imposter) {
      switch (particleGroup?.particleGroupType) {
        case (simulationpb.ParticleGroupType.ACTUATOR_DISK): {
          let diskParam: ParaviewRpc.ActuatorDiskParam;
          if (particleGroup) {
            diskParam = actuatorDiskParamFromParticleGroup(particleGroup);
          } else {
            diskParam = newActuatorDiskParam(id);
          }
          const createdNode = newNode(diskParam, viewState.root, true, null);
          // Set the imposter displayProps depending on its type.
          createdNode.displayProps = getImposterDisplayProps(createdNode);
          // Under the hood this is an async rpc
          addNode(viewState.root.id, createdNode);
          imposter = createdNode;
          break;
        }
        case (simulationpb.ParticleGroupType.PROBE_POINTS): {
          let pointParam: ParaviewRpc.MonitorPointParam;
          if (particleGroup) {
            pointParam = monitorPointParamFromParticleGroup(
              particleGroup,
              id,
              param,
              viewState,
            );
          } else {
            pointParam = newMonitorPointParam(id, viewState.root, viewState.attrs);
          }
          const createdNode = newNode(pointParam, viewState.root, true, null);
          // newNode sets the displayProps. Change it to null, so this node picks up
          // the global display props. If this line is not present, the display will
          // be a solid color.
          createdNode.displayProps = getImposterDisplayProps(createdNode);
          // Under the hood this is an asyn rpc
          addNode(viewState.root.id, createdNode);
          imposter = createdNode;
          break;
        }
        default: {
          break;
        }
      }
    }
    return imposter;
  }
  return null;
};
