// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { VirtuosoHandle } from 'react-virtuoso';
import { useRecoilTransaction_UNSTABLE } from 'recoil';

import { SimulationRowProps } from '../../lib/componentTypes/simulationTree';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { GetCurrentSelectionContexts, getCurrentSelection } from '../../lib/selectionUtils';
import { SimulationTreeNode } from '../../lib/simulationTree/node';
import { useExtractSurfacesValue } from '../../recoil/extractSurfacesState';
import { useOutputNodes } from '../../recoil/outputNodes';
import { useSimulationTreeSubselect } from '../../recoil/simulationTreeSubselect';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import useMeshMultiPart from '../../recoil/useMeshingMultiPart';
import { useControlPanelModeValue } from '../../recoil/useProjectPage';
import { simulationTreeRowsOpenedState, useRowsOpenedValue } from '../../recoil/useSimulationTreeState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useGeometryTree } from '../../state/internal/tree/section/geometry';
import { useSimulationTree } from '../../state/internal/tree/simulation';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

export const useTree = (
  panelRoot: SimulationTreeNode,
  filtering?: boolean,
) => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const {
    activeNodeTable,
    scrollToAction,
    selectedNode,
    selectedNodeIds,
    setScrollTo,
  } = useSelectionContext();

  // == Recoil
  const controlPanelMode = useControlPanelModeValue();
  const treeSubselect = useSimulationTreeSubselect();
  const experimentConfig = useEnabledExperiments();
  const nodesOpened = useRowsOpenedValue(projectId, controlPanelMode);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const [outputNodes] = useOutputNodes(projectId, '', '');
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const extractSurfacesList = useExtractSurfacesValue();
  const staticVolumes = useStaticVolumes(projectId);

  // == State
  const listRef = useRef<VirtuosoHandle>(null);

  // For a given node, render it and all of its children if it is open.
  const buildRowProps = useCallback((
    node: SimulationTreeNode,
    depth = 0,
  ): SimulationRowProps[] => {
    // If we are currently searching trough the items, we should show them in a flat structure, so
    // no grouping or nesting (except 1 level under the main container)
    depth = filtering ? Math.min(1, depth) : depth;
    // And to make the searching work, we should return all nodes, even if their parent is collapsed
    const includeChildren = nodesOpened[node.id] || filtering;

    return ([
      { depth, node },
      ...includeChildren ? node.children.reduce((result, child) => {
        result.push(...buildRowProps(child, depth + 1));
        return result;
      }, [] as SimulationRowProps[]) : [],
    ]);
  }, [nodesOpened, filtering]);

  const rowProps = useMemo(
    () => panelRoot.children.reduce((result, node) => {
      result.push(...buildRowProps(node));
      return result;
    }, [] as SimulationRowProps[]),
    [
      panelRoot,
      buildRowProps,
    ],
  );

  // This will find the index of the node that we want to scroll to and
  // will use the react-virtuoso's internal scrollToIndex method.
  const doListScroll = useCallback(
    (nodeIds: string[], resetScrollToNode?: boolean, fast?: boolean) => {
      if (!nodeIds.length) {
        return;
      }
      const scrollToIndex = rowProps.findIndex((row) => nodeIds.includes(row.node.id));
      if (scrollToIndex === -1) {
        return;
      }
      // For trees that use react-virtuoso
      if ((listRef.current as VirtuosoHandle)?.scrollToIndex) {
        // To have a good scrolling UX, the scroll should put the node somewhere between the top
        // and the middle of the view. React-virtuoso has scrollTo "center" which would be ideal,
        // but it doesn't work reliably. So to have a consistent scroll behavior, we'll scroll in a
        // way that puts the node near the top (with 3 more nodes before it in the view).
        (listRef.current as VirtuosoHandle)?.scrollToIndex({
          align: 'start',
          index: scrollToIndex - 3,
          behavior: fast ? 'auto' : 'smooth',
        });
      // For native lists
      } else {
        document?.querySelector(`[data-row-id="${nodeIds[0]}"]`)?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
      if (resetScrollToNode) {
        setScrollTo({ node: '' });
      }
    },
    [listRef, rowProps, setScrollTo],
  );

  // recoil callback to expand simtree rows, if necessary, so all selected
  // rows can be seen when scrolling through.
  const maybeUpdateRowsOpened = useRecoilTransaction_UNSTABLE(({ get, set }) => (
    simTreeRoot: SimulationTreeNode,
    selectedIds: string[],
  ) => {
    const rowsOpenedState = simulationTreeRowsOpenedState({ projectId, mode: controlPanelMode });
    const prevValue = get(rowsOpenedState);
    const newValue = { ...prevValue };

    let modified = false;
    selectedIds.forEach((nodeId) => {
      // Ensure that each selected node is visible by opening up all of its ancestors.
      let selectedAncestor = simTreeRoot.getDescendant(nodeId)?.parent;
      while (selectedAncestor) {
        if (selectedAncestor.children.length > 0 &&
          !newValue[selectedAncestor.id]) {
          newValue[selectedAncestor.id] = true;
          modified = true;
        }
        selectedAncestor = selectedAncestor.parent;
      }
    });
    // Don't update if we didn't change anything, to avoid triggering
    // an unnecessary re-render.
    if (modified) {
      set(rowsOpenedState, newValue);
    }
  }, []);

  // If the node table is activated for editing,
  // try to scroll to the respective selected nodes in the control panel.
  useEffect(() => {
    if (activeNodeTable.type !== NodeTableType.NONE) {
      const getSelectionContexts: GetCurrentSelectionContexts = {
        providedSelection: {
          selectedNode,
          selectedNodeIds,
          activeNodeTable,
          treeSubselect,
        },
        selection: {
          experimentConfig,
          param: simParam,
          meshMultiPart,
          extractSurfacesList,
          outputNodes,
          staticVolumes,
        },
      };

      const currentSelection = getCurrentSelection(getSelectionContexts);
      doListScroll(currentSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNodeTable]);

  // A scroll can also be triggered if another component uses the
  // setScrollTo setter and sets a nodeId.
  useEffect(() => {
    // In some cases clicking another node will expand the parent container (e.g. clicking
    // a "stopping condition" link will expand the "Simulation settings"). In order to
    // make the scrollToNode work, we should put it in a requestAnimationFrame so that
    // it waits for the expand to happen. Otherwise it will scroll to a wrong place.
    window.requestAnimationFrame(() => {
      scrollToAction.node && doListScroll([scrollToAction.node], true, scrollToAction.fast);
    });
  }, [doListScroll, scrollToAction]);

  return {
    rowProps,
    listRef,
    maybeUpdateRowsOpened,
  };
};

export const useGetNodeFromAnyTree = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const simulationTree = useSimulationTree(projectId, workflowId, jobId);
  const geometryTree = useGeometryTree(projectId, workflowId, jobId);

  const getNodeFromAnyTree = useCallback((nodeId: string) => {
    // First check the Simulation tree because it filters out the Geometry nodes when the
    // geometry tree is separated.
    const nodeInSimulationTree = simulationTree.getDescendant(nodeId);
    if (nodeInSimulationTree) {
      return nodeInSimulationTree;
    }
    // If the node is not found in the main Simulation tree, check the separated Geometry tree
    return geometryTree.getDescendant(nodeId);
  }, [simulationTree, geometryTree]);

  return getNodeFromAnyTree;
};
