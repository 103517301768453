// Copyright 2023-2025 Luminary Cloud, Inc. All Rights Reserved.
// Generated by generate_client_params.py. DO NOT EDIT

import * as ProtoDescriptor from './ProtoDescriptor';
import * as ParamPb from './proto/client/simulation_pb';
import * as QuantityPb from './proto/quantity/quantity_pb';

/* eslint-disable max-len */
export enum ParamName {
  Schema = 'schema',
  Url = 'url',
  Filename = 'filename',
  ReferencePressure = 'reference_pressure',
  DensityRelationship = 'density_relationship',
  MolecularWeight = 'molecular_weight',
  ConstantDensityValue = 'constant_density_value',
  SpecificHeatCp = 'specific_heat_cp',
  LaminarThermalConductivity = 'laminar_thermal_conductivity',
  LaminarConstantThermalPrandtlConstant = 'laminar_constant_thermal_prandtl_constant',
  LaminarConstantThermalConductivityConstant = 'laminar_constant_thermal_conductivity_constant',
  ThermalConductivityTableData = 'thermal_conductivity_table_data',
  BoussinesqApproximation = 'boussinesq_approximation',
  BoussinesqTempRef = 'boussinesq_temp_ref',
  ThermalExpansionCoefficient = 'thermal_expansion_coefficient',
  MaterialFluidPreset = 'material_fluid_preset',
  LaminarViscosityModelNewtonian = 'laminar_viscosity_model_newtonian',
  SutherlandViscosityRef = 'sutherland_viscosity_ref',
  SutherlandViscosityTempRef = 'sutherland_viscosity_temp_ref',
  SutherlandConstant = 'sutherland_constant',
  LaminarConstantViscosityConstant = 'laminar_constant_viscosity_constant',
  DynamicViscosityTableData = 'dynamic_viscosity_table_data',
  ConstantDensityValueSolid = 'constant_density_value_solid',
  SpecificHeatCpSolid = 'specific_heat_cp_solid',
  ThermalConductivityConstantSolid = 'thermal_conductivity_constant_solid',
  MaterialSolidPreset = 'material_solid_preset',
  FloatType = 'float_type',
  Gravity = 'gravity',
  Acceleration = 'acceleration',
  FlowBehavior = 'flow_behavior',
  SolverParams = 'solver_params',
  Surfaces = 'surfaces',
  DeformedCoordsId = 'deformed_coords_id',
  DeformedCoordsUrl = 'deformed_coords_url',
  PrimalSimulationId = 'primal_simulation_id',
  TimeMarching = 'time_marching',
  TimeImplicitOrder = 'time_implicit_order',
  PhysicalTimeStepMethod = 'physical_time_step_method',
  TimeStepVal = 'time_step_val',
  TimeStepRamp = 'time_step_ramp',
  TimeStepRampInitialVal = 'time_step_ramp_initial_val',
  TimeStepRampIterationBegin = 'time_step_ramp_iteration_begin',
  TimeStepRampIterationEnd = 'time_step_ramp_iteration_end',
  ComputeStatistics = 'compute_statistics',
  StatisticsStartIteration = 'statistics_start_iteration',
  StatisticsUpdateInterval = 'statistics_update_interval',
  FrameId = 'frame_id',
  FrameName = 'frame_name',
  FrameParent = 'frame_parent',
  AttachedDomains = 'attached_domains',
  AttachedBoundaries = 'attached_boundaries',
  MotionType = 'motion_type',
  MotionTranslationVelocity = 'motion_translation_velocity',
  MotionTranslation = 'motion_translation',
  MotionFormulation = 'motion_formulation',
  MotionSpecification = 'motion_specification',
  MotionAngularVelocity = 'motion_angular_velocity',
  MotionRotationAngles = 'motion_rotation_angles',
  TransformName = 'transform_name',
  TransformType = 'transform_type',
  TransformRotationAngles = 'transform_rotation_angles',
  TransformTranslation = 'transform_translation',
  ParticleGroupId = 'particle_group_id',
  ParticleGroupName = 'particle_group_name',
  ParticleGroupBehaviorModelRef = 'particle_group_behavior_model_ref',
  ParticleGroupType = 'particle_group_type',
  ActuatorDiskInnerRadius = 'actuator_disk_inner_radius',
  ActuatorDiskOuterRadius = 'actuator_disk_outer_radius',
  ActuatorDiskCenter = 'actuator_disk_center',
  ActuatorDiskOrientationSelection = 'actuator_disk_orientation_selection',
  ActuatorDiskRotationAngle = 'actuator_disk_rotation_angle',
  ActuatorDiskNormalVector = 'actuator_disk_normal_vector',
  SourceParticleRadius = 'source_particle_radius',
  SearchRadiusScaleFactor = 'search_radius_scale_factor',
  IsotropicGaussianScaleFactor = 'isotropic_gaussian_scale_factor',
  ParticlePositionsTable = 'particle_positions_table',
  MonitorPlaneId = 'monitor_plane_id',
  MonitorPlaneName = 'monitor_plane_name',
  MonitorPlanePoint = 'monitor_plane_point',
  MonitorPlaneNormal = 'monitor_plane_normal',
  MonitorPlaneBoxClip = 'monitor_plane_box_clip',
  MonitorPlaneClipCenter = 'monitor_plane_clip_center',
  MonitorPlaneClipSize = 'monitor_plane_clip_size',
  MonitorPlaneClipRotation = 'monitor_plane_clip_rotation',
  MonitorPlaneVolumeClip = 'monitor_plane_volume_clip',
  BodyFrameId = 'body_frame_id',
  SurfaceName = 'surface_name',
  ItersPerOutput = 'iters_per_output',
  DebugOutput = 'debug_output',
  DebugOutputInteriorSurfaceData = 'debug_output_interior_surface_data',
  AreaRef = 'area_ref',
  LengthRef = 'length_ref',
  UseAeroMomentRefLengths = 'use_aero_moment_ref_lengths',
  LengthRefPitch = 'length_ref_pitch',
  LengthRefRoll = 'length_ref_roll',
  LengthRefYaw = 'length_ref_yaw',
  PRef = 'p_ref',
  TRef = 't_ref',
  VRef = 'v_ref',
  RefinementIterations = 'refinement_iterations',
  RefinementDispatchInterval = 'refinement_dispatch_interval',
  MaxRefinementInterval = 'max_refinement_interval',
  InitialTargetComplexity = 'initial_target_complexity',
  FinalTargetComplexity = 'final_target_complexity',
  MeshingMethod = 'meshing_method',
  TargetCvMillions = 'target_cv_millions',
  AllTet = 'all_tet',
  UserScaling = 'user_scaling',
  NLayers = 'n_layers',
  InitialSize = 'initial_size',
  GrowthRate = 'growth_rate',
  RelaxationMethod = 'relaxation_method',
  ImplicitMethod = 'implicit_method',
  LinsolIterations = 'linsol_iterations',
  LinsolTolerance = 'linsol_tolerance',
  LinearSolverType = 'linear_solver_type',
  LinsolAmgPreSweeps = 'linsol_amg_pre_sweeps',
  LinsolAmgPostSweeps = 'linsol_amg_post_sweeps',
  LinsolAmgCoarseningSize = 'linsol_amg_coarsening_size',
  LinsolAmgLevels = 'linsol_amg_levels',
  LinsolAmgRelaxation = 'linsol_amg_relaxation',
  LinsolAmgCycleType = 'linsol_amg_cycle_type',
  LinsolAmgFreezeLevelsThreshold = 'linsol_amg_freeze_levels_threshold',
  LinsolAmgSmoother = 'linsol_amg_smoother',
  ExplicitMethod = 'explicit_method',
  ControlsPresetVersion = 'controls_preset_version',
  SolutionControlsHeatPreset = 'solution_controls_heat_preset',
  AdjointSolutionMethod = 'adjoint_solution_method',
  AdjointGmresRestartIters = 'adjoint_gmres_restart_iters',
  GradientMethod = 'gradient_method',
  HlsqBlend = 'hlsq_blend',
  GeometryFixes = 'geometry_fixes',
  GeometryFixesMitigations = 'geometry_fixes_mitigations',
  DiscretizationPresetVersion = 'discretization_preset_version',
  SpatialDiscretizationHeatPreset = 'spatial_discretization_heat_preset',
  BoundaryConditionName = 'boundary_condition_name',
  BoundaryConditionDisplayName = 'boundary_condition_display_name',
  BoundaryConditionInterfaceId = 'boundary_condition_interface_id',
  ProfileBc = 'profile_bc',
  ProfileBcData = 'profile_bc_data',
  ProfileType = 'profile_type',
  HeatPhysicalBoundary = 'heat_physical_boundary',
  FixedHeatFlux = 'fixed_heat_flux',
  FixedIntegratedHeatFlux = 'fixed_integrated_heat_flux',
  HeatFluxCol = 'heat_flux_col',
  FixedTemperature = 'fixed_temperature',
  HeatTransferCoefficient = 'heat_transfer_coefficient',
  InitializationType = 'initialization_type',
  UniformT = 'uniform_t',
  ExistingSolutionUrl = 'existing_solution_url',
  ExistingSolutionId = 'existing_solution_id',
  ProfileSource = 'profile_source',
  ProfileSourceData = 'profile_source_data',
  HeatSourceCol = 'heat_source_col',
  HeatSourceId = 'heat_source_id',
  HeatSourceName = 'heat_source_name',
  HeatSourceZoneIds = 'heat_source_zone_ids',
  HeatSourceType = 'heat_source_type',
  HeatSourcePower = 'heat_source_power',
  HeatSourcePowerPerUnitVolume = 'heat_source_power_per_unit_volume',
  SlidingInterfaceId = 'sliding_interface_id',
  SlidingInterfaceName = 'sliding_interface_name',
  SlidingA = 'sliding_a',
  SlidingB = 'sliding_b',
  SlidingMatchingTranslationTransform = 'sliding_matching_translation_transform',
  SlidingMatchingPeriodicRotationAngles = 'sliding_matching_periodic_rotation_angles',
  SlidingMatchingPeriodicCenterOfRotation = 'sliding_matching_periodic_center_of_rotation',
  InterfaceType = 'interface_type',
  PeriodicPairName = 'periodic_pair_name',
  BoundA = 'bound_a',
  BoundB = 'bound_b',
  PeriodicBcType = 'periodic_bc_type',
  Translational = 'translational',
  PeriodicTranslation = 'periodic_translation',
  PeriodicCenterOfRotation = 'periodic_center_of_rotation',
  PeriodicRotationAngles = 'periodic_rotation_angles',
  FluidType = 'fluid_type',
  ViscousModel = 'viscous_model',
  DesFormulation = 'des_formulation',
  RansRegion = 'rans_region',
  ZdesRmin = 'zdes_rmin',
  ZdesRmax = 'zdes_rmax',
  SubGridScaleModel = 'sub_grid_scale_model',
  CS = 'c_s',
  CVreman = 'c_vreman',
  CWale = 'c_wale',
  CSigma = 'c_sigma',
  CAmd = 'c_amd',
  TurbulentThermalConductivityPrandtlConstant = 'turbulent_thermal_conductivity_prandtl_constant',
  TransitionModel = 'transition_model',
  TransitionModelCrossFlow = 'transition_model_cross_flow',
  TransitionFreeStreamTurbulenceIntensity = 'transition_free_stream_turbulence_intensity',
  TurbulenceModel = 'turbulence_model',
  QcrSa = 'qcr_sa',
  RotationCorrectionSa = 'rotation_correction_sa',
  CRot = 'c_rot',
  CSaDes = 'C_sa_des',
  CB1 = 'c_b_1',
  Sigma = 'sigma',
  CB2 = 'c_b_2',
  Kappa = 'kappa',
  CW2 = 'c_w_2',
  CW3 = 'c_w_3',
  CV1 = 'c_v_1',
  CT3 = 'c_t_3',
  CT4 = 'c_t_4',
  CR1 = 'c_r_1',
  QcrSst = 'qcr_sst',
  KatoLaunder = 'kato_launder',
  TurbulenceModelConstants = 'turbulence_model_constants',
  CSstDes1 = 'C_sst_des1',
  CSstDes2 = 'C_sst_des2',
  SigmaK1 = 'sigma_k_1',
  SigmaK2 = 'sigma_k_2',
  SigmaW1 = 'sigma_w_1',
  SigmaW2 = 'sigma_w_2',
  Beta1 = 'beta_1',
  Beta2 = 'beta_2',
  BetaStar = 'beta_star',
  KappaSst = 'kappa_sst',
  A1 = 'a_1',
  Gamma1 = 'gamma_1',
  Gamma2 = 'gamma_2',
  PseudoTimeStepMethod = 'pseudo_time_step_method',
  Cfl = 'cfl',
  LocalTimeStepping = 'local_time_stepping',
  PseudoTimeStepVal = 'pseudo_time_step_val',
  JacobianUpdateMethod = 'jacobian_update_method',
  JacobianUpdateInterval = 'jacobian_update_interval',
  JacobianWarmupThreshold = 'jacobian_warmup_threshold',
  RobustStartup = 'robust_startup',
  RobustStartupInitialCfl = 'robust_startup_initial_cfl',
  RobustStartupIterations = 'robust_startup_iterations',
  RelaxFlow = 'relax_flow',
  RelaxTurb = 'relax_turb',
  UpdateLimitFlow = 'update_limit_flow',
  UpdateLimitTurb = 'update_limit_turb',
  SolutionControlsFluidPreset = 'solution_controls_fluid_preset',
  AdjointSecondOrderDamping = 'adjoint_second_order_damping',
  AdjointFrozenTurbulence = 'adjoint_frozen_turbulence',
  AdjointFrozenTransition = 'adjoint_frozen_transition',
  ConvectiveSchemesDensityBased = 'convective_schemes_density_based',
  EntropyEpsilon = 'entropy_epsilon',
  Preconditioning = 'preconditioning',
  FdsMinLowDissipation = 'fds_min_low_dissipation',
  SkewSymmetricFormulation = 'skew_symmetric_formulation',
  ArtificialViscosityModel = 'artificial_viscosity_model',
  AlphaHybrid = 'alpha_hybrid',
  UpwindSchemeOrder = 'upwind_scheme_order',
  RobustDissipation = 'robust_dissipation',
  Limiter = 'limiter',
  LimiterKappa = 'limiter_kappa',
  UmusclChi = 'umuscl_chi',
  AlphaLd2 = 'alpha_ld2',
  OrderBlend = 'order_blend',
  SpatialDiscretizationFluidPreset = 'spatial_discretization_fluid_preset',
  PhysicalBoundary = 'physical_boundary',
  WallMomentum = 'wall_momentum',
  RoughnessControl = 'roughness_control',
  EquivalentSandGrainRoughness = 'equivalent_sand_grain_roughness',
  WallEnergy = 'wall_energy',
  WallMovementTranslation = 'wall_movement_translation',
  WallMovementRotationCenter = 'wall_movement_rotation_center',
  WallMovementAngularVelocity = 'wall_movement_angular_velocity',
  InletMomentum = 'inlet_momentum',
  InletVelocityMagnitude = 'inlet_velocity_magnitude',
  InletVelocityMagnitudeCol = 'inlet_velocity_magnitude_col',
  InletVelocityComponents = 'inlet_velocity_components',
  TotalPressureCol = 'total_pressure_col',
  MassFlowRate = 'mass_flow_rate',
  TotalPressure = 'total_pressure',
  DirectionSpecification = 'direction_specification',
  FlowDirection = 'flow_direction',
  InletEnergy = 'inlet_energy',
  TotalTemperature = 'total_temperature',
  TotalTemperatureCol = 'total_temperature_col',
  OutletStrategy = 'outlet_strategy',
  OutletTargetMassFlowRate = 'outlet_target_mass_flow_rate',
  OutletReferencePressure = 'outlet_reference_pressure',
  OutletReferenceTemperature = 'outlet_reference_temperature',
  OutletPressure = 'outlet_pressure',
  FanCurveTableData = 'fan_curve_table_data',
  HeadLossCoefficient = 'head_loss_coefficient',
  OutletPressureConstraint = 'outlet_pressure_constraint',
  FarfieldMachNumber = 'farfield_mach_number',
  FarfieldVelocityMagnitude = 'farfield_velocity_magnitude',
  FarfieldPressure = 'farfield_pressure',
  FarfieldTemperature = 'farfield_temperature',
  FarFieldFlowDirectionSpecification = 'far_field_flow_direction_specification',
  FarfieldFlowDirection = 'farfield_flow_direction',
  FarfieldAngleAlpha = 'farfield_angle_alpha',
  FarfieldAngleBeta = 'farfield_angle_beta',
  TurbulenceSpecificationSpalartAllmaras = 'turbulence_specification_spalart_allmaras',
  BcUniformNuTilde = 'bc_uniform_nu_tilde',
  BcNuTildeCol = 'bc_nu_tilde_col',
  TurbulenceSpecificationKomega = 'turbulence_specification_komega',
  TurbulentViscosityRatio = 'turbulent_viscosity_ratio',
  TurbulentViscosity = 'turbulent_viscosity',
  TurbulenceIntensity = 'turbulence_intensity',
  BcUniformTke = 'bc_uniform_tke',
  BcUniformOmega = 'bc_uniform_omega',
  BcTkeCol = 'bc_tke_col',
  BcOmegaCol = 'bc_omega_col',
  UniformV = 'uniform_v',
  PotentialFlowPressureInitialization = 'potential_flow_pressure_initialization',
  UniformP = 'uniform_p',
  TurbulentVariableInitializationTypeSa = 'turbulent_variable_initialization_type_sa',
  UniformNuTilde = 'uniform_nu_tilde',
  TurbulentVariableInitializationTypeKomega = 'turbulent_variable_initialization_type_komega',
  InitTurbulentViscosityRatio = 'init_turbulent_viscosity_ratio',
  InitTurbulentViscosity = 'init_turbulent_viscosity',
  InitTurbulenceIntensity = 'init_turbulence_intensity',
  UniformTke = 'uniform_tke',
  UniformOmega = 'uniform_omega',
  VerificationSolutions = 'verification_solutions',
  TgvRho = 'tgv_rho',
  TgvP = 'tgv_p',
  TgvV = 'tgv_v',
  ShocktubeP1 = 'shocktube_p1',
  ShocktubeP4 = 'shocktube_p4',
  ShocktubeT1 = 'shocktube_t1',
  ShocktubeT4 = 'shocktube_t4',
  ShuosherP1 = 'shuosher_p1',
  ShuosherRho1 = 'shuosher_rho1',
  ShuosherM1 = 'shuosher_m1',
  ShuosherP2 = 'shuosher_p2',
  ShuosherRho2 = 'shuosher_rho2',
  ShuosherM2 = 'shuosher_m2',
  ShuosherAmp = 'shuosher_amp',
  ShuosherKx = 'shuosher_kx',
  DisturbancewaveP = 'disturbancewave_p',
  DisturbancewaveT = 'disturbancewave_t',
  DisturbancewaveM = 'disturbancewave_m',
  AcousticwaveAmp = 'acousticwave_amp',
  VorticitywaveAmp = 'vorticitywave_amp',
  EntropywaveAmp = 'entropywave_amp',
  DisturbancewaveKx = 'disturbancewave_kx',
  NormalshockP1 = 'normalshock_p1',
  NormalshockT1 = 'normalshock_t1',
  NormalshockM1 = 'normalshock_m1',
  ShockvortexP1 = 'shockvortex_p1',
  ShockvortexT1 = 'shockvortex_t1',
  ShockvortexM1 = 'shockvortex_m1',
  ShockvortexMv = 'shockvortex_mv',
  ShockvortexXv = 'shockvortex_xv',
  ShockvortexZv = 'shockvortex_zv',
  ShockvortexAv = 'shockvortex_av',
  ShockvortexBv = 'shockvortex_bv',
  MmsK = 'mms_k',
  MmsA = 'mms_a',
  InviscidVortexMinfty = 'inviscid_vortex_minfty',
  InviscidVortexPinfty = 'inviscid_vortex_pinfty',
  InviscidVortexTinfty = 'inviscid_vortex_tinfty',
  InviscidVortexXv = 'inviscid_vortex_xv',
  InviscidVortexYv = 'inviscid_vortex_yv',
  InviscidVortexXflowdir = 'inviscid_vortex_xflowdir',
  InviscidVortexYflowdir = 'inviscid_vortex_yflowdir',
  InviscidVortexRvortex = 'inviscid_vortex_rvortex',
  InviscidVortexBetavortex = 'inviscid_vortex_betavortex',
  PhysicalBehaviorId = 'physical_behavior_id',
  PhysicalBehaviorName = 'physical_behavior_name',
  PhysicalBehaviorModel = 'physical_behavior_model',
  ActuatorDiskModel = 'actuator_disk_model',
  ActuatorDiskThrust = 'actuator_disk_thrust',
  ActuatorDiskTorque = 'actuator_disk_torque',
  ActuatorDiskRadialForce = 'actuator_disk_radial_force',
  ActuatorDiskRadialTableData = 'actuator_disk_radial_table_data',
  ActuatorLineModel = 'actuator_line_model',
  ActuatorDiskRotationRate = 'actuator_disk_rotation_rate',
  ActuatorDiskBemStrategy = 'actuator_disk_bem_strategy',
  ActuatorDiskBladeCount = 'actuator_disk_blade_count',
  BladeElementPitchSchedule = 'blade_element_pitch_schedule',
  BladeElementFlapSchedule = 'blade_element_flap_schedule',
  BladeElementLagSchedule = 'blade_element_lag_schedule',
  BladeElementGeometryData = 'blade_element_geometry_data',
  BladeElementReferenceSoundSpeed = 'blade_element_reference_sound_speed',
  ParticleSourceModel = 'particle_source_model',
  SourceParticleMassInjectionRate = 'source_particle_mass_injection_rate',
  SourceParticleAccelerationVector = 'source_particle_acceleration_vector',
  AirfoilId = 'airfoil_id',
  AirfoilName = 'airfoil_name',
  AirfoilRadialStation = 'airfoil_radial_station',
  AirfoilPerformanceData = 'airfoil_performance_data',
  CavitationModel = 'cavitation_model',
  DensityVaporPhaseCavitation = 'density_vapor_phase_cavitation',
  SaturationPressureCavitation = 'saturation_pressure_cavitation',
  N0SauerSchnerr = 'n0_sauer_schnerr',
  NReboud = 'n_reboud',
  PorousBehaviorId = 'porous_behavior_id',
  PorousBehaviorName = 'porous_behavior_name',
  ZoneIds = 'zone_ids',
  PorousModelType = 'porous_model_type',
  DarcyCoeff = 'darcy_coeff',
  ForchheimerCoeff = 'forchheimer_coeff',
  PorousHeatSourcePower = 'porous_heat_source_power',
  PorousHeatSourcePowerPerUnitVolume = 'porous_heat_source_power_per_unit_volume',
  RefFrameId = 'ref_frame_id',
  MpCouplingLinSolCoupling = 'mp_coupling_lin_sol_coupling',
}
export const paramDesc: { [name: string]: ProtoDescriptor.Param } = {
  [ParamName.Schema]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'schema',
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'schema',
    parentGroups: [
      'simulation_param',
    ],
    pascalCaseName: 'Schema',
    quantityType: 0,
    text: 'Schema',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.Url]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'url',
    help: 'The URL of the mesh file. This field is not directly exposed in the UI dialog. Instead, it is is filled by the frontend issuing UploadFile RPC and obtaining the URL in return.',
    isMap: false,
    isRepeated: false,
    name: 'url',
    parentGroups: [
      'input',
    ],
    pascalCaseName: 'Url',
    quantityType: 0,
    text: 'URL',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.Filename]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'filename',
    help: 'The mesh filename, that is, the filename (without \'/\') of the mesh when it was originally updated by the user. The value is used only by the UI to display the mesh name. This field is ignored by the backend or the solver.',
    isMap: false,
    isRepeated: false,
    name: 'filename',
    parentGroups: [
      'input',
    ],
    pascalCaseName: 'Filename',
    quantityType: 0,
    text: 'Filename',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.ReferencePressure]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'referencePressure',
    help: 'Reference pressure for the simulation. Unless otherwise stated, all pressure values are relative to this field (i.e. gauge pressures).',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'reference_pressure',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'ReferencePressure',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'Reference Pressure',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.DensityRelationship]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'densityRelationship',
    choices: [
      {
        enumNumber: 37484,
        help: 'Compute density using the ideal gas law.',
        name: 'IDEAL_GAS',
        text: 'Ideal Gas Law',
      },
      {
        enumNumber: 28817,
        help: 'Constant density fluid (heat transfer is not simulated).',
        name: 'CONSTANT_DENSITY',
        text: 'Constant Density',
      },
      {
        enumNumber: 41777,
        help: 'Constant density fluid with energy equation.',
        name: 'CONSTANT_DENSITY_ENERGY',
        text: 'Constant Density with Energy Equation',
      },
    ],
    defaultValue: 37484,
    help: 'Relationship for computing the density of the fluid.',
    isMap: false,
    isRepeated: false,
    name: 'density_relationship',
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'DensityRelationship',
    quantityType: 0,
    text: 'Density Relationship',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.MolecularWeight]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'molecularWeight',
    cond: {
      choice: 37484,
      param: 'density_relationship',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 28.96,
    help: 'Molecular weight of the gas used to compute its specific gas constant. Air is 28.96 g/mol.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'molecular_weight',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'MolecularWeight',
    quantityType: QuantityPb.QuantityType.MOLECULAR_WEIGHT,
    text: 'Molecular Weight',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ConstantDensityValue]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'constantDensityValue',
    cond: {
      list: [
        {
          choice: 28817,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 41777,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 56617,
          param: 'fluid_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 1.225,
    help: 'Constant density value.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'constant_density_value',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'ConstantDensityValue',
    quantityType: QuantityPb.QuantityType.DENSITY,
    text: 'Density',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SpecificHeatCp]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'specificHeatCp',
    cond: {
      list: [
        {
          choice: 37484,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 41777,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 1004.703,
    help: 'Specific heat at constant pressure. Air is 1004.703 J/kg/K.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'specific_heat_cp',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'SpecificHeatCp',
    quantityType: QuantityPb.QuantityType.SPECIFIC_HEAT,
    text: 'Specific Heat at Constant Pressure',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.LaminarThermalConductivity]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'laminarThermalConductivity',
    choices: [
      {
        enumNumber: 46513,
        help: 'Laminar thermal conductivity as function of local specific heat, laminar viscosity, and the specified laminar Prandtl number.',
        name: 'LAMINAR_CONSTANT_THERMAL_PRANDTL',
        text: 'Prandtl Number',
      },
      {
        enumNumber: 39477,
        help: 'Constant laminar thermal conductivity or tabulated values vs temperature.',
        name: 'LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY',
        text: 'Thermal Conductivity',
      },
      {
        cond: {
          name: 'temp_varying_material',
          type: ProtoDescriptor.CondType.EXPERIMENT,
        },
        enumNumber: 38127,
        help: 'Tabulated thermal conductivity values vs temperature.',
        name: 'TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY',
        text: 'Temperature-Dependent Thermal Conductivity',
      },
    ],
    cond: {
      list: [
        {
          choice: 37484,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 41777,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 46513,
    help: 'Model for the laminar thermal conductivity of a fluid.',
    isMap: false,
    isRepeated: false,
    name: 'laminar_thermal_conductivity',
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'LaminarThermalConductivity',
    quantityType: 0,
    text: 'Laminar Thermal Conductivity Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.LaminarConstantThermalPrandtlConstant]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'laminarConstantThermalPrandtlConstant',
    cond: {
      list: [
        {
          choice: 46513,
          param: 'laminar_thermal_conductivity',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.72,
    help: 'The laminar Prandtl number.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'laminar_constant_thermal_prandtl_constant',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'LaminarConstantThermalPrandtlConstant',
    quantityType: 0,
    text: 'Prandtl Number',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.LaminarConstantThermalConductivityConstant]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'laminarConstantThermalConductivityConstant',
    cond: {
      list: [
        {
          choice: 39477,
          param: 'laminar_thermal_conductivity',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 0.0257,
    help: 'Value or table for laminar thermal conductivity.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'laminar_constant_thermal_conductivity_constant',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'LaminarConstantThermalConductivityConstant',
    quantityType: QuantityPb.QuantityType.THERMAL_CONDUCTIVITY,
    text: 'Thermal Conductivity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ThermalConductivityTableData]: <ProtoDescriptor.RectilinearTableParam>{
    axis: [
      {
        quantity: QuantityPb.QuantityType.TEMPERATURE,
      },
    ],
    camelCaseName: 'thermalConductivityTableData',
    cond: {
      choice: 38127,
      param: 'laminar_thermal_conductivity',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: 'Correlation between thermal conductivity and temperature.',
    isMap: false,
    isRepeated: false,
    name: 'thermal_conductivity_table_data',
    parentGroups: [
      'material_fluid',
      'material_solid',
    ],
    pascalCaseName: 'ThermalConductivityTableData',
    quantityType: 0,
    tableRecords: [
      {
        quantity: QuantityPb.QuantityType.THERMAL_CONDUCTIVITY,
      },
    ],
    text: 'Table for Temperature-Dependent Conductivity',
    type: ProtoDescriptor.ParamType.TABLE,
  },
  [ParamName.BoussinesqApproximation]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'boussinesqApproximation',
    choices: [
      {
        enumNumber: 12354,
        help: 'Disable Boussinesq approximation.',
        name: 'BOUSSINESQ_OFF',
        text: 'Off',
      },
      {
        enumNumber: 26001,
        help: 'Enable Boussinesq approximation.',
        name: 'BOUSSINESQ_ON',
        text: 'On',
      },
    ],
    cond: {
      list: [
        {
          choice: 61724,
          param: 'gravity',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 41777,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 26001,
    help: 'Introduce a body force due to thermal expansion without modifying the material density.',
    isMap: false,
    isRepeated: false,
    name: 'boussinesq_approximation',
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'BoussinesqApproximation',
    quantityType: 0,
    text: 'Boussinesq Approximation',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.BoussinesqTempRef]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'boussinesqTempRef',
    cond: {
      choice: 26001,
      param: 'boussinesq_approximation',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 288.15,
    help: 'Temperature at which there is no effect from the Boussinesq approximation (i.e. no change in density).',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'boussinesq_temp_ref',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'BoussinesqTempRef',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'Reference Temperature',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ThermalExpansionCoefficient]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'thermalExpansionCoefficient',
    cond: {
      choice: 26001,
      param: 'boussinesq_approximation',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 0.0035,
    help: 'Volumetric expansion due to temperature.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'thermal_expansion_coefficient',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'ThermalExpansionCoefficient',
    quantityType: QuantityPb.QuantityType.THERMAL_EXPANSION_COEFFICIENT,
    text: 'Thermal Expansion Coefficient',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.MaterialFluidPreset]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'materialFluidPreset',
    choices: [
      {
        enumNumber: 41114,
        help: 'Fluid material preset is not set.',
        name: 'UNSET_MATERIAL_FLUID_PRESET',
        text: 'Unset',
      },
      {
        enumNumber: 28216,
        help: 'Standard air material properties',
        name: 'STANDARD_AIR',
        text: 'Standard Air',
      },
      {
        enumNumber: 24388,
        help: 'Properties of water at 1 atmosphere and 20° Celsius',
        name: 'WATER_NTP',
        text: 'Water (NTP)',
      },
      {
        enumNumber: 32460,
        help: 'A custom set of material properties.',
        name: 'CUSTOM_MATERIAL_FLUID',
        text: 'Custom',
      },
    ],
    defaultValue: 32460,
    help: 'Select a predefined set of material properties or allow a custom set of properties.',
    isMap: false,
    isRepeated: false,
    name: 'material_fluid_preset',
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'MaterialFluidPreset',
    quantityType: 0,
    text: 'Material Fluid Preset',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.LaminarViscosityModelNewtonian]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'laminarViscosityModelNewtonian',
    choices: [
      {
        cond: {
          choice: 37484,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        enumNumber: 36363,
        help: 'Dynamic viscosity as a function of local temperature using Sutherland\'s Law.',
        name: 'SUTHERLAND',
        text: 'Sutherland\'s Law',
      },
      {
        enumNumber: 9272,
        help: 'Constant dynamic viscosity or tabulated values vs temperature.',
        name: 'LAMINAR_CONSTANT_VISCOSITY',
        text: 'Dynamic Viscosity',
      },
      {
        cond: {
          name: 'temp_varying_material',
          type: ProtoDescriptor.CondType.EXPERIMENT,
        },
        enumNumber: 41732,
        help: 'Tabulated dynamic viscosity values vs temperature.',
        name: 'TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY',
        text: 'Temperature-Dependent Dynamic Viscosity',
      },
    ],
    defaultValue: 36363,
    help: 'Models available for the dynamic viscosity of the fluid.',
    isMap: false,
    isRepeated: false,
    name: 'laminar_viscosity_model_newtonian',
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'LaminarViscosityModelNewtonian',
    quantityType: 0,
    text: 'Dynamic Viscosity Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.SutherlandViscosityRef]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'sutherlandViscosityRef',
    cond: {
      choice: 36363,
      param: 'laminar_viscosity_model_newtonian',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 1.716e-05,
    help: 'Dynamic viscosity at the reference temperature.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'sutherland_viscosity_ref',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'SutherlandViscosityRef',
    quantityType: QuantityPb.QuantityType.VISCOSITY,
    text: 'Reference Dynamic Viscosity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SutherlandViscosityTempRef]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'sutherlandViscosityTempRef',
    cond: {
      choice: 36363,
      param: 'laminar_viscosity_model_newtonian',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 273.15,
    help: 'Reference temperature.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'sutherland_viscosity_temp_ref',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'SutherlandViscosityTempRef',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'Reference Temperature',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SutherlandConstant]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'sutherlandConstant',
    cond: {
      choice: 36363,
      param: 'laminar_viscosity_model_newtonian',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 110.4,
    help: 'Sutherland Constant.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'sutherland_constant',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'SutherlandConstant',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'Sutherland Constant',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.LaminarConstantViscosityConstant]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'laminarConstantViscosityConstant',
    cond: {
      choice: 9272,
      param: 'laminar_viscosity_model_newtonian',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 1.7894e-05,
    help: 'Value or table for dynamic viscosity.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'laminar_constant_viscosity_constant',
    openBound: true,
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'LaminarConstantViscosityConstant',
    quantityType: QuantityPb.QuantityType.VISCOSITY,
    text: 'Dynamic Viscosity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.DynamicViscosityTableData]: <ProtoDescriptor.RectilinearTableParam>{
    axis: [
      {
        quantity: QuantityPb.QuantityType.TEMPERATURE,
      },
    ],
    camelCaseName: 'dynamicViscosityTableData',
    cond: {
      choice: 41732,
      param: 'laminar_viscosity_model_newtonian',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: 'Correlation between dynamic viscosity and temperature.',
    isMap: false,
    isRepeated: false,
    name: 'dynamic_viscosity_table_data',
    parentGroups: [
      'material_fluid',
    ],
    pascalCaseName: 'DynamicViscosityTableData',
    quantityType: 0,
    tableRecords: [
      {
        quantity: QuantityPb.QuantityType.VISCOSITY,
      },
    ],
    text: 'Table for Temperature-Dependent Viscosity',
    type: ProtoDescriptor.ParamType.TABLE,
  },
  [ParamName.ConstantDensityValueSolid]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'constantDensityValueSolid',
    defaultValue: 2700,
    help: 'Constant density value.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'constant_density_value_solid',
    openBound: true,
    parentGroups: [
      'material_solid',
    ],
    pascalCaseName: 'ConstantDensityValueSolid',
    quantityType: QuantityPb.QuantityType.DENSITY,
    text: 'Density',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SpecificHeatCpSolid]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'specificHeatCpSolid',
    defaultValue: 896,
    help: 'Specific heat at constant pressure.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'specific_heat_cp_solid',
    openBound: true,
    parentGroups: [
      'material_solid',
    ],
    pascalCaseName: 'SpecificHeatCpSolid',
    quantityType: QuantityPb.QuantityType.SPECIFIC_HEAT,
    text: 'Specific Heat at Constant Pressure',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ThermalConductivityConstantSolid]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'thermalConductivityConstantSolid',
    defaultValue: 167,
    help: 'The thermal conductivity of the material.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'thermal_conductivity_constant_solid',
    openBound: true,
    parentGroups: [
      'material_solid',
    ],
    pascalCaseName: 'ThermalConductivityConstantSolid',
    quantityType: QuantityPb.QuantityType.THERMAL_CONDUCTIVITY,
    text: 'Thermal Conductivity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.MaterialSolidPreset]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'materialSolidPreset',
    choices: [
      {
        enumNumber: 35124,
        help: 'Properties of pure aluminum.',
        name: 'ALUMINUM',
        text: 'Aluminum',
      },
      {
        enumNumber: 60508,
        help: 'Properties of pure copper.',
        name: 'COPPER',
        text: 'Copper',
      },
      {
        enumNumber: 31466,
        help: 'Properties of pure iron.',
        name: 'IRON',
        text: 'Iron',
      },
      {
        enumNumber: 46291,
        help: 'Properties of pure nickel.',
        name: 'NICKEL',
        text: 'Nickel',
      },
      {
        enumNumber: 46073,
        help: 'Properties of pure titanium.',
        name: 'TITANIUM',
        text: 'Titanium',
      },
      {
        enumNumber: 62886,
        help: 'A custom set of material properties.',
        name: 'CUSTOM_MATERIAL_SOLID',
        text: 'Custom',
      },
    ],
    defaultValue: 62886,
    help: 'Select a predefined set of material properties or allow a custom set of properties.',
    isMap: false,
    isRepeated: false,
    name: 'material_solid_preset',
    parentGroups: [
      'material_solid',
    ],
    pascalCaseName: 'MaterialSolidPreset',
    quantityType: 0,
    text: 'Material Solid Preset',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.FloatType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'floatType',
    choices: [
      {
        enumNumber: 26171,
        help: 'Solve the normal set of governing equations.',
        name: 'DOUBLE',
        text: 'Primal',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 56378,
        help: 'First order, scalar tangent.',
        name: 'ADT1D',
        text: 'adt1d',
      },
      {
        cond: {
          choice: 64640,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        enumNumber: 58692,
        help: 'Solve the discrete adjoint equations to obtain geometric sensitivities with respect to an output of interest.',
        name: 'ADA1D',
        text: 'Adjoint',
      },
    ],
    defaultValue: 26171,
    help: 'Type of equations solved for the physics.',
    isMap: false,
    isRepeated: false,
    name: 'float_type',
    parentGroups: [
      'general',
    ],
    pascalCaseName: 'FloatType',
    quantityType: 0,
    text: 'Simulation Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.Gravity]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'gravity',
    choices: [
      {
        enumNumber: 61845,
        help: 'Disable gravity or other body force.',
        name: 'GRAVITY_OFF',
        text: 'Off',
      },
      {
        enumNumber: 61724,
        help: 'Enable gravity or other body force.',
        name: 'GRAVITY_ON',
        text: 'On',
      },
    ],
    defaultValue: 61845,
    help: 'Apply an acceleration due to gravity or other body force.',
    isMap: false,
    isRepeated: false,
    name: 'gravity',
    parentGroups: [
      'general',
    ],
    pascalCaseName: 'Gravity',
    quantityType: 0,
    text: 'Gravity',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.Acceleration]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'acceleration',
    cond: {
      choice: 61724,
      param: 'gravity',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: -9.81,
    },
    help: '(x,y,z) components of the acceleration due to gravity or other body force.',
    isMap: false,
    isRepeated: false,
    name: 'acceleration',
    parentGroups: [
      'general',
    ],
    pascalCaseName: 'Acceleration',
    quantityType: QuantityPb.QuantityType.ACCELERATION,
    text: 'Acceleration',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.FlowBehavior]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'flowBehavior',
    choices: [
      {
        enumNumber: 64640,
        help: 'Solve for a steady-state solution of the governing equations.',
        name: 'STEADY',
        text: 'Steady',
      },
      {
        enumNumber: 36606,
        help: 'Solve for a time-accurate solution of the governing equations.',
        name: 'TRANSIENT',
        text: 'Transient',
      },
    ],
    defaultValue: 64640,
    help: 'Importance of physical time for the current simulation.',
    isMap: false,
    isRepeated: false,
    name: 'flow_behavior',
    parentGroups: [
      'general',
    ],
    pascalCaseName: 'FlowBehavior',
    quantityType: 0,
    text: 'Time',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.SolverParams]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'solverParams',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'Staff only. Text is interpreted as json serialization of internal solver params (proto.solver_params.MultiPhysicsParam) and values are merged with the translated client params in the solver. This means only parameters that are set here will be overwritten. For repeated fields items are merged when they already exist otherwise they are appended.',
    isMap: false,
    isRepeated: false,
    name: 'solver_params',
    parentGroups: [
      'general',
    ],
    pascalCaseName: 'SolverParams',
    quantityType: 0,
    text: 'Solver Params',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.Surfaces]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'surfaces',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: true,
    name: 'surfaces',
    parentGroups: [
      'adjoint',
      'boundary_layer_profile',
      'boundary_conditions_heat',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'Surfaces',
    quantityType: 0,
    text: 'Surfaces',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.DeformedCoordsId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'deformedCoordsId',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'Upload ID of the file containing deformed coordinates for design surfaces. Setting this to \'template\' instructs the solver to output a file with the current surface coordinates.',
    isMap: false,
    isRepeated: false,
    name: 'deformed_coords_id',
    parentGroups: [
      'adjoint',
    ],
    pascalCaseName: 'DeformedCoordsId',
    quantityType: 0,
    text: 'Deformed Coordinates ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.DeformedCoordsUrl]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'deformedCoordsUrl',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'File with deformed coordinates for design surfaces.',
    isMap: false,
    isRepeated: false,
    name: 'deformed_coords_url',
    parentGroups: [
      'adjoint',
    ],
    pascalCaseName: 'DeformedCoordsUrl',
    quantityType: 0,
    text: 'Deformed Coordinates',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.PrimalSimulationId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'primalSimulationId',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'ID of the primal simulation to differentiate.',
    isMap: false,
    isRepeated: false,
    name: 'primal_simulation_id',
    parentGroups: [
      'adjoint',
    ],
    pascalCaseName: 'PrimalSimulationId',
    quantityType: 0,
    text: 'Primal Simulation ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.TimeMarching]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'timeMarching',
    choices: [
      {
        enumNumber: 33327,
        help: 'Implicit scheme (dual time stepping) for time-accurate integration.',
        name: 'TIME_IMPLICIT',
        text: 'Time Implicit',
      },
      {
        enumNumber: 24337,
        help: 'Explicit scheme for time-accurate integration.',
        name: 'TIME_EXPLICIT',
        text: 'Time Explicit',
      },
    ],
    cond: {
      choice: 36606,
      param: 'flow_behavior',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 33327,
    help: 'Scheme for time-accurate integration.',
    isMap: false,
    isRepeated: false,
    name: 'time_marching',
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'TimeMarching',
    quantityType: 0,
    text: 'Time Marching',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.TimeImplicitOrder]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'timeImplicitOrder',
    choices: [
      {
        enumNumber: 50695,
        help: 'Second-order backward Euler integration.',
        name: 'TIME_SECOND',
        text: 'Second-Order',
      },
      {
        enumNumber: 39911,
        help: 'First-order backward Euler integration.',
        name: 'TIME_FIRST',
        text: 'First-Order',
      },
    ],
    cond: {
      choice: 33327,
      param: 'time_marching',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 50695,
    help: 'Temporal order of accuracy of the dual time stepping scheme for time-accurate integration.',
    isMap: false,
    isRepeated: false,
    name: 'time_implicit_order',
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'TimeImplicitOrder',
    quantityType: 0,
    text: 'Temporal Accuracy',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.PhysicalTimeStepMethod]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'physicalTimeStepMethod',
    choices: [
      {
        enumNumber: 31808,
        help: 'Apply a fixed physical time step.',
        name: 'FIXED_TIME_STEP',
        text: 'Fixed Time Step',
      },
    ],
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
        {
          choice: 36606,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 31808,
    help: 'Method for obtaining the physical time step in a time-accurate simulation.',
    isMap: false,
    isRepeated: false,
    name: 'physical_time_step_method',
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'PhysicalTimeStepMethod',
    quantityType: 0,
    text: 'Physical Time Step Method',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.TimeStepVal]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'timeStepVal',
    cond: {
      choice: 36606,
      param: 'flow_behavior',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 0.0001,
    help: 'The fixed physical time step.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'time_step_val',
    openBound: true,
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'TimeStepVal',
    quantityType: QuantityPb.QuantityType.TIME,
    text: 'Physical Time Step',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TimeStepRamp]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'timeStepRamp',
    choices: [
      {
        enumNumber: 31507,
        help: 'Disable physical time step ramping.',
        name: 'TIME_STEP_RAMP_OFF',
        text: 'Off',
      },
      {
        enumNumber: 38748,
        help: 'Enable physical time step ramping.',
        name: 'TIME_STEP_RAMP_ON',
        text: 'On',
      },
    ],
    cond: {
      list: [
        {
          choice: 36606,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33327,
          param: 'time_marching',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 31507,
    help: 'Use a larger time step value during the initial transients of a simulation and then ramp linearly towards the target value, to accelerate statistical convergence. Only applicable to transient problems with time implicit integration (dual time stepping).',
    isMap: false,
    isRepeated: false,
    name: 'time_step_ramp',
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'TimeStepRamp',
    quantityType: 0,
    text: 'Time Step Ramp',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.TimeStepRampInitialVal]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'timeStepRampInitialVal',
    cond: {
      choice: 38748,
      param: 'time_step_ramp',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 0.001,
    help: 'Initial physical time step for time step ramping. The physical time step is ramped toward the target for the duration of the ramp.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'time_step_ramp_initial_val',
    openBound: false,
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'TimeStepRampInitialVal',
    quantityType: QuantityPb.QuantityType.TIME,
    text: 'Initial Time Step Value',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TimeStepRampIterationBegin]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'timeStepRampIterationBegin',
    cond: {
      choice: 38748,
      param: 'time_step_ramp',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 500,
    help: 'Time step iteration to start the time step ramping process.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'time_step_ramp_iteration_begin',
    openBound: false,
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'TimeStepRampIterationBegin',
    quantityType: 0,
    text: 'Starting Time Iteration',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.TimeStepRampIterationEnd]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'timeStepRampIterationEnd',
    cond: {
      choice: 38748,
      param: 'time_step_ramp',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 1000,
    help: 'Time step iteration to end the time step ramping process.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'time_step_ramp_iteration_end',
    openBound: false,
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'TimeStepRampIterationEnd',
    quantityType: 0,
    text: 'Ending Time Iteration',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.ComputeStatistics]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'computeStatistics',
    choices: [
      {
        enumNumber: 40562,
        help: 'Disable computation of transient statistics.',
        name: 'COMPUTE_STATISTICS_OFF',
        text: 'Off',
      },
      {
        enumNumber: 1075,
        help: 'Enable computation of transient statistics.',
        name: 'COMPUTE_STATISTICS_ON',
        text: 'On',
      },
    ],
    cond: {
      choice: 36606,
      param: 'flow_behavior',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 40562,
    help: 'Compute time-averaged values of flow variables (e.g. Velocity).',
    isMap: false,
    isRepeated: false,
    name: 'compute_statistics',
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'ComputeStatistics',
    quantityType: 0,
    text: 'Compute Statistics',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.StatisticsStartIteration]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'statisticsStartIteration',
    cond: {
      list: [
        {
          choice: 1075,
          param: 'compute_statistics',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 36606,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1,
    help: 'Time step iteration at which to start computing the statistics.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 1,
    name: 'statistics_start_iteration',
    openBound: false,
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'StatisticsStartIteration',
    quantityType: 0,
    text: 'Starting Time Iteration',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.StatisticsUpdateInterval]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'statisticsUpdateInterval',
    cond: {
      list: [
        {
          choice: 1075,
          param: 'compute_statistics',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 36606,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1,
    help: 'Number of time steps in between updates of the statistics.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 1,
    name: 'statistics_update_interval',
    openBound: false,
    parentGroups: [
      'time',
    ],
    pascalCaseName: 'StatisticsUpdateInterval',
    quantityType: 0,
    text: 'Update Interval',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.FrameId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'frameId',
    help: 'ID of the Coordinate Frame.',
    isMap: false,
    isRepeated: false,
    name: 'frame_id',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'FrameId',
    quantityType: 0,
    text: 'Frame ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.FrameName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'frameName',
    help: 'Name of the Coordinate Frame.',
    isMap: false,
    isRepeated: false,
    name: 'frame_name',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'FrameName',
    quantityType: 0,
    text: 'Frame Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.FrameParent]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'frameParent',
    help: 'ID of the parent frame.',
    isMap: false,
    isRepeated: false,
    name: 'frame_parent',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'FrameParent',
    quantityType: 0,
    text: 'Frame Parent',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.AttachedDomains]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'attachedDomains',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'Domains that are attached to this frame.',
    isMap: false,
    isRepeated: true,
    name: 'attached_domains',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'AttachedDomains',
    quantityType: 0,
    text: 'Attached Domains',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.AttachedBoundaries]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'attachedBoundaries',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'Surfaces that are attached to this frame.',
    isMap: false,
    isRepeated: true,
    name: 'attached_boundaries',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'AttachedBoundaries',
    quantityType: 0,
    text: 'Attached Boundaries',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.MotionType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'motionType',
    choices: [
      {
        enumNumber: 21556,
        help: 'No relative motion.',
        name: 'NO_MOTION',
        text: 'No Motion',
      },
      {
        enumNumber: 26751,
        help: 'Motion is defined by specifying an initial translation and constant translational velocity.',
        name: 'CONSTANT_TRANSLATION_MOTION',
        text: 'Constant Translation',
      },
      {
        enumNumber: 47882,
        help: 'Motion is defined by specifying an initial rotation and constant angular velocity.',
        name: 'CONSTANT_ANGULAR_MOTION',
        text: 'Constant Rotation',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 46055,
        help: 'Motion is defined by specifying constant translational and angular velocities.',
        name: 'CONSTANT_VELOCITY_MOTION',
        text: 'Constant',
      },
    ],
    defaultValue: 21556,
    help: 'Type of the Motion.',
    isMap: false,
    isRepeated: false,
    name: 'motion_type',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'MotionType',
    quantityType: 0,
    text: 'Motion Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.MotionTranslationVelocity]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'motionTranslationVelocity',
    cond: {
      choice: 26751,
      param: 'motion_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Constant translation velocity of this frame',
    isMap: false,
    isRepeated: false,
    name: 'motion_translation_velocity',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'MotionTranslationVelocity',
    quantityType: QuantityPb.QuantityType.VELOCITY,
    text: 'Motion Translation Velocity',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.MotionTranslation]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'motionTranslation',
    cond: {
      choice: 26751,
      param: 'motion_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Constant translation of this frame',
    isMap: false,
    isRepeated: false,
    name: 'motion_translation',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'MotionTranslation',
    quantityType: 0,
    text: 'Motion Translation',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.MotionFormulation]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'motionFormulation',
    choices: [
      {
        enumNumber: 46481,
        help: 'The mesh position is updated each time step.',
        name: 'AUTOMATIC_MOTION_FORMULATION',
        text: 'Moving Mesh',
      },
      {
        enumNumber: 44900,
        help: 'The equations are solved in a moving reference frame without moving the mesh. This choice affects the child frames of this frame.',
        name: 'MRF_MOTION_FORMULATION',
        text: 'Moving Frame',
      },
    ],
    cond: {
      list: [
        {
          choice: 36606,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          cond: {
            choice: 21556,
            param: 'motion_type',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 46481,
    help: 'Formulation used to model motion of volumes in transient simulations.',
    isMap: false,
    isRepeated: false,
    name: 'motion_formulation',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'MotionFormulation',
    quantityType: 0,
    text: 'Formulation',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.MotionSpecification]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'motionSpecification',
    choices: [
      {
        enumNumber: 23057,
        help: 'The mesh is repositioned at simulation start time using the initial displacement or rotation',
        name: 'MOTION_SPECIFICATION_REPOSITION',
        text: 'Reposition mesh',
      },
      {
        enumNumber: 55326,
        help: 'Specify both initial translation or rotation and angular or translational velocities',
        name: 'MOTION_SPECIFICATION_NORMAL',
        text: 'Moving Frame or Mesh',
      },
    ],
    defaultValue: 55326,
    help: 'Choice between repositioning volumes at simulation start, or specifying motion velocities',
    isMap: false,
    isRepeated: false,
    name: 'motion_specification',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'MotionSpecification',
    quantityType: 0,
    text: 'Specification',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.MotionAngularVelocity]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'motionAngularVelocity',
    cond: {
      choice: 47882,
      param: 'motion_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Constant angular velocity of this frame',
    isMap: false,
    isRepeated: false,
    name: 'motion_angular_velocity',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'MotionAngularVelocity',
    quantityType: QuantityPb.QuantityType.ANGULAR_VELOCITY,
    text: 'Motion Angular Velocity',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.MotionRotationAngles]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'motionRotationAngles',
    cond: {
      choice: 47882,
      param: 'motion_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Constant rotation of this frame',
    isMap: false,
    isRepeated: false,
    name: 'motion_rotation_angles',
    parentGroups: [
      'motion_data',
    ],
    pascalCaseName: 'MotionRotationAngles',
    quantityType: 0,
    text: 'Motion Rotation Angles',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.TransformName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'transformName',
    help: 'Name of the transformation.',
    isMap: false,
    isRepeated: false,
    name: 'transform_name',
    parentGroups: [
      'frame_transforms',
    ],
    pascalCaseName: 'TransformName',
    quantityType: 0,
    text: 'Transform Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.TransformType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'transformType',
    choices: [
      {
        enumNumber: 46032,
        help: 'No Transform',
        name: 'NO_TRANSFORM',
        text: 'No transform',
      },
      {
        enumNumber: 56422,
        help: 'Rotational Transformation',
        name: 'ROTATIONAL_TRANSFORM',
        text: 'Rotational',
      },
      {
        enumNumber: 42619,
        help: 'Translational Transformation',
        name: 'TRANSLATIONAL_TRANSFORM',
        text: 'Translation',
      },
    ],
    defaultValue: 46032,
    help: 'Type of the Transformation.',
    isMap: false,
    isRepeated: false,
    name: 'transform_type',
    parentGroups: [
      'frame_transforms',
    ],
    pascalCaseName: 'TransformType',
    quantityType: 0,
    text: 'Transform Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.TransformRotationAngles]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'transformRotationAngles',
    cond: {
      choice: 56422,
      param: 'transform_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'transform_rotation_angles',
    parentGroups: [
      'frame_transforms',
    ],
    pascalCaseName: 'TransformRotationAngles',
    quantityType: QuantityPb.QuantityType.DEGREE,
    text: 'Transform Rotation Angles',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.TransformTranslation]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'transformTranslation',
    cond: {
      choice: 42619,
      param: 'transform_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'transform_translation',
    parentGroups: [
      'frame_transforms',
    ],
    pascalCaseName: 'TransformTranslation',
    quantityType: 0,
    text: 'Transform Translation',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.ParticleGroupId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'particleGroupId',
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'particle_group_id',
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ParticleGroupId',
    quantityType: 0,
    text: 'Particle Group ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.ParticleGroupName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'particleGroupName',
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'particle_group_name',
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ParticleGroupName',
    quantityType: 0,
    text: 'Particle Group Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.ParticleGroupBehaviorModelRef]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'particleGroupBehaviorModelRef',
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'particle_group_behavior_model_ref',
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ParticleGroupBehaviorModelRef',
    quantityType: 0,
    text: 'Particle Group Behavior Model Reference',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.ParticleGroupType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'particleGroupType',
    choices: [
      {
        enumNumber: 28633,
        help: 'Applies a uniform force at all locations on the disk',
        name: 'ACTUATOR_DISK',
        text: 'Disk',
      },
      {
        enumNumber: 56370,
        help: 'Applies thrust, azimuthal, and radial forces via a table of user-specified coefficients',
        name: 'ACTUATOR_LINE',
        text: 'Line',
      },
      {
        enumNumber: 35112,
        help: 'Injects material into the solver at particle locations',
        name: 'SOURCE_POINTS',
        text: 'Generic Source Points',
      },
      {
        enumNumber: 8029,
        help: 'Reports solver variables at probe locations.',
        name: 'PROBE_POINTS',
        text: 'Monitor Point',
      },
    ],
    defaultValue: 28633,
    help: 'Defines the behavior of the particles.',
    isMap: false,
    isRepeated: false,
    name: 'particle_group_type',
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ParticleGroupType',
    quantityType: 0,
    text: 'Particle Group Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ActuatorDiskInnerRadius]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'actuatorDiskInnerRadius',
    cond: {
      list: [
        {
          choice: 28633,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'The inner radius of the actuator disk',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'actuator_disk_inner_radius',
    openBound: false,
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ActuatorDiskInnerRadius',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Inner Radius',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ActuatorDiskOuterRadius]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'actuatorDiskOuterRadius',
    cond: {
      list: [
        {
          choice: 28633,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'The outer radius of the actuator disk',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'actuator_disk_outer_radius',
    openBound: true,
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ActuatorDiskOuterRadius',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Outer Radius',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ActuatorDiskCenter]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'actuatorDiskCenter',
    cond: {
      list: [
        {
          choice: 28633,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Hub location (x,y,z) for rotor/propeller',
    isMap: false,
    isRepeated: false,
    name: 'actuator_disk_center',
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ActuatorDiskCenter',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Center',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.ActuatorDiskOrientationSelection]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'actuatorDiskOrientationSelection',
    choices: [
      {
        enumNumber: 51954,
        help: 'Specify rotation about x-, y-, and z- axes',
        name: 'ACTUATOR_DISK_SPECIFY_ROTATION_ANGLES',
        text: 'Angles',
      },
      {
        enumNumber: 7230,
        help: 'Specify the normal direction to the plane of the actuator disk.',
        name: 'ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR',
        text: 'Vector',
      },
    ],
    cond: {
      list: [
        {
          choice: 28633,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 51954,
    help: 'Specify orientation via normal vector, or a series of x-, y-, z-rotational transformations',
    isMap: false,
    isRepeated: false,
    name: 'actuator_disk_orientation_selection',
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ActuatorDiskOrientationSelection',
    quantityType: 0,
    text: 'Actuator Disk Orientation',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ActuatorDiskRotationAngle]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'actuatorDiskRotationAngle',
    cond: {
      list: [
        {
          choice: 28633,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 51954,
          param: 'actuator_disk_orientation_selection',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'X-, Y-, Z-rotation angles for actuator disk',
    isMap: false,
    isRepeated: false,
    name: 'actuator_disk_rotation_angle',
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ActuatorDiskRotationAngle',
    quantityType: QuantityPb.QuantityType.DEGREE,
    text: 'Rotation Angles',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.ActuatorDiskNormalVector]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'actuatorDiskNormalVector',
    cond: {
      list: [
        {
          choice: 28633,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 7230,
          param: 'actuator_disk_orientation_selection',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 1.0,
    },
    help: 'Vector normal to the actuator disk (thrust direction defined as negative-Z)',
    isMap: false,
    isRepeated: false,
    name: 'actuator_disk_normal_vector',
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ActuatorDiskNormalVector',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Normal Vector',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.SourceParticleRadius]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'sourceParticleRadius',
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
        {
          choice: 35112,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.25,
    help: 'Sets the physical size of the source',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'source_particle_radius',
    openBound: true,
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'SourceParticleRadius',
    quantityType: 0,
    text: 'Source particle radius',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SearchRadiusScaleFactor]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'searchRadiusScaleFactor',
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
        {
          choice: 28633,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 2.5,
    help: 'Increasing this parameter widens the projection search radius',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'search_radius_scale_factor',
    openBound: true,
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'SearchRadiusScaleFactor',
    quantityType: 0,
    text: 'Search Radius Scale Factor',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.IsotropicGaussianScaleFactor]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'isotropicGaussianScaleFactor',
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
        {
          choice: 28633,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Increasing this parameter widens the projection kernel',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'isotropic_gaussian_scale_factor',
    openBound: true,
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'IsotropicGaussianScaleFactor',
    quantityType: 0,
    text: 'Isotropic Gaussian Scale Factor',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ParticlePositionsTable]: <ProtoDescriptor.RectilinearTableParam>{
    axis: [],
    camelCaseName: 'particlePositionsTable',
    cond: {
      list: [
        {
          choice: 8029,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 35112,
          param: 'particle_group_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Particle positions',
    isMap: false,
    isRepeated: false,
    name: 'particle_positions_table',
    parentGroups: [
      'particle_group',
    ],
    pascalCaseName: 'ParticlePositionsTable',
    quantityType: 0,
    tableRecords: [
      {
        quantity: QuantityPb.QuantityType.LENGTH,
      },
      {
        quantity: QuantityPb.QuantityType.LENGTH,
      },
      {
        quantity: QuantityPb.QuantityType.LENGTH,
      },
      {
        name: 'name',
      },
      {
        name: 'id',
      },
    ],
    text: 'Particle Positions',
    type: ProtoDescriptor.ParamType.TABLE,
  },
  [ParamName.MonitorPlaneId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'monitorPlaneId',
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'monitor_plane_id',
    parentGroups: [
      'monitor_plane',
    ],
    pascalCaseName: 'MonitorPlaneId',
    quantityType: 0,
    text: 'Monitor Plane ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.MonitorPlaneName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'monitorPlaneName',
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'monitor_plane_name',
    parentGroups: [
      'monitor_plane',
    ],
    pascalCaseName: 'MonitorPlaneName',
    quantityType: 0,
    text: 'Monitor Plane Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.MonitorPlanePoint]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'monitorPlanePoint',
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'A point on the plane',
    isMap: false,
    isRepeated: false,
    name: 'monitor_plane_point',
    parentGroups: [
      'monitor_plane',
    ],
    pascalCaseName: 'MonitorPlanePoint',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Point',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.MonitorPlaneNormal]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'monitorPlaneNormal',
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 1.0,
    },
    help: 'A vector normal to the plane',
    isMap: false,
    isRepeated: false,
    name: 'monitor_plane_normal',
    parentGroups: [
      'monitor_plane',
    ],
    pascalCaseName: 'MonitorPlaneNormal',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Normal',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.MonitorPlaneBoxClip]: <ProtoDescriptor.BoolParam>{
    camelCaseName: 'monitorPlaneBoxClip',
    help: 'Turn on or off the ability to clip a monitor plane using a box.',
    isMap: false,
    isRepeated: false,
    name: 'monitor_plane_box_clip',
    parentGroups: [
      'monitor_plane',
    ],
    pascalCaseName: 'MonitorPlaneBoxClip',
    quantityType: 0,
    text: 'Box-Clipped Monitor Plane',
    type: ProtoDescriptor.ParamType.BOOL,
  },
  [ParamName.MonitorPlaneClipCenter]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'monitorPlaneClipCenter',
    cond: {
      param: 'monitor_plane_box_clip',
      type: ProtoDescriptor.CondType.TRUEFALSE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Center of the box used to clip the monitor plane.',
    isMap: false,
    isRepeated: false,
    name: 'monitor_plane_clip_center',
    parentGroups: [
      'monitor_plane',
    ],
    pascalCaseName: 'MonitorPlaneClipCenter',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Center',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.MonitorPlaneClipSize]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'monitorPlaneClipSize',
    cond: {
      param: 'monitor_plane_box_clip',
      type: ProtoDescriptor.CondType.TRUEFALSE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Side lengths of the box used to clip the monitor plane.',
    isMap: false,
    isRepeated: false,
    name: 'monitor_plane_clip_size',
    parentGroups: [
      'monitor_plane',
    ],
    pascalCaseName: 'MonitorPlaneClipSize',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Extents',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.MonitorPlaneClipRotation]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'monitorPlaneClipRotation',
    cond: {
      param: 'monitor_plane_box_clip',
      type: ProtoDescriptor.CondType.TRUEFALSE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Rotation vector of Euler angles (XYZ order) that transforms the box used to clip the monitor plane.',
    isMap: false,
    isRepeated: false,
    name: 'monitor_plane_clip_rotation',
    parentGroups: [
      'monitor_plane',
    ],
    pascalCaseName: 'MonitorPlaneClipRotation',
    quantityType: QuantityPb.QuantityType.DEGREE,
    text: 'Orientation',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.MonitorPlaneVolumeClip]: <ProtoDescriptor.BoolParam>{
    camelCaseName: 'monitorPlaneVolumeClip',
    help: 'Turn on or off the ability to constrain a monitor plane to specific volumes of the geometry.',
    isMap: false,
    isRepeated: false,
    name: 'monitor_plane_volume_clip',
    parentGroups: [
      'monitor_plane',
    ],
    pascalCaseName: 'MonitorPlaneVolumeClip',
    quantityType: 0,
    text: 'Volume-Clipped Monitor Plane',
    type: ProtoDescriptor.ParamType.BOOL,
  },
  [ParamName.BodyFrameId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'bodyFrameId',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'ID of the MotionData frame that defined the body orientation.',
    isMap: false,
    isRepeated: false,
    name: 'body_frame_id',
    parentGroups: [
      'body_frame',
    ],
    pascalCaseName: 'BodyFrameId',
    quantityType: 0,
    text: 'Body Frame ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.SurfaceName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'surfaceName',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'surface_name',
    parentGroups: [
      'surface_name',
    ],
    pascalCaseName: 'SurfaceName',
    quantityType: 0,
    text: 'Surface Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.ItersPerOutput]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'itersPerOutput',
    defaultValue: 1000,
    help: 'Number of (pseudo) timesteps between successive full solution output. If &le;0, only the final solution is written.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'iters_per_output',
    openBound: false,
    parentGroups: [
      'output',
    ],
    pascalCaseName: 'ItersPerOutput',
    quantityType: 0,
    text: 'Iterations per output',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.DebugOutput]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'debugOutput',
    choices: [
      {
        enumNumber: 40287,
        help: 'Disable debug output.',
        name: 'SOLN_DEBUG_OUTPUT_OFF',
        text: 'Off',
      },
      {
        enumNumber: 5251,
        help: 'Enable debug output.',
        name: 'SOLN_DEBUG_OUTPUT_ON',
        text: 'On',
      },
    ],
    cond: {
      name: 'debug_soln_files',
      type: ProtoDescriptor.CondType.EXPERIMENT,
    },
    defaultValue: 40287,
    help: 'Output debug fields in solution files.',
    isMap: false,
    isRepeated: false,
    name: 'debug_output',
    parentGroups: [
      'output',
    ],
    pascalCaseName: 'DebugOutput',
    quantityType: 0,
    text: 'Debug output',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.DebugOutputInteriorSurfaceData]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'debugOutputInteriorSurfaceData',
    choices: [
      {
        enumNumber: 38426,
        help: 'Disable debug output.',
        name: 'SOLN_DEBUG_OUTPUT_INT_SURF_DATA_OFF',
        text: 'Off',
      },
      {
        enumNumber: 21622,
        help: 'Enable debug output.',
        name: 'SOLN_DEBUG_OUTPUT_INT_SURF_DATA_ON',
        text: 'On',
      },
    ],
    cond: {
      choice: 5251,
      param: 'debug_output',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 38426,
    help: 'Copy the interior volume data into the surface data.',
    isMap: false,
    isRepeated: false,
    name: 'debug_output_interior_surface_data',
    parentGroups: [
      'output',
    ],
    pascalCaseName: 'DebugOutputInteriorSurfaceData',
    quantityType: 0,
    text: 'Debug output interior surface data',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.AreaRef]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'areaRef',
    defaultValue: 1.0,
    help: 'Reference area for computing force and moment coefficients.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'area_ref',
    openBound: true,
    parentGroups: [
      'reference_values',
    ],
    pascalCaseName: 'AreaRef',
    quantityType: QuantityPb.QuantityType.AREA,
    text: 'Area',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.LengthRef]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'lengthRef',
    defaultValue: 1.0,
    help: 'Generic reference length for computing moment coefficients.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'length_ref',
    openBound: true,
    parentGroups: [
      'reference_values',
    ],
    pascalCaseName: 'LengthRef',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Length',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.UseAeroMomentRefLengths]: <ProtoDescriptor.BoolParam>{
    camelCaseName: 'useAeroMomentRefLengths',
    help: 'Separate reference lengths for pitching, rolling and yawing moments.',
    isMap: false,
    isRepeated: false,
    name: 'use_aero_moment_ref_lengths',
    parentGroups: [
      'reference_values',
    ],
    pascalCaseName: 'UseAeroMomentRefLengths',
    quantityType: 0,
    text: 'Aerodynamic Moment Reference Lengths',
    type: ProtoDescriptor.ParamType.BOOL,
  },
  [ParamName.LengthRefPitch]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'lengthRefPitch',
    cond: {
      list: [
        {
          param: 'use_aero_moment_ref_lengths',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Reference length for computing pitching moment coefficients.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'length_ref_pitch',
    openBound: true,
    parentGroups: [
      'reference_values',
    ],
    pascalCaseName: 'LengthRefPitch',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Pitch',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.LengthRefRoll]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'lengthRefRoll',
    cond: {
      list: [
        {
          param: 'use_aero_moment_ref_lengths',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Reference length for computing rolling moment coefficients.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'length_ref_roll',
    openBound: true,
    parentGroups: [
      'reference_values',
    ],
    pascalCaseName: 'LengthRefRoll',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Roll',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.LengthRefYaw]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'lengthRefYaw',
    cond: {
      list: [
        {
          param: 'use_aero_moment_ref_lengths',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Reference length for computing yawing moment coefficients.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'length_ref_yaw',
    openBound: true,
    parentGroups: [
      'reference_values',
    ],
    pascalCaseName: 'LengthRefYaw',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Yaw',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.PRef]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'pRef',
    defaultValue: 101325.0,
    help: 'Absolute static reference pressure, exclusively for computing force and moment coefficients. This value is independent of the material reference pressure and it does not interact with physics inputs such as boundary conditions.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'p_ref',
    openBound: true,
    parentGroups: [
      'reference_values',
    ],
    pascalCaseName: 'PRef',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'Absolute Static Pressure',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TRef]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'tRef',
    cond: {
      list: [
        {
          list: [
            {
              choice: 37484,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 288.15,
    help: 'Reference temperature for computing force and moment coefficients.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 't_ref',
    openBound: true,
    parentGroups: [
      'reference_values',
    ],
    pascalCaseName: 'TRef',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'Temperature',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.VRef]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'vRef',
    defaultValue: 1.0,
    help: 'Reference velocity magnitude for computing force and moment coefficients.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'v_ref',
    openBound: true,
    parentGroups: [
      'reference_values',
    ],
    pascalCaseName: 'VRef',
    quantityType: QuantityPb.QuantityType.VELOCITY,
    text: 'Velocity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.RefinementIterations]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'refinementIterations',
    help: 'Number of refinement iterations to perform.',
    isMap: false,
    isRepeated: false,
    maxVal: 99,
    minVal: 0,
    name: 'refinement_iterations',
    openBound: false,
    parentGroups: [
      'adaptive_mesh_refinement',
    ],
    pascalCaseName: 'RefinementIterations',
    quantityType: 0,
    text: 'Refinement Iterations',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.RefinementDispatchInterval]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'refinementDispatchInterval',
    help: 'Number of solver iterations to perform before dispatching refinement.',
    isMap: false,
    isRepeated: false,
    maxVal: 100000.0,
    minVal: 0,
    name: 'refinement_dispatch_interval',
    openBound: false,
    parentGroups: [
      'adaptive_mesh_refinement',
    ],
    pascalCaseName: 'RefinementDispatchInterval',
    quantityType: 0,
    text: 'Refinement Dispatch Interval',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.MaxRefinementInterval]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'maxRefinementInterval',
    help: 'Maximum number of solver iterations to perform before waiting for refinement to complete.',
    isMap: false,
    isRepeated: false,
    maxVal: 100000.0,
    minVal: 0,
    name: 'max_refinement_interval',
    openBound: false,
    parentGroups: [
      'adaptive_mesh_refinement',
    ],
    pascalCaseName: 'MaxRefinementInterval',
    quantityType: 0,
    text: 'Max Refinement Interval',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.InitialTargetComplexity]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'initialTargetComplexity',
    help: 'Target Complexity on the first refinement iteration.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'initial_target_complexity',
    openBound: false,
    parentGroups: [
      'adaptive_mesh_refinement',
    ],
    pascalCaseName: 'InitialTargetComplexity',
    quantityType: 0,
    text: 'Initial Target Complexity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.FinalTargetComplexity]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'finalTargetComplexity',
    help: 'Target Complexity on the final refinement iteration.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'final_target_complexity',
    openBound: false,
    parentGroups: [
      'adaptive_mesh_refinement',
    ],
    pascalCaseName: 'FinalTargetComplexity',
    quantityType: 0,
    text: 'Final Target Complexity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.MeshingMethod]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'meshingMethod',
    choices: [
      {
        enumNumber: 34850,
        help: 'Using user inputs to guide single, manual mesh generation.',
        name: 'MESH_METHOD_MANUAL',
        text: 'User-prescribed mesh',
      },
      {
        enumNumber: 1659,
        help: 'Using solution-based adaptive mesh refinement.',
        name: 'MESH_METHOD_AUTO',
        text: 'Solution-based adaptive mesh refinement',
      },
    ],
    defaultValue: 34850,
    help: 'The method to generate the computational mesh.',
    isMap: false,
    isRepeated: false,
    name: 'meshing_method',
    parentGroups: [
      'adaptive_mesh_refinement',
    ],
    pascalCaseName: 'MeshingMethod',
    quantityType: 0,
    text: 'Meshing Method',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.TargetCvMillions]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'targetCvMillions',
    cond: {
      choice: 1659,
      param: 'meshing_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 10,
    help: 'User-requested mesh size in millions of control volumes.',
    isMap: false,
    isRepeated: false,
    maxVal: 200,
    minVal: 0,
    name: 'target_cv_millions',
    openBound: true,
    parentGroups: [
      'adaptive_mesh_refinement',
    ],
    pascalCaseName: 'TargetCvMillions',
    quantityType: 0,
    text: 'Target CV Millions',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.AllTet]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'allTet',
    choices: [
      {
        enumNumber: 4214,
        help: '',
        name: 'ALL_TET_UNSET',
        text: 'Unset',
      },
      {
        enumNumber: 24636,
        help: '',
        name: 'ALL_TET_OFF',
        text: 'Off',
      },
      {
        enumNumber: 48394,
        help: '',
        name: 'ALL_TET_ON',
        text: 'On',
      },
    ],
    cond: {
      list: [
        {
          choice: 1659,
          param: 'meshing_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          name: 'all_tet',
          type: ProtoDescriptor.CondType.EXPERIMENT,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 4214,
    help: 'Automatically inserts high aspect ratio mesh elements in the boundary layer and ignores all adaptation boundary layer settings',
    isMap: false,
    isRepeated: false,
    name: 'all_tet',
    parentGroups: [
      'adaptive_mesh_refinement',
    ],
    pascalCaseName: 'AllTet',
    quantityType: 0,
    text: 'Automatic Boundary Layer',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.UserScaling]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'userScaling',
    cond: {
      choice: 1659,
      param: 'meshing_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 1.0,
    help: 'Scale factor between the geometry and the mesh.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0.0,
    name: 'user_scaling',
    openBound: true,
    parentGroups: [
      'adaptive_mesh_refinement',
    ],
    pascalCaseName: 'UserScaling',
    quantityType: 0,
    text: 'User geometry scaling',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.NLayers]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'nLayers',
    cond: {
      choice: 1659,
      param: 'meshing_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 40,
    help: 'Maximum number of prism layers within a boundary layer mesh.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'n_layers',
    openBound: false,
    parentGroups: [
      'boundary_layer_profile',
    ],
    pascalCaseName: 'NLayers',
    quantityType: 0,
    text: 'Number of Layers',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.InitialSize]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'initialSize',
    cond: {
      choice: 1659,
      param: 'meshing_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 1e-06,
    help: 'Size of the mesh layer nearest the boundary surface in meters.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0.0,
    name: 'initial_size',
    openBound: true,
    parentGroups: [
      'boundary_layer_profile',
    ],
    pascalCaseName: 'InitialSize',
    quantityType: 0,
    text: 'Initial Size',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.GrowthRate]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'growthRate',
    cond: {
      choice: 1659,
      param: 'meshing_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 1.2,
    help: 'Ratio of size between 2 successive boundary mesh layers.',
    isMap: false,
    isRepeated: false,
    maxVal: 4,
    minVal: 1,
    name: 'growth_rate',
    openBound: false,
    parentGroups: [
      'boundary_layer_profile',
    ],
    pascalCaseName: 'GrowthRate',
    quantityType: 0,
    text: 'Growth Rate',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.RelaxationMethod]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'relaxationMethod',
    choices: [
      {
        enumNumber: 53024,
        help: 'Apply an implicit relaxation scheme.',
        name: 'IMPLICIT',
        text: 'Implicit',
      },
      {
        cond: {
          cond: {
            list: [
              {
                tagName: 'heat',
                type: ProtoDescriptor.CondType.TAG,
              },
            ],
            type: ProtoDescriptor.CondType.ANY,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        enumNumber: 27134,
        help: 'Apply an explicit relaxation scheme.',
        name: 'EXPLICIT',
        text: 'Explicit',
      },
    ],
    cond: {
      list: [
        {
          choice: 64640,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33327,
          param: 'time_marching',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 53024,
    help: 'Relaxation scheme for steady-state simulations or time implicit transient simulations.',
    isMap: false,
    isRepeated: false,
    name: 'relaxation_method',
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'RelaxationMethod',
    quantityType: 0,
    text: 'Relaxation Method',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ImplicitMethod]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'implicitMethod',
    choices: [
      {
        enumNumber: 62903,
        help: 'First-order backward Euler scheme.',
        name: 'BACKWARD_EULER',
        text: 'Backward Euler',
      },
    ],
    cond: {
      choice: 53024,
      param: 'relaxation_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 62903,
    help: 'Scheme for implicit relaxation of the governing equations.',
    isMap: false,
    isRepeated: false,
    name: 'implicit_method',
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'ImplicitMethod',
    quantityType: 0,
    text: 'Implicit Method',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.LinsolIterations]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'linsolIterations',
    cond: {
      choice: 53024,
      param: 'relaxation_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 15,
    help: 'Maximum number of linear solver iterations per nonlinear solution update. The linear solver terminates if the maximum is reached.',
    isMap: false,
    isRepeated: false,
    maxVal: 1000,
    minVal: 2,
    name: 'linsol_iterations',
    openBound: false,
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinsolIterations',
    quantityType: 0,
    text: 'Maximum Linear Iterations',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.LinsolTolerance]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'linsolTolerance',
    cond: {
      choice: 53024,
      param: 'relaxation_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 0.05,
    help: 'Relative reduction target in the linear solver residual from an initial value of 1.0 per nonlinear solution update. The linear solver terminates if the tolerance is met.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'linsol_tolerance',
    openBound: true,
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinsolTolerance',
    quantityType: 0,
    text: 'Linear Solver Tolerance',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.LinearSolverType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'linearSolverType',
    choices: [
      {
        cond: {
          cond: {
            list: [
              {
                choice: 43767,
                param: 'convective_schemes_density_based',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              {
                tagName: 'heat',
                type: ProtoDescriptor.CondType.TAG,
              },
            ],
            type: ProtoDescriptor.CondType.ANY,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        enumNumber: 16081,
        help: 'Gauss-Seidel iterative method.',
        name: 'GS',
        text: 'Gauss-Seidel',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 14928,
        help: 'Gauss-Seidel iterative method.',
        name: 'GS_AMGX',
        text: 'Gauss-Seidel AmgX',
      },
      {
        cond: {
          list: [
            {
              choice: 43767,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 28817,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              tagName: 'heat',
              type: ProtoDescriptor.CondType.TAG,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        enumNumber: 34601,
        help: 'AMG + Krylov iterative method.',
        name: 'AMG_KRYLOV_AMGX',
        text: 'AMG + Krylov',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 22544,
        help: 'AMG iterative method.',
        name: 'AMG_AMGX',
        text: 'AMG',
      },
    ],
    cond: {
      choice: 53024,
      param: 'relaxation_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 16081,
    help: 'Type of linear solver used for implicit relaxation.',
    isMap: false,
    isRepeated: false,
    name: 'linear_solver_type',
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinearSolverType',
    quantityType: 0,
    text: 'Linear Solver Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.LinsolAmgPreSweeps]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'linsolAmgPreSweeps',
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 22544,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 34601,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1,
    help: 'Linear Solver AMG Pre-Sweeps',
    isMap: false,
    isRepeated: false,
    maxVal: 1,
    minVal: 0,
    name: 'linsol_amg_pre_sweeps',
    openBound: false,
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinsolAmgPreSweeps',
    quantityType: 0,
    text: 'Linear Solver AMG Pre-Sweeps',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.LinsolAmgPostSweeps]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'linsolAmgPostSweeps',
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 22544,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 34601,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1,
    help: 'Linear Solver AMG Post-Sweeps',
    isMap: false,
    isRepeated: false,
    maxVal: 3,
    minVal: 1,
    name: 'linsol_amg_post_sweeps',
    openBound: false,
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinsolAmgPostSweeps',
    quantityType: 0,
    text: 'Linear Solver AMG Post-Sweeps',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.LinsolAmgCoarseningSize]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'linsolAmgCoarseningSize',
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 22544,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 34601,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 8,
    help: 'Linear Solver AMG Coarsening Sizing',
    isMap: false,
    isRepeated: false,
    maxVal: 8,
    minVal: 2,
    name: 'linsol_amg_coarsening_size',
    openBound: false,
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinsolAmgCoarseningSize',
    quantityType: 0,
    text: 'Linear Solver AMG Coarsening Sizing',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.LinsolAmgLevels]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'linsolAmgLevels',
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 22544,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 34601,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 20,
    help: 'Linear Solver AMG Level',
    isMap: false,
    isRepeated: false,
    maxVal: 40,
    minVal: 10,
    name: 'linsol_amg_levels',
    openBound: false,
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinsolAmgLevels',
    quantityType: 0,
    text: 'Linear Solver AMG Levels',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.LinsolAmgRelaxation]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'linsolAmgRelaxation',
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 22544,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 34601,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.75,
    help: 'Linear Solver AMG Relaxation applied to the smoother.',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: 0.5,
    name: 'linsol_amg_relaxation',
    openBound: false,
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinsolAmgRelaxation',
    quantityType: 0,
    text: 'Linear Solver AMG Relaxation',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.LinsolAmgCycleType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'linsolAmgCycleType',
    choices: [
      {
        enumNumber: 27261,
        help: 'V cycle',
        name: 'LINSOL_AMG_CYCLE_TYPE_V',
        text: 'V',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 60015,
        help: 'W cycle',
        name: 'LINSOL_AMG_CYCLE_TYPE_W',
        text: 'W',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 25036,
        help: 'F cycle',
        name: 'LINSOL_AMG_CYCLE_TYPE_F',
        text: 'F',
      },
    ],
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 22544,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 34601,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 27261,
    help: 'AMG cycle type',
    isMap: false,
    isRepeated: false,
    name: 'linsol_amg_cycle_type',
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinsolAmgCycleType',
    quantityType: 0,
    text: 'Linear Solver AMG Cycle',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.LinsolAmgFreezeLevelsThreshold]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'linsolAmgFreezeLevelsThreshold',
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 64640,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 22544,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 34601,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 300,
    help: 'Iteration at which the AMG levels are freezed.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'linsol_amg_freeze_levels_threshold',
    openBound: true,
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinsolAmgFreezeLevelsThreshold',
    quantityType: 0,
    text: 'Linear Solver AMG Freeze Levels Iteration',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.LinsolAmgSmoother]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'linsolAmgSmoother',
    choices: [
      {
        enumNumber: 45176,
        help: 'Jacobi',
        name: 'LINSOL_AMG_SMOOTHER_JACOBI',
        text: 'Jacobi',
      },
      {
        enumNumber: 42943,
        help: 'Gauss-Seidel',
        name: 'LINSOL_AMG_SMOOTHER_GS',
        text: 'Gauss-Seidel',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 49105,
        help: 'Symmetric Gauss-Seidel',
        name: 'LINSOL_AMG_SMOOTHER_SYM_GS',
        text: 'Symmetric GS',
      },
    ],
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 22544,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 34601,
              param: 'linear_solver_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 45176,
    help: 'AMG cycle smoother',
    isMap: false,
    isRepeated: false,
    name: 'linsol_amg_smoother',
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LinsolAmgSmoother',
    quantityType: 0,
    text: 'Linear Solver AMG Smoother',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ExplicitMethod]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'explicitMethod',
    choices: [
      {
        enumNumber: 42347,
        help: 'Classical Runge-Kutta fourth-order scheme.',
        name: 'RK_4',
        text: 'RK 4',
      },
      {
        enumNumber: 11958,
        help: 'Total Variation Diminishing Runge-Kutta third-order scheme.',
        name: 'TVD_RK_3',
        text: 'TVD RK 3',
      },
      {
        enumNumber: 21402,
        help: 'First-order forward Euler scheme.',
        name: 'FORWARD_EULER',
        text: 'Forward Euler',
      },
    ],
    cond: {
      list: [
        {
          choice: 27134,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 24337,
          param: 'time_marching',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 42347,
    help: 'Scheme for explicit relaxation or explicit time-accurate integration of the governing equations.',
    isMap: false,
    isRepeated: false,
    name: 'explicit_method',
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'ExplicitMethod',
    quantityType: 0,
    text: 'Explicit Method',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ControlsPresetVersion]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'controlsPresetVersion',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'controls_preset_version',
    openBound: true,
    parentGroups: [
      'solution_controls_heat',
      'solution_controls_fluid',
    ],
    pascalCaseName: 'ControlsPresetVersion',
    quantityType: 0,
    text: '',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.SolutionControlsHeatPreset]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'solutionControlsHeatPreset',
    choices: [
      {
        enumNumber: 24195,
        help: 'Solution controls preset is not set.',
        name: 'UNSET_SOLUTION_CONTROLS_HEAT_PRESET',
        text: 'Unset',
      },
      {
        enumNumber: 740,
        help: 'Conservative performance settings that emphasize robustness (ability to converge).',
        name: 'DEFAULT_SOLUTION_CONTROLS_HEAT',
        text: 'Default (Conservative)',
      },
      {
        enumNumber: 61301,
        help: 'Reasonable compromise between speed and robustness.',
        name: 'INTERMEDIATE_SOLUTION_CONTROLS_HEAT',
        text: 'Intermediate Performance',
      },
      {
        enumNumber: 58935,
        help: 'Aggressive settings further biased towards speed.',
        name: 'AGGRESSIVE_SOLUTION_CONTROLS_HEAT',
        text: 'Aggressive Performance',
      },
      {
        enumNumber: 44012,
        help: 'Custom solution controls.',
        name: 'CUSTOM_SOLUTION_CONTROLS_HEAT',
        text: 'Custom',
      },
    ],
    defaultValue: 740,
    help: 'Select suggested control settings or allow a custom choice. In general, assume a trade-off between speed and robustness (i.e. the ability to converge).',
    isMap: false,
    isRepeated: false,
    name: 'solution_controls_heat_preset',
    parentGroups: [
      'solution_controls_heat',
    ],
    pascalCaseName: 'SolutionControlsHeatPreset',
    quantityType: 0,
    text: 'Apply Solution Controls Heat Transfer in Solid Preset',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.AdjointSolutionMethod]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'adjointSolutionMethod',
    choices: [
      {
        enumNumber: 44791,
        help: 'Use the GMRES linear solver to solve the equations.',
        name: 'ADJOINT_METHOD_GMRES',
        text: 'GMRES',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 30098,
        help: 'Use a preconditioned Richardson iteration to solve the equations.',
        name: 'ADJOINT_METHOD_RICHARDSON',
        text: 'Richardson Iteration',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 7104,
        help: 'Use a fully consistent algorithmic differentiation approach.',
        name: 'ADJOINT_METHOD_ALGORITHMIC',
        text: 'Algorithmic Adjoint',
      },
    ],
    cond: {
      choice: 58692,
      param: 'float_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 44791,
    help: 'The method used to solve the discrete adjoint equations.',
    isMap: false,
    isRepeated: false,
    name: 'adjoint_solution_method',
    parentGroups: [
      'adjoint_controls_heat',
      'adjoint_controls_fluid',
    ],
    pascalCaseName: 'AdjointSolutionMethod',
    quantityType: 0,
    text: 'Solution Method',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.AdjointGmresRestartIters]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'adjointGmresRestartIters',
    cond: {
      choice: 44791,
      param: 'adjoint_solution_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 50,
    help: 'Number of iterations between GMRES restarts. A larger period makes the method more effective for tougher problems (for example incompressible flows), but increases the computational cost.',
    isMap: false,
    isRepeated: false,
    maxVal: 100,
    minVal: 30,
    name: 'adjoint_gmres_restart_iters',
    openBound: false,
    parentGroups: [
      'adjoint_controls_heat',
      'adjoint_controls_fluid',
    ],
    pascalCaseName: 'AdjointGmresRestartIters',
    quantityType: 0,
    text: 'Restart Period',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.GradientMethod]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'gradientMethod',
    choices: [
      {
        enumNumber: 62491,
        help: 'Standard hybrid least squares method with custom weighting.',
        name: 'HLSQ',
        text: 'HLSQ',
      },
      {
        enumNumber: 41255,
        help: 'Least squares method with inverse distance weighting.',
        name: 'WEIGHTED_LEAST_SQUARES',
        text: 'Weighted Least Squares',
      },
      {
        enumNumber: 29799,
        help: 'Green-Gauss method.',
        name: 'GREEN_GAUSS',
        text: 'Green-Gauss',
      },
      {
        enumNumber: 50544,
        help: 'Luminary Cloud\'s custom hybrid least squares method.',
        name: 'LC_HLSQ',
        text: 'LC-HLSQ',
      },
      {
        enumNumber: 15192,
        help: 'Gradients computed using nodal values interpolated from the cell-centered solution.',
        name: 'NODAL_GRADIENT',
        text: 'Nodal Gradient',
      },
    ],
    defaultValue: 15192,
    help: 'Method for computing the spatial gradients of fluid variables.',
    isMap: false,
    isRepeated: false,
    name: 'gradient_method',
    parentGroups: [
      'spatial_discretization_heat',
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'GradientMethod',
    quantityType: 0,
    text: 'Gradient Method',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.HlsqBlend]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'hlsqBlend',
    cond: {
      list: [
        {
          choice: 62491,
          param: 'gradient_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 50544,
          param: 'gradient_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 2.0,
    help: 'Blending parameter in the hybrid least squares gradient methods in [1,2].',
    isMap: false,
    isRepeated: false,
    maxVal: 2.0,
    minVal: 1.0,
    name: 'hlsq_blend',
    openBound: false,
    parentGroups: [
      'spatial_discretization_heat',
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'HlsqBlend',
    quantityType: 0,
    text: 'Hybrid Gradient Blend',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.GeometryFixes]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'geometryFixes',
    choices: [
      {
        enumNumber: 29529,
        help: 'Enable geometry fixes.',
        name: 'GEOMETRY_FIXES_ON',
        text: 'On',
      },
      {
        enumNumber: 55792,
        help: 'Disable geometry fixes.',
        name: 'GEOMETRY_FIXES_OFF',
        text: 'Off',
      },
    ],
    cond: {
      list: [
        {
          choice: 33909,
          param: 'upwind_scheme_order',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 29529,
    help: 'Strategies to cope with problematic mesh regions (e.g. high skewness angles).',
    isMap: false,
    isRepeated: false,
    name: 'geometry_fixes',
    parentGroups: [
      'spatial_discretization_heat',
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'GeometryFixes',
    quantityType: 0,
    text: 'Geometry Fixes',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.GeometryFixesMitigations]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'geometryFixesMitigations',
    cond: {
      choice: 29529,
      param: 'geometry_fixes',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 0.25,
    help: 'Magnitude of the geometrical fixes mitigations. Expected value within [0,1]. The higher this value, the more numerical dissipation is added at control volumes with low-quality topological properties.',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: 0.0,
    name: 'geometry_fixes_mitigations',
    openBound: false,
    parentGroups: [
      'spatial_discretization_heat',
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'GeometryFixesMitigations',
    quantityType: 0,
    text: 'Geometry Fixes Mitigations',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.DiscretizationPresetVersion]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'discretizationPresetVersion',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'discretization_preset_version',
    openBound: true,
    parentGroups: [
      'spatial_discretization_heat',
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'DiscretizationPresetVersion',
    quantityType: 0,
    text: '',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.SpatialDiscretizationHeatPreset]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'spatialDiscretizationHeatPreset',
    choices: [
      {
        enumNumber: 37699,
        help: 'Spatial discretization preset is not set.',
        name: 'UNSET_SPATIAL_DISCRETIZATION_HEAT_PRESET',
        text: 'Unset',
      },
      {
        enumNumber: 6705,
        help: 'Default settings, good compromise between accuracy and robustness (ability to converge).',
        name: 'DEFAULT_SPATIAL_DISCRETIZATION_HEAT',
        text: 'Default',
      },
      {
        enumNumber: 60394,
        help: 'Settings biased towards robustness over maximum accuracy (relative to default).',
        name: 'CONSERVATIVE_SPATIAL_DISCRETIZATION_HEAT',
        text: 'Conservative',
      },
      {
        enumNumber: 5587,
        help: 'Settings that emphasize accuracy (relative to default).',
        name: 'HIGH_ACCURACY_SPATIAL_DISCRETIZATION_HEAT',
        text: 'High Accuracy',
      },
      {
        enumNumber: 2742,
        help: 'Custom discretization settings.',
        name: 'CUSTOM_SPATIAL_DISCRETIZATION_HEAT',
        text: 'Custom',
      },
    ],
    defaultValue: 6705,
    help: 'Select suggested control settings or allow a custom choice. In general, assume a trade-off between accuracy and robustness (i.e. the ability to converge).',
    isMap: false,
    isRepeated: false,
    name: 'spatial_discretization_heat_preset',
    parentGroups: [
      'spatial_discretization_heat',
    ],
    pascalCaseName: 'SpatialDiscretizationHeatPreset',
    quantityType: 0,
    text: 'Apply Spatial Discretization Heat Transfer in Solid Preset',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.BoundaryConditionName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'boundaryConditionName',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'boundary_condition_name',
    parentGroups: [
      'boundary_conditions_heat',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'BoundaryConditionName',
    quantityType: 0,
    text: 'Boundary Condition Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.BoundaryConditionDisplayName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'boundaryConditionDisplayName',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'boundary_condition_display_name',
    parentGroups: [
      'boundary_conditions_heat',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'BoundaryConditionDisplayName',
    quantityType: 0,
    text: 'Boundary Condition Display Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.BoundaryConditionInterfaceId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'boundaryConditionInterfaceId',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'ID of the multiphysics interface that manages this boundary condition.',
    isMap: false,
    isRepeated: false,
    name: 'boundary_condition_interface_id',
    parentGroups: [
      'boundary_conditions_heat',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'BoundaryConditionInterfaceId',
    quantityType: 0,
    text: 'Boundary Condition Interface ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.ProfileBc]: <ProtoDescriptor.BoolParam>{
    camelCaseName: 'profileBc',
    cond: {
      list: [
        {
          list: [
            {
              choice: 36606,
              param: 'flow_behavior',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              list: [
                {
                  choice: 63012,
                  param: 'heat_physical_boundary',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 61671,
                  param: 'heat_physical_boundary',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          choice: 46673,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Turn on or off the ability to use tabulated data to define spatially varying boundary conditions.',
    isMap: false,
    isRepeated: false,
    name: 'profile_bc',
    parentGroups: [
      'boundary_conditions_heat',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'ProfileBc',
    quantityType: 0,
    text: 'Tabulated Profile',
    type: ProtoDescriptor.ParamType.BOOL,
  },
  [ParamName.ProfileBcData]: <ProtoDescriptor.RectilinearTableParam>{
    axis: [
      {
        name: '',
      },
    ],
    camelCaseName: 'profileBcData',
    cond: {
      param: 'profile_bc',
      type: ProtoDescriptor.CondType.TRUEFALSE,
    },
    help: 'Tabulated data for spatially varying boundary conditions',
    isMap: false,
    isRepeated: false,
    name: 'profile_bc_data',
    parentGroups: [
      'boundary_conditions_heat',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'ProfileBcData',
    quantityType: 0,
    tableRecords: [],
    text: 'Table Data',
    type: ProtoDescriptor.ParamType.TABLE,
  },
  [ParamName.ProfileType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'profileType',
    choices: [
      {
        enumNumber: 66439,
        help: '1D profile in X direction.',
        name: 'CARTESIAN_X',
        text: 'X',
      },
      {
        enumNumber: 39749,
        help: '1D profile in Y direction.',
        name: 'CARTESIAN_Y',
        text: 'Y',
      },
      {
        enumNumber: 23052,
        help: '1D profile in Z direction.',
        name: 'CARTESIAN_Z',
        text: 'Z',
      },
      {
        enumNumber: 63301,
        help: '1D radial profile normal to the X direction.',
        name: 'RADIAL_X',
        text: 'Radial X',
      },
      {
        enumNumber: 15041,
        help: '1D radial profile normal to the Y direction.',
        name: 'RADIAL_Y',
        text: 'Radial Y',
      },
      {
        enumNumber: 12670,
        help: '1D radial profile normal to the Z direction.',
        name: 'RADIAL_Z',
        text: 'Radial Z',
      },
      {
        cond: {
          choice: 36606,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        enumNumber: 22665,
        help: 'Time varying profile.',
        name: 'TIME',
        text: 'Time',
      },
    ],
    cond: {
      list: [
        {
          param: 'profile_bc',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
        {
          param: 'profile_source',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 66439,
    help: 'Types of boundary condition profile.',
    isMap: false,
    isRepeated: false,
    name: 'profile_type',
    parentGroups: [
      'boundary_conditions_heat',
      'heat_source',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'ProfileType',
    quantityType: 0,
    text: 'Profile Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.HeatPhysicalBoundary]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'heatPhysicalBoundary',
    choices: [
      {
        enumNumber: 25246,
        help: 'Fixed temperature.',
        name: 'HEAT_BC_ISOTHERMAL',
        text: 'Isothermal',
      },
      {
        enumNumber: 63012,
        help: 'Heat flux.',
        name: 'HEAT_BC_HEAT_FLUX',
        text: 'Heat Flux',
      },
      {
        enumNumber: 61671,
        help: 'Integrated heat flux.',
        name: 'HEAT_BC_INTEGRATED_HEAT_FLUX',
        text: 'Integrated Heat Flux',
      },
      {
        enumNumber: 55632,
        help: 'Symmetry.',
        name: 'HEAT_BC_SYMMETRY',
        text: 'Symmetry',
      },
      {
        enumNumber: 57747,
        help: 'Convective heat transfer.',
        name: 'HEAT_BC_CONVECTION',
        text: 'Convective Heat Transfer',
      },
    ],
    defaultValue: 25246,
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'heat_physical_boundary',
    parentGroups: [
      'boundary_conditions_heat',
    ],
    pascalCaseName: 'HeatPhysicalBoundary',
    quantityType: 0,
    text: 'Heat Physical Boundary',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.FixedHeatFlux]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'fixedHeatFlux',
    cond: {
      list: [
        {
          choice: 814,
          param: 'wall_energy',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 63012,
          param: 'heat_physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Heat flux per unit area at wall boundary surfaces. Negative values increase temperatures at the wall while positive values decrease it. Enter 0 for an adiabatic wall.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'fixed_heat_flux',
    openBound: true,
    parentGroups: [
      'boundary_conditions_heat',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FixedHeatFlux',
    quantityType: QuantityPb.QuantityType.HEAT_FLUX,
    text: 'Heat Flux',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.FixedIntegratedHeatFlux]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'fixedIntegratedHeatFlux',
    cond: {
      choice: 61671,
      param: 'heat_physical_boundary',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: 'Total heat flux (power) at wall boundary surfaces. Negative values increase temperatures at the wall while positive values decrease it. Enter 0 for an adiabatic wall.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'fixed_integrated_heat_flux',
    openBound: true,
    parentGroups: [
      'boundary_conditions_heat',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FixedIntegratedHeatFlux',
    quantityType: QuantityPb.QuantityType.POWER,
    text: 'Integrated Heat Flux',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.HeatFluxCol]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'heatFluxCol',
    cond: {
      list: [
        {
          list: [
            {
              choice: 63012,
              param: 'heat_physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 61671,
              param: 'heat_physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        {
          param: 'profile_bc',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Index of the column of the table in profile_bc_data for heat flux.',
    isMap: false,
    isRepeated: false,
    maxVal: 100000.0,
    minVal: 0,
    name: 'heat_flux_col',
    openBound: false,
    parentGroups: [
      'boundary_conditions_heat',
    ],
    pascalCaseName: 'HeatFluxCol',
    quantityType: 0,
    text: 'Heat Flux Column',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.FixedTemperature]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'fixedTemperature',
    cond: {
      list: [
        {
          choice: 22055,
          param: 'wall_energy',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 25246,
          param: 'heat_physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 57747,
          param: 'heat_physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 288.15,
    help: 'Temperature at the wall boundary surfaces.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'fixed_temperature',
    openBound: true,
    parentGroups: [
      'boundary_conditions_heat',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FixedTemperature',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'Temperature',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.HeatTransferCoefficient]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'heatTransferCoefficient',
    cond: {
      list: [
        {
          choice: 57747,
          param: 'heat_physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Heat transfer coefficient for convective heat transfer. Allowed range [0, infinity).',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0.0,
    name: 'heat_transfer_coefficient',
    openBound: false,
    parentGroups: [
      'boundary_conditions_heat',
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'HeatTransferCoefficient',
    quantityType: QuantityPb.QuantityType.HEAT_TRANSFER_COEFFICIENT,
    text: 'Heat Transfer Coefficient',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InitializationType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'initializationType',
    choices: [
      {
        cond: {
          cond: {
            choice: 58692,
            param: 'float_type',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        enumNumber: 24354,
        help: 'Apply a uniform initial condition for all fields in space.',
        name: 'UNIFORM_VALUES',
        text: 'Uniform Values',
      },
      {
        cond: {
          list: [
            {
              cond: {
                choice: 58692,
                param: 'float_type',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
            {
              cond: {
                tagName: 'heat',
                type: ProtoDescriptor.CondType.TAG,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        enumNumber: 3666,
        help: 'Initialize all fields uniformly in space using the values at the far-field boundary.',
        name: 'FARFIELD_VALUES',
        text: 'Far-field Values',
      },
      {
        cond: {
          list: [
            {
              type: ProtoDescriptor.CondType.FALSE,
            },
            {
              cond: {
                choice: 58692,
                param: 'float_type',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
            {
              cond: {
                tagName: 'heat',
                type: ProtoDescriptor.CondType.TAG,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        enumNumber: 40255,
        help: 'Initialize the velocity from an irrotational incompressible potential flow solution.',
        name: 'INITIALIZATION_POTENTIAL_FLOW',
        text: 'Potential Flow',
      },
      {
        cond: {
          list: [
            {
              type: ProtoDescriptor.CondType.FALSE,
            },
            {
              cond: {
                choice: 58692,
                param: 'float_type',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
            {
              cond: {
                tagName: 'heat',
                type: ProtoDescriptor.CondType.TAG,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        enumNumber: 11399,
        help: 'Apply an initial condition corresponding to a predefined problem.',
        name: 'VERIFICATION_SOLUTION',
        text: 'Verification Solution',
      },
      {
        enumNumber: 26852,
        help: 'Apply the starting values from an existing solution for the same mesh.',
        name: 'EXISTING_SOLUTION',
        text: 'Existing Solution',
      },
    ],
    defaultValue: 24354,
    help: 'Type of initial condition for the field variables.',
    isMap: false,
    isRepeated: false,
    name: 'initialization_type',
    parentGroups: [
      'initialization_heat',
      'initialization_fluid',
    ],
    pascalCaseName: 'InitializationType',
    quantityType: 0,
    text: 'Initialization',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.UniformT]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'uniformT',
    cond: {
      list: [
        {
          list: [
            {
              list: [
                {
                  choice: 24354,
                  param: 'initialization_type',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 40255,
                  param: 'initialization_type',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
            {
              list: [
                {
                  choice: 37484,
                  param: 'density_relationship',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 41777,
                  param: 'density_relationship',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          tagName: 'heat',
          type: ProtoDescriptor.CondType.TAG,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 288.15,
    help: 'Uniform initial condition for static temperature.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'uniform_t',
    openBound: true,
    parentGroups: [
      'initialization_heat',
      'initialization_fluid',
    ],
    pascalCaseName: 'UniformT',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'Temperature',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ExistingSolutionUrl]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'existingSolutionUrl',
    cond: {
      choice: 26852,
      param: 'initialization_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: 'Url for the existing solution to use for initialization.',
    isMap: false,
    isRepeated: false,
    name: 'existing_solution_url',
    parentGroups: [
      'initialization_heat',
      'initialization_fluid',
    ],
    pascalCaseName: 'ExistingSolutionUrl',
    quantityType: 0,
    text: 'Existing Solution Url',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.ExistingSolutionId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'existingSolutionId',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'ID for the existing solution to use for initialization.',
    isMap: false,
    isRepeated: false,
    name: 'existing_solution_id',
    parentGroups: [
      'initialization_heat',
      'initialization_fluid',
    ],
    pascalCaseName: 'ExistingSolutionId',
    quantityType: 0,
    text: 'Existing Solution ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.ProfileSource]: <ProtoDescriptor.BoolParam>{
    camelCaseName: 'profileSource',
    cond: {
      choice: 36606,
      param: 'flow_behavior',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: 'Turn on or off the ability to use tabulated data to define time varying heat sources.',
    isMap: false,
    isRepeated: false,
    name: 'profile_source',
    parentGroups: [
      'heat_source',
    ],
    pascalCaseName: 'ProfileSource',
    quantityType: 0,
    text: 'Tabulated Profile',
    type: ProtoDescriptor.ParamType.BOOL,
  },
  [ParamName.ProfileSourceData]: <ProtoDescriptor.RectilinearTableParam>{
    axis: [
      {
        name: '',
      },
    ],
    camelCaseName: 'profileSourceData',
    cond: {
      param: 'profile_source',
      type: ProtoDescriptor.CondType.TRUEFALSE,
    },
    help: 'Tabulated data for time varying heat sources',
    isMap: false,
    isRepeated: false,
    name: 'profile_source_data',
    parentGroups: [
      'heat_source',
    ],
    pascalCaseName: 'ProfileSourceData',
    quantityType: 0,
    tableRecords: [],
    text: 'Table Data',
    type: ProtoDescriptor.ParamType.TABLE,
  },
  [ParamName.HeatSourceCol]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'heatSourceCol',
    cond: {
      param: 'profile_source',
      type: ProtoDescriptor.CondType.TRUEFALSE,
    },
    help: 'Index of the column of the table for heat source.',
    isMap: false,
    isRepeated: false,
    maxVal: 100000.0,
    minVal: 0,
    name: 'heat_source_col',
    openBound: false,
    parentGroups: [
      'heat_source',
    ],
    pascalCaseName: 'HeatSourceCol',
    quantityType: 0,
    text: 'Heat Source Column',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.HeatSourceId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'heatSourceId',
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'heat_source_id',
    parentGroups: [
      'heat_source',
    ],
    pascalCaseName: 'HeatSourceId',
    quantityType: 0,
    text: 'Heat Source Id',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.HeatSourceName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'heatSourceName',
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Name of the heat source.',
    isMap: false,
    isRepeated: false,
    name: 'heat_source_name',
    parentGroups: [
      'heat_source',
    ],
    pascalCaseName: 'HeatSourceName',
    quantityType: 0,
    text: 'Heat Source Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.HeatSourceZoneIds]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'heatSourceZoneIds',
    help: 'Ids of the zones the heat source is applied to.',
    isMap: false,
    isRepeated: true,
    name: 'heat_source_zone_ids',
    parentGroups: [
      'heat_source',
    ],
    pascalCaseName: 'HeatSourceZoneIds',
    quantityType: 0,
    text: 'Heat Source Zone Ids',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.HeatSourceType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'heatSourceType',
    choices: [
      {
        enumNumber: 28994,
        help: 'Specify heat source power in Watts.',
        name: 'HEAT_SOURCE_TYPE_POWER',
        text: 'Power',
      },
      {
        enumNumber: 61247,
        help: 'Specify heat source power in Watts per unit volume.',
        name: 'HEAT_SOURCE_TYPE_POWER_PER_UNIT_OF_VOLUME',
        text: 'Power per unit volume',
      },
    ],
    defaultValue: 28994,
    help: 'Heat source specification.',
    isMap: false,
    isRepeated: false,
    name: 'heat_source_type',
    parentGroups: [
      'heat_source',
    ],
    pascalCaseName: 'HeatSourceType',
    quantityType: 0,
    text: 'Heat Source Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.HeatSourcePower]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'heatSourcePower',
    cond: {
      list: [
        {
          choice: 28994,
          param: 'heat_source_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Power of the heat source.',
    isMap: false,
    isRepeated: false,
    name: 'heat_source_power',
    parentGroups: [
      'heat_source',
    ],
    pascalCaseName: 'HeatSourcePower',
    quantityType: QuantityPb.QuantityType.POWER,
    text: 'Heat source power',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.HeatSourcePowerPerUnitVolume]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'heatSourcePowerPerUnitVolume',
    cond: {
      list: [
        {
          choice: 61247,
          param: 'heat_source_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Power per unit volume of the heat source',
    isMap: false,
    isRepeated: false,
    name: 'heat_source_power_per_unit_volume',
    parentGroups: [
      'heat_source',
    ],
    pascalCaseName: 'HeatSourcePowerPerUnitVolume',
    quantityType: QuantityPb.QuantityType.POWER_PER_UNIT_VOLUME,
    text: 'Heat source power per unit volume',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SlidingInterfaceId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'slidingInterfaceId',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'ID of the sliding interface',
    isMap: false,
    isRepeated: false,
    name: 'sliding_interface_id',
    parentGroups: [
      'sliding_interfaces',
      'sliding_interfaces',
      'interfaces',
    ],
    pascalCaseName: 'SlidingInterfaceId',
    quantityType: 0,
    text: 'Sliding Interface ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.SlidingInterfaceName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'slidingInterfaceName',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'Name of the sliding interface',
    isMap: false,
    isRepeated: false,
    name: 'sliding_interface_name',
    parentGroups: [
      'sliding_interfaces',
      'sliding_interfaces',
      'interfaces',
    ],
    pascalCaseName: 'SlidingInterfaceName',
    quantityType: 0,
    text: 'Sliding Interface Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.SlidingA]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'slidingA',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'Names of the surfaces of side A of the sliding interface',
    isMap: false,
    isRepeated: true,
    name: 'sliding_a',
    parentGroups: [
      'sliding_interfaces',
      'sliding_interfaces',
      'interfaces',
    ],
    pascalCaseName: 'SlidingA',
    quantityType: 0,
    text: 'Sliding A',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.SlidingB]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'slidingB',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: 'Names of the surfaces of side B of the sliding interface',
    isMap: false,
    isRepeated: true,
    name: 'sliding_b',
    parentGroups: [
      'sliding_interfaces',
      'sliding_interfaces',
      'interfaces',
    ],
    pascalCaseName: 'SlidingB',
    quantityType: 0,
    text: 'Sliding B',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.SlidingMatchingTranslationTransform]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'slidingMatchingTranslationTransform',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Translation transform vector needed to match the planes defined by each side of the sliding interface. Similar to the translation transform required to match fully-covered and -matching translating periodic bcs. Also, similar to periodic_translation in periodic_pair.',
    isMap: false,
    isRepeated: false,
    name: 'sliding_matching_translation_transform',
    parentGroups: [
      'sliding_interfaces',
      'sliding_interfaces',
      'interfaces',
    ],
    pascalCaseName: 'SlidingMatchingTranslationTransform',
    quantityType: 0,
    text: 'Sliding Matching Translation Transform',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.SlidingMatchingPeriodicRotationAngles]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'slidingMatchingPeriodicRotationAngles',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Similar to sliding_matching_translation_transform but for periodic transforms. Also, similar to periodic_rotation_angles in periodic_pair.',
    isMap: false,
    isRepeated: false,
    name: 'sliding_matching_periodic_rotation_angles',
    parentGroups: [
      'sliding_interfaces',
      'sliding_interfaces',
      'interfaces',
    ],
    pascalCaseName: 'SlidingMatchingPeriodicRotationAngles',
    quantityType: QuantityPb.QuantityType.RADIANS,
    text: 'Sliding Matching Rotation Angles',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.SlidingMatchingPeriodicCenterOfRotation]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'slidingMatchingPeriodicCenterOfRotation',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Similar to sliding_matching_translation_transform but for periodic transforms. Also, similar to periodic_center_of_rotation in periodic_pair.',
    isMap: false,
    isRepeated: false,
    name: 'sliding_matching_periodic_center_of_rotation',
    parentGroups: [
      'sliding_interfaces',
      'sliding_interfaces',
      'interfaces',
    ],
    pascalCaseName: 'SlidingMatchingPeriodicCenterOfRotation',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Sliding Matching Center of rotation',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.InterfaceType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'interfaceType',
    choices: [
      {
        enumNumber: 53698,
        help: 'Automatic treatment based on geometry and settings (general interface, or frozen rotor, or sliding interface).',
        name: 'GENERAL_INTERFACE',
        text: 'Automatic',
      },
      {
        enumNumber: 44970,
        help: 'Imposes a pitchwise average of the variables on either side of the interface.',
        name: 'MIXING_PLANE_INTERFACE',
        text: 'Mixing Plane',
      },
    ],
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    defaultValue: 53698,
    help: 'Type of interface treatment',
    isMap: false,
    isRepeated: false,
    name: 'interface_type',
    parentGroups: [
      'sliding_interfaces',
      'sliding_interfaces',
      'interfaces',
    ],
    pascalCaseName: 'InterfaceType',
    quantityType: 0,
    text: 'Interface Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.PeriodicPairName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'periodicPairName',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'periodic_pair_name',
    parentGroups: [
      'periodic_pair',
      'periodic_pair',
    ],
    pascalCaseName: 'PeriodicPairName',
    quantityType: 0,
    text: 'Periodic Pair Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.BoundA]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'boundA',
    help: '',
    isMap: false,
    isRepeated: true,
    name: 'bound_a',
    parentGroups: [
      'periodic_pair',
      'periodic_pair',
    ],
    pascalCaseName: 'BoundA',
    quantityType: 0,
    text: 'Bound A',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.BoundB]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'boundB',
    help: '',
    isMap: false,
    isRepeated: true,
    name: 'bound_b',
    parentGroups: [
      'periodic_pair',
      'periodic_pair',
    ],
    pascalCaseName: 'BoundB',
    quantityType: 0,
    text: 'Bound B',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.PeriodicBcType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'periodicBcType',
    choices: [
      {
        enumNumber: 20185,
        help: '',
        name: 'TRANSLATIONAL',
        text: 'Translational',
      },
      {
        enumNumber: 34414,
        help: '',
        name: 'ROTATIONAL',
        text: 'Rotational',
      },
    ],
    defaultValue: 20185,
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'periodic_bc_type',
    parentGroups: [
      'periodic_pair',
      'periodic_pair',
    ],
    pascalCaseName: 'PeriodicBcType',
    quantityType: 0,
    text: 'Periodic BC Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.Translational]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'translational',
    cond: {
      choice: 20185,
      param: 'periodic_bc_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'translational',
    openBound: true,
    parentGroups: [
      'periodic_pair',
      'periodic_pair',
    ],
    pascalCaseName: 'Translational',
    quantityType: 0,
    text: 'Translational',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.PeriodicTranslation]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'periodicTranslation',
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Holds the translation vector in the x,y,z directions from each point on the first periodic boundary to its matching point on the second periodic boundary.',
    isMap: false,
    isRepeated: false,
    name: 'periodic_translation',
    parentGroups: [
      'periodic_pair',
      'periodic_pair',
    ],
    pascalCaseName: 'PeriodicTranslation',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Translation',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.PeriodicCenterOfRotation]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'periodicCenterOfRotation',
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Origin for the rotational periodicity transformation.',
    isMap: false,
    isRepeated: false,
    name: 'periodic_center_of_rotation',
    parentGroups: [
      'periodic_pair',
      'periodic_pair',
    ],
    pascalCaseName: 'PeriodicCenterOfRotation',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Center of rotation',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.PeriodicRotationAngles]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'periodicRotationAngles',
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Holds the rotation vector needed to transform each point on the first periodic boundary to its matching point on the second periodic boundary. The vector direction defines the periodicity axis and its magnitude defines the periodicity angle, in radians, from A to B according to the right-hand rule.',
    isMap: false,
    isRepeated: false,
    name: 'periodic_rotation_angles',
    parentGroups: [
      'periodic_pair',
      'periodic_pair',
    ],
    pascalCaseName: 'PeriodicRotationAngles',
    quantityType: QuantityPb.QuantityType.RADIANS,
    text: 'Rotation Angles',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.FluidType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'fluidType',
    choices: [
      {
        enumNumber: 30813,
        help: 'Standard single phase fluid',
        name: 'SINGLE_PHASE',
        text: 'Single Phase',
      },
      {
        enumNumber: 56617,
        help: 'Single phase approximation for cavitation with a cavitation model',
        name: 'CAVITATING_FLUID',
        text: 'Cavitating Fluid',
      },
      {
        enumNumber: 26616,
        help: 'VOF multiphase model for simulating flows with multiple fluids and immiscible interfaces.',
        name: 'VOF_FLUID',
        text: 'VOF',
      },
    ],
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 30813,
    help: 'Fluid types available for use with the solver.',
    isMap: false,
    isRepeated: false,
    name: 'fluid_type',
    parentGroups: [
      'basic_fluid',
    ],
    pascalCaseName: 'FluidType',
    quantityType: 0,
    text: 'Fluid Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ViscousModel]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'viscousModel',
    choices: [
      {
        enumNumber: 35474,
        help: 'Reynolds-averaged Navier-Stokes.',
        name: 'RANS',
        text: 'RANS',
      },
      {
        cond: {
          choice: 36606,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        enumNumber: 55751,
        help: 'Detached Eddy Simulation.',
        name: 'DES',
        text: 'DES',
      },
      {
        cond: {
          choice: 36606,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        enumNumber: 65537,
        help: 'Large Eddy Simulation.',
        name: 'LES',
        text: 'LES',
      },
      {
        enumNumber: 48078,
        help: 'Laminar flow governed by the Navier-Stokes equations.',
        name: 'LAMINAR',
        text: 'Laminar',
      },
      {
        cond: {
          cond: {
            choice: 41777,
            param: 'density_relationship',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        enumNumber: 8775,
        help: 'Inviscid flow governed by the Euler equations.',
        name: 'INVISCID',
        text: 'Inviscid',
      },
    ],
    defaultValue: 35474,
    help: 'Set the viscous model for the fluid solver.',
    isMap: false,
    isRepeated: false,
    name: 'viscous_model',
    parentGroups: [
      'basic_fluid',
    ],
    pascalCaseName: 'ViscousModel',
    quantityType: 0,
    text: 'Viscous Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.DesFormulation]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'desFormulation',
    choices: [
      {
        enumNumber: 34278,
        help: 'Use the DDES formulation with the shear-layer-adapted LES length-scale.',
        name: 'DDES_VTM',
        text: 'DDES-VTM',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 17788,
        help: 'DDES with shear-layer-adapted LES length-scale and Sigma LES model.',
        name: 'DDES_VTM_SIGMA',
        text: 'DDES-VTM-SIGMA',
      },
      {
        enumNumber: 63764,
        help: 'Use the Improved Delayed Detached Eddy Simulation (DDES) formulation.',
        name: 'IDDES',
        text: 'IDDES',
      },
      {
        enumNumber: 6895,
        help: 'Use the delayed Detached Eddy Simulation (DDES) formulation.',
        name: 'DDES',
        text: 'DDES',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 37035,
        help: 'Use the original Detached Eddy Simulation (DES) formulation.',
        name: 'DES97',
        text: 'DES97',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 25341,
        help: 'Use the Zonal Detached Eddy Simulation (ZDES) formulation.',
        name: 'ZDES',
        text: 'ZDES',
      },
    ],
    cond: {
      choice: 55751,
      param: 'viscous_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 34278,
    help: 'Select a Detached Eddy Simulation (DES) formulation.',
    isMap: false,
    isRepeated: false,
    name: 'des_formulation',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'DesFormulation',
    quantityType: 0,
    text: 'DES Formulation',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.RansRegion]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'ransRegion',
    choices: [
      {
        enumNumber: 14809,
        help: 'Force RANS behavior inside a prescribed bounding box.',
        name: 'INSIDE',
        text: 'Inside Bounding Box',
      },
      {
        enumNumber: 3117,
        help: 'Force RANS behavior outside a prescribed bounding box.',
        name: 'OUTSIDE',
        text: 'Outside Bounding Box',
      },
    ],
    cond: {
      choice: 25341,
      param: 'des_formulation',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 14809,
    help: 'Select a region where RANS behavior should be enforced.',
    isMap: false,
    isRepeated: false,
    name: 'rans_region',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'RansRegion',
    quantityType: 0,
    text: 'RANS Region',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ZdesRmin]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'zdesRmin',
    cond: {
      choice: 25341,
      param: 'des_formulation',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Minimum bounds (x, y, z coordinates of bottom left corner) of the bounding box.',
    isMap: false,
    isRepeated: false,
    name: 'zdes_rmin',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'ZdesRmin',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'ZDES Bounding Box Minimum Bound',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.ZdesRmax]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'zdesRmax',
    cond: {
      choice: 25341,
      param: 'des_formulation',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Maximum bounds (x, y, z coordinates of top right corner) of the bounding box.',
    isMap: false,
    isRepeated: false,
    name: 'zdes_rmax',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'ZdesRmax',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'ZDES Bounding Box Maximum Bound',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.SubGridScaleModel]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'subGridScaleModel',
    choices: [
      {
        enumNumber: 50728,
        help: 'No sub-grid scale model.',
        name: 'NONE',
        text: 'None',
      },
      {
        enumNumber: 35746,
        help: 'Smagorinsky eddy viscosity model.',
        name: 'SMAGORINSKY',
        text: 'Smagorinsky',
      },
      {
        enumNumber: 22775,
        help: 'Vreman eddy viscosity model.',
        name: 'VREMAN',
        text: 'Vreman',
      },
      {
        enumNumber: 58568,
        help: 'Wall adapting local eddy (WALE) viscosity model.',
        name: 'WALE',
        text: 'WALE',
      },
      {
        enumNumber: 13255,
        help: 'Sigma eddy viscosity model.',
        name: 'SIGMA',
        text: 'Sigma',
      },
      {
        enumNumber: 4052,
        help: 'AMD eddy viscosity model.',
        name: 'AMD',
        text: 'AMD',
      },
    ],
    cond: {
      choice: 65537,
      param: 'viscous_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 22775,
    help: 'Sub-grid scale models available for Large Eddy Simulation (LES).',
    isMap: false,
    isRepeated: false,
    name: 'sub_grid_scale_model',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'SubGridScaleModel',
    quantityType: 0,
    text: 'Sub-Grid Scale Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.CS]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cS',
    cond: {
      choice: 35746,
      param: 'sub_grid_scale_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 0.1,
    help: 'Coefficient of the Smagorinsky eddy viscosity model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_s',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CS',
    quantityType: 0,
    text: 'Smagorinsky Model Coefficient',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CVreman]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cVreman',
    cond: {
      choice: 22775,
      param: 'sub_grid_scale_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 0.07225,
    help: 'Coefficient of the Vreman eddy viscosity model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_vreman',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CVreman',
    quantityType: 0,
    text: 'Vreman Model Coefficient',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CWale]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cWale',
    cond: {
      choice: 58568,
      param: 'sub_grid_scale_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 0.325,
    help: 'Coefficient of the WALE eddy viscosity model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_wale',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CWale',
    quantityType: 0,
    text: 'WALE Model Coefficient',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CSigma]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cSigma',
    cond: {
      choice: 13255,
      param: 'sub_grid_scale_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 1.5,
    help: 'Coefficient of the Sigma eddy viscosity model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_sigma',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CSigma',
    quantityType: 0,
    text: 'Sigma Model Coefficient',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CAmd]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cAmd',
    cond: {
      choice: 4052,
      param: 'sub_grid_scale_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 0.19,
    help: 'Coefficient of the AMD eddy viscosity model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_amd',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CAmd',
    quantityType: 0,
    text: 'AMD Model Coefficient',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TurbulentThermalConductivityPrandtlConstant]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'turbulentThermalConductivityPrandtlConstant',
    cond: {
      list: [
        {
          list: [
            {
              choice: 37484,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 65537,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.85,
    help: 'Turbulent Prandtl number for computing an effective thermal conductivity for heat transport.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'turbulent_thermal_conductivity_prandtl_constant',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'TurbulentThermalConductivityPrandtlConstant',
    quantityType: 0,
    text: 'Turbulent Prandtl Number',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TransitionModel]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'transitionModel',
    choices: [
      {
        enumNumber: 62497,
        help: 'No transition modelling, the flow is fully turbulent.',
        name: 'NO_TRANSITION',
        text: 'Fully Turbulent',
      },
      {
        enumNumber: 20304,
        help: 'One-equation local correlation-based, γ-2015, transition model (simplified γ-Re<sub>θ<sub>t</sub></sub>).',
        name: 'GAMMA_2015',
        text: 'γ-2015',
      },
      {
        cond: {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              list: [
                {
                  choice: 2762,
                  param: 'turbulence_model',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 14358,
                  param: 'turbulence_model',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        enumNumber: 37856,
        help: 'Two-equation local correlation-based, γ-Re<sub>θ<sub>t</sub></sub>-2009, transition model.',
        name: 'GAMMA_RE_THETA_2009',
        text: 'γ-Re<sub>θ<sub>t</sub></sub>-2009',
      },
      {
        cond: {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 2762,
              param: 'turbulence_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        enumNumber: 52077,
        help: 'Two-equation amplification factor transport, AFT-2019, transition model.',
        name: 'AFT_2019',
        text: 'AFT-2019',
      },
    ],
    cond: {
      list: [
        {
          choice: 35474,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 2762,
              param: 'turbulence_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 14358,
              param: 'turbulence_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 62497,
    help: 'Laminar-turbulent transition models available for Reynolds-averaged Navier-Stokes (RANS).',
    isMap: false,
    isRepeated: false,
    name: 'transition_model',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'TransitionModel',
    quantityType: 0,
    text: 'Transition Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.TransitionModelCrossFlow]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'transitionModelCrossFlow',
    choices: [
      {
        enumNumber: 29326,
        help: 'Crossflow instability is not considered in transition modelling.',
        name: 'TRANSITION_MODEL_CROSS_FLOW_OFF',
        text: 'Off',
      },
      {
        enumNumber: 37250,
        help: 'Crossflow treatment is active in transition modelling.',
        name: 'TRANSITION_MODEL_CROSS_FLOW_ON',
        text: 'On',
      },
    ],
    cond: {
      list: [
        {
          choice: 20304,
          param: 'transition_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 37856,
          param: 'transition_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 52077,
          param: 'transition_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 29326,
    help: 'Crossflow instability treatment for transition model.',
    isMap: false,
    isRepeated: false,
    name: 'transition_model_cross_flow',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'TransitionModelCrossFlow',
    quantityType: 0,
    text: 'Crossflow Treatment',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.TransitionFreeStreamTurbulenceIntensity]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'transitionFreeStreamTurbulenceIntensity',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 37856,
              param: 'transition_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 52077,
              param: 'transition_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.002,
    help: 'The ratio of the root-mean-square velocity fluctuations to the free stream velocity. A higher number indicates a greater degree of turbulence in the incoming flow.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'transition_free_stream_turbulence_intensity',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'TransitionFreeStreamTurbulenceIntensity',
    quantityType: 0,
    text: 'Free Stream Turbulence Intensity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TurbulenceModel]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'turbulenceModel',
    choices: [
      {
        enumNumber: 2762,
        help: '\'Standard\' Spalart-Allmaras one-equation turbulence model.',
        name: 'SPALART_ALLMARAS',
        text: 'Spalart-Allmaras',
      },
      {
        enumNumber: 14358,
        help: 'SST 2003m model.',
        name: 'KOMEGA_SST',
        text: 'SST',
      },
    ],
    cond: {
      list: [
        {
          choice: 35474,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 55751,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 2762,
    help: 'Turbulence models available for Reynolds-averaged Navier-Stokes (RANS) or Detached Eddy Simulation (DES).',
    isMap: false,
    isRepeated: false,
    name: 'turbulence_model',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'TurbulenceModel',
    quantityType: 0,
    text: 'Turbulence Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.QcrSa]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'qcrSa',
    choices: [
      {
        enumNumber: 665,
        help: 'A QCR modification is not applied.',
        name: 'QCR_OFF',
        text: 'Off',
      },
      {
        enumNumber: 11469,
        help: 'The 2000 version of the QCR modification is applied (QCR2000).',
        name: 'QCR2000',
        text: 'QCR2000',
      },
    ],
    cond: {
      choice: 2762,
      param: 'turbulence_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 665,
    help: 'Modification of the traditional linear Boussinesq relation for the Spalart-Allmaras turbulence model via the quadratic constitutive relation (QCR).',
    isMap: false,
    isRepeated: false,
    name: 'qcr_sa',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'QcrSa',
    quantityType: 0,
    text: 'Quadratic Constitutive Relation (QCR)',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.RotationCorrectionSa]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'rotationCorrectionSa',
    choices: [
      {
        enumNumber: 35194,
        help: 'A rotation correction is not applied to the Spalart-Allmaras turbulence model.',
        name: 'ROTATION_CORRECTION_OFF',
        text: 'No Correction',
      },
      {
        enumNumber: 4526,
        help: 'The SA-R form of the rotation correction is applied to the Spalart-Allmaras turbulence model.',
        name: 'ROTATION_CORRECTION_ON',
        text: 'SA-R',
      },
    ],
    cond: {
      choice: 2762,
      param: 'turbulence_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 35194,
    help: 'Apply a rotation correction to the Spalart-Allmaras turbulence model.',
    isMap: false,
    isRepeated: false,
    name: 'rotation_correction_sa',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'RotationCorrectionSa',
    quantityType: 0,
    text: 'Rotation Correction',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.CRot]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cRot',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 4526,
          param: 'rotation_correction_sa',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 2.0,
    help: 'C<sub>rot</sub> constant of the Spalart-Allmaras turbulence model, used for the rotation correction.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_rot',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CRot',
    quantityType: 0,
    text: 'C<sub>rot</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CSaDes]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'CSaDes',
    cond: {
      list: [
        {
          choice: 55751,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.65,
    help: 'C<sub>des</sub> constant of the Spalart-Allmaras turbulence model for Detached Eddy Simulation.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'C_sa_des',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CSaDes',
    quantityType: 0,
    text: 'C<sub>des</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CB1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cB1',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.1355,
    help: 'C<sub>b1</sub> constant of the Spalart-Allmaras turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_b_1',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CB1',
    quantityType: 0,
    text: 'C<sub>b1</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.Sigma]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'sigma',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.6666666666666666,
    help: 'sigma constant of the Spalart-Allmaras turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'sigma',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'Sigma',
    quantityType: 0,
    text: 'σ',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CB2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cB2',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.622,
    help: 'C<sub>b2</sub> constant of the Spalart-Allmaras turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_b_2',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CB2',
    quantityType: 0,
    text: 'C<sub>b2</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.Kappa]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'kappa',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.41,
    help: 'kappa constant of the Spalart-Allmaras turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'kappa',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'Kappa',
    quantityType: 0,
    text: 'κ',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CW2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cW2',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.3,
    help: 'C<sub>w2</sub> constant of the Spalart-Allmaras turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_w_2',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CW2',
    quantityType: 0,
    text: 'C<sub>w2</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CW3]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cW3',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 2.0,
    help: 'C<sub>w3</sub> constant of the Spalart-Allmaras turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_w_3',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CW3',
    quantityType: 0,
    text: 'C<sub>w3</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CV1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cV1',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 7.1,
    help: 'C<sub>v1</sub> constant of the Spalart-Allmaras turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_v_1',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CV1',
    quantityType: 0,
    text: 'C<sub>v1</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CT3]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cT3',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.2,
    help: 'C<sub>t3</sub> constant of the Spalart-Allmaras turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_t_3',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CT3',
    quantityType: 0,
    text: 'C<sub>t3</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CT4]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cT4',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.5,
    help: 'C<sub>t4</sub> constant of the Spalart-Allmaras turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_t_4',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CT4',
    quantityType: 0,
    text: 'C<sub>t4</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CR1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cR1',
    cond: {
      list: [
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.5,
    help: 'C<sub>r1</sub> constant of the Spalart-Allmaras turbulence model, used for roughness.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'c_r_1',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CR1',
    quantityType: 0,
    text: 'C<sub>r1</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.QcrSst]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'qcrSst',
    choices: [
      {
        enumNumber: 30201,
        help: 'A QCR modification is not applied.',
        name: 'SST_QCR_OFF',
        text: 'Off',
      },
      {
        enumNumber: 28154,
        help: 'The 2000 version of the QCR modification is applied (QCR2000).',
        name: 'SST_QCR2000',
        text: 'QCR2000',
      },
    ],
    cond: {
      choice: 14358,
      param: 'turbulence_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 30201,
    help: 'Modification of the traditional linear Boussinesq relation for the SST turbulence model via the quadratic constitutive relation (QCR).',
    isMap: false,
    isRepeated: false,
    name: 'qcr_sst',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'QcrSst',
    quantityType: 0,
    text: 'Quadratic Constitutive Relation (QCR)',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.KatoLaunder]: <ProtoDescriptor.BoolParam>{
    camelCaseName: 'katoLaunder',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 62497,
          param: 'transition_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Modification of the traditional SST source term (SΩ instead of S<sup>2</sup>).',
    isMap: false,
    isRepeated: false,
    name: 'kato_launder',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'KatoLaunder',
    quantityType: 0,
    text: 'Kato-Launder (SST-2003-KLm)',
    type: ProtoDescriptor.ParamType.BOOL,
  },
  [ParamName.TurbulenceModelConstants]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'turbulenceModelConstants',
    choices: [
      {
        enumNumber: 26932,
        help: 'Use default turbulence model constants.',
        name: 'DEFAULT_TURB_CONSTANTS',
        text: 'Default',
      },
      {
        enumNumber: 53831,
        help: 'Enter custom turbulence model constants.',
        name: 'CUSTOM_TURB_CONSTANTS',
        text: 'Custom',
      },
    ],
    cond: {
      list: [
        {
          choice: 35474,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 55751,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 26932,
    help: 'Apply default constants for the RANS turbulence model or choose to customize.',
    isMap: false,
    isRepeated: false,
    name: 'turbulence_model_constants',
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'TurbulenceModelConstants',
    quantityType: 0,
    text: 'Turbulence Model Constants',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.CSstDes1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'CSstDes1',
    cond: {
      list: [
        {
          choice: 55751,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.78,
    help: 'C<sub>des1</sub> constant of the SST turbulence model for Detached Eddy Simulation.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'C_sst_des1',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CSstDes1',
    quantityType: 0,
    text: 'C<sub>des1</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.CSstDes2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'CSstDes2',
    cond: {
      list: [
        {
          choice: 55751,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.61,
    help: 'C<sub>des2</sub> constant of the SST turbulence model for Detached Eddy Simulation.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'C_sst_des2',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'CSstDes2',
    quantityType: 0,
    text: 'C<sub>des2</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SigmaK1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'sigmaK1',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.85,
    help: 'σ<sub>k1</sub> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'sigma_k_1',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'SigmaK1',
    quantityType: 0,
    text: 'σ<sub>k1</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SigmaK2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'sigmaK2',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'σ<sub>k2</sub> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'sigma_k_2',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'SigmaK2',
    quantityType: 0,
    text: 'σ<sub>k2</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SigmaW1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'sigmaW1',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.5,
    help: 'σ<sub>w1</sub> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'sigma_w_1',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'SigmaW1',
    quantityType: 0,
    text: 'σ<sub>w1</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SigmaW2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'sigmaW2',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.856,
    help: 'σ<sub>w2</sub> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'sigma_w_2',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'SigmaW2',
    quantityType: 0,
    text: 'σ<sub>w2</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.Beta1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'beta1',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.075,
    help: 'β<sub>1</sub> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'beta_1',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'Beta1',
    quantityType: 0,
    text: 'β<sub>1</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.Beta2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'beta2',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.0828,
    help: 'β<sub>2</sub> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'beta_2',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'Beta2',
    quantityType: 0,
    text: 'β<sub>2</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.BetaStar]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'betaStar',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.09,
    help: 'β<sup>*</sup> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'beta_star',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'BetaStar',
    quantityType: 0,
    text: 'β<sup>*</sup>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.KappaSst]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'kappaSst',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.41,
    help: 'κ<sub>SST</sub> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'kappa_sst',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'KappaSst',
    quantityType: 0,
    text: 'κ<sub>SST</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.A1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'a1',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.31,
    help: 'a<sub>1</sub> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'a_1',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'A1',
    quantityType: 0,
    text: 'a<sub>1</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.Gamma1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'gamma1',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.5555555555555556,
    help: 'γ<sub>1</sub> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'gamma_1',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'Gamma1',
    quantityType: 0,
    text: 'γ<sub>1</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.Gamma2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'gamma2',
    cond: {
      list: [
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 53831,
          param: 'turbulence_model_constants',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.44,
    help: 'γ<sub>2</sub> constant of the SST turbulence model.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'gamma_2',
    openBound: true,
    parentGroups: [
      'turbulence',
    ],
    pascalCaseName: 'Gamma2',
    quantityType: 0,
    text: 'γ<sub>2</sub>',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.PseudoTimeStepMethod]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'pseudoTimeStepMethod',
    choices: [
      {
        enumNumber: 60768,
        help: 'Compute a pseudo time step from a Courant-Friedrichs-Lewy (CFL) number.',
        name: 'CFL_BASED',
        text: 'CFL-based',
      },
      {
        enumNumber: 32578,
        help: 'Apply a fixed pseudo time step.',
        name: 'FIXED_PSEUDO_TIME_STEP',
        text: 'Fixed Pseudo Time Step',
      },
    ],
    cond: {
      list: [
        {
          list: [
            {
              choice: 64640,
              param: 'flow_behavior',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 33327,
              param: 'time_marching',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 60768,
    help: 'Method for obtaining the pseudo time step in a steady-state simulation or for the inner iterations of the dual time stepping method.',
    isMap: false,
    isRepeated: false,
    name: 'pseudo_time_step_method',
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'PseudoTimeStepMethod',
    quantityType: 0,
    text: 'Pseudo Time Step Method',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.Cfl]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'cfl',
    cond: {
      choice: 60768,
      param: 'pseudo_time_step_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 50.0,
    help: 'Courant-Friedrichs-Lewy (CFL) number.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'cfl',
    openBound: true,
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'Cfl',
    quantityType: 0,
    text: 'CFL',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.LocalTimeStepping]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'localTimeStepping',
    choices: [
      {
        enumNumber: 26520,
        help: 'Enable local time stepping.',
        name: 'LOCAL_TIME_STEPPING_ON',
        text: 'On',
      },
      {
        enumNumber: 60494,
        help: 'Disable local time stepping.',
        name: 'LOCAL_TIME_STEPPING_OFF',
        text: 'Off',
      },
    ],
    cond: {
      list: [
        {
          choice: 60768,
          param: 'pseudo_time_step_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 26520,
    help: 'Compute local time steps in each control volume to accelerate convergence of steady-state simulations or the inner iterations of time-accurate simulations with dual time stepping.',
    isMap: false,
    isRepeated: false,
    name: 'local_time_stepping',
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'LocalTimeStepping',
    quantityType: 0,
    text: 'Local Time Stepping',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.PseudoTimeStepVal]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'pseudoTimeStepVal',
    cond: {
      choice: 32578,
      param: 'pseudo_time_step_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 1e-06,
    help: 'The fixed pseudo time step.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'pseudo_time_step_val',
    openBound: true,
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'PseudoTimeStepVal',
    quantityType: 0,
    text: 'Pseudo Time Step Val',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.JacobianUpdateMethod]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'jacobianUpdateMethod',
    choices: [
      {
        enumNumber: 14966,
        help: 'Compute the jacobian every iteration for \'Jacobian Warmup Threshold\' iterations, then compute every \'Jacobian Update Interval\' iterations',
        name: 'EXPLICIT_INTERVAL_AND_WARMUP',
        text: 'Explicit (Interval + Warmup)',
      },
    ],
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 14966,
    help: 'Method for determining how often to ',
    isMap: false,
    isRepeated: false,
    name: 'jacobian_update_method',
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'JacobianUpdateMethod',
    quantityType: 0,
    text: 'Jacobian Update Method',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.JacobianUpdateInterval]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'jacobianUpdateInterval',
    cond: {
      choice: 14966,
      param: 'jacobian_update_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 1.0,
    help: 'How many iterations in between updating the Jacobian values for implicit solving.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'jacobian_update_interval',
    openBound: true,
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'JacobianUpdateInterval',
    quantityType: 0,
    text: 'Jacobian Update Interval',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.JacobianWarmupThreshold]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'jacobianWarmupThreshold',
    cond: {
      choice: 14966,
      param: 'jacobian_update_method',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 300,
    help: 'How many iterations to update Jacobians every iteration before switching to the specified \'Jacobian Update Interval\'',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'jacobian_warmup_threshold',
    openBound: true,
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'JacobianWarmupThreshold',
    quantityType: 0,
    text: 'Jacobian Warmup Threshold',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.RobustStartup]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'robustStartup',
    choices: [
      {
        enumNumber: 32536,
        help: 'Enable robust startup mode.',
        name: 'ROBUST_STARTUP_ON',
        text: 'On',
      },
      {
        enumNumber: 3695,
        help: 'Disable robust startup mode.',
        name: 'ROBUST_STARTUP_OFF',
        text: 'Off',
      },
    ],
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 64640,
          param: 'flow_behavior',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 32536,
    help: 'Applies a robust startup process during the initial transients of a simulation. Applicable to steady problems only.',
    isMap: false,
    isRepeated: false,
    name: 'robust_startup',
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'RobustStartup',
    quantityType: 0,
    text: 'Robust Startup',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.RobustStartupInitialCfl]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'robustStartupInitialCfl',
    cond: {
      choice: 32536,
      param: 'robust_startup',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 1.0,
    help: 'Initial CFL number for robust startup mode. The CFL is ramped toward the target value during startup.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'robust_startup_initial_cfl',
    openBound: true,
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'RobustStartupInitialCfl',
    quantityType: 0,
    text: 'Robust Startup Initial CFL',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.RobustStartupIterations]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'robustStartupIterations',
    cond: {
      choice: 32536,
      param: 'robust_startup',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 300,
    help: 'Number of iterations over which to apply the robust startup process.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'robust_startup_iterations',
    openBound: true,
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'RobustStartupIterations',
    quantityType: 0,
    text: 'Robust Startup Iterations',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.RelaxFlow]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'relaxFlow',
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Under-relaxation factor in [0,1] applied to the mean flow solution update with each implicit nonlinear iteration. Default of 1.0.',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: 0.0,
    name: 'relax_flow',
    openBound: false,
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'RelaxFlow',
    quantityType: 0,
    text: 'Under-Relaxation Factor - Flow',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.RelaxTurb]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'relaxTurb',
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.5,
    help: 'Under-relaxation factor in [0,1] applied to the turbulence model solution update with each implicit nonlinear iteration. Default of 0.5.',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: 0.0,
    name: 'relax_turb',
    openBound: false,
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'RelaxTurb',
    quantityType: 0,
    text: 'Under-Relaxation Factor - Turbulence',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.UpdateLimitFlow]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'updateLimitFlow',
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.2,
    help: 'Allowable percent change in the mean flow solution variables (pressure, temperature) with each implicit nonlinear iteration. Values in [0,1] with a default of 0.2.',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: 0.0,
    name: 'update_limit_flow',
    openBound: false,
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'UpdateLimitFlow',
    quantityType: 0,
    text: 'Update Limit - Flow',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.UpdateLimitTurb]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'updateLimitTurb',
    cond: {
      list: [
        {
          choice: 53024,
          param: 'relaxation_method',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.99,
    help: 'Allowable percent change in the turbulence solution variables with each implicit nonlinear iteration. Values in [0,1] with a default of 0.99.',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: 0.0,
    name: 'update_limit_turb',
    openBound: false,
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'UpdateLimitTurb',
    quantityType: 0,
    text: 'Update Limit - Turbulence',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SolutionControlsFluidPreset]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'solutionControlsFluidPreset',
    choices: [
      {
        enumNumber: 51359,
        help: 'Solution controls preset is not set.',
        name: 'UNSET_SOLUTION_CONTROLS_FLUID_PRESET',
        text: 'Unset',
      },
      {
        enumNumber: 57802,
        help: 'Conservative performance settings that emphasize robustness (ability to converge).',
        name: 'DEFAULT_SOLUTION_CONTROLS_FLUID',
        text: 'Default (Conservative)',
      },
      {
        enumNumber: 11033,
        help: 'Reasonable compromise between speed and robustness, up to 2 times faster than the default settings.',
        name: 'INTERMEDIATE_SOLUTION_CONTROLS_FLUID',
        text: 'Intermediate Performance',
      },
      {
        enumNumber: 20860,
        help: 'Aggressive settings further biased towards speed, up to 3 times faster than the default settings (these may not be suitable for all problems).',
        name: 'AGGRESSIVE_SOLUTION_CONTROLS_FLUID',
        text: 'Aggressive Performance',
      },
      {
        enumNumber: 6315,
        help: 'Custom solution controls.',
        name: 'CUSTOM_SOLUTION_CONTROLS_FLUID',
        text: 'Custom',
      },
    ],
    defaultValue: 57802,
    help: 'Select suggested control settings or allow a custom choice. In general, assume a trade-off between speed and robustness (i.e. the ability to converge).',
    isMap: false,
    isRepeated: false,
    name: 'solution_controls_fluid_preset',
    parentGroups: [
      'solution_controls_fluid',
    ],
    pascalCaseName: 'SolutionControlsFluidPreset',
    quantityType: 0,
    text: 'Apply Solution Controls Fluid Preset',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.AdjointSecondOrderDamping]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'adjointSecondOrderDamping',
    cond: {
      list: [
        {
          choice: 58692,
          param: 'float_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              cond: {
                tagName: 'heat',
                type: ProtoDescriptor.CondType.TAG,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Simplify the adjoint equations (at the expense of accuracy) by reducing the blend between first-order and second-order convective schemes used in the primal simulation.',
    isMap: false,
    isRepeated: false,
    maxVal: 1,
    minVal: 0,
    name: 'adjoint_second_order_damping',
    openBound: false,
    parentGroups: [
      'adjoint_controls_fluid',
    ],
    pascalCaseName: 'AdjointSecondOrderDamping',
    quantityType: 0,
    text: 'Adjoint Second-Order Damping Factor',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.AdjointFrozenTurbulence]: <ProtoDescriptor.BoolParam>{
    camelCaseName: 'adjointFrozenTurbulence',
    cond: {
      list: [
        {
          list: [
            {
              choice: 58692,
              param: 'float_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              list: [
                {
                  cond: {
                    tagName: 'heat',
                    type: ProtoDescriptor.CondType.TAG,
                  },
                  type: ProtoDescriptor.CondType.NOT,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          choice: 35474,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Simplify the adjoint equations (at the expense of accuracy) by assuming that eddy viscosity does not depend on the turbulence variables.',
    isMap: false,
    isRepeated: false,
    name: 'adjoint_frozen_turbulence',
    parentGroups: [
      'adjoint_controls_fluid',
    ],
    pascalCaseName: 'AdjointFrozenTurbulence',
    quantityType: 0,
    text: 'Frozen Turbulence',
    type: ProtoDescriptor.ParamType.BOOL,
  },
  [ParamName.AdjointFrozenTransition]: <ProtoDescriptor.BoolParam>{
    camelCaseName: 'adjointFrozenTransition',
    cond: {
      list: [
        {
          list: [
            {
              list: [
                {
                  choice: 58692,
                  param: 'float_type',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  list: [
                    {
                      cond: {
                        tagName: 'heat',
                        type: ProtoDescriptor.CondType.TAG,
                      },
                      type: ProtoDescriptor.CondType.NOT,
                    },
                  ],
                  type: ProtoDescriptor.CondType.ANY,
                },
              ],
              type: ProtoDescriptor.CondType.ALL,
            },
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          cond: {
            param: 'adjoint_frozen_turbulence',
            type: ProtoDescriptor.CondType.TRUEFALSE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        {
          list: [
            {
              choice: 20304,
              param: 'transition_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 37856,
              param: 'transition_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 52077,
              param: 'transition_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Simplify the adjoint equations (at the expense of accuracy) by assuming that turbulence variables are not affected by turbulence intermittency (γ).',
    isMap: false,
    isRepeated: false,
    name: 'adjoint_frozen_transition',
    parentGroups: [
      'adjoint_controls_fluid',
    ],
    pascalCaseName: 'AdjointFrozenTransition',
    quantityType: 0,
    text: 'Frozen Transition',
    type: ProtoDescriptor.ParamType.BOOL,
  },
  [ParamName.ConvectiveSchemesDensityBased]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'convectiveSchemesDensityBased',
    choices: [
      {
        enumNumber: 40881,
        help: 'Flux Difference Splitting scheme.',
        name: 'ROE',
        text: 'FDS',
      },
      {
        cond: {
          choice: 37484,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        enumNumber: 46617,
        help: 'Low-Dissipation Low-Dispersion (LD2) scheme.',
        name: 'LD2',
        text: 'LD2',
      },
      {
        cond: {
          choice: 37484,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        enumNumber: 52649,
        help: 'Entropy-Stable discretization.',
        name: 'EC2',
        text: 'EC2',
      },
      {
        cond: {
          list: [
            {
              cond: {
                choice: 8775,
                param: 'viscous_model',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
            {
              list: [
                {
                  choice: 28817,
                  param: 'density_relationship',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 41777,
                  param: 'density_relationship',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        enumNumber: 43767,
        help: 'Rhie-Chow method.',
        name: 'RHIE_CHOW',
        text: 'Rhie-Chow',
      },
    ],
    defaultValue: 40881,
    help: 'Type of scheme for discretizing the convective terms of the fluid equations.',
    isMap: false,
    isRepeated: false,
    name: 'convective_schemes_density_based',
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'ConvectiveSchemesDensityBased',
    quantityType: 0,
    text: 'Convective Scheme',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.EntropyEpsilon]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'entropyEpsilon',
    cond: {
      choice: 40881,
      param: 'convective_schemes_density_based',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 2.0,
    help: 'Epsilon factor for the entropy fix of Harten.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'entropy_epsilon',
    openBound: true,
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'EntropyEpsilon',
    quantityType: 0,
    text: 'Entropy Epsilon',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.Preconditioning]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'preconditioning',
    choices: [
      {
        enumNumber: 64776,
        help: 'Enable low-speed preconditioning.',
        name: 'PRECONDITIONING_ON',
        text: 'On',
      },
      {
        enumNumber: 41590,
        help: 'Disable low-speed preconditioning.',
        name: 'PRECONDITIONING_OFF',
        text: 'Off',
      },
    ],
    cond: {
      list: [
        {
          choice: 37484,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 40881,
          param: 'convective_schemes_density_based',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 33327,
              param: 'time_marching',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 64640,
              param: 'flow_behavior',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 64776,
    help: 'Apply low-speed preconditioning to obtain Mach number-independent solutions for ideal gases.',
    isMap: false,
    isRepeated: false,
    name: 'preconditioning',
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'Preconditioning',
    quantityType: 0,
    text: 'Low-Speed Preconditioning',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.FdsMinLowDissipation]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'fdsMinLowDissipation',
    cond: {
      list: [
        {
          choice: 55751,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 40881,
          param: 'convective_schemes_density_based',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.05,
    help: 'Lower bound on the factor multiplying the FDS dissipation term for scale-resolving problems in [0,1].',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: 0.0,
    name: 'fds_min_low_dissipation',
    openBound: false,
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'FdsMinLowDissipation',
    quantityType: 0,
    text: 'FDS Low Dissipation Bound',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SkewSymmetricFormulation]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'skewSymmetricFormulation',
    choices: [
      {
        enumNumber: 8382,
        help: 'Praveen Chandrasekhar\'s EC2 formulation.',
        name: 'CHANDRASEKHAR_EC2',
        text: 'Chandrasekhar EC2',
      },
      {
        enumNumber: 7955,
        help: 'Praveen Chandrasekhar\'s EC1 formulation.',
        name: 'CHANDRASEKHAR_EC1',
        text: 'Chandrasekhar EC1',
      },
      {
        enumNumber: 7972,
        help: 'Kuya et al\'s formulation.',
        name: 'KUYA',
        text: 'Kuya',
      },
    ],
    cond: {
      choice: 52649,
      param: 'convective_schemes_density_based',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 8382,
    help: 'Choice among skew-symmetric formulations.',
    isMap: false,
    isRepeated: false,
    name: 'skew_symmetric_formulation',
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'SkewSymmetricFormulation',
    quantityType: 0,
    text: 'Skew Symmetric Formulation',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ArtificialViscosityModel]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'artificialViscosityModel',
    choices: [
      {
        enumNumber: 18186,
        help: 'No artificial viscosity model.',
        name: 'NO_MODEL',
        text: 'No Model',
      },
      {
        enumNumber: 32785,
        help: 'Localized artificial diffusivity (LAD) model.',
        name: 'LAD',
        text: 'LAD',
      },
    ],
    cond: {
      list: [
        {
          choice: 46617,
          param: 'convective_schemes_density_based',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 52649,
          param: 'convective_schemes_density_based',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 18186,
    help: 'Artificial viscosity for shock and interface capturing.',
    isMap: false,
    isRepeated: false,
    name: 'artificial_viscosity_model',
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'ArtificialViscosityModel',
    quantityType: 0,
    text: 'Artificial Viscosity Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.AlphaHybrid]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'alphaHybrid',
    cond: {
      list: [
        {
          choice: 46617,
          param: 'convective_schemes_density_based',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 43767,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 1.0,
    help: 'Upwind weight in the hybrid central-upwind scheme in [0,1].',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: 0.0,
    name: 'alpha_hybrid',
    openBound: false,
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'AlphaHybrid',
    quantityType: 0,
    text: 'Hybrid Scheme Alpha',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.UpwindSchemeOrder]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'upwindSchemeOrder',
    choices: [
      {
        enumNumber: 33909,
        help: 'Second-order accuracy.',
        name: 'SECOND',
        text: 'Second-Order',
      },
      {
        enumNumber: 9854,
        help: 'First-order accuracy.',
        name: 'FIRST',
        text: 'First-Order',
      },
    ],
    defaultValue: 33909,
    help: 'Spatial order of accuracy of the convective scheme used for the fluid equations.',
    isMap: false,
    isRepeated: false,
    name: 'upwind_scheme_order',
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'UpwindSchemeOrder',
    quantityType: 0,
    text: 'Convective Scheme Accuracy',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.RobustDissipation]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'robustDissipation',
    choices: [
      {
        enumNumber: 40461,
        help: 'Disable robust dissipation.',
        name: 'ROBUST_DISS_OFF',
        text: 'Off',
      },
      {
        enumNumber: 57825,
        help: 'Enable robust dissipation.',
        name: 'ROBUST_DISS_ON',
        text: 'On',
      },
    ],
    cond: {
      list: [
        {
          choice: 33909,
          param: 'upwind_scheme_order',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 40881,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 43767,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        {
          list: [
            {
              choice: 8775,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 48078,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 40461,
    help: 'Use a form of dissipation that improves robustness but that may reduce accuracy.',
    isMap: false,
    isRepeated: false,
    name: 'robust_dissipation',
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'RobustDissipation',
    quantityType: 0,
    text: 'Robust Dissipation',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.Limiter]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'limiter',
    choices: [
      {
        enumNumber: 34181,
        help: 'Do not apply a slope limiter.',
        name: 'NO_LIMITER',
        text: 'No Limiter',
      },
      {
        enumNumber: 28008,
        help: 'Apply cell-based limiting with the limiter function of Venkatakrishnan.',
        name: 'INVARIANT_VENKATAKRISHNAN_CV',
        text: 'Venkatakrishnan-Wang',
      },
      {
        enumNumber: 30237,
        help: 'Apply face-based limiting with the limiter function of Van Albada. This limiter is more conservative than Venkatakrishnan-Wang.',
        name: 'VAN_ALBADA_FACE',
        text: 'Van Albada',
      },
      {
        enumNumber: 33708,
        help: 'This option was deprecated in favor of an implementation of the same method that guarantees coordinate-system invariance.',
        name: 'VENKATAKRISHNAN_CV',
        text: 'LEGACY Venkatakrishnan-Wang',
      },
    ],
    cond: {
      list: [
        {
          choice: 33909,
          param: 'upwind_scheme_order',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 40881,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 46617,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 43767,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 28008,
    help: 'Apply a slope limiter for second-order upwind schemes. This tends to increase robustness at the expense of solution time and higher dissipation in regions of the flow with sharp gradients. For this reason, it may be helpful to increase gradient blending parameters when using limiters.',
    isMap: false,
    isRepeated: false,
    name: 'limiter',
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'Limiter',
    quantityType: 0,
    text: 'Limiter',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.LimiterKappa]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'limiterKappa',
    cond: {
      list: [
        {
          choice: 33708,
          param: 'limiter',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 28008,
          param: 'limiter',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 57825,
          param: 'robust_dissipation',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 0.1,
    help: 'Parameter defining smooth flow regions where the limiter is disabled, and also where less robust dissipation is applied (if enabled). Higher values reduce limiting and/or dissipation.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'limiter_kappa',
    openBound: true,
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'LimiterKappa',
    quantityType: 0,
    text: 'Limiter Constant',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.UmusclChi]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'umusclChi',
    cond: {
      list: [
        {
          choice: 33909,
          param: 'upwind_scheme_order',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          cond: {
            choice: 57825,
            param: 'robust_dissipation',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        {
          list: [
            {
              choice: 40881,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 46617,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 43767,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Chi parameter of the U-MUSCL variable extrapolation scheme in (-1,1).',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: -1.0,
    name: 'umuscl_chi',
    openBound: false,
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'UmusclChi',
    quantityType: 0,
    text: 'U-MUSCL Chi',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.AlphaLd2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'alphaLd2',
    cond: {
      list: [
        {
          choice: 33909,
          param: 'upwind_scheme_order',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 46617,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 52649,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Parameter in range [0,1], generally 0 is more robust and 1 more accurate, thus it should be used on high quality grids with gentle stretching. On uniform meshes 0 gives a 2nd order reconstruction, and 2/3 gives a 4th order reconstruction. With the value 1, 2nd order is also obtained on non uniform meshes.',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: 0.0,
    name: 'alpha_ld2',
    openBound: false,
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'AlphaLd2',
    quantityType: 0,
    text: 'LD2 Face Recontruction Parameter',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.OrderBlend]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'orderBlend',
    cond: {
      list: [
        {
          choice: 33909,
          param: 'upwind_scheme_order',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 40881,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 46617,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 43767,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Blend factor in [0,1] between a first-order and second-order convective scheme. A value of 0 results in a first-order scheme, and a value of 1.0 results in a second-order scheme. 1.0 by default. A value less than 1.0 may improve robustness at the cost of accuracy compared to a pure second-order scheme.',
    isMap: false,
    isRepeated: false,
    maxVal: 1.0,
    minVal: 0.0,
    name: 'order_blend',
    openBound: false,
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'OrderBlend',
    quantityType: 0,
    text: 'First/Second-Order Blend Factor',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SpatialDiscretizationFluidPreset]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'spatialDiscretizationFluidPreset',
    choices: [
      {
        enumNumber: 49649,
        help: 'Spatial discretization preset is not set.',
        name: 'UNSET_SPATIAL_DISCRETIZATION_FLUID_PRESET',
        text: 'Unset',
      },
      {
        enumNumber: 38654,
        help: 'Default settings, good compromise between accuracy and robustness (ability to converge).',
        name: 'DEFAULT_SPATIAL_DISCRETIZATION_FLUID',
        text: 'Default',
      },
      {
        enumNumber: 2519,
        help: 'Settings biased towards robustness over maximum accuracy (relative to default).',
        name: 'CONSERVATIVE_SPATIAL_DISCRETIZATION_FLUID',
        text: 'Conservative',
      },
      {
        enumNumber: 59227,
        help: 'Settings that emphasize accuracy (relative to default).',
        name: 'HIGH_ACCURACY_SPATIAL_DISCRETIZATION_FLUID',
        text: 'High Accuracy',
      },
      {
        enumNumber: 10562,
        help: 'Custom discretization settings.',
        name: 'CUSTOM_SPATIAL_DISCRETIZATION_FLUID',
        text: 'Custom',
      },
    ],
    defaultValue: 38654,
    help: 'Select suggested control settings or allow a custom choice. In general, assume a trade-off between accuracy and robustness (i.e. the ability to converge).',
    isMap: false,
    isRepeated: false,
    name: 'spatial_discretization_fluid_preset',
    parentGroups: [
      'spatial_discretization_fluid',
    ],
    pascalCaseName: 'SpatialDiscretizationFluidPreset',
    quantityType: 0,
    text: 'Apply Spatial Discretization Fluid Preset',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.PhysicalBoundary]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'physicalBoundary',
    choices: [
      {
        enumNumber: 64731,
        help: 'Solid wall boundary condition.',
        name: 'WALL',
        text: 'Wall',
      },
      {
        enumNumber: 46673,
        help: 'Inlet boundary condition.',
        name: 'INLET',
        text: 'Inlet',
      },
      {
        enumNumber: 55266,
        help: 'Outlet boundary condition.',
        name: 'OUTLET',
        text: 'Outlet',
      },
      {
        enumNumber: 13215,
        help: 'Symmetry boundary condition.',
        name: 'SYMMETRY',
        text: 'Symmetry',
      },
      {
        enumNumber: 32160,
        help: 'Far-field boundary condition.',
        name: 'FARFIELD',
        text: 'Far-field',
      },
      {
        enumNumber: 51513,
        help: 'Overset boundary condition.',
        name: 'OVERSET',
        text: 'Overset',
      },
    ],
    defaultValue: 64731,
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'physical_boundary',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'PhysicalBoundary',
    quantityType: 0,
    text: 'Physical Boundary',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.WallMomentum]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'wallMomentum',
    choices: [
      {
        cond: {
          cond: {
            choice: 8775,
            param: 'viscous_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        enumNumber: 11063,
        help: 'Apply a no-slip condition at the wall surface.',
        name: 'NO_SLIP',
        text: 'No-slip',
      },
      {
        enumNumber: 3491,
        help: 'Apply a slip (flow tangency) condition at the wall surface.',
        name: 'SLIP',
        text: 'Slip',
      },
      {
        cond: {
          list: [
            {
              choice: 2762,
              param: 'turbulence_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 65537,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 14358,
              param: 'turbulence_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        enumNumber: 53449,
        help: 'Apply a wall model at the wall surface.',
        name: 'WALL_MODEL',
        text: 'Wall Model',
      },
    ],
    cond: {
      choice: 64731,
      param: 'physical_boundary',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 11063,
    help: 'Condition applied to the momentum equations at a solid wall boundary.',
    isMap: false,
    isRepeated: false,
    name: 'wall_momentum',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'WallMomentum',
    quantityType: 0,
    text: 'Momentum',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.RoughnessControl]: <ProtoDescriptor.BoolParam>{
    camelCaseName: 'roughnessControl',
    cond: {
      list: [
        {
          list: [
            {
              cond: {
                choice: 48078,
                param: 'viscous_model',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
            {
              cond: {
                choice: 8775,
                param: 'viscous_model',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
            {
              choice: 64731,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              cond: {
                choice: 3491,
                param: 'wall_momentum',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          list: [
            {
              choice: 65537,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 64731,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 53449,
              param: 'wall_momentum',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Turn roughness settings on or off.',
    isMap: false,
    isRepeated: false,
    name: 'roughness_control',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'RoughnessControl',
    quantityType: 0,
    text: 'Roughness Control',
    type: ProtoDescriptor.ParamType.BOOL,
  },
  [ParamName.EquivalentSandGrainRoughness]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'equivalentSandGrainRoughness',
    cond: {
      list: [
        {
          list: [
            {
              cond: {
                choice: 48078,
                param: 'viscous_model',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
            {
              cond: {
                choice: 8775,
                param: 'viscous_model',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
            {
              choice: 64731,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              cond: {
                choice: 3491,
                param: 'wall_momentum',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          list: [
            {
              choice: 65537,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 64731,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 53449,
              param: 'wall_momentum',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Equivalent sand-grain roughness of the wall.',
    isMap: false,
    isRepeated: false,
    maxVal: 0.005,
    minVal: 0,
    name: 'equivalent_sand_grain_roughness',
    openBound: false,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'EquivalentSandGrainRoughness',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Equivalent Sand-Grain Roughness',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.WallEnergy]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'wallEnergy',
    choices: [
      {
        enumNumber: 814,
        help: 'Apply a fixed heat flux at the wall surface.',
        name: 'FIXED_HEAT_FLUX',
        text: 'Fixed Heat Flux',
      },
      {
        cond: {
          cond: {
            choice: 8775,
            param: 'viscous_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        enumNumber: 22055,
        help: 'Apply a fixed temperature at the wall surface.',
        name: 'FIXED_TEMPERATURE',
        text: 'Fixed Temperature',
      },
    ],
    cond: {
      list: [
        {
          choice: 64731,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 37484,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 65537,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 48078,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 814,
    help: 'Condition applied to the energy equation at a solid wall boundary.',
    isMap: false,
    isRepeated: false,
    name: 'wall_energy',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'WallEnergy',
    quantityType: 0,
    text: 'Energy',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.WallMovementTranslation]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'wallMovementTranslation',
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
        {
          choice: 64731,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Translational velocity (x,y,z) of the wall surface.',
    isMap: false,
    isRepeated: false,
    name: 'wall_movement_translation',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'WallMovementTranslation',
    quantityType: QuantityPb.QuantityType.VELOCITY,
    text: 'Translation',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.WallMovementRotationCenter]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'wallMovementRotationCenter',
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
        {
          choice: 64731,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Center of rotation (x,y,z) of the rotational velocity of the wall surface.',
    isMap: false,
    isRepeated: false,
    name: 'wall_movement_rotation_center',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'WallMovementRotationCenter',
    quantityType: QuantityPb.QuantityType.LENGTH,
    text: 'Rotation Center',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.WallMovementAngularVelocity]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'wallMovementAngularVelocity',
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
        {
          choice: 64731,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Rotational velocity about the (x,y,z)-axes from the rotational center of the wall surface.',
    isMap: false,
    isRepeated: false,
    name: 'wall_movement_angular_velocity',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'WallMovementAngularVelocity',
    quantityType: QuantityPb.QuantityType.ANGULAR_VELOCITY,
    text: 'Angular Velocity',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.InletMomentum]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'inletMomentum',
    choices: [
      {
        enumNumber: 55562,
        help: 'Specify total pressure.',
        name: 'TOTAL_PRESSURE_INLET',
        text: 'Total Pressure',
      },
      {
        enumNumber: 17311,
        help: 'Specify mass flow rate.',
        name: 'MASS_FLOW_INLET',
        text: 'Mass Flow',
      },
      {
        cond: {
          list: [
            {
              choice: 28817,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        enumNumber: 58910,
        help: 'Specify the velocity magnitude.',
        name: 'VELOCITY_INLET',
        text: 'Velocity Magnitude',
      },
      {
        cond: {
          list: [
            {
              choice: 28817,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        enumNumber: 66019,
        help: 'Specify the components of the velocity vector.',
        name: 'VELOCITY_COMPONENTS_INLET',
        text: 'Velocity Components',
      },
      {
        enumNumber: 56265,
        help: 'Specify the relation between the fan pressure rise and the volume flow rate.',
        name: 'FAN_CURVE_INLET',
        text: 'Fan Curve',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 22425,
        help: 'Specify the mach number and state at inlet.',
        name: 'MACH_INLET',
        text: 'Mach Inlet',
      },
    ],
    cond: {
      choice: 46673,
      param: 'physical_boundary',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 55562,
    help: 'Method of defining the inlet momentum conditions.',
    isMap: false,
    isRepeated: false,
    name: 'inlet_momentum',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'InletMomentum',
    quantityType: 0,
    text: 'Momentum',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.InletVelocityMagnitude]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'inletVelocityMagnitude',
    cond: {
      list: [
        {
          choice: 58910,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 46673,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Velocity magnitude at the inlet boundary.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'inlet_velocity_magnitude',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'InletVelocityMagnitude',
    quantityType: QuantityPb.QuantityType.VELOCITY,
    text: 'Velocity Magnitude',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InletVelocityMagnitudeCol]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'inletVelocityMagnitudeCol',
    cond: {
      list: [
        {
          choice: 58910,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          param: 'profile_bc',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Index of the column of the table in profile_bc_data for inlet_velocity_magnitude',
    isMap: false,
    isRepeated: false,
    maxVal: 100000.0,
    minVal: 0,
    name: 'inlet_velocity_magnitude_col',
    openBound: false,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'InletVelocityMagnitudeCol',
    quantityType: 0,
    text: 'Velocity Magnitude Column',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.InletVelocityComponents]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'inletVelocityComponents',
    cond: {
      list: [
        {
          choice: 66019,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 46673,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: {
      x: 1.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Vector (x,y,z) defining the velocity vector.',
    isMap: false,
    isRepeated: false,
    name: 'inlet_velocity_components',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'InletVelocityComponents',
    quantityType: 0,
    text: 'Velocity Components',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.TotalPressureCol]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'totalPressureCol',
    cond: {
      list: [
        {
          choice: 55562,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          param: 'profile_bc',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Index of the column of the table in profile_bc_data for total pressure',
    isMap: false,
    isRepeated: false,
    maxVal: 100000.0,
    minVal: 0,
    name: 'total_pressure_col',
    openBound: false,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'TotalPressureCol',
    quantityType: 0,
    text: 'Total Pressure Column',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.MassFlowRate]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'massFlowRate',
    cond: {
      list: [
        {
          choice: 17311,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 46673,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Mass flow rate at the inlet. The prescribed mass flow is imposed over the aggregate area of all surfaces listed for the inlet boundary condition. To prescribe mass flow on a per-surface basis, create multiple mass flow inlet boundary conditions, each with a single surface.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'mass_flow_rate',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'MassFlowRate',
    quantityType: QuantityPb.QuantityType.MASS_FLOW,
    text: 'Mass Flow Rate',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TotalPressure]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'totalPressure',
    cond: {
      list: [
        {
          list: [
            {
              choice: 55562,
              param: 'inlet_momentum',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 46673,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          choice: 56265,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 101325,
    help: 'Total pressure at the boundary relative to the material reference pressure.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'total_pressure',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'TotalPressure',
    quantityType: QuantityPb.QuantityType.TOTAL_PRESSURE,
    text: 'Total Pressure (Gauge)',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.DirectionSpecification]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'directionSpecification',
    choices: [
      {
        enumNumber: 54246,
        help: 'Impose a flow direction normal to the inlet boundary toward the interior of the domain.',
        name: 'NORMAL_TO_BOUNDARY',
        text: 'Normal to Boundary',
      },
      {
        cond: {
          cond: {
            choice: 56265,
            param: 'inlet_momentum',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        enumNumber: 27183,
        help: 'Specify a vector for the inlet flow direction.',
        name: 'DIRECTION_VECTOR',
        text: 'Vector',
      },
    ],
    cond: {
      list: [
        {
          choice: 46673,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          cond: {
            choice: 66019,
            param: 'inlet_momentum',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 54246,
    help: 'Method of defining the flow direction at the inlet.',
    isMap: false,
    isRepeated: false,
    name: 'direction_specification',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'DirectionSpecification',
    quantityType: 0,
    text: 'Flow Direction',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.FlowDirection]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'flowDirection',
    cond: {
      list: [
        {
          choice: 27183,
          param: 'direction_specification',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 46673,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: {
      x: 1.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Vector (x,y,z) defining the flow direction. Automatically scaled to a unit vector internally.',
    isMap: false,
    isRepeated: false,
    name: 'flow_direction',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FlowDirection',
    quantityType: 0,
    text: 'Flow Direction',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.InletEnergy]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'inletEnergy',
    choices: [
      {
        enumNumber: 63646,
        help: 'Specify total temperature.',
        name: 'TOTAL_TEMPERATURE_INLET',
        text: 'Total Temperature',
      },
    ],
    cond: {
      list: [
        {
          choice: 46673,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          cond: {
            choice: 22425,
            param: 'inlet_momentum',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        {
          list: [
            {
              choice: 37484,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 63646,
    help: 'Method of defining the inlet energy conditions.',
    isMap: false,
    isRepeated: false,
    name: 'inlet_energy',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'InletEnergy',
    quantityType: 0,
    text: 'Energy',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.TotalTemperature]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'totalTemperature',
    cond: {
      list: [
        {
          choice: 63646,
          param: 'inlet_energy',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 46673,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 37484,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 300,
    help: 'Total temperature at the inlet boundary.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'total_temperature',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'TotalTemperature',
    quantityType: QuantityPb.QuantityType.TOTAL_TEMPERATURE,
    text: 'Total Temperature',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TotalTemperatureCol]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'totalTemperatureCol',
    cond: {
      list: [
        {
          choice: 63646,
          param: 'inlet_energy',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          param: 'profile_bc',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
        {
          list: [
            {
              choice: 37484,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Index of the column of the table in profile_bc_data for Total Temperature',
    isMap: false,
    isRepeated: false,
    maxVal: 100000.0,
    minVal: 0,
    name: 'total_temperature_col',
    openBound: false,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'TotalTemperatureCol',
    quantityType: 0,
    text: 'Total Temperature Column',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.OutletStrategy]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'outletStrategy',
    choices: [
      {
        enumNumber: 56304,
        help: 'Specify an outlet static pressure.',
        name: 'OUTLET_PRESSURE',
        text: 'Outlet Pressure',
      },
      {
        enumNumber: 21851,
        help: 'Specify a target mass flow rate. Warning: this strategy will not work if the flow becomes choked or if it is fixed by any other flow constraint (e.g. a velocity inlet).',
        name: 'OUTLET_TARGET_MASS_FLOW_RATE',
        text: 'Target Mass Flow Rate',
      },
      {
        cond: {
          choice: 37484,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        enumNumber: 14338,
        help: 'Specify a target mass flow rate corrected for given reference temperature and pressure (&#856;m<sub>corr</sub> = &#856;m <sup>&radic; T<sub>0</sub></sup> &frasl; <sub>&radic;T<sub>ref</sub></sub> <sup>P<sub>ref</sub></sup> &frasl; <sub>P<sub>0</sub></sub>).',
        name: 'OUTLET_TARGET_CORRECTED_MASS_FLOW_RATE',
        text: 'Target Corrected Mass Flow Rate',
      },
      {
        enumNumber: 44920,
        help: 'Specify the relation between the fan pressure rise and the volume flow rate.',
        name: 'FAN_CURVE_OUTLET',
        text: 'Fan Curve',
      },
    ],
    cond: {
      choice: 55266,
      param: 'physical_boundary',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 56304,
    help: 'Outlet strategy.',
    isMap: false,
    isRepeated: false,
    name: 'outlet_strategy',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'OutletStrategy',
    quantityType: 0,
    text: 'Outlet Strategy',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.OutletTargetMassFlowRate]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'outletTargetMassFlowRate',
    cond: {
      list: [
        {
          choice: 55266,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 21851,
              param: 'outlet_strategy',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 14338,
              param: 'outlet_strategy',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Target mass flow rate (or corrected mass flow rate).',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'outlet_target_mass_flow_rate',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'OutletTargetMassFlowRate',
    quantityType: QuantityPb.QuantityType.MASS_FLOW,
    text: 'Target Mass Flow Rate',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.OutletReferencePressure]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'outletReferencePressure',
    cond: {
      list: [
        {
          choice: 55266,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 14338,
          param: 'outlet_strategy',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 101325.0,
    help: 'Absolute total pressure used to compute the corrected mass flow target.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'outlet_reference_pressure',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'OutletReferencePressure',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'Reference Pressure (Absolute)',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.OutletReferenceTemperature]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'outletReferenceTemperature',
    cond: {
      list: [
        {
          choice: 55266,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 14338,
          param: 'outlet_strategy',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 288.15,
    help: 'Total temperature used to compute the corrected mass flow target.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'outlet_reference_temperature',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'OutletReferenceTemperature',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'Reference Temperature',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.OutletPressure]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'outletPressure',
    cond: {
      list: [
        {
          choice: 55266,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 101325.0,
    help: 'Static pressure at the boundary relative to the material reference pressure. Also used as initial estimate to establish the target mass flow rate if requested.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'outlet_pressure',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'OutletPressure',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'Static Pressure (Gauge)',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.FanCurveTableData]: <ProtoDescriptor.RectilinearTableParam>{
    axis: [
      {
        quantity: QuantityPb.QuantityType.VOLUME_FLOW_RATE,
      },
    ],
    camelCaseName: 'fanCurveTableData',
    cond: {
      list: [
        {
          choice: 56265,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 44920,
          param: 'outlet_strategy',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 51439,
          param: 'actuator_disk_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Correlation between fan pressure rise and volume flow rate',
    isMap: false,
    isRepeated: false,
    name: 'fan_curve_table_data',
    parentGroups: [
      'boundary_conditions_fluid',
      'physical_behavior',
    ],
    pascalCaseName: 'FanCurveTableData',
    quantityType: 0,
    tableRecords: [
      {
        quantity: QuantityPb.QuantityType.PRESSURE_RISE,
      },
    ],
    text: 'Table Data for Fan Curve',
    type: ProtoDescriptor.ParamType.TABLE,
  },
  [ParamName.HeadLossCoefficient]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'headLossCoefficient',
    cond: {
      list: [
        {
          choice: 44920,
          param: 'outlet_strategy',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 56265,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 51439,
          param: 'actuator_disk_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Fraction of dynamic pressure lost through the fan.',
    isMap: false,
    isRepeated: false,
    maxVal: 1,
    minVal: 0,
    name: 'head_loss_coefficient',
    openBound: false,
    parentGroups: [
      'boundary_conditions_fluid',
      'physical_behavior',
    ],
    pascalCaseName: 'HeadLossCoefficient',
    quantityType: 0,
    text: 'Head Loss Coefficient',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.OutletPressureConstraint]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'outletPressureConstraint',
    choices: [
      {
        enumNumber: 37894,
        help: 'Pressure values are imposed locally at each mesh face, thereby imposing a fixed pressure profile (e.g. uniform).',
        name: 'OUTLET_LOCAL_CONSTRAINT',
        text: 'Local',
      },
      {
        cond: {
          cond: {
            choice: 26616,
            param: 'fluid_type',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
        enumNumber: 33055,
        help: 'The area-averaged pressure is imposed while local values can deviate. This allows a pressure profile to develop naturally, but can be less numerically stable than the Local constraint mode.',
        name: 'OUTLET_AVERAGE_CONSTRAINT',
        text: 'Average',
      },
    ],
    cond: {
      list: [
        {
          choice: 55266,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          cond: {
            choice: 44920,
            param: 'outlet_strategy',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          type: ProtoDescriptor.CondType.NOT,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 37894,
    help: 'Mode of imposing pressure at the outlet.',
    isMap: false,
    isRepeated: false,
    name: 'outlet_pressure_constraint',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'OutletPressureConstraint',
    quantityType: 0,
    text: 'Pressure Constraint Mode',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.FarfieldMachNumber]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'farfieldMachNumber',
    cond: {
      list: [
        {
          list: [
            {
              choice: 32160,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 37484,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          choice: 22425,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 0.5,
    help: 'Mach number at the boundary.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'farfield_mach_number',
    openBound: false,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FarfieldMachNumber',
    quantityType: QuantityPb.QuantityType.MACH,
    text: 'Mach Number',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.FarfieldVelocityMagnitude]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'farfieldVelocityMagnitude',
    cond: {
      list: [
        {
          choice: 32160,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 28817,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Velocity magnitude at the far-field boundary.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'farfield_velocity_magnitude',
    openBound: false,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FarfieldVelocityMagnitude',
    quantityType: QuantityPb.QuantityType.VELOCITY,
    text: 'Velocity Magnitude',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.FarfieldPressure]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'farfieldPressure',
    cond: {
      list: [
        {
          choice: 32160,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 22425,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 101325,
    help: 'Static pressure at the boundary relative to the material reference pressure.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'farfield_pressure',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FarfieldPressure',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'Static Pressure (Gauge)',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.FarfieldTemperature]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'farfieldTemperature',
    cond: {
      list: [
        {
          list: [
            {
              choice: 32160,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              list: [
                {
                  choice: 37484,
                  param: 'density_relationship',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 41777,
                  param: 'density_relationship',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          choice: 22425,
          param: 'inlet_momentum',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 288.15,
    help: 'Static temperature at the boundary.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'farfield_temperature',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FarfieldTemperature',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'Temperature',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.FarFieldFlowDirectionSpecification]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'farFieldFlowDirectionSpecification',
    choices: [
      {
        enumNumber: 30342,
        help: 'Specify a vector for the far-field flow direction.',
        name: 'FARFIELD_DIRECTION',
        text: 'Vector',
      },
      {
        enumNumber: 62593,
        help: 'Specify body axes, angle of attack, and angle of sideslip to define the far-field flow direction.',
        name: 'FARFIELD_ANGLES',
        text: 'Flow Angles',
      },
    ],
    cond: {
      choice: 32160,
      param: 'physical_boundary',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 30342,
    help: 'Method of defining the flow direction at the far-field.',
    isMap: false,
    isRepeated: false,
    name: 'far_field_flow_direction_specification',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FarFieldFlowDirectionSpecification',
    quantityType: 0,
    text: 'Flow Direction',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.FarfieldFlowDirection]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'farfieldFlowDirection',
    cond: {
      list: [
        {
          choice: 30342,
          param: 'far_field_flow_direction_specification',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 32160,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: {
      x: 1.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Vector specifying the flow direction at the far-field boundary. Automatically scaled to a unit vector internally.',
    isMap: false,
    isRepeated: false,
    name: 'farfield_flow_direction',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FarfieldFlowDirection',
    quantityType: 0,
    text: 'Flow Direction',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.FarfieldAngleAlpha]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'farfieldAngleAlpha',
    cond: {
      list: [
        {
          choice: 62593,
          param: 'far_field_flow_direction_specification',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 32160,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Angle of attack. Positive angle of attack results in a non-zero far-field velocity component in the negative body-z direction.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'farfield_angle_alpha',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FarfieldAngleAlpha',
    quantityType: QuantityPb.QuantityType.DEGREE,
    text: 'Angle of Attack',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.FarfieldAngleBeta]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'farfieldAngleBeta',
    cond: {
      list: [
        {
          choice: 62593,
          param: 'far_field_flow_direction_specification',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 32160,
          param: 'physical_boundary',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Angle of sideslip. Positive angle of sideslip results in a non-zero far-field velocity component in the positive body-y direction.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'farfield_angle_beta',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'FarfieldAngleBeta',
    quantityType: QuantityPb.QuantityType.DEGREE,
    text: 'Angle of Sideslip',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TurbulenceSpecificationSpalartAllmaras]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'turbulenceSpecificationSpalartAllmaras',
    choices: [
      {
        enumNumber: 50655,
        help: 'Apply a ratio of turbulent viscosity to laminar viscosity at the boundary.',
        name: 'TURBULENT_VISCOSITY_RATIO_SA',
        text: 'Turbulent Viscosity Ratio',
      },
      {
        enumNumber: 52501,
        help: 'Set the turbulent viscosity at the boundary.',
        name: 'TURBULENT_VISCOSITY_SA',
        text: 'Turbulent Viscosity',
      },
      {
        enumNumber: 6756,
        help: 'Set the Spalart-Allmaras variable value at the boundary condition.',
        name: 'BC_SA_VARIABLE',
        text: 'Prescribed Value',
      },
    ],
    cond: {
      list: [
        {
          list: [
            {
              choice: 32160,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 46673,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 50655,
    help: 'Condition applied to the Spalart-Allmaras turbulence equation at the boundary.',
    isMap: false,
    isRepeated: false,
    name: 'turbulence_specification_spalart_allmaras',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'TurbulenceSpecificationSpalartAllmaras',
    quantityType: 0,
    text: 'Turbulence - Spalart-Allmaras',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.BcUniformNuTilde]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'bcUniformNuTilde',
    cond: {
      list: [
        {
          choice: 6756,
          param: 'turbulence_specification_spalart_allmaras',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 4.166705541552236e-05,
    help: 'Uniform boundary condition value for the Spalart-Allmaras turbulence variable.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'bc_uniform_nu_tilde',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'BcUniformNuTilde',
    quantityType: QuantityPb.QuantityType.SA_VARIABLE,
    text: 'Uniform Spalart-Allmaras Variable Value',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.BcNuTildeCol]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'bcNuTildeCol',
    cond: {
      list: [
        {
          choice: 6756,
          param: 'turbulence_specification_spalart_allmaras',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          param: 'profile_bc',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Index of the column of the profile boundary condition table used for the Sparlart-Allmaras variable.',
    isMap: false,
    isRepeated: false,
    maxVal: 100000.0,
    minVal: 0,
    name: 'bc_nu_tilde_col',
    openBound: false,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'BcNuTildeCol',
    quantityType: 0,
    text: 'Spalart-Allmaras Variable Column',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.TurbulenceSpecificationKomega]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'turbulenceSpecificationKomega',
    choices: [
      {
        enumNumber: 34446,
        help: 'Apply a uniform ratio of turbulent viscosity to laminar viscosity and turbulence intensity at the boundary.',
        name: 'BC_TURBULENT_VISCOSITY_RATIO_AND_INTENSITY_KOMEGA',
        text: 'Turbulent Viscosity Ratio and Intensity',
      },
      {
        enumNumber: 66095,
        help: 'Set a uniform turbulent viscosity and turbulence intensity in the domain.',
        name: 'BC_TURBULENT_VISCOSITY_AND_INTENSITY_KOMEGA',
        text: 'Turbulent Viscosity and Intensity',
      },
      {
        enumNumber: 10604,
        help: 'Set the k-ω variables at the boundary condition.',
        name: 'BC_KOMEGA_VARIABLES',
        text: 'Prescribed Values',
      },
    ],
    cond: {
      list: [
        {
          list: [
            {
              choice: 32160,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 46673,
              param: 'physical_boundary',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 34446,
    help: 'Condition applied to the k-ω turbulence variables at the boundary.',
    isMap: false,
    isRepeated: false,
    name: 'turbulence_specification_komega',
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'TurbulenceSpecificationKomega',
    quantityType: 0,
    text: 'Turbulence Specification - k-ω',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.TurbulentViscosityRatio]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'turbulentViscosityRatio',
    cond: {
      list: [
        {
          choice: 50655,
          param: 'turbulence_specification_spalart_allmaras',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 34446,
          param: 'turbulence_specification_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 0.21043825715555026,
    help: 'Turbulent-to-laminar viscosity ratio at the boundary.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'turbulent_viscosity_ratio',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'TurbulentViscosityRatio',
    quantityType: 0,
    text: 'Turbulent Viscosity Ratio',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TurbulentViscosity]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'turbulentViscosity',
    cond: {
      list: [
        {
          choice: 52501,
          param: 'turbulence_specification_spalart_allmaras',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 66095,
          param: 'turbulence_specification_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 3.765582173541416e-06,
    help: 'Turbulent viscosity at the boundary.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'turbulent_viscosity',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'TurbulentViscosity',
    quantityType: QuantityPb.QuantityType.VISCOSITY,
    text: 'Turbulent Viscosity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TurbulenceIntensity]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'turbulenceIntensity',
    cond: {
      list: [
        {
          choice: 66095,
          param: 'turbulence_specification_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 34446,
          param: 'turbulence_specification_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 0.05,
    help: 'Turbulence intensity at the boundary.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'turbulence_intensity',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'TurbulenceIntensity',
    quantityType: 0,
    text: 'Turbulence Intensity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.BcUniformTke]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'bcUniformTke',
    cond: {
      list: [
        {
          choice: 10604,
          param: 'turbulence_specification_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 4.166705541552236e-05,
    help: 'Uniform boundary condition value for the turbulent kinetic energy variable.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'bc_uniform_tke',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'BcUniformTke',
    quantityType: QuantityPb.QuantityType.TKE,
    text: 'Turbulent Kinetic Energy Variable',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.BcUniformOmega]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'bcUniformOmega',
    cond: {
      list: [
        {
          choice: 10604,
          param: 'turbulence_specification_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 4.166705541552236e-05,
    help: 'Uniform boundary condition value for the specific dissipation rate variable.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'bc_uniform_omega',
    openBound: true,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'BcUniformOmega',
    quantityType: QuantityPb.QuantityType.OMEGA,
    text: 'Specific Dissipation Rate Variable',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.BcTkeCol]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'bcTkeCol',
    cond: {
      list: [
        {
          param: 'profile_bc',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
        {
          list: [
            {
              choice: 10604,
              param: 'turbulence_specification_komega',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 14358,
              param: 'turbulence_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              list: [
                {
                  choice: 35474,
                  param: 'viscous_model',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 55751,
                  param: 'viscous_model',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Index of the column of the profile boundary condition table used for the turbulent kinetic energy.',
    isMap: false,
    isRepeated: false,
    maxVal: 100000.0,
    minVal: 0,
    name: 'bc_tke_col',
    openBound: false,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'BcTkeCol',
    quantityType: 0,
    text: 'Turbulent Kinetic Energy Column',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.BcOmegaCol]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'bcOmegaCol',
    cond: {
      list: [
        {
          param: 'profile_bc',
          type: ProtoDescriptor.CondType.TRUEFALSE,
        },
        {
          list: [
            {
              choice: 10604,
              param: 'turbulence_specification_komega',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 14358,
              param: 'turbulence_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              list: [
                {
                  choice: 35474,
                  param: 'viscous_model',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 55751,
                  param: 'viscous_model',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Index of the column of the profile boundary condition table used for the specific dissipation rate.',
    isMap: false,
    isRepeated: false,
    maxVal: 100000.0,
    minVal: 0,
    name: 'bc_omega_col',
    openBound: false,
    parentGroups: [
      'boundary_conditions_fluid',
    ],
    pascalCaseName: 'BcOmegaCol',
    quantityType: 0,
    text: 'Specific Dissipation Rate Column',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.UniformV]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'uniformV',
    cond: {
      choice: 24354,
      param: 'initialization_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 1.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Uniform initial condition for velocity.',
    isMap: false,
    isRepeated: false,
    name: 'uniform_v',
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'UniformV',
    quantityType: QuantityPb.QuantityType.VELOCITY,
    text: 'Velocity',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.PotentialFlowPressureInitialization]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'potentialFlowPressureInitialization',
    choices: [
      {
        enumNumber: 7137,
        help: 'Do not initialize the pressure using the potential flow method',
        name: 'INITIALIZATION_POTENTIAL_FLOW_PRESSURE_OFF',
        text: 'Off',
      },
      {
        enumNumber: 8565,
        help: 'Initialize the pressure using Bernouilli\'s equation.',
        name: 'INITIALIZATION_POTENTIAL_FLOW_PRESSURE_ON',
        text: 'On',
      },
    ],
    cond: {
      list: [
        {
          choice: 40255,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 28817,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 41777,
              param: 'density_relationship',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 7137,
    help: 'Pressure initialization options when using potential flow initialization.',
    isMap: false,
    isRepeated: false,
    name: 'potential_flow_pressure_initialization',
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'PotentialFlowPressureInitialization',
    quantityType: 0,
    text: 'Potential Flow - Pressure Initialization',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.UniformP]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'uniformP',
    cond: {
      list: [
        {
          choice: 24354,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 40255,
              param: 'initialization_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              cond: {
                choice: 8565,
                param: 'potential_flow_pressure_initialization',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              type: ProtoDescriptor.CondType.NOT,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 101325.0,
    help: 'Uniform initial condition for the static pressure relative to the material reference pressure.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'uniform_p',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'UniformP',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'Static Pressure (Gauge)',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TurbulentVariableInitializationTypeSa]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'turbulentVariableInitializationTypeSa',
    choices: [
      {
        enumNumber: 25067,
        help: 'Apply a uniform ratio of turbulent viscosity to laminar viscosity in the domain.',
        name: 'INIT_TURBULENT_VISCOSITY_RATIO_SA',
        text: 'Turbulent Viscosity Ratio',
      },
      {
        enumNumber: 57075,
        help: 'Set a uniform turbulent viscosity in the domain.',
        name: 'INIT_TURBULENT_VISCOSITY_SA',
        text: 'Turbulent Viscosity',
      },
      {
        enumNumber: 65587,
        help: 'Uniform initial condition for the Spalart-Allmaras turbulence variable.',
        name: 'INIT_SA_VARIABLE',
        text: 'Uniform Value',
      },
      {
        enumNumber: 14914,
        help: 'Uniform initial condition for the Spalart-Allmaras turbulence variable from the far-field boundary.',
        name: 'INIT_FARFIELD_VALUES_SA',
        text: 'Far-field Value',
      },
    ],
    cond: {
      list: [
        {
          list: [
            {
              choice: 24354,
              param: 'initialization_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 3666,
              param: 'initialization_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 40255,
              param: 'initialization_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 25067,
    help: 'Type of initial condition for the turbulent variables.',
    isMap: false,
    isRepeated: false,
    name: 'turbulent_variable_initialization_type_sa',
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'TurbulentVariableInitializationTypeSa',
    quantityType: 0,
    text: 'Turbulent Variable Initialization - Spalart-Allmaras',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.UniformNuTilde]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'uniformNuTilde',
    cond: {
      list: [
        {
          choice: 65587,
          param: 'turbulent_variable_initialization_type_sa',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 2762,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 4.166705541552236e-05,
    help: 'Uniform initial condition for the Spalart-Allmaras turbulence variable.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'uniform_nu_tilde',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'UniformNuTilde',
    quantityType: QuantityPb.QuantityType.SA_VARIABLE,
    text: 'Spalart-Allmaras Variable',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TurbulentVariableInitializationTypeKomega]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'turbulentVariableInitializationTypeKomega',
    choices: [
      {
        enumNumber: 2461,
        help: 'Apply a uniform ratio of turbulent viscosity to laminar viscosity and turbulence intensity in the domain.',
        name: 'INIT_TURBULENT_VISCOSITY_RATIO_AND_INTENSITY_KOMEGA',
        text: 'Turbulent Viscosity Ratio and Intensity',
      },
      {
        enumNumber: 10533,
        help: 'Set a uniform turbulent viscosity and turbulence intensity in the domain.',
        name: 'INIT_TURBULENT_VISCOSITY_AND_INTENSITY_KOMEGA',
        text: 'Turbulent Viscosity and Intensity',
      },
      {
        cond: {
          type: ProtoDescriptor.CondType.FALSE,
        },
        enumNumber: 22789,
        help: 'Uniform initial conditions.',
        name: 'INIT_KOMEGA_VARIABLES',
        text: 'Uniform Values',
      },
      {
        enumNumber: 43086,
        help: 'Uniform initial condition from the far-field boundary.',
        name: 'INIT_FARFIELD_VALUES_KOMEGA',
        text: 'Far-field Values',
      },
    ],
    cond: {
      list: [
        {
          list: [
            {
              choice: 24354,
              param: 'initialization_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 3666,
              param: 'initialization_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 40255,
              param: 'initialization_type',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 2461,
    help: 'Type of initial condition for the turbulent variables.',
    isMap: false,
    isRepeated: false,
    name: 'turbulent_variable_initialization_type_komega',
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'TurbulentVariableInitializationTypeKomega',
    quantityType: 0,
    text: 'Turbulent Variable Initialization - k-omega',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.InitTurbulentViscosityRatio]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'initTurbulentViscosityRatio',
    cond: {
      list: [
        {
          choice: 2461,
          param: 'turbulent_variable_initialization_type_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 25067,
          param: 'turbulent_variable_initialization_type_sa',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 0.21043825715555026,
    help: 'Turbulent-to-laminar viscosity ratio.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'init_turbulent_viscosity_ratio',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InitTurbulentViscosityRatio',
    quantityType: 0,
    text: 'Turbulent Viscosity Ratio',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InitTurbulentViscosity]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'initTurbulentViscosity',
    cond: {
      list: [
        {
          choice: 10533,
          param: 'turbulent_variable_initialization_type_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 57075,
          param: 'turbulent_variable_initialization_type_sa',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 3.765582173541416e-06,
    help: 'Turbulent viscosity.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'init_turbulent_viscosity',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InitTurbulentViscosity',
    quantityType: QuantityPb.QuantityType.VISCOSITY,
    text: 'Turbulent Viscosity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InitTurbulenceIntensity]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'initTurbulenceIntensity',
    cond: {
      list: [
        {
          choice: 10533,
          param: 'turbulent_variable_initialization_type_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 2461,
          param: 'turbulent_variable_initialization_type_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 0.05,
    help: 'Turbulence intensity.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'init_turbulence_intensity',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InitTurbulenceIntensity',
    quantityType: 0,
    text: 'Turbulence Intensity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.UniformTke]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'uniformTke',
    cond: {
      list: [
        {
          choice: 22789,
          param: 'turbulent_variable_initialization_type_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 4.166705541552236e-05,
    help: 'Uniform initial condition for the turbulent kinetic energy variable.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'uniform_tke',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'UniformTke',
    quantityType: QuantityPb.QuantityType.TKE,
    text: 'Turbulent Kinetic Energy Variable',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.UniformOmega]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'uniformOmega',
    cond: {
      list: [
        {
          choice: 22789,
          param: 'turbulent_variable_initialization_type_komega',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 14358,
          param: 'turbulence_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 35474,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 55751,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 4.166705541552236e-05,
    help: 'Uniform initial condition for the specific dissipation rate variable.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'uniform_omega',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'UniformOmega',
    quantityType: QuantityPb.QuantityType.OMEGA,
    text: 'Specific Dissipation Rate Variable',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.VerificationSolutions]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'verificationSolutions',
    choices: [
      {
        enumNumber: 57970,
        help: 'Taylor-Green vortex problem.',
        name: 'TAYLOR_GREEN_VORTEX',
        text: 'Taylor-Green Vortex',
      },
      {
        enumNumber: 3183,
        help: 'Method of manufactured solutions (MMS) for the Navier-Stokes equations.',
        name: 'NS_MMS',
        text: 'NS MMS',
      },
      {
        enumNumber: 43874,
        help: 'Method of manufactured solutions (MMS) for the Euler equations.',
        name: 'EULER_MMS',
        text: 'Euler MMS',
      },
      {
        enumNumber: 7698,
        help: 'Shock tube problem.',
        name: 'SHOCK_TUBE',
        text: 'Shock Tube Problem',
      },
      {
        enumNumber: 12571,
        help: 'Normal shock problem.',
        name: 'NORMAL_SHOCK',
        text: 'Normal Shock Problem',
      },
      {
        enumNumber: 59115,
        help: 'Shock vortex problem.',
        name: 'SHOCK_VORTEX',
        text: 'Shock Vortex Problem',
      },
      {
        enumNumber: 10488,
        help: 'Shu Osher problem.',
        name: 'SHU_OSHER',
        text: 'Shu Osher Problem',
      },
      {
        enumNumber: 34992,
        help: 'Disturbance wave problem.',
        name: 'DISTURBANCE_WAVE',
        text: 'Disturbance Wave Problem',
      },
      {
        enumNumber: 33985,
        help: 'Invisvid vortex transport by uniform flow.',
        name: 'INVISCID_VORTEX',
        text: 'Inviscid Vortex Transport by Uniform Flow',
      },
      {
        enumNumber: 60987,
        help: '2D planar lattice flow for incompressible NS.',
        name: 'INS_2D_LATTICE',
        text: '2D Planar lattice flow for incompressible NS',
      },
      {
        enumNumber: 2909,
        help: 'Run a debug check on the left/right symmetry of fluxes.',
        name: 'CHECK_FLUX_SYMMETRY',
        text: 'Check Flux Symmetry',
      },
    ],
    cond: {
      list: [
        {
          type: ProtoDescriptor.CondType.FALSE,
        },
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 57970,
    help: 'Predefined verification problems built into the solver.',
    isMap: false,
    isRepeated: false,
    name: 'verification_solutions',
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'VerificationSolutions',
    quantityType: 0,
    text: 'Verification Solutions',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.TgvRho]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'tgvRho',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 57970,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Initial Taylor-Green vortex density.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'tgv_rho',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'TgvRho',
    quantityType: QuantityPb.QuantityType.DENSITY,
    text: 'Rho',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TgvP]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'tgvP',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 57970,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 100.0,
    help: 'Initial Taylor-Green vortex pressure.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'tgv_p',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'TgvP',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'Pressure',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.TgvV]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'tgvV',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 57970,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Initial Taylor-Green vortex velocity (magnitude).',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'tgv_v',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'TgvV',
    quantityType: QuantityPb.QuantityType.VELOCITY,
    text: 'Velocity',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShocktubeP1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shocktubeP1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 7698,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.1,
    help: 'Initial pressure on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shocktube_p1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShocktubeP1',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'P1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShocktubeP4]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shocktubeP4',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 7698,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Initial pressure on side 4.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shocktube_p4',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShocktubeP4',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'P4',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShocktubeT1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shocktubeT1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 7698,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.00278,
    help: 'Initial temperature on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shocktube_t1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShocktubeT1',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'T1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShocktubeT4]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shocktubeT4',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 7698,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.00348,
    help: 'Initial temperature on side 4.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shocktube_t4',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShocktubeT4',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'T4',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShuosherP1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shuosherP1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 10488,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 10.3333,
    help: 'Initial pressure on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shuosher_p1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShuosherP1',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'P1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShuosherRho1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shuosherRho1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 10488,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 3.857143,
    help: 'Initial density on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shuosher_rho1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShuosherRho1',
    quantityType: QuantityPb.QuantityType.DENSITY,
    text: 'Rho1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShuosherM1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shuosherM1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 10488,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 3.0,
    help: 'Initial Mach Number on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shuosher_m1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShuosherM1',
    quantityType: QuantityPb.QuantityType.MACH,
    text: 'M1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShuosherP2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shuosherP2',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 10488,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Initial pressure on side 2.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shuosher_p2',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShuosherP2',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'P2',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShuosherRho2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shuosherRho2',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 10488,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Initial density on side 2.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shuosher_rho2',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShuosherRho2',
    quantityType: QuantityPb.QuantityType.DENSITY,
    text: 'Rho2',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShuosherM2]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shuosherM2',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 10488,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Initial Mach Number on side 2.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shuosher_m2',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShuosherM2',
    quantityType: QuantityPb.QuantityType.MACH,
    text: 'M2',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShuosherAmp]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shuosherAmp',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 10488,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.2,
    help: 'Amplitude of the entropy wave.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shuosher_amp',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShuosherAmp',
    quantityType: 0,
    text: 'Amplitude',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShuosherKx]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shuosherKx',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 10488,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 5.0,
    help: 'Wavenumber of the entropy wave.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shuosher_kx',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShuosherKx',
    quantityType: 0,
    text: 'Wavenumber',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.DisturbancewaveP]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'disturbancewaveP',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 34992,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.7143,
    help: 'Base flow pressure.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'disturbancewave_p',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'DisturbancewaveP',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'P',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.DisturbancewaveT]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'disturbancewaveT',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 34992,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.0025,
    help: 'Base flow temperature.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'disturbancewave_t',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'DisturbancewaveT',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'T',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.DisturbancewaveM]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'disturbancewaveM',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 34992,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.2,
    help: 'Base flow Mach number.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'disturbancewave_m',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'DisturbancewaveM',
    quantityType: QuantityPb.QuantityType.MACH,
    text: 'M',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.AcousticwaveAmp]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'acousticwaveAmp',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 34992,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.01,
    help: 'Amplitude of the acoustic wave.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'acousticwave_amp',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'AcousticwaveAmp',
    quantityType: 0,
    text: 'Acoustic Wave Amplitude',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.VorticitywaveAmp]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'vorticitywaveAmp',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 34992,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.01,
    help: 'Amplitude of the vorticity wave.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'vorticitywave_amp',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'VorticitywaveAmp',
    quantityType: 0,
    text: 'Vorticity Wave Amplitude',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.EntropywaveAmp]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'entropywaveAmp',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 34992,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.01,
    help: 'Amplitude of the entropy wave.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'entropywave_amp',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'EntropywaveAmp',
    quantityType: 0,
    text: 'Entropy Wave Amplitude',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.DisturbancewaveKx]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'disturbancewaveKx',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 34992,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 5.0,
    help: 'Wavenumber of the disturbance wave.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'disturbancewave_kx',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'DisturbancewaveKx',
    quantityType: 0,
    text: 'Wavenumber',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.NormalshockP1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'normalshockP1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 12571,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.7143,
    help: 'Initial pressure on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'normalshock_p1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'NormalshockP1',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'P1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.NormalshockT1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'normalshockT1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 12571,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.0025,
    help: 'Initial temperature on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'normalshock_t1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'NormalshockT1',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'T1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.NormalshockM1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'normalshockM1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 12571,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 2.0,
    help: 'Initial Mach Number on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'normalshock_m1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'NormalshockM1',
    quantityType: QuantityPb.QuantityType.MACH,
    text: 'M1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShockvortexP1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shockvortexP1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 59115,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'Initial pressure on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shockvortex_p1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShockvortexP1',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'P1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShockvortexT1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shockvortexT1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 59115,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.0035,
    help: 'Initial temperature on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shockvortex_t1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShockvortexT1',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'T1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShockvortexM1]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shockvortexM1',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 59115,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.5,
    help: 'Initial Mach Number on side 1.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shockvortex_m1',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShockvortexM1',
    quantityType: QuantityPb.QuantityType.MACH,
    text: 'M1',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShockvortexMv]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shockvortexMv',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 59115,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.2,
    help: 'Vortex Mach Number.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shockvortex_mv',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShockvortexMv',
    quantityType: QuantityPb.QuantityType.MACH,
    text: 'Mv',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShockvortexXv]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shockvortexXv',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 59115,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.25,
    help: 'X-Location of vortex core center.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shockvortex_xv',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShockvortexXv',
    quantityType: 0,
    text: 'Xv',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShockvortexZv]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shockvortexZv',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 59115,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.5,
    help: 'Z-Location of vortex core center.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shockvortex_zv',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShockvortexZv',
    quantityType: 0,
    text: 'Zv',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShockvortexAv]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shockvortexAv',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 59115,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.075,
    help: 'Av constant for the vortex.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shockvortex_av',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShockvortexAv',
    quantityType: 0,
    text: 'Av',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ShockvortexBv]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'shockvortexBv',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 59115,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.175,
    help: 'Bv constant for the vortex.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'shockvortex_bv',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'ShockvortexBv',
    quantityType: 0,
    text: 'Bv',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.MmsK]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'mmsK',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 43874,
              param: 'verification_solutions',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 3183,
              param: 'verification_solutions',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 3.141592653589793,
    help: 'K constant for the MMS solution.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'mms_k',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'MmsK',
    quantityType: 0,
    text: 'MMS K',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.MmsA]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'mmsA',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              choice: 43874,
              param: 'verification_solutions',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 3183,
              param: 'verification_solutions',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ANY,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 10.0,
    help: 'A constant for the MMS solution.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'mms_a',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'MmsA',
    quantityType: 0,
    text: 'MMS A',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InviscidVortexMinfty]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'inviscidVortexMinfty',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33985,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.05,
    help: 'Free-stream Mach number far away from the vortex.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0.01,
    name: 'inviscid_vortex_minfty',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InviscidVortexMinfty',
    quantityType: QuantityPb.QuantityType.MACH,
    text: 'Minfty',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InviscidVortexPinfty]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'inviscidVortexPinfty',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33985,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 100000,
    help: 'Free-stream pressure far away from the vortex.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 1,
    name: 'inviscid_vortex_pinfty',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InviscidVortexPinfty',
    quantityType: QuantityPb.QuantityType.PRESSURE,
    text: 'Pinfty',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InviscidVortexTinfty]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'inviscidVortexTinfty',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33985,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 300,
    help: 'Free-stream temperature far away from the vortex.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 10,
    name: 'inviscid_vortex_tinfty',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InviscidVortexTinfty',
    quantityType: QuantityPb.QuantityType.TEMPERATURE,
    text: 'Tinfty',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InviscidVortexXv]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'inviscidVortexXv',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33985,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.5,
    help: 'X-Location of vortex core center at t = 0.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'inviscid_vortex_xv',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InviscidVortexXv',
    quantityType: 0,
    text: 'Xv',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InviscidVortexYv]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'inviscidVortexYv',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33985,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.5,
    help: 'Y-Location of vortex core center at t = 0.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'inviscid_vortex_yv',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InviscidVortexYv',
    quantityType: 0,
    text: 'Yv',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InviscidVortexXflowdir]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'inviscidVortexXflowdir',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33985,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 1.0,
    help: 'X-component of the uniform flow direction.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'inviscid_vortex_xflowdir',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InviscidVortexXflowdir',
    quantityType: 0,
    text: 'Xflowdir',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InviscidVortexYflowdir]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'inviscidVortexYflowdir',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33985,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    help: 'Y-component of the uniform flow direction.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'inviscid_vortex_yflowdir',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InviscidVortexYflowdir',
    quantityType: 0,
    text: 'Yflowdir',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InviscidVortexRvortex]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'inviscidVortexRvortex',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33985,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.005,
    help: 'Characteristic radius of the vortex.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'inviscid_vortex_rvortex',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InviscidVortexRvortex',
    quantityType: 0,
    text: 'Rvortex',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.InviscidVortexBetavortex]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'inviscidVortexBetavortex',
    cond: {
      list: [
        {
          choice: 11399,
          param: 'initialization_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 33985,
          param: 'verification_solutions',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 0.02,
    help: 'Strength of the vortex.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'inviscid_vortex_betavortex',
    openBound: true,
    parentGroups: [
      'initialization_fluid',
    ],
    pascalCaseName: 'InviscidVortexBetavortex',
    quantityType: 0,
    text: 'Betavortex',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.PhysicalBehaviorId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'physicalBehaviorId',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'physical_behavior_id',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'PhysicalBehaviorId',
    quantityType: 0,
    text: 'Physical Behavior ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.PhysicalBehaviorName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'physicalBehaviorName',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'physical_behavior_name',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'PhysicalBehaviorName',
    quantityType: 0,
    text: 'Physical Behavior Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.PhysicalBehaviorModel]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'physicalBehaviorModel',
    choices: [
      {
        enumNumber: 48090,
        help: 'Behavior inputs associated with actuator disks',
        name: 'ACTUATOR_DISK_MODEL',
        text: 'Disk Model',
      },
      {
        enumNumber: 2007,
        help: 'Behavior inputs associated with actuator lines',
        name: 'ACTUATOR_LINE_MODEL',
        text: 'Line Model',
      },
      {
        enumNumber: 36795,
        help: 'Behavior inputs associated with source points',
        name: 'SOURCE_POINTS_MODEL',
        text: 'Generic Source Points Model',
      },
    ],
    defaultValue: 48090,
    help: 'Defines the physical behavior type.',
    isMap: false,
    isRepeated: false,
    name: 'physical_behavior_model',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'PhysicalBehaviorModel',
    quantityType: 0,
    text: 'Physical Behavior Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ActuatorDiskModel]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'actuatorDiskModel',
    choices: [
      {
        enumNumber: 55624,
        help: 'Applies a uniform force at all locations on the disk',
        name: 'ACTUATOR_DISK_UNIFORM_THRUST',
        text: 'Uniform Thrust',
      },
      {
        enumNumber: 4518,
        help: 'Thrust, torque, and radial force profiles',
        name: 'ACTUATOR_DISK_RADIAL_DISTRIBUTION',
        text: 'Radial Distribution',
      },
      {
        enumNumber: 32379,
        help: 'Uses tables of airfoil aerodynamic data to model the effect of rotors and propellers',
        name: 'ACTUATOR_DISK_BLADE_ELEMENT',
        text: 'Blade Element Model',
      },
      {
        enumNumber: 51439,
        help: 'Specify the relation between the fan pressure rise and the volume flow rate.',
        name: 'FAN_CURVE_INTERNAL',
        text: 'Fan',
      },
    ],
    cond: {
      choice: 48090,
      param: 'physical_behavior_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 55624,
    help: 'Defines the physics behavior of the actuator disk.',
    isMap: false,
    isRepeated: false,
    name: 'actuator_disk_model',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'ActuatorDiskModel',
    quantityType: 0,
    text: 'Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ActuatorDiskThrust]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'actuatorDiskThrust',
    cond: {
      list: [
        {
          choice: 55624,
          param: 'actuator_disk_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 4518,
          param: 'actuator_disk_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          list: [
            {
              list: [
                {
                  choice: 48090,
                  param: 'physical_behavior_model',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 2007,
                  param: 'physical_behavior_model',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
            {
              choice: 32379,
              param: 'actuator_disk_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 3596,
              param: 'actuator_disk_bem_strategy',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Thrust provided by the actuator disk',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'actuator_disk_thrust',
    openBound: true,
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'ActuatorDiskThrust',
    quantityType: QuantityPb.QuantityType.DISK_THRUST,
    text: 'Disk Thrust',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ActuatorDiskTorque]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'actuatorDiskTorque',
    cond: {
      choice: 4518,
      param: 'actuator_disk_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: 'Torque of the actuator disk',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'actuator_disk_torque',
    openBound: true,
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'ActuatorDiskTorque',
    quantityType: QuantityPb.QuantityType.DISK_TORQUE,
    text: 'Disk Torque',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ActuatorDiskRadialForce]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'actuatorDiskRadialForce',
    cond: {
      choice: 4518,
      param: 'actuator_disk_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: 'Radial force of the actuator disk',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'actuator_disk_radial_force',
    openBound: true,
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'ActuatorDiskRadialForce',
    quantityType: QuantityPb.QuantityType.GENERIC_FORCE,
    text: 'Radial Force',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ActuatorDiskRadialTableData]: <ProtoDescriptor.RectilinearTableParam>{
    axis: [
      {
        quantity: QuantityPb.QuantityType.RELATIVE_RADIUS,
      },
    ],
    camelCaseName: 'actuatorDiskRadialTableData',
    cond: {
      choice: 4518,
      param: 'actuator_disk_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: 'Radially dependent axial, circumferential, and radial force distribution',
    isMap: false,
    isRepeated: false,
    name: 'actuator_disk_radial_table_data',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'ActuatorDiskRadialTableData',
    quantityType: 0,
    tableRecords: [
      {
        quantity: QuantityPb.QuantityType.THRUST_PROFILE,
      },
      {
        quantity: QuantityPb.QuantityType.TORQUE_PROFILE,
      },
      {
        quantity: QuantityPb.QuantityType.RADIAL_FORCE_PROFILE,
      },
    ],
    text: 'Radial Profile',
    type: ProtoDescriptor.ParamType.TABLE,
  },
  [ParamName.ActuatorLineModel]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'actuatorLineModel',
    choices: [
      {
        enumNumber: 48609,
        help: 'Uses tables of airfoil aerodynamic data to model the effect of rotors and propellers',
        name: 'ACTUATOR_LINE_BLADE_ELEMENT',
        text: 'Blade Element Model',
      },
    ],
    cond: {
      choice: 2007,
      param: 'physical_behavior_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 48609,
    help: 'Defines the physics behavior of the actuator line.',
    isMap: false,
    isRepeated: false,
    name: 'actuator_line_model',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'ActuatorLineModel',
    quantityType: 0,
    text: 'Actuator Line Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ActuatorDiskRotationRate]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'actuatorDiskRotationRate',
    cond: {
      list: [
        {
          list: [
            {
              choice: 48090,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 32379,
              param: 'actuator_disk_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          list: [
            {
              choice: 2007,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 48609,
              param: 'actuator_line_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 100.0,
    help: 'Rotational speed of the fictitious blades represented by the actuator disk',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'actuator_disk_rotation_rate',
    openBound: true,
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'ActuatorDiskRotationRate',
    quantityType: QuantityPb.QuantityType.ANGULAR_VELOCITY,
    text: 'Rotation Rate',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ActuatorDiskBemStrategy]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'actuatorDiskBemStrategy',
    choices: [
      {
        enumNumber: 18835,
        help: 'Prescribes a rotation rate for the fictitious blades.',
        name: 'BEM_ROTATION_RATE',
        text: 'Rotation Rate',
      },
      {
        enumNumber: 3596,
        help: 'The rotation rate specified initially is adjusted to achieve a prescribed target thrust. The final rotation rate is available as a surface output',
        name: 'BEM_TARGET_THRUST',
        text: 'Target Thrust',
      },
    ],
    cond: {
      list: [
        {
          list: [
            {
              choice: 48090,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 32379,
              param: 'actuator_disk_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          list: [
            {
              choice: 2007,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 48609,
              param: 'actuator_line_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 18835,
    help: 'Defines how the power of the propeller is specified in the blade element model',
    isMap: false,
    isRepeated: false,
    name: 'actuator_disk_bem_strategy',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'ActuatorDiskBemStrategy',
    quantityType: 0,
    text: 'Blade Element Strategy',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.ActuatorDiskBladeCount]: <ProtoDescriptor.IntParam>{
    camelCaseName: 'actuatorDiskBladeCount',
    cond: {
      list: [
        {
          list: [
            {
              choice: 48090,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 32379,
              param: 'actuator_disk_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          list: [
            {
              choice: 2007,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 48609,
              param: 'actuator_line_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 2,
    help: 'Number of blades in the propeller/rotor',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'actuator_disk_blade_count',
    openBound: true,
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'ActuatorDiskBladeCount',
    quantityType: 0,
    text: 'Blade Count',
    type: ProtoDescriptor.ParamType.INT,
  },
  [ParamName.BladeElementPitchSchedule]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'bladeElementPitchSchedule',
    cond: {
      list: [
        {
          list: [
            {
              choice: 48090,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 32379,
              param: 'actuator_disk_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          list: [
            {
              choice: 2007,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 48609,
              param: 'actuator_line_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Constant, cosine, and sine Fourier series coefficients for pitch first harmonic',
    isMap: false,
    isRepeated: false,
    name: 'blade_element_pitch_schedule',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'BladeElementPitchSchedule',
    quantityType: QuantityPb.QuantityType.DEGREE,
    text: 'Pitch Schedule',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.BladeElementFlapSchedule]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'bladeElementFlapSchedule',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Constant, cosine, and sine Fourier series coefficients for flap hinge first harmonic',
    isMap: false,
    isRepeated: false,
    name: 'blade_element_flap_schedule',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'BladeElementFlapSchedule',
    quantityType: QuantityPb.QuantityType.DEGREE,
    text: 'Flap Schedule',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.BladeElementLagSchedule]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'bladeElementLagSchedule',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: 'Constant, cosine, and sine Fourier series coefficients for lag hinge first harmonic',
    isMap: false,
    isRepeated: false,
    name: 'blade_element_lag_schedule',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'BladeElementLagSchedule',
    quantityType: QuantityPb.QuantityType.DEGREE,
    text: 'Lag Schedule',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.BladeElementGeometryData]: <ProtoDescriptor.RectilinearTableParam>{
    axis: [
      {
        quantity: QuantityPb.QuantityType.RELATIVE_RADIUS,
      },
    ],
    camelCaseName: 'bladeElementGeometryData',
    cond: {
      list: [
        {
          list: [
            {
              choice: 48090,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 32379,
              param: 'actuator_disk_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          list: [
            {
              choice: 2007,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 48609,
              param: 'actuator_line_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Blade twist, sweep, anhedral, and chord vs. radial station (r/R)',
    isMap: false,
    isRepeated: false,
    name: 'blade_element_geometry_data',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'BladeElementGeometryData',
    quantityType: 0,
    tableRecords: [
      {
        quantity: QuantityPb.QuantityType.TWIST_ANGLE,
      },
      {
        quantity: QuantityPb.QuantityType.SWEEP_ANGLE,
      },
      {
        quantity: QuantityPb.QuantityType.ANHEDRAL_ANGLE,
      },
      {
        quantity: QuantityPb.QuantityType.RELATIVE_CHORD,
      },
    ],
    text: 'Blade Geometry',
    type: ProtoDescriptor.ParamType.TABLE,
  },
  [ParamName.BladeElementReferenceSoundSpeed]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'bladeElementReferenceSoundSpeed',
    cond: {
      list: [
        {
          list: [
            {
              list: [
                {
                  choice: 28817,
                  param: 'density_relationship',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 41777,
                  param: 'density_relationship',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
            {
              choice: 48090,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 32379,
              param: 'actuator_disk_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          list: [
            {
              list: [
                {
                  choice: 28817,
                  param: 'density_relationship',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
                {
                  choice: 41777,
                  param: 'density_relationship',
                  type: ProtoDescriptor.CondType.CHOICE,
                },
              ],
              type: ProtoDescriptor.CondType.ANY,
            },
            {
              choice: 2007,
              param: 'physical_behavior_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 48609,
              param: 'actuator_line_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    defaultValue: 340.0,
    help: 'Reference sound speed required for each blade element model in constant density flows. Sound speed of air at sea level conditions is 340 m/s.',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 1e-10,
    name: 'blade_element_reference_sound_speed',
    openBound: true,
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'BladeElementReferenceSoundSpeed',
    quantityType: QuantityPb.QuantityType.VELOCITY,
    text: 'Sound Speed',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.ParticleSourceModel]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'particleSourceModel',
    choices: [
      {
        enumNumber: 36384,
        help: 'Injects material into the solver at particle locations',
        name: 'GENERAL_MASS_SOURCE',
        text: 'General Mass Source',
      },
      {
        enumNumber: 25728,
        help: 'Applies an acceleration at particle locations',
        name: 'GENERAL_ACCELERATION_SOURCE',
        text: 'General Acceleration Source',
      },
    ],
    cond: {
      choice: 36795,
      param: 'physical_behavior_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 36384,
    help: 'Defines the behavior of the general source particles.',
    isMap: false,
    isRepeated: false,
    name: 'particle_source_model',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'ParticleSourceModel',
    quantityType: 0,
    text: 'Particle Source Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.SourceParticleMassInjectionRate]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'sourceParticleMassInjectionRate',
    cond: {
      choice: 36384,
      param: 'particle_source_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: 'Mass injection rate supplied by particle group',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: 0,
    name: 'source_particle_mass_injection_rate',
    openBound: false,
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'SourceParticleMassInjectionRate',
    quantityType: 0,
    text: 'Mass Injection Rate',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SourceParticleAccelerationVector]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'sourceParticleAccelerationVector',
    cond: {
      choice: 25728,
      param: 'particle_source_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: -9.81,
    },
    help: 'Acceleration applied by generic source particles',
    isMap: false,
    isRepeated: false,
    name: 'source_particle_acceleration_vector',
    parentGroups: [
      'physical_behavior',
    ],
    pascalCaseName: 'SourceParticleAccelerationVector',
    quantityType: QuantityPb.QuantityType.ACCELERATION,
    text: 'Acceleration',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.AirfoilId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'airfoilId',
    help: 'Assign a unique ID to this airfoil',
    isMap: false,
    isRepeated: false,
    name: 'airfoil_id',
    parentGroups: [
      'blade_element_airfoil_data',
    ],
    pascalCaseName: 'AirfoilId',
    quantityType: 0,
    text: 'Airfoil ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.AirfoilName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'airfoilName',
    help: 'Apply a descriptive name to this airfoil',
    isMap: false,
    isRepeated: false,
    name: 'airfoil_name',
    parentGroups: [
      'blade_element_airfoil_data',
    ],
    pascalCaseName: 'AirfoilName',
    quantityType: 0,
    text: 'Airfoil Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.AirfoilRadialStation]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'airfoilRadialStation',
    help: 'Airfoil shape on the blade at this r/R',
    isMap: false,
    isRepeated: false,
    maxVal: 1,
    minVal: 0,
    name: 'airfoil_radial_station',
    openBound: false,
    parentGroups: [
      'blade_element_airfoil_data',
    ],
    pascalCaseName: 'AirfoilRadialStation',
    quantityType: QuantityPb.QuantityType.RELATIVE_RADIUS,
    text: 'Radial Station',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.AirfoilPerformanceData]: <ProtoDescriptor.RectilinearTableParam>{
    axis: [
      {
        quantity: QuantityPb.QuantityType.DEGREE,
      },
      {
        quantity: QuantityPb.QuantityType.MACH,
      },
    ],
    camelCaseName: 'airfoilPerformanceData',
    help: 'Lift, drag, and pitching moment coefficients vs. alpha & Mach number',
    isMap: false,
    isRepeated: false,
    name: 'airfoil_performance_data',
    parentGroups: [
      'blade_element_airfoil_data',
    ],
    pascalCaseName: 'AirfoilPerformanceData',
    quantityType: 0,
    tableRecords: [
      {
        quantity: QuantityPb.QuantityType.LIFT_COEFFICIENT,
      },
      {
        quantity: QuantityPb.QuantityType.DRAG_COEFFICIENT,
      },
      {
        quantity: QuantityPb.QuantityType.PITCHING_MOMENT_COEFFICIENT,
      },
    ],
    text: 'Airfoil data',
    type: ProtoDescriptor.ParamType.TABLE,
  },
  [ParamName.CavitationModel]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'cavitationModel',
    choices: [
      {
        enumNumber: 29874,
        help: 'Sauer-Schnerr one equation cavitation model.',
        name: 'SAUER_SCHNERR',
        text: 'Sauer-Schnerr',
      },
    ],
    cond: {
      choice: 56617,
      param: 'fluid_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 29874,
    help: 'Cavitation models available for simulating flow with cavitation.',
    isMap: false,
    isRepeated: false,
    name: 'cavitation_model',
    parentGroups: [
      'cavitation',
    ],
    pascalCaseName: 'CavitationModel',
    quantityType: 0,
    text: 'Cavitation Model',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.DensityVaporPhaseCavitation]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'densityVaporPhaseCavitation',
    cond: {
      choice: 56617,
      param: 'fluid_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 0.7267,
    help: 'The density of the vapor phase for the simulation of a flow with a cavitating fluid',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'density_vapor_phase_cavitation',
    openBound: true,
    parentGroups: [
      'cavitation',
    ],
    pascalCaseName: 'DensityVaporPhaseCavitation',
    quantityType: 0,
    text: 'Density vapor phase cavitation',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.SaturationPressureCavitation]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'saturationPressureCavitation',
    cond: {
      choice: 56617,
      param: 'fluid_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 3170,
    help: 'The saturation pressure of the fluid, assumed constant for now, for the simulation of a flow with a cavitating fluid',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'saturation_pressure_cavitation',
    openBound: true,
    parentGroups: [
      'cavitation',
    ],
    pascalCaseName: 'SaturationPressureCavitation',
    quantityType: 0,
    text: 'Saturation pressure cavitation',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.N0SauerSchnerr]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'n0SauerSchnerr',
    cond: {
      choice: 29874,
      param: 'cavitation_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: 100000000.0,
    help: 'The initial nuclei concentration in bubbles/m3 for the Sauer Schnerr cavitation model',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'n0_sauer_schnerr',
    openBound: true,
    parentGroups: [
      'cavitation',
    ],
    pascalCaseName: 'N0SauerSchnerr',
    quantityType: 0,
    text: 'n0 Sauer Schnerr',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.NReboud]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'nReboud',
    cond: {
      list: [
        {
          choice: 56617,
          param: 'fluid_type',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 35474,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ALL,
    },
    defaultValue: 10.0,
    help: 'Exponent for the Reboud correction for the eddy viscosity in cavitating regions',
    isMap: false,
    isRepeated: false,
    maxVal: 10000000000.0,
    minVal: -10000000000.0,
    name: 'n_reboud',
    openBound: true,
    parentGroups: [
      'cavitation',
    ],
    pascalCaseName: 'NReboud',
    quantityType: 0,
    text: 'n Reboud',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.PorousBehaviorId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'porousBehaviorId',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'porous_behavior_id',
    parentGroups: [
      'porous_behavior',
    ],
    pascalCaseName: 'PorousBehaviorId',
    quantityType: 0,
    text: 'Porous Behavior ID',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.PorousBehaviorName]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'porousBehaviorName',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: false,
    name: 'porous_behavior_name',
    parentGroups: [
      'porous_behavior',
    ],
    pascalCaseName: 'PorousBehaviorName',
    quantityType: 0,
    text: 'Porous Behavior Name',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.ZoneIds]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'zoneIds',
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    help: '',
    isMap: false,
    isRepeated: true,
    name: 'zone_ids',
    parentGroups: [
      'porous_behavior',
    ],
    pascalCaseName: 'ZoneIds',
    quantityType: 0,
    text: 'Zones',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.PorousModelType]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'porousModelType',
    choices: [
      {
        enumNumber: 55595,
        help: 'Darcy Forchheimer model.',
        name: 'DARCY_FORCHHEIMER',
        text: 'Darcy-Forchheimer',
      },
    ],
    cond: {
      type: ProtoDescriptor.CondType.FALSE,
    },
    defaultValue: 55595,
    help: 'Type of porous model.',
    isMap: false,
    isRepeated: false,
    name: 'porous_model_type',
    parentGroups: [
      'porous_behavior',
    ],
    pascalCaseName: 'PorousModelType',
    quantityType: 0,
    text: 'Type',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
  [ParamName.DarcyCoeff]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'darcyCoeff',
    cond: {
      choice: 55595,
      param: 'porous_model_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: '(x,y,z) components of the Darcy coefficients.',
    isMap: false,
    isRepeated: false,
    name: 'darcy_coeff',
    parentGroups: [
      'porous_behavior',
    ],
    pascalCaseName: 'DarcyCoeff',
    quantityType: QuantityPb.QuantityType.DARCY_COEFFICIENT,
    text: 'Darcy Coefficients',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.ForchheimerCoeff]: <ProtoDescriptor.Vector3Param>{
    camelCaseName: 'forchheimerCoeff',
    cond: {
      choice: 55595,
      param: 'porous_model_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    defaultValue: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    help: '(x,y,z) components of the Forchheimer coefficients.',
    isMap: false,
    isRepeated: false,
    name: 'forchheimer_coeff',
    parentGroups: [
      'porous_behavior',
    ],
    pascalCaseName: 'ForchheimerCoeff',
    quantityType: QuantityPb.QuantityType.FORCHHEIMER_COEFFICIENT,
    text: 'Forchheimer Coefficients',
    type: ProtoDescriptor.ParamType.VECTOR3,
  },
  [ParamName.PorousHeatSourcePower]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'porousHeatSourcePower',
    cond: {
      list: [
        {
          choice: 37484,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 41777,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Power of the heat source.',
    isMap: false,
    isRepeated: false,
    name: 'porous_heat_source_power',
    parentGroups: [
      'porous_behavior',
    ],
    pascalCaseName: 'PorousHeatSourcePower',
    quantityType: QuantityPb.QuantityType.POWER,
    text: 'Heat Source Power',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.PorousHeatSourcePowerPerUnitVolume]: <ProtoDescriptor.RealParam>{
    camelCaseName: 'porousHeatSourcePowerPerUnitVolume',
    cond: {
      list: [
        {
          choice: 37484,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 41777,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
    help: 'Power per unit volume of the heat source.',
    isMap: false,
    isRepeated: false,
    name: 'porous_heat_source_power_per_unit_volume',
    parentGroups: [
      'porous_behavior',
    ],
    pascalCaseName: 'PorousHeatSourcePowerPerUnitVolume',
    quantityType: QuantityPb.QuantityType.POWER_PER_UNIT_VOLUME,
    text: 'Heat Source Power',
    type: ProtoDescriptor.ParamType.REAL,
  },
  [ParamName.RefFrameId]: <ProtoDescriptor.StringParam>{
    camelCaseName: 'refFrameId',
    cond: {
      choice: 55595,
      param: 'porous_model_type',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    help: 'Frame that defines the principal directions used by the Darcy-Forchheimer coefficients.',
    isMap: false,
    isRepeated: false,
    name: 'ref_frame_id',
    parentGroups: [
      'porous_behavior',
    ],
    pascalCaseName: 'RefFrameId',
    quantityType: 0,
    text: 'Reference Frame',
    type: ProtoDescriptor.ParamType.STRING,
  },
  [ParamName.MpCouplingLinSolCoupling]: <ProtoDescriptor.MultipleChoiceParam>{
    camelCaseName: 'mpCouplingLinSolCoupling',
    choices: [
      {
        enumNumber: 36695,
        help: 'Enable enhanced coupling.',
        name: 'MP_COUPLING_LIN_SOL_COUPLING_ON',
        text: 'On',
      },
      {
        enumNumber: 40344,
        help: 'Disable enhanced coupling.',
        name: 'MP_COUPLING_LIN_SOL_COUPLING_OFF',
        text: 'Off',
      },
    ],
    defaultValue: 36695,
    help: 'Turns on the use of enhanced coupling between the physics, which provides greater robustness at the cost of simulation speed.',
    isMap: false,
    isRepeated: false,
    name: 'mp_coupling_lin_sol_coupling',
    parentGroups: [
      'mp_coupling_options',
    ],
    pascalCaseName: 'MpCouplingLinSolCoupling',
    quantityType: 0,
    text: 'Enhanced Coupling',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  },
};
export enum ParamGroupName {
  MeshIdentifier = 'mesh_identifier',
  Input = 'input',
  MaterialIdentifier = 'material_identifier',
  MaterialFluid = 'material_fluid',
  MaterialSolid = 'material_solid',
  Material = 'material',
  MaterialEntity = 'material_entity',
  VolumeIdentifier = 'volume_identifier',
  VolumeEntity = 'volume_entity',
  General = 'general',
  AdjointOutput = 'adjoint_output',
  Adjoint = 'adjoint',
  Time = 'time',
  FrameTransforms = 'frame_transforms',
  MotionData = 'motion_data',
  ParticleGroup = 'particle_group',
  MonitorPlaneVolumes = 'monitor_plane_volumes',
  MonitorPlane = 'monitor_plane',
  BodyFrame = 'body_frame',
  SurfaceName = 'surface_name',
  Table = 'table',
  TableReferences = 'table_references',
  Output = 'output',
  StoppingCondition = 'stopping_condition',
  TimeStepStoppingCondition = 'time_step_stopping_condition',
  EntityGroup = 'entity_group',
  ConvergenceCriteria = 'convergence_criteria',
  VolumeMaterialRelationship = 'volume_material_relationship',
  PhysicsIdentifier = 'physics_identifier',
  VolumePhysicsRelationship = 'volume_physics_relationship',
  EntityRelationships = 'entity_relationships',
  ReferenceValues = 'reference_values',
  BoundaryLayerProfile = 'boundary_layer_profile',
  AdaptiveMeshRefinement = 'adaptive_mesh_refinement',
  SolutionControlsHeat = 'solution_controls_heat',
  AdjointControlsHeat = 'adjoint_controls_heat',
  SpatialDiscretizationHeat = 'spatial_discretization_heat',
  BoundaryConditionsHeat = 'boundary_conditions_heat',
  InitializationHeat = 'initialization_heat',
  HeatSource = 'heat_source',
  SlidingInterfaces = 'sliding_interfaces',
  PeriodicPair = 'periodic_pair',
  Heat = 'heat',
  BasicFluid = 'basic_fluid',
  Turbulence = 'turbulence',
  SolutionControlsFluid = 'solution_controls_fluid',
  AdjointControlsFluid = 'adjoint_controls_fluid',
  SpatialDiscretizationFluid = 'spatial_discretization_fluid',
  BoundaryConditionsFluid = 'boundary_conditions_fluid',
  InitializationFluid = 'initialization_fluid',
  BladeElementAirfoilData = 'blade_element_airfoil_data',
  PhysicalBehavior = 'physical_behavior',
  Cavitation = 'cavitation',
  PorousBehavior = 'porous_behavior',
  Fluid = 'fluid',
  Params = 'params',
  Physics = 'physics',
  Interfaces = 'interfaces',
  MpCouplingOptions = 'mp_coupling_options',
  SimulationParam = 'simulation_param',
}
export const paramGroupDesc: { [name: string]: ProtoDescriptor.ParamGroup } = {
  [ParamGroupName.MeshIdentifier]: {
    name: 'mesh_identifier',
    text: 'Mesh Identifier',
    pascalCaseName: 'MeshIdentifier',
    camelCaseName: 'meshIdentifier',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.Input]: {
    name: 'input',
    text: 'Input Settings',
    pascalCaseName: 'Input',
    camelCaseName: 'input',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.url, paramDesc.filename],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.Input(),
  },
  [ParamGroupName.MaterialIdentifier]: {
    name: 'material_identifier',
    text: 'Material Identifier',
    pascalCaseName: 'MaterialIdentifier',
    camelCaseName: 'materialIdentifier',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.MaterialFluid]: {
    name: 'material_fluid',
    text: 'Material_Fluid',
    pascalCaseName: 'MaterialFluid',
    camelCaseName: 'materialFluid',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.reference_pressure, paramDesc.density_relationship, paramDesc.molecular_weight, paramDesc.constant_density_value, paramDesc.specific_heat_cp, paramDesc.laminar_thermal_conductivity, paramDesc.laminar_constant_thermal_prandtl_constant, paramDesc.laminar_constant_thermal_conductivity_constant, paramDesc.thermal_conductivity_table_data, paramDesc.boussinesq_approximation, paramDesc.boussinesq_temp_ref, paramDesc.thermal_expansion_coefficient, paramDesc.material_fluid_preset, paramDesc.laminar_viscosity_model_newtonian, paramDesc.sutherland_viscosity_ref, paramDesc.sutherland_viscosity_temp_ref, paramDesc.sutherland_constant, paramDesc.laminar_constant_viscosity_constant, paramDesc.dynamic_viscosity_table_data],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.MaterialFluid(),
  },
  [ParamGroupName.MaterialSolid]: {
    name: 'material_solid',
    text: 'Material_Solid',
    pascalCaseName: 'MaterialSolid',
    camelCaseName: 'materialSolid',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.constant_density_value_solid, paramDesc.specific_heat_cp_solid, paramDesc.thermal_conductivity_constant_solid, paramDesc.thermal_conductivity_table_data, paramDesc.material_solid_preset],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.MaterialSolid(),
  },
  [ParamGroupName.Material]: {
    name: 'material',
    text: 'Material',
    pascalCaseName: 'Material',
    camelCaseName: 'material',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [ParamGroupName.MaterialFluid, ParamGroupName.MaterialSolid],
    isExternal: false,
    isOneOf: true,
  },
  [ParamGroupName.MaterialEntity]: {
    name: 'material_entity',
    text: 'Material Entity',
    pascalCaseName: 'MaterialEntity',
    camelCaseName: 'materialEntity',
    isMap: false,
    isRepeated: true,
    params: [],
    paramGroups: [ParamGroupName.Material],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.MaterialEntity(),
  },
  [ParamGroupName.VolumeIdentifier]: {
    name: 'volume_identifier',
    text: 'Volume Identifier',
    pascalCaseName: 'VolumeIdentifier',
    camelCaseName: 'volumeIdentifier',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.VolumeEntity]: {
    name: 'volume_entity',
    text: 'Volume Entity',
    pascalCaseName: 'VolumeEntity',
    camelCaseName: 'volumeEntity',
    isMap: false,
    isRepeated: true,
    params: [],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.VolumeEntity(),
  },
  [ParamGroupName.General]: {
    name: 'general',
    text: 'Solution Basics',
    pascalCaseName: 'General',
    camelCaseName: 'general',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.float_type, paramDesc.gravity, paramDesc.acceleration, paramDesc.flow_behavior, paramDesc.solver_params],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.General(),
  },
  [ParamGroupName.AdjointOutput]: {
    name: 'adjoint_output',
    text: 'Output',
    pascalCaseName: 'AdjointOutput',
    camelCaseName: 'adjointOutput',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.Adjoint]: {
    name: 'adjoint',
    text: 'Adjoint',
    pascalCaseName: 'Adjoint',
    camelCaseName: 'adjoint',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.surfaces, paramDesc.deformed_coords_id, paramDesc.deformed_coords_url, paramDesc.primal_simulation_id],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.Adjoint(),
  },
  [ParamGroupName.Time]: {
    name: 'time',
    text: 'Time',
    pascalCaseName: 'Time',
    camelCaseName: 'time',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.time_marching, paramDesc.time_implicit_order, paramDesc.physical_time_step_method, paramDesc.time_step_val, paramDesc.time_step_ramp, paramDesc.time_step_ramp_initial_val, paramDesc.time_step_ramp_iteration_begin, paramDesc.time_step_ramp_iteration_end, paramDesc.compute_statistics, paramDesc.statistics_start_iteration, paramDesc.statistics_update_interval],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.Time(),
  },
  [ParamGroupName.FrameTransforms]: {
    name: 'frame_transforms',
    text: 'Frame Transforms',
    pascalCaseName: 'FrameTransforms',
    camelCaseName: 'frameTransforms',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.transform_name, paramDesc.transform_type, paramDesc.transform_rotation_angles, paramDesc.transform_translation],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.FrameTransforms(),
  },
  [ParamGroupName.MotionData]: {
    name: 'motion_data',
    text: 'Motion Data',
    pascalCaseName: 'MotionData',
    camelCaseName: 'motionData',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.frame_id, paramDesc.frame_name, paramDesc.frame_parent, paramDesc.attached_domains, paramDesc.attached_boundaries, paramDesc.motion_type, paramDesc.motion_translation_velocity, paramDesc.motion_translation, paramDesc.motion_formulation, paramDesc.motion_specification, paramDesc.motion_angular_velocity, paramDesc.motion_rotation_angles],
    paramGroups: [ParamGroupName.FrameTransforms],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.MotionData(),
  },
  [ParamGroupName.ParticleGroup]: {
    name: 'particle_group',
    text: 'Particle Group',
    pascalCaseName: 'ParticleGroup',
    camelCaseName: 'particleGroup',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.particle_group_id, paramDesc.particle_group_name, paramDesc.particle_group_behavior_model_ref, paramDesc.particle_group_type, paramDesc.actuator_disk_inner_radius, paramDesc.actuator_disk_outer_radius, paramDesc.actuator_disk_center, paramDesc.actuator_disk_orientation_selection, paramDesc.actuator_disk_rotation_angle, paramDesc.actuator_disk_normal_vector, paramDesc.source_particle_radius, paramDesc.search_radius_scale_factor, paramDesc.isotropic_gaussian_scale_factor, paramDesc.particle_positions_table],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.ParticleGroup(),
  },
  [ParamGroupName.MonitorPlaneVolumes]: {
    name: 'monitor_plane_volumes',
    text: 'Volumes',
    pascalCaseName: 'MonitorPlaneVolumes',
    camelCaseName: 'monitorPlaneVolumes',
    isMap: false,
    isRepeated: true,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.MonitorPlane]: {
    name: 'monitor_plane',
    text: 'Monitor Plane',
    pascalCaseName: 'MonitorPlane',
    camelCaseName: 'monitorPlane',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.monitor_plane_id, paramDesc.monitor_plane_name, paramDesc.monitor_plane_point, paramDesc.monitor_plane_normal, paramDesc.monitor_plane_box_clip, paramDesc.monitor_plane_clip_center, paramDesc.monitor_plane_clip_size, paramDesc.monitor_plane_clip_rotation, paramDesc.monitor_plane_volume_clip],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.MonitorPlane(),
  },
  [ParamGroupName.BodyFrame]: {
    name: 'body_frame',
    text: 'Body Frame',
    pascalCaseName: 'BodyFrame',
    camelCaseName: 'bodyFrame',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.body_frame_id],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.BodyFrame(),
  },
  [ParamGroupName.SurfaceName]: {
    name: 'surface_name',
    text: 'Surface Name Map',
    pascalCaseName: 'SurfaceName',
    camelCaseName: 'surfaceName',
    isMap: true,
    isRepeated: false,
    params: [paramDesc.surface_name],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.SurfaceName(),
  },
  [ParamGroupName.Table]: {
    name: 'table',
    text: 'Table',
    pascalCaseName: 'Table',
    camelCaseName: 'table',
    isMap: true,
    isRepeated: false,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.TableReferences]: {
    name: 'table_references',
    text: 'Table References',
    pascalCaseName: 'TableReferences',
    camelCaseName: 'tableReferences',
    isMap: true,
    isRepeated: false,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.Output]: {
    name: 'output',
    text: 'Solution Output',
    pascalCaseName: 'Output',
    camelCaseName: 'output',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.iters_per_output, paramDesc.debug_output, paramDesc.debug_output_interior_surface_data],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.Output(),
  },
  [ParamGroupName.StoppingCondition]: {
    name: 'stopping_condition',
    text: 'Stopping Condition',
    pascalCaseName: 'StoppingCondition',
    camelCaseName: 'stoppingCondition',
    isMap: false,
    isRepeated: true,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.TimeStepStoppingCondition]: {
    name: 'time_step_stopping_condition',
    text: 'Time-Step Stopping Condition',
    pascalCaseName: 'TimeStepStoppingCondition',
    camelCaseName: 'timeStepStoppingCondition',
    isMap: false,
    isRepeated: true,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.EntityGroup]: {
    name: 'entity_group',
    text: 'Entity Group',
    pascalCaseName: 'EntityGroup',
    camelCaseName: 'entityGroup',
    isMap: true,
    isRepeated: false,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.ConvergenceCriteria]: {
    name: 'convergence_criteria',
    text: 'Convergence Criteria',
    pascalCaseName: 'ConvergenceCriteria',
    camelCaseName: 'convergenceCriteria',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.ConvergenceCriteria(),
  },
  [ParamGroupName.VolumeMaterialRelationship]: {
    name: 'volume_material_relationship',
    text: 'Volume Material Relationship',
    pascalCaseName: 'VolumeMaterialRelationship',
    camelCaseName: 'volumeMaterialRelationship',
    isMap: false,
    isRepeated: true,
    params: [],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.VolumeMaterialRelationship(),
  },
  [ParamGroupName.PhysicsIdentifier]: {
    name: 'physics_identifier',
    text: 'Physics Identifier',
    pascalCaseName: 'PhysicsIdentifier',
    camelCaseName: 'physicsIdentifier',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [],
    isExternal: true,
    isOneOf: false,
  },
  [ParamGroupName.VolumePhysicsRelationship]: {
    name: 'volume_physics_relationship',
    text: 'Volume Physics Relationship',
    pascalCaseName: 'VolumePhysicsRelationship',
    camelCaseName: 'volumePhysicsRelationship',
    isMap: false,
    isRepeated: true,
    params: [],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.VolumePhysicsRelationship(),
  },
  [ParamGroupName.EntityRelationships]: {
    name: 'entity_relationships',
    text: 'Entity Relationships',
    pascalCaseName: 'EntityRelationships',
    camelCaseName: 'entityRelationships',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [ParamGroupName.VolumeMaterialRelationship, ParamGroupName.VolumePhysicsRelationship],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.EntityRelationships(),
  },
  [ParamGroupName.ReferenceValues]: {
    name: 'reference_values',
    text: 'Reference Values',
    pascalCaseName: 'ReferenceValues',
    camelCaseName: 'referenceValues',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.area_ref, paramDesc.length_ref, paramDesc.use_aero_moment_ref_lengths, paramDesc.length_ref_pitch, paramDesc.length_ref_roll, paramDesc.length_ref_yaw, paramDesc.p_ref, paramDesc.t_ref, paramDesc.v_ref],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.ReferenceValues(),
  },
  [ParamGroupName.BoundaryLayerProfile]: {
    name: 'boundary_layer_profile',
    text: 'Boundary Layer Profile',
    pascalCaseName: 'BoundaryLayerProfile',
    camelCaseName: 'boundaryLayerProfile',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.n_layers, paramDesc.initial_size, paramDesc.growth_rate, paramDesc.surfaces],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.BoundaryLayerProfile(),
  },
  [ParamGroupName.AdaptiveMeshRefinement]: {
    name: 'adaptive_mesh_refinement',
    text: 'Adaptive Mesh Refinement',
    pascalCaseName: 'AdaptiveMeshRefinement',
    camelCaseName: 'adaptiveMeshRefinement',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.refinement_iterations, paramDesc.refinement_dispatch_interval, paramDesc.max_refinement_interval, paramDesc.initial_target_complexity, paramDesc.final_target_complexity, paramDesc.meshing_method, paramDesc.target_cv_millions, paramDesc.all_tet, paramDesc.user_scaling],
    paramGroups: [ParamGroupName.BoundaryLayerProfile],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.AdaptiveMeshRefinement(),
  },
  [ParamGroupName.SolutionControlsHeat]: {
    name: 'solution_controls_heat',
    text: 'Solution Controls Heat',
    pascalCaseName: 'SolutionControlsHeat',
    camelCaseName: 'solutionControlsHeat',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.relaxation_method, paramDesc.implicit_method, paramDesc.linsol_iterations, paramDesc.linsol_tolerance, paramDesc.linear_solver_type, paramDesc.linsol_amg_pre_sweeps, paramDesc.linsol_amg_post_sweeps, paramDesc.linsol_amg_coarsening_size, paramDesc.linsol_amg_levels, paramDesc.linsol_amg_relaxation, paramDesc.linsol_amg_cycle_type, paramDesc.linsol_amg_freeze_levels_threshold, paramDesc.linsol_amg_smoother, paramDesc.explicit_method, paramDesc.controls_preset_version, paramDesc.solution_controls_heat_preset],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.SolutionControlsHeat(),
  },
  [ParamGroupName.AdjointControlsHeat]: {
    name: 'adjoint_controls_heat',
    text: 'Adjoint Solution Controls Heat',
    pascalCaseName: 'AdjointControlsHeat',
    camelCaseName: 'adjointControlsHeat',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.adjoint_solution_method, paramDesc.adjoint_gmres_restart_iters],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.AdjointControlsHeat(),
  },
  [ParamGroupName.SpatialDiscretizationHeat]: {
    name: 'spatial_discretization_heat',
    text: 'Spatial Discretization Heat',
    pascalCaseName: 'SpatialDiscretizationHeat',
    camelCaseName: 'spatialDiscretizationHeat',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.gradient_method, paramDesc.hlsq_blend, paramDesc.geometry_fixes, paramDesc.geometry_fixes_mitigations, paramDesc.discretization_preset_version, paramDesc.spatial_discretization_heat_preset],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.SpatialDiscretizationHeat(),
  },
  [ParamGroupName.BoundaryConditionsHeat]: {
    name: 'boundary_conditions_heat',
    text: 'Boundary Conditions Heat',
    pascalCaseName: 'BoundaryConditionsHeat',
    camelCaseName: 'boundaryConditionsHeat',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.surfaces, paramDesc.boundary_condition_name, paramDesc.boundary_condition_display_name, paramDesc.boundary_condition_interface_id, paramDesc.profile_bc, paramDesc.profile_bc_data, paramDesc.profile_type, paramDesc.heat_physical_boundary, paramDesc.fixed_heat_flux, paramDesc.fixed_integrated_heat_flux, paramDesc.heat_flux_col, paramDesc.fixed_temperature, paramDesc.heat_transfer_coefficient],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.BoundaryConditionsHeat(),
  },
  [ParamGroupName.InitializationHeat]: {
    name: 'initialization_heat',
    text: 'Initialization Heat',
    pascalCaseName: 'InitializationHeat',
    camelCaseName: 'initializationHeat',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.initialization_type, paramDesc.uniform_t, paramDesc.existing_solution_url, paramDesc.existing_solution_id],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.InitializationHeat(),
  },
  [ParamGroupName.HeatSource]: {
    name: 'heat_source',
    text: 'Heat Source',
    pascalCaseName: 'HeatSource',
    camelCaseName: 'heatSource',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.profile_source, paramDesc.profile_source_data, paramDesc.profile_type, paramDesc.heat_source_col, paramDesc.heat_source_id, paramDesc.heat_source_name, paramDesc.heat_source_zone_ids, paramDesc.heat_source_type, paramDesc.heat_source_power, paramDesc.heat_source_power_per_unit_volume],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.HeatSource(),
  },
  [ParamGroupName.SlidingInterfaces]: {
    name: 'sliding_interfaces',
    text: 'Sliding Interfaces',
    pascalCaseName: 'SlidingInterfaces',
    camelCaseName: 'slidingInterfaces',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.sliding_interface_id, paramDesc.sliding_interface_name, paramDesc.sliding_a, paramDesc.sliding_b, paramDesc.sliding_matching_translation_transform, paramDesc.sliding_matching_periodic_rotation_angles, paramDesc.sliding_matching_periodic_center_of_rotation, paramDesc.interface_type],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.SlidingInterfaces(),
  },
  [ParamGroupName.PeriodicPair]: {
    name: 'periodic_pair',
    text: 'Periodic Bounds',
    pascalCaseName: 'PeriodicPair',
    camelCaseName: 'periodicPair',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.periodic_pair_name, paramDesc.bound_a, paramDesc.bound_b, paramDesc.periodic_bc_type, paramDesc.translational, paramDesc.periodic_translation, paramDesc.periodic_center_of_rotation, paramDesc.periodic_rotation_angles],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.PeriodicPair(),
  },
  [ParamGroupName.Heat]: {
    name: 'heat',
    text: 'Heat Transfer',
    pascalCaseName: 'Heat',
    camelCaseName: 'heat',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [ParamGroupName.SolutionControlsHeat, ParamGroupName.AdjointControlsHeat, ParamGroupName.SpatialDiscretizationHeat, ParamGroupName.BoundaryConditionsHeat, ParamGroupName.InitializationHeat, ParamGroupName.HeatSource],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.Heat(),
  },
  [ParamGroupName.BasicFluid]: {
    name: 'basic_fluid',
    text: 'Basic Fluid',
    pascalCaseName: 'BasicFluid',
    camelCaseName: 'basicFluid',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.fluid_type, paramDesc.viscous_model],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.BasicFluid(),
  },
  [ParamGroupName.Turbulence]: {
    name: 'turbulence',
    text: 'Turbulence',
    pascalCaseName: 'Turbulence',
    camelCaseName: 'turbulence',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.des_formulation, paramDesc.rans_region, paramDesc.zdes_rmin, paramDesc.zdes_rmax, paramDesc.sub_grid_scale_model, paramDesc.c_s, paramDesc.c_vreman, paramDesc.c_wale, paramDesc.c_sigma, paramDesc.c_amd, paramDesc.turbulent_thermal_conductivity_prandtl_constant, paramDesc.transition_model, paramDesc.transition_model_cross_flow, paramDesc.transition_free_stream_turbulence_intensity, paramDesc.turbulence_model, paramDesc.qcr_sa, paramDesc.rotation_correction_sa, paramDesc.c_rot, paramDesc.C_sa_des, paramDesc.c_b_1, paramDesc.sigma, paramDesc.c_b_2, paramDesc.kappa, paramDesc.c_w_2, paramDesc.c_w_3, paramDesc.c_v_1, paramDesc.c_t_3, paramDesc.c_t_4, paramDesc.c_r_1, paramDesc.qcr_sst, paramDesc.kato_launder, paramDesc.turbulence_model_constants, paramDesc.C_sst_des1, paramDesc.C_sst_des2, paramDesc.sigma_k_1, paramDesc.sigma_k_2, paramDesc.sigma_w_1, paramDesc.sigma_w_2, paramDesc.beta_1, paramDesc.beta_2, paramDesc.beta_star, paramDesc.kappa_sst, paramDesc.a_1, paramDesc.gamma_1, paramDesc.gamma_2],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.Turbulence(),
  },
  [ParamGroupName.SolutionControlsFluid]: {
    name: 'solution_controls_fluid',
    text: 'Solution Controls Fluid',
    pascalCaseName: 'SolutionControlsFluid',
    camelCaseName: 'solutionControlsFluid',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.pseudo_time_step_method, paramDesc.cfl, paramDesc.local_time_stepping, paramDesc.pseudo_time_step_val, paramDesc.relaxation_method, paramDesc.implicit_method, paramDesc.linsol_iterations, paramDesc.linsol_tolerance, paramDesc.linear_solver_type, paramDesc.linsol_amg_pre_sweeps, paramDesc.linsol_amg_post_sweeps, paramDesc.linsol_amg_coarsening_size, paramDesc.linsol_amg_levels, paramDesc.linsol_amg_relaxation, paramDesc.linsol_amg_cycle_type, paramDesc.linsol_amg_freeze_levels_threshold, paramDesc.linsol_amg_smoother, paramDesc.jacobian_update_method, paramDesc.jacobian_update_interval, paramDesc.jacobian_warmup_threshold, paramDesc.robust_startup, paramDesc.robust_startup_initial_cfl, paramDesc.robust_startup_iterations, paramDesc.relax_flow, paramDesc.relax_turb, paramDesc.update_limit_flow, paramDesc.update_limit_turb, paramDesc.explicit_method, paramDesc.controls_preset_version, paramDesc.solution_controls_fluid_preset],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.SolutionControlsFluid(),
  },
  [ParamGroupName.AdjointControlsFluid]: {
    name: 'adjoint_controls_fluid',
    text: 'Adjoint Solution Controls Fluid',
    pascalCaseName: 'AdjointControlsFluid',
    camelCaseName: 'adjointControlsFluid',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.adjoint_solution_method, paramDesc.adjoint_gmres_restart_iters, paramDesc.adjoint_second_order_damping, paramDesc.adjoint_frozen_turbulence, paramDesc.adjoint_frozen_transition],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.AdjointControlsFluid(),
  },
  [ParamGroupName.SpatialDiscretizationFluid]: {
    name: 'spatial_discretization_fluid',
    text: 'Spatial Discretization Fluid',
    pascalCaseName: 'SpatialDiscretizationFluid',
    camelCaseName: 'spatialDiscretizationFluid',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.gradient_method, paramDesc.hlsq_blend, paramDesc.convective_schemes_density_based, paramDesc.entropy_epsilon, paramDesc.preconditioning, paramDesc.fds_min_low_dissipation, paramDesc.skew_symmetric_formulation, paramDesc.artificial_viscosity_model, paramDesc.alpha_hybrid, paramDesc.upwind_scheme_order, paramDesc.robust_dissipation, paramDesc.limiter, paramDesc.limiter_kappa, paramDesc.umuscl_chi, paramDesc.alpha_ld2, paramDesc.geometry_fixes, paramDesc.geometry_fixes_mitigations, paramDesc.order_blend, paramDesc.discretization_preset_version, paramDesc.spatial_discretization_fluid_preset],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.SpatialDiscretizationFluid(),
  },
  [ParamGroupName.BoundaryConditionsFluid]: {
    name: 'boundary_conditions_fluid',
    text: 'Boundary Conditions Fluid',
    pascalCaseName: 'BoundaryConditionsFluid',
    camelCaseName: 'boundaryConditionsFluid',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.surfaces, paramDesc.boundary_condition_name, paramDesc.boundary_condition_display_name, paramDesc.boundary_condition_interface_id, paramDesc.physical_boundary, paramDesc.wall_momentum, paramDesc.roughness_control, paramDesc.equivalent_sand_grain_roughness, paramDesc.fixed_integrated_heat_flux, paramDesc.heat_transfer_coefficient, paramDesc.wall_energy, paramDesc.fixed_heat_flux, paramDesc.fixed_temperature, paramDesc.wall_movement_translation, paramDesc.wall_movement_rotation_center, paramDesc.wall_movement_angular_velocity, paramDesc.inlet_momentum, paramDesc.inlet_velocity_magnitude, paramDesc.inlet_velocity_magnitude_col, paramDesc.inlet_velocity_components, paramDesc.total_pressure_col, paramDesc.mass_flow_rate, paramDesc.total_pressure, paramDesc.direction_specification, paramDesc.flow_direction, paramDesc.profile_bc, paramDesc.profile_bc_data, paramDesc.profile_type, paramDesc.inlet_energy, paramDesc.total_temperature, paramDesc.total_temperature_col, paramDesc.outlet_strategy, paramDesc.outlet_target_mass_flow_rate, paramDesc.outlet_reference_pressure, paramDesc.outlet_reference_temperature, paramDesc.outlet_pressure, paramDesc.fan_curve_table_data, paramDesc.head_loss_coefficient, paramDesc.outlet_pressure_constraint, paramDesc.farfield_mach_number, paramDesc.farfield_velocity_magnitude, paramDesc.farfield_pressure, paramDesc.farfield_temperature, paramDesc.far_field_flow_direction_specification, paramDesc.farfield_flow_direction, paramDesc.farfield_angle_alpha, paramDesc.farfield_angle_beta, paramDesc.turbulence_specification_spalart_allmaras, paramDesc.bc_uniform_nu_tilde, paramDesc.bc_nu_tilde_col, paramDesc.turbulence_specification_komega, paramDesc.turbulent_viscosity_ratio, paramDesc.turbulent_viscosity, paramDesc.turbulence_intensity, paramDesc.bc_uniform_tke, paramDesc.bc_uniform_omega, paramDesc.bc_tke_col, paramDesc.bc_omega_col],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.BoundaryConditionsFluid(),
  },
  [ParamGroupName.InitializationFluid]: {
    name: 'initialization_fluid',
    text: 'Initialization Fluid',
    pascalCaseName: 'InitializationFluid',
    camelCaseName: 'initializationFluid',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.initialization_type, paramDesc.uniform_v, paramDesc.uniform_t, paramDesc.potential_flow_pressure_initialization, paramDesc.uniform_p, paramDesc.turbulent_variable_initialization_type_sa, paramDesc.uniform_nu_tilde, paramDesc.turbulent_variable_initialization_type_komega, paramDesc.init_turbulent_viscosity_ratio, paramDesc.init_turbulent_viscosity, paramDesc.init_turbulence_intensity, paramDesc.uniform_tke, paramDesc.uniform_omega, paramDesc.verification_solutions, paramDesc.tgv_rho, paramDesc.tgv_p, paramDesc.tgv_v, paramDesc.shocktube_p1, paramDesc.shocktube_p4, paramDesc.shocktube_t1, paramDesc.shocktube_t4, paramDesc.shuosher_p1, paramDesc.shuosher_rho1, paramDesc.shuosher_m1, paramDesc.shuosher_p2, paramDesc.shuosher_rho2, paramDesc.shuosher_m2, paramDesc.shuosher_amp, paramDesc.shuosher_kx, paramDesc.disturbancewave_p, paramDesc.disturbancewave_t, paramDesc.disturbancewave_m, paramDesc.acousticwave_amp, paramDesc.vorticitywave_amp, paramDesc.entropywave_amp, paramDesc.disturbancewave_kx, paramDesc.normalshock_p1, paramDesc.normalshock_t1, paramDesc.normalshock_m1, paramDesc.shockvortex_p1, paramDesc.shockvortex_t1, paramDesc.shockvortex_m1, paramDesc.shockvortex_mv, paramDesc.shockvortex_xv, paramDesc.shockvortex_zv, paramDesc.shockvortex_av, paramDesc.shockvortex_bv, paramDesc.mms_k, paramDesc.mms_a, paramDesc.inviscid_vortex_minfty, paramDesc.inviscid_vortex_pinfty, paramDesc.inviscid_vortex_tinfty, paramDesc.inviscid_vortex_xv, paramDesc.inviscid_vortex_yv, paramDesc.inviscid_vortex_xflowdir, paramDesc.inviscid_vortex_yflowdir, paramDesc.inviscid_vortex_rvortex, paramDesc.inviscid_vortex_betavortex, paramDesc.existing_solution_url, paramDesc.existing_solution_id],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.InitializationFluid(),
  },
  [ParamGroupName.BladeElementAirfoilData]: {
    name: 'blade_element_airfoil_data',
    text: 'Aero Tables',
    pascalCaseName: 'BladeElementAirfoilData',
    camelCaseName: 'bladeElementAirfoilData',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.airfoil_id, paramDesc.airfoil_name, paramDesc.airfoil_radial_station, paramDesc.airfoil_performance_data],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.BladeElementAirfoilData(),
  },
  [ParamGroupName.PhysicalBehavior]: {
    name: 'physical_behavior',
    text: 'Physical Behavior',
    pascalCaseName: 'PhysicalBehavior',
    camelCaseName: 'physicalBehavior',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.physical_behavior_id, paramDesc.physical_behavior_name, paramDesc.physical_behavior_model, paramDesc.actuator_disk_model, paramDesc.actuator_disk_thrust, paramDesc.actuator_disk_torque, paramDesc.actuator_disk_radial_force, paramDesc.actuator_disk_radial_table_data, paramDesc.fan_curve_table_data, paramDesc.head_loss_coefficient, paramDesc.actuator_line_model, paramDesc.actuator_disk_rotation_rate, paramDesc.actuator_disk_bem_strategy, paramDesc.actuator_disk_blade_count, paramDesc.blade_element_pitch_schedule, paramDesc.blade_element_flap_schedule, paramDesc.blade_element_lag_schedule, paramDesc.blade_element_geometry_data, paramDesc.blade_element_reference_sound_speed, paramDesc.particle_source_model, paramDesc.source_particle_mass_injection_rate, paramDesc.source_particle_acceleration_vector],
    paramGroups: [ParamGroupName.BladeElementAirfoilData],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.PhysicalBehavior(),
  },
  [ParamGroupName.Cavitation]: {
    name: 'cavitation',
    text: 'Cavitation',
    pascalCaseName: 'Cavitation',
    camelCaseName: 'cavitation',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.cavitation_model, paramDesc.density_vapor_phase_cavitation, paramDesc.saturation_pressure_cavitation, paramDesc.n0_sauer_schnerr, paramDesc.n_reboud],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.Cavitation(),
  },
  [ParamGroupName.PorousBehavior]: {
    name: 'porous_behavior',
    text: 'Porous Model',
    pascalCaseName: 'PorousBehavior',
    camelCaseName: 'porousBehavior',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.porous_behavior_id, paramDesc.porous_behavior_name, paramDesc.zone_ids, paramDesc.porous_model_type, paramDesc.darcy_coeff, paramDesc.forchheimer_coeff, paramDesc.porous_heat_source_power, paramDesc.porous_heat_source_power_per_unit_volume, paramDesc.ref_frame_id],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.PorousBehavior(),
  },
  [ParamGroupName.Fluid]: {
    name: 'fluid',
    text: 'Fluid Flow',
    pascalCaseName: 'Fluid',
    camelCaseName: 'fluid',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [ParamGroupName.BasicFluid, ParamGroupName.Turbulence, ParamGroupName.SolutionControlsFluid, ParamGroupName.AdjointControlsFluid, ParamGroupName.SpatialDiscretizationFluid, ParamGroupName.BoundaryConditionsFluid, ParamGroupName.InitializationFluid, ParamGroupName.PhysicalBehavior, ParamGroupName.Cavitation, ParamGroupName.PorousBehavior],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.Fluid(),
  },
  [ParamGroupName.Params]: {
    name: 'params',
    text: 'Params',
    pascalCaseName: 'Params',
    camelCaseName: 'params',
    isMap: false,
    isRepeated: false,
    params: [],
    paramGroups: [ParamGroupName.Heat, ParamGroupName.Fluid],
    isExternal: false,
    isOneOf: true,
  },
  [ParamGroupName.Physics]: {
    name: 'physics',
    text: 'Physics',
    pascalCaseName: 'Physics',
    camelCaseName: 'physics',
    isMap: false,
    isRepeated: true,
    params: [],
    paramGroups: [ParamGroupName.Params],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.Physics(),
  },
  [ParamGroupName.Interfaces]: {
    name: 'interfaces',
    text: 'Interfaces',
    pascalCaseName: 'Interfaces',
    camelCaseName: 'interfaces',
    isMap: false,
    isRepeated: true,
    params: [paramDesc.sliding_interface_id, paramDesc.sliding_interface_name, paramDesc.sliding_a, paramDesc.sliding_b, paramDesc.sliding_matching_translation_transform, paramDesc.sliding_matching_periodic_rotation_angles, paramDesc.sliding_matching_periodic_center_of_rotation, paramDesc.interface_type],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.SlidingInterfaces(),
  },
  [ParamGroupName.MpCouplingOptions]: {
    name: 'mp_coupling_options',
    text: 'Multi-Physics Coupling Options',
    pascalCaseName: 'MpCouplingOptions',
    camelCaseName: 'mpCouplingOptions',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.mp_coupling_lin_sol_coupling],
    paramGroups: [],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.MultiPhysicsCouplingOptions(),
  },
  [ParamGroupName.SimulationParam]: {
    name: 'simulation_param',
    text: 'Simulation Param',
    pascalCaseName: 'SimulationParam',
    camelCaseName: 'simulationParam',
    isMap: false,
    isRepeated: false,
    params: [paramDesc.schema],
    paramGroups: [ParamGroupName.Input, ParamGroupName.MaterialEntity, ParamGroupName.VolumeEntity, ParamGroupName.General, ParamGroupName.Adjoint, ParamGroupName.Time, ParamGroupName.MotionData, ParamGroupName.ParticleGroup, ParamGroupName.MonitorPlane, ParamGroupName.BodyFrame, ParamGroupName.SurfaceName, ParamGroupName.Output, ParamGroupName.ConvergenceCriteria, ParamGroupName.EntityRelationships, ParamGroupName.ReferenceValues, ParamGroupName.AdaptiveMeshRefinement, ParamGroupName.Physics],
    isExternal: false,
    isOneOf: false,
    createNewProto: () => new ParamPb.SimulationParam(),
  },
};
