import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { newAdFloat } from '../../../lib/adUtils';
import assert from '../../../lib/assert';
import { ASSISTANT_ACCESSIBLE_VIEWS, macroEmitter } from '../../../lib/assistant/assistantMacros';
import { getPathFromView, getViewFromPartial } from '../../../lib/componentTypes/context';
import { MESH_MAX_CELLS } from '../../../lib/constants';
import newInt from '../../../lib/intUtils';
import { clampMaxMeshCount } from '../../../lib/meshingStatusUtils';
import { projectLink, projectsLink } from '../../../lib/navigation';
import { Logger } from '../../../lib/observability/logs';
import { protoToJson } from '../../../lib/proto';
import { getOrCreateAdaptiveMeshRefinement } from '../../../lib/simulationParamUtils';
import { isSimulationSteady } from '../../../lib/simulationUtils';
import * as meshgenerationpb from '../../../proto/meshgeneration/meshgeneration_pb';
import { useIsMeshPending } from '../../../recoil/pendingWorkOrders';
import { MeshPanelType, useSetMeshPanelState } from '../../../recoil/useMeshPanelState';
import { useMeshValidator } from '../../../recoil/useMeshValidator';
import { useProjectMeshList } from '../../../recoil/useProjectMeshList';
import { useProjectContext } from '../../context/ProjectContext';
import { useAssistantSyncData } from '../../hooks/assistant/useAssistantSyncData';
import { useZoomToFit } from '../../hooks/useCamera';
import { useIsLMAActive, useIsMinimalMeshMode, useSetLMA } from '../../hooks/useMesh';
import { useNodeSelect } from '../../hooks/useNodeSelect';
import { useClickRunSimulationButton, useRunSimulationButtonProps } from '../../hooks/useRunSimulation';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { useOnParamUpload, usePrepareSimulationSettings } from '../../treePanel/SimulationTreeMoreMenu';
import { useGenerateMesh } from '../../treePanel/propPanel/mesh/components/GenerateMeshButton';

import { useSelectionContext } from '@/components/context/SelectionManager';
import { useHandleLoadToSetup, useLoadToSetupDisabled } from '@/components/hooks/useLoadToSetup';
import { useSetPropertiesPanelVisible } from '@/recoil/propertiesPanel';
import useMeshMultiPart, { useSetMeshMultiPart } from '@/recoil/useMeshingMultiPart';
import { analytics } from '@/services/analytics';

const logger = new Logger('MacroSubscriber');

const ComplexityType = meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType;

const useSetStrategyToMinimalMesh = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const complexityParams = meshMultiPart?.complexityParams;
  const complexityType = complexityParams?.type || ComplexityType.MAX;

  return useCallback(() => {
    if (complexityType !== ComplexityType.MIN) {
      setMeshMultiPart((oldMeshMultiPart) => {
        const newMeshMultiPart = oldMeshMultiPart!.clone();
        const newParams = new meshgenerationpb.MeshingMultiPart_MeshComplexityParams({
          ...newMeshMultiPart!.complexityParams,
          type: meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType.MIN,
          limitMaxCells: BigInt(MESH_MAX_CELLS),
          targetCells: BigInt(0),
        });
        newMeshMultiPart.complexityParams = newParams;
        newMeshMultiPart.meshingMode = new meshgenerationpb.MeshingMultiPart_MeshingMode({
          ...newMeshMultiPart!.meshingMode,
          mode: {
            case: 'base',
            value: new meshgenerationpb.MeshingMultiPart_MeshingMode_Base(),
          },
        });
        return newMeshMultiPart;
      });
    }
  }, [complexityType, setMeshMultiPart]);
};

const useGenerateMinimalMesh = () => {
  const { projectId } = useProjectContext();
  const generateMesh = useGenerateMesh();
  const [meshList] = useProjectMeshList(projectId);
  const isMinimalMeshMode = useIsMinimalMeshMode(projectId);

  const generateMinimalMesh = useCallback(async () => {
    if (meshList.length) {
      throw new Error(`The project already has ${isMinimalMeshMode ?
        'a minimal mesh selected' : 'existing meshes'
      }. There is no need to generate a new minimal mesh.`);
    }
    try {
      await generateMesh();
    } catch (err) {
      throw new Error(err);
    }
  }, [
    generateMesh,
    isMinimalMeshMode,
    meshList,
  ]);

  return generateMinimalMesh;
};

type AdaptMeshSettings = {
  maxMeshSize: number,
  adaptBoundaryLayerCount: number,
  initialSize: number,
  growthRate: number,
}

const useCreateLumiMeshAdaptation = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setLMA } = useSetLMA();
  const meshWarning = useMeshValidator(projectId, workflowId, jobId, readOnly);
  const isLMAActive = useIsLMAActive();
  const { saveParam, simParam } = useSimulationConfig();
  const setMeshPanel = useSetMeshPanelState(projectId);
  const meshGenerating = useIsMeshPending(projectId);

  const [triggerAdapt, setTriggerAdapt] = useState<null | AdaptMeshSettings>(null);
  const { disabledReason } = meshWarning;

  const isSteady = isSimulationSteady(simParam);

  const adaptLumiMesh = useCallback(async (
    _projectId: string,
    maxMeshSize: number = 10,
    adaptBoundaryLayerCount: number = 30,
    initialSize: number = 0.000005,
    growthRate: number = 1.2,
  ) => {
    if (disabledReason) {
      throw new Error('Cannot create lumi mesh adaptation due to warnings');
    }
    if (meshGenerating) {
      throw new Error(`Cannot create lumi mesh adaption while the mesh is being generated.
        Try again after the mesh is generated.`);
    }
    if (!isSteady) {
      throw new Error('Lumi Mesh Adaptation is only available for steady simulations.');
    }

    setLMA(true);
    setTriggerAdapt({
      maxMeshSize,
      adaptBoundaryLayerCount,
      initialSize,
      growthRate,
    });

    // If we are in the mesh select or mesh edit panel, we should get away from it when entering LMA
    setMeshPanel(MeshPanelType.DETAILS);
  }, [disabledReason, meshGenerating, isSteady, setLMA, setMeshPanel]);

  useEffect(() => {
    if (isLMAActive && triggerAdapt) {
      saveParam((newParam) => {
        const amr = getOrCreateAdaptiveMeshRefinement(newParam).clone();

        // Update the Max Mesh Count size
        amr.targetCvMillions = clampMaxMeshCount(triggerAdapt.maxMeshSize);

        // Update the rest of the props for the default Adaptation Boundary Layer
        const blp = amr.boundaryLayerProfile[0];
        blp.nLayers = newInt(triggerAdapt.adaptBoundaryLayerCount);
        blp.initialSize = newAdFloat(triggerAdapt.initialSize);
        blp.growthRate = newAdFloat(triggerAdapt.growthRate);
        newParam.adaptiveMeshRefinement = amr;
        return newParam;
      });

      // Make sure we do not run this effect again accidentally
      setTriggerAdapt(null);
    }
  }, [isLMAActive, triggerAdapt, saveParam]);

  return adaptLumiMesh;
};

const useMacroSubscription = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { setScrollTo } = useSelectionContext();

  // == Hooks
  const navigate = useNavigate();
  const onParamUpload = useOnParamUpload();
  const prepareSettings = usePrepareSimulationSettings();
  const zoomToFit = useZoomToFit();
  const assistantSyncData = useAssistantSyncData();
  const runSimulation = useClickRunSimulationButton();
  const runSumulationProps = useRunSimulationButtonProps();
  const createLumiMeshAdaptation = useCreateLumiMeshAdaptation();
  const select = useNodeSelect();
  const setPropertiesPanelVisible = useSetPropertiesPanelVisible();
  const handleLoadToSetup = useHandleLoadToSetup();
  const handleLoadToSetupProps = useLoadToSetupDisabled();

  const goToProjectList = useCallback(() => {
    navigate(projectsLink());
    window.location.reload();
  }, [navigate]);

  const openProject = useCallback((projId: string, partial: ASSISTANT_ACCESSIBLE_VIEWS) => {
    const path = getPathFromView(getViewFromPartial(partial), projId);
    navigate(path || projectLink(projId));
  }, [navigate]);

  const uploadCadOrMesh = useCallback((projId: string) => {
    navigate(projectLink(projId));
    window.location.reload();
  }, [navigate]);

  const setStrategyToMinimalMesh = useSetStrategyToMinimalMesh();
  const generateMinimalMesh = useGenerateMinimalMesh();

  const pullSettings = useCallback(async () => {
    const settings = prepareSettings();
    const json = await protoToJson(settings);
    return JSON.stringify(json);
  }, [prepareSettings]);

  useEffect(() => {
    const macroSubscription = macroEmitter.subscribe(async (macroData) => {
      if (!macroData) {
        return;
      }
      const { action, args, resolve, reject } = macroData;

      try {
        let result: any;

        switch (action) {
          case 'pullUiState':
            result = assistantSyncData;
            break;
          case 'home':
            goToProjectList();
            break;
          case 'openProject':
            assert(!!args, 'Expected argument for openProject');
            openProject(args[0], args[1]);
            break;
          case 'selectNode':
            assert(!!args, 'Expected arguments for selectNode');
            setScrollTo({ node: args[0] });
            select(args[0]);
            setPropertiesPanelVisible(true);
            break;
          case 'uploadCadOrMesh':
            assert(!!args, 'Expected arguments for uploadCadOrMesh');
            result = uploadCadOrMesh(args[0]);
            break;
          case 'zoomToFit':
            result = zoomToFit();
            break;
          case 'minimalMesh':
            setStrategyToMinimalMesh();
            result = await generateMinimalMesh();
            break;
          case 'setMeshAdaptation':
            assert(!!args, 'Expected arguments for setMeshAdaptation');
            result = createLumiMeshAdaptation(
              ...args as [string, number, number, number, number],
            );
            break;
          case 'loadToSetup':
            if (handleLoadToSetupProps.disabledReason) {
              throw new Error(handleLoadToSetupProps.disabledReason);
            }
            result = await handleLoadToSetup();
            break;
          case 'pullSettings':
            result = pullSettings();
            break;
          case 'pushSettings': {
            assert(!!args, 'Expected arguments for pushSettings');
            try {
              JSON.parse(args[0]);
              const file = new File([args[0]], 'Assistant Settings', { type: 'application/json' });
              result = await onParamUpload(file, false);
            } catch (err) {
              throw new Error(err);
            }
            break;
          }
          case 'createSimulation':
            if (runSumulationProps.disabled) {
              throw new Error(`There are some errors that need to be fixed before
                running the simulation.`);
            }
            result = await runSimulation();
            break;

          // The following cases are not yet implemented.
          default:
            logger.error(`Unsupported macro: ${action}`);
            break;
        }
        resolve(result);
        if (action !== 'pullUiState') {
          analytics.assistant('Executed Macro', {
            projectId,
            workflowId,
            jobId,
            value: action,
          }, '', true);
        }
      } catch (err) {
        logger.error(`Error executing macro: ${action}`, err);
        reject(err);
        analytics.assistant('Executed Macro Failed', {
          projectId,
          workflowId,
          jobId,
          value: action,
        }, '', false, err.message);
      }
      // Reset the BehaviorSubject to prevent the same macro from being executed again.
      macroEmitter.next(null);
    });

    return () => {
      macroSubscription.unsubscribe();
    };
  }, [
    assistantSyncData,
    openProject,
    runSimulation,
    runSumulationProps.disabled,
    prepareSettings,
    goToProjectList,
    setStrategyToMinimalMesh,
    setPropertiesPanelVisible,
    setScrollTo,
    generateMinimalMesh,
    pullSettings,
    onParamUpload,
    createLumiMeshAdaptation,
    uploadCadOrMesh,
    zoomToFit,
    select,
    handleLoadToSetup,
    handleLoadToSetupProps.disabledReason,
    projectId,
    workflowId,
    jobId,
  ]);
};

/**
 * The assistant macros live in assistantMacros.ts. But the assistant macros
 * need to be able to call into React state and hooks.
 *
 * The MacroSubscriber is a React component that subscribes to the macros
 * called by the assistant, and calls the appropriate React hooks to update the
 * app state. It doesn't render anything.
 */
export const MacroSubscriber = () => {
  useMacroSubscription();

  return <></>;
};
