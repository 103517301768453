// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { matchPath } from 'react-router-dom';

import * as simulationpb from '../../proto/client/simulation_pb';
import * as workflowpb from '../../proto/workflow/workflow_pb';
import { ViewName } from '../ParaviewClientEnums';
import { geometryLink, meshLink, outputsLink, physicsLink, resultsLink, routes, solverLink } from '../navigation';

export enum CurrentView {
  SETUP = 0, // only used in the original UI (it's replaced by 4-7) in the workflow UI
  ANALYSIS = 1,
  RESULTS = 2,
  GEOMETRY = 3,
  PHYSICS = 4,
  MESH = 5,
  OUTPUTS = 6,
  SOLVER = 7,
  ADVANCED_ANALYSIS = 8, // in this page we setup DoE in the new workflow UI
}

export const currentViewMap = {
  [CurrentView.SETUP]: 'setup',
  [CurrentView.ANALYSIS]: 'analysis',
  [CurrentView.RESULTS]: 'results',
  [CurrentView.GEOMETRY]: 'geometry',

  [CurrentView.PHYSICS]: 'physics',
  [CurrentView.MESH]: 'mesh',
  [CurrentView.OUTPUTS]: 'outputs',
  [CurrentView.SOLVER]: 'solver',
  [CurrentView.ADVANCED_ANALYSIS]: 'advanced analysis',
} as const;

export type CurrentViewPartial = (typeof currentViewMap)[keyof typeof currentViewMap];

export const INTERMEDIATE_VIEWS = [
  CurrentView.PHYSICS,
  CurrentView.MESH,
  CurrentView.OUTPUTS,
  CurrentView.SOLVER,
];

export const isIntermediateView = (view: CurrentView): boolean => INTERMEDIATE_VIEWS.includes(view);

export const getNextPath = (
  projectId: string,
  view: CurrentView,
  isIgeoProject: boolean,
): string => {
  switch (view) {
    case CurrentView.GEOMETRY:
      return physicsLink(projectId);
    case CurrentView.PHYSICS:
      return isIgeoProject ? meshLink(projectId) : outputsLink(projectId);
    case CurrentView.MESH:
      return outputsLink(projectId);
    case CurrentView.OUTPUTS:
      return solverLink(projectId);
    default:
      return '';
  }
};

export const getViewFromPartial = (partial: CurrentViewPartial): CurrentView => {
  switch (partial) {
    case 'geometry':
      return CurrentView.GEOMETRY;
    case 'setup':
      return CurrentView.SETUP;
    case 'analysis':
      return CurrentView.ANALYSIS;
    case 'physics':
      return CurrentView.PHYSICS;
    case 'mesh':
      return CurrentView.MESH;
    case 'outputs':
      return CurrentView.OUTPUTS;
    case 'solver':
      return CurrentView.SOLVER;
    case 'results':
      return CurrentView.RESULTS;
    case 'advanced analysis':
      return CurrentView.ADVANCED_ANALYSIS;
    default:
      throw Error('Invalid partial type');
  }
};

export const getPathFromView = (view: CurrentView, projectId: string): string => {
  switch (view) {
    case CurrentView.GEOMETRY:
      return geometryLink(projectId);
    case CurrentView.PHYSICS:
      return physicsLink(projectId);
    case CurrentView.MESH:
      return meshLink(projectId);
    case CurrentView.OUTPUTS:
      return outputsLink(projectId);
    case CurrentView.SOLVER:
      return solverLink(projectId);
    case CurrentView.RESULTS:
      return resultsLink(projectId);
    default:
      return '';
  }
};

export const getIntermediaryPathPartialFromView = (view: CurrentView) => {
  switch (view) {
    case CurrentView.PHYSICS:
      return 'physics';
    case CurrentView.MESH:
      return 'mesh';
    case CurrentView.OUTPUTS:
      return 'outputs';
    case CurrentView.SOLVER:
      return 'solver';
    default:
      throw Error('Invalid intermediary view');
  }
};

export const getViewFromPath = (path: string): CurrentView => {
  if (matchPath(routes.physics, path)) {
    return CurrentView.PHYSICS;
  }
  if (matchPath(routes.mesh, path)) {
    return CurrentView.MESH;
  }
  if (matchPath(routes.outputs, path)) {
    return CurrentView.OUTPUTS;
  }
  if (matchPath(routes.solver, path)) {
    return CurrentView.SOLVER;
  }
  if (matchPath(routes.advancedAnalysisV1, path) || matchPath(routes.advancedAnalysisV2, path)) {
    return CurrentView.ADVANCED_ANALYSIS;
  }
  return CurrentView.SETUP;
};

export type ProjectContextType = {
  projectId: string;
  workflowId: string;
  jobId: string;
  readOnly: boolean;
  geometryId: string;
  // Called to update the simulation parameters.
  onParamUpdate: (newParam: simulationpb.SimulationParam) => void;
  asyncOnParamUpdate: (newParam: simulationpb.SimulationParam) => Promise<void>;
  // Called to update the workflow config.
  onNewWorkflowConfig: (newConfig: workflowpb.Config) => void;
  copyToSetup: () => Promise<void>;
};

export const getParaviewViewName = (currentView: CurrentView) => (
  (INTERMEDIATE_VIEWS.includes(currentView) || currentView === CurrentView.SETUP) ?
    ViewName.SETUP :
    ViewName.SOLUTION
);
