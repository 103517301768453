// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import {
  DefaultValue,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import { viewStateFixture } from '../../lib/fixtures';
import { newNode, traverseTreeNodes } from '../../lib/paraviewUtils';
import { RecoilProjectKey } from '../../lib/persist';
import { isTestingEnv } from '../../lib/testing/utils';
import { DEFAULT_FILTER_ROOT } from '../../lib/visUtils';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { meshMetadataSelector } from '../meshState';
import { newMeshKeys, paraviewInitialSettingsState, paraviewSettingsKeyPrefix } from '../paraviewState';
import { viewStateAtomFamily } from '../useViewState';

import { activeVisUrlState } from './activeVisUrl';

/**
 * A selector to get just the visualization filter state.
 * It depends on the paraview initial settings state,
 * which contains a list of all the vis filters in each project.
*/
export const filterStateSelector = selectorFamily<ParaviewRpc.TreeNode, RecoilProjectKey>({
  key: 'filterStateSelector',
  get: (key: RecoilProjectKey) => ({ get }) => {
    if (isTestingEnv()) {
      return viewStateFixture().root;
    }
    const activeUrl = get(activeVisUrlState(key));
    const metadata = get(meshMetadataSelector({ projectId: key.projectId, meshUrl: activeUrl }));
    const meshKeys = newMeshKeys(paraviewSettingsKeyPrefix, activeUrl, metadata);

    const paraviewState = get(paraviewInitialSettingsState({ projectId: key.projectId, meshKeys }));

    const param: ParaviewRpc.TreeNodeParam = {
      typ: ParaviewRpc.TreeNodeType.READER,
      url: activeUrl,
      fvmparams: null,
      customexp: null,
    };
    const displayProps: ParaviewRpc.DisplayProps = {
      reprType: 'Surface',
      displayVariable: null,
      highlightNeighborEdges: false,
    };

    const root = {
      ...newNode(param, null, false, displayProps),
      name: 'Reader',
      id: DEFAULT_FILTER_ROOT,
      child: paraviewState.filters,
    };

    return root;
  },
  set: (key: RecoilProjectKey) => ({ get, set }, newVal) => {
    const { projectId, workflowId, jobId } = key;
    const activeUrl = get(activeVisUrlState({ projectId, workflowId, jobId }));
    const metadata = get(meshMetadataSelector({ projectId, meshUrl: activeUrl }));
    const meshKeys = newMeshKeys(paraviewSettingsKeyPrefix, activeUrl, metadata);
    const pvSettings = paraviewInitialSettingsState({ projectId, meshKeys });
    if (newVal instanceof DefaultValue) {
      set(pvSettings, (settings) => ({ ...settings, filters: [] }));
    } else {
      set(pvSettings, (settings) => ({ ...settings, filters: newVal?.child ?? [] }));
      // Set the viewState too, so that when the user switches to Paraview the filters show up.
      set(viewStateAtomFamily(projectId), (prevViewState) => {
        // If the viewState is currently null, we don't need to worry about updating it in recoil.
        // In that case, Paraview will build the viewState using the paraviewInitialSettingsState.
        if (prevViewState === null) {
          return prevViewState;
        }
        return {
          ...prevViewState,
          root: { ...prevViewState.root, child: newVal?.child ?? [] },
          // we only cache 1 viewState per projectId, so we need to update the activeUrl so that
          // Paraview knows if the current viewState is stale and it needs to rebuild it.
          path: activeUrl,
        };
      });
    }
  },
});

/**
 * A map of filter ids to visibilities.
 * This is easier to work with than a tree for some operations.
 * */
export const filterVisibilitiesState = selectorFamily<Map<string, boolean>, RecoilProjectKey>({
  key: 'filterVisibilitiesState',
  get: (key: RecoilProjectKey) => ({ get }) => {
    const filterState = get(filterStateSelector(key));
    const visibilities = new Map<string, boolean>();
    traverseTreeNodes(filterState, (node) => {
      visibilities.set(node.id, node.visible);
    });
    return visibilities;
  },
});

export const useFilterVisibilitiesValue = (
  key: RecoilProjectKey,
) => useRecoilValue(filterVisibilitiesState(key));

export const useFilterState = (key: RecoilProjectKey) => useRecoilState(filterStateSelector(key));
export const useSetFilterState = (
  key: RecoilProjectKey,
) => useSetRecoilState(filterStateSelector(key));
export const useFilterStateValue = (
  key: RecoilProjectKey,
) => useRecoilValue(filterStateSelector(key));

export const useResetFilterState = (
  key: RecoilProjectKey,
) => useResetRecoilState(filterStateSelector(key));
