// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';

import * as jwt from '../../../lib/jwt';
import * as persist from '../../../lib/persist';
import { syncUserStateEffect } from '../../../lib/recoilSync';
import { isTestingEnv } from '../../../lib/testing/utils';
import * as userstatepb from '../../../proto/userstate/userstate_pb';

// #region: Constants

const frontendRoleKey = 'frontendRole';

// #region: Helper Functions

function serialize(val: userstatepb.SystemRole) {
  return new userstatepb.FrontendRole({ role: val }).toBinary();
}

function deserialize(val: Uint8Array) {
  if (val.length) {
    const result = userstatepb.FrontendRole.fromBinary(val).role;
    return result;
  }
  const js = jwt.loadSessionJwt();
  return jwt.systemRole(js!);
}

const frontendRoleStateRpc = atom<userstatepb.SystemRole>({
  key: frontendRoleKey,
  // The default value for this global atom is set in `persist.getUserStateEffect(...)`
  // in `effects` the first time this state is used.
  effects: [
    syncUserStateEffect(frontendRoleKey, deserialize, serialize),
    persist.getUserStateEffect(frontendRoleKey, deserialize),
  ],
});

const frontendRoleStateTesting = atom<userstatepb.SystemRole>({
  key: `${frontendRoleKey}/testing`,
  default: userstatepb.SystemRole.USER,
});

export const frontendRoleState = isTestingEnv() ? frontendRoleStateTesting : frontendRoleStateRpc;

/**
 * Atom value returns true if the user is a staff member
 */

export const staffRoleState = selector<boolean>({
  key: 'staffRoleState',
  get: async ({ get }) => {
    const role = get(frontendRoleState);
    return role === userstatepb.SystemRole.STAFF || role === userstatepb.SystemRole.ADMIN;
  },
});

// #region: Hooks

export function useFrontendRole() {
  return useRecoilState(frontendRoleState);
}

/**
 * @returns true if the user has the staff or admin system role
 * */
export function useIsStaff() {
  return useRecoilValue(staffRoleState);
}
