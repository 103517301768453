// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useMemo } from 'react';

import { CurrentView } from '../../lib/componentTypes/context';
import { ConditionalFeature } from '../../proto/frontend/frontend_pb';
import { useCurrentUser } from '../../recoil/useAccountInfo';
import { useCurrentView } from '../../state/internal/global/currentView';

export const useAssistantEnabled = () => {
  const currentView = useCurrentView();

  // check if ai assistant is enabled
  const conditionalFeatures = useCurrentUser()?.conditionalFeatures;
  const aiAssistantEnabled = conditionalFeatures
    ?.includes(ConditionalFeature.AI_ASSISTANT) ?? false;

  return useMemo(() => aiAssistantEnabled && [
    CurrentView.ADVANCED_ANALYSIS,
    CurrentView.GEOMETRY,
    CurrentView.SETUP,
  ].includes(currentView), [aiAssistantEnabled, currentView]);
};
