// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import { CurrentView } from '../../../lib/componentTypes/context';
import { SimulationTreeNode } from '../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { newSimulationTree } from '../../../lib/simulationTree/utils';
import { onGeometryTabSelector } from '../../../recoil/geometry/geometryState';
import { workflowFlagState } from '../../../workflowFlag';
import { currentViewState } from '../global/currentView';

import { explorationTreeSelector } from './exploration';
import { geoModificationsTreeSelector } from './geometryModifications';
import { setupTreeSelector } from './setup';

const combinedSetupTreeSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'combinedSetupTreeSelector',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const workflowFlag = get(workflowFlagState);
    if (get(onGeometryTabSelector) && !workflowFlag) {
      return newSimulationTree([]);
    }
    const setupTree = get(setupTreeSelector(key));
    const explorationTree = get(explorationTreeSelector(key));

    // The root of a tree that organizes the simulation settings
    return newSimulationTree([setupTree, explorationTree]);
  },
  dangerouslyAllowMutability: true,
});

export const simulationTreeSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'simulationTreeSelector',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const workflowFlag = get(workflowFlagState);
    if (get(currentViewState) === CurrentView.GEOMETRY && !workflowFlag) {
      return get(geoModificationsTreeSelector(key));
    }

    return get(combinedSetupTreeSelector(key));
  },
  dangerouslyAllowMutability: true,
});

export function useSimulationTree(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(simulationTreeSelector({ projectId, workflowId, jobId }));
}

export function useModificationsTree(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(geoModificationsTreeSelector({ projectId, workflowId, jobId }));
}
