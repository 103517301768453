// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { atom, atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import * as rpc from '../../../lib/rpc';
import * as frontendpb from '../../../proto/frontend/frontend_pb';

export enum ProjectDeniedStatus {
  NONE = 0,
  DELETED = 1,
  NO_ACCESS = 2,
}

const projectDeniedDialogState = atom<{
  open: boolean,
  type: ProjectDeniedStatus,
  projectId: string,
}>({
  key: 'ProjectDeniedDialogState',
  default: {
    open: false,
    type: ProjectDeniedStatus.NONE,
    projectId: '',
  },
});

export function useProjectDeniedDialog() {
  return useRecoilState(projectDeniedDialogState);
}
export function useSetProjectDeniedDialog() {
  return useSetRecoilState(projectDeniedDialogState);
}

const projectShareDialogState = atom<{ open: boolean, projectId: string }>({
  key: 'projectShareDialogState',
  default: {
    open: false,
    projectId: '',
  },
});

export function useProjectShareDialog() {
  return useRecoilState(projectShareDialogState);
}

export function useSetProjectShareDialog() {
  return useSetRecoilState(projectShareDialogState);
}

export const rpcRefreshSharedState = (projectId: string) => {
  const req = new frontendpb.RefreshSharedStateRequest({ projectId });
  return rpc.callRetry('RefreshSharedState', rpc.client.refreshSharedState, req);
};

// Incrementing this recoil value will force the paraview view state to sync the nodes by getting
// the most recent data from the sessionstate. This is used when a Viewer wants to reset their
// state to the owner's state and it initiates the RefreshSharedState rpc call. The
// RefreshSharedState call successfully triggers the sessionstate updates but the paraview
// component was not intercepting the changes, unless they were happening on the initial page load.
export const forceViewStateSyncKeyState = atomFamily<number, string | undefined>({
  key: 'ForceViewStateSyncKeyState',
  default: 0,
});
export function useForceViewStateSyncKeyValue(projectId?: string) {
  return useRecoilValue(forceViewStateSyncKeyState(projectId));
}
export function useForceViewStateSync(projectId?: string) {
  const setForceViewStateSyncKey = useSetRecoilState(forceViewStateSyncKeyState(projectId));
  return useCallback(
    () => setForceViewStateSyncKey((oldValue) => oldValue + 1),
    [setForceViewStateSyncKey],
  );
}
