// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { ParamGroupName, paramGroupDesc } from '../SimulationParamDescriptor';
import * as entitypb from '../proto/client/entity_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';

import { getOrCreateEntityRelationship } from './entityRelationships';
import { initParamGroupProto } from './initParam';
import { getFluid, getHeat } from './physicsUtils';
import * as rpc from './rpc';

/**
 * Return a SimulationParam object from the workflow config, if it exists.  If it doesn't,
 * throw an error.
 */
export function getParamFromConfig(config: workflowpb.Config): simulationpb.SimulationParam {
  const param = config.jobConfigTemplate?.typ.case === 'simulationParam' ?
    config.jobConfigTemplate.typ.value : undefined;
  if (!param) {
    throw Error(`No client param found in ${config.toJsonString()}`);
  }
  return param;
}

/**
 * Convert a SimulationParam proto object to a safe proto3-format json.
 * (https://developers.google.com/protocol-buffers/docs/proto3#json).
 */
export async function simulationParamToJson(
  simParam: simulationpb.SimulationParam,
): Promise<string> {
  const req = new frontendpb.SimulationParamToJSONRequest({
    simulationParam: simParam,
  });
  const reply = await rpc.callRetry('ClientParamToJSON', rpc.client.simulationParamToJSON, req);
  return reply.json;
}

/** Get or Create functions */
export function getOrCreateConvergenceCriteria(
  param: simulationpb.SimulationParam,
): simulationpb.ConvergenceCriteria {
  if (!param.convergenceCriteria) {
    param.convergenceCriteria = new simulationpb.ConvergenceCriteria();
  }

  return param.convergenceCriteria!;
}

export function getOrCreateGeneral(param: simulationpb.SimulationParam): simulationpb.General {
  if (!param.general) {
    param.general = initParamGroupProto(
      new simulationpb.General(),
      paramGroupDesc[ParamGroupName.General],
    );
  }
  return param.general!;
}

export function getOrCreateTime(param: simulationpb.SimulationParam): simulationpb.Time {
  if (!param.time) {
    param.time = initParamGroupProto(
      new simulationpb.Time(),
      paramGroupDesc[ParamGroupName.Time],
    );
  }
  return param.time!;
}

export function getOrCreateInput(param: simulationpb.SimulationParam): simulationpb.Input {
  if (!param.input) {
    param.input = initParamGroupProto(
      new simulationpb.Input(),
      paramGroupDesc[ParamGroupName.Input],
    );
  }
  return param.input!;
}

export function getOrCreateReferenceValues(
  param: simulationpb.SimulationParam,
): simulationpb.ReferenceValues {
  if (!param.referenceValues) {
    param.referenceValues = initParamGroupProto(
      new simulationpb.ReferenceValues(),
      paramGroupDesc[ParamGroupName.ReferenceValues],
    );
  }
  return param.referenceValues!;
}

export function getOrCreateSolutionOutput(
  param: simulationpb.SimulationParam,
): simulationpb.Output {
  if (!param.output) {
    param.output = initParamGroupProto(
      new simulationpb.Output(),
      paramGroupDesc[ParamGroupName.Output],
    );
  }
  return param.output!;
}

export function getOrCreateAdaptiveMeshRefinement(
  param: simulationpb.SimulationParam,
): simulationpb.AdaptiveMeshRefinement {
  if (!param.adaptiveMeshRefinement) {
    param.adaptiveMeshRefinement = initParamGroupProto(
      new simulationpb.AdaptiveMeshRefinement(),
      paramGroupDesc[ParamGroupName.AdaptiveMeshRefinement],
    );
  }
  return param.adaptiveMeshRefinement!;
}

export function getOrCreateMpCouplingOptions(
  param: simulationpb.SimulationParam,
): simulationpb.MultiPhysicsCouplingOptions {
  if (!param.mpCouplingOptions) {
    param.mpCouplingOptions = initParamGroupProto(
      new simulationpb.MultiPhysicsCouplingOptions(),
      paramGroupDesc[ParamGroupName.MpCouplingOptions],
    );
  }
  return param.mpCouplingOptions!;
}

// Replaces getFvmParam calls everywhere
export function getSimulationParam(config: workflowpb.Config): simulationpb.SimulationParam {
  const param = getParamFromConfig(config);

  // Initialize first-class settings
  getOrCreateGeneral(param);
  getOrCreateTime(param);
  getOrCreateInput(param);
  getOrCreateReferenceValues(param);
  getOrCreateSolutionOutput(param);
  getOrCreateAdaptiveMeshRefinement(param);
  getOrCreateEntityRelationship(param);

  return param;
}

export function setMeshId(input: simulationpb.Input | undefined, id: string) {
  if (input) {
    const identifier = input.meshIdentifier;
    if (identifier) {
      identifier.id = id;
    } else {
      input.meshIdentifier = (new entitypb.EntityIdentifier({ id }));
    }
  }
}

export function setInputMeshUrl(input: simulationpb.Input | undefined, url: string) {
  if (input) {
    input.url = url;
  }
}

// This function is used to pruning fields that are not needed by LcVis to generate the
// visualization filters.
export function cleanSimParamForLcVis(simParam: simulationpb.SimulationParam) {
  simParam.interfaces = [];
  // Unless we are doing motion preview, this is not really needed.
  simParam.motionData = [];
  simParam.convergenceCriteria = new simulationpb.ConvergenceCriteria();
  // These names were appearning in a random order and forcing filters to ger needlessly
  // recalculated.
  simParam.surfaceName = {};
  simParam.physics.forEach((physics) => {
    if (physics.params.value?.slidingInterfaces) {
      physics.params.value.slidingInterfaces = [];
    }
    if (physics.params.value?.periodicPair) {
      physics.params.value.periodicPair = [];
    }
    getFluid(physics)?.boundaryConditionsFluid?.filter((bc) => (
      bc.physicalBoundary === simulationpb.PhysicalBoundary.FARFIELD
    ));
    getFluid(physics)?.boundaryConditionsFluid?.forEach((bc) => {
      if (bc.physicalBoundary !== simulationpb.PhysicalBoundary.FARFIELD) {
        bc.surfaces = [];
      }
    });
    const heat = getHeat(physics);
    if (heat?.boundaryConditionsHeat) {
      heat.boundaryConditionsHeat = [];
    }
  });
}

export function isPrimalSimulation(param: simulationpb.SimulationParam) {
  const { floatType } = getOrCreateGeneral(param);
  return floatType === simulationpb.FloatType.DOUBLE;
}

export function isAdjointSimulation(param: simulationpb.SimulationParam) {
  const { floatType } = getOrCreateGeneral(param);
  return floatType === simulationpb.FloatType.ADA1D;
}
