// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { Empty } from '@bufbuild/protobuf';
import { selector, useRecoilValue } from 'recoil';

import { AuthState } from '../../../lib/AuthInfoCallback';
import * as rpc from '../../../lib/rpc';
import { SampleProjectCategory } from '../../../lib/sampleProjects';
import * as frontendpb from '../../../proto/frontend/frontend_pb';
import { authInfoV2State } from '../auth/authInfo';

const sampleProjectsState = selector<frontendpb.ListSampleProjectsReply>({
  key: 'sampleProjectsState',
  get: async ({ get }) => {
    // Do not fire the request if the user is not authenticated
    if (get(authInfoV2State).authState !== AuthState.AUTHENTICATED) {
      return new frontendpb.ListSampleProjectsReply();
    }
    return rpc.callRetry('listSampleProjects', rpc.client.listSampleProjects, new Empty());
  },
  dangerouslyAllowMutability: true,
});

/**
 * This function processes the category received from the backend and ensures it matches
 * the `SampleProjectCategory` enum. If the input category is unrecognized, a default
 * category is returned.
 */
const ensureCorrectCategory = (category: string): SampleProjectCategory => {
  const respectiveCategory = Object.values(SampleProjectCategory).find((item) => item === category);

  return respectiveCategory || SampleProjectCategory.UNCATEGORIZED;
};

export function useSampleProjectsValue() {
  const { sampleProjects } = useRecoilValue(sampleProjectsState);

  return {
    sampleProjects: [
      {
        projectId: 'external-aerodynamics-1',
        projectName: 'External Aerodynamics',
        projectDescription: '',
        teaser: 'Compressible',
        iconName: 'airplaneOutline',
        documentationUrl: '',
        position: 1,
        category: SampleProjectCategory.TEMPLATE,
        comingSoon: true,
      },
      {
        projectId: 'external-aerodynamics-2',
        projectName: 'External Aerodynamics',
        projectDescription: '',
        teaser: 'Incompressible',
        iconName: 'car',
        documentationUrl: '',
        position: 2,
        category: SampleProjectCategory.TEMPLATE,
        comingSoon: true,
      },
      ...sampleProjects.map((item, index) => ({
        projectId: item.projectId,
        projectName: item.projectName,
        projectDescription: item.projectDescription,
        teaser: item.shortDescription ?? '',
        imageUrl: item.imageUrl ?? '', // TODO: Add default image
        documentationUrl: item.documentationUrl ?? '',
        position: index + 1,
        category: ensureCorrectCategory(item.category),
        comingSoon: false,
      })),
    ],
  };
}
