// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ParamGroupName, paramGroupDesc } from '@/SimulationParamDescriptor';
import { DataSelect } from '@/components/Form/DataSelect';
import LabeledInput from '@/components/Form/LabeledInput';
import { CollapsibleNodePanel } from '@/components/Panel/CollapsibleNodePanel';
import { ParamForm } from '@/components/ParamForm';
import { useProjectContext } from '@/components/context/ProjectContext';
import NodeTable from '@/components/treePanel/NodeTable';
import { SelectOption } from '@/lib/componentTypes/form';
import { NodeTableType } from '@/lib/nodeTableUtil';
import { GENERAL_SETTINGS_NODE_ID } from '@/lib/simulationTree/node';
import { useFluidPhysics } from '@/model/hooks/useFluidPhysics';
import { useGeneral } from '@/model/hooks/useGeneral';
import { useHeatPhysics } from '@/model/hooks/useHeatPhysics';
import * as simulationpb from '@/proto/client/simulation_pb';
import { useOutputNodes } from '@/recoil/outputNodes';
import { useSimulationParamScope } from '@/state/external/project/simulation/paramScope';

export const AdjointOutput = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == State
  const { adjoint, setAdjoint } = useGeneral(projectId, workflowId, jobId, readOnly);
  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);

  // Make selection options from compatible output nodes.
  const outputOptions: SelectOption<string>[] = [];

  outputNodes.nodes.forEach((node) => {
    switch (node.nodeProps.case) {
      case 'surfaceAverage':
      case 'force':
      case 'derived':
      case 'volumeReduction':
        outputOptions.push({
          name: node.name,
          value: node.id,
          selected: adjoint?.adjointOutput?.id === node.id,
        });
        break;
      default:
        break;
    }
  });

  return (
    <LabeledInput
      help="Output of interest for which geometric sensitivities are computed."
      key="adjoint-output"
      label="Adjoint Output">
      <DataSelect
        asBlock
        disabled={readOnly || !outputOptions.length}
        onChange={async (value: string) => {
          const newAdjoint = adjoint!.clone();
          newAdjoint.adjointOutput!.id = value;
          await setAdjoint(newAdjoint);
        }}
        options={outputOptions}
        size="small"
        tooltip={!outputOptions.length ?
          'There are no "Surface", "Volume", or "Custom" outputs defined.' : ''}
      />
    </LabeledInput>
  );
};

export const AdjointSensitivitySurfaces = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Hooks
  const { adjoint } = useGeneral(projectId, workflowId, jobId, readOnly);

  // == State
  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);

  // Make selection options from compatible output nodes.
  const outputOptions: SelectOption<string>[] = [];

  outputNodes.nodes.forEach((node) => {
    switch (node.nodeProps.case) {
      case 'surfaceAverage':
      case 'force':
      case 'derived':
      case 'volumeReduction':
        outputOptions.push({
          name: node.name,
          value: node.id,
          selected: adjoint?.adjointOutput?.id === node.id,
        });
        break;
      default:
        break;
    }
  });

  return (
    <CollapsibleNodePanel
      heading="Sensitivity Surfaces"
      nodeId={GENERAL_SETTINGS_NODE_ID}
      panelName="sensitivity surfaces">
      <NodeTable
        editable={!readOnly}
        formGroups
        nodeIds={adjoint!.surfaces}
        tableId="adjoint-sensitivity-surfaces"
        tableType={NodeTableType.SENSITIVITY_SURFACES}
        title="Geometry (Optional)"
      />
    </CollapsibleNodePanel>
  );
};

const paramAdjointControlsFluid = paramGroupDesc[ParamGroupName.AdjointControlsFluid];
const paramAdjointControlsHeat = paramGroupDesc[ParamGroupName.AdjointControlsHeat];

interface AdjointSolverSettingsProps {
  physicsId: string;
}

export const AdjointFluidSolverSettings = (props: AdjointSolverSettingsProps) => {
  // == Props
  const { physicsId } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == State
  const {
    getParamScope,
    adjointSolutionControls,
    setAdjointSolutionControls,
  } = useFluidPhysics(projectId, workflowId, jobId, readOnly, physicsId);

  // == Data
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const physicsScope = getParamScope(paramScope);

  if (!adjointSolutionControls) {
    return null;
  }

  return (
    <ParamForm<simulationpb.AdjointControlsFluid>
      group={paramAdjointControlsFluid}
      onUpdate={setAdjointSolutionControls}
      paramScope={physicsScope}
      proto={adjointSolutionControls}
      readOnly={readOnly}
    />
  );
};

export const AdjointHeatSolverSettings = (props: AdjointSolverSettingsProps) => {
  // == Props
  const { physicsId } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == State
  const {
    getParamScope,
    adjointSolutionControls,
    setAdjointSolutionControls,
  } = useHeatPhysics(projectId, workflowId, jobId, readOnly, physicsId);

  // == Data
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const physicsScope = getParamScope(paramScope);

  if (!adjointSolutionControls) {
    return null;
  }

  return (
    <ParamForm<simulationpb.AdjointControlsHeat>
      group={paramAdjointControlsHeat}
      onUpdate={setAdjointSolutionControls}
      paramScope={physicsScope}
      proto={adjointSolutionControls}
      readOnly={readOnly}
    />
  );
};
