// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVManipulationMode, LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';

import { LcvWidget } from './LcvWidget';

export interface LcvSphereWidgetState {
  center: [number, number, number],
  radius: number,
}

export class LcvSphereWidget extends LcvWidget {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, sessionHandle, 'sphere');
  }

  setState(newState: Partial<LcvSphereWidgetState>) {
    if (newState.center) {
      this.setParam(
        'center',
        LCVType.kLCVDataTypeFloat3,
        newState.center,
      );
    }
    if (newState.radius) {
      this.setParam(
        'radius',
        LCVType.kLCVDataTypeFloat,
        newState.radius,
      );
    }
  }

  getState(): LcvSphereWidgetState {
    return {
      center: this.getProperty('center', LCVType.kLCVDataTypeFloat3),
      radius: this.getProperty('radius', LCVType.kLCVDataTypeFloat),
    };
  }

  showControls({ manipulationMode }: { manipulationMode?: LCVManipulationMode } = {}) {
    const DEFAULT_MANIPULATION_MODE = (
      LCVManipulationMode.kLCVManipulationModeTranslate | // eslint-disable-line no-bitwise
      LCVManipulationMode.kLCVManipulationModeScale
    );

    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      manipulationMode ?? DEFAULT_MANIPULATION_MODE,
    );
  }

  hideControls(): void {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeNone,
    );
  }

  /**
   * Set the callback to be invoked whenever the box widget state changes.
   * @param callback a callback to be invoked whenever the box widget state changes.
   */
  setOnUpdateCallback(
    callback: ((newState: LcvSphereWidgetState, message?: string) => void) | null,
  ) {
    if (!callback) {
      this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, null);
      return;
    }
    const internalCallback = (
      _lcv: LcvModule,
      _session: LCVObject,
      _obj: LCVObject,
      message: string,
    ) => {
      const newState = this.getState();
      callback(newState, message);
    };
    this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, internalCallback);
  }
}
