// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView, INTERMEDIATE_VIEWS } from '../../../lib/componentTypes/context';
import { LeveledMessage, levelToRank } from '../../../lib/notificationUtils';
import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { SimulationValidator, validateSimulation } from '../../../lib/simulationValidation';
import { EntityGroupData, entityGroupDataSelector } from '../../../recoil/entityGroupState';
import { onGeometryTabSelector } from '../../../recoil/geometry/geometryState';
import { GeometryTags } from '../../../recoil/geometry/geometryTagsObject';
import { geometryTagsState } from '../../../recoil/geometry/geometryTagsState';
import { pendingMonitorPlanesState } from '../../../recoil/monitorPlanes';
import { geometryPendingState } from '../../../recoil/pendingWorkOrders';
import { initDataSelector } from '../../../recoil/useInitData';
import { meshReadyStateAtom } from '../../../recoil/useMeshReadyState';
import { currentViewState } from '../../internal/global/currentView';
import { intermediateTreeSelector } from '../../internal/tree/setup';

import { simulationBoundaryNamesState } from './simulation/param/boundaryNames';
import { projectValidationDataState } from './validationData';

export function geometryTagsWarningsForNodeId(
  geometryTags: GeometryTags,
  nodeId: string,
) {
  const msg = geometryTags.faceTagIssue(nodeId);
  if (msg) {
    return msg;
  }
  const msg2 = geometryTags.bodyTagIssue(nodeId);
  if (msg2) {
    return msg2;
  }
  return undefined;
}

function addGeometryTagsWarnings(
  validator: SimulationValidator,
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  entityGroupData.groupMap.traverse((group) => {
    const msg = geometryTagsWarningsForNodeId(geometryTags, group.id);
    if (msg) {
      validator.addWarning(group.id, msg);
    }
  });
}

export const projectValidatorState = selectorFamily<
  SimulationValidator,
  SectionRecoilKey
>({
  key: 'projectValidatorState',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const [
      onGeometryTab,
      geometryTagsRecoil,
      boundaryConditionNames,
      geometryPending,
      initData,
      meshReady,
      simData,
      entityGroupDataRecoil,
      pendingMonitorPlanes,
    ] = get(waitForAll([
      onGeometryTabSelector,
      geometryTagsState({ projectId: key.projectId }),
      simulationBoundaryNamesState(key),
      geometryPendingState(key.projectId),
      initDataSelector(key),
      meshReadyStateAtom(key),
      projectValidationDataState(key),
      entityGroupDataSelector(key),
      pendingMonitorPlanesState,
    ]));
    const validator = validateSimulation(
      {
        ...simData,
        boundaryConditionNames,
        geometryPending,
        initData,
        meshReady,
        pendingMonitorPlanes,
      },
    );
    // Due to the fact that we treat the KV store differently in the geometry tab than in other app
    // tabs, we need to make sure we fetch the correct state of the geometry tags and the
    // entityGroupData. projectValidationDataState already handles the fetching of the correct data
    // for the setup tab. However, in order to get the correct warnings for the geometry tags,
    // we need to make sure we are using the correct state, i.e. the one from coming directly from
    // recoil if we are in the geometry tab.
    const geometryTags = onGeometryTab ? geometryTagsRecoil : simData.geometryTags;
    const entityGroupData = onGeometryTab ? entityGroupDataRecoil : simData.entityGroupData;
    addGeometryTagsWarnings(validator, geometryTags, entityGroupData);
    return validator;
  },
  dangerouslyAllowMutability: true,
});

export function useProjectValidator(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(projectValidatorState({ projectId, workflowId, jobId }));
}

const errorRank = levelToRank('error');

type LevelMessageMap = Map<string, LeveledMessage[]>;
type LevelMessageViewMap = Map<CurrentView, LevelMessageMap>;

const allWarningsState = selectorFamily<LevelMessageViewMap, SectionRecoilKey>({
  key: 'allWarningsState',
  get: (key: SectionRecoilKey) => ({ get }) => {
    const { projectId, workflowId, jobId } = key;
    const [
      validator,
    ] = get(waitForAll([
      projectValidatorState({ projectId, workflowId, jobId }),
    ]));
    const views = [...INTERMEDIATE_VIEWS, CurrentView.ADVANCED_ANALYSIS];
    const intermediateTrees = get(waitForAll(
      views.map((view) => intermediateTreeSelector({ ...key, currentView: view })),
    ));

    const warnings: LevelMessageViewMap = new Map();
    const setupWarnings: LevelMessageMap = new Map();
    views.forEach((view, index) => {
      const messages = validator.messageMap;
      const viewWarnings: LevelMessageMap = new Map();
      messages.forEach((collector, id) => {
        if (intermediateTrees[index].nodeIds.has(id) && collector.messages.length > 0) {
          const filteredMessages = collector.messages
            .filter((message) => levelToRank(message.level) >= errorRank);
          if (filteredMessages.length > 0) {
            viewWarnings.set(
              id,
              filteredMessages,
            );
            // The setup tab is a special case where we want to show all warnings
            setupWarnings.set(id, filteredMessages);
          }
        }
      });
      warnings.set(view, viewWarnings);
    });
    warnings.set(CurrentView.SETUP, setupWarnings);
    return warnings;
  },
});

export function useAllWarnings(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(allWarningsState({ projectId, workflowId, jobId }));
}

const perTabWarnings = selectorFamily<LevelMessageMap, SectionRecoilKey>({
  key: 'perTabWarnings',
  get: (key: SectionRecoilKey) => ({ get }) => {
    const currenView = get(currentViewState);
    const warnings = get(allWarningsState(key));
    return warnings.get(currenView) ?? new Map();
  },
});

export const usePerTabWarnings = (
  projectId: string,
  workflowId: string,
  jobId: string,
) => useRecoilValue(perTabWarnings({ projectId, workflowId, jobId }));

export const useAllTabWarnings = (
  projectId: string,
  workflowId: string,
  jobId: string,
) => useRecoilValue(
  allWarningsState({ projectId, workflowId, jobId }),
);
