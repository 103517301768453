// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * This holds the value for a toggle switch which appears when we select volumes for a Frame.
 * It true, selecting the volume will also automatically select all bounding surfaces of the volume.
 */
export const autoSelectSurfacesState = atomFamily<boolean, string>({
  key: 'autoSelectSurfacesState',
  default: true,
});

export function useAutoSelectSurfaces(projectId: string) {
  return useRecoilState(autoSelectSurfacesState(projectId));
}
export function useAutoSelectSurfacesValue(projectId: string) {
  return useRecoilValue(autoSelectSurfacesState(projectId));
}
export function useSetAutoSelectSurfaces(projectId: string) {
  return useSetRecoilState(autoSelectSurfacesState(projectId));
}
