// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo, useState } from 'react';

import { CommonMenuItem, CommonMenuSeparator } from '../../../lib/componentTypes/menu';
import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { colors } from '../../../lib/designSystem';
import { expandGroups, isGroupVisible, unwrapSurfaceIds } from '../../../lib/entityGroupUtils';
import { isFarfield } from '../../../lib/farfieldUtils';
import { isGeomHealthId } from '../../../lib/geometryHealthUtils';
import { globalDisabledReason } from '../../../lib/geometryUtils';
import { assembleMenuSections, filteredMenuItems } from '../../../lib/menuUtil';
import { getAllAttachedDomains, getAllAttachedSurfaceIds } from '../../../lib/motionDataUtils';
import { getNodeTypeIcon } from '../../../lib/simulationTree/nodeIcon';
import { formatDescendantCount } from '../../../lib/simulationTree/utils';
import { useSurfaceTransparencyMenuItems } from '../../../lib/surfaceTransparencyMenuItems';
import {
  deleteTreeNodeMenuItem,
  groupTreeNodeMenuItem,
  ungroupTreeNodeMenuItem,
  visibilityToggleTreeNodeMenuItem,
} from '../../../lib/treeUtils';
import { useEntityGroupData, useNumDescendants } from '../../../recoil/entityGroupState';
import { useIsGeometryServerActive } from '../../../recoil/geometry/geometryServerStatus';
import { useGeometrySelectedFeature } from '../../../recoil/geometry/geometryState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useGeometryUsesTags } from '../../../recoil/geometry/geometryUsesTags';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useShowRowChildrenCountValue } from '../../../recoil/simulationTree/showRowChildrenCount';
import { useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useStaticVolumes } from '../../../recoil/volumes';
import environmentState from '../../../state/environment';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import VisibilityButton from '../../Paraview/VisibilityButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import CreateTagDialog from '../../dialog/CreateTag';
import { useDeleteNodeWithKeyboard } from '../../hooks/useDeleteNodeWithKeyboard';
import { useTagsInteractiveGeometry } from '../../hooks/useInteractiveGeometry';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeDrop } from '../../hooks/useNodeDrop';
import { useNodeGrouping } from '../../hooks/useNodeGrouping';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { useTagSurfaceIds } from '../../hooks/useTagSurfaceIds';
import { DraggableTreeRow } from '../DraggableTreeRow';
import { VisualCue } from '../TreeRow';

const isTagVisible = (
  visibilityRealm: Map<string, boolean>,
  surfaceIds: string[],
) => surfaceIds.every((surfaceId) => visibilityRealm.get(surfaceId) || false);

// A row displaying information about a surface group.
export const SurfaceGroupTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;
  const { id: nodeId } = node;

  // == Context
  const { viewState, visibilityMap } = useParaviewContext();
  const { projectId, workflowId, jobId, readOnly, geometryId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const showRowChildren = useShowRowChildrenCountValue();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const [selectedFeature] = useGeometrySelectedFeature(geometryId);
  const isGeoServerActive = useIsGeometryServerActive(geometryId);
  const { onNodeDrop, destinationTag } = useNodeDrop(node);

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteSurfaceGroupNode, postDeleteNodeIds } = useNodeDeletion();
  const {
    canGroup,
    canUngroup,
    groupEntities,
    ungroupEntities,
  } = useNodeGrouping();
  const {
    createTag,
    removeTags,
    isCreateTagDisabled,
    isRemoveTagDisabled,
    addVolumesToExistingTag,
    addSurfacesToExistingTag,
    mergeTags,
  } = useTagsInteractiveGeometry();

  const [createTagMode, setCreateTagMode] = useState<{
    type: 'idle';
  } | {
    type: 'create';
  } | {
    type: 'merge';
    tagIds: string[];
  }>({ type: 'idle' });
  const [tagCreationIds, setTagCreationIds] = useState<string[]>([]);
  const geoUsesTags = useGeometryUsesTags(projectId);
  const getTransparencyItems = useSurfaceTransparencyMenuItems();

  const isTag = geometryTags.isTagId(nodeId);
  const tagSurfaces = useTagSurfaceIds(nodeId);
  const tagVolumes = isTag ? geometryTags.domainsFromTag(nodeId) : [];

  const visibilityRealm = lcvisEnabled ? visibilityV2 : visibilityMap;
  const isVisible = isTag ?
    isTagVisible(visibilityRealm, tagSurfaces) :
    isGroupVisible(visibilityRealm, entityGroupData.groupMap, nodeId);

  const surfacesWithMotion = getAllAttachedSurfaceIds(
    simParam,
    { motion: 'moving' },
    geometryTags,
    entityGroupData,
  );
  const volumesWithMotion = getAllAttachedDomains(simParam, { motion: 'moving' }, geometryTags);

  const toggleIds = useMemo(() => {
    const isSelected = selectedNodeIds.includes(nodeId);

    const surfacesToHide = isTag ? tagSurfaces : [nodeId];

    // when tag is selected - hide its surfaces
    const unrolledSelectedNodeIds = isTag ?
      selectedNodeIds.flatMap((id) => (id === nodeId ? tagSurfaces : [id])) :
      selectedNodeIds;

    // Toggle all the selected IDs, if this ID is selected.
    const ids = (isSelected ? unrolledSelectedNodeIds : surfacesToHide);

    // Vis knows nothing about geometry health ids, but those ids are included as part of the
    // selection since both the heath check row and the sim tree row are selected.
    return ids.reduce((result, id) => {
      if (!isGeomHealthId(id)) {
        result.add(id);
      }
      return result;
    }, new Set<string>());
  }, [isTag, nodeId, selectedNodeIds, tagSurfaces]);

  const visControlsDisabled = lcvisEnabled ? !lcvisReady : !viewState;
  const toggleVis = useToggleVisibility(toggleIds, isVisible);

  const numDescendants = useNumDescendants(projectId, workflowId, jobId, nodeId);
  const descendantsLabel = showRowChildren ? formatDescendantCount(numDescendants) : '';

  const defaultTagDisabledReason = globalDisabledReason(
    selectedFeature,
    readOnly,
    isGeoServerActive,
  );

  const auxIcons = useMemo<IconSpec[]>(() => {
    // Find all the leaf surfaces in the group

    // Check for nodeId in the map, because there's a race condition where this component re-renders
    // after the group is removed from the entity map but before the group node is removed from the
    // control panel.
    const { groupMap } = entityGroupData;

    let groupSurfaces: string[] = [];
    let [volumesSomeIn, volumesSomeOut] = [false, false];

    if (geometryTags.isTagId(nodeId)) {
      const tagDomains = geometryTags.domainsFromTag(nodeId);

      volumesSomeIn = tagDomains.some((domain) => volumesWithMotion.has(domain));
      volumesSomeOut = tagDomains.some((domain) => !volumesWithMotion.has(domain));

      groupSurfaces = unwrapSurfaceIds([nodeId], geometryTags, entityGroupData);
    } else if (groupMap.has(nodeId)) {
      const group = groupMap.get(nodeId);
      groupSurfaces = groupMap.findDescendants(group.id);
    }

    // Determine if some member surfaces are attached and some are unattached
    const surfacesSomeIn = groupSurfaces.some((surface) => surfacesWithMotion.has(surface));
    const surfacesSomeOut = groupSurfaces.some((surface) => !surfacesWithMotion.has(surface));

    const someIn = surfacesSomeIn || volumesSomeIn;
    const someOut = surfacesSomeOut || volumesSomeOut;

    if (someIn) {
      return [{ name: 'rotatingDots', color: colors.citronGreen600, opacity: someOut ? 0.5 : 1 }];
    }

    return [];
  }, [entityGroupData, geometryTags, nodeId, surfacesWithMotion, volumesWithMotion]);

  const deleteRow = () => {
    if (deleteSurfaceGroupNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  };

  const isNodeSelected = (
    selectedNodeIds.length === 0 || (selectedNodeIds.length === 1 && selectedNodeIds[0] === node.id)
  );
  const areOnlyTagsSelected = (
    selectedNodeIds.length > 1 && selectedNodeIds.every((id) => geometryTags.isTagId(id))
  );

  const addItemOptions = useMemo<CommonMenuItem[]>(() => {
    const disabledReason = (
      isNodeSelected ? '' : 'The tag assignment can be done only when this node is selected.'
    );

    return ([
      {
        label: 'Add Volume',
        items: staticVolumes.map((volume) => ({
          label: volume.defaultName,
          onClick: async () => {
            await addVolumesToExistingTag(node.id, [volume.id]);
          },
          disabled: !!disabledReason,
          disabledReason,
        })),
        disabled: !!disabledReason,
        disabledReason,
      }, {
        label: 'Add Surface',
        items: staticVolumes.flatMap((volume) => [...volume.bounds].map((surfaceId) => {
          const surfaceName = entityGroupData.groupMap.has(surfaceId) ?
            entityGroupData.groupMap.get(surfaceId).name :
            'unknown';

          return {
            label: surfaceName,
            onClick: async () => {
              await addSurfacesToExistingTag(node.id, [surfaceId]);
            },
            disabled: !!disabledReason,
            disabledReason,
          };
        })),
        disabled: !!disabledReason,
        disabledReason,
      }, {
        label: isNodeSelected ? 'Merge With' : 'Merge',
        disabled: !isNodeSelected && !areOnlyTagsSelected,
        ...(isNodeSelected ? {
          items: geometryTags.tags.map((item) => ({
            label: item.name,
            disabled: item.id === node.id || !!disabledReason,
            disabledReason: (
              item.id === node.id ? 'The tag can\'t be merged with itself' : disabledReason
            ),
            onClick: () => {
              setCreateTagMode({ type: 'merge', tagIds: [node.id, item.id] });
            },
          })),
        } : {
          onClick: () => {
            setCreateTagMode({ type: 'merge', tagIds: selectedNodeIds });
          },
        }),

      }]);
  }, [
    addSurfacesToExistingTag,
    addVolumesToExistingTag,
    entityGroupData.groupMap,
    node.id,
    staticVolumes,
    geometryTags.tags,
    isNodeSelected,
    areOnlyTagsSelected,
    selectedNodeIds,
  ]);

  const deleteTagRow = async () => {
    const isSelected = selectedNodeIds.includes(nodeId);
    const ids = (isSelected ? selectedNodeIds : [nodeId]);
    await removeTags(ids);
    postDeleteNodeIds(ids);
  };

  useDeleteNodeWithKeyboard({
    onDelete: deleteTagRow,
    enabled: isTag,
    nodeName: node.name,
    nodeId,
  });

  // TODO: lots of duplication with Surface.tsx. Can the common stuff be factored out?
  const getContextMenuItems = () => {
    let nodeIdsToGroup: string[];
    if (selectedNodeIds.length === 0) {
      nodeIdsToGroup = [nodeId];
    } else if (selectedNodeIds.includes(nodeId)) {
      nodeIdsToGroup = selectedNodeIds;
    } else {
      nodeIdsToGroup = [];
    }
    const transparencyNodes = nodeIdsToGroup.length ? nodeIdsToGroup : [nodeId];

    const visItems = [visibilityToggleTreeNodeMenuItem(isVisible, toggleVis, visControlsDisabled)];
    const transparencyItems = getTransparencyItems(transparencyNodes, entityGroupData);

    if (isGeometryView) {
      if (!isRemoveTagDisabled && isTag) {
        const crudItems = [
          deleteTreeNodeMenuItem(deleteTagRow, isRemoveTagDisabled),
          ...addItemOptions,
        ];
        return assembleMenuSections(visItems, transparencyItems, crudItems);
      }
      if (!isTag && !isCreateTagDisabled && geoUsesTags && nodeIdsToGroup.length) {
        // Allow creating/adding tags from groups. In case we are mixing groups and individual
        // surfaces, make sure to expand the groups so that we are sending the correct surface IDs
        // to the backend.
        const surfaceIds = expandGroups(entityGroupData.leafMap)(nodeIdsToGroup);

        const groupingItems: CommonMenuItem[] = [
          {
            label: 'Add to Tag',
            disabled: !!defaultTagDisabledReason,
            disabledReason: defaultTagDisabledReason,
            items: [
              {
                label: 'New Tag...',
                onClick: () => {
                  setCreateTagMode({ type: 'create' });
                  setTagCreationIds(surfaceIds);
                },
              },
              { separator: true } as CommonMenuSeparator,
              ...(geometryTags.tags.map((tag) => ({
                label: tag.name,
                onClick: async () => {
                  await addSurfacesToExistingTag(tag.id, surfaceIds);
                },
              }))),
            ],
            startIcon: { name: 'tag' },
          },
        ];
        return assembleMenuSections(visItems, transparencyItems, groupingItems);
      }
      return assembleMenuSections(visItems, transparencyItems);
    }

    const groupingItems = filteredMenuItems([
      {
        itemConfig: groupTreeNodeMenuItem(() => groupEntities(nodeIdsToGroup)),
        shouldShow: canGroup(nodeIdsToGroup, entityGroupData),
      },
      {
        itemConfig: ungroupTreeNodeMenuItem(() => ungroupEntities(node.id)),
        shouldShow: canUngroup(node, entityGroupData),
      },
    ]);

    const crudItems = filteredMenuItems([
      {
        itemConfig: deleteTreeNodeMenuItem(deleteRow, !canDelete(node.type, node.id)),
        shouldShow: isFarfield(node.id),
      },
    ]);

    return assembleMenuSections(visItems, transparencyItems, groupingItems, crudItems);
  };

  const visButton = (
    <VisibilityButton disabled={visControlsDisabled} isVisible={isVisible} onClick={toggleVis} />
  );

  return (
    <>
      {createTagMode.type !== 'idle' && (
        <CreateTagDialog
          isOpen
          onCancel={() => {
            setCreateTagMode({ type: 'idle' });
            setTagCreationIds([]);
          }}
          onSubmit={async (name) => {
            if (createTagMode.type === 'create') {
              await createTag(name, tagCreationIds);
            } else {
              await mergeTags(name, createTagMode.tagIds);
            }
            setCreateTagMode({ type: 'idle' });
            setTagCreationIds([]);
          }}
          title={createTagMode.type === 'merge' ? 'Merge Tags' : undefined}
        />
      )}

      <DraggableTreeRow
        {...props}
        auxIcons={auxIcons}
        canMultiSelect
        dropDestinationTagNode={destinationTag}
        getContextMenuItems={getContextMenuItems}
        isDraggable={geometryTags.isTagId(nodeId)}
        label={node.name}
        manipulationType="dropOnly"
        onNodeDrop={onNodeDrop}
        primaryIcon={getNodeTypeIcon(props.node.type, { isTag })}
        propertiesControl={!isGeometryView}
        renaming={renaming}
        sublabel={descendantsLabel || undefined}
        visibilityButton={visButton}
        visualCues={[
          (geometryTags.surfacesFromTagEntityGroupId(nodeId)?.length || 0) > 0 ?
            { icon: 'cubeOutline', tooltip: 'This tag contains surfaces.' } :
            undefined,
          tagVolumes.length > 0 ?
            { icon: 'cubeSolid', tooltip: 'This tag contains volumes.' } :
            undefined,
        ].filter(Boolean) as VisualCue[]}
      />

    </>
  );
};
