// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { selector, useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';

import * as rpc from '../../../lib/rpc';
import * as librarypb from '../../../proto/library/library_pb';

const personalLibraryState = selector<librarypb.LibraryItem[] | undefined>({
  key: 'personalLibrary',
  get: async () => rpc.callRetry(
    'ListLibraryItems',
    rpc.client.listLibraryItems,
    new librarypb.ListItemsRequest(),
  ).then((resp) => resp.items).catch(() => undefined),
});

export function usePersonalLibrary() {
  return useRecoilValue(personalLibraryState);
}

export function useRefreshPersonalLibrary() {
  return useRecoilRefresher_UNSTABLE(personalLibraryState);
}
