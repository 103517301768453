// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * This state keeps the distance between the clicked tree row's top edge and the top edge
 * of the 3D viewer. This is used to show the property panel of a clicked tree row next to the row.
 */
const clickedTreeRowVisOffsetState = atom<number>({
  key: 'clickedTreeRowVisOffsetState',
  default: 0,
});

export const useSetClickedTreeRowVisOffset = () => useSetRecoilState(clickedTreeRowVisOffsetState);
export const useClickedTreeRowVisOffsetValue = () => useRecoilValue(clickedTreeRowVisOffsetState);
