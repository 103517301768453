// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useMemo } from 'react';

import { LCVKeyModifier, LCVMouseButton, LCVMouseEvent, LCVType } from '@luminarycloudinternal/lcvis';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useProjectContext } from '../../components/context/ProjectContext';
import { useSelectionContext } from '../../components/context/SelectionManager';
import { useTagsInteractiveGeometry } from '../../components/hooks/useInteractiveGeometry';
import { useNodeGrouping } from '../../components/hooks/useNodeGrouping';
import { useSubselectVisualizerMenuItems } from '../../components/treePanel/NodeSubselect/control';
import { CommonMenuItem, CommonMenuPositionTransform, CommonMenuSeparator } from '../../lib/componentTypes/menu';
import { colors, hexToRgbList } from '../../lib/designSystem';
import { expandGroups } from '../../lib/entityGroupUtils';
import { globalDisabledReason } from '../../lib/geometryUtils';
import { getHelpText } from '../../lib/keyBindings';
import { areArraysNear } from '../../lib/lang';
import { lcvResetCamera } from '../../lib/lcvis/api';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { assembleMenuSections, filteredMenuItems, filteredWithLeadIcon } from '../../lib/menuUtil';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { traverseTreeNodes } from '../../lib/paraviewUtils';
import { SelectionAction } from '../../lib/selectionUtils';
import { VisibilityInfo, getVisibilityInfo, isClipOrSlice } from '../../lib/visUtils';
import { UrlType } from '../../proto/projectstate/projectstate_pb';
import { TreeNode } from '../../pvproto/ParaviewRpc';
import { useIsAnalysisView } from '../../state/internal/global/currentView';
import { useEntityGroupData } from '../entityGroupState';
import { useGeoShowSurfacesValue } from '../geometry/geoShowSurfaces';
import { useGeoClipValue } from '../geometry/geometryClipState';
import { useIsGeometryServerActive } from '../geometry/geometryServerStatus';
import { useGeometrySelectedFeature } from '../geometry/geometryState';
import { GeometryTag } from '../geometry/geometryTagsObject';
import { useGeometryTags } from '../geometry/geometryTagsState';
import { useGeometryUsesTags } from '../geometry/geometryUsesTags';
import { useMeshUrlState } from '../meshState';
import { useEditStateValue } from '../paraviewState';
import { useEntitySelectionValue } from '../selectionOptions';
import { useSimulationTreeSubselect } from '../simulationTreeSubselect';
import { useSetCreateTagModalOpen } from '../useCreateTagModal';
import { useBackgroundColorState } from '../vis/backgroundColor';
import { useFilterState } from '../vis/filterState';
import { useSetNodeVisibility } from '../vis/useToggleVisibility';
import { StaticVolume, useStaticVolumes } from '../volumes';

import { useLcvisExplodeFactorState } from './explodeFactor';
import { useLcVisFilterClipHideEnabled } from './lcvisClipHide';
import { useLcvisVisibilityMap } from './lcvisVisibilityMap';
import { TRANSPARENCY_MODE_SUBTITLE, defaultTransparencyDialogSettings, defaultTransparencySettings, useTransparencyDialog } from './transparencySettings';

import environmentState from '@/state/environment';

export type LcvisContextMenuSettings = {
  // The id of the surface being hovered when the context menu was triggered.
  clickedId: string,
  menuOpen: boolean,
  transform: CommonMenuPositionTransform,
};

/** Recoil atom which informs the placement and open state of the LCVis context menu. */
export const lcvisMenuSettings = atom<LcvisContextMenuSettings>({
  key: 'lcvisContextMenuProps',
  default: {
    clickedId: '',
    menuOpen: false,
    transform: {},
  },
});

export const useLcvisMenuSettings = () => useRecoilState(lcvisMenuSettings);

export const useLcvisMenuSettingsValue = () => useRecoilValue(lcvisMenuSettings);

export const useSetLcvisMenuSettings = () => useSetRecoilState(lcvisMenuSettings);

/**
 * Returns the items to populate the LCVis context menu on right click.
 * Exits early if the context menu isn't open.
*/
export const useLcvisContextMenuItems = (): CommonMenuItem[] => {
  const { projectId, workflowId, jobId, geometryId, readOnly } = useProjectContext();
  const selectionContext = useSelectionContext();
  const { groupMap: entityGroupMap, leafMap } = useEntityGroupData(projectId, workflowId, jobId);
  const [visibilityMap] = useLcvisVisibilityMap({ projectId, workflowId, jobId });
  const { clickedId, menuOpen, transform } = useLcvisMenuSettingsValue();
  const [
    backgroundColor,
    setBackgroundColor,
  ] = useBackgroundColorState({ projectId, workflowId, jobId });
  const {
    activeNodeTable,
    modifySelection,
    selectedNode,
    selectedNodeIds,
    setScrollTo,
    highlightedInVisualizer,
    setSelection,
  } = selectionContext;
  const staticVolumes = useStaticVolumes(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const [explodeFactor, setExplodeFactor] = useLcvisExplodeFactorState(projectId);
  const geoClip = useGeoClipValue(projectId);

  const setCreateTagModalOpen = useSetCreateTagModalOpen();

  const geoClipActive = !!geoClip.active;
  const {
    canGroupSelectedNodes,
    canUngroupSelectedNode,
    groupEntities,
    ungroupEntities,
  } = useNodeGrouping();

  const {
    isCreateTagDisabled,
    addSurfacesToExistingTag,
    addVolumesToExistingTag,
  } = useTagsInteractiveGeometry();
  const treeSubselect = useSimulationTreeSubselect();
  const getSubselectMenuItems = useSubselectVisualizerMenuItems();
  const [clipHide, setClipHide] = useLcVisFilterClipHideEnabled();
  const editState = useEditStateValue();
  const showSurfaces = useGeoShowSurfacesValue(projectId);
  const [filterState] = useFilterState({ projectId, workflowId, jobId });
  const [meshUrlState] = useMeshUrlState(projectId);
  const isAnalysisView = useIsAnalysisView();
  const isMesh = meshUrlState.activeType === UrlType.MESH || isAnalysisView;
  const highlightedInVisSet = useMemo(
    () => new Set(highlightedInVisualizer),
    [highlightedInVisualizer],
  );
  const [transparencyDialogSettings, setTransparencyDialogSettings] = useTransparencyDialog();
  const entitySelectionState = useEntitySelectionValue(projectId);
  const geometryTags = useGeometryTags(projectId);
  const [selectedFeature] = useGeometrySelectedFeature(geometryId);
  const isGeoServerActive = useIsGeometryServerActive(geometryId);
  const setNodeVisibility = useSetNodeVisibility();

  const defaultTagDisabledReason = globalDisabledReason(
    selectedFeature,
    readOnly,
    isGeoServerActive,
  );

  const lightBackground = useMemo(() => (
    areArraysNear(backgroundColor, hexToRgbList(colors.lightBackgroundColor))
  ), [backgroundColor]);
  const toggleBackground = useCallback(() => {
    const newColor = lightBackground ? colors.darkBackgroundColor : colors.lightBackgroundColor;
    setBackgroundColor(hexToRgbList(newColor));
  }, [lightBackground, setBackgroundColor]);

  const anyClipSliceVisible = useMemo(() => {
    let visibleClipSlice = false;
    const callback = (node: TreeNode) => {
      if (node.visible && isClipOrSlice(node)) {
        visibleClipSlice = true;
      }
    };
    traverseTreeNodes(filterState, callback);
    return visibleClipSlice;
  }, [filterState]);

  // if any selected surfaces are hidden, show 'Show'.
  // if any selected surfaces are visible, show 'Hide'.
  // if any deselected surfaces are visible, show 'Hide Others (Isolate)'.
  // if any deselected surfaces are hidden, show 'Show Others'.
  // if any surfaces are hidden, show 'Show all'.
  const visibilities: VisibilityInfo = useMemo(() => getVisibilityInfo(
    isMesh,
    filterState,
    highlightedInVisSet,
    visibilityMap,
    entityGroupMap,
    staticVolumes,
  ), [isMesh, filterState, highlightedInVisSet, visibilityMap, entityGroupMap, staticVolumes]);

  const showAll = () => setNodeVisibility(true, new Set([
    ...visibilities.selectedHidden,
    ...visibilities.deselectedHidden,
  ]));
  const showOthers = () => setNodeVisibility(true, visibilities.deselectedHidden);
  const hideOthers = () => setNodeVisibility(false, visibilities.deselectedVisible);
  const showSelection = () => setNodeVisibility(true, visibilities.selectedHidden);
  const hideSelection = () => setNodeVisibility(false, visibilities.selectedVisible);

  const selectionInVisualizer = !!highlightedInVisualizer.length;

  const geoUsesTags = useGeometryUsesTags(projectId);

  // if the user is hovering a surface when the menu is opened, allow them to select either
  // the surface itself or the volume it bounds.
  const boundingVolume: StaticVolume | undefined = useMemo(() => {
    if (!clickedId || !menuOpen) {
      return undefined;
    }
    return staticVolumes.find((staticVolume) => staticVolume.bounds.has(clickedId));
  }, [clickedId, staticVolumes, menuOpen]);

  if (!menuOpen) {
    return [];
  }

  const hasActiveNodeTable = (activeNodeTable.type !== NodeTableType.NONE);

  const selectionItems: CommonMenuItem[] = filteredMenuItems([
    {
      itemConfig: {
        label: 'Clear Selection',
        onClick: () => {
          modifySelection({
            action: SelectionAction.OVERWRITE,
            modificationIds: [],
            nodeTableOverride: activeNodeTable,
          });
        },
      },
      shouldShow: selectionInVisualizer,
    },
    {
      itemConfig: {
        label: 'Select Surface',
        onClick: () => {
          modifySelection({
            action: hasActiveNodeTable ? SelectionAction.ADD : SelectionAction.OVERWRITE,
            modificationIds: [clickedId],
            nodeTableOverride: activeNodeTable,
          });
          setScrollTo({ node: clickedId, fast: true });
        },
        startIcon: { name: 'cubeOutline' },
      },
      shouldShow: !!clickedId && !treeSubselect.active && showSurfaces &&
        !highlightedInVisualizer.includes(clickedId),
    },
    {
      itemConfig: {
        label: 'Select Volume',
        onClick: () => {
          modifySelection({
            action: hasActiveNodeTable ? SelectionAction.ADD : SelectionAction.OVERWRITE,
            modificationIds: boundingVolume ? [boundingVolume.id] : [],
            nodeTableOverride: activeNodeTable,
          });
          setScrollTo({ node: boundingVolume?.id ?? '', fast: true });
        },
        startIcon: { name: 'cubeSolid' },
      },
      shouldShow: !!clickedId && !treeSubselect.active && !!boundingVolume?.domain &&
        !highlightedInVisualizer.includes(boundingVolume.domain),
    },
  ]);

  if (clickedId && treeSubselect.active) {
    selectionItems.push(...getSubselectMenuItems(clickedId, boundingVolume, true));
  }

  const canMakeSelectionTransparent = Boolean(
    entitySelectionState === 'surface' &&
    selectedNodeIds.length &&
    entityGroupMap.has(selectedNodeIds[0]),
  );

  const transparencyItems = filteredWithLeadIcon('transparency', [
    {
      itemConfig: {
        label: 'Transparency Mode',
        help: TRANSPARENCY_MODE_SUBTITLE,
        onClick: () => setTransparencyDialogSettings({
          ...defaultTransparencySettings(),
          active: true,
        }),
      },
      shouldShow: !transparencyDialogSettings.active && !canMakeSelectionTransparent,
    },
    {
      itemConfig: {
        label: 'Exit Transparency Mode',
        help: TRANSPARENCY_MODE_SUBTITLE,
        onClick: () => setTransparencyDialogSettings(defaultTransparencyDialogSettings()),
      },
      shouldShow: transparencyDialogSettings.active,
    },
    {
      itemConfig: {
        label: 'Make Transparent',
        help: TRANSPARENCY_MODE_SUBTITLE,
        onClick: () => setTransparencyDialogSettings({
          targetSurfaceIds: new Set(expandGroups(leafMap)(selectedNodeIds)),
          mode: 'transparent',
          active: true,
        }),
      },
      shouldShow: !transparencyDialogSettings.active && canMakeSelectionTransparent,
    },
    {
      itemConfig: {
        label: 'Make Others Transparent (Reveal)',
        help: TRANSPARENCY_MODE_SUBTITLE,
        onClick: () => setTransparencyDialogSettings({
          targetSurfaceIds: new Set(expandGroups(leafMap)(selectedNodeIds)),
          mode: 'opaque',
          active: true,
        }),
      },
      shouldShow: !transparencyDialogSettings.active && canMakeSelectionTransparent,
    },
  ]);

  const visibilityItems = [
    ...filteredWithLeadIcon('eyeOff', [
      {
        itemConfig: { label: 'Hide', onClick: hideSelection },
        shouldShow: selectionInVisualizer && visibilities.selectedVisible.size > 0,
      },
      {
        itemConfig: { label: 'Hide Others (Isolate)', onClick: hideOthers },
        shouldShow: selectionInVisualizer && visibilities.deselectedVisible.size > 0,
      },
    ]),
    ...filteredWithLeadIcon('eyeOn', [
      {
        itemConfig: { label: 'Show', onClick: showSelection },
        shouldShow: selectionInVisualizer && visibilities.selectedHidden.size > 0,
      },
      {
        itemConfig: { label: 'Show All', onClick: showAll },
        shouldShow: visibilities.deselectedHidden.size > 0 || visibilities.selectedHidden.size > 0,
      },
      {
        itemConfig: { label: 'Show Others', onClick: showOthers },
        shouldShow: selectionInVisualizer && visibilities.deselectedHidden.size > 0,
      },
    ]),
    ...filteredMenuItems([
      {
        itemConfig: {
          label: clipHide ? 'Show Clipped Surfaces' : 'Hide Clipped Surfaces',
          onClick: () => setClipHide(!clipHide),
          help: (
            'Clipped surfaces are transparent by default. ' +
            'Transparent surfaces cannot be selected or probed in the visualizer.'
          ),
        },
        shouldShow: (anyClipSliceVisible && !isMesh) || geoClipActive || !!editState,
      },
    ]),
  ];

  let clickedThing = entitySelectionState === 'volume' ? boundingVolume?.domain : clickedId;
  const noSelectionAndSomethingClicked = !selectionInVisualizer && !!clickedThing;

  if (entitySelectionState === 'volume') {
    // this is a bummer... when a volume is highlighted in the visualizer, `highlightedInVisualizer`
    // includes its domain, not its id.  but we use its id for functions like `setSelection`. so now
    // that we're done with the highlighted check, swap out the domain for the id.
    clickedThing = boundingVolume?.id;
  }

  const createTagMenuItem = {
    label: 'New Tag...',
    onClick: () => {
      setCreateTagModalOpen(true);
      if (noSelectionAndSomethingClicked) {
        setSelection(clickedThing ? [clickedThing] : []);
      }
    },
  };
  const addToTagMenuItem = (tag: GeometryTag) => ({
    label: tag.name,
    onClick: async () => {
      const stuffToAdd = noSelectionAndSomethingClicked ? [clickedId] : selectedNodeIds;
      if (['surface', 'surface_no_highlight'].includes(entitySelectionState)) {
        await addSurfacesToExistingTag(tag.id, stuffToAdd);
      } else if (entitySelectionState === 'volume') {
        await addVolumesToExistingTag(tag.id, stuffToAdd);
      }
    },
  });

  const groupItems = filteredMenuItems([
    {
      itemConfig: {
        label: 'Group',
        onClick: () => groupEntities(selectedNodeIds),
        keyboardShortcut: getHelpText('group'),
        startIcon: { name: 'groupAction' },
      },
      shouldShow: canGroupSelectedNodes,
    },
    {
      itemConfig: {
        label: 'Ungroup',
        onClick: () => ungroupEntities(selectedNode!.id),
        keyboardShortcut: getHelpText('ungroup'),
        startIcon: { name: 'ungroupAction' },
      },
      shouldShow: canUngroupSelectedNode,
    },
    {
      itemConfig: {
        label: 'Add to Tag',
        disabled: !!defaultTagDisabledReason,
        disabledReason: defaultTagDisabledReason,
        items: [
          createTagMenuItem,
          { separator: true } as CommonMenuSeparator,
          ...geometryTags.tags.map(addToTagMenuItem),
        ],
        startIcon: { name: 'tag' },
      },
      shouldShow: (noSelectionAndSomethingClicked || selectionInVisualizer) &&
        !isCreateTagDisabled && geoUsesTags,
    },
  ]);

  const cameraItems: CommonMenuItem[] = [
    {
      label: explodeFactor === null ? 'Exploded View' : 'Exit Exploded View',
      onClick: () => {
        setExplodeFactor((prev) => {
          if (prev === null) {
            return 0;
          }
          return null;
        });
      },
      startIcon: { name: 'cubeFacesOutline' },
    },
    {
      label: 'Zoom to Fit',
      onClick: () => {
        lcvResetCamera();
      },
      startIcon: { name: 'arrowsOut' },
      keyboardShortcut: getHelpText('resetCamera'),
    },
    {
      label: 'Set Center of Rotation',
      onClick: () => {
        if (!lcvisReady || !lcvHandler.display) {
          return;
        }
        lcvHandler.display.widgets.arcballWidget?.setParam(
          'center_of_rotation_modifier',
          LCVType.kLCVDataTypeInt,
          1,
        );
        // send a mouse event at the clicked location in the next animation frame, since LCVis
        // takes 1 frame to apply the new param before a new click will set the center of rot.
        requestAnimationFrame(() => {
          lcvHandler.display?.invokeEvent.call(
            lcvHandler.display!,
            new LCVMouseEvent(
              LCVMouseButton.kLCVMouseButtonLeft,
              LCVKeyModifier.kLCVKeyModifierNone,
              transform.left!,
              transform.top!,
            ),
            'mouseup',
          );
        });
      },
      startIcon: { name: 'ringCircle' },
    },
  ];

  const backgroundColorItems: CommonMenuItem[] = [
    {
      label: lightBackground ? 'Dark background' : 'Light background',
      onClick: toggleBackground,
    },
  ];

  return assembleMenuSections(
    selectionItems,
    visibilityItems,
    transparencyItems,
    groupItems,
    cameraItems,
    backgroundColorItems,
  );
};
