// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo } from 'react';

import { CommonMenuItem } from '../../../lib/componentTypes/menu';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { projectHasMotion } from '../../../lib/motionDataUtils';
import { getNodeParentByNodeIds } from '../../../lib/paraviewUtils';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { TreeNodeParam } from '../../../pvproto/ParaviewRpc';
import { useLcVisEnabledState } from '../../../recoil/lcvis/lcvisEnabledState';
import { useAnyFiltersPending } from '../../../recoil/lcvis/lcvisFilterStatus';
import { useViewStateOverflow } from '../../../recoil/lcvis/viewStateOverflow';
import { useEditState, useIsEditingFilterType } from '../../../recoil/paraviewState';
import { useMeshReadyState } from '../../../recoil/useMeshReadyState';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useIsAnalysisView, useIsGeometryView, useIsSetupOrAdvancedView } from '../../../state/internal/global/currentView';
import { useMotionAnimationPlaying, useShowMotionAnimationSettings } from '../../../state/internal/vis/motionAnimation';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { RibbonToolbarTool } from '../../RibbonToolbar/RibbonToolbarButton';
import { getIconName } from '../../Toolbar/LcVisToolbarButton';
import { ToolbarButtonProps } from '../../Toolbar/ToolbarButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useGetFilterMenuItem } from '../../visFilter/useGetFilterMenuItem';
import { useHandleLcVisFilterClick } from '../../visFilter/useHandleLcVisFilterClick';
import { useVisFilterParentNode } from '../../visFilter/useVisFilterParentNode';

import environmentState from '@/state/environment';

interface VisDropdownProps {
  omit?: Array<'clip' | 'slice'>;
  parentNodeId?: string;
}

const ALLOWED_STREAMLINES_PARENT_TYPES: TreeNodeParam['typ'][] = ['Reader', 'Clip', 'Threshold'];

export const useVisDropdownData = (props?: VisDropdownProps): RibbonToolbarTool => {
  // == Props
  const { omit = [], parentNodeId } = props || {};

  // == Contexts
  const { paraviewClientState } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const [
    showMotionAnimationSettings,
    setShowMotionAnimationSettings,
  ] = useShowMotionAnimationSettings();
  const [motionAnimationPlaying] = useMotionAnimationPlaying();
  const handleLcvButtonClick = useHandleLcVisFilterClick({ parentNodeId });
  const getFilterMenuItem = useGetFilterMenuItem({ parentNodeId });
  const { isTreeModal } = useSelectionContext();
  const isAnalysisView = useIsAnalysisView();
  const isGeometryView = useIsGeometryView();
  const isSetupOrAdvancedView = useIsSetupOrAdvancedView();
  const [editState] = useEditState();
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const [lcVisEnabled] = useLcVisEnabledState(projectId);
  const lcVisReady = environmentState.use.lcvisReady;
  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });
  const anyFiltersPending = useAnyFiltersPending();

  const clientDisconnected = paraviewClientState.client === null;

  // Only show the animation button if we are in the setup tab and if motion has been imposed.
  const canAnimateMotion = isSetupOrAdvancedView && projectHasMotion(simParam);

  // The parent to which the new node will be added as a child.
  const parentNode = useVisFilterParentNode(parentNodeId);
  const rootNode = useVisFilterParentNode();

  const isEditing = useIsEditingFilterType();

  const toolboxItems: CommonMenuItem[] = [];

  const nodeParentByNodeIds = useMemo(
    () => (rootNode ? getNodeParentByNodeIds(rootNode) : new Map<string, ParaviewRpc.TreeNode>()),
    [rootNode],
  );

  let currentParentNode = parentNode;
  let streamlinesFilterParentErrorMessage = '';

  do {
    if (
      currentParentNode &&
      !ALLOWED_STREAMLINES_PARENT_TYPES.includes(currentParentNode.param.typ)
    ) {
      streamlinesFilterParentErrorMessage = (
        'Streamlines cannot be a child of a surface output filter'
      );
      break;
    }

    currentParentNode = nodeParentByNodeIds.get(currentParentNode?.id || '');
  } while (currentParentNode && currentParentNode.param.typ !== 'Reader');

  if (!lcVisEnabled && parentNode && meshReadyState) {
    if (canAnimateMotion) {
      const title = editState ?
        'The motion animation cannot be activated while editing a visualization' :
        'Motion Animation';
      const disabled = clientDisconnected || showMotionAnimationSettings || editState !== null;
      // The button to show the animation settings panel is disabled if there's an animation being
      // displayed, if the client is disconnected or while editState is active. In the latter case,
      // we need to disable the button because while we edit filters we may display widgets and
      // we don't want the widgets to be displayed at the same time the animation is playing.
      toolboxItems.push({
        disabled,
        label: title,
        startIcon: { name: 'play' },
        onClick: () => setShowMotionAnimationSettings(true),
      });
    }

    if (!isGeometryView) {
      toolboxItems.length && toolboxItems.push({ separator: true });

      if (!omit?.includes('clip')) {
        toolboxItems.push(getFilterMenuItem('Clip', ParaviewRpc.TreeNodeType.CLIP));
      }

      // Enable Contour and Threshold with scalars and vectors.
      const hasData = parentNode.pointData.some((item) => item.dim === 1 || item.dim === 3);

      if (!omit?.includes('slice')) {
        toolboxItems.push(getFilterMenuItem('Slice', ParaviewRpc.TreeNodeType.SLICE));
      }
      if (isAnalysisView && hasData) {
        toolboxItems.push(getFilterMenuItem('MultiSlice', ParaviewRpc.TreeNodeType.MULTI_SLICE));
      }

      if (isAnalysisView) {
        if (hasData) {
          // Contours are displayed to the user as "Isosurface".
          toolboxItems.push(
            getFilterMenuItem('Threshold', ParaviewRpc.TreeNodeType.THRESHOLD),
            getFilterMenuItem('Isosurface', ParaviewRpc.TreeNodeType.CONTOUR),
          );
        }

        // Streamlines, surface LIC and glyph work only for vector arrays.
        if (parentNode.pointData.some((item) => item.dim === 3)) {
          const streamlinesMenuItem = getFilterMenuItem(
            'Streamlines',
            ParaviewRpc.TreeNodeType.STREAMLINES,
          );

          if (streamlinesFilterParentErrorMessage) {
            streamlinesMenuItem.disabled = true;
            streamlinesMenuItem.disabledReason = streamlinesFilterParentErrorMessage;
          }

          toolboxItems.push(
            { separator: true },
            streamlinesMenuItem,
            getFilterMenuItem('SurfaceLIC', ParaviewRpc.TreeNodeType.SURFACE_L_I_C),
            getFilterMenuItem('Vector', ParaviewRpc.TreeNodeType.GLYPH),
          );
        }
      }
      toolboxItems.push(
        { separator: true },
        { title: 'More Filters' },
      );

      if (isAnalysisView && hasData) {
        toolboxItems.push(
          getFilterMenuItem('Line', ParaviewRpc.TreeNodeType.LINE, true),
          getFilterMenuItem(
            'Intersection Curve',
            ParaviewRpc.TreeNodeType.INTERSECTION_CURVE,
            true,
          ),
        );
      }

      toolboxItems.push(
        getFilterMenuItem('Extract Surfaces', ParaviewRpc.TreeNodeType.EXTRACT_SURFACES),
      );
    }
  }

  if (lcVisEnabled) {
    if (canAnimateMotion) {
      const onClickStop = async () => {
        const display = await lcvHandler.getDisplay();
        display?.getWorkspace()?.stopAnimation();
      };
      const onClickPlay = () => setShowMotionAnimationSettings(true);

      const playButtonProps: ToolbarButtonProps = motionAnimationPlaying ? {
        icon: { name: 'stopOutline' },
        onClick: onClickStop,
        locator: 'toolbar-stop-motion-animation',
        title: 'Stop Motion Preview',
      } : {
        icon: { name: 'playOutline' },
        onClick: onClickPlay,
        locator: 'toolbar-motion-animation',
        title: 'Motion Preview',
      };
      playButtonProps.disabled = (showMotionAnimationSettings || editState !== null || !lcVisReady);
      toolboxItems.push(
        {
          disabled: playButtonProps.disabled,
          label: playButtonProps.title as string,
          startIcon: { name: playButtonProps.icon.name },
          onClick: motionAnimationPlaying ? onClickStop : onClickPlay,
        },
        { separator: true },
      );
    }

    const needServerSideRendering = 'Coming soon, currently requires server-side rendering';
    if (!omit?.includes('clip')) {
      toolboxItems.push({
        disabled: !lcVisReady || anyFiltersPending,
        engaged: isEditing(ParaviewRpc.TreeNodeType.CLIP),
        label: 'Clip',
        startIcon: { name: getIconName('clip') },
        onClick: (event) => handleLcvButtonClick(event, 'clip'),
      });
    }
    if (!omit?.includes('slice')) {
      toolboxItems.push({
        disabled: !lcVisReady || anyFiltersPending,
        engaged: isEditing(ParaviewRpc.TreeNodeType.SLICE),
        label: 'Slice',
        startIcon: { name: getIconName('slice') },
        onClick: (event) => handleLcvButtonClick(event, 'slice'),
      });
    }

    // Add entries for all the vis filters, including ones that LCVis doesn't
    // support yet so that users know to switch to server-side rendering
    // to use them for now
    if (isAnalysisView && lcvisData.data) {
      toolboxItems.push(
        {
          disabled: !lcVisReady || anyFiltersPending,
          engaged: isEditing(ParaviewRpc.TreeNodeType.MULTI_SLICE),
          label: 'MultiSlice',
          startIcon: { name: getIconName('multislice') },
          onClick: (event) => handleLcvButtonClick(event, 'multislice'),
        },
        {
          disabled: !lcVisReady || anyFiltersPending,
          engaged: isEditing(ParaviewRpc.TreeNodeType.THRESHOLD),
          label: 'Threshold',
          startIcon: { name: getIconName('threshold') },
          onClick: (event) => handleLcvButtonClick(event, 'threshold'),
        },
        {
          disabled: !lcVisReady || anyFiltersPending,
          engaged: isEditing(ParaviewRpc.TreeNodeType.CONTOUR),
          label: 'Isosurface',
          startIcon: { name: getIconName('contour') },
          onClick: (event) => handleLcvButtonClick(event, 'contour'),
        },
        { separator: true },
        {
          disabled: !lcVisReady || anyFiltersPending || !!streamlinesFilterParentErrorMessage,
          engaged: isEditing(ParaviewRpc.TreeNodeType.STREAMLINES),
          label: 'Streamlines',
          disabledReason: streamlinesFilterParentErrorMessage,
          startIcon: { name: getIconName('streamlines') },
          onClick: (event) => handleLcvButtonClick(event, 'streamlines'),
        },
        {
          disabled: true,
          engaged: false,
          label: 'SurfaceLIC',
          disabledReason: `SurfaceLIC (${needServerSideRendering})`,
          startIcon: { name: getIconName('surfaceLIC') },
          // TODO: not implemented in LCVis yet
          onClick: () => {},
        },
        {
          disabled: !lcVisReady || anyFiltersPending,
          engaged: isEditing(ParaviewRpc.TreeNodeType.GLYPH),
          label: 'Vector',
          startIcon: { name: getIconName('glyph') },
          onClick: (event) => handleLcvButtonClick(event, 'glyph'),
        },
        { separator: true },
        { title: 'More Filters' },
        {
          disabled: true,
          label: 'Line',
          startIcon: { name: getIconName('intersectionLine') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
          earlyAccess: true,
        },
        {
          disabled: true,
          label: 'Intersection Curve',
          startIcon: { name: getIconName('intersectionCurve') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
          earlyAccess: true,
        },
      );
    }
  }

  return {
    disabled: isTreeModal || !toolboxItems.length,
    icon: { name: 'wand' },
    key: 'toolbox',
    locator: 'toolbar-toolbox',
    label: 'Visualizations',
    title: 'Visualizations',
    items: toolboxItems,
  };
};
