// This file was created to prevent circular dependencies between
// ParaviewClient and src/lib/componentTypes/context.ts.

// Metadata about the current state of the Paraview connection.
// CONNECTED means that  the client is connected to the paraview.
// There are two disconnected states.
// CONNECTING means the client is currently is not connected, but it wants to.
// VOLUNTARY_DISCONNECT means the client disconnected due to the idle timeout,
// and connection can be resumed using keepalive().

export enum ConnState {
  CONNECTING = 0,
  VOLUNTARY_DISCONNECT = 1,
  CONNECTED = 2,
}

// Defines rendering views. A view is a canvas on which a mesh is drawn.
// Creating multiple view is useful if you want to show multiple canvases
// simultaneously, or if you want to let the user switch between two canvases
// quickly. Each view costs the backend memory and GPU cycles, so its number
// should be minimized.
export enum ViewName {
  SETUP = 'setup', // Used to show a mesh in the setup screen
  SOLUTION = 'soln', // Used to show a mesh or a solution in the analysis screen.
}
