// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { CallbackInterface } from 'recoil';

import * as simulationpb from '../proto/client/simulation_pb';
import * as explorationpb from '../proto/exploration/exploration_pb';
import { WorkflowMetadata } from '../proto/frontend/frontend_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as feoutputpb from '../proto/frontend/output/output_pb';
import { JobType } from '../proto/notification/notification_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { geometryTagsState } from '../recoil/geometry/geometryTagsState';
import { jobNameMapSelector } from '../recoil/jobNameMap';
import { outputNodesState } from '../recoil/outputNodes';
import { enabledExperimentsState } from '../recoil/useExperimentConfig';
import { OutputResultKey, ScalarOutput, scalarResultList } from '../recoil/useOutputResults';
import { projectMetadataState } from '../recoil/useProjectMetadata';
import { StaticVolume, staticVolumesState } from '../recoil/volumes';
import { WorkflowMap, workflowMapSelector } from '../recoil/workflowState';

import { ParamScope, createParamScope } from './ParamScope';
import assert from './assert';
import { getBoundaryConditionNames } from './boundaryConditionUtils';
import { CsvWriter } from './csv';
import { getLabel, getValueForVarSpec, validExploration, varNameEquals } from './explorationUtils';
import { JobNameMap, getJobType } from './jobNameMap';
import { formatNumber } from './number';
import { getSimulationParam } from './simulationParamUtils';

type Job = frontendpb.GetWorkflowReply_Job;

// Information about a workflow.
export interface WorkflowDatum {
  id: string;
  metadata: frontendpb.WorkflowMetadata;
  reply: frontendpb.GetWorkflowReply | undefined;
  simParam: simulationpb.SimulationParam | undefined;
  singleJob: boolean;
  paramScope: ParamScope | undefined;
}

// Information about a row.
export interface RowDatum {
  job: Job | null;
  type: JobType;
  workflow: WorkflowDatum;
  name: string;
  creationTime: number;
}

export function getRowId(row: RowDatum) {
  return row.job?.jobId ?? row.workflow.id;
}

/**
 * Gather the data for workflows
 * @param workflowMap - Map of workflowIds -> workflow data
 * @param workflowMetadata - Metadata for workflows
 * @param experimentConfig - Experiment configuration
 * @returns Workflow data
 */
export function createWorkflowData(
  workflowMap: WorkflowMap,
  workflowMetadata: WorkflowMetadata[],
  experimentConfig: string[],
): WorkflowDatum[] {
  // Get the workflow metadata for each workflow.
  const data = workflowMetadata.map((wfMeta) => {
    const reply = workflowMap[wfMeta.workflowId];
    const exploration = reply?.config?.exploration;
    const isExploration = validExploration(exploration);
    const singleJob = !!reply && Object.keys(reply.job).length === 1 && !isExploration;
    const simParam = reply ? getSimulationParam(reply.config!) : undefined;
    const paramScope = simParam ? createParamScope(simParam, experimentConfig) : undefined;
    return {
      id: wfMeta.workflowId,
      simParam,
      metadata: wfMeta,
      reply,
      singleJob,
      paramScope,
    } as WorkflowDatum;
  });
  // Sort the workflows based on the creation time.
  data.sort((a: WorkflowDatum, b: WorkflowDatum) => {
    const diff = a.metadata.creationTime - b.metadata.creationTime;
    return diff;
  });
  return data;
}

/**
 * Create the data for the rows in the table.
 * @param workflowIds - List of workflow ids to include
 * @param workflowData - Data for workflows
 * @param jobNameMap - Map of job names
 * @returns Row data
 */
export function createRowData(
  workflowIds: string[],
  workflowData: WorkflowDatum[],
  jobNameMap: JobNameMap,
): RowDatum[] {
  const data: RowDatum[] = [];
  workflowData.forEach((workflow: WorkflowDatum) => {
    if (workflow.reply) {
      if (!workflow.singleJob) {
        const name = jobNameMap.getDefault(
          { workflowId: workflow.id, type: getJobType(workflow.reply!) },
        );
        const exploration = workflow.reply!.config!.exploration;
        const isSensitivity = exploration?.policy.case === 'sensitivityAnalysis';
        const type = isSensitivity ? JobType.SENSITIVITY_ANALYSIS : JobType.DESIGN_OF_EXPERIMENTS;
        data.push({
          workflow,
          job: null,
          type,
          name,
          creationTime: workflow.metadata.creationTime,
        });
      }
      // Add the jobs if this is a single job or if the exploration is open.
      if (workflow.singleJob || workflowIds.includes(workflow.id)) {
        Object.entries(workflow.reply.job).forEach(([_, job]) => {
          const name = jobNameMap.getDefault(
            { workflowId: workflow.id, jobId: job.jobId, type: getJobType(workflow.reply!) },
          );
          job &&
            data.push({
              workflow,
              job,
              type: getJobType(workflow.reply!),
              name,
              creationTime: job.creationTime,
            });
        });
      }
    }
  });
  data.sort((a: RowDatum, b: RowDatum) => {
    const diff = a.creationTime - b.creationTime;
    return diff;
  });
  return data;
}

// ColumnData type
type ColumnData<Data> = {
  labelRows: string[];
  data: Data[];
};

/**
 * Create the exploration variable data for the table.
 * @param rowData
 * @param bcNames
 * @returns exploration data
 */
export function createExplorationVarData(
  rowData: RowDatum[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
): ColumnData<string>[] {
  // Find a list every unique input variable used in the exploration.
  const uniqueVarSpecs: explorationpb.VarSpec[] = [];
  rowData.forEach((row) => {
    if (row.type === JobType.DESIGN_OF_EXPERIMENTS) {
      const exploration = row.workflow.reply!.config!.exploration!;
      exploration.var.forEach((variable) => {
        const specA = variable.spec;
        if (specA && !variable.synthetic) {
          if (!uniqueVarSpecs.some((specB) => varNameEquals(specA, specB))) {
            uniqueVarSpecs.push(specA);
          }
        }
      });
    }
  });
  // For each input variable, get the value for each row to create a column of
  // values.
  return uniqueVarSpecs.map((varSpec) => {
    let varLabel: string[] = [];
    const values = rowData.map((row) => {
      if (row.type === JobType.DESIGN_OF_EXPERIMENTS) {
        // Check if the exploration variable is part of this exploration and if true
        // construct the label.
        if (row.workflow.reply?.config?.exploration?.var.find(
          (expVar) => varNameEquals(expVar.spec!, varSpec),
        )) {
          varLabel = getLabel(
            row.workflow.simParam!,
            getBoundaryConditionNames(row.workflow.simParam!),
            varSpec,
          ).split('/');
        }
        return '';
      }
      const param = row.workflow.simParam;
      const paramScope = row.workflow.paramScope;
      const jobValues = row.job?.explorationValues;
      let value = '';
      if (param && paramScope) {
        value = getValueForVarSpec(
          varSpec,
          jobValues?.value ?? [],
          paramScope,
          geometryTags,
          staticVolumes,
          param,
          row.workflow.reply?.config?.exploration,
        );
      }
      return value;
    });
    assert(varLabel.length > 0, 'varLabel is empty');
    return { labelRows: varLabel, data: values };
  });
}

/**
 *  Append the output name to the name array.
 * @param name - Name array
 * @param outputName - Output name
 * @param outputNode - Output node
 * @returns
 */
export const appendOutputName = (
  name: string[],
  outputName: string,
  outputNode: feoutputpb.OutputNode,
): string[] => {
  if (outputNode.nodeProps.case === 'residual') {
    name.push(outputName);
  } else {
    name[name.length - 1] += outputName;
  }
  return name;
};

/**
 *  Create the output data for the table.
 * @param outputNode - Output node
 * @param results - Results for the output node
 * @param entityGroupMap - Entity group map
 * @returns
 */
export function createOutputData(
  results: ScalarOutput[][],
  outputNodeName: string,
): ColumnData<number | undefined>[] {
  const outputData: ColumnData<number | undefined>[] = [];
  results.forEach((result: ScalarOutput[]) => {
    const name = [outputNodeName, result[0].name];
    outputData.push(
      {
        labelRows: result[0].shortName ? name.concat(result[0].shortName) : name,
        data: result.map((res) => res.baseValue),
      },
    );
  });
  return outputData;
}

/**
 * Export the job table data (name, exploration var values, outputs) to a CSV file.
 * @param param0
 * @returns
 */
export const exportJobTableCallback = (
  { snapshot: { getPromise } }: CallbackInterface,
) => async (projectId: string, workflowIds: string[]) => {
  const projectKey = { projectId, workflowId: '', jobId: '' };
  const projectMetadata = await getPromise(projectMetadataState(projectId));
  const workflowMap = await getPromise(workflowMapSelector({ projectId, workflowIds }));
  const experimentConfig = await getPromise(enabledExperimentsState);
  const jobNameMap = await getPromise(jobNameMapSelector(projectId));
  const geometryTags = await getPromise(geometryTagsState({ projectId }));
  const staticVolumes = await getPromise(staticVolumesState(projectId));

  const workflowData = createWorkflowData(workflowMap, projectMetadata!.workflow, experimentConfig);
  const rowData = createRowData(workflowIds, workflowData, jobNameMap);

  const explorationData = createExplorationVarData(rowData, geometryTags, staticVolumes);

  const outputNodes = await getPromise(outputNodesState(projectKey));
  const outputResultKeys: OutputResultKey[] = [];
  outputNodes.nodes.forEach((output) => rowData.forEach((data) => (
    outputResultKeys.push({
      projectId,
      workflowId: data.workflow.id,
      jobId: data.job?.jobId ?? '',
      outputId: output.id,
    })
  )));
  const outputResultList = await getPromise(scalarResultList(outputResultKeys));
  const outputData: ColumnData<number | undefined>[] = [];
  outputNodes.nodes.forEach((output) => {
    const results = outputResultList[output.id];
    if (results && results.length > 0) {
      outputData.push(...createOutputData(results, output.name));
    }
  });

  const headers: string[] = ['Name'];
  headers.push(...explorationData.map((data) => data.labelRows.join('/')));
  headers.push(...outputData.map((data) => data.labelRows.join('/')));

  const csvWriter = new CsvWriter(headers);
  rowData.forEach((data, index) => {
    if (data.type !== JobType.DESIGN_OF_EXPERIMENTS) {
      csvWriter.addRow(
        [
          data.name,
          ...explorationData.map((exp) => exp.data[index]),
          ...outputData.map((output) => (
            output.data[index] ? formatNumber(output.data[index]!) : ''
          )),
        ],
      );
    }
  });
  csvWriter.saveFile(`results_${projectId}.csv`);
};

export function getMenuButtonOpacity(
  rowId: string,
  hoveredRowId: string | null,
  selectedRowId: string | null,
) {
  if (rowId === selectedRowId) {
    return 1;
  }
  if (rowId === hoveredRowId) {
    return 0.5;
  }
  return 0;
}
