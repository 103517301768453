// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useMemo, useRef, useState } from 'react';

import { Badge } from '@mui/material';
import cx from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { isItarEnv } from '../../lib/RuntimeParams';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { LEARNING_URL, SUPPORT_URL } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { INTERCOM_LAUNCHER_SELECTOR, isIntercomReady } from '../../lib/intercom';
import { isInProject, routes } from '../../lib/navigation';
import { useNotifications, useNotificationsOpen } from '../../recoil/useNotifications';
import { useSetShowLCVisSettingsDialog } from '../../state/external/lcvis/showLCVisSettingsDialog';
import { useIsStaff } from '../../state/external/user/frontendRole';
import { IconButton, IconButtonProps } from '../Button/IconButton';
import Dropdown from '../Dropdown';
import { CommonMenu } from '../Menu/CommonMenu';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { AboutDialog } from '../dialog/About';
import { ProjectDeniedDialog } from '../dialog/ProjectDeniedDialog';
import { WarmClusterDialog } from '../dialog/WarmCluster';
import { NotificationCenter } from '../notification/NotificationCenter';
import { BallAsteriskIcon } from '../svg/BallAsteriskIcon';
import { BellSolidIcon } from '../svg/BellSolidIcon';
import { DiskQuestionIcon } from '../svg/DiskQuestionIcon';

import { ItarBar } from './header/ItarBar';
import { ProjectLogoSection } from './header/ProjectLogoSection';
import { ProjectNameSection } from './header/ProjectNameSection';
import { ProjectShareSection } from './header/ProjectShareSection';
import { ProjectTopLevelLinksSection } from './header/ProjectTopLevelLinksSection';
import { RemainingCredits } from './header/RemainingCredits';

//
// *** DO NOT CHANGE HOME ICON WITHOUT LEGAL APPROVAL ***
//

const NAV_ICON_SIZE = 17;
export const HEADER_HEIGHT = 48;

const useNavButtonStyles = makeStyles(
  ({ palette }) => createStyles({
    root: {
      width: '32px',
      height: `${HEADER_HEIGHT}px`,
      borderRadius: '0',
      color: colors.mediumEmphasisText,
      '&.help': {
        fontSize: '13px',
        width: 'auto',
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        color: colors.purple850,
      },
      '&:not(.help)': {
        '&:hover, &:active, &.opened': {
          color: palette.common.white,
        },
      },
      '&.inProject': {
        '&:hover': {
          backgroundColor: colors.surfaceMedium1,
        },
        '&:active, &.opened': {
          backgroundColor: colors.surfaceBackground,
        },
      },
      '&:not(.inProject)': {
        '&:hover': {
          backgroundColor: colors.surfaceLight1,
        },
        '&:active, &.opened': {
          backgroundColor: colors.surfaceDark2,
        },
      },
    },
  }),
  { name: 'NavButton' },
);

interface NavIconButtonProps extends IconButtonProps {
  // Require a label for accessibility.
  label: string;
  opened?: boolean;
  inProject: boolean;
}

interface HelpButtonProps extends Omit<NavIconButtonProps, 'label'> {}

const HelpButton = (props: HelpButtonProps) => {
  const classes = useNavButtonStyles();
  const { opened, inProject, ...otherProps } = props;

  return (
    <IconButton
      {...otherProps}
      className={cx(classes.root, 'help', { opened, inProject })}
      color="inherit"
      data-button-type="nav"
    />
  );
};

/**
 * Styled icon button for use in top-level navigation.
 */
const NavIconButton = (props: NavIconButtonProps) => {
  const classes = useNavButtonStyles();
  const { opened, inProject, ...otherProps } = props;

  return (
    <Tooltip title={opened ? '' : props.label}>
      <IconButton
        {...otherProps}
        arial-label={props.label}
        className={cx(classes.root, { opened, inProject })}
        color="inherit"
        data-button-type="nav"
      />
    </Tooltip>
  );
};

const usePageHeaderStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '8px',
      overflow: 'hidden',
      position: 'sticky',
      paddingRight: '24px',
      '&.isItarEnv': {
        paddingRight: '56px',
      },
    },
    headerSide: {
      overflow: 'auto',
      display: 'flex',
    },
    headerCenter: {
    },
    headerLeft: {
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      alignSelf: 'stretch',
      justifyContent: 'flex-start',
      paddingLeft: '20px',
    },
    headerRight: {
      justifyContent: 'flex-end',
    },
    globalToolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '4px',
    },
  }),
  { name: 'PageHeader' },
);

const useBadgeStyles = makeStyles(
  () => createStyles({
    badge: {
      background: colors.primaryCta,
      fontSize: '9px',
      height: '15px',
      minWidth: '15px',
      width: '15px',
    },
  }),
  { name: 'Badge' },
);
// TODO(saito) add callback for dismissing notification
export interface PageHeaderProps {
  // The currently active project.
  projectId?: string;
  title: string; // eslint-disable-line react/no-unused-prop-types
}

const PageHeader = (props: PageHeaderProps) => {
  const { projectId } = props;

  const classes = usePageHeaderStyles();
  const badgeClasses = useBadgeStyles();
  const notifications = useNotifications();
  const navigate = useNavigate();
  const isStaff = useIsStaff();
  const [notificationsOpen, setNotificationsOpen] = useNotificationsOpen();
  const location = useLocation();

  const [isInternalMenuOpen, setIsInternalMenuOpen] = useState(false);
  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false);
  const helpMenuAnchor = useRef<HTMLDivElement>(null);
  // Is the "About" dialog open?
  const [showAboutDialog, setShowAboutDialog] = useState<boolean>(false);
  // Is the Warm Cluster dialog open?
  const [showWarmClusterDialog, setShowWarmClusterDialog] = useState<boolean>(false);
  const setShowLCVisDialog = useSetShowLCVisSettingsDialog();

  const inProject = useMemo(() => isInProject(location.pathname), [location]);

  // The number of unread notifications.
  const badgeCount = useMemo(
    () => notifications.reduce((count, notification) => {
      if (!notification.read) {
        count += 1;
      }
      return count;
    }, 0),
    [notifications],
  );

  // The button which opens the Notification Center.
  const notificationsToggle = (
    <NavIconButton
      data-locator="navNotificationsButton"
      inProject={inProject}
      label="Notifications"
      onClick={() => setNotificationsOpen(!notificationsOpen)}
      opened={notificationsOpen}>
      <Badge
        badgeContent={badgeCount}
        classes={badgeClasses}>
        <BellSolidIcon maxHeight={NAV_ICON_SIZE} maxWidth={NAV_ICON_SIZE} />
      </Badge>
    </NavIconButton>
  );

  const menuItems: CommonMenuItem[] = [
    {
      label: 'Warm Cluster',
      onClick: () => setShowWarmClusterDialog(true),
    },
    {
      label: 'Component Versions',
      onClick: () => setShowAboutDialog(true),
      nameAttribute: 'help-about',
    },
    ...(
      inProject ? [{
        label: 'LCVis',
        onClick: () => setShowLCVisDialog((prev) => !prev),
      }] : []
    ),
    {
      label: 'Settings',
      onClick: () => {
        navigate(routes.adminSettings);
      },
    },
  ];

  return (
    <>
      {isItarEnv && <ItarBar />}
      <header className={cx(classes.root, { isItarEnv })}>
        <AboutDialog
          onClose={() => setShowAboutDialog(false)}
          open={showAboutDialog}
        />
        {isStaff && (
          <WarmClusterDialog
            onClose={() => setShowWarmClusterDialog(false)}
            open={showWarmClusterDialog}
          />
        )}
        <ProjectDeniedDialog />
        <div className={cx(classes.headerSide, classes.headerLeft)}>
          <ProjectLogoSection />
          {projectId && <ProjectNameSection projectId={projectId} />}
        </div>
        {projectId && (
          <div className={classes.headerCenter}>
            <ProjectTopLevelLinksSection projectId={projectId} />
          </div>
        )}
        <div className={cx(classes.headerSide, classes.headerRight)}>
          <div className={classes.globalToolbar}>
            <RemainingCredits />
            <ProjectShareSection projectId={projectId || ''} />
            {isStaff && (
              <Dropdown
                menuItems={menuItems}
                onClose={() => {
                  setIsInternalMenuOpen(false);
                }}
                onOpen={() => {
                  setIsInternalMenuOpen(true);
                  setNotificationsOpen(false);
                }}
                toggle={(
                  <NavIconButton
                    inProject={inProject}
                    label="Internal Tools"
                    opened={isInternalMenuOpen}>
                    <BallAsteriskIcon maxHeight={NAV_ICON_SIZE} maxWidth={NAV_ICON_SIZE} />
                  </NavIconButton>
                )}
              />
            )}
            <div style={{ display: 'inline-flex' }}>
              {notificationsToggle}
              <NotificationCenter
                notifications={notifications}
                open={notificationsOpen}
                setOpen={setNotificationsOpen}
              />
            </div>
            <div
              className={
                isIntercomReady() ? INTERCOM_LAUNCHER_SELECTOR.replace('.', '') : 'learningUrlLink'
              }
              ref={helpMenuAnchor}>
              <HelpButton
                inProject={inProject}
                onClick={() => {
                  setNotificationsOpen(false);
                  if (!isIntercomReady()) {
                    setIsHelpMenuOpen(!isHelpMenuOpen);
                  }
                }}
                opened={isHelpMenuOpen}>
                <DiskQuestionIcon
                  color={colors.purple850}
                  maxHeight={NAV_ICON_SIZE}
                  maxWidth={NAV_ICON_SIZE}
                />
                Help
              </HelpButton>
            </div>

            <CommonMenu
              anchorEl={helpMenuAnchor.current}
              menuItems={[
                {
                  label: 'Visit the Support website',
                  link: { href: SUPPORT_URL, external: true },
                  onClick: () => { },
                  startIcon: { maxHeight: 16, name: 'wireframeInverted' },
                },
                {
                  label: 'Go to Luminary Learning',
                  link: { href: LEARNING_URL, external: true },
                  onClick: () => { },
                  startIcon: { maxHeight: 16, name: 'bookmark' },
                },
              ]}
              onClose={() => setIsHelpMenuOpen(false)}
              open={isHelpMenuOpen}
            />
          </div>
        </div>
      </header>
    </>
  );
};

export default PageHeader;
