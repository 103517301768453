/* eslint-disable no-bitwise */
// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { LCVManipulationMode } from '@luminarycloudinternal/lcvis';
import { SetterOrUpdater } from 'recoil';

import { newOriginProto, newProto } from '../../../../lib/Vector';
import assert from '../../../../lib/assert';
import { LcvCylinderWithInnerRadiusWidgetState, LcvSphereWithInnerRadiusWidgetState, hideBoxWidget, hideCylinderWithInnerRadiusWidget, hideSphereWithInnerRadiusWidget, showBoxWidget, showCylinderWithInnerRadiusWidget, showSphereWithInnerRadiusWidget, updateBoxWidgetState, updateCylinderWithInnerRadiusWidgetState, updateSphereWithInnerRadiusWidgetState } from '../../../../lib/lcvis/api';
import { LcvBoxWidgetState } from '../../../../lib/lcvis/classes/widgets/LcvClipBoxWidget';
import { nullableMeshing } from '../../../../lib/mesh';
import { formatNumber } from '../../../../lib/number';
import {
  axesToEulerAngles,
  boxDefinitionToRefineRegionDefinition,
  eulerAnglesToAxes,
  getCurrentRrParam,
  getHLimitBounds,
  getShapeName,
  refineRegionDefinitionToBoxDefinition,
  updateRrParamNumber,
  updateRrParamVector,
} from '../../../../lib/refinementRegionUtils';
import { debounce } from '../../../../lib/utils';
import * as basepb from '../../../../proto/base/base_pb';
import { OrientedCube } from '../../../../proto/cad/shape_pb';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import * as meshgenerationpb from '../../../../proto/meshgeneration/meshgeneration_pb';
import * as quantitypb from '../../../../proto/quantity/quantity_pb';
import { useMeshReadOnly } from '../../../../recoil/useMeshReadOnly';
import useMeshMultiPart, { useSetMeshMultiPart } from '../../../../recoil/useMeshingMultiPart';
import { useSetRefinementRegionSelection, useSetRefinementRegionVisibility } from '../../../../recoil/useRefinementRegions';
import { useSimulationParam } from '../../../../state/external/project/simulation/param';
import { BoxInput } from '../../../Form/CompositeInputs/BoxInput';
import { InputDescription } from '../../../Form/InputDescription';
import LabeledInput from '../../../Form/LabeledInput';
import { NumberField } from '../../../Form/NumberField';
import { Vector3Input } from '../../../Form/Vector3Input';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import QuantityAdornment from '../../../QuantityAdornment';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import ValidatedNumberSpinner from '../../../controls/ValidatedNumberSpinner';
import { SimpleSlider } from '../../../controls/slider/SimpleSlider';
import PropertiesSection from '../../PropertiesSection';
import { EmptyPropPanel } from '../Empty';

type SizingSectionProps = {
  id: string,
  readOnly: boolean,
  currentRrParam: meshgenerationpb.MeshingMultiPart_RefinementRegionParams,
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
  meshMultiPart: meshgenerationpb.MeshingMultiPart,
}

const SizingSection = (props: SizingSectionProps) => {
  const { id, readOnly, currentRrParam, setMeshMultiPart, meshMultiPart } = props;
  const [displayValue, setDisplayValue] = useState(currentRrParam.hLimit);

  const { maxSize, minSize } = getHLimitBounds(meshMultiPart);

  const validate = (newValue: number) => {
    if (newValue < 0) {
      return 'Must be > 0.';
    }
    // When the NumberField is blurred and the value is at its max or min,
    // the input value displayed will sometimes be rounded to an out-of-bounds value. So here we
    // need to parse the formatted input value to check that it's still within bounds, even if
    // it is being displayed as something out of bounds.
    if (newValue > parseFloat(formatNumber(maxSize))) {
      return 'Must be less than the Maximum Mesh Size';
    } if (newValue < parseFloat(formatNumber(minSize))) {
      return 'Must be greater than the Minimum Mesh Size';
    }
    return '';
  };

  const [warning, setWarning] = useState(displayValue ? validate(displayValue) : '');

  // Rerendering the SizingSection when we switch nodes (e.g. from Box 1 to Box 2) doesn't
  // reset its state, so we must manually set displayValue to the hLimit of the newly selected node.
  useEffect(() => {
    setDisplayValue(currentRrParam.hLimit);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // update the meshMultiPart proto when displayValue changes.
  // It's here so we can debounce it later.
  useEffect(() => {
    if (!readOnly) {
      updateRrParamNumber(setMeshMultiPart, id, displayValue, 'Size');
    }
  }, [displayValue, id, readOnly, setMeshMultiPart]);

  return (
    <CollapsibleNodePanel
      heading="Sizing"
      nodeId={`sizing-${id}`}
      panelName="sizing">
      <LabeledInput label="Max Size">
        <NumberField
          asBlock
          disabled={readOnly}
          endAdornment={<QuantityAdornment quantity={quantitypb.QuantityType.LENGTH} />}
          faultType={warning ? 'error' : undefined}
          isInput
          onBlur={() => setWarning('')}
          onChange={(newVal: number) => {
            setWarning(validate(newVal));
          }}
          onCommit={(newValue: number) => {
            if (warning) {
              return;
            }
            setDisplayValue(newValue);
          }}
          readOnly={readOnly}
          size="small"
          value={displayValue}
        />
        {warning && (
          <div style={{ paddingTop: '8px' }}>
            <InputDescription faultType="error" value={warning} />
          </div>
        )}
      </LabeledInput>
      <LabeledInput label="">
        <div style={{ padding: '0 8px' }}>
          <SimpleSlider
            disabled={readOnly}
            gutterHeight={4}
            max={maxSize}
            min={Math.max(minSize, 0)}
            onChange={(newValue) => {
              if (newValue > maxSize) {
                setDisplayValue(maxSize);
              } else if (newValue < minSize) {
                setDisplayValue(minSize);
              } else {
                setDisplayValue(newValue);
              }
            }}
            readoutConfig={{ disabled: true }}
            value={displayValue}
          />
        </div>
      </LabeledInput>
    </CollapsibleNodePanel>
  );
};

interface ShapeSectionProps {
  id: string,
  readOnly: boolean,
  currentRrParam: meshgenerationpb.MeshingMultiPart_RefinementRegionParams,
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
}

/**
 * Debounce time set to 50ms for stability: prevents excessive requests and ensures a
 * smooth user experience during widget interactions. Needed to apply temporary settings
 * and update the backend efficiently.
 */
const DEBOUNCE_TIME = 50;

const getRotation = (
  currentRrParam: meshgenerationpb.MeshingMultiPart_RefinementRegionParams,
  cubeParam: OrientedCube,
) => {
  // If we have a rotation field, we're in the setup tab and preserve the user's exact input.
  if (currentRrParam.rotation) {
    return currentRrParam.rotation!;
  }

  // If not, we're in a simulation tab and we don't have access to the user's exact input.
  // So we have to call getDisplayValues to get some valid euler angles for the orientation field
  // (which might not be identical to what the user originally entered, since euler angles are
  // not unique).
  return axesToEulerAngles(cubeParam.xAxis!, cubeParam.yAxis!);
};

const BoxSection = (props: ShapeSectionProps) => {
  const { id, readOnly, currentRrParam, setMeshMultiPart } = props;
  assert(currentRrParam.shape.case === 'orientedCube', 'BoxSection only valid for OrientedCube');
  const cubeParam = currentRrParam.shape.value!;

  const [tempRefinementRegion, setTempRefinementRegion] = useState(cubeParam);
  const [tempRotation, setTempRotation] = useState(
    getRotation(currentRrParam, tempRefinementRegion),
  );

  const rotation = useMemo(() => (
    getRotation(currentRrParam, cubeParam)
  ), [currentRrParam, cubeParam]);

  const handleRotation = useCallback((newRotation: basepb.Vector3) => {
    const { xAxis, yAxis } = eulerAnglesToAxes(newRotation);
    setMeshMultiPart((oldMeshMultiPart) => {
      const newMeshMultiPart = oldMeshMultiPart!.clone();
      const param = getCurrentRrParam(id, newMeshMultiPart)!;
      assert(param.shape.case === 'orientedCube', 'BoxSection handleRotation must be OrientedCube');
      param.rotation = newRotation;
      const box = param.shape.value;
      box.xAxis = xAxis;
      box.yAxis = yAxis;
      return newMeshMultiPart;
    });
  }, [id, setMeshMultiPart]);

  const applyDebouncedBox = useMemo(() => debounce((_, box: LcvBoxWidgetState) => {
    const refineRegion = boxDefinitionToRefineRegionDefinition({
      size: { x: box.size[0], y: box.size[1], z: box.size[2] },
      center: { x: box.center[0], y: box.center[1], z: box.center[2] },
    }, { x: box.rotation[0], y: box.rotation[1], z: box.rotation[2] });

    updateRrParamVector(
      setMeshMultiPart,
      id,
      new basepb.Vector3(refineRegion.origin),
      'Origin',
    );
    updateRrParamVector(
      setMeshMultiPart,
      id,
      new basepb.Vector3(refineRegion.max),
      'CubeMax',
    );
    handleRotation(newProto(...box.rotation));
  }, DEBOUNCE_TIME), [handleRotation, id, setMeshMultiPart]);

  // show widget & set change callback
  useEffect(() => {
    const manipulationMode = (
      LCVManipulationMode.kLCVManipulationModeTranslate |
      LCVManipulationMode.kLCVManipulationModeScale |
      LCVManipulationMode.kLCVManipulationModeRotate
    );

    showBoxWidget({
      onChange: (updatedValue) => {
        const rrDefinition = boxDefinitionToRefineRegionDefinition({
          size: newProto(...updatedValue.size),
          center: newProto(...updatedValue.center),
        }, rotation);

        setTempRefinementRegion(new OrientedCube({
          max: new basepb.Vector3(rrDefinition.max),
          origin: new basepb.Vector3(rrDefinition.origin),
          min: newOriginProto(),
          xAxis: newProto(1, 0, 0),
          yAxis: newProto(0, 1, 0),
        }));
        setTempRotation(newProto(...updatedValue.rotation));

        applyDebouncedBox(null, updatedValue);
      },
      manipulationMode,
    });

    return hideBoxWidget;
  }, [applyDebouncedBox, rotation]);

  // synchronize widget state with the initial data
  useEffect(() => {
    const box = refineRegionDefinitionToBoxDefinition(cubeParam, rotation);

    updateBoxWidgetState({
      center: [box.center.x, box.center.y, box.center.z],
      size: [box.size.x, box.size.y, box.size.z],
      rotation: [rotation.x, rotation.y, rotation.z],
    });
  }, [cubeParam, rotation]);

  // synchronize temp state when source changes
  useEffect(() => {
    setTempRotation(rotation);
    setTempRefinementRegion(cubeParam);
  }, [rotation, cubeParam]);

  return (
    <>
      <BoxInput
        onCommit={(box) => {
          const refineRegion = boxDefinitionToRefineRegionDefinition(box);

          updateRrParamVector(
            setMeshMultiPart,
            id,
            new basepb.Vector3(refineRegion.origin),
            'Origin',
          );
          updateRrParamVector(
            setMeshMultiPart,
            id,
            new basepb.Vector3(refineRegion.max),
            'CubeMax',
          );
        }}
        value={refineRegionDefinitionToBoxDefinition(tempRefinementRegion)}
      />
      <LabeledInput
        help="Euler angles defining the rotation of the box."
        label="Orientation">
        <Vector3Input
          disabled={readOnly}
          onCommit={(value) => {
            setTempRotation(value);
            handleRotation(value);
          }}
          quantityType={quantitypb.QuantityType.DEGREE}
          value={tempRotation}
        />
      </LabeledInput>
    </>
  );
};

const SphereSection = (props: ShapeSectionProps) => {
  const { id, readOnly, currentRrParam, setMeshMultiPart } = props;
  assert(currentRrParam.shape.case === 'sphereShell', 'SphereSection only valid for SphereShell');
  const sphereParam = currentRrParam.shape.value!;

  const [tempSphereShell, setTempSphereShell] = useState(sphereParam);

  const applyDebouncedSphere = useMemo(
    () => debounce((_, sphereShell: Partial<LcvSphereWithInnerRadiusWidgetState>) => {
      if (sphereShell.center) {
        updateRrParamVector(setMeshMultiPart, id, newProto(...sphereShell.center), 'Center');
      }
      if (sphereShell.radius !== undefined) {
        updateRrParamNumber(setMeshMultiPart, id, sphereShell.radius, 'SphereRadiusOuter');
      }
      if (sphereShell.radiusInner !== undefined) {
        updateRrParamNumber(setMeshMultiPart, id, sphereShell.radiusInner, 'SphereRadiusInner');
      }
    }, DEBOUNCE_TIME),
    [id, setMeshMultiPart],
  );

  // show widget & set change callback
  useEffect(() => {
    showSphereWithInnerRadiusWidget({
      onChange: (updatedSphere) => {
        applyDebouncedSphere(null, updatedSphere);

        setTempSphereShell((currentShell) => {
          const result = currentShell.clone();

          if (updatedSphere.center) {
            result.center = newProto(...updatedSphere.center);
          }
          if (updatedSphere.radius !== undefined) {
            result.radius = updatedSphere.radius;
          }
          if (updatedSphere.radiusInner !== undefined) {
            result.radiusInner = updatedSphere.radiusInner;
          }

          return result;
        });
      },
      innerSphereManipulationMode: LCVManipulationMode.kLCVManipulationModeScale,
    });

    return hideSphereWithInnerRadiusWidget;
  }, [applyDebouncedSphere, sphereParam]);

  // synchronize widget state with the initial data
  useEffect(() => {
    updateSphereWithInnerRadiusWidgetState({
      radius: sphereParam.radius,
      center: [sphereParam.center!.x, sphereParam.center!.y, sphereParam.center!.z],
      radiusInner: sphereParam.radiusInner,
    });
  }, [sphereParam.center, sphereParam.radius, sphereParam.radiusInner]);

  // synchronize temp state when source changes
  useEffect(() => {
    setTempSphereShell(sphereParam);
  }, [sphereParam]);

  return (
    <>
      <LabeledInput
        label="Center">
        <Vector3Input
          disabled={readOnly}
          onCommit={(newValue: basepb.Vector3) => {
            setTempSphereShell((currentShell) => {
              const result = currentShell.clone();

              result.center = newValue;
              return result;
            });
            updateRrParamVector(setMeshMultiPart, id, newValue, 'Center');
          }}
          value={tempSphereShell.center!}
        />
      </LabeledInput>
      <LabeledInput
        label="Outer Radius">
        <ValidatedNumberSpinner
          disabled={readOnly}
          endAdornment={<QuantityAdornment quantity={quantitypb.QuantityType.LENGTH} />}
          messageOnError={(newValue: number) => {
            if (newValue < 0) {
              return 'Must be > 0.';
            }
            const innerRadius = tempSphereShell.radiusInner;
            if (newValue < innerRadius) {
              return 'Must be > inner radius.';
            }
            return '';
          }}
          minimumValue={Math.max(tempSphereShell.radiusInner, 0)}
          onCommit={(newValue: number) => {
            updateRrParamNumber(setMeshMultiPart, id, newValue, 'SphereRadiusOuter');

            setTempSphereShell((currentShell) => {
              const result = currentShell.clone();

              result.radius = newValue;
              return result;
            });
          }}
          step={{ scaleFactor: 2 }}
          value={tempSphereShell.radius}
        />
      </LabeledInput>
      <LabeledInput
        label="Inner Radius">
        <ValidatedNumberSpinner
          disabled={readOnly}
          endAdornment={<QuantityAdornment quantity={quantitypb.QuantityType.LENGTH} />}
          maximumValue={tempSphereShell.radius}
          messageOnError={(newValue: number) => {
            if (newValue < 0) {
              return 'Must be > 0.';
            }
            const outerRadius = tempSphereShell.radius;
            if (newValue > outerRadius) {
              return 'Must be < outer radius.';
            }
            return '';
          }}
          minimumValue={0}
          onCommit={(newValue: number) => {
            updateRrParamNumber(setMeshMultiPart, id, newValue, 'SphereRadiusInner');

            setTempSphereShell((currentShell) => {
              const result = currentShell.clone();

              result.radiusInner = newValue;
              return result;
            });
          }}
          step={{ scaleFactor: 2 }}
          value={tempSphereShell.radiusInner}
        />
      </LabeledInput>
    </>
  );
};

const CylinderSection = (props: ShapeSectionProps) => {
  const { id, readOnly, currentRrParam, setMeshMultiPart } = props;
  assert(
    currentRrParam.shape.case === 'annularCylinder',
    'CylinderSection only valid for AnnularCylinder',
  );
  const cylinderParam = currentRrParam.shape.value!;
  const [tempCylinder, setTempCylinder] = useState(cylinderParam);

  const applyDebouncedCylinder = useMemo(() => (
    debounce((_, cylinderState: Partial<LcvCylinderWithInnerRadiusWidgetState>) => {
      if (cylinderState.start) {
        updateRrParamVector(setMeshMultiPart, id, newProto(...cylinderState.start), 'Start');
      }
      if (cylinderState.end) {
        updateRrParamVector(setMeshMultiPart, id, newProto(...cylinderState.end), 'End');
      }
      if (cylinderState.radius !== undefined) {
        updateRrParamNumber(setMeshMultiPart, id, cylinderState.radius, 'CylRadiusOuter');
      }
      if (cylinderState.radiusInner !== undefined) {
        updateRrParamNumber(setMeshMultiPart, id, cylinderState.radiusInner, 'CylRadiusInner');
      }
    }, DEBOUNCE_TIME)
  ), [id, setMeshMultiPart]);

  // show widget & set change callback
  useEffect(() => {
    showCylinderWithInnerRadiusWidget({
      onChange: (updatedCylinder) => {
        setTempCylinder((previousCylinder) => {
          const result = previousCylinder.clone();

          if (updatedCylinder.start) {
            result.start = newProto(...updatedCylinder.start);
          }
          if (updatedCylinder.end) {
            result.end = newProto(...updatedCylinder.end);
          }
          if (updatedCylinder.radius !== undefined) {
            result.radius = updatedCylinder.radius;
          }
          if (updatedCylinder.radiusInner !== undefined) {
            result.radiusInner = updatedCylinder.radiusInner;
          }

          return result;
        });

        applyDebouncedCylinder(null, updatedCylinder);
      },
      innerCylinderManipulationMode: LCVManipulationMode.kLCVManipulationModeScale,
    });

    return hideCylinderWithInnerRadiusWidget;
  }, [applyDebouncedCylinder]);

  // synchronize widget state with the initial data
  useEffect(() => {
    updateCylinderWithInnerRadiusWidgetState({
      start: [cylinderParam.start!.x, cylinderParam.start!.y, cylinderParam.start!.z],
      end: [cylinderParam.end!.x, cylinderParam.end!.y, cylinderParam.end!.z],
      radius: cylinderParam.radius,
      radiusInner: cylinderParam.radiusInner,
    });
  }, [cylinderParam.end, cylinderParam.radius, cylinderParam.start, cylinderParam.radiusInner]);

  // synchronize temp state when source changes
  useEffect(() => {
    setTempCylinder(cylinderParam);
  }, [cylinderParam]);

  return (
    <>
      <LabeledInput
        help="Location of the cylinder's first base's center."
        label="Start">
        <Vector3Input
          disabled={readOnly}
          onCommit={(newValue: basepb.Vector3) => {
            updateRrParamVector(setMeshMultiPart, id, newValue, 'Start');
            setTempCylinder((cylinder) => {
              const result = cylinder.clone();
              result.start = newValue;

              return result;
            });
          }}
          value={tempCylinder.start!}
        />
      </LabeledInput>
      <LabeledInput
        help="Location of the cylinder's second base's center."
        label="End">
        <Vector3Input
          disabled={readOnly}
          onCommit={(newValue: basepb.Vector3) => {
            updateRrParamVector(setMeshMultiPart, id, newValue, 'End');

            setTempCylinder((cylinder) => {
              const result = cylinder.clone();
              result.end = newValue;

              return result;
            });
          }}
          value={tempCylinder.end!}
        />
      </LabeledInput>
      <LabeledInput
        label="Outer Radius">
        <ValidatedNumberSpinner
          disabled={readOnly}
          endAdornment={<QuantityAdornment quantity={quantitypb.QuantityType.LENGTH} />}
          messageOnError={(newValue: number) => {
            if (newValue < 0) {
              return 'Must be > 0.';
            }
            const innerRadius = cylinderParam.radiusInner;
            if (newValue <= innerRadius) {
              return 'Must be > inner radius.';
            }
            return '';
          }}
          minimumValue={cylinderParam.radiusInner}
          onCommit={(newValue: number) => {
            updateRrParamNumber(setMeshMultiPart, id, newValue, 'CylRadiusOuter');

            setTempCylinder((cylinder) => {
              const result = cylinder.clone();
              result.radius = newValue;

              return result;
            });
          }}
          step={{ scaleFactor: 2 }}
          value={tempCylinder.radius}
        />
      </LabeledInput>
      <LabeledInput
        label="Inner Radius">
        <ValidatedNumberSpinner
          disabled={readOnly}
          endAdornment={<QuantityAdornment quantity={quantitypb.QuantityType.LENGTH} />}
          maximumValue={tempCylinder.radius}
          messageOnError={(newValue: number) => {
            if (newValue < 0) {
              return 'Must be > 0.';
            }
            const outerRadius = tempCylinder.radius;
            if (newValue >= outerRadius) {
              return 'Must be < outer radius.';
            }
            return '';
          }}
          minimumValue={0}
          onCommit={(newValue: number) => {
            updateRrParamNumber(setMeshMultiPart, id, newValue, 'CylRadiusInner');

            setTempCylinder((cylinder) => {
              const result = cylinder.clone();
              result.radiusInner = newValue;

              return result;
            });
          }}
          step={{ scaleFactor: 2 }}
          value={tempCylinder.radiusInner}
        />
      </LabeledInput>
    </>
  );
};

interface SectionProps extends ShapeSectionProps {
  param: simulationpb.SimulationParam,
}

const ShapeSection = (props: SectionProps) => {
  const { projectId } = useProjectContext();

  const { id, currentRrParam } = props;
  const nodeId = `shapeDef-${id}`;
  const nodeName = 'ShapeDefinition';
  const currentShape = getShapeName(currentRrParam);
  const setRefinementRegionVisibility = useSetRefinementRegionVisibility(projectId);

  useEffect(() => {
    let hasEffectHiddenRegion = false;

    setRefinementRegionVisibility((currentValue) => {
      const isAlreadyHidden = currentValue[id] !== undefined && !currentValue[id];

      if (!isAlreadyHidden) {
        hasEffectHiddenRegion = true;
        return { ...currentValue, [id]: false };
      }

      return currentValue;
    });

    return () => {
      // restore the region's visibility if the effect has hidden it
      if (hasEffectHiddenRegion) {
        setRefinementRegionVisibility((currentValue) => ({ ...currentValue, [id]: true }));
      }
    };
  }, [id, setRefinementRegionVisibility]);

  const shapeParamSection = useMemo(() => {
    switch (currentShape) {
      case 'Sphere':
        return <SphereSection {...props} />;
      case 'Cylinder':
        return <CylinderSection {...props} />;
      case 'Box':
        return <BoxSection {...props} />;
      default:
        return <></>;
    }
  }, [currentShape, props]);

  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        heading="Shape Definition"
        nodeId={nodeId}
        panelName={nodeName}>
        {shapeParamSection}
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};

export const RefinementRegionPropPanel = () => {
  const { selectedNode } = useSelectionContext();
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const setSelectedRegion = useSetRefinementRegionSelection(projectId);
  const meshReadOnly = useMeshReadOnly(projectId);

  const disabled = readOnly || meshReadOnly;

  const idRef = useRef(selectedNode?.id);

  const currentRrParam = useMemo(
    () => getCurrentRrParam(selectedNode?.id, meshMultiPart),
    [meshMultiPart, selectedNode?.id],
  );

  useEffect(() => {
    if (selectedNode && currentRrParam && selectedNode.id === currentRrParam.id) {
      idRef.current = selectedNode.id;
      setSelectedRegion(currentRrParam.id);
    }

    return () => {
      if (!idRef.current) {
        return;
      }
      setSelectedRegion('');
    };
  }, [selectedNode, currentRrParam, setSelectedRegion]);

  if (!selectedNode || !meshMultiPart?.refinementParams.length || !currentRrParam) {
    return <EmptyPropPanel />;
  }

  return (
    <div>
      <PropertiesSection>
        <SizingSection
          currentRrParam={currentRrParam}
          id={selectedNode.id}
          meshMultiPart={meshMultiPart}
          readOnly={disabled}
          setMeshMultiPart={setMeshMultiPart}
        />
      </PropertiesSection>
      <Divider />
      <ShapeSection
        currentRrParam={currentRrParam}
        id={selectedNode.id}
        param={simParam}
        readOnly={disabled}
        setMeshMultiPart={setMeshMultiPart}
      />
    </div>
  );
};
