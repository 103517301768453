// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView } from '../../../lib/componentTypes/context';
import { RecoilProjectKey } from '../../../lib/persist';
import { meshReadyStateAtom } from '../../../recoil/useMeshReadyState';
import { surfaceToVolumeMapState } from '../../../recoil/volumes';
import { currentViewState } from '../global/currentView';

const showGeometryTreeState = atomFamily<boolean, RecoilProjectKey>({
  key: 'showGeometryTreeState',
  default: selectorFamily<boolean, RecoilProjectKey>({
    key: 'showGeometryTreeState/default',
    get: (key: RecoilProjectKey) => ({ get }) => {
      const [
        surfaceToVolumeMap,
        meshReadyState,
        currentView,
      ] = get(waitForAll([
        surfaceToVolumeMapState(key.projectId),
        meshReadyStateAtom(key),
        currentViewState,
      ]));

      // We show the tree only when we have data for it or if we are the Geometry tab
      if ((meshReadyState && surfaceToVolumeMap.size) || currentView === CurrentView.GEOMETRY) {
        return true;
      }

      return false;
    },
  }),
});

export function useShowGeometryTree(key: RecoilProjectKey) {
  return useRecoilValue(showGeometryTreeState(key));
}
