// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVType } from '@luminarycloudinternal/lcvis';
import { deepEqual } from 'fast-equals';

import { RgbColor } from '../../../designSystem';
import { LcvModule } from '../../types';

import { LcvFilter } from './LcvFilter';
import { MonitorPlaneState } from './filterUtils';

export class LcvMonitorPlane extends LcvFilter {
  state: MonitorPlaneState;
  parent: LcvFilter;
  color: RgbColor | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    id: string,
    parent: LcvFilter,
    initialState: MonitorPlaneState,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'monitor_plane',
        `monitor_plane_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'monitor_plane',
      id,
    );

    this.parent = parent;
    this.state = initialState;

    this.lcv.connectFilters(sessionHandle, workspaceHandle, parent.handle, this.handle);

    this.updateParams();
  }

  /**
   * Update the mesh_extra field command to be compatible with the new filter state.
   */
  updateParams() {
    const { state } = this;
    const {
      origin,
      normal,
      volume_ids,
      box_constraint_enabled,
      box_origin,
      box_rotation,
      box_size,
    } = state;

    this.setParam('origin', LCVType.kLCVDataTypeFloat3, origin);
    this.setParam('normal', LCVType.kLCVDataTypeFloat3, normal);
    this.setParam(
      'volume_ids',
      LCVType.kLCVDataTypeData1D,
      this.lcv.writeStringArray(this.sessionHandle, volume_ids),
    );

    this.setParam(
      'box_constraint_enabled',
      LCVType.kLCVDataTypeUint,
      Number(box_constraint_enabled),
    );

    if (box_rotation) {
      this.setParam('box_rotation', LCVType.kLCVDataTypeFloat3, box_rotation);
    }

    if (box_size) {
      this.setParam('box_size', LCVType.kLCVDataTypeFloat3, box_size);
    }

    if (box_origin) {
      this.setParam('box_origin', LCVType.kLCVDataTypeFloat3, box_origin);
    }
  }

  setState(newState: MonitorPlaneState) {
    if (deepEqual(this.state, newState)) {
      return;
    }

    this.state = newState;
    return this.updateParams();
  }
}
