// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import * as rpc from '../../lib/rpc';
import { isTestingEnv } from '../../lib/testing/utils';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as cadmetadatapb from '../../proto/cadmetadata/cadmetadata_pb';
import { selectedGeometryState } from '../selectedGeometry';
import { cadMetadataState } from '../useCadMetadata';
import { routeParamsState } from '../useRouteParams';

import { DEFAULT_SELECTED_FEATURE, geometrySelectedFeature, getGeoState, onGeometryTabSelector } from './geometryState';
import { GeometryTags } from './geometryTagsObject';
import { geometryUsesTagsSelector } from './geometryUsesTags';

// TODO(LC-21795): Figure out a better key.
type RecoilKey = {
  projectId: string,
};

const geometryTagsStateTesting = selectorFamily<
  GeometryTags,
  RecoilKey
>({
  key: 'geometryTags/testing',
  get: () => () => new GeometryTags(undefined, new cadmetadatapb.CadMetadata()),
  dangerouslyAllowMutability: true,
});

// List of geometries in a given project.
export const geometryTagsStateNonTesting = selectorFamily<
  GeometryTags,
  RecoilKey
>({
  key: 'geometryTags',
  get: (key: RecoilKey) => async ({ get }) => {
    const geoUsesTags = get(geometryUsesTagsSelector(key.projectId));
    if (!geoUsesTags) {
      return new GeometryTags(undefined, new cadmetadatapb.CadMetadata());
    }
    const geoTab = get(onGeometryTabSelector);
    if (geoTab) {
      const geoState = getGeoState(get, key.projectId);
      const params = get(routeParamsState);
      if (!geoState) {
        return new GeometryTags(undefined, new cadmetadatapb.CadMetadata());
      }
      // In the geometry tab with a valid state, we don't want users to see tags while operations
      // are in progress or while users are looking at an arbitrary feature. Hence, we return empty
      // tags in such cases to avoid confussion and intricate behaviors.
      const geometrySelectedFeatureValue = get(geometrySelectedFeature(params.geometryId || ''));
      if (geometrySelectedFeatureValue === DEFAULT_SELECTED_FEATURE) {
        return new GeometryTags(geoState.tags, geoState.cadMetadata);
      }
      return new GeometryTags(undefined, new cadmetadatapb.CadMetadata());
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return get(geometryTagsStateSetupTab(key));
  },
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

const geometryTagsStateSetupTabNonTesting = selectorFamily<
  GeometryTags,
  RecoilKey
>({
  key: 'geometryTagsSetupTab',
  get: (key: RecoilKey) => async ({ get }) => {
    const { projectId } = key;
    // Don't start the RPC if the flag is not enabled.
    const [selectedGeometry, cadMetadata] = get(waitForAll([
      selectedGeometryState(projectId), cadMetadataState(projectId),
    ]));
    if (!rpc.clientGeometry || !projectId || !selectedGeometry.geometryId) {
      return new GeometryTags(undefined, cadMetadata);
    }
    try {
      const response = await rpc.callRetryWithClient(
        rpc.clientGeometry,
        'GetTags',
        rpc.clientGeometry.getTags,
        new geometryservicepb.GetTagsRequest({
          geometryId: selectedGeometry.geometryId,
          geometryVersionId: selectedGeometry.geometryVersionId,
        }),
      );
      return new GeometryTags(response.tags, cadMetadata);
    } catch (error) {
      console.error('Failed to get tags', error);
      return new GeometryTags(undefined, cadMetadata);
    }
  },
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

export const geometryTagsState = isTestingEnv() ?
  geometryTagsStateTesting : geometryTagsStateNonTesting;

export const geometryTagsStateSetupTab = isTestingEnv() ?
  geometryTagsStateTesting : geometryTagsStateSetupTabNonTesting;

export function useGeometryTags(projectId: string) {
  return useRecoilValue(geometryTagsState({ projectId }));
}
