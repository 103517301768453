// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/**
 * Configure the geometry for a project.
 */
import React, { Suspense, useCallback, useEffect, useMemo, useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE } from 'recoil';

import ProjectStateSync from '../components/RecoilSync/ProjectState';
import suspenseWidget from '../components/SuspenseWidget';
import Project from '../components/context/ProjectContext';
import { MainPageLayout } from '../components/layout/page/Main';
import { CurrentView } from '../lib/componentTypes/context';
import { geometryIdLink } from '../lib/navigation';
import { ProjectParams } from '../lib/routeParamTypes';
import * as rpc from '../lib/rpc';
import { ListenerEvent, useEventListener } from '../lib/useEventListener';
import { debounce } from '../lib/utils';
import { useGeometryList } from '../recoil/geometry/geometryListState';
import { useGeometryServerStatus } from '../recoil/geometry/geometryServerStatus';
import { useGeometryState, useLatestGeometryVersionid } from '../recoil/geometry/geometryState';
import { geometryTagsState } from '../recoil/geometry/geometryTagsState';
import { useGeometryHealth, useSetGeometryHealth } from '../recoil/geometryHealth';
import { useSetShouldGetCheckGeometry } from '../recoil/getCheckGeometry';
import useProjectMetadata from '../recoil/useProjectMetadata';
import { useIsStaff } from '../state/external/user/frontendRole';
import { useSetCurrentView } from '../state/internal/global/currentView';

import PageBody from './ProjectBody';

const GeometryPage = () => {
  const params = useParams<ProjectParams>();
  const projectId = params.projectId || '';
  const geometryId = params.geometryId || '';
  const projectMetadata = useProjectMetadata(projectId);
  const setCurrentView = useSetCurrentView();
  const geometryList = useGeometryList(projectId);
  const [geometryServerStatus] = useGeometryServerStatus(geometryId);
  const navigate = useNavigate();
  const isStaff = useIsStaff();
  const lastGeometryVersionId = useLatestGeometryVersionid(projectId, geometryId);
  const setGeometryHealth = useSetGeometryHealth(projectId);
  const setShouldGetCheckGeometry = useSetShouldGetCheckGeometry();
  const firstGeometryVersionId = useRef<string>('');
  const lastGeometryVersionIdRef = useRef<string>('');
  const geometryHealth = useGeometryHealth(projectId);
  const geometryState = useGeometryState(projectId, geometryId);
  const firstGeometryStateLoaded = useRef(false);
  const refreshGeometryTags = useRecoilRefresher_UNSTABLE(geometryTagsState({ projectId }));

  // This effect is needed to fix LC-23325. It seems that the tags selector is not being reevaluated
  // when the geometryState changes. This is a workaround to force the reevaluation of the tags.
  useEffect(() => {
    if (!geometryState || firstGeometryStateLoaded.current) {
      return;
    }
    firstGeometryStateLoaded.current = true;
    refreshGeometryTags();
  }, [geometryState, refreshGeometryTags]);

  const projectName = projectMetadata ?
    projectMetadata.summary!.name :
    projectId;

  useEffect(() => {
    setCurrentView(CurrentView.GEOMETRY);
  }, [setCurrentView]);

  useEffect(() => {
    if (!lastGeometryVersionId) {
      return;
    }
    // Make sure to reset the geometry health state when the version changes. We always go forward
    // in the version ID sense, so whenever that version changes, it is safe to assume that there
    // will be no available health information yet. Users can then demand a geometry check.
    if (!firstGeometryVersionId.current) {
      firstGeometryVersionId.current = lastGeometryVersionId;
      lastGeometryVersionIdRef.current = lastGeometryVersionId;
      return;
    }
    if (lastGeometryVersionId !== firstGeometryVersionId.current) {
      setShouldGetCheckGeometry(false);
    }
    if (lastGeometryVersionId !== lastGeometryVersionIdRef.current) {
      setGeometryHealth(null);
      lastGeometryVersionIdRef.current = lastGeometryVersionId;
    }
  }, [lastGeometryVersionId, setGeometryHealth, setShouldGetCheckGeometry, geometryHealth]);

  const keepAliveDebounce = useMemo(() => debounce(() => {
    if (!geometryId) {
      return;
    }
    rpc.clientGeometry!.keepAlive({ geometryId }).catch(
      (error: Error) => console.error(`Error keeping alive ${error}`),
    );
  }, 1500), [geometryId]);
  const keepAliveDebounceCb = useCallback((eventListener: ListenerEvent) => {
    if (geometryServerStatus !== 'disconnected') {
      const event = eventListener as KeyboardEvent;
      // Some logic to disconnect the worker when using a rather convoluted key combination. This is
      // needed to speed up UI tests.
      if (event.ctrlKey && event.shiftKey && event.key === 'K' && isStaff) {
        rpc.clientGeometry!.stopWorker({ geometryId }).catch(
          (error: Error) => console.error(`Error sending special command ${error}`),
        );
        return;
      }
      keepAliveDebounce();
    }
  }, [geometryId, geometryServerStatus, isStaff, keepAliveDebounce]);
  useEventListener('click', keepAliveDebounceCb);
  useEventListener('keydown', keepAliveDebounceCb);
  useEventListener('keypress', keepAliveDebounceCb);
  useEventListener('scroll', keepAliveDebounceCb);

  // If there is a geometryId within the project we navigate directly to it. This is to avoid users
  // seeing more than one geometry in their project. The backend supports it but we don't want to
  // expose such a functionality to the users.
  useEffect(() => {
    if (!geometryId && geometryList?.geometries.length) {
      navigate(geometryIdLink(projectId, geometryList.geometries[0].id));
    }
  }, [geometryId, geometryList, navigate, projectId]);

  return (
    <MainPageLayout projectId={projectId} title={projectName}>
      <Suspense fallback={suspenseWidget}>
        <ProjectStateSync geometryId={geometryId} projectId={projectId}>
          <Project
            geometryId={geometryId}
            projectId={projectId}
            selectedJobId=""
            workflowId="">
            <PageBody
              isExploration={false}
            />
          </Project>
        </ProjectStateSync>
      </Suspense>
    </MainPageLayout>
  );
};
export default GeometryPage;
