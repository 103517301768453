// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import * as feoutputpb from '../../../../proto/frontend/output/output_pb';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useViewStateOverflow } from '../../../../recoil/lcvis/viewStateOverflow';
import { BaseExpressionPanel } from '../common/BaseExpressionPanel';

// TODO(chiodi): Implement this key/value store in the backend and then uncomment below.
const useValidateExpressions =
  (jobId: string) => useCallback((expressions: Map<string, string>) => { }, []);

export interface ExpressionPanelProps {
  customFieldNode: feoutputpb.CustomField;
  projectId: string;
  workflowId: string;
  jobId: string;
  error?: boolean;
}

export function ExpressionPanel(props: ExpressionPanelProps) {
  const { error, customFieldNode, projectId, workflowId, jobId } = props;

  const updateExpression = useValidateExpressions(jobId);

  const filterOptionItem = ({ type }: ParaviewRpc.ArrayInformation) => (
    type === ParaviewRpc.FieldAssociation.POINT
  );

  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });
  const fieldList = lcvisData.data.filter(filterOptionItem).map(({ name }) => name);
  const options = fieldList;

  const handleUpdateExpression = (newExpression: string, id: string) => {
    updateExpression(new Map<string, string>().set(id, newExpression));
  };

  return (
    <BaseExpressionPanel
      error={error}
      expression={customFieldNode.expression}
      expressionId={customFieldNode.id}
      onUpdateExpression={handleUpdateExpression}
      options={options}
      placeholderText={'Use existing fields, math operators, and numeric values to create an ' +
        'expression.\nExample: max(0.0, "Pressure")'}
      tooltipText={'Define a custom field using an expression with existing fields in ' +
        'quotation marks.'}
    />
  );
}
