// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import { geometryListState } from './geometryListState';

/**
 * Whether to the geometry being processes uses tags.
 */
export const geometryUsesTagsSelector = selectorFamily<boolean, string>({
  key: 'geometryUsesTagSelector',
  get: (projectId: string) => ({ get }) => {
    const geometryList = get(geometryListState(projectId));
    return geometryList?.geometries[0]?.usesTags ?? false;
  },
});

export const useGeometryUsesTags = (
  projectId: string,
) => useRecoilValue(geometryUsesTagsSelector(projectId));
