// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily, waitForAll } from 'recoil';

import { CurrentView } from '../../../lib/componentTypes/context';
import { newNode } from '../../../lib/paraviewUtils';
import {
  ADJOINT_SETTINGS_NODE_ID,
  CAD_SOLID_OR_FLUID_NODE_ID,
  CREATE_FARFIELD_NODE_ID,
  GENERAL_SETTINGS_NODE_ID,
  NodeType,
  ROOT_SIMULATION_CONTAINER_ID,
  SOLVER_SETTINGS as SOLVER_SETTINGS_NODE_ID,
  STOPPING_CONDITIONS_NODE_ID,
  SimulationTreeNode,
  TRANSIENT_SETTINGS_NODE_ID,
  UPLOAD_CAD_OR_MESH_NODE_ID,
} from '../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { paraviewToSimulationNode } from '../../../lib/simulationTree/utils';
import { addChildNode } from '../../../lib/visUtils';
import { FlowBehavior } from '../../../proto/client/simulation_pb';
import { TreeNode } from '../../../pvproto/ParaviewRpc';
import { lcVisEnabledSelector } from '../../../recoil/lcvis/lcvisEnabledState';
import { editStateState } from '../../../recoil/paraviewState';
import { controlPanelModeState } from '../../../recoil/useProjectPage';
import { TreeViewType, treeViewTypeState } from '../../../recoil/useTreeViewState';
import { viewStateAtomFamily } from '../../../recoil/useViewState';
import { filterStateSelector } from '../../../recoil/vis/filterState';
import { simulationParamState } from '../../external/project/simulation/param';
import { currentViewState, isAdjointSetupState } from '../global/currentView';

import { explorationTreeSelector } from './exploration';
import { solidOrFluidState } from './geometrySetupTree';
import { cameraSectionSelector } from './section/camera';
import { customFieldSectionSelector } from './section/customField';
import { materialsSectionSelector } from './section/material';
import { meshSectionSelector } from './section/mesh';
import { motionSectionSelector } from './section/motion';
import { outputsSectionSelector } from './section/output';
import { physicsSectionSelector } from './section/physics';
import { plotSectionSelector } from './section/plot';

type SetupTreeInfo = {
  tree: SimulationTreeNode;
  nodeIds: Set<string>;
}

type SetupTreeKey = SectionRecoilKey & { currentView: CurrentView };
// selector for the childNodes of the simulation setup tree, based on the current view.
export const setupTreeState = selectorFamily<SetupTreeInfo, SetupTreeKey>({
  key: 'setupTreeState',
  get: (fullKey: SetupTreeKey) => async ({ get }) => {
    const { currentView, projectId, workflowId, jobId } = fullKey;
    const key = { projectId, workflowId, jobId };
    const [
      mesh,
      materials,
      physics,
      outputs,
      customFields,
      cameras,
      plot,
      motion,
      viewState,
      treeViewType,
      filterState,
      lcvisEnabled,
      simParam,
      solidOrFluid,
      explorationTree,
      controlPanelMode,
      isAdjointSetup,
      editState,
    ] = get(waitForAll([
      meshSectionSelector(key),
      materialsSectionSelector(key),
      physicsSectionSelector(key),
      outputsSectionSelector(key.projectId),
      customFieldSectionSelector(key),
      cameraSectionSelector(key),
      plotSectionSelector(key.projectId),
      motionSectionSelector(key),
      viewStateAtomFamily(key.projectId),
      treeViewTypeState,
      filterStateSelector(key),
      lcVisEnabledSelector(key.projectId),
      simulationParamState(key),
      solidOrFluidState(projectId),
      explorationTreeSelector(key),
      controlPanelModeState,
      isAdjointSetupState,
      editStateState,
    ]));

    const flowBehavior = simParam.general?.flowBehavior;
    const transientSettings = (
      flowBehavior === FlowBehavior.TRANSIENT ?
        new SimulationTreeNode(
          NodeType.TRANSIENT_SETTINGS,
          TRANSIENT_SETTINGS_NODE_ID,
          'Transient Settings',
        ) : null
    );

    const general = new SimulationTreeNode(
      NodeType.GENERAL_SETTINGS,
      GENERAL_SETTINGS_NODE_ID,
      'General',
    );

    const stoppingConditions = new SimulationTreeNode(
      NodeType.STOPPING_CONDITIONS,
      STOPPING_CONDITIONS_NODE_ID,
      'Stopping Conditions',
    );

    const solverSettings = new SimulationTreeNode(
      NodeType.SOLVER_SETTINGS,
      SOLVER_SETTINGS_NODE_ID,
      'Solver Settings',
    );

    const getVisChildren = () => {
      let rootNode: TreeNode;

      if (lcvisEnabled) {
        rootNode = filterState;
      } else if (viewState) {
        rootNode = viewState.root;
      } else {
        return [];
      }

      if (editState?.newNode) {
        const parentId = editState.nodeId;

        rootNode = addChildNode(
          rootNode,
          parentId,
          newNode(editState.param, rootNode, false, editState.displayProps, editState.newNodeId),
        );
      }

      return [paraviewToSimulationNode(rootNode)];
    };

    const childNodes: SimulationTreeNode[] = [];
    switch (currentView) {
      case CurrentView.ADVANCED_ANALYSIS: {
        if (isAdjointSetup) {
          const adjointSettings = new SimulationTreeNode(
            NodeType.ADJOINT_SETTINGS,
            ADJOINT_SETTINGS_NODE_ID,
            'Adjoint Settings',
          );

          childNodes.push(
            adjointSettings,
            solverSettings,
            outputs,
            stoppingConditions,
          );
        }
        if (controlPanelMode === 'exploration') {
          childNodes.push(...explorationTree.children);
        }
        break;
      }

      case CurrentView.SETUP:
        childNodes.push(
          general,
          materials,
          motion,
          physics,
          mesh,
          solverSettings,
        );
        if (transientSettings) {
          childNodes.push(transientSettings);
        }

        childNodes.push(
          outputs,
          stoppingConditions,
          cameras,
          plot,
          ...getVisChildren(),
        );
        break;
      case CurrentView.ANALYSIS: {
        switch (treeViewType) {
          case TreeViewType.POST_PROCESSING: {
            childNodes.push(
              outputs,
              customFields,
              plot,
              cameras,
              ...getVisChildren(),
            );
            break;
          }
          case TreeViewType.SETUP: {
            childNodes.push(
              general,
              mesh,
              materials,
              motion,
              physics,
              outputs,
              stoppingConditions,
            );
            break;
          }
          default: {
            // node default, enum exhausted
          }
        }
        break;
      }
      case CurrentView.GEOMETRY:
        childNodes.push(
          new SimulationTreeNode(
            NodeType.UPLOAD_CAD_OR_MESH,
            UPLOAD_CAD_OR_MESH_NODE_ID,
            'Upload CAD or Mesh',
          ),
          new SimulationTreeNode(
            NodeType.CAD_SOLID_OR_FLUID,
            CAD_SOLID_OR_FLUID_NODE_ID,
            'Is your CAD solid or fluid?',
          ),
          // TODO: add this back once we have the geometry check trigger feature
          // new SimulationTreeNode(
          //   NodeType.GEOMETRY_CHECK,
          //   GEOMETRY_CHECK_NODE_ID,
          //   'Geometry Check',
          // ),
          // TODO: add this back once shrink wrap actually works
          // new SimulationTreeNode(
          //   NodeType.SHRINK_WRAP,
          //   SHRINK_WRAP_NODE_ID,
          //   'Shrink Wrap',
          // ),
        );
        if (solidOrFluid === 'solid') {
          childNodes.push(
            new SimulationTreeNode(
              NodeType.CREATE_FARFIELD,
              CREATE_FARFIELD_NODE_ID,
              'Create Farfield',
            ),
          );
        }
        break;
      default: {
        // none
      }
    }

    const nodeIds = new Set<string>();
    childNodes.forEach((parent) => {
      parent.traverse((node) => {
        nodeIds.add(node.id);
      });
    });

    return {
      tree: new SimulationTreeNode(
        NodeType.ROOT_SIMULATION,
        ROOT_SIMULATION_CONTAINER_ID,
        'Simulation',
        childNodes,
      ),
      nodeIds,
    };
  },
  dangerouslyAllowMutability: true,
});

export const setupTreeSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'setupTreeSelector',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const currentView = get(currentViewState);
    const setupTree = get(setupTreeState({ ...key, currentView }));
    return setupTree.tree;
  },
  dangerouslyAllowMutability: true,
});
