import { atomFamily, selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { AnyBoundaryCondition, getAllBoundaryConditions } from '../lib/boundaryConditionUtils';
import { unwrapSurfaceIdsNoEntityGroups } from '../lib/entityGroupUtils';
import * as persist from '../lib/persist';
import { simulationParamState } from '../state/external/project/simulation/param';

import { geometryTagsState } from './geometry/geometryTagsState';

/**
 * This atom maps surface IDs to boundary conditions. It is used to quickly find the boundary
 * condition assigned to a given surface without N^2 complexity.
 */
export const surfaceToBoundaryConditionsState =
  atomFamily<Map<string, AnyBoundaryCondition>, persist.RecoilProjectKey>({
    key: 'surfaceToBoundaryConditionsState',
    default: selectorFamily<Map<string, AnyBoundaryCondition>, persist.RecoilProjectKey>({
      key: 'surfaceToBoundaryConditions/Default',
      get: (key: persist.RecoilProjectKey) => ({ get }) => {
        const [simParam, geometryTags] = get(waitForAll([
          simulationParamState(key),
          geometryTagsState(key),
        ]));
        const boundaryConditionsToSurf = new Map<AnyBoundaryCondition, Set<string>>();
        getAllBoundaryConditions(simParam).forEach((bc) => {
          unwrapSurfaceIdsNoEntityGroups(bc.surfaces, geometryTags).forEach((surf) => {
            const set = boundaryConditionsToSurf.get(bc) || new Set();
            set.add(surf);
            boundaryConditionsToSurf.set(bc, set);
          });
        });
        const surfToBoundaryConditions = new Map<string, AnyBoundaryCondition>();
        boundaryConditionsToSurf.forEach((surfSet, bc) => {
          surfSet.forEach((surf) => {
            surfToBoundaryConditions.set(surf, bc);
          });
        });
        return surfToBoundaryConditions;
      },
      dangerouslyAllowMutability: true,
    }),
  });

export function useSurfaceToBoundaryConditions(
  projectId: string,
  workflowId: string,
  jobId: string,
) {
  return useRecoilValue(surfaceToBoundaryConditionsState({ projectId, workflowId, jobId }));
}
