// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilState, useSetRecoilState } from 'recoil';

import * as frontendpb from '../proto/frontend/frontend_pb';

type RequestedWorkOrders = Map<frontendpb.WorkOrderType, frontendpb.PendingWorkOrder>;

// Saves a map of all the work orders that have currently requested meaning that an RPC is in
// progress for it. This map should match pendingWorkOrders when all the work orders are in
// progress. This gets wiped out if the user navigates away.
const workOrderState = atomFamily<RequestedWorkOrders, string>({
  key: 'workOrderState',
  default: new Map<frontendpb.WorkOrderType, frontendpb.PendingWorkOrder>(),
});

export const useRequestedWorkOrders = (projectId: string) => (
  useRecoilState(workOrderState(projectId))
);
export const useSetRequestedWorkOrders = (projectId: string) => (
  useSetRecoilState(workOrderState(projectId))
);
