// Copyright 2020-2025 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect } from 'react';

import { RecoilState, RecoilValue, Snapshot, useRecoilCallback } from 'recoil';

import { CurrentView } from '../../lib/componentTypes/context';
import { convertFromProto } from '../../lib/entityGroupUtils';
import { FARFIELD_ID, createFarfieldGroups } from '../../lib/farfieldUtils';
import { readMeshMetadata, reconcileMeshingSettings } from '../../lib/mesh';
import { Logger } from '../../lib/observability/logs';
import { DEFAULT_MESH_GENERATION } from '../../lib/paramDefaults/meshGenerationState';
import { clearAllBoundaryConditions } from '../../lib/physicsUtils';
import * as rpc from '../../lib/rpc';
import { getSimulationParam, setMeshId } from '../../lib/simulationParamUtils';
import {
  defaultBoundaryLayerParams,
  defaultMeshComplexityParams,
  defaultMeshingMode,
  defaultModelParams,
} from '../../lib/simulationUtils';
import { addWarning } from '../../lib/transientNotification';
import {
  CheckGeometryHandler,
  ComputeGeomContactsHandler,
  GetGeometryHandler,
  GetMeshHandler,
  ReqT,
  WorkOrderHandler,
  WorkOrderRpcPool,
  fixGetGeometryMessage,
} from '../../lib/workOrderHandler';
import { WorkOrderDone } from '../../lib/workOrderTypes';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { Level } from '../../proto/lcstatus/levels_pb';
import * as meshgenerationpb from '../../proto/meshgeneration/meshgeneration_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { checkedUrlsState } from '../../recoil/checkedGeometryUrls';
import { entityGroupState, updateGroups } from '../../recoil/entityGroupState';
import { geometryLastVersionIdAtom } from '../../recoil/geometry/geometryState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useGeometryUsesTags } from '../../recoil/geometry/geometryUsesTags';
import { geometryContactsStateSelector } from '../../recoil/geometryContactsState';
import { geometryHealthState, useSetGeometryHealth } from '../../recoil/geometryHealth';
import { meshUrlState, useSetMeshUrlState } from '../../recoil/meshState';
import { usePendingWorkOrders } from '../../recoil/pendingWorkOrders';
import { selectedGeometryState } from '../../recoil/selectedGeometry';
import { cadMetadataSetupTabState, cadMetadataState } from '../../recoil/useCadMetadata';
import { meshGenParamsSelector, meshGenerationState } from '../../recoil/useMeshGeneration';
import { defaultVolumeParams, meshingMultiPartState } from '../../recoil/useMeshingMultiPart';
import { useRefetchProjectMeshList } from '../../recoil/useProjectMeshList';
import { useRequestedWorkOrders } from '../../recoil/useRequestedWorkOrders';
import { defaultVolumeState } from '../../recoil/volumes';
import { currentConfigSelector, projectConfigState } from '../../recoil/workflowConfig';
import { useSetUploadError } from '../../state/external/project/uploadError';
import { currentViewState } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useDefaultVolumesAssignment } from '../hooks/useDefaultVolumesAssignment';

const logger = new Logger('meshing/WorkOrderManager');

const WorkOrderType = frontendpb.WorkOrderType;
type wOrderKey = frontendpb.WorkOrderType;
type wOrderValue = frontendpb.PendingWorkOrder;

const getGeomRpcPool = new rpc.StreamingRpcPool<
  frontendpb.GetGeometryRequest,
  frontendpb.GetGeometryReply
>('GetGeometry', rpc.client.getGeometry);

const computeGeomContacts = new rpc.StreamingRpcPool<
  frontendpb.ComputeGeometryContactsRequest,
  frontendpb.ComputeGeometryContactsReply
>('ComputeGeometryContacts', rpc.client.computeGeometryContacts);

const checkGeomRpcPool = new rpc.StreamingRpcPool<
  frontendpb.CheckGeometryRequest,
  frontendpb.CheckGeometryReply
>('CheckGeometry', rpc.client.checkGeometry);

const getMeshRpcPool = new rpc.StreamingRpcPool<
  frontendpb.GetMeshRequest,
  frontendpb.GetMeshReply
>('GetMesh', rpc.client.getMesh);

const orderHandlers = new Map<wOrderKey, WorkOrderHandler>();
orderHandlers.set(
  WorkOrderType.GET_GEOMETRY,
  new GetGeometryHandler(getGeomRpcPool as WorkOrderRpcPool),
);
orderHandlers.set(
  WorkOrderType.COMPUTE_GEOMETRY_CONTACTS,
  new ComputeGeomContactsHandler(computeGeomContacts as WorkOrderRpcPool),
);
orderHandlers.set(
  WorkOrderType.CHECK_GEOMETRY,
  new CheckGeometryHandler(checkGeomRpcPool as WorkOrderRpcPool),
);
orderHandlers.set(
  WorkOrderType.GET_MESH,
  new GetMeshHandler(getMeshRpcPool as WorkOrderRpcPool),
);

function removeOrder(workOrders: Map<wOrderKey, wOrderValue>, key: wOrderKey) {
  const newWorkOrders = new Map<wOrderKey, wOrderValue>(workOrders);
  newWorkOrders.delete(key);
  return newWorkOrders;
}

export interface WorkOrderManagerProps {
  projectId: string,
}

// WorkOrderManager handles all the work orders in projectstatepb.pendingWorkOrders.
// If it sees a new work order, it sends a new request and handles the response. If the user
// navigates away before the response is received, it sends a new request when the user navigates
// back. If it sees a work order removed from frontendMenu, it cancels the request.
const WorkOrderManager = (props: WorkOrderManagerProps) => {
  const { projectId } = props;
  const { geometryId } = useProjectContext();

  const [requestedWorkOrders, setRequestedWorkOrders] = useRequestedWorkOrders(projectId);
  const [pendingWorkOrders, setPendingWorkOrders] = usePendingWorkOrders(projectId);
  const geometryTags = useGeometryTags(projectId);
  const setUploadError = useSetUploadError(projectId);
  const pendingOrdersMap = pendingWorkOrders.workOrders;
  const context = { projectId, setPendingWorkOrders, setUploadError };
  const { setSelection } = useSelectionContext();
  // Sets the state of the geometry health card.
  const setGeometryHealth = useSetGeometryHealth(projectId);
  const setMeshUrlState = useSetMeshUrlState(projectId);
  const { refetchMeshList } = useRefetchProjectMeshList(projectId);

  const hasPendingOrders = Object.keys(pendingWorkOrders.workOrders).length > 0;
  useDefaultVolumesAssignment(!hasPendingOrders);
  const geoUsesTags = useGeometryUsesTags(projectId);

  // Handles a getGeometry reply.
  const handleGetGeometry = async (
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void,
    snapshot: Snapshot,
    refresh: (recoilValue: RecoilValue<any>) => void,
    done: frontendpb.GetGeometryReply_Done,
    urlIn: string,
  ) => {
    // In the interactive geometry worflow, the URL is known from the reply of GetGeometry. Else,
    // we follow the normal path where the url is provided by the initial import operation.
    const url = done.interactiveGeoUrl || urlIn;
    const isIgeo = done.interactiveGeoUrl !== '';
    const geometryUrl = done.surfaceGeoMeshUrl;
    const metadata = done.metadata;
    const fileType = done.fileType;
    const selectedGeometry = await snapshot.getPromise(selectedGeometryState(projectId));
    if (!selectedGeometry.geometryId) {
      // Non-igeo mode, the mesh URL needs to be updated here since it is here where we store the
      // tessellation and we don't have a way to retrieve it in another easy way.
      const newMeshUrl = new projectstatepb.MeshUrl({
        url,
        geometry: geometryUrl,
        activeType: projectstatepb.UrlType.GEOMETRY,
      });
      set(meshUrlState(projectId), newMeshUrl);
    } else {
      // The mesh URL can be built from selectedGeometry, so just let recoil that it needs to
      // refetch.
      refresh(meshUrlState(projectId));
    }
    const meshMetadata = await readMeshMetadata(projectId, geometryUrl);
    const currentConfig = await snapshot.getPromise(
      currentConfigSelector({ projectId, workflowId: '', jobId: '' }),
    );
    const newConfig = currentConfig.clone();
    const param = getSimulationParam(newConfig);
    if (fileType.includes('ANSA')) {
      addWarning('This CAD file was generated by ANSA. ' +
        'Robustness of the meshing pipeline cannot be guaranteed. ' +
        'We advise you to use a solid modeler instead.');
    }
    if (fileType.includes('IGES')) {
      addWarning('This CAD file is in IGES format. ' +
        'Robustness of the meshing pipeline cannot be guaranteed. ' +
        'We advise you to use a solid model file format instead.');
    }
    if (metadata) {
      const newProtoEntityGroups = metadata.entityGroups;
      // With igeo, the entity groups are grabbed from the cad metadata in the selector and we don't
      // have any special casing for farfield nodes.
      if (newProtoEntityGroups && !isIgeo) {
        const newEntityGroups = createFarfieldGroups(convertFromProto(newProtoEntityGroups));
        updateGroups({
          groupMap: newEntityGroups,
          param,
          staticVolumes: defaultVolumeState(meshMetadata.meshMetadata, metadata, geoUsesTags),
          meshMetadata: meshMetadata.meshMetadata,
        });
        set(
          entityGroupState({ projectId, workflowId: '', jobId: '' }),
          newEntityGroups,
        );
        // Select farfield node if present
        if (newEntityGroups.has(FARFIELD_ID)) {
          setSelection([FARFIELD_ID]);
        } else {
          setSelection([]);
        }
      }
      set(cadMetadataState(projectId), metadata);
      // This is needed to fix LC-23325 because some state depends on this selector.
      set(cadMetadataSetupTabState(projectId), metadata);
    }
    if (param.input) {
      param.input.url = '';
    }
    // Clear the boundary conditions
    clearAllBoundaryConditions(param);
    if (done.issues.some(({ level }) => level === Level.ERROR)) {
      const checkDone = new frontendpb.CheckGeometryReply_Done({
        ok: false,
        issues: done.issues,
      });
      setGeometryHealth(checkDone);
    } else {
      setGeometryHealth(null);
    }
    if (metadata) {
      const meshGeneration = await snapshot.getPromise(
        meshGenParamsSelector({ projectId, meshUrl: '' }),
      );
      if (meshGeneration) {
        // If meshGeneration exists, only update the max size and surfaces and leave the rest of
        // the parameters the same.
        const newMeshGeneration = new meshgenerationpb.UserMeshingParams({
          ...meshGeneration,
          globalMaxSizeM: metadata.globalMaxSizeM,
          blSurfaces: [],
          modelSurfaces: [],
        });
        set(meshGenerationState(projectId), newMeshGeneration);
      } else {
        // If meshGeneration does not exist, generate the default values from the metadata.
        const newMeshGeneration = new meshgenerationpb.UserMeshingParams({
          ...DEFAULT_MESH_GENERATION,
          globalMaxSizeM: metadata.globalMaxSizeM,
          globalMinSizeM: metadata.globalMinSizeM,
          modelMaxSize: metadata.modelMaxSizeM > 0 ?
            metadata.modelMaxSizeM : metadata.globalMaxSizeM,
        });
        set(meshGenerationState(projectId), newMeshGeneration);
      }

      const meshingMultiPart = await snapshot.getPromise(meshingMultiPartState(projectId));
      if (meshingMultiPart) {
        // If meshingMultiPart exists, only update the max size and surfaces and leave the rest of
        // the parameters the same.
        // NOTE: in igeo mode, the backend already clears out the proto and keeps the tags in it.
        if (!isIgeo) {
          const newMeshMultiPart = meshingMultiPart.clone();
          newMeshMultiPart.volumeParams[0].maxSize = metadata.globalMaxSizeM;
          // Remove surfaces as some may have be part of a farfield that was removed.
          newMeshMultiPart.blParams.forEach((blParam) => {
            blParam.surfaces = [];
          });
          newMeshMultiPart.modelParams.forEach((modelParam) => {
            modelParam.surfaces = [];
          });
          set(meshingMultiPartState(projectId), newMeshMultiPart);
        } else {
          // We need to reconcile the settings with the new metadata. Unfortunately, this biz logic
          // lives in the frontend.
          const meshSurfaces: string[] = [];
          meshMetadata.meshMetadata.zone.forEach((zone) => (
            zone.bound.forEach((bound) => meshSurfaces.push(bound.name))
          ));
          const newMeshMultiPart = reconcileMeshingSettings(
            meshingMultiPart,
            getSimulationParam(newConfig),
            metadata,
            meshSurfaces,
            geometryTags,
          );
          set(meshingMultiPartState(projectId), newMeshMultiPart);
        }
      } else {
        // If meshingMultiPart does not exist, generate the default values from the metadata.
        const newMeshMultiPart = new meshgenerationpb.MeshingMultiPart({
          volumeParams: [defaultVolumeParams(metadata)],
          modelParams: [defaultModelParams(metadata, param)],
          blParams: [defaultBoundaryLayerParams(param, geometryTags)],
          complexityParams: defaultMeshComplexityParams(),
          meshingMode: defaultMeshingMode(),
        });
        set(meshingMultiPartState(projectId), newMeshMultiPart);
      }
    }
    // In igeo mode, the simulation configuration update is done by the backend on LoadToSetup.
    if (!isIgeo) {
      set(projectConfigState(projectId), newConfig);
    }
  };
  const handleReply = useRecoilCallback(({ set, snapshot, refresh }) => async (
    req: ReqT | undefined,
    type: frontendpb.WorkOrderType,
    done: WorkOrderDone,
    url: string,
  ) => {
    switch (type) {
      case WorkOrderType.GET_GEOMETRY:
        await handleGetGeometry(
          set,
          snapshot,
          refresh,
          done as frontendpb.GetGeometryReply_Done,
          url,
        );
        break;
      case WorkOrderType.CHECK_GEOMETRY: {
        const checkDone = done as frontendpb.CheckGeometryReply_Done;
        const currentView = await snapshot.getPromise(currentViewState);
        const latestGeometryVersion = await snapshot.getPromise(geometryLastVersionIdAtom({
          projectId,
          geometryId,
        }));
        // Avoid setting the geometry health state if the geometry version ID that we are receiving
        // the reply is not the same as the one we are currently viewing.
        if (currentView === CurrentView.GEOMETRY) {
          const { geometryVersionId } = req as frontendpb.CheckGeometryRequest;
          if (latestGeometryVersion !== geometryVersionId) {
            return;
          }
        }
        set(geometryHealthState(projectId), checkDone);
        if (checkDone.ok) {
          // we need to await the checkedUrlsState before setting it, since calling set on
          // an async atom/selector throws a recoil error if the state hasn't yet resolved.
          await snapshot.getPromise(checkedUrlsState(projectId));
          set(
            checkedUrlsState(projectId),
            (oldUrls) => {
              const newUrls = oldUrls.clone();
              newUrls.status = projectstatepb.CheckGeometryStatus.SUCCESSFUL;
              newUrls.urls.push(url);
              return newUrls;
            },
          );
        }
        break;
      }
      case WorkOrderType.GET_MESH: {
        const meshUrl = (done as frontendpb.GetMeshReply_Done).meshUrl;
        const meshId = (done as frontendpb.GetMeshReply_Done).meshId;

        set(meshUrlState(projectId), (oldMeshState) => {
          const newMeshState = oldMeshState.clone();
          newMeshState.mesh = meshUrl;
          newMeshState.meshId = meshId;
          newMeshState.activeType = projectstatepb.UrlType.MESH;
          return newMeshState;
        });
        // Set the new mesh url in the project config
        set(projectConfigState(projectId), (oldConfig) => {
          const newConfig = oldConfig.clone();
          const param = getSimulationParam(newConfig);
          if (param.input) {
            param.input.url = meshUrl;
          }
          setMeshId(param.input, meshId);
          return newConfig;
        });
        // Refetch the mesh list to show this newly created mesh
        await refetchMeshList();
      }
        break;
      case WorkOrderType.COMPUTE_GEOMETRY_CONTACTS:
        // Force a reload of the contacts selector to fetch the data from the backend.
        refresh(geometryContactsStateSelector({ projectId }));
        break;
      default:
        throw Error('Unknown WorkOrderType');
    }
    // Clear both the pending and requested work orders.
    setPendingWorkOrders(
      (workOrders) => {
        const newWorkOrders = workOrders.clone();
        const workOrdersMap = newWorkOrders.workOrders;
        delete workOrdersMap[type];
        return newWorkOrders;
      },
    );
    setRequestedWorkOrders((workOrders) => removeOrder(workOrders, type));
  });

  const handlePreview = (preview: frontendpb.GetGeometryReply_Preview) => {
    setMeshUrlState((oldMeshUrl) => {
      const newMeshUrl = oldMeshUrl.clone();
      newMeshUrl.preview = preview.surfaceGeoMeshUrl;
      newMeshUrl.activeType = projectstatepb.UrlType.GEOMETRY;
      return newMeshUrl;
    });
  };

  useEffect(() => {
    // If there is a requested work order, but it is no longer pending this means the request should
    // be canceled.
    requestedWorkOrders.forEach((requested, type) => {
      if (requested && !pendingOrdersMap[type]) {
        const reqJson = JSON.stringify(requested);
        const message = `Cancelling work order. order is not pending - ${reqJson}`;
        logger.info(message);

        orderHandlers.get(type)!.cancel(context, requested.workOrderId);
        // Delete request from requestedWorkOrders.
        setRequestedWorkOrders((workOrders) => removeOrder(workOrders, type));
      }
    });

    Object.entries(pendingOrdersMap).forEach(([typeStr, pending]) => {
      const type = parseInt(typeStr, 10) as frontendpb.WorkOrderType;

      // Removes the current request.
      const clearRequest = () => {
        setRequestedWorkOrders((workOrders) => removeOrder(workOrders, type));
      };

      const requested = requestedWorkOrders.get(type);
      if (pending && requested) {
        // Copy in the work order ID if it's missing. It might be missing because we only receive
        // it once the work has been started on the backend.
        if (!requested.workOrderId.length && pending.workOrderId) {
          setRequestedWorkOrders(
            (workOrders) => {
              const newWorkOrders = new Map<wOrderKey, wOrderValue>(workOrders);
              newWorkOrders.set(type, pending);
              return newWorkOrders;
            },
          );
          // If the pending and requested work orders are different cancel the requested work order.
          // It is obsolete. jspb.Message.equals seems to not be very robust. Also, in
          // GET_GEOMETRY-related work orders, we get two different work order ids because of the
          // preview and non-preview work orders that are generated (sigh). That's why we have to
          // perform two different checks, one with jspb.Message.equals with some filtering applied
          // to the pending and requested work orders and another check related to the equivalence
          // of the work order ids. Note that the backend should be stable wrt to the work order
          // ids.
        } else if (pending.workOrderId !== requested.workOrderId) {
          const newPending = pending.clone();
          const newRequested = requested.clone();
          newPending.progressInfo = undefined;
          newPending.workOrderId = '';
          newRequested.progressInfo = undefined;
          newRequested.workOrderId = '';
          // For GET_GEOMETRY worders, we need to be careful with the userGeoMod, since it may be
          // undefined vs empty in some cases. This is because this RPC generates potentially two
          // different work order ids and there's the possibility of the DONE from one RPC to race
          // with this code. Since we don't want to drop GET_GEOMETRY when there's no user geo mod,
          // we set to undefined messages that have a default value of `userGeoMod`.
          // See LC-16051 for more information.
          if (type === WorkOrderType.GET_GEOMETRY) {
            fixGetGeometryMessage(newPending);
            fixGetGeometryMessage(newRequested);
          }

          if (!newPending.equals(newRequested)) {
            const message = `Cancelling workorder. type=${type} ` +
              `Pending (${pending.toJsonString}) ` +
              `and requested (${requested.toJsonString()}) are diff.`;
            logger.info(message);
            orderHandlers.get(type)!.cancel(context, requested.workOrderId);
            clearRequest();
          }
        }
      }

      // If there is a pending work order, but no request has been sent, send an RPC request.
      if (pending && !requested) {
        orderHandlers.get(type)!.request(
          pending,
          { ...context, clearRequest, handleReply, handlePreview },
        );
        setRequestedWorkOrders(
          (workOrders) => {
            const newWorkOrders = new Map<wOrderKey, wOrderValue>(workOrders);
            newWorkOrders.set(type, pending);
            return newWorkOrders;
          },
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingOrdersMap, requestedWorkOrders]);

  // Cancel any existing Rpc pool on unmount, otherwise new requests will be ignored next time a
  // request is sent.
  useEffect(() => () => {
    orderHandlers.forEach((handler) => {
      handler.endRpc();
    });
    setRequestedWorkOrders(new Map<wOrderKey, wOrderValue>());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO (LC-6668): Move the code in WorkOrderManager into recoil. Creating this as a component
  // is not ideal as it blocks the UI from loading until all states are fetched.
  return <></>;
};

export default WorkOrderManager;
