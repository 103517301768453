export enum SampleProjectCategory {
  UNCATEGORIZED = 'uncategorized',
  TEMPLATE = 'template',
  TUTORIAL = 'tutorial',
  V_AND_V = 'v_and_v',
  CASE_STUDY = 'case_study',
}

const CATEGORY_LABEL_MAP: Record<SampleProjectCategory, string> = {
  [SampleProjectCategory.UNCATEGORIZED]: 'Other',
  [SampleProjectCategory.TEMPLATE]: 'Templates',
  [SampleProjectCategory.TUTORIAL]: 'Tutorials',
  [SampleProjectCategory.V_AND_V]: 'V & V',
  [SampleProjectCategory.CASE_STUDY]: 'Case Studies',
};

export const getProjectCategoryLabel = (category: SampleProjectCategory): string => (
  CATEGORY_LABEL_MAP[category] ||
  CATEGORY_LABEL_MAP.uncategorized
);
