// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * The height of the 3D viewer
 * This value is used for calculating the size for the floating geometry panel's content
 */
const visHeightState = atom({
  key: 'visHeightState',
  default: 0,
});

export function useVisHeightValue() {
  return useRecoilValue(visHeightState);
}

export function useSetVisHeight() {
  return useSetRecoilState(visHeightState);
}
