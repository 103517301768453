// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useSetRecoilState } from 'recoil';

import { getLocalStorageData } from '../lib/browserStorage';
import { localStorageEffect } from '../lib/persist';

/* Read/set clicked node links without paraview dependency */
export const NODE_LINK_KEY = 'nodeLinkState';

const nodeLinkState = atom<string[]>({
  key: 'NodeLink',
  default: getLocalStorageData(NODE_LINK_KEY, []),
  effects: [
    localStorageEffect(NODE_LINK_KEY),
  ],
});

export function useNodeLinkState() {
  return useRecoilState(nodeLinkState);
}

export function useSetNodeLinkState() {
  return useSetRecoilState(nodeLinkState);
}
