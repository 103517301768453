// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useEffect, useMemo, useRef } from 'react';

import cx from 'classnames';
import { useParams } from 'react-router-dom';

import TabPanel from '../components/Pane/TabPanel';
import Paraview from '../components/Paraview';
import ParaviewManager from '../components/Paraview/ParaviewManager';
import { RibbonToolbar } from '../components/RibbonToolbar/RibbonToolbar';
import suspenseWidget from '../components/SuspenseWidget';
import { createStyles, makeStyles } from '../components/Theme';
import { useProjectContext } from '../components/context/ProjectContext';
import SelectionManager from '../components/context/SelectionManager';
import { SetupSummaryDialog } from '../components/dialog/SetupSummary';
import { useOnStartExplorationSetup } from '../components/hooks/exploration/useCreateExploration';
import { useAssistantEnabled } from '../components/hooks/useAssistantEnabled';
import { useNeedToImportGeometry } from '../components/hooks/useNeedToImportGeometry';
import { useSwitchPage } from '../components/hooks/useSwitchPage';
import { GeoDisconnectedOverlay } from '../components/layout/ProjectBodyOverlay';
import { InfoFooter } from '../components/layout/footer/InfoFooter';
import { LcVisManager } from '../components/lcvis/LcVisManager';
import WorkOrderManager from '../components/meshing/WorkOrderManager';
import DragAndDropFiles from '../components/project/DragAndDropFiles';
import { UnavailableStage } from '../components/project/UnavailableStage';
import { SideRail } from '../components/project/assistant/SideRail';
import SensitivityAnalysisResults from '../components/project/sensitivityAnalysis/Results';
import { CurrentView } from '../lib/componentTypes/context';
import { colors } from '../lib/designSystem';
import { lcvHandler } from '../lib/lcvis/handler/LcvHandler';
import { useSetupTabTrace } from '../lib/observability/hooks/useSetupTabTrace';
import { AdvancedAnalysisParams } from '../lib/routeParamTypes';
import { isSensitivityAnalysis } from '../lib/workflowUtils';
import * as basepb from '../proto/base/base_pb';
import { useRpcGeometryState } from '../recoil/geometry/geometryState';
import { useLcVisEnabledValue } from '../recoil/lcvis/lcvisEnabledState';
import { useSelectedSolution } from '../recoil/selectedSolution';
import { useEnabledExperiments } from '../recoil/useExperimentConfig';
import { useMeshReadyState } from '../recoil/useMeshReadyState';
import { useSetControlPanelMode } from '../recoil/useProjectPage';
import { useWorkflowState } from '../recoil/workflowState';
import { useCurrentView, useIsAdvancedAnalysisView, useIsAnalysisView, useIsExplorationSetup, useIsGeometryView } from '../state/internal/global/currentView';

const useStyles = makeStyles(
  () => createStyles({
    tabBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      gap: '16px',
      overflow: 'hidden',
      width: '100%',
      borderBottom: `2px solid ${colors.neutral150}`,
    },
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    mainWindow: {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      overflow: 'hidden',
      position: 'relative',
    },
    mainParaview: {
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    paraviewDisplayContainer: {
      // This puts the paraviewContainer (and any modals inside of it, like the color adjuster)
      // above the Resize's splitter and content.
      zIndex: 3,
    },
    paraviewDisplay: {
      position: 'relative',
      height: '100%',
    },
  }),
  { name: 'PageBody' },
);

export interface PageBodyProps {
  // Whether we are showing the results from an exploration.
  isExploration: boolean;
}

/**
 * The main body of a page containing several panes.
 */
const ProjectBody = (props: PageBodyProps) => {
  const { projectId, workflowId, jobId, geometryId } = useProjectContext();
  // Start the streaming RPC for geometry updates.
  useRpcGeometryState(projectId, geometryId);
  // Effects that need to fire when switching pages.
  useSwitchPage();
  const workflow = useWorkflowState(projectId, workflowId);
  const solution = useSelectedSolution(projectId, workflowId, jobId);
  const enabledExperiments = useEnabledExperiments();
  const lcVisEnabled = useLcVisEnabledValue(projectId);
  const isAnalysisView = useIsAnalysisView();
  const isGeometryView = useIsGeometryView();
  const isAdvancedAnalysisView = useIsAdvancedAnalysisView();
  const currentView = useCurrentView();
  const isAnalysisOrResults = isAnalysisView || currentView === CurrentView.RESULTS;
  const assistantEnabled = useAssistantEnabled();
  const onStartExplorationSetup = useOnStartExplorationSetup();
  const isExplorationSetup = useIsExplorationSetup();
  const readyState = useMeshReadyState(projectId, workflowId, jobId);
  const needToImportGeometry = useNeedToImportGeometry(projectId);
  const params = useParams<AdvancedAnalysisParams>();
  const advancedAnalysisType = params.advancedType;

  // Layout
  const paraviewWindowRef = useRef<HTMLDivElement>(null);

  const isSensitivity = props.isExploration && !!workflow && isSensitivityAnalysis(workflow);

  const classes = useStyles();

  // Set the initial mode to experiments for sensitivity analysis.
  const setControlPanelMode = useSetControlPanelMode();

  // Add traces for when the user is on the setup tab, with a Geometry vs a Mesh.
  useSetupTabTrace(projectId, workflowId, jobId);

  // This effect is used to set the proper control panel mode depending on the view on page load
  // and on page changes. This is also responsible to get out of the `exploration` setup mode when
  // we change pages.
  useEffect(() => {
    // The geometry page only ever wants the `geometry` mode.
    if (isGeometryView) {
      setControlPanelMode('geometry');
      return;
    }
    // If we have opened a sensitivity analysis workflow, we'll use the `exploration` mode.
    // In all other cases we'll revert to the `simulation`. The only exception is the
    // Solver page because that's the page we currently use in the background to setup the
    // DoE for the new WorkflowUI. Update with LC-22632 (check for new view).
    if (!isAdvancedAnalysisView) {
      setControlPanelMode(isSensitivity ? 'exploration' : 'simulation');
    }
  }, [
    isGeometryView,
    isAdvancedAnalysisView,
    isSensitivity,
    setControlPanelMode,
  ]);

  // This is used to enter into the DoE mode in the advanced route when we have `doe` in the url.
  // It's executed both on page refresh and when we come from the Analysis page (through the
  // Setup Design of Experiments button.)
  // We can't combine this with the other effect above because the `onStartExplorationSetup` dep
  // will cause the other effect to run when exploration vars are updated and this will
  // prematurelly exit the DoE in the original UI.
  useEffect(() => {
    if (isAdvancedAnalysisView && advancedAnalysisType === 'doe' && !isExplorationSetup) {
      onStartExplorationSetup();
      setControlPanelMode('exploration');
    }
  }, [
    isAdvancedAnalysisView,
    advancedAnalysisType,
    isExplorationSetup,
    onStartExplorationSetup,
    setControlPanelMode,
  ]);

  useEffect(() => {
    const updateCurrentView = async () => {
      if (lcVisEnabled && projectId) {
        await lcvHandler.setCurrentView(currentView);
      }
    };

    updateCurrentView().catch(console.error);
  }, [currentView, lcVisEnabled, projectId]);

  const lcvisKey = `${projectId}${workflowId}${jobId}${geometryId}`;
  const centerPane: ReactElement | null = useMemo(() => {
    if (isGeometryView) {
      if (geometryId !== '') {
        return (
          <LcVisManager
            key={lcvisKey}
          />
        );
      }
      return (
        <DragAndDropFiles
          experimentConfig={enabledExperiments}
          projectId={projectId}
        />
      );
    } if (readyState) {
      if (isSensitivity) {
        return (<SensitivityAnalysisResults />);
      }
      return lcVisEnabled ? (
        <LcVisManager
          key={lcvisKey}
        />
      ) : (
        <Paraview />
      );

      // Do not show the import dialog if the geo mod is enabled. The only way to import is from the
      // geometry tab when that flag is enabled to avoid additional uploads when a geometry already
      // exists.
    }

    if (!isGeometryView && needToImportGeometry) {
      return (
        <UnavailableStage />
      );
    }

    return null;
  }, [
    isGeometryView,
    geometryId,
    lcvisKey,
    readyState,
    isSensitivity,
    lcVisEnabled,
    enabledExperiments,
    projectId,
    needToImportGeometry,
  ]);

  if (isAnalysisView) {
    // If the workflow is completed and there is no solution yet,
    // we know that we are currently fetching data
    // TODO(bamo): we should have a better way of knowing if we're in fetching state --
    // this kind of reasoning about job status/solution value is brittle (previously caused
    // LC-6388).
    if (!workflow ||
      (workflow?.status?.typ === basepb.JobStatusType.Completed && !solution)) {
      return suspenseWidget;
    }
  }

  return (
    <SelectionManager>
      <ParaviewManager>
        <div className="flexColumnLayout fullHeight">
          <div className="flexItem elastic">
            <div className={classes.rootContainer}>
              <div className={classes.mainWindow}>
                <GeoDisconnectedOverlay />
                <div className={classes.mainParaview} ref={paraviewWindowRef}>
                  <div className="flexColumnLayout fullHeight">
                    {isAnalysisOrResults && (
                      <div className="flexItem">
                        <div className={classes.tabBar}>
                          <TabPanel />
                        </div>
                      </div>
                    )}
                    <RibbonToolbar />
                    <div className={cx(classes.paraviewDisplayContainer, 'flexItem', 'elastic')}>
                      <div className={classes.paraviewDisplay}>
                        {centerPane}
                      </div>
                    </div>
                    {!isGeometryView && <InfoFooter ref={paraviewWindowRef} />}
                  </div>
                </div>
                <SetupSummaryDialog />
                {assistantEnabled && <SideRail />}
              </div>
            </div>
          </div>
        </div>
        <WorkOrderManager projectId={projectId} />
      </ParaviewManager>
    </SelectionManager>
  );
};

export default ProjectBody;
