// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { StatusCardProps } from '../../../lib/statusCardProps';

const statusCardPropsState = atomFamily<StatusCardProps, string>({
  key: 'statusCardPropsState',
  default: { isVisible: false },
});

export const useStatusCardProps = (
  projectId: string,
) => useRecoilState(statusCardPropsState(projectId));

export const useSetStatusCardProps = (
  projectId: string,
) => useSetRecoilState(statusCardPropsState(projectId));

export const useStatusCardPropsValue = (
  projectId: string,
) => useRecoilValue(statusCardPropsState(projectId));
