import { useMemo } from 'react';

import * as flags from '../../../flags';
import { CurrentView, INTERMEDIATE_VIEWS, currentViewMap } from '../../../lib/componentTypes/context';
import { EntityGroup } from '../../../lib/entityGroupMap';
import { LeveledMessage } from '../../../lib/notificationUtils';
import { SimulationTreeNode } from '../../../lib/simulationTree/node';
import { UrlType } from '../../../proto/projectstate/projectstate_pb';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useMeshUrlState } from '../../../recoil/meshState';
import { useIsEnabled } from '../../../recoil/useExperimentConfig';
import useProjectMetadata from '../../../recoil/useProjectMetadata';
import { StaticVolume, useStaticVolumes } from '../../../recoil/volumes';
import { useAllWarnings } from '../../../state/external/project/validator';
import { useCurrentView } from '../../../state/internal/global/currentView';
import { useGeometryTree } from '../../../state/internal/tree/section/geometry';
import { useSimulationTree } from '../../../state/internal/tree/simulation';
import { useWorkflowFlagValue } from '../../../workflowFlag';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';

/**
 * The shape of the blob of project state that we send to the assistant.
 * Data constructs should not be part of the object as they are lost during seralization.
 */
interface EntityGroupAI extends Omit<EntityGroup, 'children'> { children: string[] }
interface WarningAI extends LeveledMessage { view: CurrentView, nodeId: string }
interface SimulationTreeNodeAI extends Omit<
  SimulationTreeNode,
  'parent' | 'getDescendant' | 'isDescendant' | 'traverse' | 'children' | 'descendantsMap'
> { children: string[] }

interface ProjectStateType {
  visualizerReady: boolean;
  visualizerUsed: 'paraview' | 'lcvis';
  volumeIdToName: Record<string, string>; // Maybe we have to JSON.stringify these?
  staticVolumes: StaticVolume[];
  entityGroups: EntityGroupAI[];
  surfaceIdToName: Record<string, string>;
  currentView: 'geometry' |
  'setup' |
  'analysis' |
  'results' |
  'physics' |
  'mesh' |
  'outputs' |
  'solver' |
  'advanced analysis';
  projectName: string | undefined;
  projectId: string;
  geometryId: string;
  workflowId: string;
  jobId: string;
  isMesh: boolean;
  warnings: WarningAI[];
  // Includes the tree nodes for the geometry tree on the left and the settings tree on the right
  treeNodes: SimulationTreeNodeAI[];
}

// A function to exclude the 'parent' key from the SimulationTreeNode and to change the children to
// be a string[] so that the object can be safely stringified for processing by the assistant.
function prepNodeForAssistant(node: SimulationTreeNode): SimulationTreeNodeAI {
  const { parent, children, ...safeNode } = node;
  return { ...safeNode, children: children.map((child) => child.id) };
}

/**
 * A hook that constructs a data object for consumption by the ai assistant (through a macro).
 */
export const useAssistantSyncData = () => {
  const { projectId, geometryId, workflowId, jobId } = useProjectContext();
  const lcvisReady = useLcVisReadyValue();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const { viewState } = useParaviewContext();
  const staticVolumes = useStaticVolumes(projectId);
  const currentView = useCurrentView();
  const assistantEnabled = useIsEnabled(flags.aiAssistant);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const projectName = useProjectMetadata(projectId)?.summary?.name;
  const [meshUrlState] = useMeshUrlState(projectId);
  const allWarnings = useAllWarnings(projectId, workflowId, jobId);
  const workflowFlagValue = useWorkflowFlagValue();
  const simulationTree = useSimulationTree(projectId, workflowId, jobId);
  const geometryTree = useGeometryTree(projectId, workflowId, jobId);
  const isMesh = meshUrlState.activeType === UrlType.MESH;

  const visualizerReady = lcvisEnabled ? lcvisReady : !!viewState;
  const visualizerUsed = lcvisEnabled ? 'lcvis' : 'paraview';
  const volumeIdToName = useMemo(
    () => staticVolumes.reduce((acc, volume) => {
      acc[volume.id] = volume.defaultName;
      return acc;
    }, {} as Record<string, string>),
    [staticVolumes],
  );
  const surfaceIdToName = useMemo(() => {
    const rec: Record<string, string> = {};
    entityGroupMap.traverse((entityGroup) => {
      rec[entityGroup.id] = entityGroup.name;
    });
    return rec;
  }, [entityGroupMap]);

  const groups = useMemo(() => entityGroupMap.getGroups().map((group) => ({
    ...group,
    children: Array.from(group.children),
  })), [entityGroupMap]);

  const warnings = useMemo(
    () => [...allWarnings].flatMap(
      ([view, warningsNodeMap]) => [...warningsNodeMap].flatMap(
        ([nodeId, nodeErrors]) => nodeErrors.map((error) => ({ ...error, nodeId, view })),
      ),
    ).filter((warning) => {
      // Make sure errors for workflow-based UI are not duplicated with errors for original UI
      if (workflowFlagValue) {
        if (warning.view === CurrentView.SETUP) {
          return false;
        }
      } else if ([...INTERMEDIATE_VIEWS, CurrentView.ADVANCED_ANALYSIS].includes(warning.view)) {
        return false;
      }
      return true;
    }),
    [allWarnings, workflowFlagValue],
  );

  const treeNodes = useMemo(() => {
    const rows: SimulationTreeNodeAI[] = [];
    geometryTree.traverse((node) => {
      rows.push(prepNodeForAssistant(node));
    });
    simulationTree.traverse((node) => {
      rows.push(prepNodeForAssistant(node));
    });
    return rows;
  }, [geometryTree, simulationTree]);

  const projectState = useMemo(() => {
    if (!assistantEnabled) {
      return;
    }
    const state: ProjectStateType = {
      visualizerReady,
      visualizerUsed,
      volumeIdToName,
      staticVolumes,
      entityGroups: groups,
      surfaceIdToName,
      currentView: currentViewMap[currentView],
      projectName,
      projectId,
      geometryId,
      workflowId,
      jobId,
      isMesh,
      warnings,
      treeNodes,
    };
    return state;
  }, [
    visualizerReady,
    visualizerUsed,
    volumeIdToName,
    staticVolumes,
    groups,
    currentView,
    assistantEnabled,
    surfaceIdToName,
    projectName,
    projectId,
    geometryId,
    workflowId,
    jobId,
    isMesh,
    warnings,
    treeNodes,
  ]);

  return projectState;
};
